import React, {useEffect, useReducer, useState} from 'react';
import {Icon, Typography} from '../../../../core/components';
import AdminPage from '../../../../core/components/admin-page/AdminPage';
import {useDialog} from '../../../../core/components/dialog';
import {colorTheme} from '../../../../core/configs';
import {Column} from 'react-table';
import AdminTable from '../users/AdminTable';
import {capitalize} from '../../../utils/random.util';
import GenericConfirmationDialog from '../../../views/dialog-content/generic-dialog/GenericConfirmationDialog';
import {useToast} from "../../../../core/components/toast";
import {useAuth} from "../../../hooks/useAuth";
import AdminVoucherSearch from './AdminVoucherSearch';
import { KazamException } from '../../../server/types';
import CreateVoucherModalView from '../../../views/dialog-content/voucher-modal/CreateVoucherModalView';
import UpdateVoucherModalView from '../../../views/dialog-content/voucher-modal/UpdateVoucherModalView';
import { useDeleteVoucher, useGetVouchers } from '../../../server/react-query/useVoucher';
import { VoucherDiscountType, VoucherResponse } from '../../../server/types/voucher.types';

interface TableData {
  voucherId: string
  name: string
  period: string
  discountValue: string
  availLimit?: number
  code: string
  // priority: number
  availers: number
  status: string
  startDate: Date
  endDate: Date
  createdOn: Date
}

export interface PaginatedQueryParams {
  searchKey: string | undefined,
  pageNumber: number
}

export const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'SET_SEARCH':
      return {
        ...state,
        searchKey: action.payload.searchKey,
        pageNumber: 1
      }
    case 'SET_PAGE':
      return {
        ...state,
        pageNumber: action.payload.pageNumber
      }
    default:
      return state
  }
}

export const INITIAL_SEARCH_STATE: PaginatedQueryParams = {
  searchKey: '',
  pageNumber: 1
}

const AdminVoucherPage = () => {
  const {presentDialog, dismissDialog} = useDialog();
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [searchKey, setSearchKey] = useState<string>('');
  const [vouchersData, setVouchersData] = useState<TableData[]>([]);
  const [searchBody, dispatchSearch] = useReducer(reducer, INITIAL_SEARCH_STATE)

  const {data, isLoading, isFetching, refetch} = useGetVouchers(searchBody);

  const {presentToast} = useToast();
  const {authData} = useAuth();

  const [columns] = useState<Column<TableData>[]>([
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Status',
      accessor: 'status', 
    },
    {
      Header: 'Validity',
      accessor: 'period', 
    },
    {
      Header: 'Code',
      accessor: 'code', 
    },
    {
      Header: 'Discount',
      accessor: 'discountValue', 
    },
    {
      Header: 'Availers',
      accessor: 'availers', 
    },
    {
      Header: 'Action',
      accessor: 'action',
    },
    {
      Header: '',
      accessor: 'open',
    },
  ] as Column<TableData>[]);

  useEffect(() => {
    if (data) {
      console.log("data", data);
      
      setPageCount(data.totalPages);
      setPageNumber(data.pageNumber);

      setVouchersData(
        data.data.map(i => {
          let period = 'No Expiration'

          if (i.endDate) {
            const startDate = i.startDate ? new Date(i.startDate) : new Date();
            const endDate = new Date(i.endDate)
            const durationInMilliseconds = endDate.getTime() - startDate.getTime();
            const durationInDays = durationInMilliseconds / (1000 * 60 * 60 * 24);
            period = `${Math.round(durationInDays).toString()} days`
          }
          const status = capitalize(i.status)

          let discountValue = i.discountType === 'amountOff' ? `${i.discountValue} Php` : `${i.discountValue} %`;

          return {...i, period, discountValue, status} as TableData;
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    dispatchSearch({
      type: 'SET_SEARCH',
      payload: {
        searchKey: searchKey?.trim(),
      },
    });
  }, [searchKey]);

  useEffect(() => {
    console.log("searchBody", searchBody);
    
    refetch()
  }, [searchBody]);

  // useEffect(() => {
  //   if (searchKey === '') {
  //     refetch();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [searchKey]);

  // useEffect(() => {
  //   refetch();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pageNumber]);

  const createVoucher = () => {
    presentDialog({
      headerText: '',
      content: (
        <CreateVoucherModalView
          type='splash'
          onClose={dismissDialog}
          onCancel={dismissDialog}
          onCreated={() => {
            dismissDialog();
            refetch();
          }}
          onError={showError}
        />
      ),
      enableBackdropDismiss: false,
      hideClose: true,
      fullWidth: true,
    });
  };

  const viewVoucher = (voucherId: string) => {
    presentDialog({
      headerText: '',
      content: (
        <UpdateVoucherModalView
          voucherId={voucherId}
          onClose={dismissDialog}
          onCancel={dismissDialog}
          onUpdated={() => {
            dismissDialog();
            refetch();
          }}
          onError={showError}
        />
      ),
      enableBackdropDismiss: false,
      hideClose: true,
      fullWidth: true,
    });
  };

  const onPageChangedHandler = (pageNumber: number) => {
    setPageNumber(pageNumber);
    dispatchSearch({
      type: 'SET_PAGE',
      payload: {
        pageNumber: pageNumber
      },
    });
  };

  const onSearch = () => {
    refetch();
  };

  const onVoucherClick = (value: any) => {
    const data = value as VoucherResponse;
    if (data) {
      viewVoucher(data.voucherId);
    }
  };

  const onDeleteVoucherClick = (value: any) => {
    const data = value as VoucherResponse;
    deleteVoucherDialog(data);
  };

  const deleteVoucher = useDeleteVoucher();
  const deleteAsync = async (data: VoucherResponse) => {
    if (data) {
      try {
        await deleteVoucher.mutateAsync(data.voucherId);
        dismissDialog();
      } catch (e: any) {
        console.log(e);
        const exception = e.data as KazamException;
        showError(exception ? exception.error : 'Failed to delete.')
      }
    }
  };
  const deleteVoucherDialog = (data: VoucherResponse) => {
    presentDialog({
      headerText: '',
      content: (
        <GenericConfirmationDialog
          headerText="Delete Voucher"
          desc="Are you sure you want to delete this voucher?"
          rightButtonText="Delete"
          leftButtonText="Cancel"
          onLeftButtonAction={dismissDialog}
          onRightButtonAction={() => {
            deleteAsync(data);
          }}
        />
      ),
      enableBackdropDismiss: true,
    });
  };

  const manualRenderFn = (columnId: string, rowData: TableData) => {
    if (columnId === 'open') {
      return (
        <div className="flex flex-col justify-center items-end">
          <Icon name="chevronRight" color={colorTheme.dark} />
        </div>
      );
    }
    
    return (
      <div
        className="cursor-pointer"
        onClick={(event: React.MouseEvent) => {
          event.stopPropagation();
          onDeleteVoucherClick(rowData);
        }}>
        <Typography
          label="Delete"
          variant="f2"
          color={colorTheme.primary}
          align="left"
        />
      </div>
    );
  };

  const showError = (message: string) => {
    presentToast({
      message: message,
      variant: 'error',
      position: 'bottom',
    })
  }

  return (
    <AdminPage>
      <div>
        <Typography
          label="Manage Vouchers"
          variant="f3"
          weight="semibold"
          color={colorTheme.dark}
        />
      </div>

      <AdminVoucherSearch
        value={searchKey}
        onClickCreate={createVoucher}
        onChange={setSearchKey}
        onClickSearch={onSearch}
        // onExtractAllClickedHandler={createVoucher}
      />

      <div className="pt-6">
        <AdminTable
          columns={columns}
          data={vouchersData}
          pageSize={10}
          pageCount={pageCount}
          showDebugView={false}
          pageNumber={pageNumber}
          onPageChange={onPageChangedHandler}
          onRowClick={onVoucherClick}
          manualRenderFn={manualRenderFn}
        />
      </div>
    </AdminPage>
  );
};

export default AdminVoucherPage;
