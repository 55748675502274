import styled from 'styled-components';
import { colorTheme } from '../../../../core/configs';

interface StyledOfferCardProps {
  isSender: boolean
}

export const StyledOfferCard = styled.div<StyledOfferCardProps>`
    display: flex;
    width: 100%;
    justify-content: ${p => p.isSender ? 'end' : 'start'};

    & .offer-details{
        background-color:${colorTheme.generatedMessage};
        display: table-footer-group;
        padding: 10px;
        width: 320px;
        border-radius: 10px;
        font-size: 0.8rem;
        color:${colorTheme.black};
    }

    & .txt-heading{
      font-weight: 600;
    }

    & .offer-primary-btn{
      background-color: ${colorTheme.light};
      color: ${colorTheme.black};
      font-weight: 600;
    }

    & .offer-secondary-btn{
      background-color: ${colorTheme.darkFaded};
      color: ${colorTheme.white};
      font-weight: 600600;
    }

    & .offer-msg-time span {
      color: ${colorTheme.darkFaded};
      font-weight: 100;
    }

    & .offer-status {
      color: ${colorTheme.primary};
      font-weight: 600;
    }

`;