import * as React from 'react';
import type {SVGProps} from 'react';
import {Ref, forwardRef, memo} from 'react';
const SvgTrueSelfie = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 25 25"
    ref={ref}
    {...props}>
    <g clipPath="url(#true-selfie_svg__a)">
      <rect
        width={20.5}
        height={22.5}
        x={2.5}
        y={1.25}
        stroke="currentColor"
        strokeWidth={1.5}
        rx={1.25}
      />
      <circle cx={12.5} cy={4.5} r={1.5} fill="currentColor" />
      <path
        fill="currentColor"
        d="M3.75 23.5c0-4.305 3.19-7.795 7.125-7.795h3.75c3.935 0 7.125 3.49 7.125 7.795zM16.5 11.603c0 2.265-1.763 4.102-3.937 4.102s-3.938-1.837-3.938-4.102S10.388 7.5 12.563 7.5c2.174 0 3.937 1.837 3.937 4.103"
      />
    </g>
    <defs>
      <clipPath id="true-selfie_svg__a">
        <path fill="#fff" d="M.75.5h24v24h-24z" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgTrueSelfie);
const Memo = memo(ForwardRef);
export default Memo;
