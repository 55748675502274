import {isMobile} from 'react-device-detect';
import styled, {css} from 'styled-components';
import {colorTheme} from '../../../../core/configs';

export const StyledFinderSettings = styled.div`
  background: ${colorTheme.darkFaded};
  padding: 8px 16px;
  color: white;
  position: fixed;
  z-index: 2;

  ${isMobile
    ? css`
        top: 56px;
        left: 0;
        right: 0;
      `
    : css`
        top: 66px;
        left: 300px;
        right: 300px;
      `}
`;
