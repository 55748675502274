import * as React from 'react';
import {SVGProps, Ref, forwardRef, memo} from 'react';
const SvgMissedVoice = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 25"
    ref={ref}
    {...props}>
    <path
      fill="currentColor"
      d="m3.4 20.9-2.3-2.25a.96.96 0 0 1-.3-.7c0-.267.1-.5.3-.7 1.467-1.583 3.158-2.77 5.075-3.563C8.092 12.896 10.033 12.5 12 12.5s3.904.396 5.813 1.188c1.908.791 3.604 1.979 5.087 3.562.2.2.3.433.3.7 0 .267-.1.5-.3.7l-2.3 2.25a.975.975 0 0 1-1.3.1l-2.9-2.2a.993.993 0 0 1-.3-.35.992.992 0 0 1-.1-.45v-2.85a15.13 15.13 0 0 0-1.95-.475A11.868 11.868 0 0 0 12 14.5c-.7 0-1.383.058-2.05.175-.667.117-1.317.275-1.95.475V18a.992.992 0 0 1-.1.45.992.992 0 0 1-.3.35L4.7 21a.975.975 0 0 1-1.3-.1ZM6 15.85c-.483.25-.95.537-1.4.862-.45.326-.917.688-1.4 1.088l1 1L6 17.4v-1.55Zm12 .05v1.5l1.8 1.4 1-.95a15.34 15.34 0 0 0-1.4-1.125A9.933 9.933 0 0 0 18 15.9ZM6 9.5a.968.968 0 0 1-.713-.287A.968.968 0 0 1 5 8.5v-4c0-.283.096-.52.287-.712A.968.968 0 0 1 6 3.5h4c.283 0 .52.096.713.288.191.191.287.429.287.712s-.096.52-.287.713A.968.968 0 0 1 10 5.5H8.4l3.525 3.525 4.95-4.95c.2-.2.438-.3.712-.3.276 0 .513.1.713.3.2.2.3.438.3.712 0 .276-.1.513-.3.713l-4.925 4.925c-.383.383-.858.575-1.425.575-.567 0-1.042-.192-1.425-.575L7 6.9v1.6c0 .283-.096.52-.287.713A.968.968 0 0 1 6 9.5Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgMissedVoice);
const Memo = memo(ForwardRef);
export default Memo;
