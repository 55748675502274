import React from "react";

interface SideDrawerMenusProps {
    children: React.ReactNode
}

const SideDrawerMenus = (props: SideDrawerMenusProps) => {
    return (
        <ul>
            {props.children}
        </ul>
    );
};

export default SideDrawerMenus;