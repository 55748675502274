import { useEffect, useState } from 'react';
import { JobOfferMeta, JobHistoryResponse } from '../../../server/types/job-offer.types';
import format from 'date-fns/format';
import { toNumberWithCommas } from '../../../utils/decimal.util';
import { getLivingArrangementValue } from '../../../utils/job-offer.util';
import { Typography } from '../../../../core/components';
import { colorTheme } from '../../../../core/configs';
import { getTerminationReasonValue } from '../../../utils/job-history.util';

interface JobOfferDetailsProps {
  offer: JobHistoryResponse
}

const JobEndedDetails = ({
  offer,
}: JobOfferDetailsProps) => {
  const [offerMeta, setOfferMeta] = useState<JobOfferMeta>()
  const [roles, setRoles] = useState<string>();

  useEffect(() => {
    if (offer
      && offer.meta) {
      var meta = JSON.parse(offer.meta) as JobOfferMeta
      setOfferMeta(meta)

      var roles = meta.Roles?.map(r => r.Role?.Text)
      setRoles(roles.join(", "))
    }
  }, [offer])
  
  return (
    <>
      <div className="flex gap-1">
        <span className="txt-heading">Start Date:</span>
        <span>{offer?.startDate ? format(new Date(offer.startDate), 'PP') : ""}</span>
      </div>

      <div className="flex gap-1">
        <span className="txt-heading">End Date:</span>
        <span>{offer?.endDate ? format(new Date(offer.endDate), 'PP') : "No date given"}</span>
      </div>

      <div className="flex gap-1">
        <span className="txt-heading">Hiring Address:</span>
        <span>
          {offerMeta?.Location?.HasProvince 
            ? `${offerMeta?.Location?.CityOrMunicipality}, ${offerMeta?.Location?.Province?.Name}`
            : offerMeta?.Location?.CityOrMunicipality}
        </span>
      </div>

      <div className="flex gap-1">
        <span className="txt-heading">Salary per month:</span>
        <span>{`PHP ${toNumberWithCommas(offerMeta?.Salary ?? 0)}`}</span>
      </div>

      <div className="flex gap-1">
        <span className="txt-heading">Living Arrangement:</span>
        <span>{getLivingArrangementValue(offerMeta?.LivingArrangement)}</span>
      </div>

      <div className="flex gap-1">
        <span className="txt-heading">Role:</span>
        <span>{roles}</span>
      </div>

      <div className="flex w-full justify-center items-center mx-2">
        <Typography 
          label='- JOB TERMINATED -'
          variant='f1'
          weight='semibold'
          color={colorTheme.red}
        />
      </div>

      <div className="gap-1">
        <span className="txt-heading">Reason for contract termination: </span>
        <span>{offer.terminationReason ? getTerminationReasonValue(offer.terminationReason) : ""}</span>
      </div>

      <div className="flex gap-1">
        <span className="txt-heading">End Date:</span>
        <span>{offer?.endDate ? format(new Date(offer.endDate), 'PP') : "No date given"}</span>
      </div>
    </>
  );
};

export default JobEndedDetails;
