import React, {useEffect, useState} from "react";
import AdminPage from "../../../../../core/components/admin-page/AdminPage";
import {Button, Icon, Typography} from "../../../../../core/components";
import {colorTheme} from "../../../../../core/configs";
import AdminTable from "../AdminTable";
import {StyledAdminReportersViewPage} from "../styled";
import {UserResponse} from "../../../../server/types";
import {useHistory, useParams} from "react-router-dom";
import {useGetAbuseReporters, useGetUserById, usePostJob} from "../../../../server/react-query";
import {format} from "date-fns";
import {Column} from "react-table";
import ExtractReportDialog from "../../../../views/dialog-content/ExtractReportDialog";
import {useToast} from "../../../../../core/components/toast";
import {useDialog} from "../../../../../core/components/dialog";
import {useAuth} from "../../../../hooks/useAuth";
import { RoutePath } from "../../../../navigation/config/RouteConfig";

interface AdminReportersViewPageParams {
  id: string;
}

interface TableData {
  userId: string;
  name: string;
  location: string;
  conversationId: string;
  date: string;
}

const AdminReportersViewPage = () => {
  const [user, setUser] = useState<UserResponse | undefined>(undefined);
  const [data, setData] = useState<TableData[]>([]);
  const [pageCount, setPageCount] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const history = useHistory();
  const {id: userId} = useParams<AdminReportersViewPageParams>();

  const {data: hookDataUser} = useGetUserById(userId);

  const {data: hookDataAbuseReporters, refetch} = useGetAbuseReporters(userId, pageNumber);

  const postJob = usePostJob();
  const {presentToast} = useToast();
  const {presentDialog, dismissDialog} = useDialog();
  const {authData} = useAuth();

  const [columns] = useState<Column<TableData>[]>([
    {
      Header: 'Name',
      accessor: 'name', // accessor is the "key" in the data
    },
    {
      Header: 'User ID',
      accessor: 'userId', // accessor is the "key" in the data
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'Conversation ID',
      accessor: 'conversationId',
    },
    {
      Header: 'Date Reported',
      accessor: 'date',
    },
    {
      Header: '',
      accessor: 'open',
    },
  ] as Column<TableData>[]);

  useEffect(() => {
    (async () => {
      await refetch();
    })();
  }, [pageNumber]);

  useEffect(() => {
    if (hookDataAbuseReporters) {
      const response = hookDataAbuseReporters;
      setPageCount(Math.ceil(response.total / response.pageSize));
      setData(response.data.map(i => {
        const userId = i.reporterUserId;

        const name = `${i.reporterUser.firstName} ${i.reporterUser.lastName}`;

        let location = '';
        const userLocation = i.reporterUser.filterSetting.location;
        if (userLocation) {
          location = userLocation?.cityOrMunicipality;
          if (userLocation.hasProvince) {
            location += `, ${userLocation.province.name}`;
          }
        }

        const conversationId = i.conversationChannelId;

        const date = format(new Date(i.createdOn), 'M/dd/yyyy');

        return {userId, name, location, conversationId, date} as TableData;
      }));
    }
  }, [hookDataAbuseReporters]);

  useEffect(() => {
    if (hookDataUser) {
      setUser(hookDataUser);
    }
  }, [hookDataUser]);

  const onBackButtonClickedHandler = () => {
    history.go(-1);
  };

  const onPageChangedHandler = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const onExtractClickedHandler = async () => {
    if (authData?.user.email) {
      try {
        await postJob.mutateAsync({
          type: 'abuseReportersReport',
          params: JSON.stringify({
            email: authData?.user.email,
            userId: userId,
          }),
        });

        presentDialog({
          headerText: '',
          content: (
            <ExtractReportDialog requestName="Abuse Reporters" onOkayClick={() => dismissDialog()} />
          ),
          enableBackdropDismiss: true,
          hideClose: true,
        });
      } catch (error) {
        console.log({error});
        presentToast({
          message: 'Request failed',
          variant: 'error',
          position: 'bottom',
        });
      }
    }
  };

  const tableRowClickHandler = (rowData: TableData) => {
    const params = `?reporter=${rowData.userId}`
    history.push(
      RoutePath.ADMIN_CHAT_ROOM.replace(':id', rowData.conversationId) + params
    );
  };

  const manualRenderFn = (columnId: string, rowData: TableData) => {
    if (columnId === 'action') {
      return undefined;
    } else if (columnId === 'open') {
      return (
        <div className="flex flex-col justify-center items-end">
          <Icon name="chevronRight" color={colorTheme.dark} />
        </div>
      );
    } else {
      return (<div />);
    }
  };

  return (
    <AdminPage>
      <StyledAdminReportersViewPage>
        <div className="w-full flex flex-row">
          <div className="flex-1 flex">
            <div className="flex items-center cursor-pointer" onClick={onBackButtonClickedHandler}>
              <Icon
                name="chevronLeft"
                color={colorTheme.dark}
                size={24}
                type="button" />

              <Typography
                label={`Reporters of ${user?.firstName} ${user?.lastName}`}
                variant="f3"
                weight="semibold"
                color={colorTheme.dark}
              />
            </div>
          </div>
          <div>
            <Button
              label="Extract All"
              variant="outlined"
              onClick={onExtractClickedHandler}
              className="btn-search" />
          </div>
        </div>

        <div className="table-container">
          <AdminTable
            columns={columns}
            data={data}
            pageSize={10}
            pageCount={pageCount}
            showDebugView={false}
            onRowClick={tableRowClickHandler}
            onPageChange={onPageChangedHandler}
            manualRenderFn={manualRenderFn} />
        </div>

      </StyledAdminReportersViewPage>
    </AdminPage>
  );
};

export default AdminReportersViewPage;
