import React from "react";
import {Button, Typography} from "../../../core/components";
import {StyledExtractReportDialog} from "./styled";
import { colorTheme } from "../../../core/configs";

interface ExtractReportDialogProps {
  requestName: string;
  onOkayClick: () => void;
}

const ExtractReportDialog = (props: ExtractReportDialogProps) => {
  const {requestName, onOkayClick} = props;
  return (
    <StyledExtractReportDialog>
      <Typography
        variant="f2"
        color={colorTheme.dark}
        label={`Your request for the ${requestName} report has been submitted. Please check your email after a moment.`} />

      <Button className="btn-okay" label="Okay" color="primary" onClick={onOkayClick} />
    </StyledExtractReportDialog>
  );
};

export default ExtractReportDialog;