import * as React from 'react';
import {SVGProps, Ref, forwardRef, memo} from 'react';
const SvgHistory = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 25 25"
    ref={ref}
    {...props}>
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      clipPath="url(#history_svg__a)">
      <path d="m3.749 13.312.001-.829a9 9 0 1 1 5.325 8.234" />
      <path d="m1.751 11.668 2.118 2.124 2.125-2.118M11.756 8.5l-.007 5 5 .007" />
    </g>
    <defs>
      <clipPath id="history_svg__a">
        <path fill="#fff" d="M.75.5h24v24h-24z" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgHistory);
const Memo = memo(ForwardRef);
export default Memo;
