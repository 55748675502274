import styled, {css} from 'styled-components';
import {colorTheme} from '../../configs';

export const StyledSideDrawerMenu = styled.div`
  cursor: pointer;
  position: relative;
  margin-bottom: 16px;
  & a {
    display: flex;
  }

  & img {
    margin-right: 16px;
  }
`;

export const StyledProfileView = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  background: ${colorTheme.primary};
  border-radius: 48px;
`;

export const StyledSideDrawerProfile = styled.div`
  & .flex1 {
    flex: 1 1 0%;
    min-width: 0;
  }

  & .overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledImage = styled.img`
  width: 48px;
  height: 48px;
  object-fit: cover !important;
  border-radius: 48px;
`;

export const StyledOverlay = styled.li`
  position: absolute;
  background: white;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0.5;
`;

interface StyledSideDrawerProps {
  open?: boolean;
  isMobile?: boolean;
  isAdmin?: boolean;
  isDefaultLanding?: boolean;
}

export const StyledSideDrawer = styled.nav<StyledSideDrawerProps>`
  height: 100%;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.1s ease-out;
  padding: 24px;

  ${props =>
    props.isDefaultLanding &&
    !props.isMobile &&
    css`
      position: unset !important;
    `}

  ${props =>
    !props.isMobile &&
    css`
      height: unset !important;
      top: ${props.isAdmin ? '0' : '66px !important'};
      bottom: 0;
      background: ${colorTheme.light};
    `}

  ${props =>
    props.open &&
    css`
      transform: translateX(0);
    `}

  & .logo-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  & .logo-wrapper img {
    height: 48px;
  }
`;
