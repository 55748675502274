import firebase from 'firebase/app';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const publicKey = process.env.REACT_APP_VAPID_KEY;

export const getToken = async (setTokenFound: (arg0: boolean) => void) => {
  let currentToken = '';

  try {
    if (firebase.messaging.isSupported()) {
      currentToken = await firebase.messaging().getToken({
        vapidKey: publicKey,
      });
      if (currentToken) {
        setTokenFound(true);
      } else {
        setTokenFound(false);
      }
    }
  } catch (error) {
    console.log('An error occurred while retrieving token. ', error);
  }

  return currentToken;
};
