import styled from 'styled-components';
import { colorTheme } from '../../../../core/configs';

export const StyledChatPage = styled.div`
  & .search-bar {
    padding: 16px 0;
  }

  & .page {
    padding: 8px 0;
  }

  & .avatar {
    width: 55px;
    height: 55px;
    border-radius: 30px;
  }

  & .tabs {
    height: 40px;
  }

  & .tab {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid ${colorTheme.lightGray};
  }

  & .tab:hover {
    background: ${colorTheme.lightGray};
  }

  & .active-tab {
    border-bottom: 1px solid ${colorTheme.dark};
  }
`;

export const StyledChatsComponent = styled.div``;

export const StyledFavoritesComponent = styled.div``;

export const StyledKawaysComponent = styled.div``;

interface ChatItemProps {
  isHomeowner?: boolean;
}

export const StyledGenericChatItem = styled.div<ChatItemProps>`
  padding: 0 24px;
  cursor: pointer;
  margin-bottom: 24px;

  :hover,
  :active {
    background-color: #f3f4f6;
  }

  & .profile-picture {
    width: 55px;
  }

  & .disabled-avatar {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    position: relative;
    background: ${colorTheme.darkFaded};
  }

  & .avatar {
    width: 55px;
    height: 55px;
    position: relative;
    background: ${colorTheme.primary};
  }

  & .avatar img {
    width: 55px;
    height: 55px;
    border-radius: 28px;
    object-fit: cover !important;
  }

  & .online-status {
    width: 12px;
    height: 12px;
    position: absolute;
    float: right;
    right: 1px;
    bottom: 1px;
    background: white;
    padding: 1px;
    border-radius: 6px;
  }

  & .bullet {
    padding: 0 4px;
  }

  & .flex1 {
    flex: 1 1 0%;
    min-width: 0;
  }

  & .overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledTable = styled.div`
  .table-container {
    min-height: 600px;
  }
  
  & table {
    border-top: 1px solid ${colorTheme.lightGray};
    border-bottom: 1px solid ${colorTheme.lightGray};
  }

  & table tbody tr {
    border-top: 2px solid ${colorTheme.lightGray};
  }

  & table tbody tr:not(:last-child) {
    border-bottom: 2px solid ${colorTheme.lightGray};
  }

  & table tr th {
    padding: 17px 14px;
  }

  & table tr td {
    padding: 14px;
  }

  & th {
    text-align: start;
  }

  & .pagination {
    margin-top: 24px;
  }

  & .pagination-control {
    min-width: 50px;
    min-height: 40px;
    border: 1px solid ${colorTheme.lightGray};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  & .selected {
    background-color: ${colorTheme.primary};
  }

  & .pagination-control.clickable,
  .pagination-control.nav-button {
    cursor: pointer;
  }

  & .pagination-control.clickable:hover,
  .pagination-control.nav-button:hover {
    background-color: ${colorTheme.lightGray};
  }

  & .row-dark {
    background-color: #F3F3F3;
  }
`;

export const StyledAdminChatsTabs = styled.div`
  margin-top: 24px;

  & .tab-control {
    border: solid 1px ${colorTheme.lightGray};
    background-color: ${colorTheme.light};
    padding: 8px;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .tab-control.active {
    color: white;
    background-color: ${colorTheme.primary};
  }
`;


