import Page from '../../../../core/components/page/Page';
import KazamProfileView from '../../../views/cards/kazam-profile/KazamProfileView';
import {Button, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {
  useGetMultipleAdminControl,
  useGetMyProfile,
} from '../../../server/react-query';
import {useHistory} from 'react-router-dom';
import {RoutePath} from '../../../navigation/config/RouteConfig';
import {localization} from '../../../localization/Localization';
import {useEffect} from 'react';
import {DurationContent} from '../../../server/types/admin-control.types';

const ProfileSetupPage = () => {
  const history = useHistory();
  const {data, isFetching} = useGetMyProfile();
  const {data: adminControlData} = useGetMultipleAdminControl([
    'ekycInitialReminder',
    'ekycSubsequentReminder',
  ]);

  useEffect(() => {
    const validAdminControlData = Array.isArray(adminControlData)
      ? adminControlData
      : [];

    if (validAdminControlData) {
      const ekycInitialReminderControls = validAdminControlData.filter(
        control => control.type === 'ekycInitialReminder' && control.enabled,
      );
      const ekycSubsequentReminderControls = validAdminControlData.filter(
        control => control.type === 'ekycSubsequentReminder' && control.enabled,
      );

      const ekycInitialReminderData =
        ekycInitialReminderControls.length > 0
          ? ekycInitialReminderControls[0].content
          : null;

      const ekycSubsequentReminderData =
        ekycSubsequentReminderControls.length > 0
          ? ekycSubsequentReminderControls[0].content
          : null;

      if (ekycInitialReminderData) {
        var initDuration = JSON.parse(
          ekycInitialReminderData.toLowerCase(),
        ) as DurationContent;

        localStorage.setItem(
          'initialEkycDuration',
          JSON.stringify({
            hours: initDuration.hours as number,
            days: 0,
          }),
        );

        localStorage.setItem(
          'isNewUser',
          'true'
        )
      }

      if (ekycSubsequentReminderData) {
        var subDuration = JSON.parse(
          ekycSubsequentReminderData.toLowerCase(),
        ) as DurationContent;

        localStorage.setItem(
          'subsequentEkycDuration',
          JSON.stringify({
            hours: 0,
            days: subDuration.days as number,
          }),
        );
      }
    }
  }, [adminControlData]);

  const editProfile = async () => {
    history.push(RoutePath.EDIT_PROFILE_SETUP);
  };

  const seeEmployers = async () => {
    localStorage.setItem('isNewKazam', 'true');
    // history.replace(RoutePath.MATCHES_LANDING);
    const path = RoutePath.TAKE_PERSONALITY_TEST + '?signUp=true';
    history.replace(path);
  };

  return (
    <Page
      showLoading={isFetching}
      showRightIcon
      rightIconName="edit"
      onRightActionClick={editProfile}>
      <div className="space-y-4">
        <Typography
          label={localization.hdr_my_profile}
          variant="f3"
          weight="semibold"
          align="center"
          color={colorTheme.dark}
        />
        <KazamProfileView profile={data} myProfile />
        <Button
          label={localization.btn_see_matched_ho}
          color="primary"
          onClick={seeEmployers}></Button>
      </div>
    </Page>
  );
};

export default ProfileSetupPage;
