import React, {ElementType, MouseEventHandler} from 'react';
import {StyledButton, StyledLoader} from './styled';
import {
  ButtonSize,
  ButtonVariant,
  ButtonColor,
  ButtonType,
} from './button.types';

interface BaseButtonProps {
  label: React.ReactNode;
  variant?: ButtonVariant;
  color?: ButtonColor;
  size?: ButtonSize;
  disabled?: boolean;
  className?: string;
  type?: ButtonType;
  /**
   * To be fixed
   */
  ref?: any;
  isLoading?: boolean;
  iconOnly?: boolean;
  borderWidth?: string;
  borderColor?: ButtonColor;
}

type HTMLButtonProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
} & BaseButtonProps;

type HTMLAnchorProps = {
  href?: string;
} & BaseButtonProps;

type CustomNodeProps = {
  as?: ElementType;
  to?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
} & BaseButtonProps;

export type ButtonProps = HTMLButtonProps & HTMLAnchorProps & CustomNodeProps;

/**
 * Add documentation
 * @param props
 * @param ref
 */
const Button: React.ForwardRefRenderFunction<unknown, ButtonProps> = (
  props,
  ref,
) => {
  const {
    variant = 'contained',
    size = 'default',
    color = 'default',
    className,
    label,
    disabled = false,
    type = 'button',
    onClick,
    href,
    as,
    to,
    isLoading = false,
    iconOnly = false,
    borderWidth = '2px',
    borderColor = 'light'
  } = props;

  const styles = {
    variant,
    size,
    color,
    disabled,
    isLoading,
    iconOnly,
    borderWidth,
    borderColor
  };

  if (as) {
    return (
      <StyledButton
        // ref={ref}
        as={as}
        to={to}
        onClick={onClick}
        className={className}
        {...styles}>
        {isLoading ? <StyledLoader {...styles} /> : label}
      </StyledButton>
    );
  }

  if (href) {
    return (
      <StyledButton
        // ref={ref as React.MutableRefObject<HTMLAnchorElement>}
        as={'a'}
        href={href}
        className={className}
        {...styles}>
        {isLoading ? <StyledLoader {...styles} /> : label}
      </StyledButton>
    );
  }

  return (
    <StyledButton
      // ref={ref as React.MutableRefObject<HTMLButtonElement>}
      as="button"
      type={type}
      className={className}
      onClick={onClick}
      {...styles}>
      {isLoading ? <StyledLoader {...styles} /> : label}
    </StyledButton>
  );
};

export default Button;
