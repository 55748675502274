import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';

export const StyledFinderSettings = styled.div`
  background: ${({ color }) => color || '#998CFF'};
  padding: 8px 16px;
  color: white;
  position: fixed;
  z-index: 2;
  height: 54px;

  ${isMobile
    ? css`
        top: 115px;
        left: 0;
        right: 0;
      `
    : css`
        top: 125px;
        left: 300px;
        right: 300px;
      `}
`;
