import format from 'date-fns/format';
import { UserResponse } from '../../../server/types';
import { ConversationContentMeta, JobOfferContentMeta } from '../../../server/types/job-offer.types';
import {StyledOfferCard} from './styles';
import JobOfferDetails from '../chat-job-offer-details/JobOfferDetails';

interface ChatJobOfferDeclinedProps {
  sender?: UserResponse
  recipient?: UserResponse
  meta: JobOfferContentMeta
  offer: ConversationContentMeta
  date: Date
}

const ChatJobOfferDeclined = (props: ChatJobOfferDeclinedProps) => {
  const senderIsHomeowner = props?.sender?.userRole === 'homeowner'
  return (
    <StyledOfferCard className="msg-right">
      <div className="offer-details ">
        <div className="text-center mb-2">
          <label className="txt-heading">OFFER DECLINED</label>
        </div>

        <div className="flex gap-1 mb-3">
          <span>{senderIsHomeowner 
            ? `${props?.recipient?.firstName} has declined your offer of employment.`
            : `You have declined ${props?.recipient?.firstName}’s offer of employment.`}</span>
        </div>

        {senderIsHomeowner && 
          <div className="flex gap-1 mb-2">
            <span>
                You can try offering an employment to other kasambahays available.
            </span>
          </div>
        }

        <JobOfferDetails offer={props.offer}/>

        <div className="text-right offer-msg-time">
          <span>{`${format(props.date, 'PP')} ${format(props.date, 'p')}`}</span>
        </div>
      </div>
    </StyledOfferCard>
  );
};

export default ChatJobOfferDeclined;
