import { BeatLoader } from 'react-spinners';
import {colorTheme} from '../../configs';
import Icon from '../icon';
import Typography from '../typography';

export interface LoadMoreProps {
  onClick: () => void;
  isFetching: boolean;
}

const LoadMore = (props: LoadMoreProps) => {
  const {onClick, isFetching} = props;

  return (
    <div className="flex space-x-2 justify-center align-center cursor-pointer">
      {isFetching ? 
        <BeatLoader
          color={colorTheme.primary}
          loading={isFetching}
          margin={2}
          size={15}
        /> : 
        <div className='flex p-1 space-x-1 justify-center items-center cursor-pointer' style={{
          backgroundColor: colorTheme.primary,
          width: '200px',
          borderRadius:'20px'
        }}
        onClick={onClick}>
          <div></div>
          <Icon className='mr-1' name="rotateCw" size={16} color={colorTheme.white} />
          <Typography
            label="Load previous messages"
            variant="f1"
            weight="semibold"
            color={colorTheme.white}
          />
        </div>
      }
    </div>
  );
};

export default LoadMore;
