import {addMonths, differenceInMonths, format} from 'date-fns';
import {useDialog} from '../../../../core/components/dialog';
import {useToast} from '../../../../core/components/toast';
import {useAuth} from '../../../hooks/useAuth';
import {useDeleteDocument} from '../../../server/react-query';
import GenericConfirmationDialog from '../../../views/dialog-content/generic-dialog/GenericConfirmationDialog';
import {StyledLabel, StyledLink, StyledLinkSelfie} from './styled';
import { usePatchRevokeVerifications } from '../../../server/react-query/useAdminUsers';
import { RoutePath } from '../../../navigation/config/RouteConfig';
import { useHistory } from 'react-router-dom';
import { colorTheme } from '../../../../core/configs';

interface LockedFieldsViewProps {
  onContinue?: () => void;
  isSelfieVerified?: boolean;
  type: 'default' | 'trueSelfieOnly'
}

const LockedFieldsView = (props: LockedFieldsViewProps) => {
  const history = useHistory();
  const {presentDialog, dismissDialog} = useDialog();
  const {presentToast} = useToast();
  const {authData} = useAuth();

  const showConfirmationDialog = () => {
    const userData = authData?.user;
    const lastVerifiedDate = userData?.lastVerifiedDate
      ? new Date(userData.lastVerifiedDate)
      : null;

    const lastTrueSelfieVerifiedDate = userData?.lastTrueSelfieVerifiedDate
      ? new Date(userData.lastTrueSelfieVerifiedDate)
      : null;
    const currentDate = new Date();

    if (lastVerifiedDate) {
      const monthsSinceLastVerification = differenceInMonths(
        currentDate,
        lastVerifiedDate,
      );

      if (monthsSinceLastVerification >= 6) {
        showConfirmDialog();
      } else {
        const monthsLeft = 6 - monthsSinceLastVerification;
        const nextEligibleDate = addMonths(lastVerifiedDate, 6);
        const formattedNextEligibleDate = format(
          nextEligibleDate,
          'MMMM dd, yyyy',
        );
        presentDialog({
          headerText: '',
          content: (
            <GenericConfirmationDialog
              headerText="Edit Profile"
              desc={`You can only edit your profile again in ${monthsLeft} months, on or after ${formattedNextEligibleDate}.`}
            />
          ),
          enableBackdropDismiss: false,
        });
      }
    } else if (lastTrueSelfieVerifiedDate) {
      const monthsSinceLastVerification = differenceInMonths(
        currentDate,
        lastTrueSelfieVerifiedDate,
      );

      if (monthsSinceLastVerification >= 6) {
        showConfirmDialog();
      } else {
        const monthsLeft = 6 - monthsSinceLastVerification;
        const nextEligibleDate = addMonths(lastTrueSelfieVerifiedDate, 6);
        const formattedNextEligibleDate = format(
          nextEligibleDate,
          'MMMM dd, yyyy',
        );
        presentDialog({
          headerText: '',
          content: (
            <GenericConfirmationDialog
              headerText="Edit Profile"
              desc={`You can only edit your profile again in ${monthsLeft} months, on or after ${formattedNextEligibleDate}.`}
            />
          ),
          enableBackdropDismiss: false,
        });
      }
    } else {
      showConfirmDialog();
    }
  };

  const showConfirmDialog = () => {
    presentDialog({
      headerText: '',
      content: (
        <GenericConfirmationDialog
          headerText="Edit ID Verified profile"
          desc={`Continuing to edit your profile will remove your ID verified tag ${
            props.isSelfieVerified ? 'and true selfie tag' : ''
          }. You will need to request verification again.`}
          rightButtonText="Continue"
          leftButtonText="Cancel"
          onLeftButtonAction={dismissDialog}
          onRightButtonAction={revokeVerifiedStatus}
          isLoading={deleteDocument.isLoading}
        />
      ),
      enableBackdropDismiss: false,
    });
  }

  const patchUseRevokeVerifications = usePatchRevokeVerifications();
  const deleteDocument = useDeleteDocument();

  const revokeVerifiedStatus = async () => {
    try {
      if (authData?.user?.userId) {
        // Only call delete documents from shufti if they are eKYC verified.
        if (
          authData.user.faceDocumentVerified ||
          authData.user.trueSelfieVerified
        ) {
          await deleteDocument.mutateAsync();
        }

        await patchUseRevokeVerifications.mutateAsync({
          userId: authData?.user?.userId,
          verifiedStatus: 'notVerified',
        });

        dismissDialog();
        props?.onContinue && props.onContinue();

        presentToast({
          message: `Successfully removed verified tags.`,
          variant: 'success',
          position: 'bottom',
        });
      }
    } catch (e) {
      console.log(e);
      presentToast({
        message: `Unexpected error was occurred.`,
        variant: 'error',
        position: 'bottom',
      });
    }
  };

  const confirmEditTrueSelfie = () => {
    const userData = authData?.user;
    const lastTrueSelfieVerifiedDate = userData?.lastTrueSelfieVerifiedDate
      ? new Date(userData.lastTrueSelfieVerifiedDate)
      : null;
    const currentDate = new Date();
    if (lastTrueSelfieVerifiedDate) {
      const monthsSinceLastVerification = differenceInMonths(
        currentDate,
        lastTrueSelfieVerifiedDate,
      );

      if (monthsSinceLastVerification >= 6) {
        showConfirmEditPhotoDialog()
      } else {
        const monthsLeft = 6 - monthsSinceLastVerification;
        const nextEligibleDate = addMonths(lastTrueSelfieVerifiedDate, 6);
        const formattedNextEligibleDate = format(
          nextEligibleDate,
          'MMMM dd, yyyy',
        );
        presentDialog({
          headerText: '',
          content: (
            <GenericConfirmationDialog
              headerText="Edit Profile Picture"
              desc={`You can only edit your profile picture again in ${monthsLeft} months, on or after ${formattedNextEligibleDate}.`}
            />
          ),
          enableBackdropDismiss: false,
        });
      }
    } else {
      showConfirmEditPhotoDialog()
    }
  }

  const showConfirmEditPhotoDialog = () => {
    presentDialog({
      headerText: '',
      content: (
        <GenericConfirmationDialog
          headerText="Edit True Selfie Verified Picture"
          desc="Continuing to edit your profile picture will remove your True Selfie tag."
          rightButtonText="Continue"
          leftButtonText="Cancel"
          onLeftButtonAction={dismissDialog}
          onRightButtonAction={revokeVerifiedStatus}
          isLoading={deleteDocument.isLoading}
        />
      ),
      enableBackdropDismiss: false,
    });
  }

  return (
    <div className="flex flex-col justify-center items-center">
      {props.type == "default" &&
        <>
          <StyledLabel>
            Your profile is ID Verified! Editing profile details like your name,
            photo, and birthday will reset your ID Verified status so we discourage
            making edits.
          </StyledLabel>
          <StyledLink onClick={showConfirmationDialog}>
            Edit ID Verified profile
          </StyledLink>
        </>
      }

      {props.type == "trueSelfieOnly" &&
        <>
          <StyledLabel>
            Your profile picture is locked since you're True Sefie Verified!
          </StyledLabel>
          <StyledLinkSelfie
            color={colorTheme.magenta}
            onClick={confirmEditTrueSelfie}>
            Edit True Selfie Verified picture
          </StyledLinkSelfie>
        </>
      }
      
    </div>
  );
};

export default LockedFieldsView;
