import Page from '../../../core/components/page/Page';
import {Button, Typography} from '../../../core/components';
import {colorTheme} from '../../../core/configs';
import logo from '../../../assets/KAZAM_logo_Transparent.svg';
import {useHistory, useLocation} from 'react-router-dom';
import {RoutePath} from '../../navigation/config/RouteConfig';
import { isMobile } from 'react-device-detect';

const UserVerificationQueuedPage = () => {
  const location = useLocation();
  const history = useHistory();

  const onProceed = async () => {
    history.push(RoutePath.MY_PROFILE);
  };;

  return (
    <>
      <Page showBackButton={false}>
      <div className="space-y-6">
        <div className='flex flex-col lg:justify-between'
          style={{
            minHeight: isMobile
              ? '100%'
              : '500px'
          }}>
          <div>
            <div className="flex flex-1">
              <Typography
                label="Thank you for your interest in getting your profile verified."
                variant="f3"
                align="center"
                weight='semibold'
                color={colorTheme.dark}
              />
            </div>
            <div className="pt-1">
              <Typography
                label="Please allow our verification team 2-3 working days to assess your submissions. You will be informed on the results via Kazam Admin Chat."
                variant="f2"
                align="center"
                color={colorTheme.darkFaded}
              />
            </div>
          </div>
          <div className='fixed bottom-6 left-6 right-6 lg:relative lg:w-full lg:left-0 lg:bottom-0'>
            <div>
              <div>
                <Button
                  label="Proceed"
                  color="primary"
                  onClick={onProceed}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      </Page>
    </>
  );
};

export default UserVerificationQueuedPage;
