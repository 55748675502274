import React, {useEffect, useState} from 'react';
import {StyledRoleComponent} from './styled';
import Typography from '../../../core/components/typography';
import {colorTheme} from '../../../core/configs';
import {Checkbox} from '../../../core/components';
import {Role} from '../../server/types';
import {useGetRoles} from '../../server/react-query';
import {localization} from '../../localization/Localization';

interface RoleComponentProps {
  value: string[];
  onChange?: (value: string[]) => void;
}

const RoleComponent = (props: RoleComponentProps) => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<string[]>(props.value);
  const [isValid, setIsValid] = useState<boolean>(true);

  const {data} = useGetRoles();

  useEffect(() => {
    setIsValid(selectedRoles.length > 0 && selectedRoles.length <= 3);
  }, [selectedRoles]);

  useEffect(() => {
    if (data) {
      setRoles(data);
    }
  }, [data]);

  useEffect(() => {
    setSelectedRoles(props.value);
  }, [props.value]);

  const selectedHandler = (value: string) => {
    setSelectedRoles(prevState => {
      let newState: string[] = [];

      if (prevState) {
        if (!prevState.includes(value)) {
          // Add
          newState = [...prevState, value];
        } else if (prevState) {
          // Remove
          newState = prevState.filter(i => i !== value);
        }
      } else {
        // Add
        newState = [value];
      }

      if (props.onChange) {
        props.onChange(newState || []);
      }
      return newState;
    });
  };

  return (
    <StyledRoleComponent>
      <Typography
        label={isValid ? localization.lbl_role : localization.lbl_role_max}
        color={!isValid ? colorTheme.danger : colorTheme.darkFaded}
        variant="f1"
        weight="semibold"
        className="pb-2"
      />

      <div className="space-y-4">
        {roles.map(role => (
          <div
            key={role.roleId}
            style={{width: 'fit-content'}}
            onClick={() => selectedHandler(role.roleId)}>
            <Checkbox
              className="checkbox"
              checked={selectedRoles.includes(role.roleId)}
              label={role.text}
            />
          </div>
        ))}
      </div>
    </StyledRoleComponent>
  );
};

export default RoleComponent;
