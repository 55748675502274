import React from 'react';
import {StyledDeletedAccountNoticeDialog} from './styled';
import deleted_acct from '../../../../assets/deleted_acct.svg';
import {Button, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {localization} from '../../../localization/Localization';

interface DeletedAccountNoticeDialogProps {
  onCancel: () => void;
  onContinue: () => void;
}

const DeletedAccountNoticeDialog = (props: DeletedAccountNoticeDialogProps) => {
  return (
    <StyledDeletedAccountNoticeDialog className="flex flex-col">
      <div className="self-center">
        <img
          width="153px"
          height="149px"
          src={deleted_acct}
          alt="banned-account"
        />
      </div>

      <div>
        <Typography
          label={localization.dlg_hdr_recover_profile}
          variant="f3"
          weight="semibold"
          align="center"
          color={colorTheme.dark}
        />
      </div>

      <div>
        <Typography
          label={localization.dlg_desc_recover_profile}
          color={colorTheme.darkFaded}
          align="center"
          variant="f1"
        />
      </div>

      <div className="flex flex-row w-full space-x-4">
        <Button
          label={localization.btn_cancel}
          variant="outlined"
          className="flex-1"
          onClick={props.onCancel}
        />
        <div className="spacer" />
        <Button
          label={localization.btn_continue}
          color="primary"
          className="flex-1"
          onClick={props.onContinue}
        />
      </div>
    </StyledDeletedAccountNoticeDialog>
  );
};

export default DeletedAccountNoticeDialog;
