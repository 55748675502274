import * as React from 'react';

function SvgXCircleGradient(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}>
      <path
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
        stroke="url(#x-circle-gradient_svg__paint0_linear)"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 9l-6 6"
        stroke="url(#x-circle-gradient_svg__paint1_linear)"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 9l6 6"
        stroke="url(#x-circle-gradient_svg__paint2_linear)"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="x-circle-gradient_svg__paint0_linear"
          x1={12}
          y1={2}
          x2={12}
          y2={22}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AD0082" />
          <stop offset={1} stopColor="#820000" stopOpacity={0.43} />
        </linearGradient>
        <linearGradient
          id="x-circle-gradient_svg__paint1_linear"
          x1={12}
          y1={9}
          x2={12}
          y2={15}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AD0082" />
          <stop offset={1} stopColor="#820000" stopOpacity={0.43} />
        </linearGradient>
        <linearGradient
          id="x-circle-gradient_svg__paint2_linear"
          x1={12}
          y1={9}
          x2={12}
          y2={15}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#AD0082" />
          <stop offset={1} stopColor="#820000" stopOpacity={0.43} />
        </linearGradient>
      </defs>
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgXCircleGradient);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
