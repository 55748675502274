import React, {useEffect, useState} from 'react';
import {colorTheme} from '../../../core/configs';
import {Icon, Typography} from '../../../core/components';
import { JobHistoryResponse, JobOfferMeta } from '../../server/types/job-offer.types';
import format from 'date-fns/format';
import kHouseIcon from '../../../assets/khouse-icon.svg';
import JobHistoryDialog from '../../views/dialog-content/job-history-modal/JobHistoryDialog';
import { useDialog } from '../../../core/components/dialog';
import { Edit } from '../../../core/assets/icons/components';
import { useToast } from '../../../core/components/toast';
import { TerminationReason } from '../../server/types/job-history.type';
import Rating from '../rating/Rating';
import { useAuth } from '../../hooks/useAuth';


interface KazamExperienceCardProps {
  data?: JobHistoryResponse
  isJobHistory?: boolean
  onUpdated?: () => void;
}

const KazamExperienceCard = ({data, isJobHistory, onUpdated} : KazamExperienceCardProps) => {
  const {authData} = useAuth();
  const isHomeowner = authData?.user?.userRole === 'homeowner'
  const [meta, setMeta] = useState<JobOfferMeta>();
  const [duration, setDuration] = useState<string>();
  const [roles, setRoles] = useState<string>();
  const {presentDialog, dismissDialog} = useDialog();
  const {presentToast} = useToast();

  useEffect(() => {
    if (data
      && data.meta) {
        var meta = JSON.parse(data.meta) as JobOfferMeta
        setMeta(meta)

        var roles = meta.Roles?.map(r => r.Role?.Text)
        setRoles(roles.join(","))
        
        const startDate = format(new Date(data.startDate), 'PP')
        let duration = `No show - ${startDate}`
        if (data.status != 'noShow') {
          const endDate = data?.endDate 
            ? (new Date() < new Date(data.endDate)
              && !data.terminationReason
              && data.status != 'ended')
              ? "Present" 
              : format(new Date(data.endDate), 'PP') 
            : "no date given"
          duration = `${startDate} - ${endDate}`
        }
        setDuration(duration)
      }
  },[data])

  const showError = (message: string) => {
    presentToast({
      message: message,
      variant: 'error',
      position: 'bottom',
    });
  };

  const showJobHistoryDialog = () => {
    presentDialog({
      headerText: '',
      enableBackdropDismiss: false,
      content: (
        <JobHistoryDialog 
          data={data}
          meta={meta}
          jobHistoryId={data?.jobHistoryId ?? ""}
          roles={roles ?? ''}
          onError={v => showError(v)}
          onUpdated={() => {
            onUpdated && onUpdated()
            dismissDialog()
          }}
        />
      ),
    });
} 

  return (
    <div className="card flex mt-3 gap-2">
      <div>
        {/* <Icon name="bgHome" size={42} color={colorTheme.dark} type="button" /> */}
        <img
          className='w-10 h-10'
          src={kHouseIcon}
          alt="kHouseIcon" />
      </div>
      <div>
        <div>
          <Typography
            label={isJobHistory
              ? isHomeowner
                ? data?.employeeName ?? ""
                : data?.employerName ?? ""
              : meta?.Location?.CityOrMunicipality ?? ""
            }
            variant="f2"
            weight="semibold"
            color={colorTheme.darkFaded}
          />
        </div>
        <div>
          <Typography
            label={roles ?? ""}
            variant="f1"
            weight="semibold"
            color={colorTheme.darkFaded}
          />
        </div>
        <div className="mb-1">
          <Typography
            label={duration ?? ""}
            variant="f1"
            weight="normal"
            color={colorTheme.darkFaded}
          />
        </div>
        {data?.employeeRating
          && data.employerRating
          && isJobHistory && 
          <div>
            <Rating
              rating={isHomeowner
                ? data.employerRating
                : data.employeeRating
              }
              readonly
            />
          </div>
        }
        
      </div>

      {isJobHistory && 
        <div className='flex flex-1 justify-end items-center' onClick={showJobHistoryDialog}>
          <Icon name='edit' color={colorTheme.accent} strokeWidth='1.5em'/>
        </div>
      }
      
    </div>
  );
};

export default KazamExperienceCard;
