import {useEffect} from "react";
import {useAnalytics} from "./useAnalytics";

export const useAnalyticsTrackComponent = (componentName: string) => {
  const {systemLogEvent} = useAnalytics();

  return useEffect(() => {
    systemLogEvent('component_enter', {
      name: componentName
    });

    return () => {
      systemLogEvent('component_leave', {
        name: componentName
      });
    };
  }, []);
};
