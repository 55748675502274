import React from 'react';
import {
  StyledMainPageWrapper,
  StyledMainWrapper,
  StyledPageContent,
  StyledPageWrapper,
  StyledSideBarWrapper,
} from './styled';
import {BeatLoader} from 'react-spinners';
import {colorTheme} from '../../configs';
import {IconName} from '../icon';
import SideBar from '../side-drawer/SideBar';

interface AdminPageProps {
  children: React.ReactNode;
  className?: string;
  hideNavbar?: boolean;
  unPadded?: boolean;
  showLoading?: boolean;
  showMenu?: boolean;
  showBackButton?: boolean;
  showRightIcon?: boolean;
  showRightText?: boolean;
  rightIconName?: IconName;
  rightText?: string;
  onBackClick?: () => void;
  onRightActionClick?: () => void;
  rightActionOutline?: boolean;
  rightActionIsLoading?: boolean;
  showFilterSettings?: boolean;
  chatRoom?: boolean;
}

const AdminPage = (props: AdminPageProps) => {
  return (
    <StyledMainWrapper chatRoom={props.chatRoom}>
      <StyledMainPageWrapper className="flex flex-row justify-center">
        <StyledSideBarWrapper>
          <SideBar sideDrawerOpen={true}></SideBar>
        </StyledSideBarWrapper>

        <StyledPageWrapper className={`
          ${props.className}
          ${props.unPadded ? '' : 'padding'}
          ${props.showLoading ? 'flex items-center justify-center' : ''}`}>
          {props.showLoading ? (
            <BeatLoader
              color={colorTheme.primary}
              loading={props.showLoading}
              margin={2}
              size={15}
            />
          ) : (
            <StyledPageContent 
              chatRoom={props.chatRoom}
              className='page-content'>
                {props.children}
            </StyledPageContent>
          )}
        </StyledPageWrapper>
      </StyledMainPageWrapper>
    </StyledMainWrapper>
  );
};

export default AdminPage;
