import styled, {keyframes} from 'styled-components';
import {colorTheme} from '../../configs';

const animateDialog = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
`;

export const StyledDialogContainer = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 200;

  background: rgba(0, 0, 0, 0.25);

  user-select: none;

  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  animation: ${animateDialog} ease-in-out 150ms;
`;

export const StyledDialog = styled.div`
  border-radius: 16px;
  width: 100%;
  background-color: ${colorTheme.white};
  position: relative;
`;

export const StyledDialogHeader = styled.div`
  display: flex;
  align-items: center;

  padding: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: ${colorTheme.darkFaded};
`;

export const StyledDialogContent = styled.div`
  /* padding: 1rem; */
  overflow: auto;
`;

export const StyledDialogFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding: 0.5rem 1rem;
`;

export const StyledAction = styled.div`
  & + & {
    margin-left: 0.5rem;
  }
`;
