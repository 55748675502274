import React, {useEffect, useState} from 'react';
import {
  DurationInput,
  QuantityInput,
  Typography,
} from '../../../../core/components';
import {StyledWrapper} from './styled';
import {colorTheme} from '../../../../core/configs';
import {usePutAdminControl} from '../../../server/react-query';
import {useToast} from '../../../../core/components/toast';
import {
  AdminControl,
  AdminControlReqBody,
  DurationContent,
  QuantityContent,
} from '../../../server/types/admin-control.types';

interface AdsSettingsViewProps {
  data: AdminControl[];
}

const AdsSettingsView = ({data}: AdsSettingsViewProps) => {
  const {presentToast} = useToast();

  const [enableDwellTime, setEnableDwellTime] = useState(false);
  const [isSavingTime, setIsSavingTime] = useState(false);
  const [isSavingFrequency, setIsSavingFrequency] = useState(false);

  const [adsDwellTime, setAdsDwellTime] = useState<DurationContent>();
  const [adsFrequency, setAdsFrequency] = useState<QuantityContent>();

  const putAdminControl = usePutAdminControl();

  useEffect(() => {
    if (data) {
      var enableDwellTime = data.find(d => d.type === 'adsDwellTime');
      if (enableDwellTime) {
        setEnableDwellTime(true);

        if (enableDwellTime.content) {
          var duration = JSON.parse(
            enableDwellTime?.content.toLowerCase(),
          ) as DurationContent;
          setAdsDwellTime(duration);
        }
      }

      var adsFrequency = data.find(d => d.type === 'adsFrequency');
      if (adsFrequency) {
        if (adsFrequency.content) {
          var quantity = JSON.parse(
            adsFrequency?.content.toLowerCase(),
          ) as QuantityContent;
          setAdsFrequency(quantity);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSaveDwellTime = async (value: DurationContent) => {
    try {
      setIsSavingTime(true);
      const data: AdminControlReqBody = {
        type: 'adsDwellTime',
        enabled: true,
        content: JSON.stringify(value),
      };

      await putAdminControl.mutateAsync(data);

      setIsSavingTime(false);
      presentToast({
        message: `Successfully saved.`,
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      setIsSavingTime(false);
      console.log(e);
    }
  };

  const onSaveAdsFrequency = async (value: QuantityContent) => {
    try {
      setIsSavingFrequency(true);
      const data: AdminControlReqBody = {
        type: 'adsFrequency',
        enabled: true,
        content: JSON.stringify(value),
      };

      await putAdminControl.mutateAsync(data);

      setIsSavingFrequency(false);
      presentToast({
        message: `Successfully saved.`,
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      setIsSavingFrequency(false);
      console.log(e);
    }
  };

  return (
    <StyledWrapper>
      <Typography
        label="ADS SETTINGS"
        variant="f1"
        weight="semibold"
        color={colorTheme.black}
      />

      <div>
        <div>
          <Typography
            className="pt-3"
            label="Ads dwell time"
            variant="f3"
            color={enableDwellTime ? colorTheme.dark : colorTheme.lightGray}
          />
          <Typography
            label="Set the length of time for ads before it can be closed. The default value is 5 seconds."
            variant="f1"
            color={
              enableDwellTime ? colorTheme.darkFaded : colorTheme.lightGray
            }
          />
        </div>

        {enableDwellTime && (
          <div className="pt-3">
            <DurationInput
              duration={adsDwellTime}
              onSubmit={onSaveDwellTime}
              isLoading={isSavingTime}
              minuteSeconds={true}
            />
          </div>
        )}
      </div>

      <div>
        <div>
          <Typography
            className="pt-3"
            label="Ads frequency"
            variant="f3"
            color={colorTheme.dark}
          />
          <Typography
            label="Set the frequency or number of profiles viewed before another splash ad will show."
            variant="f1"
            color={colorTheme.darkFaded}
          />
        </div>

        {enableDwellTime && (
          <div className="pt-3">
            <QuantityInput
              quantity={adsFrequency}
              onSubmit={onSaveAdsFrequency}
              isLoading={isSavingFrequency}
            />
          </div>
        )}
      </div>
    </StyledWrapper>
  );
};

export default AdsSettingsView;
