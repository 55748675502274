import {AxiosRequestConfig} from 'axios';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';
import {convertJsonToParams} from '../../utils/query.util';
import {PaginatedData} from '../types/pagination.type';
import {
  DeleteDocumentRequest,
  DeleteDocumentResponse,
  UserVerification,
  UserVerificationMismatchProfileBody,
  UserVerificationResponse,
} from '../types/user.verification.types';
import {UserResponse} from '../types';

const postVerifyDocument = () => {
  return API.post<UserVerificationResponse>(
    ApiEndpointsEnum.POST_VERIFY_DOCUMENT,
  );
};

const usePostVerifyDocument = () => {
  const queryClient = useQueryClient();

  return useMutation(['post-verify-document'], () => postVerifyDocument(), {
    onSuccess: () => {
      queryClient.invalidateQueries(['post-verify-document']);
    },
  });
};

const deleteDocument = () => {
  return API.del<DeleteDocumentResponse>(ApiEndpointsEnum.DELETE_DOCUMENT);
};

const useDeleteDocument = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['delete-document'],
    () => deleteDocument(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['delete-document']);
      },
    },
  );
};

const postVerifyFacial = () => {
  return API.post<UserVerificationResponse>(
    ApiEndpointsEnum.POST_VERIFY_FACIAL,
  );
};

const usePostVerifyFacial = () => {
  const queryClient = useQueryClient();

  return useMutation(['post-verify-facial'], () => postVerifyFacial(), {
    onSuccess: () => {
      queryClient.invalidateQueries(['post-verify-facial']);
    },
  });
};

const postVerifyFacialDocument = () => {
  return API.post<UserVerificationResponse>(
    ApiEndpointsEnum.POST_VERIFY_FACIAL_DOCUMENT,
  );
};

const usePostVerifyFacialDocument = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['post-verify-facial-document'],
    () => postVerifyFacialDocument(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['post-verify-facial-document']);
      },
    },
  );
};

const getVerificationStatus = (userVerificationId: string) => {
  const url = ApiEndpointsEnum.GET_VERIFICATION_STATUS.replace(
    '{id}',
    userVerificationId,
  );
  return API.get<UserVerification>(url);
};

const useGetVerificationStatus = (userVerificationId: string) => {
  return useQuery(['get-verification-status', userVerificationId], () =>
    getVerificationStatus(userVerificationId),
  );
};

const patchMismatchUserProfile = (
  requestBody: UserVerificationMismatchProfileBody,
) => {
  return API.patch<UserResponse>(
    ApiEndpointsEnum.PATCH_MISMATCH_USER_PROFILE,
    requestBody,
  );
};

const usePatchMismatchUserProfile = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['patch-mismatch-user-profile'],
    (requestBody: UserVerificationMismatchProfileBody) =>
      patchMismatchUserProfile(requestBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['patch-mismatch-user-profile']);
      },
    },
  );
};

export {
  usePostVerifyDocument,
  usePostVerifyFacial,
  usePostVerifyFacialDocument,
  useGetVerificationStatus,
  usePatchMismatchUserProfile,
  useDeleteDocument,
};
