import { useEffect, useState } from 'react';
import { JobOfferMeta, ConversationContentMeta } from '../../../server/types/job-offer.types';
import format from 'date-fns/format';
import { toNumberWithCommas } from '../../../utils/decimal.util';
import { getLivingArrangementValue } from '../../../utils/job-offer.util';
import { UserResponse } from '../../../server/types';

interface JobOfferDetailsProps {
  offer: ConversationContentMeta
}

const JobOfferDetails = ({
  offer,
}: JobOfferDetailsProps) => {
  const [offerMeta, setOfferMeta] = useState<JobOfferMeta>()
  const [roles, setRoles] = useState<string>();

  useEffect(() => {
    if (offer
      && offer.Meta) {
      var meta = JSON.parse(offer.Meta) as JobOfferMeta
      setOfferMeta(meta)

      var roles = meta.Roles?.map(r => r.Role?.Text)
      setRoles(roles.join(", "))
    }
  }, [offer])

  
  return (
    <>
      <div className="flex gap-1">
        <span className="txt-heading">Start Date:</span>
        <span>{offer?.StartDate ? format(new Date(offer.StartDate), 'PP') : ""}</span>
      </div>

      <div className="flex gap-1">
        <span className="txt-heading">End Date:</span>
        <span>{offer?.EndDate ? format(new Date(offer.EndDate), 'PP') : "No date given"}</span>
      </div>

      <div className="flex gap-1">
        <span className="txt-heading">Hiring Address:</span>
        <span>
          {offerMeta?.Location?.HasProvince 
            ? `${offerMeta?.Location?.CityOrMunicipality}, ${offerMeta?.Location?.Province?.Name}`
            : offerMeta?.Location?.CityOrMunicipality}
        </span>
      </div>

      <div className="flex gap-1">
        <span className="txt-heading">Salary per month:</span>
        <span>{`PHP ${toNumberWithCommas(offerMeta?.Salary ?? 0)}`}</span>
      </div>

      <div className="flex gap-1">
        <span className="txt-heading flex-none">Living Arrangement:</span>
        <span className='flex-initial text-wrap'>{getLivingArrangementValue(offerMeta?.LivingArrangement)}</span>
      </div>

      <div className="flex gap-1">
        <span className="txt-heading">Role:</span>
        <span>{roles}</span>
      </div>
    </>
  );
};

export default JobOfferDetails;
