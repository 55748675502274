import {Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import profilesSvg from '../../../../assets/img_I_Am_Homeowner.svg';
import {StyledImg} from './styles';
import { BeatLoader } from 'react-spinners';


const FetchingProfilesView = () => {
  return (
    <div className="flex flex-col items-center p-4 space-y-4">
      <StyledImg src={profilesSvg} alt="Kaway"></StyledImg>
      <div className="space-y-2">
        <Typography
          label="Please wait while we're fetching your matches..."
          align="center"
          weight="semibold"
          variant="f3"
          color={colorTheme.dark}
        />
        <div className='flex items-center justify-center'>
          <BeatLoader
              color={colorTheme.primary}
              loading
              size={15}
            />
        </div>
        
      </div>
    </div>
  );
};

export default FetchingProfilesView;
