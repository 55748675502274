import { UserRole, VerifiedStatus } from "./user.types";
import { userVerificationStatus } from "./user.verification.types";

export type AdminControlType =
  | 'enableKaway'
  | 'enableKawaySchedule'
  | 'allowHiddenKazamForKaway'
  | 'hiddenKazamDuration'
  | 'onlineStatusThreshold'
  | 'offlineStatusThreshold'
  | 'genericChatMessage'
  | 'paywallControl'
  | 'adsDwellTime'
  | 'adsFrequency'
  | 'referralText'
  | 'emailNotificationInterval'
  | 'searchMatchRecentActivityThresholds'
  | 'smsNotificationInterval'
  | 'newHomeownerChatSmsNotification'
  | 'newAdminChatSmsNotification'
  | 'newMessageToKazamSmsNotification'
  | 'newMessageToHomeownerSmsNotification'
  | 'salaryLowerLimit'
  | 'salaryUpperLimit'
  | 'hiredLockDuration'
  | 'blogData'
  | 'newHomeownerCallSmsNotification'
  | 'ekycInitialReminder'
  | 'ekycSubsequentReminder';

export type MatchAttributeType = 'location' | 'employment' | 'salary' | 'role' | 'personalityMatch';

export type AdvertisementType = 'splash' | 'banner';

export type AdvertisementTargetType = 'all' | 'kazam' | 'homeowner';

export type AdvertisementLinkType = 'outbound' | 'inbound';

export type AdvertisementStatus = 'active' | 'expired' | 'upcoming';

export const inboundLinks: {[key in AdvertisementTargetType]: string[]} = {
  all: [
    'about-us',
    'chat',
    'notifications',
    'profile',
    'privacy-policy',
    'settings',
    'terms-and-conditions',
    'verification'
  ],
  kazam: [
    'about-us',
    'chat',
    'notifications',
    'profile',
    'privacy-policy',
    'settings',
    'terms-and-conditions',
    'verification'
  ],
  homeowner: [
    'about-us',
    'chat',
    'favorites',
    'kaways',
    'notifications',
    'profile',
    'privacy-policy',
    'settings',
    'terms-and-conditions',
    'verification'
  ]
}


export interface AdminControl {
  adminControlId: string;
  enabled: boolean;
  type: AdminControlType;
  content: string;
  text: string;
}

export interface AdminControlReqBody {
  type: AdminControlType;
  enabled: boolean;
  content?: string;
  text?: string;
}

export interface DurationContent {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export interface BlogDataContent {
  title: string;
  description: string;
  imageUrl: string;
  url: string;
}

export interface RecentActivityThreshold {
  tier1: DurationContent;
  tier2: DurationContent;
  tier3: DurationContent;
}

export interface QuantityContent {
  value: number;
}

export interface PaywallContent {
  freeChats: number;
  defaultStartDate?: string;
}

export interface SearchWeightParams {
  locationWeighValue: number;
  employmentWeightValue: number;
  salaryWeightValue: number;
  roleWeightValue: number;
  personalityMatchWeightValue: number;
}

export interface MatchingAttributeResponse {
  matchAttributeId: string;
  attributeType: MatchAttributeType;
  weightValue: number;
}

export interface PostAdBody {
  name: string;
  outboundLink?: string;
  image?: any | null;
  type: AdvertisementType;
  targetType: AdvertisementTargetType;
  linkType: AdvertisementLinkType;
  startDate: Date;
  endDate: Date;
  featured: boolean
  includeInLoop: boolean
}

export interface AdvertisementResponse {
  advertisementId: string;
  name: string;
  outboundLink?: string;
  imageUrl: string;
  type: AdvertisementType;
  linkType: AdvertisementLinkType;
  targetType: AdvertisementTargetType;
  status: AdvertisementStatus;
  impressions: number;
  startDate: Date;
  endDate: Date;
  createdOn: Date;
  featured: boolean
  includeInLoop: boolean
}

export interface GetAdsParams {
  searchKey?: string;
  type: AdvertisementType;
  pageNumber?: number;
  pageSize?: number;
}

export interface PostBroadcastBody {
  readOnly: boolean;
  userIds: string[];
  fileName: string;
  title: string;
  message: string;
}

export interface UserBroadcastResponse {
  userId: string;
  status: string;
  response: string;
}

export interface BroadcastResponse {
  broadcastId: string;
  fileName: string;
  title: string;
  message: string;
  readOnly: boolean;
  successfullySent?: UserBroadcastResponse[];
  failedToSend?: UserBroadcastResponse[];
  createdOn: Date;
}

export interface PostReferralLinkBody {
  name: string;
  code: string;
  type: string;
}

export interface ReferralLinkResponse {
  referralLinkId: string;
  name: string;
  code: string;
  type: string;
  createdOn?: Date;
  modifiedOn?: Date;
}

export interface PaginatedChatsQueryParams {
  searchQuery: string | undefined,
  sortBy: string | undefined,
  sortOrder: string | undefined,
  roles: string | undefined,
  page: number,
  userRole: UserRole
}

export interface PatchUserVerificationStatusBody {
  userId: string
  verifiedStatus: VerifiedStatus
}

export interface PatchRevokeVerificationStatusBody {
  userId: string
  userVerificationStatus: userVerificationStatus
}

export interface PaginatedUsersQueryParams {
  searchQuery: string | undefined,
  page: number
}

