import React, {useCallback, useEffect, useState} from 'react';
import {ProfileStatus} from '../../../core/components';
import {StatusVariant} from '../../../core/components/profile-status';
import {useGetOnlineStatus} from '../../server/react-query';
import {useQueryClient} from 'react-query';
import {ONLINE_STATUS_PULL_INTERVAL} from '../../utils/online-status.util';

interface WrappedProfileStatusProps {
  userId: string;
}

const WrappedProfileStatus = (props: WrappedProfileStatusProps) => {
  const [statusValue, setStatusValue] = useState<StatusVariant>('offline');
  const [lastOnline, setLastOnline] = useState<string>('');
  const [intervalRef, setIntervalRef] = useState<NodeJS.Timeout | undefined>(
    undefined,
  );

  const {userId} = props;

  const queryClient = useQueryClient();
  const {data} = useGetOnlineStatus(userId);

  useEffect(() => {
    return () => intervalRef && clearInterval(intervalRef);
  }, [intervalRef]);

  useEffect(() => {
    if (data) {
      setStatusValue(data.status);
      setLastOnline(data.lastOnline);
    }
  }, [data]);

  const fetchData = useCallback(() => {
    const intervalRef = setInterval(async () => {
      await queryClient.fetchQuery(`get-online-status-${userId}`);
    }, ONLINE_STATUS_PULL_INTERVAL);
    setIntervalRef(intervalRef);
  }, [queryClient, userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <ProfileStatus
      status={statusValue}
      lastSeen={lastOnline ? lastOnline + ' ago' : ''}
    />
  );
};

export default WrappedProfileStatus;
