import React, {useEffect, useState} from "react";
import {Role} from "../../server/types";
import {useGetRoles} from "../../server/react-query";
import {Checkbox} from "../../../core/components";

interface RolesSelectionComponentProps {
  values: string[];
  onChange?: (_: string[]) => void;
  className?: string;
}

const RolesSelectionComponent = (props: RolesSelectionComponentProps) => {
  const {values, onChange, className} = props;

  const [roles, setRoles] = useState<Role[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<string[]>(values);

  const {data} = useGetRoles();

  useEffect(() => {
    if (values) {
      setSelectedRoles(values);
    }
  }, [values]);

  useEffect(() => {
    if (data) {
      setRoles(data);
    }
  }, [data]);

  const selectedHandler = (roleId: string) => {
    let newData: string[];
    if (selectedRoles.find(i => i === roleId)) {
      // Remove
      newData = [...selectedRoles.filter(i => i !== roleId)];
    } else {
      // Add
      newData = Array.from(new Set(selectedRoles.concat(roleId)));
    }

    setSelectedRoles(newData);

    onChange && onChange(newData);
  };

  return (
    <div className={["space-y-4", className || ''].join(' ')}>
      {roles.map(role => (
        <div
          key={role.roleId}
          style={{width: 'fit-content'}}
          onClick={() => selectedHandler(role.roleId)}>
          <Checkbox
            className="checkbox"
            checked={selectedRoles.includes(role.roleId)}
            label={role.text}
          />
        </div>
      ))}
    </div>
  );
};

export default RolesSelectionComponent;
