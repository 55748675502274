export enum ApiEndpointsEnum {
  POST_SIGN_UP = '/api/auth/sign-up',
  POST_SIGN_IN = '/api/auth/sign-in',
  POST_SIGN_IN_ADMIN = '/api/auth/sign-in/admin',
  POST_OTP_REQUEST = '/api/auth/otp/request',
  POST_OTP_VERIFY = '/api/auth/otp/verify',
  POST_PASSWORD_RESET_REQUEST = '/api/auth/password-reset/request',
  POST_PASSWORD_RESET_VERIFY = '/api/auth/password-reset/verify',
  PUT_PASSWORD_RESET = '/api/auth/password-reset',
  PUT_RECOVER_ACCOUNT = '/api/auth/recover-account',
  GET_WEATHER_FORECAST = '/api/weatherForecast',
  GET_TAC = '/api/terms-and-conditions',
  GET_LATEST_VERSION_INFO = '/api/version-info/latest',
  GET_FILTER_SETTINGS = '/api/filtersetting',
  PUT_FILTER_SETTINGS = '/api/filtersetting',
  POST_FILTER_SETTINGS = '/api/filtersetting',
  GET_LOCATIONS = '/api/location',
  GET_PROVINCES = '/api/province',
  GET_SEARCH_LOCATIONS = 'api/location/search',
  GET_PRIVACY_POLICY = '/api/privacy-policy',
  GET_CONVERSATION = '/api/conversation/channel',
  GET_PAGINATED_CONVERSATIONS = '/api/conversation/channel/paginated',
  POST_CONVERSATION = '/api/conversation/channel',
  PUT_CONVERSATION_STATUS = '/api/conversation/channel/{id}/status',
  GET_CONVERSATION_BY_ID = '/api/conversation/channel/{id}',
  GET_CONVERSATION_CONTENTS = '/api/conversation/channel/{id}/contents',
  POST_MARK_ALL_MESSAGES_AS_READ = '/api/conversation/channel/{id}/markallmessagesasread',
  POST_MARK_CONVERSATION_CONTENT_AS_READ = '/api/conversation/content/markasread',
  GET_UNREAD_CONVERSATIONS = '/api/conversation/channel/hasunreadmessages',
  PUT_CONVERSATION_CONTENT = '/api/conversation/content/{id}/meta',
  GET_LATEST_CONVERSATION_CONTENT = '/api/conversation/content/{id}/latest',
  POST_CURRENT_LOCATION = '/api/conversation/content/{id}/location',
  GET_CONVERSATION_CONTENT = '/api/conversation/content/{id}',
  GET_FAVORITE = '/api/tag/favorite',
  POST_FAVORITE = '/api/tag/favorite',
  DELETE_FAVORITE = '/api/tag/favorite/{id}',
  GET_HIDES = '/api/tag/hide',
  GET_KAWAYS = '/api/tag/kaway',
  POST_HIDE = '/api/tag/hide',
  POST_ONLINE_STATUS = '/api/userlog/onlinestatus',
  GET_ONLINE_STATUS = '/api/userlog/onlinestatus/{userId}',
  DELETE_HIDE = '/api/tag/hide/{id}',
  POST_KAWAY = '/api/tag/kaway',
  GET_ROLES = '/api/roles',
  GET_PROFILE = '/api/profile',
  PUT_PROFILE = '/api/profile',
  PUT_PROFILE_NEW_PASSWORD = '/api/profile/new-password',
  PATCH_PROFILE = '/api/profile',
  PATCH_PROFILE_FCM_TOKEN = '/api/profile/fcm/token',
  PATCH_PROFILE_ACCEPT_DISCLAIMER = '/api/profile/accept/disclaimer',
  PATCH_PROFILE_ACCEPT_TERMS = '/api/profile/accept/terms',
  PATCH_PROFILE_ACCEPT_PRIVACY_POLICY = '/api/profile/accept/privacy-policy',
  PATCH_PROFILE_STATUS = '/api/profile/status',
  PUT_PROFILE_PHOTO = '/api/profile/photo',
  GET_USER_BY_ID = '/api/user/{id}',
  GET_MATCHES = '/api/match',
  GET_MATCH_BY_ID = '/api/match/{id}',
  IS_MOBILE_EXISTING = '/api/auth/check-number',
  GET_NOTIFICATIONS = '/api/notification',
  GET_UNREAD_NOTIFICATIONS = '/api/notification/unread',
  POST_READ_NOTIFICATIONS = '/api/notification/read',
  PATCH_PROFILE_LOCATION = '/api/profile/location',

  GET_ADMIN_CONTROLS = '/api/admincontrol',
  GET_ADMIN_CONTROl = '/api/admincontrol/type',
  GET_MULTIPLE_ADMIN_CONTROl = '/api/admincontrol/types',
  PUT_ADMIN_CONTROL = '/api/admincontrol',
  GET_ADMIN_MATCH_ATTRIBUTES = '/api/admincontrol/match/attribute',
  PUT_ADMIN_MATCH_ATTRIBUTES = '/api/admincontrol/match/attribute',
  POST_AD = '/api/advertisement',
  GET_ADS = '/api/advertisement',
  DELETE_AD = '/api/advertisement/{id}',
  PUT_AD = '/api/advertisement/{id}',
  GET_AD = '/api/advertisement/{id}',
  GET_RANDOM_BANNER_AD = '/api/advertisement/banner',
  GET_RANDOM_SLPASH_AD = '/api/advertisement/splash',
  GET_FEATURED_SPLASH_AD = '/api/advertisement/splash/featured',

  POST_ABUSE_REPORT = '/api/abusereport',
  GET_ABUSE_REPORT = '/api/abusereport',
  GET_ABUSE_REPORTERS = '/api/abusereport/reporters/{userId}',

  POST_USE_REFERRAL_CODE = '/api/referral/use',
  GET_REFERRAL_SUMMARY = '/api/referral/summary',
  GET_REFERRAL_STATISTICS = '/api/referral/statistics',
  GET_REFERRAL_TRANSACTIONS = '/api/referral/transactions',
  GET_REFERRAL_BY_USER = '/api/referral/by-user/{id}',
  GET_REFERRAL_BY_ADMIN_GENERATED = '/api/referral/by-admin/{id}',
  GET_REFERRAL_INFO = '/api/referral/info/{referralCode}',
  POST_REFERRAL_LINK = '/api/referral/link',
  GET_REFERRAL_LINK = '/api/referral/link/{id}',
  PUT_REFERRAL_LINK = '/api/referral/link/{id}',
  DELETE_REFERRAL_LINK = '/api/referral/link/{id}',

  POST_BAN_USER = '/api/user/ban',
  POST_UNBAN_USER = '/api/user/unban',
  GET_BANNED_USERS = '/api/user/banned',
  GET_USERS = '/api/user',
  GET_MULTIPLE_USERS = '/api/user/multiple',
  POST_SUSPEND_USER = '/api/user/suspend',
  POST_UNSUSPEND_USER = '/api/user/unsuspend',
  GET_SUSPENDED_USERS = '/api/user/suspended',
  GET_USERS_WITH_VERIFIED_STATUS = '/api/user/verification/status',
  GET_USERS_WITH_EKTC_VERIFIED_STATUS = '/api/user/verification/ekyc-users',
  DELETE_DOCUMENT_AS_ADMIN = 'api/UserVerification/delete-document-admin',
  PATCH_MANUAL_VERIFICATION = 'api/user/manual-verification',
  PATCH_REVOKE_MANUAL_VERIFICATION = 'api/user/revoke-manual-verification',
  PATCH_REVOKE_VERIFICATIONS = '/api/user/revoke-verifications',
  PATCH_REVOKE_EKYC_VERIFICATIONS = '/api/user/revoke-ekyc-verifications',


  POST_JOB = '/api/job',

  GET_SYSTEM_STATISTICS = '/api/system-statistics',

  POST_MEDIA = '/api/conversation/content/{id}/media',

  POST_FCM_TOKEN = '/api/fcmtoken',
  DEL_FCM_TOKEN = '/api/fcmtoken/{id}',

  POST_BROADCAST = '/api/broadcast',

  POST_CHECKOUT = '/api/paywall/checkout',
  GET_CHECKOUT = '/api/paywall/checkout',

  GET_SUBSCRIPTIONS = '/api/subscription',
  POST_SUBSCRIPTION = '/api/subscription',
  PUT_SUBSCRIPTION = '/api/subscription/{id}',
  GET_SUBSCRIPTION = '/api/subscription/{id}',
  DELETE_SUBSCRIPTION = '/api/subscription/{id}',

  POST_TRIAL_SUBSCRIPTION = '/api/usertrialsubscription',
  PUT_ACTIVE_TRIAL_SUBSCRIPTION = '/api/usertrialsubscription/active',
  GET_ACTIVE_TRIAL_SUBSCRIPTION = '/api/usertrialsubscription/active',
  GET_AVAILABLE_SUBSCRIPTIONS = '/api/subscription/available',

  GET_USER_SUBSCRIPTION = '/api/usersubscription/{id}',
  GET_ACTIVE_SUBSCRIPTION = '/api/usersubscription/active',
  GET_LATEST_SUBSCRIPTION = '/api/usersubscription/latest',
  GET_SUBSCRIPTION_HISTORY = '/api/usersubscription/history',

  POST_CALL_SESSION = '/api/callsession',

  POST_JOB_OFFER = '/api/joboffer',
  PUT_JOB_OFFER = '/api/joboffer/{id}',
  GET_JOB_OFFER = '/api/joboffer/{id}',
  PUT_JOB_OFFER_STATUS = '/api/joboffer/{id}/status',

  GET_JOB_HISTORY = '/api/jobhistory',
  PUT_JOB_HISTORY = '/api/jobhistory/{id}',


  POST_VOUCHER = '/api/voucher',
  GET_VOUCHERS = '/api/voucher',
  GET_VOUCHER = '/api/voucher/{id}',
  PUT_VOUCHER = '/api/voucher/{id}',
  DELETE_VOUCHER = '/api/voucher/{id}',
  POST_VOUCHER_REDEEM = '/api/voucher/redeem',

  PUT_PERSONALITY_MATCH = '/api/personalitymatch',
  GET_PERSONALITY_MATCH = '/api/personalitymatch',

  POST_VERIFY_DOCUMENT = '/api/UserVerification/verify-document',
  POST_VERIFY_FACIAL = '/api/UserVerification/verify-facial',
  POST_VERIFY_FACIAL_DOCUMENT = '/api/UserVerification/verify-facial-document',
  GET_VERIFICATION_STATUS = 'api/UserVerification/{id}/status',
  PATCH_MISMATCH_USER_PROFILE = '/api/UserVerification',
  DELETE_DOCUMENT = 'api/UserVerification/delete-document',
}
