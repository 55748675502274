import {useEffect, useState} from 'react';
import {Typeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {LocationResponse} from '../../../app/server/types';
import {colorTheme} from '../../configs';
import {StyledInputLabel, StyledWrapper} from './styled';

export type TypeheadProps = {
  items: LocationResponse[];
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  error?: string;
  selected?: LocationResponse;
  onChange?: (value?: LocationResponse[]) => void;

  /**
   * To be fixed
   */
  ref?: any;
};

const Typehead = (props: TypeheadProps) => {
  const {items, label, placeholder, selected, error, onChange} = props;
  const [selectedLocation, setSelectedLocation] = useState<LocationResponse[]>(
    [],
  );

  useEffect(() => {
    if (onChange) {
      onChange(selectedLocation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  useEffect(() => {
    if (selected) {
      setSelectedLocation([selected]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <StyledWrapper error={error}>
      {label && (
        <StyledInputLabel data-testid="label-element">{label}</StyledInputLabel>
      )}
      <Typeahead
        id="basic-typeahead-single"
        labelKey={option =>
          option.hasProvince
            ? `${option.cityOrMunicipality}, ${option.province.name}`
            : `${option.cityOrMunicipality}`
        }
        placeholder={placeholder}
        options={items}
        onChange={setSelectedLocation}
        selected={selectedLocation}
      />

      {error && (
        <div
          data-testid="error-msg-element"
          style={{
            marginTop: '0.25rem',
            fontSize: '0.75rem',
            lineHeight: '1.125rem',
            color: colorTheme.danger,
          }}>
          {error}
        </div>
      )}
    </StyledWrapper>
  );
};

export default Typehead;
