import styled from 'styled-components';
import {colorTheme} from '../../../../core/configs';

export const SyledContent = styled.div`
  width: 100%;
`;

export const StyledTargetComponent = styled.div`
  & .radio-button {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
`;

export const StyledLabel = styled.div`
  margin-bottom: 0.25rem;

  font-size: 0.8rem;
  font-weight: 600;
  line-height: 16.8px;

  color: ${colorTheme.darkFaded};
`;
