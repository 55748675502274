import Page from '../../../core/components/page/Page';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './sliderStyles.css';
import {useHistory} from 'react-router-dom';
import {RoutePath} from '../../navigation/config/RouteConfig';
import {localization} from '../../localization/Localization';
import {useAnalytics} from '../../hooks/useAnalytics';
import {useLocation} from 'react-router';
import {useEffect, useRef, useState} from 'react';
import { getAppBannerUrl } from '../../utils/random.util';
import { isMobile } from 'react-device-detect';
import Home from './Home/HomeSection';
import AboutUs from './AboutUs/AboutUsSection';
import Blogs from './Blog/BlogSection';
import AsSeenOn from './SeenOn/AsSeenOnSection';
import HowTheAppWorks from './HowTheAppWorks/HowTheAppWorksSection';
import Footer from './FooterSection/Footer';
import SuccessStories from './stories/StoriesSection';
import GetAppCard from '../../views/sticky-cards/get-app/GetAppCard';

const LandingPage = () => {
  const history = useHistory();
  const {systemLogEvent} = useAnalytics();

  const scrollToElement = useRef<HTMLDivElement>(null);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [referralCode] = useState<string>(params.get('referralCode') || '');
  const [showGetAppBanner, setShowGetAppBanner] = useState<boolean>(false);
  const [appUrl, setAppUrl] = useState<string>('');

  useEffect(() => {
    const appUrl = getAppBannerUrl();
    setAppUrl(appUrl);
    if (appUrl) {
      setShowGetAppBanner(true);
    } else {
      setShowGetAppBanner(false);
    }

  }, []);

  useEffect(() => {
    if (referralCode) {
      localStorage.setItem('referralCode', referralCode);
    }
  }, [referralCode]);

  

  const goToSignIn = () => {
    systemLogEvent('non_user_clicked_sign_in');
    history.push(RoutePath.SIGN_IN);
  };

  const scrollToHome = () => {
    if (scrollToElement.current) {
      scrollToElement.current.scrollIntoView({ block: "end", inline: "nearest", behavior: 'smooth' });
    }
  };

  return (
    <>
      <Page
        showMenu={isMobile}
        isLanding
        showRightText
        rightText={localization.btn_sign_in}
        rightActionOutline
        onRightActionClick={goToSignIn}>
          <div className='relative justify-self-center self-center items-center'>
            {showGetAppBanner && 
              <div>
                <GetAppCard 
                  appUrl={appUrl}
                  onClickNotNow={() => setShowGetAppBanner(false)}/>
            </div>}
            <Home ref={scrollToElement}/>
            <AboutUs />
            <SuccessStories />
            <HowTheAppWorks />
            <Blogs />
            <AsSeenOn />
            <Footer onClick={scrollToHome}/>
          </div>
      </Page>
    </>
  );
};

export default LandingPage;
