import {useMutation, useQuery, useQueryClient} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';
import { JobOfferResponse, PostJobOfferBody, PutJobOfferBody, PutJobOfferStatusBody } from '../types/job-offer.types';

const postJobOffer = (reqBody: PostJobOfferBody) => {
  return API.post<JobOfferResponse>(ApiEndpointsEnum.POST_JOB_OFFER, reqBody);
};

const usePostJobOffer = () => {
  const queryClient = useQueryClient();

  return useMutation(['post-job-offer'], (reqBody: PostJobOfferBody) => postJobOffer(reqBody), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['post-job-offer']);
    },
  });
};

const putJobOffer = async (reqBody: PutJobOfferBody, jobOfferId: string) => {
  const url = ApiEndpointsEnum.PUT_JOB_OFFER.replace('{id}', jobOfferId);
  return API.put<JobOfferResponse>(url, reqBody);
};

const usePutJobOffer = (jobOfferId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['put-job-offer'],
    (reqBody: PutJobOfferBody) => putJobOffer(reqBody, jobOfferId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['put-job-offer']);
      },
    },
  );
};

const getJobOffer = (jobOfferId: string) => {
  const url = ApiEndpointsEnum.GET_JOB_OFFER.replace('{id}', jobOfferId);
  return API.get<JobOfferResponse>(url);
};

const useGetJobOffer = (jobOfferId: string) => {
  return useQuery(['get-job-offer', jobOfferId], () => getJobOffer(jobOfferId));
};

const putJobOfferStatus = async (reqBody: PutJobOfferStatusBody, jobOfferId: string) => {
  const url = ApiEndpointsEnum.PUT_JOB_OFFER_STATUS.replace('{id}', jobOfferId);
  return API.put<JobOfferResponse>(url, reqBody);
};

const usePutJobOfferStatus = (jobOfferId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['put-job-offer-status'],
    (reqBody: PutJobOfferStatusBody,) => putJobOfferStatus(reqBody, jobOfferId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['put-job-offer-status']);
      },
    },
  );
};

export {
  usePostJobOffer,
  usePutJobOffer,
  useGetJobOffer,
  usePutJobOfferStatus,
};
