import styled from "styled-components";

export const StyledSignInPage = styled.div`
  & .section {
    margin-top: 24px;
  }
  
  & .logo {
    height: 48px;
  }
  
  & .sign-in-image {
    height: 120px;
  }
  
  & .spaced-left {
    margin-left: 5px;
  }
  
  & .controls .control {
    margin-bottom: 16px;
  }
  
  & .forgot-password-container {
    margin-top: 8px;
  }
`;