import StepWizard from 'react-step-wizard';
import PasswordResetOTPPage from './PasswordResetOTPPage';
import PasswordResetRequestPage from './PasswordResetRequestPage';
import ResetPasswordPage from './ResetPasswordPage';

const PasswordResetWizard = () => {
  return (
    <StepWizard isLazyMount={true}>
      <PasswordResetRequestPage />
      <PasswordResetOTPPage />
      <ResetPasswordPage />
    </StepWizard>
  );
};

export default PasswordResetWizard;
