
import React, { useEffect, useState } from 'react';
import { colorTheme } from '../../configs';
import Icon from '../icon';
import { StyledImage, StyledImageWrapper } from './styles';

interface ProgressiveImageProps {
    imgSrc: string;
}

export const ProgressiveImage: React.FC<ProgressiveImageProps> = props => {

    const [ usedSrc, setUsedSrc ] = useState<string | undefined>();
    const [ errorLoading, setErrorLoading ] = useState<boolean>(false);

    useEffect(() => {
        if (window.AssetCache[props.imgSrc]) {
            console.log('image found on cache');
            setUsedSrc(props.imgSrc);
            setErrorLoading(false);
        } else {
            console.log('image not found on cache');
            const img = new Image();
            img.src = props.imgSrc;
            img.onload = () => {
                setUsedSrc(img.src);
                setErrorLoading(false);
            }
            img.onerror = () => {
                console.log('error loading image');
                setErrorLoading(true);
            }
        }
    }, [props.imgSrc]);

    return (
        <>
            {!errorLoading 
                ?
                <StyledImageWrapper>
                    {usedSrc && <StyledImage src={usedSrc} />}
                </StyledImageWrapper>
                : <Icon name="user" size={215} color={colorTheme.lightGray}></Icon>
            }
        </>
        
    );

}