import { StyledContactItem, StyledContactWrapper, StyledFooterWrapper, StyledMenuItem, StyledMenuWrapper } from './styled';

import appStore from '../../../../assets/app-store.svg';
import googlePlay from '../../../../assets/google-play.svg';
import { NavItemList, navItems, unAuthenticatedNavItems } from '../../../../core/components/side-drawer/side-bar.types';
import { localization } from '../../../localization/Localization';
import { colorTheme } from '../../../../core/configs';
import { Icon } from '../../../../core/components';
import { isUsingBrowser } from '../../../utils/random.util';
import { useHistory } from 'react-router-dom';
import { AnalyticsEvent, useAnalytics } from '../../../hooks/useAnalytics';

interface FooterProps {
  onClick: () => void;
}

const Footer = (props: FooterProps) => {
  const history = useHistory();
  const {systemLogEvent} = useAnalytics();

  const googlePlayUrl = process.env.REACT_APP_KAZAM_GOOGLE_PLAYSTORE_URL;
  const appStoreUrl = process.env.REACT_APP_KAZAM_APP_STORE_URL;

  const goToUrl = (url: string) => {
    window.open(url);
  };

  const goToPage = (event: any, nav: NavItemList) => {
    event.preventDefault();
    event.stopPropagation();
    if (navItems[nav].analyticsClickEventName) {
        if(navItems[nav].path.includes('landing')) {
          props.onClick();
        }
        systemLogEvent(navItems[nav].analyticsClickEventName as AnalyticsEvent);
    }
    if (!navItems[nav].disabled) {
        if (navItems[nav].url) {
            window.open(navItems[nav].url);
        } else {
            localStorage.setItem('referralListPageNumber', '1');
            history.push(navItems[nav].path);
        }
    }
  };

  return (
    <>
        <StyledFooterWrapper className=''>
            {/* <div>
                <StyledHeader>- As Seen On -</StyledHeader>
            </div> */}
            <StyledMenuWrapper>
              {unAuthenticatedNavItems.map(item => {
                return (
                  <StyledMenuItem
                    onClick={(event) => goToPage(event, item)}
                  >
                    {localization.getString(navItems[item].name)}
                  </StyledMenuItem>
                )
              })}
            </StyledMenuWrapper>

            <StyledContactWrapper>
              <div className='gap-1'>
                <StyledMenuItem>Contact Us</StyledMenuItem>
                <StyledContactItem>
                  <div className='gap-2.5 rounded-full w-8 h-8 flex justify-center items-center' style={{backgroundColor: '#484376'}}>
                    <Icon
                      name="email"
                      className='cursor-pointer'
                      size={24}
                      color={colorTheme.light}
                      onClick={() => window.open('mailto:support@kazam.ph', '_blank')}
                    />
                  </div>
                  <span 
                    style={{fontSize: '0.7rem', color: colorTheme.light, textAlign: 'center', cursor: 'pointer'}}
                    onClick={() => window.open('mailto:support@kazam.ph', '_blank')}
                  >
                    support@kazam.ph
                  </span>
                </StyledContactItem>
              </div>

              <div className='gap-1'>
                <StyledMenuItem>Follow Us</StyledMenuItem>
                <StyledContactItem>
                  <div className='gap-2.5 rounded-full w-8 h-8 flex justify-center items-center' style={{backgroundColor: '#484376'}}>
                    <Icon
                      name="facebook"
                      className='cursor-pointer'
                      size={24}
                      color={colorTheme.light}
                      onClick={() => goToUrl('https://www.facebook.com/kazamph/')}
                    />
                  </div>
                  <div className='gap-2.5 rounded-full w-8 h-8 flex justify-center items-center' style={{backgroundColor: '#484376'}}>
                    <Icon
                      name="linkedIn"
                      className='cursor-pointer'
                      size={24}
                      color={colorTheme.light}
                      onClick={() => goToUrl('https://linkedin.com/company/kazamph')}
                    />
                  </div>
                </StyledContactItem>
              </div>

              {(googlePlayUrl || appStoreUrl) && isUsingBrowser() &&
                <div className='gap-1'>
                  <StyledMenuItem>Get the app</StyledMenuItem>
                  <StyledContactItem>
                    <img className='cursor-pointer' src={appStore} alt='app store' onClick={() => goToUrl(appStoreUrl!)}/>
                    <img className='cursor-pointer' src={googlePlay} alt='google' onClick={() => goToUrl(googlePlayUrl!)}/>
                  </StyledContactItem>
                </div>
              }
              
            </StyledContactWrapper>

        </StyledFooterWrapper>

        <div className='p-2' style={{backgroundColor: colorTheme.primary}}>
          <span className='flex w-full justify-center' style={{fontSize: '0.625rem', fontWeight: '400', color: colorTheme.light}}>
            © 2024 kazam. All rights reserved
          </span>
        </div>
    </>
    
  );
};

export default Footer;
