import format from 'date-fns/format';
import {StyledOfferCard} from './styles';
import { JobHistoryUpdatedFields } from '../../../server/types/job-offer.types';
import { getTerminationReasonValue } from '../../../utils/job-history.util';
import Rating from '../../../components/rating/Rating';

interface ChatJobHistoryEditedProps {
  sender?: string
  date: Date
  updatedFields?: JobHistoryUpdatedFields
}

const ChatJobHistoryEdited = (props: ChatJobHistoryEditedProps) => {
  return (
    <StyledOfferCard className="msg-right">
      <div className="offer-details ">
        <div className="text-center mb-2">
          <label className="txt-heading">JOB HISTORY EDITED</label>
        </div>

        <div className="flex gap-1 mb-3">
          <span>{`${props.sender} edited job details.`}</span>
        </div>

        <div className="flex flex-col gap-1 mb-2">
          {/* {props?.updatedFields?.map((f,i) => {
            return (
              <span key={i}>
                {f}
              </span>
            )
          })} */}
          {props?.updatedFields?.TerminationReason &&
            <div className="gap-1">
              <span className="txt-heading">Termination Reason: </span>
              <span>{getTerminationReasonValue(props?.updatedFields?.TerminationReason)}</span>
            </div>
          }

          {props?.updatedFields?.EndDate &&
            <div className="flex gap-1">
              <span className="txt-heading">End Date:</span>
              <span>{format(new Date(props?.updatedFields?.EndDate), 'PP')}</span>
            </div>
          }

          {props?.updatedFields?.Rating &&
            <div className="flex gap-1 items-center">
              <span className="txt-heading">Rating: </span>
              <Rating
                size={14} 
                rating={props?.updatedFields?.Rating}
                readonly
              />
            </div>
          }
        </div>

        <div className="text-right offer-msg-time">
          <span>{`${format(props.date, 'PP')} ${format(props.date, 'p')}`}</span>
        </div>
      </div>
    </StyledOfferCard>
  );
};

export default ChatJobHistoryEdited;
