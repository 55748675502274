import React, {useEffect, useState} from 'react';
import {DurationInput, Typography} from '../../../../core/components';
import {StyledWrapper} from './styled';
import {colorTheme} from '../../../../core/configs';
import {usePutAdminControl} from '../../../server/react-query';
import {useToast} from '../../../../core/components/toast';
import {
  AdminControl,
  AdminControlReqBody,
  DurationContent,
  RecentActivityThreshold,
} from '../../../server/types/admin-control.types';

interface SearchMatchViewProps {
  data: AdminControl[];
}

const SearchMatchView = ({data}: SearchMatchViewProps) => {
  const {presentToast} = useToast();

  const [isLoadingTier1Threshold, setIsLoadingTier1Threshold] = useState(false);
  const [isLoadingTier2Threshold, setIsLoadingTier2Threshold] = useState(false);
  const [isLoadingTier3Threshold, setIsLoadingTier3Threshold] = useState(false);
  const [tier1Threshold, setTier1Threshold] = useState<DurationContent>();
  const [tier2Threshold, setTier2Threshold] = useState<DurationContent>();
  const [tier3Threshold, setTier3Threshold] = useState<DurationContent>();
 

  const putAdminControl = usePutAdminControl();

  useEffect(() => {
    if (data) {
      var searchMatchRecentActivityThresholds = data.find(
        d => d.type === 'searchMatchRecentActivityThresholds',
      );

      if (searchMatchRecentActivityThresholds){
        var duration = JSON.parse(
          searchMatchRecentActivityThresholds?.content.toLowerCase(),
        ) as RecentActivityThreshold;
        setTier1Threshold(duration.tier1);
        setTier2Threshold(duration.tier2);
        setTier3Threshold(duration.tier3);
      }
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSaveSearchMatchRecentActivityThresholds = (value: DurationContent, type: number) => {
    const searchMatchThreshold : RecentActivityThreshold = { 
        tier1: (type === 1) ? value: tier1Threshold!,
        tier2: (type === 2) ? value: tier2Threshold!,
        tier3: (type === 3) ? value: tier3Threshold!,
        
    };

    // Updates tier value
    if (type === 1) {
      setTier1Threshold(value)
    }
    else if (type === 2) {
      setTier2Threshold(value)
    }
    else if (type === 3) {
      setTier3Threshold(value)
    }

    const data: AdminControlReqBody = {
      type: 'searchMatchRecentActivityThresholds',
      enabled: true,
      content: JSON.stringify(searchMatchThreshold),
    };
    if (type === 1) {
      setIsLoadingTier1Threshold(true);
    }
    else if (type === 2) {
      setIsLoadingTier2Threshold(true);
    }
    else if (type === 3) {
      setIsLoadingTier3Threshold(true);
    }
    
    onPutAdminControl(data);
  };


  const onPutAdminControl = async (data: AdminControlReqBody) => {
    try {
      await putAdminControl.mutateAsync(data);

      setIsLoadingTier1Threshold(false);
      setIsLoadingTier2Threshold(false);
      setIsLoadingTier3Threshold(false);
      presentToast({
        message: `Successfully saved.`,
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      setIsLoadingTier1Threshold(false);
      setIsLoadingTier2Threshold(false);
      setIsLoadingTier3Threshold(false);
      console.log(e);
    }
  };

  return (
    <StyledWrapper>
      <Typography
        label="SEARCH MATCH"
        variant="f1"
        weight="semibold"
        color={colorTheme.black}
      />

<div>
        <div className="pt-3 flex flex-row justify-between items-center">
          <div>
            <Typography
              label="Recently Active Threshold"
              variant="f3"
              color={colorTheme.dark}
            />
          </div>
        </div>
        
        <div className="pt-3">
          <Typography
            label= "Tier 1"
            variant="f2"
            color={colorTheme.dark}
          />
        </div>
        <div className="pt-1">
          <Typography
              label="The threshold set on this tier will be the first cut-off time"
              variant="f1"
              color={colorTheme.darkFaded}
          />
        </div>

        <div className="pt-3">
          <DurationInput
            duration={tier1Threshold}
            onSubmit={(duration) => onSaveSearchMatchRecentActivityThresholds(duration, 1)}
            isLoading={isLoadingTier1Threshold}
          />
        </div>

        <div className="pt-3">
          <Typography
            label= "Tier 2"
            variant="f2"
            color={colorTheme.dark}
          />
        </div>
        <div className="pt-1">
          <Typography
              label="The threshold set on this tier will be the second cut-off time"
              variant="f1"
              color={colorTheme.darkFaded}
          />
        </div>

        <div className="pt-3">
          <DurationInput
            duration={tier2Threshold}
            onSubmit={(duration) => onSaveSearchMatchRecentActivityThresholds(duration, 2)}
            isLoading={isLoadingTier2Threshold}
          />
        </div>

        <div className="pt-3">
          <Typography
            label= "Tier 3"
            variant="f2"
            color={colorTheme.dark}
          />
        </div>
        <div className="pt-1">
          <Typography
              label="The threshold set on this tier will be the third cut-off time"
              variant="f1"
              color={colorTheme.darkFaded}
          />
        </div>

        <div className="pt-3">
          <DurationInput
            duration={tier3Threshold}
            onSubmit={(duration) => onSaveSearchMatchRecentActivityThresholds(duration, 3)}
            isLoading={isLoadingTier3Threshold}
          />
        </div>
      </div>

     
    </StyledWrapper>
  );
};

export default SearchMatchView;
