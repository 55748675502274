import React from 'react';
import {StyledBannedAccountNoticeDialog} from './styled';
import ImageBannedAccount from '../../../../assets/banned-account.png';
import {Button, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {localization} from '../../../localization/Localization';

interface BannedAccountNoticeDialogProps {
  onClose: () => void;
}

const BannedAccountNoticeDialog = (props: BannedAccountNoticeDialogProps) => {
  return (
    <StyledBannedAccountNoticeDialog className="flex flex-col">
      <div className="self-center">
        <img
          width="153px"
          height="149px"
          src={ImageBannedAccount}
          alt="banned-account"
        />
      </div>

      <div>
        <Typography
          label={localization.hdr_banned_profile}
          variant="f3"
          weight="semibold"
          align="center"
          color={colorTheme.dark}
        />
      </div>

      <div>
        <Typography
          label="Please contact support@kazam.ph for more information."
          color={colorTheme.darkFaded}
          align="center"
          variant="f1"
        />
      </div>

      <div>
        <Button label="Okay" color="primary" onClick={props.onClose} />
      </div>
    </StyledBannedAccountNoticeDialog>
  );
};

export default BannedAccountNoticeDialog;
