import styled, {css} from 'styled-components';
import {colorTheme} from '../../../../core/configs';

interface GetAppProps {
  fixed?: boolean;
}

export const StyledGetApp = styled.div<GetAppProps>`
  background: ${colorTheme.accent};
  padding: 8px 16px;
  color: white;

  ${p =>
    p.fixed
      ? css`
        position: fixed;
        right: 0;
        left: 0;
        bottom: 59px;
        `
      : css`
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          z-index: 2;
        `}

  .not-now {
    width: 120px;
  }

  .switch {
    width: 135px;
  }
`;
