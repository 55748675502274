import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';
import {
  UserResponse,
  PutKazamProfileBody,
  Role,
  UploadPhotoReqBody,
  LocationResponse,
  PutUserProfileBody,
  PutHomeownerProfileBody,
  PatchUserProfileBody,
  ChatResponse,
  FilterSettingResponse,
  SignInBody,
  FilterSettingBody,
  PostFavoriteBody,
  TagResponse,
  PostHideBody,
  PostConversationBody,
  PatchUserProfileFCMTokenBody,
  PostKawayBody,
  UserLogResponse,
  MatchResponse,
  ChatContentResponse,
  PostMarkConversationContentAsReadBody,
  ProvinceResponse,
  AbuseReportResponse,
  PostAbuseReportBody,
  GetAbuseReportsResponse,
  PostBanUnbanUserBody,
  GetBannedUsersResponse,
  GetReferralSummariesResponse,
  GetReferralStatisticsResponse,
  GetReferralTransactionsResponse,
  PatchUserProfileStatusBody,
  GetReferralsByUserResponse,
  GetSystemStatisticsResponse,
  GetUsersResponse,
  GetReferralInfoResponse,
  GetAbuseReportersResponse,
  PostJobBody,
  MatchQueryParams,
  FcmTokenBody,
  FcmTokenResponse,
  ChangePasswordBody,
  ChatPaginatedResponse,
  UserRole,
  PatchUserLocationBody,
} from '../types';
import {AxiosRequestConfig} from 'axios';
import {ApiData, VersionInfo} from '../types/api-data';
import {OnlineStatus} from '../../types/types';
import {extractOnlineStatus} from '../../utils/online-status.util';
import {getGenderEnum} from '../../utils/random.util';
import {LivingArrangement} from '../types/filter-settings.types';
import {
  getOfflineStatusThreshold,
  getOnlineStatusThreshold,
} from '../../utils/admin.util';
import {QueryKeys} from './config';
import { ReferralType } from '../../pages/admin/referrals/AdminReferralTabs';
import { PaginatedChatsQueryParams, PaginatedUsersQueryParams } from '../types/admin-control.types';
import { useEffect } from 'react';

const getRoles = () => {
  return API.get<Role[]>(ApiEndpointsEnum.GET_ROLES);
};

const useGetRoles = () => {
  return useQuery(['get-roles'], () => getRoles());
};

const getLocations = () => {
  return API.get<LocationResponse[]>(ApiEndpointsEnum.GET_LOCATIONS);
};

const useGetLocations = () => {
  return useQuery(['get-locations'], () => getLocations());
};

const getProvinces = () => {
  return API.get<ProvinceResponse[]>(ApiEndpointsEnum.GET_PROVINCES);
};

const useGetProvinces = () => {
  return useQuery(['get-provinces'], () => getProvinces());
};

const getMyProfile = () => {
  return API.get<UserResponse>(ApiEndpointsEnum.GET_PROFILE);
};

const useGetMyProfile = () => {
  return useQuery(['get-my-profile'], () => getMyProfile());
};

const putUserProfile = async (reqBody: PutUserProfileBody) => {
  const url = ApiEndpointsEnum.PUT_PROFILE;
  return API.put<UserResponse>(url, reqBody);
};

const usePutKazamProfile = (reqBody: PutKazamProfileBody) => {
  const queryClient = useQueryClient();

  const moldedReqBody: PutUserProfileBody = {
    mobileNumber: reqBody.mobileNumber,
    firstName: reqBody.firstName,
    lastName: reqBody.lastName,
    birthDate: reqBody.birthDate,
    gender: getGenderEnum(reqBody.gender),
    email: reqBody.email,
    locationId: reqBody.locationId,
    minSalary: reqBody.minSalary,
    livingArrangement: reqBody.livingArrangement as LivingArrangement,
    roles: reqBody.roles,
  };

  return useMutation(
    ['put-kazam-profile'],
    () => putUserProfile(moldedReqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['put-kazam-profile']);
        queryClient.fetchQuery(['get-my-profile']);
      },
    },
  );
};

const postSignIn = (reqBody: SignInBody) => {
  return API.post(ApiEndpointsEnum.POST_SIGN_IN, reqBody);
};

const usePostSignIn = (reqBody: SignInBody) => {
  const queryClient = useQueryClient();

  return useMutation(['post-sign-in'], () => postSignIn(reqBody), {
    onSuccess: () => {
      queryClient.invalidateQueries(['post-sign-in']);
    },
  });
};

const postSignInAdmin = (reqBody: SignInBody) => {
  return API.post(ApiEndpointsEnum.POST_SIGN_IN_ADMIN, reqBody);
};

const usePostSignInAdmin = (reqBody: SignInBody) => {
  const queryClient = useQueryClient();

  return useMutation(['post-sign-in-admin'], () => postSignInAdmin(reqBody), {
    onSuccess: () => {
      queryClient.invalidateQueries(['post-sign-in-admin']);
    },
  });
};

const putFilterSetting = (reqBody: FilterSettingBody) => {
  return API.put<void>(ApiEndpointsEnum.PUT_FILTER_SETTINGS, reqBody);
};

const usePutFilterSetting = (reqBody: FilterSettingBody) => {
  const queryClient = useQueryClient();

  return useMutation(['put-filter-settings'], () => putFilterSetting(reqBody), {
    onSuccess: () => {
      queryClient.invalidateQueries(['put-filter-settings']);
      queryClient.invalidateQueries([`get-matches-${1}`, `get-matches-${2}`]);
    },
  });
};

const usePutHomeownerProfile = (reqBody: PutHomeownerProfileBody) => {
  const queryClient = useQueryClient();

  const moldedReqBody: PutUserProfileBody = {
    mobileNumber: reqBody.mobileNumber,
    firstName: reqBody.firstName,
    lastName: reqBody.lastName,
    birthDate: reqBody.birthDate,
    gender: getGenderEnum(reqBody.gender),
    email: reqBody.email,
    locationId: reqBody.locationId,
  };

  return useMutation(
    ['put-homeowner-profile'],
    () => putUserProfile(moldedReqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['put-homeowner-profile']);
        queryClient.fetchQuery(['get-my-profile']);
      },
    },
  );
};

const patchUserProfile = async (reqBody: PatchUserProfileBody) => {
  const url = ApiEndpointsEnum.PATCH_PROFILE;
  return API.patch<UserResponse>(url, reqBody);
};

const usePatchUserProfile = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['patch-user-profile'],
    (reqBody: PatchUserProfileBody) => patchUserProfile(reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['patch-user-profile']);
        queryClient.fetchQuery(['get-my-profile']);
      },
    },
  );
};

const patchUserProfileFCMToken = async (
  reqBody: PatchUserProfileFCMTokenBody,
) => {
  const url = ApiEndpointsEnum.PATCH_PROFILE_FCM_TOKEN;
  return API.patch<UserResponse>(url, reqBody);
};

const usePatchUserProfileFCMToken = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['patch-user-profile-fcm'],
    (reqBody: PatchUserProfileFCMTokenBody) =>
      patchUserProfileFCMToken(reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['patch-user-profile-fcm']);
        queryClient.fetchQuery(['get-my-profile']);
      },
    },
  );
};

const uploadProfilePhoto = async (reqBody: UploadPhotoReqBody) => {
  const headerAuthorization: any = {
    'Content-Type': 'multipart/form-data',
  };

  const config: AxiosRequestConfig = {
    headers: {
      ...headerAuthorization,
    },
  };

  const formData = new FormData();

  formData.append('image', reqBody.image!);

  return API.put<UploadPhotoReqBody>(
    ApiEndpointsEnum.PUT_PROFILE_PHOTO,
    formData,
    config,
  );
};

const useUploadProfilePhoto = (reqBody: UploadPhotoReqBody) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['upload-profile-photo'],
    () => uploadProfilePhoto(reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['upload-profile-photo']);
        queryClient.fetchQuery(['get-my-profiles']);
      },
    },
  );
};

const getMatches = (query: MatchQueryParams) => {
  if (query.page < 1) {
    return new Promise<MatchResponse>(resolve =>
      resolve({
        page: query.page,
        data: [],
      }),
    );
  }

  const params = API.convertToParam(query);
  const url = ApiEndpointsEnum.GET_MATCHES + '?' + params;
  return API.get<MatchResponse>(url);
};

const useGetMatches = (query: MatchQueryParams) => {
  return useQuery([`get-matches-${query.page}`], () => getMatches(query));
};

const getTaC = () => {
  return API.get<ApiData>(ApiEndpointsEnum.GET_TAC);
};

const useGetTaC = () => {
  return useQuery(['get-tac'], () => getTaC());
};

const getPrivacyPolicy = () => {
  return API.get<ApiData>(ApiEndpointsEnum.GET_PRIVACY_POLICY);
};

const useGetPrivacyPolicy = () => {
  return useQuery(['get-privacy-policy'], () => getPrivacyPolicy());
};

const getUserById = (userId: string, includePersonalityScore: boolean = false) => {
  var url = ApiEndpointsEnum.GET_USER_BY_ID.replace('{id}', userId);
  if (includePersonalityScore ){
    url = url + '?includePersonalityScore=true'
  }
  return API.get<UserResponse>(url);
};

const useGetUserById = (userId: string, includePersonalityScore: boolean = false) => {
  return useQuery(['get-user-by-id'], () => getUserById(userId, includePersonalityScore));
};

const getConversation = (
  searchQuery: string | undefined,
  sortBy: string | undefined,
  sortOrder: string | undefined,
  roles: string | undefined,
) => {
  let url = ApiEndpointsEnum.GET_CONVERSATION.toString();
  const params: {[key: string]: string} = {};
  params['sort_by'] = 'activity';
  params['sort_order'] = 'latest_first';

  if (searchQuery) {
    params['search_query'] = searchQuery;
  }
  if (sortBy) {
    params['sort_by'] = sortBy;
  }
  if (sortOrder) {
    params['sort_order'] = sortOrder;
  }
  if (roles) {
    params['roles'] = roles;
  }

  if (Object.keys(params).length > 0) {
    url += '?' + API.convertToParam(params);
  }

  return API.get<ChatResponse[]>(url);
};

const useGetConversation = (
  searchQuery: string | undefined = undefined,
  sortBy: string | undefined = undefined,
  sortOrder: string | undefined = undefined,
  roles: string | undefined = undefined,
) => {
  return useQuery(['get-conversation'], () =>
    getConversation(searchQuery, sortBy, sortOrder, roles),
  );
};

const getPaginatedConversations = (query: PaginatedChatsQueryParams) => {
  let url = ApiEndpointsEnum.GET_PAGINATED_CONVERSATIONS.toString();
  const params: {[key: string]: string} = {};
  params['sort_by'] = 'activity';
  params['sort_order'] = 'latest_first';
  params['page'] = `${query.page}`;
  params['user_role'] = `${query.userRole}`;
  
  if (query.searchQuery) {
    params['search_query'] = query.searchQuery;
  }
  if (query.sortBy) {
    params['sort_by'] = query.sortBy;
  }
  if (query.sortOrder) {
    params['sort_order'] = query.sortOrder;
  }
  if (query.roles) {
    params['roles'] = query.roles;
  }

  if (Object.keys(params).length > 0) {
    url += '?' + API.convertToParam(params);
  }

  return API.get<ChatPaginatedResponse>(url);
};

const useGetPaginatedConversations = (params: PaginatedChatsQueryParams) => {
  return useQuery(['get-paginated-conversations'], () =>
    getPaginatedConversations(params),
  );
};

const postConversation = (reqBody: PostConversationBody) => {
  return API.post<ChatResponse>(ApiEndpointsEnum.POST_CONVERSATION, reqBody);
};

const usePostConversation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['post-conversation'],
    (reqBody: PostConversationBody) => postConversation(reqBody),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['post-conversation']);
        // await queryClient.fetchQuery(['get-conversation']);
      },
    },
  );
};

const getConversationById = (channelId: string) => {
  const url = ApiEndpointsEnum.GET_CONVERSATION_BY_ID.replace(
    '{id}',
    channelId,
  );
  return API.get<ChatResponse>(url);
};

const useGetConversationById = (channelId: string) => {
  return useQuery(['get-conversation-by-id', channelId], () =>
    getConversationById(channelId),
  );
};

const getConversationContent = (
  conversationChannelId: string,
  pageNumber: number,
) => {
  let url = ApiEndpointsEnum.GET_CONVERSATION_CONTENTS.replace(
    '{id}',
    conversationChannelId,
  );
  if (pageNumber && pageNumber > 0) {
    url = url + '?' + API.convertToParam({page: pageNumber});
  }
  return API.get<ChatContentResponse[]>(url);
};

const useGetConversationContent = (
  conversationChannelId: string,
  pageNumber: number = 1,
) => {
  return useQuery(
    [`get-conversation-content`, conversationChannelId, pageNumber],
    () => getConversationContent(conversationChannelId, pageNumber),
  );
};

const postMarkAllMessagesAsRead = (conversationChannelId: string) => {
  let url = ApiEndpointsEnum.POST_MARK_ALL_MESSAGES_AS_READ.replace(
    '{id}',
    conversationChannelId,
  );
  return API.post<null>(url);
};

const usePostMarkAllMessagesAsRead = (conversationChannelId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['post-mark-all-messages-as-read'],
    () => postMarkAllMessagesAsRead(conversationChannelId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['post-mark-all-messages-as-read']);
        await queryClient.invalidateQueries(['get-unread-conversations']);
      },
    },
  );
};

const postMarkConversationContentAsRead = (
  reqBody: PostMarkConversationContentAsReadBody,
) => {
  return API.post(
    ApiEndpointsEnum.POST_MARK_CONVERSATION_CONTENT_AS_READ,
    reqBody,
  );
};

const usePostMarkConversationContentAsRead = (
  reqBody: PostMarkConversationContentAsReadBody,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['post-mark-conversation-content-as-read'],
    () => postMarkConversationContentAsRead(reqBody),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'post-mark-conversation-content-as-read',
        ]);
      },
    },
  );
};

const getUnreadConversations = () => {
  return API.get<ChatResponse[]>(ApiEndpointsEnum.GET_UNREAD_CONVERSATIONS);
};

const useGetUnreadConversations = () => {
  return useQuery(['get-unread-conversations'], () => getUnreadConversations());
};

const getFinderSetting = () => {
  return API.get<FilterSettingResponse>(ApiEndpointsEnum.GET_FILTER_SETTINGS);
};

const useGetFinderSetting = () => {
  return useQuery(['get-finder-setting'], () => getFinderSetting());
};

const postFavorite = (reqBody: PostFavoriteBody) => {
  return API.post(ApiEndpointsEnum.POST_FAVORITE, reqBody);
};

const usePostFavorite = (reqBody: PostFavoriteBody) => {
  const queryClient = useQueryClient();

  return useMutation(['post-favorite'], () => postFavorite(reqBody), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['post-favorite']);
      await queryClient.fetchQuery(['get-favorites']);
    },
  });
};

const getFavorites = (
  searchQuery: string | undefined,
  sortBy: string | undefined,
  sortOrder: string | undefined,
  roles: string | undefined,
) => {
  let url = ApiEndpointsEnum.GET_FAVORITE.toString();
  const params: {[key: string]: string} = {};

  if (searchQuery) {
    params['search_query'] = searchQuery;
  }
  if (sortBy) {
    params['sort_by'] = sortBy;
  }
  if (sortOrder) {
    params['sort_order'] = sortOrder;
  }
  if (roles) {
    params['roles'] = roles;
  }

  if (Object.keys(params).length > 0) {
    url += '?' + API.convertToParam(params);
  }

  return API.get<TagResponse[]>(url);
};

const useGetFavorites = (
  searchQuery: string | undefined = undefined,
  sortBy: string | undefined = undefined,
  sortOrder: string | undefined = undefined,
  roles: string | undefined = undefined,
) => {
  return useQuery(['get-favorites'], () =>
    getFavorites(searchQuery, sortBy, sortOrder, roles),
  );
};

const deleteFavorite = (userId: string) => {
  const url = ApiEndpointsEnum.DELETE_FAVORITE.replace('{id}', userId);
  return API.del(url);
};

const useDeleteFavorite = (userId: string) => {
  const queryClient = useQueryClient();

  return useMutation(['delete-favorite'], () => deleteFavorite(userId), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['delete-favorite']);
      await queryClient.fetchQuery(['get-favorites']);
    },
  });
};

const postHide = (reqBody: PostHideBody) => {
  return API.post(ApiEndpointsEnum.POST_HIDE, reqBody);
};

const usePostHide = (reqBody: PostHideBody) => {
  const queryClient = useQueryClient();

  return useMutation(['post-hide'], () => postHide(reqBody), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['post-hide']);
      await queryClient.fetchQuery(['get-hides']);
    },
  });
};

const getHides = () => {
  return API.get<TagResponse[]>(ApiEndpointsEnum.GET_HIDES);
};

const useGetHides = () => {
  return useQuery(['get-hides'], () => getHides());
};

const getKaways = (
  searchQuery: string | undefined,
  sortBy: string | undefined,
  sortOrder: string | undefined,
  roles: string | undefined,
) => {
  let url = ApiEndpointsEnum.GET_KAWAYS.toString();
  const params: {[key: string]: string} = {};

  if (searchQuery) {
    params['search_query'] = searchQuery;
  }
  if (sortBy) {
    params['sort_by'] = sortBy;
  }
  if (sortOrder) {
    params['sort_order'] = sortOrder;
  }
  if (roles) {
    params['roles'] = roles;
  }

  if (Object.keys(params).length > 0) {
    url += '?' + API.convertToParam(params);
  }

  return API.get<TagResponse[]>(url);
};

const useGetKaways = (
  searchQuery: string | undefined = undefined,
  sortBy: string | undefined = undefined,
  sortOrder: string | undefined = undefined,
  roles: string | undefined = undefined,
) => {
  return useQuery(['get-kaways'], () =>
    getKaways(searchQuery, sortBy, sortOrder, roles),
  );
};

const deleteHide = (userId: string) => {
  const url = ApiEndpointsEnum.DELETE_HIDE.replace('{id}', userId);
  return API.del(url);
};

const useDeleteHide = (userId: string) => {
  const queryClient = useQueryClient();

  return useMutation(['delete-hide'], () => deleteHide(userId), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['delete-hide']);
      await queryClient.fetchQuery(['get-hides']);
    },
  });
};

const getOnlineStatus = (userId: string): Promise<OnlineStatus> => {
  return new Promise<OnlineStatus>(async (res, rej) => {
    try {
      const url = ApiEndpointsEnum.GET_ONLINE_STATUS.replace(
        '{userId}',
        userId,
      );
      const data = await API.get<UserLogResponse | null>(url);
      if (data) {
        const onlineThreshold = getOnlineStatusThreshold();
        const offlineThreshold = getOfflineStatusThreshold();
        const timestamp = data.timestamp;
        const onlineStatus = extractOnlineStatus(
          timestamp,
          onlineThreshold,
          offlineThreshold,
        );
        res(onlineStatus);
      } else {
        res({status: 'offline', lastOnline: ''} as OnlineStatus);
      }
    } catch (e: any) {
      rej(e);
    }
  });
};

const useGetOnlineStatus = (userId: string): UseQueryResult<OnlineStatus> => {
  return useQuery([`get-online-status-${userId}`], () =>
    getOnlineStatus(userId),
  );
};

const postOnlineStatus = () => {
  return API.post(ApiEndpointsEnum.POST_ONLINE_STATUS);
};

const usePostOnlineStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(['post-online-status'], () => postOnlineStatus(), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['post-online-status']);
    },
  });
};

const postKaway = (reqBody: PostKawayBody) => {
  return API.post(ApiEndpointsEnum.POST_KAWAY, reqBody);
};

const usePostKaway = (reqBody: PostKawayBody) => {
  const queryClient = useQueryClient();

  return useMutation(['post-kaway'], () => postKaway(reqBody), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['post-kaway']);
      await queryClient.invalidateQueries(['get-kaways']);
    },
  });
};

const postAbuseReport = (reqBody: PostAbuseReportBody) => {
  return API.post<AbuseReportResponse>(
    ApiEndpointsEnum.POST_ABUSE_REPORT,
    reqBody,
  );
};

const usePostAbuseReport = (reqBody: PostAbuseReportBody) => {
  const queryClient = useQueryClient();

  return useMutation(['post-abuse-report'], () => postAbuseReport(reqBody), {
    onSuccess: async () => {
      await queryClient.invalidateQueries('get-abuse-reports');
      await queryClient.invalidateQueries('get-abuse-reporters');
    },
  });
};

const getMultipleUsers = (userIds: string[]) => {
  var params = '';
  userIds.forEach((item, index) => {
    params += `userIDs=${item}&`;
  });
  const url = ApiEndpointsEnum.GET_MULTIPLE_USERS + `?${params}`;
  return API.get<UserResponse[]>(url);
};

const useGetMultipleUsers = (userIds: string[]) => {
  return useQuery(['get-multiple-users', userIds], () =>
    getMultipleUsers(userIds),
  );
};

const postBanUser = (reqBody: PostBanUnbanUserBody) => {
  return API.post(ApiEndpointsEnum.POST_BAN_USER, reqBody);
};

const usePostBanUser = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['post-ban-user'],
    (reqBody: PostBanUnbanUserBody) => postBanUser(reqBody),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('get-banned-users');
        await queryClient.invalidateQueries(['get-abuse-reports']);
        await queryClient.invalidateQueries(['get-users']);
        await queryClient.invalidateQueries(['get-verified-users']);
      },
    },
  );
};

const postUnbanUser = (reqBody: PostBanUnbanUserBody) => {
  return API.post(ApiEndpointsEnum.POST_UNBAN_USER, reqBody);
};

const usePostUnbanUser = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['post-unban-user'],
    (reqBody: PostBanUnbanUserBody) => postUnbanUser(reqBody),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('get-banned-users');
        await queryClient.invalidateQueries(['get-abuse-reports']);
        await queryClient.invalidateQueries(['get-users']);
        await queryClient.invalidateQueries(['get-verified-users']);
      },
    },
  );
};

const postJob = (reqBody: PostJobBody) => {
  return API.post(ApiEndpointsEnum.POST_JOB, reqBody);
};

const usePostJob = () => {
  return useMutation(['post-job'], (reqBody: PostJobBody) => postJob(reqBody), {
    onSuccess: async () => {},
  });
};

const getBannedUsers = (pageNumber: number, searchQuery: string) => {
  const url =
    ApiEndpointsEnum.GET_BANNED_USERS +
    `?page=${pageNumber}` +
    (searchQuery.trim() ? `&searchQuery=${searchQuery}` : '');
  return API.get<GetBannedUsersResponse>(url);
};

const useGetBannedUsers = (params: PaginatedUsersQueryParams) => {
  return useQuery(['get-banned-users', params.page], () =>
    getBannedUsers(params.page, params.searchQuery),
  );
};

const getUsers = (pageNumber: number, searchQuery: string) => {
  const url =
    ApiEndpointsEnum.GET_USERS +
    `?page=${pageNumber}` +
    (searchQuery.trim() ? `&searchQuery=${searchQuery}` : '');
  return API.get<GetUsersResponse>(url);
};

const useGetUsers = (params: PaginatedUsersQueryParams) => {
  return useQuery(['get-users', params.page], () =>
    getUsers(params.page, params.searchQuery ?? ""),
  );
};

const getAbuseReports = (pageNumber: number, searchQuery: string) => {
  const url =
    ApiEndpointsEnum.GET_ABUSE_REPORT +
    `?page=${pageNumber}` +
    (searchQuery.trim() ? `&searchQuery=${searchQuery}` : '');
  return API.get<GetAbuseReportsResponse>(url);
};

const useGetAbuseReports = (params: PaginatedUsersQueryParams) => {
  return useQuery(['get-abuse-reports', params.page], () =>
    getAbuseReports(params.page, params.searchQuery ?? ""),
  );
};

const getAbuseReporters = (userId: string, pageNumber: number) => {
  const url =
    ApiEndpointsEnum.GET_ABUSE_REPORTERS.replace('{userId}', userId) +
    `?page=${pageNumber}`;
  return API.get<GetAbuseReportersResponse>(url);
};

const useGetAbuseReporters = (userId: string, pageNumber: number) => {
  return useQuery(['get-abuse-reporters', userId, pageNumber], () =>
    getAbuseReporters(userId, pageNumber),
  );
};

const getReferralSummary = (pageNumber: number, searchQuery: string, type: ReferralType) => {
  const url =
    ApiEndpointsEnum.GET_REFERRAL_SUMMARY +
    `?page=${pageNumber}` +
    (searchQuery ? `&searchQuery=${searchQuery}` : '')
    + `&type=${type}`;
  return API.get<GetReferralSummariesResponse>(url);
};

const useGetReferralSummary = (
  pageNumber: number,
  searchQuery: string,
  type: ReferralType,
  uniqueId: string = '',
) => {
  return useQuery(['get-referral-summary', pageNumber, uniqueId], () =>
    getReferralSummary(pageNumber, searchQuery, type),
  );
};

const getReferralStatistics = (type: ReferralType) => {
  const url =
    ApiEndpointsEnum.GET_REFERRAL_STATISTICS + `?type=${type}`;
  return API.get<GetReferralStatisticsResponse>(url);
};

const useGetReferralStatistics = (type: ReferralType) => {
  return useQuery(['get-referral-statistics'], () => getReferralStatistics(type));
};

const getReferralTransactions = (group: 0 | 1 | 2, type: ReferralType) => {
  const url = ApiEndpointsEnum.GET_REFERRAL_TRANSACTIONS + `?group=${group}` + `&type=${type}`;
  return API.get<GetReferralTransactionsResponse>(url);
};

const useGetReferralTransactions = (group: 0 | 1 | 2, type: ReferralType) => {
  return useQuery(['get-referral-transactions'], () =>
    getReferralTransactions(group, type),
  );
};

const getReferralsByUser = (
  userId: string,
  pageNumber: number,
  searchQuery: string,
) => {
  const url =
    ApiEndpointsEnum.GET_REFERRAL_BY_USER.replace('{id}', userId) +
    `?page=${pageNumber}` +
    (searchQuery ? `&searchQuery=${searchQuery}` : '');
  return API.get<GetReferralsByUserResponse>(url);
};

const useGetReferralsByUser = (
  userId: string,
  pageNumber: number,
  searchQuery: string,
  uniqueId: string = '',
) => {
  return useQuery(
    ['get-referrals-by-users', userId, pageNumber, uniqueId],
    () => getReferralsByUser(userId, pageNumber, searchQuery),
  );
};

const getReferralsByAdminGenerated = (
  referralLinkId: string,
  pageNumber: number,
  searchQuery: string,
) => {
  const url =
    ApiEndpointsEnum.GET_REFERRAL_BY_ADMIN_GENERATED.replace('{id}', referralLinkId) +
    `?page=${pageNumber}` +
    (searchQuery ? `&searchQuery=${searchQuery}` : '');
  return API.get<GetReferralsByUserResponse>(url);
};

const useGetReferralsByAdminGenerated = (
  referralLinkId: string,
  pageNumber: number,
  searchQuery: string,
  uniqueId: string = '',
) => {
  return useQuery(
    ['get-referrals-by-admin', referralLinkId, pageNumber, uniqueId],
    () => getReferralsByAdminGenerated(referralLinkId, pageNumber, searchQuery),
  );
};

const getReferralInfo = (referralCode: string) => {
  const url = ApiEndpointsEnum.GET_REFERRAL_INFO.replace(
    '{referralCode}',
    referralCode,
  );
  return API.get<GetReferralInfoResponse>(url);
};

const useGetReferralInfo = (referralCode: string) => {
  return useQuery(['get-referral-info', referralCode], () =>
    getReferralInfo(referralCode),
  );
};

const acceptDisclaimer = async () => {
  const url = ApiEndpointsEnum.PATCH_PROFILE_ACCEPT_DISCLAIMER;
  return API.patch<UserResponse>(url);
};

const useAcceptDisclaimer = () => {
  const queryClient = useQueryClient();

  return useMutation(['patch-accept-disclaimer'], () => acceptDisclaimer(), {
    onSuccess: () => {
      queryClient.invalidateQueries(['patch-accept-disclaimer']);
      queryClient.fetchQuery(['get-my-profile']);
    },
  });
};

const acceptTerms = async () => {
  const url = ApiEndpointsEnum.PATCH_PROFILE_ACCEPT_TERMS;
  return API.patch<UserResponse>(url);
};

const useAcceptTerms = () => {
  const queryClient = useQueryClient();

  return useMutation(['patch-accept-terms'], () => acceptTerms(), {
    onSuccess: () => {
      queryClient.invalidateQueries(['patch-accept-terms']);
      queryClient.fetchQuery(['get-my-profile']);
    },
  });
};

const acceptPrivacyPolicy = async () => {
  const url = ApiEndpointsEnum.PATCH_PROFILE_ACCEPT_PRIVACY_POLICY;
  return API.patch<UserResponse>(url);
};

const useAcceptPrivacyPolicy = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['patch-accept-privacy-policy'],
    () => acceptPrivacyPolicy(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['patch-accept-privacy-policy']);
        queryClient.fetchQuery(['get-my-profile']);
      },
    },
  );
};

const getSystemStatistics = () => {
  return API.get<GetSystemStatisticsResponse>(
    ApiEndpointsEnum.GET_SYSTEM_STATISTICS,
  );
};

const useGetSystemStatistics = () => {
  return useQuery(['get-system-statistics'], () => getSystemStatistics());
};

const patchUserProfileStatus = async (reqBody: PatchUserProfileStatusBody) => {
  const url = ApiEndpointsEnum.PATCH_PROFILE_STATUS;
  return API.patch<UserResponse>(url, reqBody);
};

const usePatchUserProfileStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['patch-user-profile-status'],
    (reqBody: PatchUserProfileStatusBody) => patchUserProfileStatus(reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['patch-user-profile-status']);
        queryClient.fetchQuery(['get-my-profile']);
      },
    },
  );
};

const postFcmToken = (reqBody: FcmTokenBody) => {
  return API.post<FcmTokenResponse>(ApiEndpointsEnum.POST_FCM_TOKEN, reqBody);
};

const usePostFcmToken = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['post-fcm-token'],
    (reqBody: FcmTokenBody) => postFcmToken(reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['post-fcm-token']);
      },
    },
  );
};

const deleteFcmToken = (tokenId: string) => {
  const url = ApiEndpointsEnum.DEL_FCM_TOKEN.replace('{id}', tokenId);
  return API.del(url);
};

const useDeleteFcmToken = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['delete-fcm-token'],
    (tokenId: string) => deleteFcmToken(tokenId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['delete-fcm-token']);
      },
    },
  );
};

const getMatchById = (userId: string) => {
  const url = ApiEndpointsEnum.GET_MATCH_BY_ID.replace('{id}', userId);
  return API.get<UserResponse>(url);
};

const useGetMatchById = (userId: string) => {
  return useQuery(['get-match-by-id'], () => getMatchById(userId));
};

const getLatestVersionInfo = () => {
  return API.get<VersionInfo>(ApiEndpointsEnum.GET_LATEST_VERSION_INFO);
};

const useGetLatestVersionInfo = () => {
  return useQuery(['get-latest-version-info'], () => getLatestVersionInfo());
};

const putNewPassword = (reqBody: ChangePasswordBody) => {
  return API.put<void>(ApiEndpointsEnum.PUT_PROFILE_NEW_PASSWORD, reqBody);
};

const postSuspendUser = (reqBody: PostBanUnbanUserBody) => {
  return API.post(ApiEndpointsEnum.POST_SUSPEND_USER, reqBody);
};

const usePostSuspendUser = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['post-suspend-user'],
    (reqBody: PostBanUnbanUserBody) => postSuspendUser(reqBody),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('get-suspended-users');
        await queryClient.invalidateQueries(['get-abuse-reports']);
        await queryClient.invalidateQueries(['get-users']);
        await queryClient.invalidateQueries(['get-verified-users']);
      },
    },
  );
};

const postUnsuspendUser = (reqBody: PostBanUnbanUserBody) => {
  return API.post(ApiEndpointsEnum.POST_UNSUSPEND_USER, reqBody);
};

const usePostUnsuspendUser = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['post-unsuspend-user'],
    (reqBody: PostBanUnbanUserBody) => postUnsuspendUser(reqBody),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('get-suspended-users');
        await queryClient.invalidateQueries(['get-abuse-reports']);
        await queryClient.invalidateQueries(['get-users']);
        await queryClient.invalidateQueries(['get-verified-users']);
      },
    },
  );
};

const getSuspendedUsers = (pageNumber: number, searchQuery: string) => {
  const url =
    ApiEndpointsEnum.GET_SUSPENDED_USERS +
    `?page=${pageNumber}` +
    (searchQuery.trim() ? `&searchQuery=${searchQuery}` : '');
  return API.get<GetBannedUsersResponse>(url);
};

const useGetSuspendedUsers = (params: PaginatedUsersQueryParams) => {
  return useQuery(['get-suspended-users', params.page], () =>
    getSuspendedUsers(params.page, params.searchQuery ?? ""),
  );
};

const patchUserLocation = async (reqBody: PatchUserLocationBody) => {
  const url = ApiEndpointsEnum.PATCH_PROFILE_LOCATION;
  return API.patch<UserResponse>(url, reqBody);
};

const usePatchUserLocation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['patch-user-location'],
    (reqBody: PatchUserLocationBody) => patchUserLocation(reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['patch-user-location']);
        queryClient.fetchQuery(['get-my-profile']);
      },
    },
  );
};

export {
  getRoles,
  useGetRoles,
  getLocations,
  useGetLocations,
  getMyProfile,
  useGetMyProfile,
  usePutKazamProfile,
  usePutHomeownerProfile,
  uploadProfilePhoto,
  useUploadProfilePhoto,
  usePatchUserProfile,
  usePatchUserProfileFCMToken,
  getMatches,
  useGetMatches,
  getTaC,
  useGetTaC,
  getPrivacyPolicy,
  useGetPrivacyPolicy,
  getUserById,
  useGetUserById,
  getConversation,
  useGetConversation,
  useGetConversationContent,
  usePostMarkAllMessagesAsRead,
  usePostMarkConversationContentAsRead,
  useGetUnreadConversations,
  postConversation,
  usePostConversation,
  getFinderSetting,
  useGetFinderSetting,
  postSignIn,
  usePostSignIn,
  putFilterSetting,
  usePutFilterSetting,
  postFavorite,
  usePostFavorite,
  getFavorites,
  useGetFavorites,
  deleteFavorite,
  useDeleteFavorite,
  postHide,
  usePostHide,
  getHides,
  useGetHides,
  useGetKaways,
  deleteHide,
  useDeleteHide,
  getOnlineStatus,
  useGetOnlineStatus,
  postOnlineStatus,
  usePostOnlineStatus,
  usePostKaway,
  useGetConversationById,
  usePostSignInAdmin,
  useGetBannedUsers,
  useGetUsers,
  useAcceptDisclaimer,
  usePostAbuseReport,
  useGetAbuseReports,
  useGetAbuseReporters,
  useGetReferralSummary,
  useGetReferralStatistics,
  useGetSystemStatistics,
  useGetReferralTransactions,
  useGetReferralsByUser,
  useGetReferralInfo,
  getProvinces,
  usePostBanUser,
  usePostUnbanUser,
  useGetProvinces,
  usePatchUserProfileStatus,
  usePostJob,
  useAcceptTerms,
  useAcceptPrivacyPolicy,
  getMultipleUsers,
  usePostFcmToken,
  useDeleteFcmToken,
  getMatchById,
  useGetLatestVersionInfo,
  putNewPassword,
  useGetReferralsByAdminGenerated,
  usePostSuspendUser,
  usePostUnsuspendUser,
  useGetSuspendedUsers,
  useGetPaginatedConversations,
  usePatchUserLocation
};
