import React, {createContext, useContext, useState, ReactNode} from 'react';

import {AuthMe} from '../server/types';

import {STORAGE} from '../utils/storage.utils';

type TAuth = {
  authData: AuthMe | null;
  updateAuthData: (data: AuthMe) => void;
  clearAuthData: () => void;
};

const AuthContext = createContext<TAuth>({
  authData: null,
  updateAuthData: () => {},
  clearAuthData: () => {},
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = (props: AuthProviderProps) => {
  const storedAuthData = STORAGE.get<AuthMe>('authData', true);

  const [authData, setAuthData] = useState<AuthMe | null>(storedAuthData);

  const updateAuthData = (data: AuthMe) => {
    STORAGE.save('authData', data, true);
    setAuthData({...data});
  };

  const clearAuthData = () => {
    STORAGE.clean('authData');
    setAuthData(null);
  };

  const providerValue = {
    authData,
    updateAuthData,
    clearAuthData,
  };

  return (
    <AuthContext.Provider value={providerValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const {authData, updateAuthData, clearAuthData} = useContext(AuthContext);

  return {
    authData,
    updateAuthData,
    clearAuthData,
  };
};
