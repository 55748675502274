import * as React from 'react';

function SvgPlusDark(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" ref={svgRef} {...props}>
    <path d="M8 3.83203V13.1654" stroke="#2E394C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.33301 8.5H12.6663" stroke="#2E394C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
  );
}

const ForwardRef = React.forwardRef(SvgPlusDark);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
