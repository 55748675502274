import { PersonalityTestData } from "../types/personality-test.types"

export const homeownerPersonalityTest: PersonalityTestData[] = [
  {question: 'personality_test_ho_q1'},
  {question: 'personality_test_ho_q2'},
  {question: 'personality_test_ho_q3'},
  {question: 'personality_test_ho_q4'},
  {question: 'personality_test_ho_q5'},
  {question: 'personality_test_ho_q6'},
  {question: 'personality_test_ho_q7'},
  {question: 'personality_test_ho_q8'},
  {question: 'personality_test_ho_q9'},
  {question: 'personality_test_ho_q10'}
]

export const kasambahayPersonalityTest: PersonalityTestData[] = [
  {question: 'personality_test_kz_q1'},
  {question: 'personality_test_kz_q2'},
  {question: 'personality_test_kz_q3'},
  {question: 'personality_test_kz_q4'},
  {question: 'personality_test_kz_q5'},
  {question: 'personality_test_kz_q6'},
  {question: 'personality_test_kz_q7'},
  {question: 'personality_test_kz_q8'},
  {question: 'personality_test_kz_q9'},
  {question: 'personality_test_kz_q10'}
]
