import React, {useEffect, useState} from "react";
import {Line} from "react-chartjs-2";
import {ChartData} from "chart.js";
import {GetReferralTransactionsResponse} from "../../../server/types";
import {getWeekNumber} from "../../../utils/date.util";
import {format} from "date-fns";

interface ReferralLineChartProps {
  data?: GetReferralTransactionsResponse;
}

const ReferralLineChart = (props: ReferralLineChartProps) => {
  const {data: referralTransactions} = props;

  const [data, setData] = useState<ChartData<'line', number[], string>>({
    labels: [],
    datasets: [
      {
        label: '',
        data: [],
        backgroundColor: '#998CFF',
        borderColor: '#998CFF',
      },
    ],
  });

  useEffect(() => {
    if (referralTransactions) {
      let newLabels: string[] = [];
      if (referralTransactions.group === 'weekly') {
        newLabels = referralTransactions.data.map(i => {
          let label = `week ${getWeekNumber(new Date(i.date))}`;
          const d = new Date(i.date);
          if (new Date('2021-020').getFullYear() !== d.getFullYear()) {
            label += `/${d.getFullYear()}`;
          }
          return label;
        });
      } else if (referralTransactions.group === 'monthly') {
        newLabels = referralTransactions.data.map(i => {
          const d = new Date(i.date);
          let label = format(d, 'MMM');
          if (new Date().getFullYear() !== d.getFullYear()) {
            label += `/${d.getFullYear()}`;
          }
          return label;
        });
      } else if (referralTransactions.group === 'yearly') {
        newLabels = referralTransactions.data.map(i => new Date(i.date).getFullYear().toString());
      } else {
        throw new Error('Invalid state');
      }
      setData(prevState => {
        return {
          ...prevState,
          labels: newLabels,
          datasets: [
            {
              ...prevState.datasets[0],
              data: referralTransactions.data.map(i => i.count)
            }
          ]
        }
      });
    }
  }, [referralTransactions]);

  return (
    <div>
      <Line
        height={160}
        data={data}
        options={{
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            }
          },
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }} />
    </div>
  );
};

export default ReferralLineChart;
