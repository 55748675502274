import React from 'react';
import {StyledRemoveFavoriteConfirmationDialog} from './styled';
import {Button, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {localization} from '../../../localization/Localization';

interface RemoveFavoriteConfirmationDialogProps {
  onCancel: () => void;
  onRemove: () => void;
}

const RemoveFavoriteConfirmationDialog = (
  props: RemoveFavoriteConfirmationDialogProps,
) => {
  return (
    <StyledRemoveFavoriteConfirmationDialog className="flex flex-col items-center">
      <div className="header">
        <Typography
          label={localization.dlg_hdr_remove_fave}
          variant="f3"
          weight="semibold"
          color={colorTheme.dark}
        />
      </div>

      <div className="message">
        <Typography
          label={localization.dlg_desc_remove_fave}
          align="center"
          variant="f1"
          color={colorTheme.darkFaded}
        />
      </div>

      <div className="actions flex flex-row">
        <Button
          label={localization.btn_cancel}
          variant="outlined"
          className="flex-1"
          onClick={props.onCancel}
        />
        <div className="spacer" />
        <Button
          label={localization.btn_remove}
          color="primary"
          className="flex-1"
          onClick={props.onRemove}
        />
      </div>
    </StyledRemoveFavoriteConfirmationDialog>
  );
};

export default RemoveFavoriteConfirmationDialog;
