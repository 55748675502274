import {Typography} from '../../../../../core/components';
import AdminPage from '../../../../../core/components/admin-page/AdminPage';
import {colorTheme} from '../../../../../core/configs';
import {StyledAdminUsersPage} from "../styled";
import AdminUsersTabs from "../AdminUsersTabs";
import React, {useState} from "react";
import AdminUsersSearch from "../AdminUsersSearch";
import AdminEkycVerifiedUsersTable from './AdminEkycVerifiedUsersTable';

const AdminEkycVerifiedUsersPage = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');

  const onSearchChangedHandler = (query: string) => {
    setSearchQuery(query);
  };

  return (
    <AdminPage>
      <StyledAdminUsersPage>
        <Typography
          label="Manage Users"
          variant="f3"
          weight="semibold"
          color={colorTheme.dark}
        />

        <AdminUsersTabs selected={6} />

        <AdminUsersSearch
          onChange={onSearchChangedHandler}
          showExtractButton={false}
          placeholder="Search user first name, last name  , or UUID" />

        <AdminEkycVerifiedUsersTable searchQuery={searchQuery}/>

      </StyledAdminUsersPage>
    </AdminPage>
  );
};

export default AdminEkycVerifiedUsersPage;
