import React, {useEffect, useState} from 'react';
import {ChatResponse, KazamException, UserResponse} from '../../server/types';
import {useHistory, useParams} from 'react-router-dom';
import {
  useDeleteFavorite,
  useDeleteHide,
  useGetConversation,
  useGetFavorites,
  useGetHides,
  useGetUserById,
  usePostConversation,
  usePostFavorite,
  usePostHide,
} from '../../server/react-query';
import {Icon} from '../../../core/components';
import KazamProfileView from '../../views/cards/kazam-profile/KazamProfileView';
import {StyledFavoriteView} from './styled';
import {useDialog} from '../../../core/components/dialog';
import RemoveFavoriteConfirmationDialog from '../../views/dialog-content/remove-favorite-confirmation-dialog/RemoveFavoriteConfirmationDialog';
import HideConfirmationDialog from '../../views/dialog-content/hide-confirmation-dialog/HideConfirmationDialog';
import {RoutePath} from '../../navigation/config/RouteConfig';
import {useAuth} from '../../hooks/useAuth';
import Page from '../../../core/components/page/Page';
import {useToast} from '../../../core/components/toast';
import { getPaywall } from '../../utils/admin.util';
import { useGetActiveTrialSubscription } from '../../server/react-query/useTrialSubscription';
import { useGetActiveSubscription } from '../../server/react-query/useUserSubscription';
import TrialSubscriptionDialogContentView from '../../views/dialog-content/paywall/trial-subscription-dialog/TrialSubscripionDialogContentView';
import SubscriptionsDialogContentView from '../../views/dialog-content/paywall/subscriptions-dialog/SubscriptionsDialogContentView';

interface FavoriteViewParams {
  id: string;
}

const FavoriteView = () => {
  const [userData, setUserData] = useState<UserResponse | undefined>(undefined);
  const [isFavorite, setIsFavorite] = useState<boolean>(true);
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [conversations, setConversations] = useState<ChatResponse[]>([]);
  const [existingConversation, setExistingConversation] = useState<
    ChatResponse | undefined
  >(undefined);

  const history = useHistory();
  const {authData} = useAuth();
  const {id: userId} = useParams<FavoriteViewParams>();
  const {
    data: hookUserData,
    refetch,
    isFetching
  } = useGetUserById(userId, true);
  const {data: hookFavoritesData} = useGetFavorites();
  const {data: hookHidesData} = useGetHides();
  const {data: hookDataConversations} = useGetConversation();
  const {
    data: userSubscription,
    isFetching: fetchingSubscription
  } = useGetActiveSubscription(true);
  const {data: userTrialSubscription} = useGetActiveTrialSubscription();
  const {presentDialog, dismissDialog} = useDialog();
  const {presentToast} = useToast();

  useEffect(() => {
    if (userId) {
      refetch();
    }
  }, [userId]);

  useEffect(() => {
    if (hookDataConversations) {
      setConversations(hookDataConversations.map(conv => ({...conv})));
    }
  }, [hookDataConversations]);

  useEffect(() => {
    const existingConversation = conversations.find(
      c => !!c.members.find(m => m.userId === userId),
    );
    setExistingConversation(existingConversation);
  }, [conversations, userId]);

  useEffect(() => {
    if (hookUserData) {
      setUserData({...hookUserData});
    }
  }, [hookUserData]);

  useEffect(() => {
    if (hookFavoritesData) {
      setIsFavorite(
        !!hookFavoritesData.find(fav => fav.tagUserId === userData?.userId),
      );
    }
  }, [hookFavoritesData, userData]);

  useEffect(() => {
    if (hookHidesData) {
      setIsHidden(
        !!hookHidesData.find(hide => hide.tagUserId === userData?.userId),
      );
    }
  }, [hookHidesData, userData]);

  const deleteFavorite = useDeleteFavorite(userId);
  const postFavorite = usePostFavorite({favoriteUserId: userId});

  const postHide = usePostHide({hideUserId: userId});
  const deleteHide = useDeleteHide(userId);

  const hideClickHandler = () => {
    presentDialog({
      headerText: '',
      content: (
        <HideConfirmationDialog onCancel={dismissDialog} onHide={hideHandler} />
      ),
      enableBackdropDismiss: true,
    });
  };

  const hideHandler = async () => {
    try {
      await postHide.mutateAsync();
      presentToast({
        message: 'Kazam successfully hidden.',
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      console.log(e);
    } finally {
      dismissDialog();
    }
  };

  const unhideClickHandler = async () => {
    try {
      await deleteHide.mutateAsync();
      presentToast({
        message: 'Kazam successfully unhidden.',
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      console.log(e);
    }
  };

  const removeFavoriteClickHandler = () => {
    presentDialog({
      headerText: '',
      content: (
        <RemoveFavoriteConfirmationDialog
          onCancel={dismissDialog}
          onRemove={removeFavoriteHandler}
        />
      ),
      enableBackdropDismiss: true,
    });
  };

  const removeFavoriteHandler = async () => {
    try {
      await deleteFavorite.mutateAsync();
      presentToast({
        message: 'Successfully removed from favorites.',
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      console.log(e);
    } finally {
      dismissDialog();
    }
  };

  const makeFavoriteHandler = async () => {
    try {
      await postFavorite.mutateAsync();
      presentToast({
        message: 'Successfully added to favorites.',
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      console.log(e);
    }
  };

  const paywall = getPaywall();
  const messageClickHandler = async () => {
    if (paywall && paywall.enabled) {
      if (!existingConversation
        && ((!userTrialSubscription && authData?.user.isEligibleForTrial)
          || (!!userTrialSubscription && userTrialSubscription.remainingFreeChats > 0))) {
          showTrialSubscriptionDialog()
          return
      }

      if (!userSubscription
        && ((!userTrialSubscription && !authData?.user.isEligibleForTrial)
          || (!!userTrialSubscription 
            && userTrialSubscription.remainingFreeChats === 0
            && !existingConversation))) {
          showPaywallDialog()
          return
      }
    }

    if (existingConversation) {
      // Has existing conversation
      history.push(
        RoutePath.CHAT_ROOM.replace(
          ':id',
          existingConversation.conversationChannelId,
        ),
      );
    } else {
      await createConversation()
    }
  };

  const postConversation = usePostConversation();
  const createConversation = async () => {
    try {
      var conversation = (await postConversation.mutateAsync({
        conversationChannelName: `Conversation with ${userData?.userId}`, // Use user's name as conversation name
        memberIds: [userData?.userId || '', authData?.user?.userId || '']
      })) as ChatResponse;
      
      if (conversation) {
        history.push(
          RoutePath.CHAT_ROOM.replace(
            ':id',
            conversation.conversationChannelId,
          ),
        );
      }
    } catch (e: any) {
      console.log(e);
      const exception = e.data as KazamException;
      showError(exception ? exception.error : "Failed to create.")
    }
  }

  const showPaywallDialog = () => {
    presentDialog({
      headerText: '',
      enableBackdropDismiss: true,
      content: (
        <SubscriptionsDialogContentView
          userId={userData?.userId}
          onSuccess={() => dismissDialog()}
          onError={(v) => showError(v)} />
      )
    });
  };

  const showTrialSubscriptionDialog = () => {
    presentDialog({
      headerText: '',
      enableBackdropDismiss: true,
      content: (
        <TrialSubscriptionDialogContentView
          userId={userData?.userId} 
          onSuccess={(id) => {
            dismissDialog();
            history.push(
              RoutePath.CHAT_ROOM.replace(':id', id)
            );
          }}
          onError={(v) => {
            showError(v);
          }}       />
      ),
    });
  }

  const showError = (message: string) => {
    presentToast({
      message: message,
      variant: 'error',
      position: 'bottom',
    })
  }

  return (
    <Page
      showBackButton
      showDesktopBackButton
      showLoading={isFetching || fetchingSubscription}>
      <StyledFavoriteView>
        <div className="page-content flex-1">
          <KazamProfileView profile={userData} />
        </div>
        <div className="bottom-nav flex flex-row w-full items-center justify-center">
          <Icon
            className="action-left"
            name={isHidden ? 'xCircleFilledGradient' : 'xCircleGradient'}
            size={30}
            type="button"
            onClick={isHidden ? unhideClickHandler : hideClickHandler}
          />

          <Icon
            className="action-middle"
            name={isFavorite ? 'heartFilledGradient' : 'heartGradient'}
            size={48}
            type="button"
            onClick={
              isFavorite ? removeFavoriteClickHandler : makeFavoriteHandler
            }
          />

          <Icon
            className="action-right"
            name="messageCircleGradient"
            size={30}
            type="button"
            onClick={messageClickHandler}
          />
        </div>
      </StyledFavoriteView>
    </Page>
  );
};

export default FavoriteView;
