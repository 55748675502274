import {useQuery} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';
import { SubscriptionHistory, UserSubscriptionResponse } from '../types/user-subscription.types';

const getActiveSubscription = (nullable: boolean) => {
  const url = ApiEndpointsEnum.GET_ACTIVE_SUBSCRIPTION + `?nullable=${nullable}`
  return API.get<UserSubscriptionResponse>(url);
};

const useGetActiveSubscription = (nullable: boolean) => {
  return useQuery(['get-active-subscription'], () => getActiveSubscription(nullable));
};

const getLatestSubscription = () => {
  return API.get<UserSubscriptionResponse>(ApiEndpointsEnum.GET_LATEST_SUBSCRIPTION);
};

const useGetLatestSubscription = () => {
  return useQuery(['get-latest-subscription'], () => getLatestSubscription());
};

const getSubscriptionHistory = () => {
  return API.get<SubscriptionHistory[]>(ApiEndpointsEnum.GET_SUBSCRIPTION_HISTORY);
};

const useGetSubscriptionHistory = () => {
  return useQuery(['get-subscription-history'], () => getSubscriptionHistory());
};

const getUserSubscription = (id: string) => {
  const url = ApiEndpointsEnum.GET_USER_SUBSCRIPTION.replace('{id}', id);
  return API.get<UserSubscriptionResponse>(url);
};

const useGetUserSubscription = (id: string) => {
  return useQuery(['get-user-subscription'], () => getUserSubscription(id));
};

export {
  getActiveSubscription,
  useGetActiveSubscription,
  useGetSubscriptionHistory,
  useGetUserSubscription,
  useGetLatestSubscription
};
