import {Typography} from "../../../../core/components";
import {colorTheme} from "../../../../core/configs";
import {StyledAdminChatsTabs} from "./styled";
import { UserRole } from "../../../server/types";

interface AdminUsersTabsProps {
  selected: UserRole;
  onChange?: (tab: UserRole) => void;
  className?: string;
}

const AdminChatsTabs = (props: AdminUsersTabsProps) => {
  const {selected, onChange, className} = props;
  const tabs: UserRole[] = ['homeowner', 'kazam']

  const onTabClickedHandler = (tab: UserRole) => {
    onChange && onChange(tab);
  };

  return (
    <StyledAdminChatsTabs className={['tabs-control flex flex-row', className].join(' ')}>
      {tabs.map((t, i) => {
        return (
          <div
            key={i}
            className={["tab-control cursor-pointer", selected === t ? 'active' : ''].join(' ')}
            onClick={() => onTabClickedHandler(t)}>
            <Typography
              className="capitalize"
              label={t === 'kazam' ? 'Kasambahay' : t}
              variant="f2"
              weight="semibold"
              color={selected === t ? colorTheme.white : colorTheme.dark}
              align="center" />
          </div>
        )
      })}
    </StyledAdminChatsTabs>
  );
};

export default AdminChatsTabs;
