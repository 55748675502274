import {yupResolver} from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import {Controller, useForm} from 'react-hook-form';
import {
  Button,
  Icon,
  Input,
  Typography,
} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {postReferalLinkSchema} from '../../../schema/admin.schema';
import {usePostReferralLink, usePutReferalLink} from '../../../server/react-query';
import {
  PostReferralLinkBody, ReferralLinkResponse,
} from '../../../server/types/admin-control.types';
import {SyledContent} from './styles';

interface CreateReferralLinkModalViewProps {
  data?: ReferralLinkResponse;
  onClose: () => void;
  onCancel: () => void;
  onCreated: () => void;
  onError: (e: any) => void;
}

const CreateReferralLinkModalView = (props: CreateReferralLinkModalViewProps) => {

  const postReferralLink = usePostReferralLink();

  const {
    control,
    getValues,
    handleSubmit,
    reset,
    setError,
    formState: {errors},
  } = useForm<PostReferralLinkBody>({
    resolver: yupResolver(postReferalLinkSchema),
    defaultValues: {
      name: '',
      code: '',
      type: '',
    },
  });

  useEffect(() => {
    if (props.data) {
      reset(props.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  const submitForm = async () => {
    handleSubmit(handleCreate)();
  };

  const putReferralLink = usePutReferalLink(getValues(), props.data?.referralLinkId ?? "");
  const handleCreate = async () => {
    try {
      if (props.data) {
        await putReferralLink.mutateAsync();
      } else {
        await postReferralLink.mutateAsync(getValues());
      }
      
      props.onCreated();
    } catch (e: any) {
      if (e.status && e.status === 409) {
        setError("code", {message: "Referral code exists"});
      } else {
        props.onError(e);
      }
    }
  };

  return (
    <div className="flex flex-col items-center px-4 pb-4 space-y-2">
      <div className="flex w-full justify-between items-center">
        <Typography
          label="Create Referral Link"
          variant="f3"
          weight="semibold"
          color={colorTheme.dark}
        />
        <Icon
          name="x"
          color={colorTheme.dark}
          size={24}
          type="button"
          onClick={props.onClose}
          disabled={postReferralLink.isLoading}
        />
      </div>

      <SyledContent>
        <div className="space-y-2">
          <div>
            <Controller
              control={control}
              name="name"
              render={({field: {value = '', onBlur, onChange}}) => (
                <Input
                  label="Name"
                  inputSize="large"
                  placeholder="Enter name"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={errors.name?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              control={control}
              name="code"
              render={({field: {value = '', onBlur, onChange}}) => (
                <Input
                  label="Referral Code"
                  inputSize="large"
                  placeholder="Enter referral code"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={errors.code?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              control={control}
              name="type"
              render={({field: {value = '', onBlur, onChange}}) => (
                <Input
                  label="Type"
                  inputSize="large"
                  placeholder="Enter type"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={errors.type?.message}
                />
              )}
            />
          </div>
        </div>
      </SyledContent>
      <div className="w-full flex flex-row space-x-2 pt-4">
        <Button
          label="Cancel"
          variant="outlined"
          className="flex-1"
          onClick={props.onCancel}
          disabled={postReferralLink.isLoading}
        />
        <div className="spacer" />
        <Button
          label={props.data ? "Update" : "Create"}
          color="primary"
          className="flex-1"
          onClick={submitForm}
          isLoading={postReferralLink.isLoading || putReferralLink.isLoading}
        />
      </div>
    </div>
  );
};

export default CreateReferralLinkModalView;
