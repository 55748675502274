import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import firebase from 'firebase/app';
import {useAuth} from './useAuth';
import {firebaseEnsureInitialized} from '../utils/firebase.util';
import {calculateAge} from '../utils/date.util';
import {capitalize} from '../utils/random.util';
import {UserResponse} from '../server/types';

firebaseEnsureInitialized();

type StringOrNumberType = string | number;

export type AnalyticsEvent =
  | 'ad_viewed'
  | 'ad_clicked'
  | 'ad_banner_viewed'
  | 'ad_banner_clicked'
  | 'sign_in'
  | 'component_enter'
  | 'component_leave'
  | 'screen_view'
  | 'session'
  | 'complete_registration'
  | 'complete_registration_with_photo'
  | 'kazam_reg_step_1'
  | 'kazam_reg_step_2'
  | 'kazam_reg_step_3'
  | 'kazam_reg_step_4'
  | 'kazam_reg_step_5'
  | 'kazam_reg_step_6_opt_in'
  | 'kazam_reg_step_6_opt_out'
  | 'kazam_reg_step_7_opt_in'
  | 'kazam_reg_step_7_opt_out'
  | 'homeo_reg_step_1'
  | 'homeo_reg_step_2'
  | 'homeo_reg_step_3'
  | 'homeo_reg_step_4_opt_in'
  | 'homeo_reg_step_4_opt_out'
  | 'homeo_reg_step_5_opt_in'
  | 'homeo_reg_step_5_opt_out'
  | 'active_users'
  | 'system_statistics'
  | 'page_session_start'
  | 'page_session_end'
  | 'unique_chat_session'
  | 'chat_message_received'
  | 'chat_message_with_attachment_received'
  | 'number_of_waves'
  | 'number_of_favorites'
  | 'add_to_favorites_click'
  | 'remove_from_favorites_click'
  | 'unique_wave'
  | 'signup_as_kazam'
  | 'signup_as_homeowner'
  | 'non_user_clicked_burger_menu'
  | 'non_user_clicked_home'
  | 'non_user_clicked_about_us'
  | 'non_user_clicked_terms_and_conditions'
  | 'non_user_clicked_data_privacy_policy'
  | 'non_user_clicked_sign_in';

type TAnalyticsContext = {
  systemAnalytics: firebase.analytics.Analytics;
  userAnalytics?: firebase.analytics.Analytics;
  initializeUser: (user: UserResponse) => void;
  systemLogEvent: (
    eventName: AnalyticsEvent,
    params?: {[key: string]: StringOrNumberType},
  ) => void;
};

// Singleton
const systemAnalyticsInstance = firebase.analytics();

const AnalyticsContext = createContext<TAnalyticsContext>({
  systemAnalytics: systemAnalyticsInstance,
  initializeUser: (_: UserResponse) => {},
  systemLogEvent: (
    _: AnalyticsEvent,
    __?: {[key: string]: StringOrNumberType},
  ) => {},
});

interface AnalyticsProviderProps {
  children: ReactNode;
}

export const AnalyticsProvider = (props: AnalyticsProviderProps) => {
  const [systemAnalytics] = useState<firebase.analytics.Analytics>(
    systemAnalyticsInstance,
  );
  const [userAnalytics, setUserAnalytics] = useState<
    firebase.analytics.Analytics | undefined
  >(undefined);

  const {authData} = useAuth();

  useEffect(() => {
    if (authData && authData.user) {
      if (userAnalytics) {
        userAnalytics.setUserId(authData.user.userId);
        userAnalytics.setUserProperties({
          age: calculateAge(new Date(authData.user.birthDate)).toString(),
          gender: capitalize(authData.user.gender),
          role: authData.user.userRole === 'homeowner' ? 'homeowner' : 'kazam',
        });

        if (authData.user.filterSetting) {
          userAnalytics.setUserProperties({
            city:
              authData.user.filterSetting.location?.cityOrMunicipality +
              (authData.user.filterSetting.location?.hasProvince
                ? `, ${authData.user.filterSetting.location?.province.name}`
                : ''),
            salary: authData.user.filterSetting.salary,
          });
        }
      } else {
        setUserAnalytics(firebase.analytics());
        console.log('User analytics has been initialized');
      }
    } else {
      setUserAnalytics(undefined);
      console.log('User analytics has been cleared');
    }
  }, [authData, userAnalytics]);

  const initializeUser = (user: UserResponse) => {
    const instance = firebase.analytics();
    instance.setUserId(user.userId);
    instance.setUserProperties({
      age: calculateAge(new Date(user.birthDate)).toString(),
      gender: capitalize(user.gender),
      role: user.userRole === 'homeowner' ? 'homeowner' : 'kazam',
    });

    if (user.filterSetting) {
      instance.setUserProperties({
        city:
          user.filterSetting.location?.cityOrMunicipality +
          (user.filterSetting.location?.hasProvince
            ? `, ${user.filterSetting.location?.province.name}`
            : ''),
        salary: user.filterSetting.salary,
      });
    }
    setUserAnalytics(instance);
  };

  const systemLogEvent = (
    eventName: AnalyticsEvent,
    params?: {[key: string]: StringOrNumberType},
  ) => {
    try {
      let allParams = {
        ...(params || {}),
      };

      if (userAnalytics && authData) {
        allParams = {
          ...allParams,
          age: calculateAge(new Date(authData.user.birthDate)).toString(),
          gender: capitalize(authData.user.gender),
          role: authData.user.userRole === 'homeowner' ? 'homeowner' : 'kazam',
        };

        if (authData.user.filterSetting) {
          allParams = {
            ...allParams,
            city:
              authData.user.filterSetting.location?.cityOrMunicipality +
              (authData.user.filterSetting.location?.hasProvince
                ? `, ${authData.user.filterSetting.location?.province.name}`
                : ''),
            salary: authData.user.filterSetting.salary?.toString() || '',
          };
        }

        userAnalytics.logEvent(eventName.toString(), allParams);
      } else {
        systemAnalytics.logEvent(eventName.toString(), allParams);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <AnalyticsContext.Provider
      value={{
        systemAnalytics: systemAnalytics,
        userAnalytics: userAnalytics,
        initializeUser: initializeUser,
        systemLogEvent: systemLogEvent,
      }}>
      {props.children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  const {
    systemAnalytics,
    userAnalytics,
    initializeUser,
    systemLogEvent,
  } = useContext(AnalyticsContext);

  return {
    systemAnalytics, // Deprecated, use `systemLogEvent` instead
    userAnalytics, // Deprecated, use `userLogEvent` instead
    initializeUser,
    systemLogEvent,
  };
};
