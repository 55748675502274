import * as React from 'react';
import {SVGProps, Ref, forwardRef, memo} from 'react';
const SvgDeclineVoice = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 25"
    ref={ref}
    {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m19 5.5-14 14"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.96 13.125a15.649 15.649 0 0 1-.465-.706l1.833-1.834a2 2 0 0 0 .547-1.806l-.734-3.671A2 2 0 0 0 7.18 3.5H5.033c-1.11 0-2.084.936-1.953 2.14.224 2.052.904 4.626 2.287 7.106.342.612.727 1.22 1.16 1.813l1.433-1.434Zm1.26 1.569-1.415 1.415a16.154 16.154 0 0 0 4.588 3.441c1.824.918 3.956 1.572 6.432 1.85 1.2.135 2.175-.825 2.175-1.967v-2.152a2 2 0 0 0-1.515-1.94l-2.694-.674a2 2 0 0 0-1.899.526l-2.232 2.232a14.14 14.14 0 0 1-3.44-2.731ZM19 19.407c-1.66-.19-3.126-.564-4.422-1.07l1.728-1.73 2.694.674v2.126ZM7.914 9.171l-1.405 1.405A17.151 17.151 0 0 1 5.077 5.5H7.18l.734 3.671Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgDeclineVoice);
const Memo = memo(ForwardRef);
export default Memo;
