import * as React from 'react';
import {SVGProps, Ref, forwardRef, memo} from 'react';
const SvgCrossLight = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"  ref={ref} {...props}>
        <path d="M12 4.5L4 12.5" stroke="#2E394C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4 4.5L12 12.5" stroke="#2E394C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

);
const ForwardRef = forwardRef(SvgCrossLight);
const Memo = memo(ForwardRef);
export default Memo;
