import React, {useEffect, useState} from 'react';
import {Column, usePagination, useTable} from 'react-table';
import {StyledAdminTable} from './styled';
import {Icon, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';

type ManualRenderFn = (columnId: string, rowData: any) => React.ReactNode;

interface AdminTableProps {
  columns: Column<any>[];
  data: any[];
  pageNumber?: number;
  pageSize: number;
  pageCount: number;
  showDebugView?: boolean;
  manualRenderFn?: ManualRenderFn;
  onPageChange?: (pageNumber: number) => void;
  rowClassName?: string;
  onRowClick?: (rowData: any) => void;
}

const AdminTable = (props: AdminTableProps) => {
  const {
    columns: pColumns,
    data: pData,
    pageNumber: pPageNumber,
    pageSize: pPageSize,
    pageCount: pPageCount,
    showDebugView: pShowDebugView,
    manualRenderFn,
    onPageChange,
    rowClassName,
    onRowClick,
  } = props;

  const [columns, setColumns] = useState<Column<any>[]>(pColumns);
  const [data, setData] = useState<any[]>(pData);
  const [pageCount, setPageCount] = useState<number>(pPageCount);
  const [pageNumber, setPageNumber] = useState<number>(pPageNumber ? pPageNumber : 1);

  const [showDebugView] = useState<boolean>(pShowDebugView === true);

  const maxNumberOfPagesShown = 20;
  const pagesArray = Array.from({ length: maxNumberOfPagesShown }, (_, i) => i);

  useEffect(() => {
    if (pPageNumber) {
      setPageNumber(pPageNumber);
      tableInstance.state.pageIndex = pPageNumber -1;
    }
  }, [pPageNumber]);

  useEffect(() => {
    setData(pData);
  }, [pData]);

  useEffect(() => {
    setColumns(pColumns);
  }, [pColumns]);

  useEffect(() => {
    setPageCount(pPageCount);
  }, [pPageCount]);

  const tableInstance = useTable(
    {
      columns: columns,
      data: data,
      initialState: {
        pageIndex: pageNumber-1,
        pageSize: pPageSize,
      },
      manualPagination: true,
      pageCount: pageCount,
    },
    usePagination,
  );

  const {
    // getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    // pageOptions,
    pageCount: statePageCount,
    gotoPage,
    // nextPage,
    // previousPage,
    // setPageSize: tableSetPageSize,
    state: {pageIndex: statePageIndex, pageSize: statePageSize},
  } = tableInstance;

  // useEffect(() => {
  //   const currentPage = statePageIndex + 1;
  //   if (pageCount > 0) {
  //     if (currentPage > pageCount) {
  //       const newPage = pageCount; // Last page
  //       onPageChange && onPageChange(newPage);
  //       gotoPage(newPage - 1);
  //     }
  //   }
  // }, [pageCount, statePageIndex]);

  const getPageStart = () : number => {
    const pageNumber = statePageIndex + 1;
    const modulo = pageNumber % maxNumberOfPagesShown;
  
    if (modulo === 0) {
      return pageNumber - (maxNumberOfPagesShown - 1);
    } else {
      return pageNumber - (modulo - 1);
    }
  };

  const onNextPageBlockClickedHandler = () => {
    const nextPageBlock = getPageStart() + maxNumberOfPagesShown;
    const newPage = nextPageBlock < pageCount ? nextPageBlock : pageCount;
    onPageChange && onPageChange(newPage);
    gotoPage(newPage - 1);
  };

  const onPreviousPageBlockClickedHandler = () => {
    const prevPageBlock = getPageStart() - maxNumberOfPagesShown;
    const newPage = prevPageBlock > 1 ? prevPageBlock : 1;
    onPageChange && onPageChange(newPage);
    gotoPage(newPage - 1);
  };

  const onGotoPageNumberClickedHandler = (pageNumber: number) => {
    onPageChange && onPageChange(pageNumber);
    gotoPage(pageNumber - 1);
  };

  const trClickHandler = (rowData: any) => {
    onRowClick && onRowClick(rowData.original);
  };

  return (
    <StyledAdminTable>
      {showDebugView && (
        <pre>
          <code>
            {JSON.stringify(
              {
                pageIndex: statePageIndex,
                pageSize: statePageSize,
                pageCount: statePageCount,
                canNextPage,
                canPreviousPage,
              },
              null,
              2,
            )}
          </code>
        </pre>
      )}
      <div className="table-container">
        <table className="w-full">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={[
                    row.getRowProps().className,
                    rowClassName ? rowClassName : '',
                    onRowClick ? 'cursor-pointer' : 'cursor-default',
                  ].join(' ')}
                  onClick={() => trClickHandler(row)}>
                  {row.cells.map(cell => {
                    const rowData = cell.row.original;
                    const columnId = cell.column.id;
                    if (rowData.hasOwnProperty(columnId)) {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={
                            cell.row.index % 2 === 0 ? 'row-dark' : ''
                          }>
                          {cell.render('Cell')}
                        </td>
                      );
                    } else {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={
                            cell.row.index % 2 === 0 ? 'row-dark' : ''
                          }>
                          {manualRenderFn
                            ? manualRenderFn(columnId, rowData)
                            : undefined}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="pagination flex flex-row items-center">
        {statePageIndex + 1 - maxNumberOfPagesShown > 0 && (
          <div
            className="pagination-control nav-button cursor-pointer"
            onClick={onPreviousPageBlockClickedHandler}>
            <div onClick={() => {}}>
              <Icon name="chevronLeft" type="button" color={colorTheme.dark} />
            </div>
          </div>
        )}

        {statePageIndex + 1 - maxNumberOfPagesShown > 0 && (
          <div className="pagination-control ellipses">
            <Typography
              label={'...'}
              variant="f2"
              weight="semibold"
              color={colorTheme.dark}
            />
          </div>
        )}

        {pagesArray.map(i => {
          const pageNumber = getPageStart() + i;
          if (pageNumber <= pageCount) {
            const isActive = pageNumber === statePageIndex + 1;
            return (
              <div
                key={pageNumber}
                className={[
                  'pagination-control page-button',
                  isActive ? 'selected' : 'clickable',
                ].join(' ')}
                onClick={() => onGotoPageNumberClickedHandler(pageNumber)}>
                <Typography
                  label={pageNumber.toString()}
                  variant="f2"
                  weight="semibold"
                  color={isActive ? colorTheme.white : colorTheme.dark}
                />
              </div>
            );
          } else {
            return undefined;
          }
        })}

        {getPageStart() + (maxNumberOfPagesShown - 1) < pageCount && (
          <div className="pagination-control ellipses">
            <Typography
              label={'...'}
              variant="f2"
              weight="semibold"
              color={colorTheme.dark}
            />
          </div>
        )}

        {getPageStart() + (maxNumberOfPagesShown - 1) < pageCount && (
          <div
            className="pagination-control nav-button cursor-pointer"
            onClick={onNextPageBlockClickedHandler}>
            <Icon name="chevronRight" type="button" color={colorTheme.dark} />
          </div>
        )}
      </div>
    </StyledAdminTable>
  );
};

export default AdminTable;
