import {useMutation, useQuery, useQueryClient} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';
import {ChatContentResponse, ChatResponse, PostConversationBody, UploadMediaReqBody} from '../types';
import {AxiosRequestConfig} from 'axios';
import { PutConversationContent, PutConversationContentBody, PutConversationStatusBody } from '../types/conversation.types';

const uploadChatMedia = async (reqBody: UploadMediaReqBody) => {
  const headerAuthorization: any = {
    'Content-Type': 'multipart/form-data',
  };

  const config: AxiosRequestConfig = {
    headers: {
      ...headerAuthorization,
    },
  };

  const formData = new FormData();

  formData.append('image', reqBody.file!);
  const url = ApiEndpointsEnum.POST_MEDIA.replace('{id}', reqBody.channelId);
  return API.post<string>(url, formData, config);
};

const useUploadChatMedia = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['upload-chat-media'],
    (reqBody: UploadMediaReqBody) => uploadChatMedia(reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['upload-chat-media']);
      },
    },
  );
};

const putConversationStatus = (reqBody: PutConversationStatusBody) => {
  const url = ApiEndpointsEnum.PUT_CONVERSATION_STATUS.replace('{id}', reqBody.channelId);
  return API.put<ChatResponse>(url, {status: reqBody.status});
};

const usePutConversationStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(['put-conversation-status'], (reqBody: PutConversationStatusBody) => putConversationStatus(reqBody), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['put-conversation-status']);
    },
  });
};

const putConversationContent = (reqBody: PutConversationContentBody) => {
  const url = ApiEndpointsEnum.PUT_CONVERSATION_CONTENT.replace('{id}', reqBody.contentId);
  return API.put<ChatContentResponse>(url, reqBody.body);
};

const usePutConversationContent = () => {
  const queryClient = useQueryClient();

  return useMutation(['put-conversation-content'], (reqBody: PutConversationContentBody) => putConversationContent(reqBody), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['put-conversation-content']);
    },
  });
};

const getLatestConversationContent = (channelId: string, content: string) => {
  const apiUrl = ApiEndpointsEnum.GET_LATEST_CONVERSATION_CONTENT.replace('{id}', channelId);
  const url = apiUrl + (content.trim() ? `?content=${content}` : '');
  return API.get<ChatContentResponse>(url);
};

const useGetLatestConversationContent = (channelId: string, content: string) => {
  return useQuery(['get-latest-content', content], () =>
    getLatestConversationContent(channelId, content),
  );
};

const postCurrentLocation = (channelId: string, reqBody: PutConversationContent) => {
  const url = ApiEndpointsEnum.POST_CURRENT_LOCATION.replace('{id}', channelId);
  return API.post<ChatContentResponse>(url, reqBody);
};

const usePostCurrentLocation = (channelId: string) => {
  const queryClient = useQueryClient();

  return useMutation(['post-current-location'],
    (reqBody: PutConversationContent) => postCurrentLocation(channelId, reqBody), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['put-current-location']);
    },
  });
};

const getConversationContentById = (contentId: string) => {
  const apiUrl = ApiEndpointsEnum.GET_CONVERSATION_CONTENT.replace('{id}', contentId);
  return API.get<ChatContentResponse>(apiUrl);
};

const useGetConversationContentById = (contentId: string) => {
  return useQuery(['get-conversation-content-by-id', contentId], () =>
  getConversationContentById(contentId),
  );
};

export {
  useUploadChatMedia,
  usePutConversationStatus,
  usePutConversationContent,
  useGetLatestConversationContent,
  usePostCurrentLocation,
  useGetConversationContentById
};
