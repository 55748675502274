import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import {StyledDatePicker} from './styled';
import {useState} from 'react';
import {useDialog} from '../dialog';
import DatePickerDialog from '../../../app/views/dialog-content/date-picker-dialog/DatePickerDialog';
import {Input} from '..';

export type SeparatedDatePickerProps = {
  label?: string;
  placeholder?: string;
  value?: Date;
  className?: string;
  error?: string;
  onChange?: (value?: Date) => void;
  /**
   * To be fixed
   */
  ref?: any;
  enableFutureDates?: boolean;
  disabled?: boolean
  verified?: boolean
};

const SeparatedDatePickerComponent = (props: SeparatedDatePickerProps) => {
  const {
    label,
    placeholder,
    value,
    className,
    error,
    onChange,
    enableFutureDates = false,
    disabled = false,
    verified = false,
  } = props;

  const {presentDialog, dismissDialog} = useDialog();
  const [selectedDay, setSelectedDay] = useState<Date | undefined>(
    props.value ? props.value : undefined,
  );

  const showDatePicker = () => {
    presentDialog({
      headerText: '',
      content: (
        <DatePickerDialog
          value={value}
          onSelectDate={date => {
            setSelectedDay(date);
            if (onChange) {
              onChange(date);
            }
            dismissDialog();
          }}
        />
      ),
      enableBackdropDismiss: false,
      fullWidth: true,
    });
  };

  return (
    <StyledDatePicker error={error} hasValue={selectedDay ? true : false}>
      <div>
        <Input
          label={label}
          inputSize="large"
          type={'text'}
          placeholder={placeholder}
          readOnly
          value={value ? value.toLocaleDateString() : ''}
          error={error}
          disabled={disabled}
          verified={verified}
          onClick={showDatePicker}
        />
      </div>
    </StyledDatePicker>
  );
};

export default SeparatedDatePickerComponent;
