import React, {useEffect, useState} from 'react';
import {QuantityInput, Toggle, Typography} from '../../../../core/components';
import {StyledWrapper} from './styled';
import {colorTheme} from '../../../../core/configs';
import {usePutAdminControl} from '../../../server/react-query';
import {useToast} from '../../../../core/components/toast';
import {
  AdminControl,
  AdminControlReqBody,
  PaywallContent,
  QuantityContent,
} from '../../../server/types/admin-control.types';
import Divider from '../../../../core/components/divider/Divider';
import { KazamException } from '../../../server/types';

interface PaywallControllViewProps {
  data: AdminControl[];
}

const PaywallControllView = ({data}: PaywallControllViewProps) => {
  const {presentToast} = useToast();

  const [control, setControl] = useState<AdminControl | undefined>()
  const [enablePaywall, setEnablePaywall] = useState(false);
  const [trialFreeChats, setTrialFreeChats] = useState<QuantityContent>();
  const [content, setContent] = useState<PaywallContent>();

  const putAdminControl = usePutAdminControl();

  useEffect(() => {
    if (data) {
      var paywallControl = data.find(d => d.type === 'paywallControl');
      if (paywallControl) {
        setControl(paywallControl)
        setEnablePaywall(paywallControl.enabled);
        if (paywallControl.content) {
          var content = JSON.parse(
            paywallControl?.content
          ) as PaywallContent;
          setContent(content)
          setTrialFreeChats({value: content.freeChats})
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (control) {
      setEnablePaywall(control.enabled);
      if (control.content) {
        var content = JSON.parse(
          control?.content
        ) as PaywallContent;
        setContent(content)
        setTrialFreeChats({value: content.freeChats});
      }
    }
  }, [control])

  const handleToggle = (toggled: boolean) => {
    setEnablePaywall(toggled)    
    if (control) {
      const data: AdminControlReqBody = {
        type: control.type,
        enabled: toggled,
        content: control.content,
      };
      
      onPutAdminControl(data);
    }
  }

  const onSave = async (value: QuantityContent) => {
    const payload = {
      freeChats: value.value,
      defaultStartDate: content?.defaultStartDate
    } as PaywallContent;
    
    const data: AdminControlReqBody = {
      type: 'paywallControl',
      enabled: enablePaywall,
      content: JSON.stringify(payload),
    };
    onPutAdminControl(data)
  };

  const onPutAdminControl = async (data: AdminControlReqBody) => {
    try {
      const res = await putAdminControl.mutateAsync(data);
      setControl(res)
      presentToast({
        message: `Successfully saved.`,
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      console.log(e);
      const exception = e.data as KazamException;
      presentToast({
        message: exception.error ?? "Failed to save.",
        variant: 'error',
        position: 'bottom',
      })
    }
  };

  return (
    <StyledWrapper>
      <Typography
        label="PAYWALL"
        variant="f1"
        weight="semibold"
        color={colorTheme.black}
      />

      <div className="pt-3 flex flex-row justify-between items-center">
        <div>
          <Typography
            label="Enable paywall feature"
            variant="f3"
            color={enablePaywall ? colorTheme.dark : colorTheme.lightGray}
          />
          <Typography
            label="This will require homeowner to subscribe first before initiating a conversation."
            variant="f1"
            color={enablePaywall ? colorTheme.darkFaded : colorTheme.lightGray}
          />
        </div>
        <div>
          <Toggle value={enablePaywall} onToggle={handleToggle} />
        </div>
      </div>

      {enablePaywall && 
        <>
          <Divider />

          <div className="flex flex-col space-y-3">
            <div>
              <Typography
                label="Trial Package"
                variant="f3"
                color={colorTheme.dark}
              />
              <Typography
                label="Set the maximum number of free chats during trial. "
                variant="f1"
                color={colorTheme.darkFaded}
              />
            </div>

            <div>
              <QuantityInput
                label='Free Chats'
                quantity={trialFreeChats}
                onSubmit={onSave}
                isLoading={putAdminControl.isLoading}
              />
            </div>
          </div>
        </>
      }
    </StyledWrapper>
  );
};

export default PaywallControllView;
