import React, {useEffect, useState} from 'react';
import {
  StyledMainPageWrapper,
  StyledMainWrapper,
  StyledNotificationContent,
  StyledNotificationEmpty,
  StyledNotificationWrapper,
  StyledPageContent,
  StyledPageWrapper,
  StyledSideBarWrapper,
} from './styled';
import {BeatLoader} from 'react-spinners';
import {colorTheme} from '../../configs';
import {Button, NavBar, Typography} from '..';
import Icon, {IconName} from '../icon';
import {isAndroid, isMobile} from 'react-device-detect';
import SideBar from '../side-drawer/SideBar';
import {useAuth} from '../../../app/hooks/useAuth';
import {
  getNotifications,
  usePostMarkAllNotificationsAsRead,
} from '../../../app/server/react-query';
import {NotificationResponse} from '../../../app/server/types/notification.types';
import {useQuery} from 'react-query';
import KazamNotificationView from '../../../app/views/cards/kazam-notification/KazamNotificationView';
import NoResultView from '../../../app/views/cards/no-result/NoResultView';
import noNotification from '../../../assets/no_notifications.svg';
import {RoutePath} from '../../../app/navigation/config/RouteConfig';
import {useHistory, useLocation} from 'react-router';
import HomeownerNotificationView from '../../../app/views/cards/homeowner-notification/HomeownerNotificationView';
import {Action} from 'history';
import {localization} from '../../../app/localization/Localization';
import BannerAd from '../../../app/views/ads/banner-ad/BannerAd';
import { App } from '@capacitor/app';
import { isUsingBrowser } from '../../../app/utils/random.util';
import JobOfferNotificationView from '../../../app/views/cards/kazam-notification/JobOfferNotificationView';

interface PageProps {
  children: React.ReactNode;
  className?: string;
  hideNavbar?: boolean;
  isLanding?: boolean;
  unPadded?: boolean;
  showLoading?: boolean;
  showRefresh?: boolean;
  showMenu?: boolean;
  showBackButton?: boolean;
  showDesktopBackButton?: boolean;
  showDesktopSaveButton?: boolean;
  showRightIcon?: boolean;
  showRightText?: boolean;
  showRightComponent?: boolean;
  rightIconName?: IconName;
  rightText?: string;
  rightComponent?: React.ReactNode;
  onRefreshClick?: () => void;
  onBackClick?: () => void;
  onRightActionClick?: () => void;
  rightActionOutline?: boolean;
  rightActionIsLoading?: boolean;
  showFilterSettings?: boolean;
}

const Page = (props: PageProps) => {
  const {authData} = useAuth();
  const history = useHistory();
  const location = useLocation();
  const [notifications, setNotifications] = useState<NotificationResponse[]>(
    [],
  );
  const {data} = useQuery(['get-notifications'], getNotifications, {
    enabled: !isMobile && authData !== null,
  });
  const postMarkAllNotificationsAsRead = usePostMarkAllNotificationsAsRead();

  useEffect(() => {
    if (data) {
      setNotifications(data);
      (async () => {
        await postMarkAllNotificationsAsRead.mutateAsync();
      })();
    }
  }, [data]);

  const backButtonClickHandler = () => {
    if (history.action === Action.Push) {
      history.go(-1); //.back() will throw an error
    } else {
      // No more previous page; replace to home
      history.replace('/');
    }
  };

  if (isAndroid && !isUsingBrowser()) {
    App.addListener('backButton', data => {
      if (data.canGoBack) {
        window.history.go(-1);
      } else {
        App.exitApp();
      }
    });
  }

  return (
    <StyledMainWrapper
      hideNavbar={props.hideNavbar}
      defaultLanding={location.pathname.includes(RoutePath.LANDING)}>
      {!props.hideNavbar && (
        <NavBar
          showRefresh={props.showRefresh}
          showMenu={props.showMenu}
          showBackButton={props.showBackButton}
          showRightIcon={props.showRightIcon}
          showRightText={props.showRightText}
          showRightComponent={props.showRightComponent}
          rightIconName={props.rightIconName}
          rightText={props.rightText}
          rightComponent={props.rightComponent}
          onBackClick={props.onBackClick}
          onRightActionClick={props.onRightActionClick}
          rightActionOutline={props.rightActionOutline}
          rightActionIsLoading={props.rightActionIsLoading}
          onRefreshClick={props.onRefreshClick}
        />
      )}
      <StyledMainPageWrapper
        hideNavbar={props.hideNavbar}
        className="flex flex-row justify-center"
        forSignUp={
          location.pathname === RoutePath.SIGN_UP ||
          location.pathname === RoutePath.EMPLOYER_SIGN_UP
        }>
        {!isMobile &&
          !props.hideNavbar &&
          (props.showMenu || props.showBackButton) &&
          location.pathname !== RoutePath.EDIT_PROFILE_SETUP &&
          location.pathname != RoutePath.SAVE_CURRENT_LOCATION && 
          authData !== null && (
            <StyledSideBarWrapper>
              <SideBar sideDrawerOpen={true}></SideBar>
            </StyledSideBarWrapper>
          )}

        <StyledPageWrapper
          className={props.className}
          hideNavbar={props.hideNavbar}
          showLoading={props.showLoading}
          unPadded={props.unPadded}
          forMatches={location.pathname === RoutePath.MATCHES_LANDING}
          forEditProfile={location.pathname.includes(RoutePath.EDIT_MY_PROFILE)}
          defaultLanding={location.pathname.includes(RoutePath.LANDING)}
          forChats={
            !isMobile &&
            (location.pathname.includes('chat') ||
              location.pathname.includes('favorites') ||
              location.pathname.includes('kaway'))
          }>
          {props.showLoading ? (
            <BeatLoader
              color={colorTheme.primary}
              loading={props.showLoading}
              margin={2}
              size={15}
            />
          ) : (
            <StyledPageContent
              hideNavbar={props.hideNavbar}
              showLoading={props.showLoading}
              unPadded={props.unPadded}
              showFilterSettings={props.showFilterSettings}
              forMatches={location.pathname === RoutePath.MATCHES_LANDING}
              forLanding={props.isLanding}
              forChats={
                !isMobile &&
                (location.pathname.includes('chat') ||
                  location.pathname.includes('current-location') ||
                  location.pathname.includes('favorites') ||
                  location.pathname.includes('kaway'))
              }
              forChatRoom={location.pathname.includes('chat-room')}>
              {!isMobile &&
                (props.showDesktopBackButton || props.showDesktopSaveButton) &&
                !props.showLoading && (
                  <div
                    className="flex justify-between items-center"
                    style={{
                      margin: '-24px -24px 24px -24px',
                    }}>
                    <div>
                      {props.showDesktopBackButton && (
                        <Icon
                          type="button"
                          name="arrowLeft"
                          size={24}
                          color={colorTheme.dark}
                          onClick={backButtonClickHandler}
                        />
                      )}
                    </div>

                    <div style={{width: 'fit-content'}}>
                      {props.showDesktopSaveButton && (
                        <Button
                          label={localization.btn_save}
                          color="default"
                          variant="outlined"
                          onClick={props.onRightActionClick}
                          isLoading={props.rightActionIsLoading}
                        />
                      )}
                    </div>
                  </div>
                )}

              {props.children}
            </StyledPageContent>
          )}
        </StyledPageWrapper>
        {!isMobile &&
          authData &&
          !props.hideNavbar &&
          (props.showMenu || props.showBackButton) &&
          location.pathname !== RoutePath.EDIT_PROFILE_SETUP &&
          location.pathname !== RoutePath.SAVE_CURRENT_LOCATION &&
          location.pathname !== RoutePath.NOTIFICATIONS && (
            <StyledNotificationWrapper>
              <StyledNotificationContent>
                <Typography
                  label={localization.hdr_notifs}
                  variant="f2"
                  weight="semibold"
                  color={colorTheme.dark}
                />
                <div className="mt-2 space-y-6">
                  {notifications && notifications.length > 0 ? (
                    <div>
                      {notifications &&
                        notifications.length > 0 &&
                        notifications.map((notif, i) => {
                          return (
                            <div key={notif.notificationId}>
                              {authData?.user.userRole === 'kazam' ? (
                                notif?.notificationType === 'jobOffer' ? (
                                  <JobOfferNotificationView key={i} data={notif} />
                                ) : (
                                  <KazamNotificationView key={i} data={notif} />
                                )
                              ) : (
                                <HomeownerNotificationView
                                  key={i}
                                  data={notif}></HomeownerNotificationView>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    <StyledNotificationEmpty>
                      <NoResultView
                        image={noNotification}
                        label={localization.desc_no_notifs}></NoResultView>
                    </StyledNotificationEmpty>
                  )}
                </div>
              </StyledNotificationContent>

              <div className="pt-6">
                <BannerAd />
              </div>
            </StyledNotificationWrapper>
          )}
      </StyledMainPageWrapper>
    </StyledMainWrapper>
  );
};

export default Page;
