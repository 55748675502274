import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  Typography,
  Input,
  Button,
  WizardStep,
  Icon,
} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {resetPassword} from '../../../schema/sign-up.schema';
import {ResetPassword} from '../../../server/types';
import logo from '../../../../assets/KAZAM_logo_Transparent.svg';
import {useHistory} from 'react-router-dom';
import {RoutePath} from '../../../navigation/config/RouteConfig';
import {useToast} from '../../../../core/components/toast';
import {
  usePutPasswordReset,
} from '../../../server/react-query';
import Page from '../../../../core/components/page/Page';
import {useState} from 'react';
import {localization} from '../../../localization/Localization';

const ResetPasswordPage = (props: any) => {
  const history = useHistory();
  const {presentToast} = useToast();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);

  const {
    control,
    getValues,
    formState: {errors},
    handleSubmit,
  } = useForm<ResetPassword>({
    resolver: yupResolver(resetPassword),
    defaultValues: {
      password: '',
      repeatPassword: '',
    },
  });

  const submitForm = async () => {
    handleSubmit(handleNext)();
  };

  const handleNext = () => {
    try {
      resetPasswordAsync();
    } catch (error) {
      console.log('error', error);
    }
  };

  const putPasswordReset = usePutPasswordReset();
  const resetPasswordAsync = async () => {
    try {
      setLoading(true);
      var passwordReset = localStorage.getItem('passwordReset');
      if (passwordReset) {
        var passwordResetData = JSON.parse(passwordReset);

        await putPasswordReset.mutateAsync({
          mobileNumber: passwordResetData.mobileNumber,
          password: getValues().password,
        });

        setLoading(false);
        localStorage.removeItem('lastLoginPassword');
        history.replace(RoutePath.SIGN_IN);
      }
    } catch (error: any) {
      setLoading(false);
      presentToast({
        message: error.data.error,
        variant: 'error',
        position: 'bottom',
      });
    }
  };

  const cancelPasswordReset = () => {
    localStorage.removeItem('passwordReset');
    history.replace(RoutePath.SIGN_IN);
  };

  return (
    <Page hideNavbar>
      <div className="space-y-6">
        <div>
          <WizardStep
            steps={props.totalSteps}
            currentStep={props.currentStep}></WizardStep>
        </div>
        <div className="flex justify-center">
          <img style={{height: '29px'}} src={logo} alt="Logo" />
        </div>
        <div>
          <div>
            <Typography
              label={localization.hdr_reset_pw}
              variant="f3"
              align="center"
              color={colorTheme.dark}></Typography>
          </div>
          <div className="pt-1">
            <Typography
              label={localization.desc_pw_reset}
              variant="f1"
              align="center"
              color={colorTheme.darkFaded}></Typography>
          </div>
        </div>
        <div>
          <div>
            <div className="pt-4">
              <Controller
                control={control}
                name="password"
                render={({field: {value = '', onBlur, onChange}}) => (
                  <Input
                    inputSize="large"
                    type={showPassword ? 'text' : 'password'}
                    placeholder={localization.ph_enter_new_pw}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={errors.password?.message}
                    iconRight={
                      <Icon
                        name={showPassword ? 'eye' : 'eyeOff'}
                        size={16}
                        color={value ? colorTheme.dark : colorTheme.darkFaded}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    }
                  />
                )}
              />
            </div>

            <div className="pt-4">
              <Controller
                control={control}
                name="repeatPassword"
                render={({field: {value = '', onBlur, onChange}}) => (
                  <Input
                    inputSize="large"
                    type={showRepeatPassword ? 'text' : 'password'}
                    placeholder={localization.ph_repeat_pw}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    onSubmit={submitForm}
                    error={errors.repeatPassword?.message}
                    iconRight={
                      <Icon
                        name={showRepeatPassword ? 'eye' : 'eyeOff'}
                        size={16}
                        color={value ? colorTheme.dark : colorTheme.darkFaded}
                        onClick={() =>
                          setShowRepeatPassword(!showRepeatPassword)
                        }
                      />
                    }
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div>
          <div>
            <Button
              label={localization.hdr_reset_pw}
              color="primary"
              isLoading={loading}
              onClick={submitForm}
            />
          </div>
          <div className="pt-4">
            <Button
              label={localization.btn_cancel}
              variant="outlined"
              onClick={cancelPasswordReset}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default ResetPasswordPage;
