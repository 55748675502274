import React, {useEffect, useReducer, useState} from 'react';
import {StyledAdminAllUsersTable} from '../styled';
import AdminTable from '../AdminTable';
import {Column} from 'react-table';
import {Typography} from '../../../../../core/components';
import {colorTheme} from '../../../../../core/configs';
import {useToast} from '../../../../../core/components/toast';
import {
  useGetSystemStatistics,
  useGetUsers,
  usePostBanUser,
  usePostConversation,
  usePostSuspendUser
} from '../../../../server/react-query';
import {useAnalytics} from '../../../../hooks/useAnalytics';
import {useAuth} from '../../../../hooks/useAuth';
import {capitalize} from '../../../../utils/random.util';
import { ChatResponse, VerifiedStatus } from '../../../../server/types';
import { useHistory } from 'react-router-dom';
import { RoutePath } from '../../../../navigation/config/RouteConfig';
import { usePatchManualVerification } from '../../../../server/react-query/useAdminUsers';
import { INITIAL_SEARCH_STATE, reducer } from '../verified/AdminVerifiedUsersTable';

interface TableData {
  userId: string;
  name: string;
  location: string;
  userType: string;
  userStatus: string;
  isBanned: boolean;
  isVerified: boolean
}

interface AdminAllUsersTableProps {
  searchQuery: string;
}

const AdminAllUsersTable = (props: AdminAllUsersTableProps) => {
  const history = useHistory();

  const {searchQuery: propSearchQuery} = props;

  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [data, setData] = useState<TableData[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>(propSearchQuery);
  const [searchBody, dispatchSearch] = useReducer(reducer, INITIAL_SEARCH_STATE)
  const {data: hookDataUsers, refetch: refetchGetUsers} = useGetUsers(searchBody);
  const postConversation = usePostConversation();

  const {presentToast} = useToast();
  const postBanUser = usePostBanUser();
  const postSuspendUser = usePostSuspendUser();

  const {systemLogEvent} = useAnalytics();
  const {
    data: hookDataSystemStatistics,
    refetch: refetchSystemStatistics,
  } = useGetSystemStatistics();
  const {authData} = useAuth();

  const [columns] = useState<Column<TableData>[]>([
    {
      Header: 'Name',
      accessor: 'name', // accessor is the "key" in the data
    },
    {
      Header: 'User ID',
      accessor: 'userId', // accessor is the "key" in the data
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'User Status',
      accessor: 'userStatus',
    },
    {
      Header: 'User Type',
      accessor: 'userType',
    },
    {
      Header: 'Action',
      accessor: 'action',
    },
  ] as Column<TableData>[]);

  useEffect(() => {
    refetchGetUsers()
  }, [searchBody]);

  useEffect(() => {
    setPageNumber(1)
    setSearchQuery(propSearchQuery);
  }, [propSearchQuery]);

  useEffect(() => {
    if (hookDataUsers) {
      setPageCount(Math.ceil(hookDataUsers.total / hookDataUsers.pageSize));
      setData(
        hookDataUsers.data.map(i => {
          const userId = i.userId;
          const name = `${i.firstName} ${i.lastName}`;

          let location = '';
          if (i.filterSetting) {
            const userLocation = i.filterSetting.location;
            if (userLocation) {
              location = userLocation?.cityOrMunicipality;
              if (userLocation.hasProvince) {
                location += `, ${userLocation.province.name}`;
              }
            }
          }

          const userType = i.userRole === 'homeowner' ? 'Homeowner' : 'Kasambahay';

          const userStatus = capitalize(i.userStatus);

          const isBanned = i.isBanned;

          const isVerified = i.verifiedStatus === 'verified'

          return {
            userId,
            name,
            location,
            userStatus,
            userType,
            isBanned,
            isVerified
          } as TableData;
        }),
      );
    }
  }, [hookDataUsers]);

  useEffect(() => {
    dispatchSearch({
      type: 'SET_SEARCH',
      payload: {
        search: searchQuery?.trim(),
      },
    });
  }, [searchQuery]);

  const onPageChangedHandler = (pageNumber: number) => {
    setPageNumber(pageNumber);
    dispatchSearch({
      type: 'SET_PAGE',
      payload: {
        page: pageNumber
      },
    });
  };

  const onBanUserClickedHandler = async (rowData: TableData) => {
    try {
      await postBanUser.mutateAsync({userId: rowData.userId});

      systemLogEvent('active_users', {
        kazams:
          (hookDataSystemStatistics?.activeKazams || 0) -
          (authData?.user.userRole === 'kazam' ? 1 : 0),
        homeowners:
          (hookDataSystemStatistics?.activeHomeowners || 0) -
          (authData?.user.userRole === 'homeowner' ? 1 : 0),
      });

      systemLogEvent('system_statistics', {
        ...hookDataSystemStatistics,
      });

      presentToast({
        message: 'Banned successfully.',
        variant: 'success',
        position: 'bottom',
      });

      await refetchSystemStatistics();
    } catch (e) {
      console.log(e);
      presentToast({
        message: `Unexpected error was occurred.`,
        variant: 'error',
        position: 'bottom',
      });
    }
  };

  const onChatUserClickedHandler = async (rowData: TableData) => {
    try {
      var conversation = (await postConversation.mutateAsync({
        conversationChannelName: `Conversation with ${rowData.userId}`, // Use user's name as conversation name
        memberIds: [rowData.userId || '', authData?.user?.userId || ''],
      })) as ChatResponse;

      if (conversation) {
        history.push(
          RoutePath.ADMIN_CHAT_ROOM.replace(':id', conversation.conversationChannelId),
        );
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const onSuspendUserClickedHandler = async (rowData: TableData) => {
    try {
      await postSuspendUser.mutateAsync({userId: rowData.userId});

      systemLogEvent('active_users', {
        kazams:
          (hookDataSystemStatistics?.activeKazams || 0) -
          (authData?.user.userRole === 'kazam' ? 1 : 0),
        homeowners:
          (hookDataSystemStatistics?.activeHomeowners || 0) -
          (authData?.user.userRole === 'homeowner' ? 1 : 0),
      });

      systemLogEvent('system_statistics', {
        ...hookDataSystemStatistics,
      });

      presentToast({
        message: 'Suspended successfully.',
        variant: 'success',
        position: 'bottom',
      });

      await refetchSystemStatistics();
    } catch (e) {
      console.log(e);
      presentToast({
        message: `Unexpected error was occurred.`,
        variant: 'error',
        position: 'bottom',
      });
    }
  };

  const patchManualVerification = usePatchManualVerification();
  const onChangeVerifiedStatus = async (rowData: TableData, verifiedStatus: VerifiedStatus) => {
    try {
      await patchManualVerification.mutateAsync({
        userId: rowData.userId,
        verifiedStatus
      });

      presentToast({
        message: 'Successfully updated.',
        variant: 'success',
        position: 'bottom',
      });

    } catch (e) {
      console.log(e);
      presentToast({
        message: `Unexpected error was occurred.`,
        variant: 'error',
        position: 'bottom',
      });
    }
  };

  const manualRenderFn = (columnId: string, rowData: TableData) => {
    if (rowData.isBanned) {
      return undefined;
    } else {
      if (rowData.userStatus === 'Suspended') {
        return (
          <div className='flex space-x-2'>
            <div
              className="cursor-pointer"
              onClick={() => onChatUserClickedHandler(rowData)}>
              <Typography
                label="Chat"
                variant="f2"
                color={colorTheme.primary}
                align="left"
              />
            </div>
          </div>
        );
      } else {
        return (
          <div className='flex space-x-2'>
            <div
              className="cursor-pointer"
              onClick={() => onBanUserClickedHandler(rowData)}>
              <Typography
                label="Ban"
                variant="f2"
                color={colorTheme.primary}
                align="left"
              />
            </div>
            <div>|</div>

            <div
              className="cursor-pointer"
              onClick={() => onSuspendUserClickedHandler(rowData)}>
              <Typography
                label="Suspend"
                variant="f2"
                color={colorTheme.primary}
                align="left"
              />
            </div>
            <div>|</div>

            <div
              className="cursor-pointer"
              onClick={() => onChatUserClickedHandler(rowData)}>
              <Typography
                label="Chat"
                variant="f2"
                color={colorTheme.primary}
                align="left"
              />
            </div>

            <div>|</div>

            <div
              className="cursor-pointer"
              onClick={() => onChangeVerifiedStatus(rowData, rowData.isVerified ? 'notVerified' : 'verified')}>
              <Typography
                label={rowData.isVerified ? 'Revoke' : 'Verify'}
                variant="f2"
                color={colorTheme.primary}
                align="left"
              />
            </div>
          </div>
        );
      }
    }
  };

  return (
    <StyledAdminAllUsersTable>
      <AdminTable
        columns={columns}
        data={data}
        pageSize={10}
        pageCount={pageCount}
        pageNumber={pageNumber}
        showDebugView={false}
        onPageChange={onPageChangedHandler}
        manualRenderFn={manualRenderFn}
      />
    </StyledAdminAllUsersTable>
  );
};

export default AdminAllUsersTable;
