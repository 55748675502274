import Page from '../../../../core/components/page/Page';
import HomeownerProfileView from '../../../views/cards/homeowner-profile/HomeownerProfileView';
import {Button, Icon, Input, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {useGetMyProfile} from '../../../server/react-query';
import {useHistory} from 'react-router-dom';
import {RoutePath} from '../../../navigation/config/RouteConfig';
import {localization} from '../../../localization/Localization';
import {useToast} from '../../../../core/components/toast';
import {getReferralLink} from '../../../utils/referral.util';
import {useAuth} from '../../../hooks/useAuth';
import {getReferralText} from '../../../utils/admin.util';
import { Clipboard } from '@capacitor/clipboard';
import { isUsingBrowser } from '../../../utils/random.util';
import { StyledLabel } from './styled';
import SettingsItemView from '../../../views/cards/settings-item/SettingsItemView';
import { useGetActiveSubscription } from '../../../server/react-query/useUserSubscription';
import { useGetActiveTrialSubscription } from '../../../server/react-query/useTrialSubscription';
import { useEffect } from 'react';

const HomeownerUserProfilePage = () => {
  const history = useHistory();
  const {data, isFetching} = useGetMyProfile();
  const {presentToast} = useToast();
  const {authData, updateAuthData} = useAuth();

  useEffect(() => {
    let oldAuthData = authData;
    if (data && oldAuthData) {
      oldAuthData.user = data;
      updateAuthData(oldAuthData)
    }
  }, [data])

  const {data: userSubscription} = useGetActiveSubscription(true)
  const {data: trialSubscription} = useGetActiveTrialSubscription()

  const editProfile = () => {
    history.push(RoutePath.EDIT_MY_PROFILE_HO);
  };

  const onCopyReferralLinkClickedHandler = async () => {
    const referralCode = authData?.user.referralCode;
    if (referralCode) {
      const referralText = getReferralText();
      const referralLink = getReferralLink(referralCode);
      try {
        if (referralText && referralText.enabled) {
          if (isUsingBrowser()) {
            await navigator.clipboard.writeText(
              `${referralText.text} ${referralLink}`,
            );
          } else {
            await Clipboard.write({
              string: `${referralText.text} ${referralLink}`
            });
          }

        } else {
          if (isUsingBrowser()) {
            await navigator.clipboard.writeText(referralLink);
          } else {
            await Clipboard.write({
              string: referralLink
            });
          }
        }

        presentToast({
          message: 'Copied',
          variant: 'success',
          position: 'bottom',
        });
      } catch (e) {
        console.log(e);
        presentToast({
          message: 'Failed to copy',
          variant: 'error',
          position: 'bottom',
        });
      }
    }
  };

  return (
    <Page
      showLoading={isFetching}
      showBackButton
      showRightIcon
      rightIconName="edit"
      onRightActionClick={editProfile}>
      <div className="space-y-4">
        <Typography
          label={localization.hdr_my_profile}
          variant="f3"
          weight="semibold"
          align="center"
          color={colorTheme.dark}
        />
        <HomeownerProfileView
          profile={data}
          myProfile
        />

        <Input
          readOnly
          inputSize='large'
          label="Referral Link"
          value={getReferralLink(authData?.user?.referralCode ?? "")}
          iconRight={
            <Icon
              type="button"
              color={colorTheme.dark}
              name='copy'
              size={20}
              onClick={onCopyReferralLinkClickedHandler}
            />
          }
        />

        {(userSubscription || trialSubscription) &&
          <div className="space-y-1">
            <StyledLabel data-testid="label-element">
              {localization.lbl_subscription_details}
            </StyledLabel>
            <SettingsItemView
              label={(userSubscription && userSubscription.subscriptionMeta)
                ? userSubscription.subscriptionMeta?.name
                : "Trial Subscription"
              }
              description="Click to see more details about your subscription"
              path={RoutePath.SUBSCRIPTION_DETAILS}
            />
          </div>
        }

        <Button
          label={data?.personalityMatch
            ? "Retake Personality Test"
            : "Take Personality Test"
          }
          color="danger"
          onClick={() => history.push(RoutePath.TAKE_PERSONALITY_TEST)}
        />

      </div>
    </Page>
  );
};

export default HomeownerUserProfilePage;
