import styled from 'styled-components';
import {colorTheme} from '../../../../core/configs';

export const StyledDialog = styled.div`
  padding: 0 16px 22px 16px;

  & .spacer {
    width: 16px;
  }

  & .actions {
    margin-top: 16px;
  }

  & .message {
    margin-top: 8px;
    padding: 16px;
    height: 100%;
    width: 100%;
    max-width: 80vw;
    max-height: 50vh;
    overflow-y: scroll;
    border-radius: 4px;
    border: 1px solid ${colorTheme.lightGray};
  }

  .tac {
    font-size: 11.2px;
    color: ${colorTheme.darkFaded};
  }

  .tac a {
    text-decoration: none;
    cursor: pointer;
    font-size: 11.2px;
    line-height: 1.2rem;
    -webkit-letter-spacing: 0rem;
    -moz-letter-spacing: 0rem;
    -ms-letter-spacing: 0rem;
    letter-spacing: 0rem;
    font-weight: 400;
    text-align: left;
    color: #34049c;
  }

  .tac ol > li {
    margin-left: 10px;
  }
`;
