import {Button, Typography} from '../../../core/components';
import TrueSelfieTag from '../../../core/components/true-selfie-tag/TrueSelfieTag';
import VerifiedTag from '../../../core/components/verified-tag/VerifiedTag';
import {colorTheme} from '../../../core/configs';
import {localization} from '../../localization/Localization';

interface DialogProps {
  onCancelClick: () => void;
  onContinueClick: () => void;
  isLoading?: boolean;
}

const UserVerificationDiscard = (props: DialogProps) => {
  console.log("🚀 ~ UserVerificationDiscard ~ props:", props)

  return (
    <>
      <div className="flex items-center justify-content p-4">
        <div className="max-w-md w-full">
          <div className="mb-4">
            <Typography
              label="DISCARD CHANGES"
              align="center"
              weight="semibold"
              variant="f3"
              color={colorTheme.dark}
            />
          </div>

          <div className="rounded-lg p-6 mb-6 flex justify-center border-2 border-borderLightGray">
            <Typography
              align="left"
              label="Discarding the changes will use the personal details you entered instead of the details from your scanned ID. You will also be manually verified by the administrators through chat."
              weight="semibold"
              variant="f1"
              color={colorTheme.darkFaded}
            />
          </div>
          <div className="flex flex-row w-full space-x-6 ">
            <Button
              label={localization.btn_cancel}
              variant="outlined"
              onClick={props.onCancelClick}
            />
            <Button
              label={'Ok'}
              color="darkFaded"
              onClick={props.onContinueClick}
              isLoading={props.isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserVerificationDiscard;
