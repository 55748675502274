import React, {useCallback, useEffect, useState} from 'react';
import {StyledKawaysComponent} from './styled';
import {ChatResponse, TagResponse} from '../../server/types';
import {
  useGetConversation,
  useGetHides,
  useGetKaways,
} from '../../server/react-query';
import {useHistory} from 'react-router-dom';
import KawayItem from './KawayItem';
import {RoutePath} from '../../navigation/config/RouteConfig';
import noKaways from '../../../assets/kaway_illustration.svg';
import NoResultView from '../../views/cards/no-result/NoResultView';
import {SavedFilter} from '../../server/types/chat-filter.types';
import {getChatFilterSetting} from '../../utils/chat-filter.util';
import {BeatLoader} from 'react-spinners';
import {colorTheme} from '../../../core/configs';
import {localization} from '../../localization/Localization';
import KazamKawayDialogContentView from '../../views/dialog-content/kazam-kaway/KazamKawayDialogContentView';
import {useDialog} from '../../../core/components/dialog';

interface KawaysComponentProps {
  searchQuery?: string | undefined;
}

const KawaysComponent = (props: KawaysComponentProps) => {
  const {searchQuery: propSearchQuery} = props;
  const {presentDialog, dismissDialog} = useDialog();

  const [saveFilterSetting] = useState<SavedFilter>(getChatFilterSetting());
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);

  const [kaways, setKaways] = useState<TagResponse[]>([]);
  const [hiddenKazams, setHiddenKazams] = useState<TagResponse[]>([]);
  const [conversations, setConversations] = useState<ChatResponse[]>([]);

  const {data: hidesData} = useGetHides();
  const history = useHistory();
  const {data: kawaysData, refetch, isFetching} = useGetKaways(
    searchQuery?.trim(),
    {
      0: 'distance',
      1: 'salary',
      2: 'active',
      3: 'activity',
    }[saveFilterSetting.activeTab],
    {
      0: {
        'Near To Far': 'nearest_first',
        'Far To Near': 'farthest_first',
      },
      1: {
        'Low To High': 'min_first',
        'High To Low': 'max_first',
      },
      2: {
        'Online First': 'most_active_first',
      },
      3: {
        'Latest First': 'latest_first',
      },
    }[saveFilterSetting.activeTab][
      saveFilterSetting.activeTab === 0
        ? saveFilterSetting.selectedDistanceSort
        : saveFilterSetting.activeTab === 1
        ? saveFilterSetting.selectedSalarySort
        : saveFilterSetting.activeTab === 2
        ? saveFilterSetting.selectedActiveSort
        : saveFilterSetting.selectedActivitySort
    ],
    saveFilterSetting.selectedRoles.join(','),
  );

  const {data: hookDataConversations} = useGetConversation();

  useEffect(() => {
    if (hookDataConversations) {
      setConversations(hookDataConversations);
    }
  }, [hookDataConversations]);

  useEffect(() => {
    (async () => {
      await refetch();
    })();
  }, [searchQuery, refetch]);

  const executeSearch = useCallback(searchQuery => {
    setSearchQuery(searchQuery);
  }, []);

  useEffect(() => {
    localStorage.removeItem('fromEmail');
    if (localStorage.getItem('isNewHomeowner')) {
      showNewKazamDialog();
    }
  }, []);

  const showNewKazamDialog = () => {
    presentDialog({
      headerText: '',
      content: (
        <KazamKawayDialogContentView
          onContinueClick={dismissDialog}
          forHomeowners
        />
      ),
      enableBackdropDismiss: false,
    });
    localStorage.removeItem('isNewHomeowner');
  };

  // Throttle search
  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      executeSearch(propSearchQuery);
    }, 500);
    return () => timeoutRef && clearTimeout(timeoutRef);
  }, [propSearchQuery, executeSearch]);

  useEffect(() => {
    if (kawaysData) {
      // Filter out hidden Kazams
      const filtered = kawaysData.filter(
        k => !hiddenKazams.find(h => h.tagUserId === k.userId),
      );
      setKaways(filtered);
    }
  }, [kawaysData, hiddenKazams]);

  useEffect(() => {
    if (hidesData) {
      setHiddenKazams(hidesData);
    }
  }, [hidesData]);

  const itemClickHandler = (item: TagResponse) => {
    history.push(RoutePath.FAVORITE_VIEW.replace(':id', item.userId));
  };

  return (
    <StyledKawaysComponent>
      {isFetching ? (
        <div
          className="w-full flex justify-center items-center"
          style={{minHeight: '250px'}}>
          <BeatLoader
            color={colorTheme.primary}
            loading={isFetching}
            margin={2}
            size={15}
          />
        </div>
      ) : (
        <div>
          {kaways && kaways.length > 0 ? (
            <div>
              {kaways.map(kaway => {
                const existingConversation = conversations.find(
                  c => !!c.members.find(m => m.userId === kaway.userId),
                );
                let conversationContent = undefined;
                if (existingConversation) {
                  if (existingConversation.contents.length > 0) {
                    conversationContent = existingConversation.contents[0];
                  }
                }

                return (
                  <KawayItem
                    key={kaway.userId}
                    data={kaway}
                    onClick={itemClickHandler}
                    recentContent={conversationContent}
                  />
                );
              })}
            </div>
          ) : (
            <NoResultView
              image={noKaways}
              label={localization.desc_no_kaways}></NoResultView>
          )}
        </div>
      )}
    </StyledKawaysComponent>
  );
};

export default KawaysComponent;
