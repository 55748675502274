import React, {useCallback, useEffect, useState} from 'react';
import {StyledFavoritesComponent} from './styled';
import {useGetConversation, useGetFavorites} from '../../server/react-query';
import {ChatResponse, TagResponse} from '../../server/types';
import FavoriteItem from './FavoriteItem';
import {useHistory} from 'react-router-dom';
import {RoutePath} from '../../navigation/config/RouteConfig';
import noFavorites from '../../../assets/no_favorites.svg';
import NoResultView from '../../views/cards/no-result/NoResultView';
import {SavedFilter} from '../../server/types/chat-filter.types';
import {getChatFilterSetting} from '../../utils/chat-filter.util';
import {BeatLoader} from 'react-spinners';
import {colorTheme} from '../../../core/configs';
import {localization} from '../../localization/Localization';

interface FavoritesComponentProps {
  searchQuery?: string | undefined;
}

const FavoritesComponent = (props: FavoritesComponentProps) => {
  const {searchQuery: propSearchQuery} = props;

  const [saveFilterSetting] = useState<SavedFilter>(getChatFilterSetting());

  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);

  const [favorites, setFavorites] = useState<TagResponse[]>([]);
  const [conversations, setConversations] = useState<ChatResponse[]>([]);

  const history = useHistory();
  const {data, refetch, isFetching} = useGetFavorites(
    searchQuery?.trim(),
    {
      0: 'distance',
      1: 'salary',
      2: 'active',
      3: 'activity',
    }[saveFilterSetting.activeTab],
    {
      0: {
        'Near To Far': 'nearest_first',
        'Far To Near': 'farthest_first',
      },
      1: {
        'Low To High': 'min_first',
        'High To Low': 'max_first',
      },
      2: {
        'Online First': 'most_active_first',
      },
      3: {
        'Latest First': 'latest_first',
      },
    }[saveFilterSetting.activeTab][
      saveFilterSetting.activeTab === 0
        ? saveFilterSetting.selectedDistanceSort
        : saveFilterSetting.activeTab === 1
        ? saveFilterSetting.selectedSalarySort
        : saveFilterSetting.activeTab === 2
        ? saveFilterSetting.selectedActiveSort
        : saveFilterSetting.selectedActivitySort
    ],
    saveFilterSetting.selectedRoles.join(','),
  );

  const {data: hookDataConversations} = useGetConversation();

  useEffect(() => {
    if (hookDataConversations) {
      setConversations(hookDataConversations);
    }
  }, [hookDataConversations]);

  useEffect(() => {
    (async () => {
      await refetch();
    })();
  }, [searchQuery, refetch]);

  const executeSearch = useCallback(searchQuery => {
    setSearchQuery(searchQuery);
  }, []);

  // Throttle search
  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      executeSearch(propSearchQuery);
    }, 500);
    return () => timeoutRef && clearTimeout(timeoutRef);
  }, [propSearchQuery, executeSearch]);

  useEffect(() => {
    if (data) {
      setFavorites(data);
    }
  }, [data]);

  const itemClickHandler = (item: TagResponse) => {
    history.push(RoutePath.FAVORITE_VIEW.replace(':id', item.tagUserId));
  };

  return (
    <StyledFavoritesComponent>
      {isFetching ? (
        <div
          className="w-full flex justify-center items-center"
          style={{minHeight: '250px'}}>
          <BeatLoader
            color={colorTheme.primary}
            loading={isFetching}
            margin={2}
            size={15}
          />
        </div>
      ) : (
        <div>
          {favorites && favorites.length > 0 ? (
            <div>
              {favorites.map(fav => {
                const existingConversation = conversations.find(
                  c => !!c.members.find(m => m.userId === fav.tagUserId),
                );
                let conversationContent = undefined;
                if (existingConversation) {
                  if (existingConversation.contents.length > 0) {
                    conversationContent = existingConversation.contents[0];
                  }
                }
                return (
                  <FavoriteItem
                    key={fav.tagUserId}
                    data={fav}
                    onClick={itemClickHandler}
                    recentContent={conversationContent}
                  />
                );
              })}
            </div>
          ) : (
            <NoResultView
              image={noFavorites}
              label={localization.desc_no_favorites}></NoResultView>
          )}
        </div>
      )}
    </StyledFavoritesComponent>
  );
};

export default FavoritesComponent;
