import styled from 'styled-components';
import {colorTheme} from '../../../../core/configs';

export const StyledDiv = styled.div`
  position: relative;

  img {
    width: 100%;
    height: 100%;
    max-height: 70vh;
    border-radius: 16px;
    object-fit: cover !important;
  }
`;

export const StyledOverlay = styled.div`
  padding: 16px;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  cursor: pointer;
`;

export const StyledIconOverlay = styled.div`
  width: 32px;
  height: 32px;
  background: ${colorTheme.primary};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.5px;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  object-fit: cover !important;
`;

export const StyledClose = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
`;
