import * as React from 'react';
import {SVGProps, Ref, forwardRef, memo} from 'react';
const SvgFlipCamera = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 25"
    ref={ref}
    {...props}>
    <path
      fill="currentColor"
      d="M15.45 13.9c-.1.883-.475 1.62-1.125 2.213-.65.591-1.425.887-2.325.887a3.215 3.215 0 0 1-.8-.1.916.916 0 0 0-.425 0 .746.746 0 0 0-.375.2.697.697 0 0 0-.2.662c.05.259.192.43.425.513.233.083.463.142.688.175.224.033.454.05.687.05 1.317 0 2.45-.446 3.4-1.337.95-.892 1.467-1.996 1.55-3.313l.225.2c.15.133.325.2.525.2s.367-.067.5-.2a.78.78 0 0 0 .238-.538.692.692 0 0 0-.213-.537L16.95 11.7a.948.948 0 0 0-.7-.275.948.948 0 0 0-.7.275l-1.275 1.275a.72.72 0 0 0-.225.525c0 .2.075.375.225.525a.72.72 0 0 0 .525.225.72.72 0 0 0 .525-.225l.125-.125Zm-8.4-.75-.225-.2a.766.766 0 0 0-.525-.2c-.2 0-.367.067-.5.2a.78.78 0 0 0-.237.538.692.692 0 0 0 .212.537L7.05 15.3a.948.948 0 0 0 .7.275.948.948 0 0 0 .7-.275l1.275-1.275a.72.72 0 0 0 .225-.525.72.72 0 0 0-.225-.525.72.72 0 0 0-.525-.225.72.72 0 0 0-.525.225l-.125.125c.1-.883.475-1.62 1.125-2.213C10.325 10.296 11.1 10 12 10a3.215 3.215 0 0 1 .8.1.916.916 0 0 0 .425 0c.15-.033.275-.1.375-.2a.697.697 0 0 0 .2-.662c-.05-.259-.192-.43-.425-.513a3.622 3.622 0 0 0-.688-.175A4.686 4.686 0 0 0 12 8.5c-1.317 0-2.45.446-3.4 1.338-.95.891-1.467 1.995-1.55 3.312ZM4 21.5c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 2 19.5v-12c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 4 5.5h3.15L8.4 4.15c.183-.2.404-.358.662-.475.259-.117.53-.175.813-.175h4.25c.283 0 .554.058.813.175.258.117.479.275.662.475l1.25 1.35H20c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v12c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 20 21.5H4Zm0-2h16v-12h-4.05l-1.825-2h-4.25l-1.825 2H4v12Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgFlipCamera);
const Memo = memo(ForwardRef);
export default Memo;
