import {useEffect, useState} from 'react';
import {
  Button,
  TextArea,
  Toggle,
  Typography,
} from '../../../../core/components';
import {StyledWrapper} from './styled';
import {colorTheme} from '../../../../core/configs';
import {usePutAdminControl} from '../../../server/react-query';
import {useToast} from '../../../../core/components/toast';
import {
  AdminControl,
  AdminControlReqBody,
} from '../../../server/types/admin-control.types';

interface ReferralLinkViewProps {
  data: AdminControl[];
}

const ReferralLinkView = ({data}: ReferralLinkViewProps) => {
  const {presentToast} = useToast();

  const [enableReferralText, setEnableReferralText] = useState(false);
  const [text, setText] = useState<string>('');

  const putAdminControl = usePutAdminControl();

  useEffect(() => {
    if (data) {
      var genericText = data.find(d => d.type === 'referralText');

      if (genericText) {
        setEnableReferralText(genericText.enabled);
        setText(genericText.text);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onEnableAutoMessage = async (value: boolean) => {
    try {
      const data: AdminControlReqBody = {
        type: 'referralText',
        enabled: value,
        text: text,
      };
      await putAdminControl.mutateAsync(data);
      setEnableReferralText(value);

      presentToast({
        message: `Successfully ${
          value ? 'enabled' : 'disabled'
        } referral text control.`,
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      console.log(e);
    }
  };

  const onPutAdminControl = async () => {
    try {
      const data: AdminControlReqBody = {
        type: 'referralText',
        text: text,
        enabled: true,
      };

      await putAdminControl.mutateAsync(data);

      presentToast({
        message: `Successfully saved.`,
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      console.log(e);
    }
  };

  return (
    <StyledWrapper>
      <Typography
        label="REFERRAL SETTINGS"
        variant="f1"
        weight="semibold"
        color={colorTheme.black}
      />

      <div className="pt-3 flex flex-row justify-between items-center">
        <div>
          <Typography
            label="Enable generic referral link text"
            variant="f3"
            color={enableReferralText ? colorTheme.dark : colorTheme.lightGray}
          />
          <Typography
            label="Set the generic text that will be prepended to the referral link when copied."
            variant="f1"
            color={
              enableReferralText ? colorTheme.darkFaded : colorTheme.lightGray
            }
          />
        </div>
        <div>
          <Toggle value={enableReferralText} onToggle={onEnableAutoMessage} />
        </div>
      </div>
      {enableReferralText && (
        <div>
          <div className="pt-3 flex flex-row justify-between items-center">
            <TextArea
              placeholder="Enter generic text"
              rows={2}
              maxRows={2}
              autoresize
              bordered
              value={text}
              onChange={value => setText(value.currentTarget.value)}
            />
          </div>

          <div className="pt-3 flex justify-end">
            <div style={{width: 'fit-content'}}>
              <Button
                label="Save"
                color="primary"
                onClick={onPutAdminControl}
                isLoading={putAdminControl.isLoading}
              />
            </div>
          </div>
        </div>
      )}
    </StyledWrapper>
  );
};

export default ReferralLinkView;
