import * as yup from 'yup';

export const handleVoucherRedeemSchema = yup.object({
    code: yup
      .string()
      .trim()
      .required('This field is required.')
      .matches(
        /^\S+$/,
        "Must not have spaces."
      )
      .matches(
        /^[ A-Za-z0-9]*$/,
        "Must be alphanumeric."
      ),
  });