import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker, {
  Day,
  utils,
} from '@hassanmojab/react-modern-calendar-datepicker';
import {StyledDatePicker, StyledClear, StyledInputLabel} from './styled';
import {colorTheme} from '../../configs';
import {useEffect, useState} from 'react';
import {format} from 'date-fns';
import Typography from '../typography';

export type DatePickerProps = {
  label?: string;
  placeholder?: string;
  value?: Date;
  className?: string;
  error?: string;
  onChange?: (value?: Date) => void;
  /**
   * To be fixed
   */
  ref?: any;
  enableFutureDates?: boolean;
  datePickerFontSize?: number
  addClear?: boolean;
};

const DatePickerComponent = (props: DatePickerProps) => {
  const {
    label,
    placeholder,
    value,
    error,
    onChange,
    enableFutureDates = false,
    datePickerFontSize,
    addClear = false
  } = props;
  const [selectedDay, setSelectedDay] = useState<Day | null>(null);
  const [minDate, setMinDate] = useState<Day | undefined>(undefined);
  const [maxDate, setMaxDate] = useState<Day | undefined>(undefined);

  const handleChange = (value: any) => {
    var date = new Date(value.year, value.month - 1, value.day);
    if (onChange) {
      onChange(date);
    }
  };

  useEffect(() => {
    if (value) {
      setSelectedDay({
        year: value.getFullYear(),
        day: value.getDate(),
        month: value.getMonth() + 1,
      });

      setMinDate({
        year: 1920,
        day: 1,
        month: 1,
      });
    }
  }, [value]);

  const formatInputValue = () => {
    if (selectedDay) {
      if (
        !isNaN(selectedDay.year) &&
        !isNaN(selectedDay.month) &&
        !isNaN(selectedDay.day)
      ) {
        const day = format(
          new Date(selectedDay.year, selectedDay.month - 1, selectedDay.day),
          'M/dd/yyyy',
        );
        return day;
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const renderCustomInput = ({ ref }: any) => (
    <div className="flex">
      <input
        readOnly
        ref={ref} // necessary
        placeholder={placeholder}
        value={formatInputValue()}
        className="date-picker-input" // a styling class
      />

      <StyledClear
        className="cursor-pointer"
        onClick={(event: React.MouseEvent) => {
          event.stopPropagation()
          setSelectedDay(null)
          onChange && onChange(undefined)
        }}>
        <Typography
          label="Clear"
          variant="f2"
          color={colorTheme.primary}
          align="left"
        />
      </StyledClear>
    </div>
  )

  return (
    <StyledDatePicker error={error} datePickerFontSize={datePickerFontSize}>
      {label && (
        <StyledInputLabel data-testid="label-element">{label}</StyledInputLabel>
      )}

      <DatePicker
        colorPrimary={colorTheme.primary}
        onChange={handleChange}
        inputPlaceholder={placeholder}
        value={selectedDay}
        minimumDate={minDate}
        maximumDate={enableFutureDates ? undefined : utils('en').getToday()}
        calendarClassName="responsive-calendar"
        formatInputText={formatInputValue}
        calendarPopperPosition='top'
        renderInput={addClear ? renderCustomInput: undefined}
      />

      {error && (
        <div
          data-testid="error-msg-element"
          style={{
            marginTop: '0.25rem',
            fontSize: '0.75rem',
            lineHeight: '1.125rem',
            color: colorTheme.danger,
          }}>
          {error}
        </div>
      )}
    </StyledDatePicker>
  );
};

export default DatePickerComponent;
