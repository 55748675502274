import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  Typography,
  Input,
  Checkbox,
  Button,
  WizardStep,
} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {signUpEmailNotifsSchema} from '../../../schema/sign-up.schema';
import {PatchUserProfileBody} from '../../../server/types/user.types';
import logo from '../../../../assets/KAZAM_logo_Transparent.svg';
import {usePatchUserProfile} from '../../../server/react-query';
import {useAuth} from '../../../hooks/useAuth';
import {useState} from 'react';
import Page from '../../../../core/components/page/Page';
import {localization} from '../../../localization/Localization';
import {useAnalyticsTrackComponent} from '../../../hooks/useAnalyticsTrackComponent';
import {useLocation} from 'react-router-dom';
import {RoutePath} from '../../../navigation/config/RouteConfig';
import {useAnalytics} from '../../../hooks/useAnalytics';

const SignUpEmailNotifsPage = (props: any) => {
  useAnalyticsTrackComponent(SignUpEmailNotifsPage.name);

  const {updateAuthData, authData} = useAuth();
  const location = useLocation();
  const {systemLogEvent} = useAnalytics();

  const patchUserProfile = usePatchUserProfile();

  const [consentEmailNotifs, setConsentEmailNotifs] = useState(true);
  const [consentEmailMarketing, setConsentEmailMarketing] = useState(true);

  const {
    control,
    getValues,
    setValue,
    formState: {errors},
    handleSubmit,
  } = useForm<PatchUserProfileBody>({
    resolver: yupResolver(signUpEmailNotifsSchema),
    defaultValues: {
      email: '',
      receiveMarketingEmails: true,
      receiveSearchMatchEmailNotifs: true,
    },
  });

  const submitForm = async () => {
    var data = getValues();
    if (consentEmailNotifs || consentEmailMarketing || data.email) {
      handleSubmit(handleNext)();
    } else {
      handleNext();
    }
  };

  const handleNext = async () => {
    try {
      var formData = getValues();

      var user = await patchUserProfile.mutateAsync(formData);

      let data = authData;
      if (data) {
        data.user = user;
        updateAuthData(data);
      }

      if (location.pathname === RoutePath.EMPLOYER_SIGN_UP) {
        systemLogEvent('homeo_reg_step_4_opt_in');
      } else {
        systemLogEvent('kazam_reg_step_6_opt_in');
      }

      props.nextStep();
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleSkip = () => {
    if (location.pathname === RoutePath.EMPLOYER_SIGN_UP) {
      systemLogEvent('homeo_reg_step_4_opt_out');
    } else {
      systemLogEvent('kazam_reg_step_6_opt_out');
    }

    props.nextStep();
  };

  const onChangeReceiveSearchNotifsCheckbox = (value: boolean) => {
    setConsentEmailNotifs(value);
    setValue('receiveSearchMatchEmailNotifs', value);
  };

  const onChangeReceiveEmailMarketingCheckbox = (value: boolean) => {
    setConsentEmailMarketing(value);
    setValue('receiveMarketingEmails', value);
  };

  return (
    <Page hideNavbar>
      <div className="space-y-6">
        <div>
          <WizardStep
            steps={props.totalSteps}
            currentStep={props.currentStep}></WizardStep>
        </div>
        <div className="flex justify-center">
          <img style={{height: '29px'}} src={logo} alt="Logo" />
        </div>
        <div>
          <div>
            <Typography
              label={localization.hdr_created_acc}
              variant="f3"
              align="center"
              color={colorTheme.dark}></Typography>
          </div>
          <div className="pt-1">
            <Typography
              label={localization.desc_created_acc}
              variant="f1"
              align="center"
              color={colorTheme.darkFaded}></Typography>
          </div>
        </div>
        <div>
          <div>
            <Controller
              control={control}
              name="email"
              render={({field: {value = '', onBlur, onChange}}) => (
                <Input
                  type="email"
                  inputSize="large"
                  placeholder={localization.ph_enter_email}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  onSubmit={submitForm}
                  error={errors.email?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="space-y-4">
          <div>
            <div className="flex">
              <Checkbox
                checked={true}
                onChange={onChangeReceiveSearchNotifsCheckbox}
              />
              <Typography
                label={localization.check_email_updates}
                variant="f1"
                align="left"
                color={colorTheme.dark}></Typography>
            </div>
          </div>
          <div>
            <div className="flex">
              <Checkbox
                checked={true}
                onChange={onChangeReceiveEmailMarketingCheckbox}
              />
              <div>
                <div className="flex space-x-1">
                  <Typography
                    label="Send me marketing emails from"
                    variant="f1"
                    align="left"
                    color={colorTheme.dark}></Typography>
                  <Typography
                    label="kazam"
                    variant="f1"
                    weight="semibold"
                    align="left"
                    color={colorTheme.primary}></Typography>
                </div>
                <Typography
                  label="and its affiliates."
                  variant="f1"
                  align="left"
                  color={colorTheme.dark}></Typography>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <Button
              label={localization.btn_next}
              color="primary"
              onClick={submitForm}
              isLoading={patchUserProfile.isLoading}
            />
          </div>

          {authData?.user.userRole === 'kazam' &&
            <div className="pt-4">
              <Button
                label={localization.btn_skip}
                variant="outlined"
                onClick={handleSkip}
              />
            </div>
          }
          
        </div>
      </div>
    </Page>
  );
};

export default SignUpEmailNotifsPage;
