import Page from '../../../core/components/page/Page';
import {useHistory, useParams} from 'react-router-dom';
import {useDialog} from '../../../core/components/dialog';
import {useGetVerificationStatus} from '../../server/react-query';
import {useEffect} from 'react';
import FetchingVerificationResults from './FetchingVerificationResults';
import {RoutePath} from '../../navigation/config/RouteConfig';
import UserVerificationSuccess from './UserVerificationSuccess';
import { Browser } from '@capacitor/browser';
import { isUsingApp } from '../../utils/random.util';
import { isIOS } from 'react-device-detect';

interface UserVerificationRedirectionParams {
  id: string;
}

const UserVerificationRedirectionPage = () => {
  const {presentDialog, dismissDialog} = useDialog();
  const history = useHistory();

  const {id: userId} = useParams<UserVerificationRedirectionParams>();
  const {data: verificationData} = useGetVerificationStatus(userId);

  useEffect(() => {
    showFetchingResults();
    if (isUsingApp() && isIOS) closeBrowser();
  }, [userId]);

  useEffect(() => {
    if (verificationData) {
      dismissDialog();

      if (
        (verificationData.status === 'accepted' ||
          verificationData.status === 'revoked') &&
        (verificationData.type === 'facialDocument' ||
          verificationData.type === 'document')
      ) {
        if (verificationData.mismatchResult?.hasMismatch) {
          history.replace(RoutePath.USER_VERIFICATION_QUEUED);
        } else {
          history.replace(RoutePath.MY_PROFILE);
        }
      } else {
        history.replace(RoutePath.MY_PROFILE);
      }
    }
  }, [verificationData]);

  const closeBrowser = async () => {
    await Browser.close();
  }

  const showFetchingResults = () => {
    presentDialog({
      headerText: '',
      enableBackdropDismiss: false,
      hideClose: true,
      content: (
        <>
          <FetchingVerificationResults />
        </>
      ),
    });
  };

  const showVerifiedModal = () => {
    presentDialog({
      headerText: '',
      enableBackdropDismiss: false,
      hideClose: true,
      content: (
        <>
          <UserVerificationSuccess
            data={verificationData}
            onContinueClick={() => {
              dismissDialog();
              history.replace(RoutePath.MY_PROFILE);
            }}
          />
        </>
      ),
    });
  };

  return (
    <>
      <Page showLoading>
        <></>
      </Page>
    </>
  );
};

export default UserVerificationRedirectionPage;
