import {Button, Input, Typography} from '../../../../core/components';
import {StyledWrapper} from './styled';
import {colorTheme} from '../../../../core/configs';
import {usePutMatchingAttribute} from '../../../server/react-query';
import {useToast} from '../../../../core/components/toast';
import {
  MatchAttributeType,
  MatchingAttributeResponse,
  SearchWeightParams,
} from '../../../server/types/admin-control.types';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {searchWeightsSchema} from '../../../schema/admin.schema';
import {useEffect} from 'react';

interface SearchWeightsViewProps {
  data: MatchingAttributeResponse[];
}

const SearchWeightsView = ({data}: SearchWeightsViewProps) => {
  const {presentToast} = useToast();
  const putMatchingAttribute = usePutMatchingAttribute();

  const {
    control,
    getValues,
    setValue,
    formState: {errors},
    handleSubmit,
  } = useForm<SearchWeightParams>({
    resolver: yupResolver(searchWeightsSchema),
    defaultValues: {
      locationWeighValue: 0,
      employmentWeightValue: 0,
      salaryWeightValue: 0,
      roleWeightValue: 0,
    },
  });

  useEffect(() => {
    if (data && data.length > 0) {
      data.map((att, i) => {
        updateWeights(att.attributeType, att.weightValue);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const updateWeights = (type: MatchAttributeType, value: number) => {
    switch (type) {
      case 'location':
        setValue('locationWeighValue', value);
        break;

      case 'employment':
        setValue('employmentWeightValue', value);
        break;

      case 'salary':
        setValue('salaryWeightValue', value);
        break;

      case 'role':
        setValue('roleWeightValue', value);
        break;

      case 'personalityMatch':
        setValue('personalityMatchWeightValue', value);
        break;

      default:
        break;
    }
  };

  const submitForm = () => {
    handleSubmit(submit)();
  };

  const submit = async () => {
    try {
      await putMatchingAttribute.mutateAsync(getValues());
      presentToast({
        message: 'Successfully saved.',
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      console.log(e);
      presentToast({
        message: 'Please enter valid value. Total weights must be 100.',
        variant: 'error',
        position: 'bottom',
      });
    }
  };

  return (
    <StyledWrapper>
      <Typography
        label="SEARCH WEIGHTS"
        variant="f1"
        weight="semibold"
        color={colorTheme.black}
      />
      <Typography
        className="pt-3"
        label="Set the weights of each parameter. This will affect the sorting of matches."
        variant="f2"
        color={colorTheme.dark}
      />
      <div className="flex justify-between items-center">
        <div className="pt-3 flex flex-row justify-between items-center">
          <Typography label="Location" variant="f3" color={colorTheme.dark} />
        </div>
        <div className="pt-3" style={{width: '85px'}}>
          <Controller
            control={control}
            name="locationWeighValue"
            render={({field: {value, onBlur, onChange}}) => (
              <Input
                type="number"
                inputSize="large"
                placeholder="0%"
                onChange={onChange}
                value={value}
                error={errors.locationWeighValue?.message}
                onBlur={onBlur}
              />
            )}
          />
        </div>
      </div>

      <div className="flex justify-between items-center">
        <div className="pt-3 flex flex-row justify-between items-center">
          <Typography
            label="Living arrangement"
            variant="f3"
            color={colorTheme.dark}
          />
        </div>
        <div className="pt-3" style={{width: '85px'}}>
          <Controller
            control={control}
            name="employmentWeightValue"
            render={({field: {value, onBlur, onChange}}) => (
              <Input
                type="number"
                inputSize="large"
                placeholder="0%"
                onChange={onChange}
                value={value}
                error={errors.employmentWeightValue?.message}
                onBlur={onBlur}
              />
            )}
          />
        </div>
      </div>

      <div className="flex justify-between items-center">
        <div className="pt-3 flex flex-row justify-between items-center">
          <Typography label="Max salary" variant="f3" color={colorTheme.dark} />
        </div>
        <div className="pt-3" style={{width: '85px'}}>
          <Controller
            control={control}
            name="salaryWeightValue"
            render={({field: {value, onBlur, onChange}}) => (
              <Input
                type="number"
                inputSize="large"
                placeholder="0%"
                onChange={onChange}
                value={value}
                error={errors.salaryWeightValue?.message}
                onBlur={onBlur}
              />
            )}
          />
        </div>
      </div>

      <div className="flex justify-between items-center">
        <div className="pt-3 flex flex-row justify-between items-center">
          <Typography label="Role" variant="f3" color={colorTheme.dark} />
        </div>
        <div className="pt-3" style={{width: '85px'}}>
          <Controller
            control={control}
            name="roleWeightValue"
            render={({field: {value, onBlur, onChange}}) => (
              <Input
                type="number"
                inputSize="large"
                placeholder="0%"
                onChange={onChange}
                value={value}
                error={errors.roleWeightValue?.message}
                onBlur={onBlur}
              />
            )}
          />
        </div>
      </div>

      <div className="flex justify-between items-center">
        <div className="pt-3 flex flex-row justify-between items-center">
          <Typography label="Personality Match" variant="f3" color={colorTheme.dark} />
        </div>
        <div className="pt-3" style={{width: '85px'}}>
          <Controller
            control={control}
            name="personalityMatchWeightValue"
            render={({field: {value, onBlur, onChange}}) => (
              <Input
                type="number"
                inputSize="large"
                placeholder="0%"
                onChange={onChange}
                value={value}
                error={errors.personalityMatchWeightValue?.message}
                onBlur={onBlur}
              />
            )}
          />
        </div>
      </div>

      <div className="pt-3 flex justify-end">
        <div style={{width: 'fit-content'}}>
          <Button
            label="Save"
            color="primary"
            onClick={submitForm}
            isLoading={putMatchingAttribute.isLoading}
          />
        </div>
      </div>
    </StyledWrapper>
  );
};

export default SearchWeightsView;
