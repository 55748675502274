import {Button, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {useHistory} from 'react-router-dom';
import {useAnalytics} from '../../../hooks/useAnalytics';
import {RoutePath} from '../../../navigation/config/RouteConfig';
import {isMobile} from 'react-device-detect';
import kasambahay from '../../../../assets/kasambahay.webp';
import homeowner from '../../../../assets/homeowner.webp';
import { StyledBodyWrapper, StyledHeader, StyledHomeWrapper } from './styled';
import { forwardRef } from 'react';

const Home = forwardRef<HTMLDivElement>((props, ref) => {
  const history = useHistory();
  const {systemLogEvent} = useAnalytics();

  const goToKazamSignUp = () => {
    systemLogEvent('signup_as_kazam');
    history.push(RoutePath.SIGN_UP);
  };

  const goToEmployerSignUp = () => {
    systemLogEvent('signup_as_homeowner');
    history.push(RoutePath.EMPLOYER_SIGN_UP);
  };
  return (
    <StyledHomeWrapper ref={ref} className='flex flex-col'>
      <div className='p-6 lg:p-9 flex-none'>
        <StyledHeader
          dangerouslySetInnerHTML={{
            __html:
              '<b>kazam</b> connects kasambahays and homeowners. Join the thousands of Filipinos! Sign up today.',
          }}
          />
        </div>
        <StyledBodyWrapper className='grow flex flex-col md:flex-row'>
            <div className='kasambahay-section relative'>
              <div className="kasambahay-overlay-bg absolute inset-0"></div>
              <div className='kasambahay-overlay flex flex-col items-center justify-between absolute inset-0 py-4 md:py-12'>
                <Typography
                  className="z-10"
                  label="Naghahanap ka ng trabaho?"    
                  weight="semibold"
                  align='center'
                  variant={isMobile ? 'f2' : 'f6'}
                  color='white'
                />
                <img className='absolute bottom-0' src={kasambahay}/>
                <div className="cta z-10 px-2 py-1 md:px-4 md:py-2"
                  onClick={goToKazamSignUp}>
                  <Typography
                    label="Sign up as a Kasambahay"    
                    weight="semibold"
                    align='center'
                    variant={isMobile ? 'f2' : 'f4'}
                    color='white'
                  />
                </div>
              </div>
              
            </div>
            <div className='homeowner-section relative'>
              <div className="homeowner-overlay-bg absolute inset-0"></div>
              <div className='homeowner-overlay flex flex-col items-center justify-between absolute inset-0 py-4 md:py-12'>
                <Typography
                  className="z-10"
                  label="Looking for a Kasambahay to hire?"    
                  weight="semibold"
                  align='center'
                  variant={isMobile ? 'f2' : 'f6'}
                  color='white'
                />
                <img className='absolute bottom-0' src={homeowner}/>
                <div className="cta z-10 px-2 py-1 md:px-4 md:py-2"
                  onClick={goToEmployerSignUp}>
                  <Typography
                    label="Sign up as a Homeowner"    
                    weight="semibold"
                    align='center'
                    variant={isMobile ? 'f2' : 'f4'}
                    color='white'
                  />
                </div>
              </div>
            </div>
        </StyledBodyWrapper>
    </StyledHomeWrapper>
  );
});

export default Home;
