import Page from '../../../core/components/page/Page';
import {Typography} from '../../../core/components';
import {colorTheme} from '../../../core/configs';

const NotificationSettingsPage = () => {
  return (
    <Page showBackButton>
      <div className="space-y-4">
        <div>
          <Typography
            label="Email Notifications"
            variant="f3"
            align="center"
            color={colorTheme.dark}
          />
          <div className="pt-1">
            <Typography
              label="Consent to receive email notifiations below."
              variant="f1"
              align="center"
              color={colorTheme.darkFaded}></Typography>
          </div>
        </div>

        <div className="pt-6 space-y-4"></div>
      </div>
    </Page>
  );
};

export default NotificationSettingsPage;
