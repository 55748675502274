import styled, {css} from 'styled-components';
import {colorTheme} from './../../configs/color.config';
import {TextAreaSize, TextAreaSizeStyle} from './textarea.types';

const inputSizeStyles: {[key in TextAreaSize]: TextAreaSizeStyle} = {
  default: {
    padding: 0.5,
    textSize: 0.75,
    lineheight: 1.125,
  },
  large: {
    padding: 1,
    textSize: 0.875,
    lineheight: 1.25,
  },
  small: {
    padding: 0.5,
    textSize: 0.75,
    lineheight: 1.125,
  },
};

export interface StyledTextAreaProps {
  error?: string;
  textAreaSize: TextAreaSize;
  bordered?: boolean;
}

export const StyledTextArea = styled.textarea<StyledTextAreaProps>`
  width: 100%;
  border-radius: 4px;

  resize: none;

  color: ${colorTheme.dark};

  font-size: 11.2px;
  line-height: 16.8px;
  padding-left: 4px;
  padding-right: 4px;

  ${pr =>
    pr.bordered &&
    css`
      padding: 0.5rem !important;
      font-size: 14px !important;
      line-height: 21px !important;
      border: 1px solid ${pr.error ? colorTheme.danger : colorTheme.lightGray};
    `}

  &:focus {
    outline: none;
    ${pr =>
      pr.bordered &&
      css`
        border: 1px solid ${pr.error ? colorTheme.danger : colorTheme.accent};
      `}
  }

  &:disabled {
    color: ${colorTheme.lightGray};
  }
`;

export const StyledTextAreaForBroadcast = styled.textarea<StyledTextAreaProps>`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none !important;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  border-radius: 4px;

  width: 100%;
  height: 100%;

  color: ${colorTheme.dark};

  padding: 0.5rem 1rem;

  font-size: 1rem;
  line-height: 1.5rem;
  text-align: left;

  border: 1px solid
    ${pr => (pr.error ? colorTheme.danger : colorTheme.lightGray)};

  &:focus {
    outline: none;
    border: 1px solid
      ${pr => (pr.error ? colorTheme.danger : colorTheme.accent)};
  }

  &:disabled {
    color: ${colorTheme.lightGray};
  }

  ::placeholder {
    color: ${colorTheme.darkFaded};
  }

  background: white;
`;

export const StyledInputLabel = styled.div<StyledTextAreaProps>`
  margin-bottom: 0.25rem;

  font-size: 0.8rem;
  font-weight: 600;
  line-height: 16.8px;

  color: ${colorTheme.darkFaded};
`;
