import {useEffect, useState} from 'react';
import {DurationInput, Toggle, Typography} from '../../../../core/components';
import {StyledWrapper} from './styled';
import {colorTheme} from '../../../../core/configs';
import {usePutAdminControl} from '../../../server/react-query';
import {useToast} from '../../../../core/components/toast';
import {
  AdminControl,
  AdminControlReqBody,
  AdminControlType,
  DurationContent,
} from '../../../server/types/admin-control.types';
import SoloDurationInput from '../../../../core/components/days-input/days-input';
import DaysInput from '../../../../core/components/days-input/days-input';

interface EkycSubsequentReminderViewProps {
  data: AdminControl[];
}

const EkycSubsequentReminderView = ({
  data,
}: EkycSubsequentReminderViewProps) => {
  const {presentToast} = useToast();
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [control, setControl] = useState<AdminControl | undefined>();

  const putAdminControl = usePutAdminControl();

  const [ekycSubsequentReminder, setEkycSubsequentReminder] =
    useState<DurationContent>();

  useEffect(() => {
    if (data) {
      var ekycSubsequentReminder = data.find(
        d => d.type === 'ekycSubsequentReminder',
      );

      if (ekycSubsequentReminder) {
        setIsEnabled(ekycSubsequentReminder.enabled);
        var duration = JSON.parse(
          ekycSubsequentReminder?.content.toLowerCase(),
        ) as DurationContent;
        setEkycSubsequentReminder(duration);
        setControl(ekycSubsequentReminder);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSaveEkycSubsequentReminder = (value: DurationContent) => {
    const data: AdminControlReqBody = {
      type: 'ekycSubsequentReminder',
      enabled: value.days === 0 ? false : true,
      content: JSON.stringify(value),
    };
    onPutAdminControl(data);
    setIsEnabled(value.days === 0 ? false : true);
  };

  const onPutAdminControl = async (data: AdminControlReqBody) => {
    try {
      const res = await putAdminControl.mutateAsync(data);
      setControl(res);
      presentToast({
        message: `Successfully saved.`,
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      console.log(e);
    }
  };
  const handleToggle = (toggled: boolean) => {
    setIsEnabled(toggled);

    const data: AdminControlReqBody = {
      type: control?.type || 'ekycSubsequentReminder',
      enabled: toggled,
      content: control?.content || JSON.stringify({duration: 0}),
    };

    onPutAdminControl(data);
  };

  return (
    <StyledWrapper>
      <Typography
        label="KAZAM SETTINGS"
        variant="f1"
        weight="semibold"
        color={colorTheme.black}
      />

      <div>
        <div className="pt-3 flex flex-row justify-between items-center">
          <div>
            <Typography
              label="Deferred Onboarding Subsequent In-App Reminder"
              variant="f3"
              color={colorTheme.dark}
            />
            <Typography
              label="Set the duration on how many days after the initial reminder the subsequent EKYC in-app reminder will show for those users that has not completed the EKYC and Personality Test."
              variant="f1"
              color={colorTheme.darkFaded}
            />
          </div>
          <div>
            <Toggle value={isEnabled} onToggle={handleToggle} />
          </div>
        </div>

        {isEnabled && (
          <>
            <div className="pt-3">
              <DaysInput
                duration={ekycSubsequentReminder}
                onSubmit={onSaveEkycSubsequentReminder}
                isLoading={putAdminControl.isLoading}
                minValue={1}
              />
            </div>
          </>
        )}
      </div>
    </StyledWrapper>
  );
};

export default EkycSubsequentReminderView;
