import React from 'react';
import {
  StyledDiv,
  StyledIconOverlay,
  StyledImage,
  StyledOverlay,
} from './styled';
import {Icon} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import { ChatContentMedia } from '../../../server/types';

interface ViewImageAttachmentProps {
  media: ChatContentMedia;
  onClose: () => void;
  onImageClicked: () => void;
}

const ViewImageAttachment = ({media, onClose, onImageClicked}: ViewImageAttachmentProps) => {

  const onClickClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClose();
  };

  return (
    <StyledDiv>
      <StyledImage
        src={media.url}
        alt="ad"
      />

      <StyledOverlay className="flex justify-end">
        <StyledIconOverlay>
          <Icon
            name="download"
            color={colorTheme.white}
            size={24}
            type="button"
            onClick={onImageClicked}
          />
        </StyledIconOverlay>
        <StyledIconOverlay className='ml-2'>
          <Icon
            name="x"
            color={colorTheme.white}
            size={24}
            type="button"
            onClick={onClickClose}
          />
        </StyledIconOverlay>
      </StyledOverlay>
    </StyledDiv>
  );
};

export default ViewImageAttachment;
