import * as React from 'react';
import {SVGProps, Ref, forwardRef, memo} from 'react';
const SvgMissedVideo = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 25"
    ref={ref}
    {...props}>
    <path
      fill="currentColor"
      d="M10.7 15.575a.942.942 0 0 0 .375-.075c.117-.05.225-.125.325-.225l3.1-3.125a.977.977 0 0 0 .287-.688.93.93 0 0 0-.287-.712.948.948 0 0 0-.7-.275.948.948 0 0 0-.7.275l-2.4 2.4L8.5 11H9c.283 0 .52-.096.713-.287A.968.968 0 0 0 10 10a.968.968 0 0 0-.287-.713A.968.968 0 0 0 9 9H6a.968.968 0 0 0-.713.287A.968.968 0 0 0 5 10v3c0 .283.096.52.287.713.192.191.43.287.713.287s.52-.096.713-.287A.968.968 0 0 0 7 13v-.7l3 3c.1.1.208.17.325.212.117.042.242.063.375.063ZM4 20c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 2 18V6c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 4 4h12c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v4.5l3.15-3.15c.167-.167.35-.208.55-.125.2.083.3.242.3.475v8.6c0 .233-.1.392-.3.475-.2.083-.383.042-.55-.125L18 13.5V18c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 16 20H4Zm0-2h12V6H4v12Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgMissedVideo);
const Memo = memo(ForwardRef);
export default Memo;
