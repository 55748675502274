import { useMutation, useQuery, useQueryClient } from "react-query";
import { API, ApiEndpointsEnum } from "../api";
import { JobHistoryResponse } from "../types/job-offer.types";
import { PutJobHistoryBody } from "../types/job-history.type";


const getJobHistory = () => {
    const url = ApiEndpointsEnum.GET_JOB_HISTORY;
    return API.get<JobHistoryResponse[]>(url);
  };
  
  const useGetJobHistory = () => {
    return useQuery(['get-job-history'], () => getJobHistory());
  };

  const putJobHistory = async (reqBody: PutJobHistoryBody, jobHistoryId: string) => {
    const url = ApiEndpointsEnum.PUT_JOB_HISTORY.replace('{id}', jobHistoryId);
    return API.put<JobHistoryResponse>(url, reqBody);
  };
  
  const usePutJobHistory = (jobHistoryId: string) => {
    const queryClient = useQueryClient();
  
    return useMutation(
      ['put-job-history'],
      (reqBody: PutJobHistoryBody) => putJobHistory(reqBody, jobHistoryId),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['put-job-history']);
        },
      },
    );
  }

  export {
    useGetJobHistory,
    usePutJobHistory
  };
  