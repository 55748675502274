import Page from '../../../../core/components/page/Page';
import {Button, Input, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {useGetMyProfile} from '../../../server/react-query';
import {useEffect, useState} from 'react';
import {KazamException} from '../../../server/types';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {useGetSubscription} from '../../../server/react-query/useSubscription';
import {localization} from '../../../localization/Localization';
import {usePostVoucherRedeem} from '../../../server/react-query/useVoucher';
import {
  HandleVoucherRedeem,
  VoucherRedeemResponse,
} from '../../../server/types/voucher.types';
import {usePostCheckoutSession} from '../../../server/react-query/usePayment';
import {isUsingApp} from '../../../utils/random.util';
import {InAppBrowser} from '@capgo/inappbrowser';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {handleVoucherRedeemSchema} from '../../../schema/voucher.schema';
import { useGetActiveSubscription } from '../../../server/react-query/useUserSubscription';
import { useDialog } from '../../../../core/components/dialog';
import SuccessfulPaymentDialogView from '../../../views/dialog-content/paywall/successful-payment-dialog/SuccessfulPaymentDialogView';
import { RoutePath } from '../../../navigation/config/RouteConfig';

interface PurchaseSummaryPageParams {
  id: string;
  userId: string;
}

const PurchaseSummaryPage = () => {
  const location = useLocation();
  let history = useHistory();
  const { presentDialog, dismissDialog } = useDialog()

  const {data: userData, isFetching, refetch} = useGetMyProfile();
  const {
    data: userSubscription,
    isFetching: fetchingSubscription,
    refetch: refetchSubscription
  } = useGetActiveSubscription(true);
  const postVoucherRedeem = usePostVoucherRedeem();

  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    setError,
    formState: {errors},
  } = useForm<HandleVoucherRedeem>({
    resolver: yupResolver(handleVoucherRedeemSchema),
  });

  const {id: subscriptionId, userId} = useParams<PurchaseSummaryPageParams>();

  const {data: subscriptionData, isFetching: isSubscriptionFetching} =
    useGetSubscription(subscriptionId);

  const [codes, setCodes] = useState<string[]>([]);
  const [vouchers, setVouchers] = useState<VoucherRedeemResponse>();

  useEffect(() => {
    if (userSubscription) {
      showSuccessModal()
    }
  }, [userSubscription])

  const showSuccessModal = () => {
    presentDialog({
      headerText: '',
      enableBackdropDismiss: false,
      hideClose: true,
      content: (
        <SuccessfulPaymentDialogView
          data={userSubscription}
          onContinueClick={() => {
            dismissDialog()
            history.replace(RoutePath.MATCHES_LANDING);
          }}
        />
      )
    });
  }

  const submitForm = async () => {
    handleSubmit(handleRedeemVoucher)();
  };

  const handleRedeemVoucher = async () => {
    var value = getValues();
    if (codes && codes.length === 0) {
      try {
        let voucherCodes: string[] = [];

        if (codes && codes.length > 0) {
          voucherCodes = [...codes, value.code!];
        } else {
          voucherCodes.push(value.code!);
        }

        const response = await postVoucherRedeem.mutateAsync({
          codes: voucherCodes,
          subscriptionId: subscriptionId,
        });
        setVouchers(response);
        if (value.code) {
          setCodes(prevCodes => [...prevCodes, value.code]);
        }
      } catch (e: any) {
        console.log('error', e);
        const exception = e.data as KazamException;
        setError('code', {message: exception.error});
      }
    } else {
      setError('code', {message: 'Only one voucher can be redeemed.'});
    }
  };

  const postCheckoutSession = usePostCheckoutSession();
  const createCheckoutSession = async (subscriptionId: string) => {
    try {
      const clientUrl = process.env.REACT_APP_CLIENT_URL ?? 'https://kazam.ph/';
      // const clientUrl = "http://localhost:3000/"
      let successUrl = `${clientUrl}payment/success/${userId}`;
      const cancelUrl = `${clientUrl}${location.pathname.substring(1)}`;
      let voucherIds = vouchers?.vouchers.flatMap(v => v.voucherId);
      if (isUsingApp()) {
        successUrl = `https://redirect.url/payment/success/${userId}`;
      }

      const checkoutSession = await postCheckoutSession.mutateAsync({
        subscriptionId: subscriptionId,
        cancelUrl: cancelUrl,
        successUrl: successUrl,
        voucherIds: voucherIds ?? [],
      });

      if (!!checkoutSession.checkoutUrl && checkoutSession.redirectToCheckout) {
        if (isUsingApp()) {
          await openInAppBrowser(
            checkoutSession.checkoutUrl,
            cancelUrl,
            successUrl,
          );
        } else {
          window.open(checkoutSession.checkoutUrl, '_self');
        }
      } else {
        history.replace(`/payment/success/${userId}`);
      }
    } catch (e: any) {
      const exception = e.data as KazamException;
    }
  };

  const openInAppBrowser = async (
    checkoutUrl: string,
    cancelUrl: string,
    successUrl: string,
  ) => {
    await InAppBrowser.openWebView({
      url: checkoutUrl,
      title: 'Kazam payment',
      toolbarColor: '#ffffff',
      closeModal: true,
    });

    InAppBrowser.addListener('urlChangeEvent', data => {
      if ([cancelUrl, successUrl].includes(data.url)) {
        InAppBrowser.close();
        InAppBrowser.removeAllListeners();

        if (data.url === successUrl) {
          history.replace(`/payment/success/${userId}`);
        }
      }
    });

    InAppBrowser.addListener('closeEvent', data => {
      InAppBrowser.removeAllListeners();
      refetchSubscription()
    });
  };

  return (
    <Page
      showBackButton
      showLoading={isFetching || fetchingSubscription}>
      <div className="space-y-6">
        <Typography
          label="Purchase Summary"
          variant="f3"
          weight="semibold"
          align="center"
          color={colorTheme.dark}
        />

        <Typography
          label="Review below details before you proceed to checkout."
          variant="f1"
          align="center"
          color={colorTheme.darkFaded}
        />
        <div className="flex w-full justify-between items-center border-b border-borderLightGray">
          <div className="flex flex-col">
            <Typography
              label={`1x ${subscriptionData?.name} ${subscriptionData?.amount}`}
              variant="f2"
              align="center"
              color={colorTheme.dark}
            />
            <Typography
              label="Subscription"
              variant="f1"
              color={colorTheme.darkFaded}
            />
          </div>

          <Typography
            label={`Php ${subscriptionData?.amount}`}
            variant="f2"
            align="center"
            color={colorTheme.dark}
          />
        </div>

        <div className="space-y-2 w-full">
          <div className="flex w-full justify-between items-center">
            <Typography
              label="Subtotal"
              variant="f2"
              align="center"
              color={colorTheme.dark}
            />

            <Typography
              label={`Php ${subscriptionData?.amount}`}
              variant="f2"
              align="center"
              color={colorTheme.dark}
            />
          </div>

          {vouchers &&
            vouchers.vouchers.map(v => {
              return (
                <div className="flex w-full justify-between items-center gap-2">
                  <Typography
                    className="truncate"
                    label={v.code}
                    variant="f2"
                    align="center"
                    color={colorTheme.green}
                  />

                  <Typography
                    className='min-w-max'
                    label={`-Php ${v.discountValue}`}
                    variant="f2"
                    align="center"
                    color={colorTheme.green}
                  />
                </div>
              );
            })}

          <div className="flex w-full justify-between items-center">
            <Typography
              label="Total"
              variant="f3"
              weight="semibold"
              align="center"
              color={colorTheme.dark}
            />

            <Typography
              label={
                vouchers
                  ? `Php ${vouchers.totalValue}`
                  : `Php ${subscriptionData?.amount}`
              }
              variant="f3"
              weight="semibold"
              align="center"
              color={colorTheme.dark}
            />
          </div>
        </div>

        <div className="flex justify-between space-x-6">
          <div className="w-full">
            <Controller
              control={control}
              name="code"
              render={({field: {value, onChange}}) => (
                <>
                  <Input
                    inputSize="large"
                    placeholder="Enter voucher code"
                    value={value}
                    onChange={onChange}
                    error={errors.code?.message}
                  />
                </>
              )}
            />
          </div>

          <div className="w-fit">
            <Button
              label="Apply"
              color="primary"
              onClick={submitForm}
              isLoading={postVoucherRedeem.isLoading}
            />
          </div>
        </div>

        <Button
          label={localization.btn_proceed_payment}
          color="primary"
          isLoading={postCheckoutSession.isLoading}
          onClick={() => createCheckoutSession(subscriptionId)}
        />
      </div>
    </Page>
  );
};

export default PurchaseSummaryPage;
