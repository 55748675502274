import styled from 'styled-components';
import {colorTheme} from '../../../core/configs';

export const StyledChatPage = styled.div`
  & .search-bar {
    padding: 16px;
  }

  & .page {
    padding: 8px 0;
  }

  & .avatar {
    width: 55px;
    height: 55px;
    border-radius: 30px;
  }

  & .tabs {
    height: 40px;
  }

  & .tab {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid ${colorTheme.lightGray};
  }

  & .tab:hover {
    background: ${colorTheme.lightGray};
  }

  & .active-tab {
    border-bottom: 1px solid ${colorTheme.dark};
  }
`;

export const StyledChatsComponent = styled.div``;

export const StyledFavoritesComponent = styled.div``;

export const StyledKawaysComponent = styled.div``;

interface ChatItemProps {
  isHomeowner?: boolean;
}

export const StyledGenericChatItem = styled.div<ChatItemProps>`
  padding: 0 24px;
  cursor: pointer;
  margin-bottom: 24px;

  :hover,
  :active {
    background-color: #f3f4f6;
  }

  & .admin-profile {
    background: #edebff;
    border-radius: 8px;
    padding: 4px;
  }

  & .profile-picture {
    width: 55px;
  }

  & .disabled-avatar {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    position: relative;
    background: ${colorTheme.darkFaded};
  }

  & .avatar {
    width: 55px;
    height: 55px;
    position: relative;
    background: ${colorTheme.primary};
  }

  & .avatar-admin {
    background: #edebff;
  }

  & .avatar img {
    width: 55px;
    height: 55px;
    border-radius: 28px;
    object-fit: cover !important;
  }

  & .online-status {
    width: 12px;
    height: 12px;
    position: absolute;
    float: right;
    right: 1px;
    bottom: 1px;
    background: white;
    padding: 1px;
    border-radius: 6px;
  }

  & .bullet {
    padding: 0 4px;
  }

  & .flex1 {
    flex: 1 1 0%;
    min-width: 0;
  }

  & .overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyleAdminIcon = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: ${colorTheme.primary};
`;

