import Page from '../../../core/components/page/Page';
import {Button, Typography} from '../../../core/components';
import {colorTheme} from '../../../core/configs';
import verification from '../../../assets/verification.svg';
import {StyledImg} from './styled';

import {useGetMyProfile} from '../../server/react-query';

import VerifiedTag from '../../../core/components/verified-tag/VerifiedTag';
import {useEffect, useState} from 'react';
import {VerifiedStatus} from '../../server/types';
import {useHistory} from 'react-router-dom';
import {RoutePath} from '../../navigation/config/RouteConfig';
import TrueSelfieTag from '../../../core/components/true-selfie-tag/TrueSelfieTag';
import {addMonths, differenceInMonths, format} from 'date-fns';
import GenericConfirmationDialog from '../../views/dialog-content/generic-dialog/GenericConfirmationDialog';
import {useDialog} from '../../../core/components/dialog';

const TrueSelfieVerificationPage = () => {
  const {data, isFetching, refetch} = useGetMyProfile();
  const {presentDialog, dismissDialog} = useDialog();

  const history = useHistory();

  const [status, setStatus] = useState<VerifiedStatus>('notVerified');

  useEffect(() => {
    if (data && data?.verifiedStatus) {
      setStatus(data.verifiedStatus);
    }
  }, [data]);

  const onStartCaptureVerification = async () => {
    if (data) {
      const lastVerifiedDate = data?.lastVerifiedDate
        ? new Date(data.lastVerifiedDate)
        : null;
      const lastTrueSelfieVerifiedDate = data?.lastTrueSelfieVerifiedDate
        ? new Date(data.lastTrueSelfieVerifiedDate)
        : null;
      const currentDate = new Date();

      // If the user has never verified, allow them to proceed
      if (!lastVerifiedDate && !lastTrueSelfieVerifiedDate) {
        history.push(RoutePath.TRUE_SELFIE_VERIFICATION);
        return;
      }

      if (lastVerifiedDate) {
        const monthsSinceLastVerification = differenceInMonths(
          currentDate,
          lastVerifiedDate,
        );

        // Check if 6 months or more have passed since last verification
        if (monthsSinceLastVerification >= 6) {
          history.push(RoutePath.TRUE_SELFIE_VERIFICATION);
        } else {
          const monthsLeft = 6 - monthsSinceLastVerification;
          const nextEligibleDate = addMonths(lastVerifiedDate, 6);
          const formattedNextEligibleDate = format(
            nextEligibleDate,
            'MMMM dd, yyyy',
          );

          presentDialog({
            headerText: '',
            content: (
              <GenericConfirmationDialog
                headerText="Capture True Selfie"
                desc={`You can only verify again in ${monthsLeft} months, on or after ${formattedNextEligibleDate}.`}
              />
            ),
            enableBackdropDismiss: false,
          });
        }
      }

      if (lastTrueSelfieVerifiedDate) {
        const monthsSinceLastVerification = differenceInMonths(
          currentDate,
          lastTrueSelfieVerifiedDate,
        );

        // Check if 6 months or more have passed since last verification
        if (monthsSinceLastVerification >= 6) {
          history.push(RoutePath.TRUE_SELFIE_VERIFICATION);
        } else {
          const monthsLeft = 6 - monthsSinceLastVerification;
          const nextEligibleDate = addMonths(lastTrueSelfieVerifiedDate, 6);
          const formattedNextEligibleDate = format(
            nextEligibleDate,
            'MMMM dd, yyyy',
          );

          presentDialog({
            headerText: '',
            content: (
              <GenericConfirmationDialog
                headerText="Capture True Selfie"
                desc={`You can only verify again in ${monthsLeft} months, on or after ${formattedNextEligibleDate}.`}
              />
            ),
            enableBackdropDismiss: false,
          });
        }
      }
    }
  };

  return (
    <Page showBackButton showLoading={isFetching}>
      <div className="space-y-4">
        <div>
          <StyledImg src={verification} alt="verification"></StyledImg>
        </div>

        <div className="flex space-x-1 justify-center items-center">
          <TrueSelfieTag type="trueSelfie" />
          <Typography
            label="at kazam"
            variant="f3"
            weight="semibold"
            align="center"
            color={colorTheme.dark}
          />
        </div>

        <Typography
          label="Users  with True Selfie tag were able to complete liveness check through selfie image and is using the said image as their profile. Users can only change their profile images once every 6 months."
          variant="f1"
          color={colorTheme.darkFaded}
          align="center"
        />

        {!!data?.trueSelfieVerified && (
          <>
            <div className="flex flex-col items-center space-y-2">
              <Typography
                label="E-KYC verification does not guarantee trustworthiness or level of job experience, so remember to do your due diligence when dealing with other users on the platform.​"
                variant="f1"
                color={colorTheme.darkFaded}
                align="center"
              />
            </div>

            <div className="flex flex-col items-center space-y-2">
              <Typography
                label="Your profile is"
                variant="f1"
                color={colorTheme.darkFaded}
                align="center"
              />
              <TrueSelfieTag type="trueSelfie" />
              <Typography
                label="To maintain your status, remember to keep your full name, birthdate, and photo unchanged. Changing those details will reset your ID Verified status.​​"
                variant="f1"
                color={colorTheme.darkFaded}
                align="center"
              />
            </div>
          </>
        )}

        {data !== null && data !== undefined && (
          <>
            {data?.trueSelfieVerified === false && (
              <>
                <div className="flex flex-col items-center space-y-2">
                  <Typography
                    label="By using liveness check technology, kazam verifies that the selfie is taken in real-time by a real person, preventing the use of fake or outdated photos. This feature helps to build trust and confidence among users, ensuring that everyone on the platform is who they claim to be. True Selfie adds an extra layer of security and reliability to kazam.
​"
                    variant="f1"
                    color={colorTheme.darkFaded}
                    align="center"
                  />
                </div>

                {data?.trueSelfieVerified === false &&
                  status !== 'pending' && (
                    <Button
                      label={'START TRUE SELFIE VERIFICATION'}
                      color={'primary'}
                      onClick={onStartCaptureVerification}
                    />
                  )}

                {data?.verifiedStatus === 'pending' && (
                  <Button
                    label={'Pending Request'}
                    color={'darkFaded'}
                    disabled={true}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </Page>
  );
};

export default TrueSelfieVerificationPage;
