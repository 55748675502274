import React, {useEffect, useState} from 'react';
import {Typography} from '../../../core/components';
import {UserResponse} from '../../server/types';
import {colorTheme} from '../../../core/configs';
import {LivingArrangement} from '../../server/types/filter-settings.types';
import {toNumberWithCommas} from '../../utils/decimal.util';
import {calculateAge} from '../../utils/date.util';

interface ReceiverInfoComponentProps {
  receiver: UserResponse;
  isStartedVideo: boolean;
  isVideoCall: boolean;
}

const ReceiverInfoComponent = ({
  receiver,
  isStartedVideo,
  isVideoCall,
}: ReceiverInfoComponentProps) => {
  const [kasambahayInfo, setKasambahayInfo] = useState<string>('');
  const [lookingForLabel, setLookingForLabel] = useState<string>('');
  const [salaryLabel, setSalaryLabel] = useState<string>('');
  const [livingArrangementLabel, setLivingArrangementLabel] = useState<string>(
    '',
  );
  const [receiverIsKazam, setReceiverIsKazam] = useState<boolean>()

  const getLivingArrangementLabel = (la: LivingArrangement) => {
    switch (la) {
      case 'stayIn':
        return 'Stay-In';
      case 'stayOut':
        return 'Stay-Out';
      default:
        return 'Stay-In or Stay-Out';
    }
  };

  const getGenderLabel = (gender: string) => {
    switch (gender) {
      case 'female':
        return 'F';
      case 'male':
        return 'M';
    }
  };

  const getReceiverInfo = () => {
    if (!receiver) return;

    const lookingFor = receiver.filterSetting.kazamRoles
      .map(kr => kr.role.text)
      .join(', ');
    setLookingForLabel(lookingFor);

    const newLivingArrangementLabel = getLivingArrangementLabel(
      receiver.filterSetting.livingArrangement,
    );
    setLivingArrangementLabel(newLivingArrangementLabel);

    setSalaryLabel(
      `${toNumberWithCommas(receiver?.filterSetting?.salary || 0)} PHP/month`,
    );

    setKasambahayInfo(
      `${calculateAge(new Date(receiver.birthDate))} ${getGenderLabel(
        receiver.gender,
      )}, ${receiver.filterSetting.location?.cityOrMunicipality}`,
    );

    setReceiverIsKazam(receiver?.userRole === 'kazam')
  };

  useEffect(() => {
    getReceiverInfo();
  }, [receiver]);

  return (
    <div className={!isVideoCall ? 'flex flex-col items-center gap-y-4' : ''}>
      {isVideoCall && (
        <div>
          <Typography
            label={receiver?.firstName || ''}
            variant="f4"
            weight="semibold"
            // color={isStartedVideo ? colorTheme.white : colorTheme.black}
            color={colorTheme.white}
            singleLine
          />
          <Typography
            label={kasambahayInfo}
            variant="f2"
            weight="normal"
            // color={isStartedVideo ? colorTheme.white : colorTheme.black}
            color={colorTheme.white}
            className="mb-3"
            singleLine
          />
        </div>
      )}
      <Typography
        label={receiverIsKazam ? lookingForLabel : 'Looking for:'}
        variant="f1"
        weight={isVideoCall ? 'normal' : 'semibold'}
        // color={isStartedVideo ? colorTheme.white : colorTheme.black}
        color={colorTheme.white}
        singleLine
      />
      {receiverIsKazam ? (
        <div className="flex w-full justify-center">
          <Typography
            label={salaryLabel}
            variant="f1"
            weight={isVideoCall ? 'semibold' : 'normal'}
            // color={isStartedVideo ? colorTheme.white : colorTheme.black}
            color={colorTheme.white}
            className={isVideoCall ? 'mr-3' : ''}
            singleLine
          />
          <Typography
            label={
              isVideoCall
                ? livingArrangementLabel
                : ` • ${livingArrangementLabel}`
            }
            variant="f1"
            weight={isVideoCall ? 'semibold' : 'normal'}
            // color={isStartedVideo ? colorTheme.white : colorTheme.black}
            color={colorTheme.white}
            singleLine
          />
        </div>
      ) : (
        <Typography
          label={lookingForLabel}
          variant="f1"
          color={colorTheme.white}
          singleLine
        />
      )}
      
    </div>
  );
};

export default ReceiverInfoComponent;
