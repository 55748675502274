import styled from "styled-components";
import { colorTheme } from "../../configs";

export const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  padding: 0 4px 0 4px;
  border-radius: 4px;
  background: ${colorTheme.red}
`;

export const StyledCircleDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  padding: 2px;
  border-radius: 50%;
  background: ${colorTheme.accent}
`;
