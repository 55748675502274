import React from 'react';
import {StyledHideConfirmationDialog} from './styled';
import {Button, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {localization} from '../../../localization/Localization';

interface HideConfirmationDialogProps {
  onCancel: () => void;
  onHide: () => void;
}

const HideConfirmationDialog = (props: HideConfirmationDialogProps) => {
  return (
    <StyledHideConfirmationDialog className="flex flex-col items-center">
      <div className="header">
        <Typography
          label={localization.dlg_hdr_hide_kzm}
          variant="f3"
          weight="semibold"
          color={colorTheme.dark}
        />
      </div>

      <div className="message">
        <Typography
          label={localization.dlg_desc_hide_kzm}
          align="center"
          variant="f1"
          color={colorTheme.darkFaded}
        />
      </div>

      <div className="actions flex flex-row">
        <Button
          label={localization.btn_cancel}
          variant="outlined"
          className="flex-1"
          onClick={props.onCancel}
        />
        <div className="spacer" />
        <Button
          label={localization.btn_hide}
          color="primary"
          className="flex-1"
          onClick={props.onHide}
        />
      </div>
    </StyledHideConfirmationDialog>
  );
};

export default HideConfirmationDialog;
