import {isIOS, isMobile} from 'react-device-detect';
import styled, { css } from 'styled-components';

export const StyledPageWrapper = styled.div`
  & .section {
    margin-top: 32px;
  }

  & .section .card {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  & .section .card img {
    width: 284px;
    margin-top: 8px;
  }
`;

export const StyledCardRound = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 16px;
  justify-content: center;
  min-height: 185px;
`;

export const StyledMatchingLandingPage = styled.div`
  flex: 1;

  & .content-container {
    padding: 24px;
    ${isMobile
      ? isIOS && css`
        max-height: calc(100vh - 210px);
        overflow-y: auto;
      `
      : css`
        max-height: 600px;
        overflow-y: auto;
      `}
  }

  & .bottom-nav {
    position: ${isMobile ? 'fixed' : 'absolute'};
    bottom: 0;
    left: 0;
    width: 100%;
    height: 59px;
    border-top: 1px solid #e2e2e2;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 17.5px 24px;
    background: white;
  }

  & .profile-container {
    position: relative;
    min-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  & .p-hidden {
    width: 200px;
    height: 200px;
    z-index: 999;
    position: absolute;
  }

  & .p-hidden-left {
    float: left;
    top: 0;
    left: -80%;
  }

  & .p-hidden-right {
    float: right;
    top: 0;
    left: 120%;
  }

  & .bottom-nav-actions {
    display: flex;
    align-items: center;
  }

  & .bottom-nav-actions .bottom-nav-action-primary {
    margin: 0 30px;
  }

  & .bottom-nav-actions .bottom-nav-action-secondary {
    width: 27px;
    height: 27px;
  }
`;
