import {Button, Typography} from '../../../core/components';
import {colorTheme} from '../../../core/configs';
import {
  StyledBroadcastDialog,
  StyledFailedToSendBox,
  StyledFailedToSendIDBox,
} from './styled';

interface BroadcastDialogProps {
  onOkayClick?: () => void;
  onClick?: () => void;
  sentUuidsCount?: number;
  failedToSendIds?: string[];
}

const BroadcastDialog = (props: BroadcastDialogProps) => {
  const {
    sentUuidsCount = 0,
    failedToSendIds = []
  } = props;

  const onCopy = (e: any) => {
    navigator.clipboard.writeText(
      JSON.stringify(failedToSendIds)
        .replaceAll('",', '\n')
        .replaceAll('"', '')
        .replace('[', '')
        .replace(']', ''),
    );
  };
  
  return (
    <StyledBroadcastDialog>
      <Typography
        align="center"
        variant="f3"
        weight="semibold"
        color={colorTheme.dark}
        label="Broadcast Done!"
      />
      <div className="mb-2 mt-2">
        <Typography
          align="center"
          variant="f1"
          color={colorTheme.darkFaded}
          label={`Successfully sent to ${sentUuidsCount} UUID/s.`}
        />
      </div>

      {failedToSendIds && failedToSendIds.length > 0 && (
        <>
          <div className="mb-2">
            <Typography
              align="left"
              variant="f1"
              color={colorTheme.dark}
              label={`Failed to send to ${failedToSendIds.length} UUID/s:`}
            />
          </div>
          <StyledFailedToSendBox
            className='flex flex-wrap'
            onClick={e => onCopy(e)}>
            {failedToSendIds?.map((id, index) => {
              return (
                <StyledFailedToSendIDBox
                  className='flex items-center justify-center py-2 px-4'
                  key={index}>
                  <Typography
                    singleLine
                    variant="f1"
                    color={colorTheme.light}
                    label={id}
                  />
                </StyledFailedToSendIDBox>
              );
            })}
          </StyledFailedToSendBox>
          <Button
            className="btn-okay"
            label="Copy Failed UUID/s"
            color="primary"
            onClick={onCopy}
          />
        </>
      )}
    </StyledBroadcastDialog>
  );
};

export default BroadcastDialog;
