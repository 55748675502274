import axios, {AxiosRequestConfig} from 'axios';
import firebase from 'firebase/app';
import {STORAGE} from '../../utils/storage.utils';
import {AuthMe} from '../types';

import {apiConfig} from './api.config';

const axiosInstance = axios.create(apiConfig);

axiosInstance.interceptors.request.use(async config => {
  const headerAuthorization: any = {
    'Content-Type': 'application/json',
  };

  // const token = getToken();
  // if (token) {
  //   headerAuthorization.Authorization = 'Bearer ' + token;
  // }

  const authData = localStorage.getItem('authData');
  if (authData) {
    var token = await firebase.auth().currentUser?.getIdToken();
    if (token) {
      console.log('Token fetched!');
      headerAuthorization.Authorization = 'Bearer ' + token;

      return {
        ...config,
        headers: {
          ...headerAuthorization,
          ...config.headers,
        },
      };
    } else {
      return;
    }
  } else {
    return {
      ...config,
      headers: {
        ...config.headers,
      },
    };
  }
});

axiosInstance.interceptors.response.use(
  params => {
    return {
      ...params,
    };
  },
  error => {
    if (error
      && error.response
      && error.response.status) {

        switch (error.response.status) {
          case 401:
            refreshToken();
            break;
          case 403:
            if (error.response.data) {
              if (error.response.data.ErrorCode === 'A_0007') {
                STORAGE.clean();
                window.location.replace('/');
              }
            }
            break;
        
          default:
            break;
        }
      }

    return Promise.reject(error.response);
  },
);

const getToken = () : string | undefined => {
  const authData = localStorage.getItem('authData');
  if (authData) {
    var data = JSON.parse(authData) as AuthMe;

    if (data && data.accessToken) {
      return data.accessToken;
    }
  }
};

const refreshToken = async() => {
  const authData = localStorage.getItem('authData');
  if (authData) {
    var data = JSON.parse(authData) as AuthMe;
    var token = await firebase.auth().currentUser?.getIdToken();
    if (token) {
      console.log('Token refreshed!');
      data.accessToken = token;
      STORAGE.save("authData", data, true);
    }
  }
}

export async function get<T>(
  path: string,
  config?: AxiosRequestConfig | undefined,
): Promise<T> {
  const {data} = await axiosInstance.get<T>(path, config);
  return data;
}

export async function post<T>(
  path: string,
  reqData?: any,
  config?: AxiosRequestConfig | undefined,
): Promise<T> {
  const {data} = await axiosInstance.post<T>(path, reqData, config);
  return data;
}

export async function patch<T>(
  path: string,
  reqData?: any,
  config?: AxiosRequestConfig | undefined,
): Promise<T> {
  const {data} = await axiosInstance.patch<T>(path, reqData, config);
  return data;
}

export async function put<T>(
  path: string,
  reqData?: any,
  config?: AxiosRequestConfig | undefined,
): Promise<T> {
  const {data} = await axiosInstance.put<T>(path, reqData, config);
  return data;
}

export async function del<T>(
  path: string,
  config?: AxiosRequestConfig | undefined,
): Promise<T> {
  const {data} = await axiosInstance.delete<T>(path, config);
  return data;
}

/**
 * `convertToParam` not applicable to nested object
 */
export const convertToParam = (data: any): string => {
  let dataString = '';

  if (data) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];

        if (dataString.length > 0) {
          dataString += '&';
        }

        if (Array.isArray(value)) {
          let arrayValue = '';
          value.forEach((item, index) => {
            if (index > 0) {
              arrayValue += '&';
            }
            arrayValue += `${key}=${item}`;
          });

          dataString += arrayValue;
        } else {
          dataString += `${key}=${value}`;
        }
      }
    }
  }

  return dataString;
};

export const API = {
  get,
  post,
  patch,
  put,
  del,
  axiosInstance,
  convertToParam,
};
