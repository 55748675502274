import React, {useEffect, useState} from 'react';
import {Icon, Button} from '../../../core/components';
import {colorTheme} from '../../../core/configs';
import Page from '../../../core/components/page/Page';
import GoogleMap from '../../../core/components/google-map/GoogleMap';

import {getCurrentLocation} from '../../../core/utils/geo-location';
import {isAndroid, isIOS, isMobile} from 'react-device-detect';
import {useHistory, useParams} from 'react-router-dom';
import {Action} from 'history';

import {StyledLocationError, CurrentLocationHeader} from './styled';
import {Geolocation} from '@capacitor/geolocation';
import { useGetLocations, usePatchUserLocation, usePostCurrentLocation } from '../../server/react-query';
import { AddressComponent, GeocodingResponse, GeocodingResponseResult } from '../../server/types/conversation.types';
import { STORAGE } from '../../utils/storage.utils';
import { getGoogleMapApiKey, isUsingApp } from '../../utils/random.util';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { useDialog } from '../../../core/components/dialog';
import GenericConfirmationDialog from '../../views/dialog-content/generic-dialog/GenericConfirmationDialog';
import { App } from '@capacitor/app';

interface Location {
  coords: {
    latitude: number;
    longitude: number;
  };
}

interface CurrentLocationParams {
  id: string;
}

interface CurrentLocationProps {
  editProfile?: boolean;
  localSave?: boolean
}

const CurrentLocation = ({
  editProfile,
  localSave
}: CurrentLocationProps) => {
  const {id: channelId} = useParams<CurrentLocationParams>();
  const history = useHistory();
  const {presentDialog, dismissDialog} = useDialog()

  const [currentLocation, setCurrentLocation] = useState<Location | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [deniedLocation, setDeniedLocation] = useState<boolean>(false);
  const [formattedAddress, setFormattedAddress] = useState<string | null>(null);
  const [currentGeocoding, setCurrentGeocoding] = useState<GeocodingResponseResult>();

  const { data: locations} = useGetLocations()

  useEffect(() => {
    const fetchLocation = async () => {
      checkLocation()
    };

    const getLocationOnWeb = async () => {
      navigator.geolocation.getCurrentPosition(pos => {
        setCurrentLocation({
          coords:{
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude
          }
        })
      }, (error: any) => {
        console.log('error', error);
        if (error?.message === 'User denied Geolocation') {
          showLocationErrorDialog()
        } else {
          backButtonClickHandler()
        }
      })
    }

    if (isUsingApp()) {
      fetchLocation()
    } else {
      getLocationOnWeb()
    }

    let listener: any = null
    if (isUsingApp() && isIOS) {
      listener = App.addListener('resume', () => {
        checkLocation()
      });
    }

    return () => {
      if (listener) listener.remove()
    };
    
  }, []);

  useEffect(() => {
    console.log("currentLocation", currentLocation);
    if (currentLocation
      && currentLocation.coords
      && currentLocation.coords.latitude
      && currentLocation.coords.longitude) {
      // get latlong data
      setShowLoading(false)
      getReverseGeocoding()
    }
  }, [currentLocation])

  const checkLocation = () => {
    try {
      dismissDialog()
      Geolocation.checkPermissions().then(res => {
        if (res.location !== 'granted') {
          Geolocation.requestPermissions().then(res => {
            if (res.location === 'denied'
              || res.location === 'prompt-with-rationale') {
              handleDeniedLocation()
            } else {
              getLocation();
            }
          })
          .catch(err => {
            handleDeniedLocation()
          });
        } else {
          getLocation();
        }
      })
      .catch(err => {
        handleDeniedLocation()
      });
    } catch (err) {
      console.log("err", err);
      setError(
        'Unable to load map. Please check your geo location permission.',
      );
    }
  }

  const handleDeniedLocation = () => {
    setShowLoading(false)
    if (isUsingApp()) showLocationSettingsDialog()
  }

  const getReverseGeocoding = async () => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY_WEB_SERVICE || ""
      const geocode_base_url = process.env.REACT_APP_GEOCODE_BASE_URL || ""
      const url = `${geocode_base_url}?latlng=${currentLocation?.coords.latitude},${currentLocation?.coords.longitude}&key=${apiKey}`
      const response = await fetch(url);
      const data = await response.json() as GeocodingResponse
      setLocationData(data)
      
    } catch (error) {
      
    }
  }

  const setLocationData = (data: GeocodingResponse) => {
    if (data?.results
      && data.results.length > 0) {
        const formatted = data.results[0]?.formatted_address ?? "";
        setFormattedAddress(formatted)

        const address = data.results
          ?.find((a => a?.types
            ?.every(t => ['locality', 'political'].includes(t))))
        console.log(data.results);
        
        if (address) {
          console.log(address, address)
          setCurrentGeocoding(address)
        }
      }
  }

  const getLocation = async () => {
    setDeniedLocation(false)
    const location = await getCurrentLocation();
    setCurrentLocation(location);
    setShowLoading(false)
  };

  const showLocationSettingsDialog = () => {
    presentDialog({
      headerText: '',
      content: (
      <GenericConfirmationDialog
        headerText="Location Services Required"
        desc="Turn on location services to show current location on the map."
        rightButtonText="Turn On"
        leftButtonText="Go Back"
        onLeftButtonAction={() => {
          dismissDialog()
          backButtonClickHandler()
        }}
        onRightButtonAction={() => {
          if (isAndroid){
            dismissDialog()
            backButtonClickHandler()
          }
          goToSettings()
        }}
      />
      ),
      enableBackdropDismiss: false,
      hideClose: true
    }); 
  }

  const showLocationErrorDialog = () => {
    presentDialog({
      headerText: '',
      content: (
      <GenericConfirmationDialog
        headerText="Location Services Required"
        desc="Turn on location services first to show current location on the map."
        leftButtonText="Okay"
        onLeftButtonAction={() => {
          dismissDialog()
          backButtonClickHandler()
        }}
      />
      ),
      enableBackdropDismiss: false,
      hideClose: true
    }); 
  }

  const goToSettings = () => {
    if (isUsingApp()) {
      NativeSettings.open({
        optionAndroid: AndroidSettings.ApplicationDetails, 
        optionIOS: IOSSettings.App
      })
    }
  }

  const backButtonClickHandler = () => {
    if (history.action === Action.Push) {
      history.go(-1); //.back() will throw an error
    } else {
      // No more previous page; replace to home
      history.replace('/');
    }
  };

  const postCurrentLocation = usePostCurrentLocation(channelId)
  const sendCurrentLocation = async () => {
    try {
      if (currentLocation) {
        let meta = JSON.stringify({
          lat: currentLocation.coords.latitude,
          long: currentLocation.coords.longitude,
          text: formattedAddress
        })
        
        await postCurrentLocation.mutateAsync({
          meta
        })
        localStorage.setItem('sentCurrentLocation', 'true')
        backButtonClickHandler()
      }
    } catch (error) {
      
    }
  }

  const patchUserLocation = usePatchUserLocation()
  const saveProfileLocation = async () => {
    try {
      console.log(currentLocation);
      console.log(currentGeocoding);
      console.log(locations);
      
      if (currentLocation
        && currentLocation.coords) {

        let currentCity = formattedAddress
        const cityName = currentGeocoding?.address_components
          ?.find((a => a?.types
            ?.every(t => ['locality', 'political'].includes(t))
          ))
        if (cityName
          && cityName.long_name) {
          currentCity = cityName.long_name
        }

        const city = locations?.find(l => {
          const lat = parseFloat(l.lat)
          const long = parseFloat(l.lng)
          const cOrM = l.cityOrMunicipality?.toLocaleLowerCase().replace('city', '').trim()

          return inRange(lat, currentGeocoding?.geometry?.bounds?.southwest?.lat ?? 0, currentGeocoding?.geometry?.bounds?.northeast?.lat ?? 0)
            && inRange(long, currentGeocoding?.geometry?.bounds?.northeast?.lng ?? 0, currentGeocoding?.geometry?.bounds?.southwest?.lng ?? 0)
            && currentCity?.toLocaleLowerCase().includes(cOrM)
        })
        console.log("city", city);
        
        if (city) {
          if (localSave) {
            STORAGE.save('currentLocation', city, true)
          } else {
            await patchUserLocation.mutateAsync({
              locationId: city.locationId
            })
          }
        }
        backButtonClickHandler()
      }
    } catch (error) {
      
    } 
  }

  // return true if in range, otherwise false
  const inRange = (x: number, min: number, max: number) => {
    return ((x-min)*(x-max) <= 0);
  }

  return (
    <Page
      showBackButton
      unPadded
      hideNavbar={isMobile ? true : false}
      showLoading={showLoading}>
      <CurrentLocationHeader className="flex justify-between items-center">
        <div className="tab back-arrow">
          <Icon
            name="arrowLeft"
            size={24}
            color={colorTheme.dark}
            type="button"
            onClick={backButtonClickHandler}></Icon>
        </div>

        <div className="tab flex justify-center">
          <label className="heading">Current Location</label>
        </div>

        <div className="tab">
          <Button
            label={editProfile ? 'Save' : 'Send'}
            color="default"
            variant="outlined"
            isLoading={editProfile 
              ? patchUserLocation.isLoading 
              : postCurrentLocation.isLoading}
            onClick={editProfile 
              ? saveProfileLocation 
              : sendCurrentLocation}
          />
        </div>
      </CurrentLocationHeader>

      <div style={{backgroundColor:'transparent'}}>
        {!showLoading
        && currentLocation
        && currentLocation.coords && (
        <GoogleMap
          height={isMobile ? '95vh' : '80vh'}
          latitude={currentLocation.coords.latitude}
          longitude={currentLocation.coords.longitude}
          fullscreenControl={false}
          mapTypeId={'roadmap'}
        />
      )}
      </div>

      {error && (
        <StyledLocationError>
          <p className="error-text">Off - Allow location access.</p>
        </StyledLocationError>
      )}
    </Page>
  );
};

export default CurrentLocation;
