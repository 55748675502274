import React, {useCallback, useEffect, useState} from "react";
import {Typography} from "../../../core/components";
import {
  TypographyAlignment,
  TypographyVariant,
  TypographyWeight
} from "../../../core/components/typography/typography.types";
import {useGetOnlineStatus} from "../../server/react-query";
import {useQueryClient} from "react-query";
import {ONLINE_STATUS_PULL_INTERVAL} from "../../utils/online-status.util";

interface LastOnlineTimeProps {
  userId: string;
  variant?: TypographyVariant;
  weight?: TypographyWeight;
  align?: TypographyAlignment;
  className?: string;
  color?: string;
  singleLine?: boolean;
}

const LastOnlineTime = (props: LastOnlineTimeProps) => {
  const [lastOnline, setLastOnline] = useState<string>("");
  const [intervalRef, setIntervalRef] = useState<NodeJS.Timeout | undefined>(undefined);

  const {userId, variant, weight, align, className, color, singleLine} = props;

  const queryClient = useQueryClient();
  const {data} = useGetOnlineStatus(userId);

  useEffect(() => {
    return () => intervalRef && clearInterval(intervalRef);
  }, [intervalRef]);

  useEffect(() => {
    if (data) {
      setLastOnline(data.lastOnline);
    }
  }, [data]);

  const fetchData = useCallback(() => {
    const intervalRef = setInterval(async () => {
      await queryClient.fetchQuery(`get-online-status-${userId}`);
    }, ONLINE_STATUS_PULL_INTERVAL);
    setIntervalRef(intervalRef);
  }, [queryClient, userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Typography
      label={lastOnline}
      variant={variant}
      weight={weight}
      align={align}
      className={className}
      color={color}
      singleLine={singleLine} />
  );
};

export default LastOnlineTime;