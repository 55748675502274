import React from 'react';
import {
  StyledDialog,
  StyledDialogHeader,
  StyledDialogContent,
  StyledDialogFooter,
  StyledAction,
} from './styled';
import Icon from '../icon';
import {colorTheme} from '../../configs';
import Typography from '../typography';

export interface BaseDialogProps {
  /**
   * Text to display on header
   */
  headerText: string;
  headerDesc?: string;

  content: React.ReactNode;

  actions?: React.ReactNode[];

  enableBackdropDismiss?: boolean;

  fullWidth?: boolean;

  hideClose?: boolean;

  hideHeader?: boolean;

  // add a property to FULLY cover the screen
  useFullScreen?: boolean;
}

export interface DialogProps extends BaseDialogProps {
  /**
   * Function event for header close icon
   */
  headerAction?: () => void;
}

const Dialog: React.ForwardRefRenderFunction<unknown, DialogProps> = (
  props,
  ref,
) => {
  const {
    headerAction,
    content,
    actions,
    hideClose = false,
    hideHeader = false,
    headerText,
    headerDesc,
  } = props;

  return (
    <StyledDialog>
      {!hideHeader && (
        <StyledDialogHeader
          className={`flex
          ${!!headerText ? 'justify-between' : 'justify-end'}`}>
          <div className="flex flex-col">
            {!!headerText && (
              <Typography
                label={headerText}
                variant="f2"
                color={colorTheme.dark}
              />
            )}
            {!!headerDesc && (
              <Typography
                label={headerDesc}
                variant="f1"
                color={colorTheme.primary}
              />
            )}
          </div>

          {!hideClose && (
            <Icon
              name="x"
              color={colorTheme.dark}
              size={24}
              onClick={headerAction}
              type="button"
            />
          )}
        </StyledDialogHeader>
      )}

      <StyledDialogContent>{content}</StyledDialogContent>

      {actions && (
        <StyledDialogFooter>
          {actions.map((a, i) => (
            <StyledAction key={i}>{a}</StyledAction>
          ))}
        </StyledDialogFooter>
      )}
    </StyledDialog>
  );
};

type DialogFooterProps = Pick<BaseDialogProps, 'actions'>;

export const DialogFooter = ({actions}: DialogFooterProps) => {
  return (
    <>
      {actions && (
        <StyledDialogFooter>
          {actions.map((a, i) => (
            <StyledAction key={i}>{a}</StyledAction>
          ))}
        </StyledDialogFooter>
      )}
    </>
  );
};

export default Dialog;
