import {isMobile} from 'react-device-detect';
import styled, { css } from 'styled-components';
import { colorTheme } from '../../../../core/configs';
import footerBG from '../../../../assets/footer-bg.png';
import footerMobileBG from '../../../../assets/footer-mobile-bg.png';

export const StyledFooterWrapper = styled.div`
  ${isMobile
    ? css`
        padding: 36px 24px 36px 24px;
        flex-direction: column;
        gap: 24px;
        background:url(${footerMobileBG});
      `
    : css`
        height: auto;
        padding: 0px 120px 0px 120px;
        justify-content: space-between;
        background:url(${footerBG});
      `}

    display: flex;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;

export const StyledMenuWrapper = styled.div`
  ${isMobile
    ? css`
        
      `
    : css`
        padding: 16px 0px 16px 0px;
      `}

  gap: 8px;
  display: flex;
  flex-direction: column;
`;

export const StyledMenuItem = styled.div`
  cursor: pointer;
  font-size: 0.875rem;
  letter-spacing: 0rem;
  font-weight: 700;
  text-align: left;
  color: ${colorTheme.light};
`;

export const StyledContactWrapper = styled.div`
  ${isMobile
    ? css`
        
      `
    : css`
        padding: 16px;
      `}

  gap: 16px;
  display: flex;
  flex-direction: column;
`;

export const StyledContactItem = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
`;
