import React, {useEffect, useState} from 'react';
import Typography from '../../../core/components/typography';
import {colorTheme} from '../../../core/configs';
import {
  RadioButton,
  RadioButtonFilled,
} from '../../../core/assets/icons/components';
import {StyledEmploymentComponent} from './styled';
import {Nullable} from '../../types/types';
import {SearchAreaType} from '../../server/types/filter-settings.types';
import {Input, ProvinceTypehead} from '../../../core/components';
import {LocationResponse, ProvinceResponse} from '../../server/types';
import {localization} from '../../localization/Localization';

interface SearchAreaComponentProps {
  value?: SearchAreaType;
  currentLocationName?: string;
  currentNearbyKm?: number;
  selectedProvince?: ProvinceResponse;
  provincesData?: ProvinceResponse[];
  provinceError?: string;
  nearbyError?: string;
  onChange?: (value: SearchAreaType) => void;
  onSelectProvince?: (value: string) => void;
  onNearbyChange?: (value: number) => void;
}

const SearchAreaComponent = (props: SearchAreaComponentProps) => {
  const [selectedSearchArea, setSelectedSearchArea] = useState<
    Nullable<SearchAreaType>
  >(props.value);

  const [nearbyRadius, setNearbyRadius] = useState<number>();

  useEffect(() => {
    setSelectedSearchArea(props.value);
    setNearbyRadius(props.currentNearbyKm);
  }, [props]);

  const selectHandler = (value: SearchAreaType) => {
    setSelectedSearchArea(value);

    if (props.onChange) {
      props.onChange(value);
    }
  };

  const selectProvinceHandler = (value: string) => {
    if (props.onSelectProvince) {
      props.onSelectProvince(value);
    }
  };

  const onChangeNearby = (value: number) => {
    setNearbyRadius(value);
    if (props.onNearbyChange) {
      props.onNearbyChange(value);
    }
  };

  return (
    <StyledEmploymentComponent>
      <Typography
        className="pb-2"
        label={localization.lbl_search_area}
        color={colorTheme.darkFaded}
        variant="f1"
        weight="semibold"
      />

      <div className="space-y-4">
        <div
          className="cursor-pointer"
          style={{width: 'fit-content'}}
          onClick={() => selectHandler('inYourCity')}>
          <div className="flex flex-row items-center">
            {selectedSearchArea === 'inYourCity' ? (
              <RadioButtonFilled
                className="radio-button"
                color={colorTheme.dark}
              />
            ) : (
              <RadioButton className="radio-button" color={colorTheme.dark} />
            )}
            <Typography
              label={`${localization.rad_your_city} (${props.currentLocationName})`}
              variant="f2"
              color={colorTheme.dark}
            />
          </div>
        </div>

        <div
          className="cursor-pointer"
          style={{width: 'fit-content'}}
          onClick={() => selectHandler('nearby')}>
          <div className="flex flex-row items-center">
            {selectedSearchArea === 'nearby' ? (
              <RadioButtonFilled
                className="radio-button"
                color={colorTheme.dark}
              />
            ) : (
              <RadioButton className="radio-button" color={colorTheme.dark} />
            )}
            <Typography
              label={localization.rad_nearby}
              variant="f2"
              color={colorTheme.dark}
            />
          </div>
        </div>

        {selectedSearchArea === 'nearby' && (
          <div className="w-full">
            <Input
              label={localization.lbl_km_range}
              placeholder={localization.ph_enter_km}
              onChange={value =>
                onChangeNearby(value.currentTarget.valueAsNumber)
              }
              type="number"
              inputSize="large"
              value={
                typeof nearbyRadius === 'number'
                  ? nearbyRadius
                  : isNaN(parseFloat((nearbyRadius as unknown) as string))
                  ? ''
                  : parseFloat((nearbyRadius as unknown) as string)
              }
              error={props.nearbyError}
            />
          </div>
        )}

        <div
          className="cursor-pointer"
          style={{width: 'fit-content'}}
          onClick={() => selectHandler('allMatches')}>
          <div className="flex flex-row items-center">
            {selectedSearchArea === 'allMatches' ? (
              <RadioButtonFilled
                className="radio-button"
                color={colorTheme.dark}
              />
            ) : (
              <RadioButton className="radio-button" color={colorTheme.dark} />
            )}
            <Typography
              label={localization.rad_all_matches}
              variant="f2"
              color={colorTheme.dark}
            />
          </div>
        </div>

        <div
          className="cursor-pointer"
          style={{width: 'fit-content'}}
          onClick={() => selectHandler('province')}>
          <div className="flex flex-row items-center">
            {selectedSearchArea === 'province' ? (
              <RadioButtonFilled
                className="radio-button"
                color={colorTheme.dark}
              />
            ) : (
              <RadioButton className="radio-button" color={colorTheme.dark} />
            )}
            <Typography
              label={localization.rad_specific_province}
              variant="f2"
              color={colorTheme.dark}
            />
          </div>
        </div>

        {selectedSearchArea === 'province' && props.provincesData && (
          <div className="w-full">
            <ProvinceTypehead
              items={props.provincesData}
              placeholder={localization.ph_search_province}
              onChange={sel => {
                var item = sel && sel.length > 0 ? sel[0].provinceId : '';
                selectProvinceHandler(item);
              }}
              selected={props.selectedProvince}
              error={props.provinceError}
            />
          </div>
        )}
      </div>
    </StyledEmploymentComponent>
  );
};

export default SearchAreaComponent;
