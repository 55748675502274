import {Button, Icon, Input} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {StyledAdminAdsSearch} from './styled';

interface AdminUsersSearchProps {
  value?: string;
  onChange?: (query: string) => void;
  onClickCreate?: () => void;
  onClickSearch?: () => void;
  onExtractAllClickedHandler?: () => void;
}

const AdminAdsSearch = (props: AdminUsersSearchProps) => {
  return (
    <StyledAdminAdsSearch className="w-full flex flex-row items-center">
      <div className="search-input flex-1 flex flex-col">
        <Input
          iconLeft={
            <Icon name="search" size={12} color={colorTheme.darkFaded} />
          }
          iconRight={
            props.value ? (
              <Icon
                type="button"
                onClick={() => props.onChange && props.onChange('')}
                color={colorTheme.dark}
                name="x"
                size={12}
              />
            ) : undefined
          }
          value={props.value}
          inputSize="large"
          placeholder="Search voucher"
          onChange={val =>
            props.onChange && props.onChange(val.currentTarget.value)
          }
        />
      </div>
      <div className="search-button flex space-x-4">
        <div style={{width: 'fit-content'}}>
          <Button
            label="Search"
            color="primary"
            onClick={props.onClickSearch}
          />
        </div>

        <div style={{width: 'fit-content'}}>
          <Button
            label="Create Voucher"
            variant="outlined"
            onClick={props.onClickCreate}
          />
        </div>
      </div>
    </StyledAdminAdsSearch>
  );
};

export default AdminAdsSearch;
