import {isMobile} from 'react-device-detect';
import styled, {css} from 'styled-components';
import {colorTheme} from '../../../../../core/configs';

interface ProfileDivWrapper {
  viewedByAdmin?: boolean;
}

export const StyledDiv = styled.div<ProfileDivWrapper>`
  padding: 8px 16px;
  background: ${colorTheme.darkFaded};
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  left: 300px;

  & .profile-picture {
    width: 62px;
    position: relative;
  }

  & .default-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: relative;
    background: ${colorTheme.primary};
    border-width: 1px;
    border-color: white;
  }

  & .disabled-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: relative;
    background: ${colorTheme.lightGray};
  }

  & .avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: relative;
    border-width: 1px;
    border-color: white;
    background: ${colorTheme.white};
  }

  & .avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover !important;
  }

  & .online-status {
    width: 12px;
    height: 12px;
    position: absolute;
    float: bottom;
    bottom: 3px;
    right: 3px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
  }

  & .bullet {
    padding: 0 4px;
  }

  & .flex1 {
    flex: 1 1 0%;
    min-width: 0;
  }

  & .overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
