import styled from 'styled-components';
import {StatusVariant, VariantColor} from '.';
import {colorTheme} from './../../configs/color.config';

export const variantColors: {[key in StatusVariant]: VariantColor} = {
  online: {
    color: colorTheme.green,
  },
  away: {
    color: colorTheme.warning,
  },
  offline: {
    color: colorTheme.darkFaded,
  },
};

interface StyledStatusIconProps {
  status: StatusVariant;
  disabledUser?: boolean;
  bannedUser?: boolean;
  width?: number;
  height?: number;
}

const StyleStatusIcon = styled.div<StyledStatusIconProps>`
  width: ${p => p.width || 8}px;
  height: ${p => p.height || 8}px;
  border-radius: 8px;
  background: ${pr =>
    pr.disabledUser ? colorTheme.dark : 
    pr.bannedUser ? colorTheme.red : variantColors[pr.status].color};
`;

export default StyleStatusIcon;
