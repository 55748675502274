import {Button, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {localization} from '../../../localization/Localization';
import {StyledDisclaimerContent} from './styles';

interface DisclaimerDialogProps {
  onCancel: () => void;
  onContinue: () => void;
}

const DisclaimerDialog = (props: DisclaimerDialogProps) => {
  const handleContinue = () => {
    props.onContinue();
  };

  return (
    <div className="flex flex-col items-center px-4 pb-4 space-y-2">
      <div className="header">
        <Typography
          label="Disclaimer"
          variant="f3"
          weight="semibold"
          color={colorTheme.dark}
        />
      </div>

      <StyledDisclaimerContent>
        <Typography
          label={localization.desc_full_disclaimer}
          align="left"
          variant="f1"
          color={colorTheme.darkFaded}
        />
      </StyledDisclaimerContent>
      <div className="w-full flex flex-row space-x-2">
        <Button
          label={localization.btn_disagree}
          variant="outlined"
          className="flex-1"
          onClick={props.onCancel}
        />
        <div className="spacer" />
        <Button
          label={localization.btn_agree}
          color="primary"
          className="flex-1"
          onClick={handleContinue}
        />
      </div>
    </div>
  );
};

export default DisclaimerDialog;
