import {StyledJobHistoryDialog} from './styled';
import {Button, Typography, Select} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {DropdownComponent, DropdownDate} from '@iskaiiii/react-dropdown-date';
import {KazamException} from '../../../server/types';
import {useAuth} from '../../../hooks/useAuth';
import {endOfDay} from 'date-fns';
import {usePutJobHistory} from '../../../server/react-query/useHistory';
import {PutJobHistoryBody, TerminationReason} from '../../../server/types/job-history.type';
import {
  getTerminationReason,
  getTerminationReasonValue,
} from '../../../utils/job-history.util';
import Rating from '../../../components/rating/Rating';
import {getLivingArrangementValue} from '../../../utils/job-offer.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { putJobHistorySchema } from '../../../schema/job-offer.schema';
import { Controller, useForm } from 'react-hook-form';
import { JobHistoryResponse, JobOfferMeta } from '../../../server/types/job-offer.types';
interface JobHistoryDialogProps {
  data?: JobHistoryResponse
  meta?: JobOfferMeta
  jobHistoryId: string;
  contentId?: string
  roles: string;
  disable?: boolean
  confirmRating?: boolean
  onError?: (message: string) => void;
  onUpdated: () => void;
}

const JobHistoryDialog = ({
  data,
  meta,
  jobHistoryId,
  contentId,
  disable = false,
  roles,
  confirmRating,
  onError,
  onUpdated
}: JobHistoryDialogProps) => {
  const {authData} = useAuth();
  const isHomeowner = authData?.user?.userRole === 'homeowner'

  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<PutJobHistoryBody>({
    resolver: yupResolver(putJobHistorySchema),
    defaultValues: {
      rating: isHomeowner
        ? (data?.employerRating ?? 0) 
        : (data?.employeeRating ?? 0),
      terminationReason: data?.terminationReason ?? "voluntaryResignation",
      endDate: data?.endDate 
        ? new Date(data.endDate)
        : endOfDay(new Date())
    },
  });

  const formatDate = (date: any) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  };

  const formatStartDate = (date: any) => {
    var d = new Date(date);
    return d.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const submitForm = async () => {
    handleSubmit(handleUpdate)();
  };

  const putJobHistory = usePutJobHistory(jobHistoryId);
  const handleUpdate = async () => {
    try {
      var body = getValues()

      if(contentId) {
        body.contentId = contentId
      }
      await putJobHistory.mutateAsync(body)
      onUpdated()
    } catch (e: any) {
      console.log('error', e);
      const exception = e.data as KazamException;
      onError && onError(exception ? exception.error : 'Failed to update.');
    }
  };
  
  return (
    <StyledJobHistoryDialog className="flex flex-col space-y-4">
      <div className="header self-center">
        <Typography
          label="Job Details"
          variant="f3"
          weight="semibold"
          color={colorTheme.dark}
        />
      </div>

      {/* job details */}
      <div className="flex flex-col items-center space-y-4 border-b-2 border-borderLightGray pb-4">
        <div className="flex justify-between w-full gap-x-2">
          <div>
            <Typography label="Role" variant="f2" color={colorTheme.dark} />
          </div>

          <div>
            <Typography
              label={roles ?? ''}
              variant="f1"
              align='right'
              className="italic"
              color={colorTheme.dark}
            />
          </div>
        </div>

        <div className="flex justify-between w-full">
          <div>
            <Typography
              label={
                authData?.user.userRole === 'homeowner'
                  ? 'Hiring Address'
                  : 'Job Address'
              }
              variant="f2"
              color={colorTheme.dark}
            />
          </div>

          <div>
            <Typography
              label={meta?.Location?.CityOrMunicipality ?? ''}
              variant="f1"
              className="italic"
              color={colorTheme.dark}
            />
          </div>
        </div>

        <div className="flex justify-between w-full">
          <div>
            <Typography
              label="Salary per month"
              variant="f2"
              color={colorTheme.dark}
            />
          </div>

          <div>
            <Typography
              label={`PHP ${meta?.Salary ?? ''}`}
              variant="f1"
              className="italic"
              color={colorTheme.dark}
            />
          </div>
        </div>

        <div className="flex justify-between w-full">
          <div>
            <Typography
              label="Living Arrangement"
              variant="f2"
              color={colorTheme.dark}
            />
          </div>

          <div>
            <Typography
              label={getLivingArrangementValue(meta?.LivingArrangement)}
              variant="f1"
              className="italic"
              color={colorTheme.dark}
            />
          </div>
        </div>

        <div className="flex justify-between w-full">
          <div>
            <Typography
              label="Start Date"
              variant="f2"
              color={colorTheme.dark}
            />
          </div>

          <div>
            <Typography
              label={formatStartDate(data?.startDate)}
              variant="f1"
              className="italic"
              color={colorTheme.dark}
            />
          </div>
        </div>
      </div>

      {/* job termination reason */}
      <div className="flex flex-col w-full space-y-2">
        <Controller
          control={control}
          name="terminationReason"
          render={({field: {value = '', onBlur, onChange}}) => (
            <Select
              label='Reason for job termination:'
              items={['Voluntary Resignation', 'Termination']}
              itemRenderer={(item, selected) => (
                <Typography
                  label={item ?? ''}
                  variant="f1"
                  color={colorTheme.dark}
                  weight={selected ? 'semibold' : 'normal'}
                  className="flex-1"
                />
              )}
              onChange={v => onChange(getTerminationReason(v ?? ''))}
              value={getTerminationReasonValue(value as TerminationReason)}
              valueRenderer={value => value ?? ''}
              disabled={disable}
            />
          )}
        />
      </div>

      {/* end date */}
      <div className="w-full space-y-2">
        <Controller
          control={control}
          name="endDate"
          render={({field: {value = '', onBlur, onChange}}) => (
            <>
              <div className="mb-2">
                <Typography
                  label="End Date"
                  variant="f1"
                  weight="semibold"
                  color={colorTheme.darkFaded}
                />
              </div>
              <div>
                <div style={disable ? {pointerEvents: "none", opacity: "0.4"} : {}}>
                  <DropdownDate
                    startDate={'1920-01-01'}
                    endDate={data?.status === 'active' 
                      ? `${new Date().getFullYear() + 10}-12-31`
                      : formatDate(endOfDay(new Date()))
                    }
                    selectedDate={formatDate(value)}
                    onDateChange={(v: any) => onChange(endOfDay(v))}
                    options={{
                      yearReverse: true,
                    }}
                    order={[
                      DropdownComponent.month,
                      DropdownComponent.day,
                      DropdownComponent.year,
                    ]}
                  />
                </div>
              </div>

              {errors.endDate?.message && (
                <div
                  data-testid="error-msg-element"
                  style={{
                    marginTop: '0.25rem',
                    fontSize: '0.75rem',
                    lineHeight: '1.125rem',
                    color: colorTheme.danger,
                  }}>
                  {errors.endDate?.message}
                </div>
              )}
            </>
          )}
        />
      </div>

      <div className="flex flex-col items-center justify-center space-y-2">
        <Controller
          control={control}
          name="rating"
          render={({field: {value, onChange}}) => (
            <>
              <Typography
                label={
                  authData?.user.userRole === 'homeowner'
                    ? 'please edit kazam rating.'
                    : 'please edit homeowner rating.'
                }
                variant="f2"
                color={colorTheme.darkFaded}
              />
              <Rating
                rating={value}
                size={24}
                onRate={onChange}
              />
              {errors.rating?.message && (
                <div
                  data-testid="error-msg-element"
                  style={{
                    marginTop: '0.25rem',
                    fontSize: '0.75rem',
                    lineHeight: '1.125rem',
                    color: colorTheme.danger,
                  }}>
                  {errors.rating?.message}
                </div>
              )}
            </>
          )}
        />
      </div>

      <div className="w-full">
        <Button
          label={confirmRating 
            ? 'Confirm Rating' 
            : data?.status === 'active' 
              ? 'End Job' 
              : 'Edit Job'
          }
          color="danger"
          className="flex-1"
          onClick={submitForm}
          isLoading={putJobHistory.isLoading}
        />
      </div>
    </StyledJobHistoryDialog>
  );
};

export default JobHistoryDialog;
