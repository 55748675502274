import {
  Button,
  Typography,
  WizardStep,
} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import logo from '../../../../assets/KAZAM_logo_Transparent.svg';
import {useHistory, useLocation} from 'react-router-dom';
import {RoutePath} from '../../../navigation/config/RouteConfig';
import Page from '../../../../core/components/page/Page';
import imgIAmHomeowner from '../../../../assets/img_I_Am_Homeowner.svg';
import { STORAGE } from '../../../utils/storage.utils';
import { useEffect } from 'react';

const EmployerSignUpConfirmationPage = (props: any) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const currentStep = STORAGE.get('signUpCurrentStep') as string
    if (!!currentStep) props.goToStep(parseInt(currentStep ?? 1))
  }, [])

  const handleNext = () => {
    props.nextStep();
  };

  const goToKazamSignUp = () => {
    // localStorage.removeItem('signup');
    // history.replace(RoutePath.SIGN_UP.replace(':step', '2'));
    // const params = '?step=2';
    // history.push(RoutePath.SIGN_UP + params);
    history.replace(RoutePath.LANDING);
  };

  return (
    <Page hideNavbar>
      <div className="space-y-6">
        <div>
          <WizardStep
            steps={props.totalSteps}
            currentStep={props.currentStep}></WizardStep>
        </div>
        <div className="flex justify-center">
          <img style={{height: '29px'}} src={logo} alt="Logo" />
        </div>

        <div className="section w-full flex flex-row justify-center">
          <img
            className="sign-in-image"
            src={imgIAmHomeowner}
            alt="Sign in images"
          />
        </div>
        <div>
          <div className="flex flex-1">
            <Typography
              label="You are about to create a homeowner account"
              variant="f3"
              align="center"
              color={colorTheme.dark}
            />
          </div>
          <div className="pt-1">
            <Typography
              label="You are a homeowner if you are looking for a kasambahay"
              variant="f1"
              align="center"
              color={colorTheme.darkFaded}></Typography>
          </div>
        </div>
        <div>
          <div>
            <Button
              label="Yes, create my homeowner account"
              color="primary"
              onClick={handleNext}
            />
          </div>
          <div className="pt-4">
            <Button
              label="No, I am a kasambahay"
              variant="outlined"
              onClick={goToKazamSignUp}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};
export default EmployerSignUpConfirmationPage;
