import {Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import { SubscriptionHistory, SubscriptionHistoryRedeemedVoucher } from '../../../server/types';

interface SubscriptionHistoryItemProps {
  label: string;
  dateSubscribed: string;
  amount?: number
  data?: SubscriptionHistory
}

const SubscriptionHistoryItem = (props: SubscriptionHistoryItemProps) => {
  var vouchers = props?.data?.voucherMeta
    ? JSON.parse(props.data.voucherMeta) as SubscriptionHistoryRedeemedVoucher[]
    : [];
  
  const getAmount = (): string => {
    let amount = "Free"
    if (!!props.amount
      && props.amount > 0)
      amount = `Php ${props.amount}`

    return amount
  }
  return (
    <div> 
      <div className="flex">
        <div className="flex justify-between items-center space-x-2 w-full flex1">
          <div>
            <Typography
              label={props.label}
              variant="f2"
              color={colorTheme.dark}
            />

            <div>
              {vouchers?.map((v,i) => {
                return (
                  <Typography
                    key={i}
                    label={`${v.Code} (-${v.DiscountValue}PHP)`}
                    variant="f1"
                    color={colorTheme.green}
                  />
                )
              })}
            </div>

            <Typography
              label={props.dateSubscribed}
              variant="f1"
              color={colorTheme.darkFaded}
            />
          </div>
          <div>
            <Typography
              label={getAmount()}
              variant="f1"
              color={colorTheme.darkFaded}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionHistoryItem;
