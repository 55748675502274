import {StyledOfferCard} from './styles';
import {Button} from '../../../../core/components';
import { useEffect, useState } from 'react';
import { JobOfferContentMeta, JobOfferMeta, ConversationContentMeta, JobOfferStatus } from '../../../server/types/job-offer.types';
import format from 'date-fns/format';
import { useGetJobOffer, usePutJobOfferStatus } from '../../../server/react-query/useJobOffer';
import { BeatLoader } from 'react-spinners';
import { colorTheme } from '../../../../core/configs';
import { toNumberWithCommas } from '../../../utils/decimal.util';
import { getLivingArrangement, getLivingArrangementValue } from '../../../utils/job-offer.util';
import { ChatContentResponse, UserResponse } from '../../../server/types';
import MessageAvatar from '../recipient-chat-message/MessageAvatar';
import { getHiredLockDuration } from '../../../utils/admin.util';
import { QuantityContent } from '../../../server/types/admin-control.types';
import JobOfferDetails from './JobOfferDetails';

interface ChatJobOfferDetailsProps {
  currentUser?: UserResponse
  fromUser: UserResponse
  offer: ConversationContentMeta
  date: Date
  contentId: string
  onUpdate?: (jobOfferId: string) => void
  onUpdateStatus?: (jobOfferId: string) => void
  onDataLoaded?: () => void
}

const ChatJobOfferDetails = ({
  currentUser,
  fromUser,
  offer,
  date,
  contentId,
  onUpdate,
  onUpdateStatus,
  onDataLoaded
}: ChatJobOfferDetailsProps) => {
  const [offerMeta, setOfferMeta] = useState<JobOfferMeta>()
  const [roles, setRoles] = useState<string>();
  const currentUserIsSender = currentUser?.userId === fromUser?.userId
  const [showAcceptLoading, setShowAcceptLoading] = useState<boolean>(false);
  const [showDeclineLoading, setShowDeclineLoading] = useState<boolean>(false);
  const [hiredLockNoOfDays, setHiredLockNoOfDays] = useState<number>(0);
  // const { data, isFetching } = useGetJobOffer(meta.JobOfferId)

  useEffect(() => {
    if (offer
      && offer.Meta) {
      var meta = JSON.parse(offer.Meta) as JobOfferMeta
      setOfferMeta(meta)

      var roles = meta.Roles?.map(r => r.Role?.Text)
      setRoles(roles.join(", "))
      // onDataLoaded()

      const hiredLockDuration = getHiredLockDuration();
      setHiredLockNoOfDays(hiredLockDuration)
    }
  }, [offer])

  const putJobOfferStatus = usePutJobOfferStatus(offer.JobOfferId)
  const cancelJoboffer = async () => {
    try {
      await putJobOfferStatus.mutateAsync({
        contentId: contentId,
        status: 'cancelled'
      })
      onUpdateStatus && onUpdateStatus(offer.JobOfferId)
    } catch (error) {
      
    }
  }

  const acceptOrDecline = async (accept: boolean) => {
    try {
      setShowAcceptLoading(accept)
      setShowDeclineLoading(!accept)
      await putJobOfferStatus.mutateAsync({
        contentId: contentId,
        status: accept ? 'accepted' : 'declined'
      })
      onUpdateStatus && onUpdateStatus(offer.JobOfferId)

      setShowAcceptLoading(false)
      setShowDeclineLoading(false)
    } catch (error) {
      setShowAcceptLoading(false)
      setShowDeclineLoading(false)
    }
  }

  const getOfferStatusText = () => {
    if (offer.Status !== 'ended')
      if (offer.Status === 'revised')
        return '- REVISED. SEE NEW OFFER BELOW -'
      else
        return `- OFFER ${offer?.Status} -`
  }
  return (
    <StyledOfferCard isSender={currentUserIsSender}>
      {!currentUserIsSender &&
        <MessageAvatar profile={fromUser}/>
      }

      <div className="offer-details ">
        <div className="text-center mb-2">
          <label className="txt-heading">OFFER DETAILS</label>
        </div>

        <>
          {!currentUserIsSender && 
            <div className="flex gap-1">
              <span>
                {`For your mutual protection, accepting ${fromUser?.firstName}'s offer will notify other homeowners 
                that you are chatting with that you are in the process of being hired.  
                Your existing chats will be locked for ${hiredLockNoOfDays} days and other homeowners will not 
                yet be able to create new chats with you. Your profile will be marked as Hired. 
                If you do not start as agreed with, they may report you as a No Show, which will appear 
                on your kazam profile.`}
              </span>
            </div>
          }

          <JobOfferDetails offer={offer}/>

          {offer?.Status === 'pending' ? (
              <div className="mt-2 mb-2">
              <Button
                label={currentUserIsSender ? 'Update' : 'Accept'}
                className="offer-primary-btn mb-1"
                isLoading={currentUserIsSender 
                  ? false
                  : showAcceptLoading}
                onClick={() => currentUserIsSender 
                  ? onUpdate && onUpdate(offer.JobOfferId) 
                  : acceptOrDecline(true)
                } 
              />

              <Button
                label={currentUserIsSender ? 'Cancel' : 'Decline'}
                className="offer-secondary-btn"
                isLoading={currentUserIsSender 
                  ? putJobOfferStatus.isLoading 
                  : showDeclineLoading}
                onClick={() => currentUserIsSender 
                  ? cancelJoboffer()
                  : acceptOrDecline(false)
                }  
              />
            </div>
          ) : (
            <div className='mt-2 mb-2 text-center '>
              <label className='offer-status uppercase'>{getOfferStatusText()}</label>
            </div>
          )}

          <div className="text-right offer-msg-time">
            <span>{`${format(date, 'PP')} ${format(date, 'p')}`}</span>
          </div>
        </>
      </div>
    </StyledOfferCard>
  );
};

export default ChatJobOfferDetails;
