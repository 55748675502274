import * as React from 'react';

function SvgBxDownArrow(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}>
      <path
        d="M11.1781 19.569C11.2698 19.7022 11.3926 19.8112 11.5357 19.8864C11.6789 19.9617 11.8383 20.001 12.0001 20.001C12.1618 20.001 12.3212 19.9617 12.4644 19.8864C12.6076 19.8112 12.7303 19.7022 12.8221 19.569L21.8221 6.569C21.9262 6.41906 21.9873 6.24343 21.9987 6.06121C22.0101 5.87898 21.9713 5.69712 21.8865 5.53539C21.8018 5.37366 21.6744 5.23825 21.5181 5.14386C21.3618 5.04947 21.1826 4.99971 21.0001 5H3.00006C2.8179 5.00075 2.6394 5.05115 2.48374 5.14576C2.32808 5.24038 2.20116 5.37564 2.11663 5.53699C2.03209 5.69835 1.99314 5.8797 2.00397 6.06153C2.01479 6.24337 2.07498 6.41881 2.17806 6.569L11.1781 19.569Z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBxDownArrow);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
