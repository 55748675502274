import {useHistory} from 'react-router-dom';
import {StyledOverlay, StyledSideDrawerMenu} from './styled';
import {Icon, Typography} from '..';
import {IconName} from '../icon';
import {colorTheme} from '../../configs';
import {RoutePath} from '../../../app/navigation/config/RouteConfig';
import {AnalyticsEvent, useAnalytics} from '../../../app/hooks/useAnalytics';

interface SideDrawerMenuProps {
  path: RoutePath;
  iconName: IconName;
  alt: string;
  text: string;
  disabled?: boolean;
  active?: boolean;
  analyticsEvent?: AnalyticsEvent;
  url?: string;
  defaultColor?: string
}

const SideDrawerMenu = (props: SideDrawerMenuProps) => {
  const history = useHistory();
  const {systemLogEvent} = useAnalytics();

  return (
    <StyledSideDrawerMenu
      onClick={() => {
        if (props.analyticsEvent) {
          systemLogEvent(props.analyticsEvent);
        }
        if (!props.disabled) {
          if (props.url) {
            window.open(props.url);
          } else {
            localStorage.setItem('referralListPageNumber', '1');
            history.push(props.path);
          }
        }
      }}>
      <div className="flex space-x-4">
        <Icon
          name={props.iconName}
          size={20}
          color={
            props?.defaultColor ? props.defaultColor :
            (props.active && !props.disabled)
              ? colorTheme.primary
              : colorTheme.dark
          }></Icon>
        <Typography
          label={props.text}
          variant="f1"
          weight={props.active && !props.disabled ? 'semibold' : 'normal'}
          color={
            props.active && !props.disabled
              ? colorTheme.primary
              : colorTheme.dark
          }></Typography>
      </div>
      {props.disabled && <StyledOverlay></StyledOverlay>}
    </StyledSideDrawerMenu>
  );
};

export default SideDrawerMenu;
