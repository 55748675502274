import styled from 'styled-components';

export const StyledDiv = styled.div`
  & .radio-button {
    min-width: 24px;
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
`;

export const StyledImg = styled.img`
  height: 215px;
`;

export const StyledDescription = styled.div`
  font-size: 0.8rem;
  line-height: 1.2rem;
  letter-spacing: 0rem;
  font-weight: 400;
  text-align: center;
  color: #8798ad;
`;
