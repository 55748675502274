import React from 'react';
import {StatusVariant, VariantText} from '.';
import {Typography} from '..';
import {colorTheme} from '../../configs';
import StyleStatusIcon from './styled';

interface ProfileStatusProps {
  status: StatusVariant;
  lastSeen?: string;
}

export const variantText: {[key in StatusVariant]: VariantText} = {
  online: {
    label: 'Online now',
  },
  away: {
    label: 'Online recently',
  },
  offline: {
    label: 'Last seen {0}',
  },
};

export const getOfflineStatusText = (status: StatusVariant, lastSeen?: string) => {
  if (lastSeen) {
    const statusNum = lastSeen.replace( /^\D+/g, '')!;
    if(parseInt(statusNum) > 4 && lastSeen.includes('w')) {
    return 'Last seen more than a month ago'
  } else {
    return variantText[status].label.replace('{0}', lastSeen!)
  }
} else {
    return 'offline';
}
  
}

const getStatusText = (status: StatusVariant, lastSeen?: string) => {
  switch (status) {
    case 'online':
      return variantText[status].label;
    case 'away':
      return variantText[status].label;
    case 'offline':
      return getOfflineStatusText(status, lastSeen);
    default:
      return 'Offline';
  }
};

const ProfileStatus = (props: ProfileStatusProps) => {
  return (
    <div className="flex items-center space-x-2">
      <StyleStatusIcon status={props.status} />
      <Typography
        label={getStatusText(props.status, props.lastSeen)}
        variant="caption"
        weight="normal"
        color={colorTheme.light}
      />
    </div>
  );
};

export default ProfileStatus;
