import React, {FormEventHandler, useState} from 'react';

import {
  StyledTextArea,
  StyledTextAreaForBroadcast,
  StyledInputLabel
} from './styled';

import {TextAreaSize} from './textarea.types';

import {colorTheme} from '../../configs';

export interface TextAreaProps {
  label?: string;

  placeholder?: string;
  value?: string;

  disabled?: boolean;
  className?: string;

  textAreaSize?: TextAreaSize;

  error?: string;

  onChange?: FormEventHandler<HTMLTextAreaElement>;
  onBlur?: FormEventHandler<HTMLTextAreaElement>;

  name?: string;
  id?: string;

  cols?: number;
  rows?: number;

  autoresize?: boolean;

  maxRows?: number;
  bordered?: boolean;
  forBroadcast?: boolean;
}

const TextArea: React.ForwardRefRenderFunction<unknown, TextAreaProps> = (
  props,
  ref,
) => {
  const {
    label,
    placeholder,
    value,
    disabled = false,
    className,
    error,
    onChange,
    onBlur,
    cols = 30,
    rows = 3,
    maxRows = 10,
    name,
    id,
    autoresize = false,
    bordered = false,
    textAreaSize = 'default',
    forBroadcast=false
  } = props;

  const [currentRows, setCurrentRows] = useState(rows);

  const styles = {
    error,
    textAreaSize,
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (autoresize) {
      const textareaLineHeight = 16;

      const previousRows = event.target.rows;
      event.target.rows = rows; // reset number of rows in textarea

      const cRows = ~~(event.target.scrollHeight / textareaLineHeight);

      if (cRows === previousRows) {
        event.target.rows = cRows;
      }

      if (cRows >= maxRows) {
        event.target.rows = maxRows;
        event.target.scrollTop = event.target.scrollHeight;
      }

      setCurrentRows(cRows < maxRows ? cRows : maxRows);
    }

    if (onChange) {
      onChange(event);
    }
  };

  return (
    <>
      {label && (
        <StyledInputLabel data-testid="label-element" {...styles}>
          {label}
        </StyledInputLabel>
      )}

      {forBroadcast 
        ? (
          <StyledTextAreaForBroadcast
            name={name}
            id={id}
            data-testid="textarea-element"
            value={value}
            onChange={handleChange}
            onBlur={onBlur}
            placeholder={placeholder}
            cols={cols}
            rows={currentRows}
            className={className}
            disabled={disabled}
            bordered={bordered}
            {...styles}
          />
        )
        : (
          <StyledTextArea
            name={name}
            id={id}
            data-testid="textarea-element"
            value={value}
            onChange={handleChange}
            onBlur={onBlur}
            placeholder={placeholder}
            cols={cols}
            rows={currentRows}
            className={className}
            disabled={disabled}
            bordered={bordered}
            {...styles}
          />
        )}

      {error && (
        <div
          data-testid="error-msg-element"
          style={{
            marginTop: '0.25rem',
            fontSize: '0.75rem',
            lineHeight: '1.125rem',
            color: colorTheme.danger,
          }}>
          {error}
        </div>
      )}
    </>
  );
};

export default TextArea;
