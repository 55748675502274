import * as yup from 'yup';

export const signInSchema = yup.object({
  mobileNumber: yup
    .string()
    .trim()
    .required('This field is required.')
    .length(11, 'Invalid mobile number format (e.g. 09xxxxxxxxx).')
    .matches(/^(09)\d{9}$/, 'Invalid mobile number format (e.g. 09xxxxxxxxx).'),
  password: yup
    .string()
    .trim()
    .required('This field is required.')
    .min(6, 'Min of 6 characters.'),
});
