import {colorTheme} from '../../configs';
import Icon from '../icon';
import Typography from '../typography';
import { StyledCircleDiv, StyledDiv} from './styled';

export interface TrueSelfieTagProps {
  type?: 'iconOnly' | 'trueSelfie';
  onClickVerifiedText?: () => void;
}

const TrueSelfieTag = (props: TrueSelfieTagProps) => {
  const {type = 'iconOnly', onClickVerifiedText} = props;

  if (type === 'trueSelfie') {
    return (
      <>
        <StyledDiv
          className="space-x-1 cursor-pointer"
          onClick={onClickVerifiedText}>
          <Icon name="trueSelfie" size={16} color={colorTheme.white} />

          <Typography
            label="True Selfie"
            variant="f2"
            weight="semibold"
            color={colorTheme.white}
          />
        </StyledDiv>
      </>
    );
  }

  return (
    <StyledCircleDiv>
      <Icon name="trueSelfie" size={12} color={colorTheme.white} />
    </StyledCircleDiv>
  );
};

export default TrueSelfieTag;
