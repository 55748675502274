import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {UserResponse} from '../server/types';
import {getMatchById, getMatches, getMultipleUsers} from '../server/react-query';
import _ from 'lodash';
import {useQuery} from 'react-query';
import {isBefore} from 'date-fns';
import { STORAGE } from '../utils/storage.utils';
import { useToast } from '../../core/components/toast';
import { getTimestampInSeconds } from '../utils/random.util';

declare global {
  interface Window {
    AssetCache: AssetCacheType
  }
}

window.AssetCache = window.AssetCache || {}

type AssetCacheType = { [key: string]: HTMLImageElement }

type TMatches = {
  data?: UserResponse | undefined;
  prev: () => void;
  next: () => void;
  requery: () => void;
  refetchMatchWithId: (id: string) => void;
  isFetching: boolean;
  isFetchingNext: boolean;
  isFetchingImages: boolean | undefined;
  hasPrev: boolean;
  hasNext: boolean;
  isLast: boolean;
  excludeFromMatches: (userId: string) => void;
};

const MatchesContext = createContext<TMatches>({
  prev: () => {},
  next: () => {},
  requery: () => {},
  refetchMatchWithId: (id: string) => {},
  isFetching: false,
  isFetchingNext: false,
  isFetchingImages: false,
  hasPrev: false,
  hasNext: false,
  isLast: false,
  excludeFromMatches: (_: string) => {},
});

interface MatchedProviderProps {
  children: ReactNode;
}

export const MatchesProvider = (props: MatchedProviderProps) => {
  const {presentToast} = useToast();
  
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [matchesData, setMatchesData] = useState<UserResponse[] | undefined>(
    undefined,
  );
  const [currentData, setCurrentData] = useState<UserResponse | undefined>(
    undefined,
  );
  const [matchedHomeownerData, setMatchedHomeownerData] = useState<UserResponse | undefined>(
    undefined,
  );
  const [prevPageMatchData, setPrevPageMatchData] = useState<UserResponse[]>(
    [],
  );
  const [nextPageMatchData, setNextPageMatchData] = useState<UserResponse[]>(
    [],
  );
  const [nextBatchOfMatchData, setNextBatchOfMatchData] = useState<
    UserResponse[]
  >([]);
  const [recentMatchesData, setRecentMatchesData] = useState<UserResponse[]>(
    [],
  );
  const [tier1LastIndex, setTier1LastIndex] = useState<number | undefined>(
    undefined,
  );

  const [userIds, setUserIds] = useState<string[]>([]);
  const [lastLoginDate, setLastLoginDate] = useState<string>();

  const [hasPrev, setHasPrev] = useState<boolean>(false);
  const [hasNext, setHasNext] = useState<boolean>(true);
  const [isLast, setIsLast] = useState<boolean>(false);

  const [getMultipleUsersData, setGetMultipleUsersData] = useState<boolean>(
    false,
  );
  const [refetchMainQuery, setRefetchMainQuery] = useState<boolean>(false);
  const [refetchNextQuery, setRefetchNextQuery] = useState<boolean>(false);
  const [refetchPrevQuery, setRefetchPrevQuery] = useState<boolean>(false);
  const [matchedHomeownerId, setMatchedHomeownerId] = useState<string>('');
  const [fetchingBatchImages, setFetchingBatchImages] = useState<boolean | undefined>(undefined);

  const {
    data: matchedHomeowner,
    refetch: refetchMatch,
    error: getMatchError,
    isFetching: isFetchingMatch
  } = useQuery(
    ['get-match-by-id', {id: getTimestampInSeconds()}], 
    () => getMatchById(matchedHomeownerId), 
    {
      enabled: !_.isNil(matchedHomeownerId) && matchedHomeownerId.length > 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: 0
  });

  // new query page: 1
  const {data: hookDataMatches, refetch: requeryNewSearchMatch} = useQuery(
    ['get-matches-1'],
    () =>
      getMatches({
        page: 1,
      }),
    {
      enabled: refetchMainQuery,
    },
  );

  // get next page
  const {
    data: hookDataNextPageMatches,
    refetch: refetchNextPageMatches,
    isFetching: nextPageIsFetching,
  } = useQuery(
    [`get-matches-${pageNumber + 1}`],
    () => getMatches({page: pageNumber + 1}),
    {
      enabled: refetchNextQuery,
    },
  );

  // get prev page
  const {
    data: hookDataPrevPageMatches,
    refetch: refetchPrevPageMatches,
    isFetching: previousPageIsFetching,
  } = useQuery(
    [`get-matches-${pageNumber - 1}`],
    () => getMatches({page: pageNumber - 1}),
    {
      enabled: refetchPrevQuery,
    },
  );

  // get recent matches
  const {data: hookDataRecentMatches, refetch: refetchRecentMatches} = useQuery(
    ['get-recent-matches'],
    () =>
      getMatches({
        page: 1,
        recentResult: true,
        loginDate: lastLoginDate,
      }),
    {
      enabled: false,
    },
  );

  // get multiple users
  const {data: hookMultipleUsers} = useQuery(
    ['get-multiple-users'],
    () => getMultipleUsers(userIds),
    {
      enabled: getMultipleUsersData,
    },
  );

  // reset query but append to list
  const {
    data: hookDataNewMatches,
    isFetching: isFetchingNextBatchOfMatches,
    refetch: requeryNextBatchOfMatches,
  } = useQuery(
    ['get-matches-1-new'],
    () =>
      getMatches({
        page: 1,
      }),
    {
      enabled: getMultipleUsersData,
    },
  );

  useEffect(() => {
    setIsLast(false);
    setRefetchMainQuery(false);

    var lastLoginDate = localStorage.getItem('lastLoginDate');
    setLastLoginDate(lastLoginDate as string);

    var lastSession = localStorage.getItem('currentSessionTimeStamp');
    var lastSessionDate = new Date(lastSession as string);
    var today = new Date();
    today.setHours(today.getHours() - 1);
    var dateIsPastOneHour = isBefore(lastSessionDate, today);

    var currentmatches = STORAGE.get<string[]>('currentMatchesData', true);
    if (currentmatches && !dateIsPastOneHour) {
      setUserIds(currentmatches);
      setGetMultipleUsersData(true);
    } else {
      setRefetchMainQuery(true);
      setRefetchNextQuery(true);
      setGetMultipleUsersData(false);
      localStorage.removeItem('currentSessionTimeStamp');
      localStorage.removeItem('currentMatchesData');
      localStorage.removeItem('currentProfile');
    }

  }, []);

  useEffect(() => {
    if (currentData && matchesData) {
      const index = matchesData.findIndex(i => i.userId === currentData.userId);
      if (index >= 0) {
        if (pageNumber > 1) {
          setHasPrev(true);
        } else {
          if (prevPageMatchData.length > 0) {
            setHasPrev(true);
          } else {
            setHasPrev(index > 0);
          }
        }
      } else {
        // Check prev page
        if (previousPageIsFetching) {
          setHasPrev(true);
        } else {
          setHasPrev(prevPageMatchData.length > 0);
        }
      }
    }
  }, [
    currentData,
    matchesData,
    prevPageMatchData,
    previousPageIsFetching,
    pageNumber,
  ]);

  useEffect(() => {
    if (hookDataMatches && refetchMainQuery && pageNumber === 1) {
      let data = hookDataMatches.data.map(i => ({...i}));

      setMatchesData(data);
      setCurrentData(data[0]);
      setMatchedHomeownerData(undefined);

      if (hookDataMatches.tier1LastIndex) {
        setTier1LastIndex(hookDataMatches.tier1LastIndex);
      }
    }
  }, [hookDataMatches]);

  useEffect(() => {
    if (hookDataMatches && refetchMainQuery && pageNumber === 1 && matchedHomeownerData) {
      let data = hookDataMatches.data.map(i => ({...i}));

      let filteredData = data.filter(item => item.userId !== matchedHomeownerData.userId);
      data = [matchedHomeownerData, ...filteredData];

      setMatchesData(data);
      setCurrentData(data[0]);
      setMatchedHomeownerData(undefined);
      setRefetchMainQuery(false);

      if (hookDataMatches.tier1LastIndex) {
        setTier1LastIndex(hookDataMatches.tier1LastIndex);
      }
    }
  }, [hookDataMatches, matchedHomeownerData]);

  useEffect(() => {
    if (hookDataMatches && matchedHomeownerData) {
      let data = hookDataMatches.data.map(i => ({...i}));

      let filteredData = data.filter(item => item.userId !== matchedHomeownerData.userId);
      data = [matchedHomeownerData, ...filteredData];

      setMatchesData(data);
      setCurrentData(data[0]);
      setMatchedHomeownerData(undefined);
      setRefetchMainQuery(false);

      if (hookDataMatches.tier1LastIndex) {
        setTier1LastIndex(hookDataMatches.tier1LastIndex);
      }
    }
  }, [hookDataMatches, matchedHomeownerData]);

  useEffect(() => {
    if (
      hookDataNextPageMatches &&
      hookDataNextPageMatches.page === pageNumber + 1
    ) {
      setNextPageMatchData(hookDataNextPageMatches.data.map(i => ({...i})));

      if (hookDataNextPageMatches.tier1LastIndex) {
        setTier1LastIndex(hookDataNextPageMatches.tier1LastIndex);
      }
    }
  }, [hookDataNextPageMatches, pageNumber]);

  useEffect(() => {
    if (
      hookDataPrevPageMatches &&
      hookDataPrevPageMatches.page === pageNumber - 1
    ) {
      setPrevPageMatchData(hookDataPrevPageMatches.data.map(i => ({...i})));
    }
  }, [hookDataPrevPageMatches, pageNumber]);

  useEffect(() => {
    if (hookDataRecentMatches) {
      const data = hookDataRecentMatches.data.map(i => ({...i}));
      setRecentMatchesData(data);
    }
  }, [hookDataRecentMatches]);

  useEffect(() => {
    if (hookMultipleUsers) {
      let data = hookMultipleUsers;
      var currentProfile = localStorage.getItem('currentProfile');
      let index = hookMultipleUsers.findIndex(
        i => i.userId === currentProfile,
      );

      setMatchesData(data);
      setCurrentData(data[index]);
      setMatchedHomeownerData(undefined);
      setNextPageMatchData([]);
    }
  }, [hookMultipleUsers]);

  useEffect(() => {
    if (hookMultipleUsers && matchedHomeownerData) {
      let data = hookMultipleUsers;
      var currentProfile = localStorage.getItem('currentProfile');
      let index = hookMultipleUsers.findIndex(
        i => i.userId === currentProfile,
      );

      if (currentProfile != matchedHomeownerData?.userId) {
        index = index + 1;
      }

      let filteredData = data.filter(item => item.userId !== matchedHomeownerData.userId);

      data = [
        ...filteredData.slice(0, index),
        matchedHomeownerData,
        ...filteredData.slice(index)
      ];

      setMatchesData(data);
      setCurrentData(data[index]);
      setMatchedHomeownerData(undefined);
      setNextPageMatchData([]);
    }
  }, [hookMultipleUsers, matchedHomeownerData]);

  useEffect(() => {
    if (hookDataNewMatches && getMultipleUsersData && pageNumber === 1) {
      const data = hookDataNewMatches.data.map(i => ({...i}));
      setNextBatchOfMatchData(data);
    }
  }, [hookDataNewMatches]);

  useEffect(() => {
    if (matchedHomeownerId) {
      refetchMatch();
    }
  }, [matchedHomeownerId]);
  
  useEffect(() => {
    if (getMatchError && !isFetchingMatch && matchedHomeownerId) {
      var error = getMatchError as any;
      if (error.status === 404) {
        setMatchedHomeownerId("");
        setMatchedHomeownerData(undefined)
        presentToast({
          message: 'User is no longer a match.',
          variant: 'error',
          position: 'bottom',
        });
      }
    }
    
  }, [getMatchError, isFetchingMatch, matchedHomeownerId])

  useEffect(() => {
    if (matchedHomeowner && matchedHomeownerId) {
      setMatchedHomeownerData(matchedHomeowner);
      setMatchedHomeownerId('');
    }
  }, [matchedHomeowner, matchedHomeownerId]);

  useEffect(() => {
    if (matchesData && pageNumber === 1) {
      var srcs = matchesData.map((match) => {return match.photo} );
      readyBatchImages(srcs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchesData, pageNumber]);

  useEffect(() => {
    if (nextPageMatchData) {
      nextPageMatchData.forEach(match => {
        readyNextImage(match.photo);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextPageMatchData]);

  const fetchNewSearchMatch = useCallback(() => {
    (async () => {
      await requeryNewSearchMatch();
    })();
  }, [requeryNewSearchMatch]);

  const fetchNextPageData = useCallback(() => {
    (async () => {
      await refetchNextPageMatches();
    })();
  }, [refetchNextPageMatches]);

  const fetchPrevPageData = useCallback(() => {
    (async () => {
      await refetchPrevPageMatches();
    })();
  }, [refetchPrevPageMatches]);

  const fetchRecentData = useCallback(() => {
    (async () => {
      await refetchRecentMatches();
    })();
  }, [refetchRecentMatches]);

  const prevHandler = () => {
    if (matchesData) {
      // Get index
      const index = matchesData.findIndex(
        i => i.userId === currentData?.userId,
      );

      if (index >= 0) {
        if (index > 0) {
          // Move to previous index
          setIsLast(false);
          setCurrentData(matchesData[index - 1]);
          localStorage.setItem('currentProfile', matchesData[index - 1].userId);

          const previousIndex = index - 1;
          if ((previousIndex + 1) % 10 === 3 && !getMultipleUsersData) {
            // Fetch previous page in advance
            fetchPrevPageData();
          }
        } else {
          const hasPreviousPage = prevPageMatchData.length > 0;
          if (hasPreviousPage && !previousPageIsFetching) {
            // Update next page's data
            setNextPageMatchData(
              matchesData.map(i => ({...i})), // Current page's data
            );

            // Update current page's data
            setMatchesData(
              prevPageMatchData.map(i => ({...i})), // From previous page
            );

            // Clear prev page
            setPrevPageMatchData([]);

            setCurrentData(prevPageMatchData[prevPageMatchData.length - 1]); // Last item
            localStorage.setItem(
              'currentProfile',
              prevPageMatchData[prevPageMatchData.length - 1].userId,
            );
            setPageNumber(prevState => prevState - 1);
          }
        }
      }

      saveDataToLocalStorage();
    }
  };

  const nextHandler = () => {
    if (matchesData) {
      // Get index
      const index = matchesData.findIndex(
        i => i.userId === currentData?.userId,
      );

      if (index >= 0) {
        if (index + 1 < matchesData.length) {
          // Move to the next index
          console.log('Move to the next index');
          setCurrentData(matchesData[index + 1]);
          localStorage.setItem(
            'currentProfile',
            matchesData[index + 1].userId,
          );

          const nextIndex = index + 1;
          if ((nextIndex + 1) % 10 === 8 && !getMultipleUsersData) {
            // Fetch next page in advance
            console.log('Fetch next page in advance');
            fetchNextPageData();
          }

          // if (tier1LastIndex && nextIndex === tier1LastIndex - 1) {
          //   fetchRecentData();
          // }

          if (
            nextIndex + 1 === matchesData.length &&
            nextPageMatchData.length === 0 &&
            !getMultipleUsersData
          ) {
            setIsLast(true);
          }
        } else {
          const hasNextPage = nextPageMatchData.length > 0;
          if (hasNextPage && !nextPageIsFetching && !getMultipleUsersData) {
            console.log('Update previous page with current data');
            // Update previous page (clear)
            setPrevPageMatchData(
              matchesData.map(i => ({...i})), // Current page's data
            );

            // Update current page
            setMatchesData(
              nextPageMatchData.map(i => ({...i})), // From next page
            );

            if (nextPageMatchData.length === 1) {
              setIsLast(true);
            }

            // Clear next page
            setNextPageMatchData([]);

            setCurrentData(nextPageMatchData[0]); // First item
            localStorage.setItem('currentProfile', nextPageMatchData[0].userId);
            setPageNumber(prevState => prevState + 1);
            fetchNextPageData();
          } else {
            if (index + 1 === matchesData.length) {
              if (getMultipleUsersData) {
                setIsLast(false);
                
                if (nextBatchOfMatchData.length > 0) {
                  console.log('nextBatchOfMatchData');

                  // Update previous page (clear)
                  setPrevPageMatchData(
                    matchesData.map(i => ({...i})), // Current page's data
                  );
                  // Update current page
                  setMatchesData(
                    nextBatchOfMatchData.map(i => ({...i})), // From next page
                  );
                  // Clear next page
                  setNextPageMatchData([]);
                  setCurrentData(nextBatchOfMatchData[0]); // First item
                  localStorage.setItem(
                    'currentProfile',
                    nextBatchOfMatchData[0].userId,
                  );
                  setPageNumber(1);
                  setGetMultipleUsersData(false);
                }
              } else {
                setIsLast(true);
              }
            }
          }
        }
      }

      saveDataToLocalStorage();
    }
  };

  const requeryHandler = () => {
    localStorage.removeItem('currentSessionTimeStamp');
    localStorage.removeItem('currentMatchesData');

    setIsLast(false);
    setPageNumber(1);
    setPrevPageMatchData([]);

    setRefetchMainQuery(true);
    setRefetchNextQuery(true);
    setGetMultipleUsersData(false);
    fetchNewSearchMatch();
  };

  const refetchMatchWithIdHandler = (id: string) => {
    if (id) {
      setMatchedHomeownerId(id)
    }
  };

  const insertRecentMatches = () => {
    if (matchesData && tier1LastIndex) {
      var currentMatchesData = _.cloneDeep(matchesData);
      var prevData = currentMatchesData.slice(0, tier1LastIndex + 1);
      setPrevPageMatchData(prevData);
      var newCurrentData = [
        ...recentMatchesData,
        ...currentMatchesData.slice(tier1LastIndex + 1),
      ];
      setMatchesData(newCurrentData);
      setCurrentData(newCurrentData[0]);

      setTier1LastIndex(undefined);
      setRecentMatchesData([]);
    }
  };

  const excludeFromMatchesHandler = (userId: string) => {
    if (matchesData) {
      const filtered = matchesData
        .filter(i => i.userId !== userId)
        .map(i => ({...i}));
      setMatchesData(filtered);
    }
  };

  const saveDataToLocalStorage = () => {
    if (matchesData) {
      const index = matchesData.findIndex(
        i => i.userId === currentData?.userId,
      );

      var toStoreData: UserResponse[] = [];
      var currentMatchesData = _.cloneDeep(matchesData);

      if (index + 1 < 5) {
        if (prevPageMatchData.length > 0) {
          var prevMatchesData = _.cloneDeep(prevPageMatchData);
          var prevDataLastIndex = prevPageMatchData.length - 1;
          prevMatchesData = prevMatchesData.slice(
            prevDataLastIndex - 4,
            prevDataLastIndex,
          );
          toStoreData = toStoreData.concat(prevMatchesData);
        }
      } else {
        currentMatchesData.splice(0, index - 4);
      }
      toStoreData = toStoreData.concat(currentMatchesData);

      if (nextPageMatchData.length > 0) {
        var nextMatchesData = _.cloneDeep(nextPageMatchData);
        nextMatchesData = nextMatchesData.slice(0, index + 2);
        toStoreData = toStoreData.concat(nextMatchesData);
      }

      if (nextBatchOfMatchData.length > 0 && getMultipleUsersData) {
        var nextBatchMatchesData = _.cloneDeep(nextBatchOfMatchData);
        nextMatchesData = nextBatchMatchesData.slice(0, index + 2);
        toStoreData = toStoreData.concat(nextMatchesData);
      }

      var userIds = toStoreData.map(data => {
        return data.userId;
      });
      localStorage.setItem('currentMatchesData', JSON.stringify(userIds));
      localStorage.setItem('currentSessionTimeStamp', new Date().toISOString());
      setRefetchMainQuery(false);
    }
  };

  const readyNextImage = (src: string) => {
    if (src) {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        window.AssetCache[src] = img;
        console.log('next image loaded');
      }
    }
  };

  const readyBatchImages = async (srcs: string[]) => {
    setFetchingBatchImages(true);
    const promises = await srcs.map((src) => {
      if (src) {
        return new Promise<void>(async (resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = () => {
            window.AssetCache[src] = img;
            console.log('resolved');
            resolve();
          };
          img.onerror = () => {
            console.log('reject');
            resolve();
          };
        });
      }
    })

    await Promise.all(promises);
    setFetchingBatchImages(false);
  };

  const isFetchingNextData = (): boolean => {
    return nextPageIsFetching || isFetchingNextBatchOfMatches
  }

  return (
    <MatchesContext.Provider
      value={{
        prev: prevHandler,
        next: nextHandler,
        requery: requeryHandler,
        refetchMatchWithId: refetchMatchWithIdHandler,
        data: currentData,
        isFetching: matchesData === undefined || isFetchingMatch,
        isFetchingNext: isFetchingNextData(),
        isFetchingImages: fetchingBatchImages,
        hasPrev: hasPrev,
        hasNext: hasNext,
        isLast: isLast,
        excludeFromMatches: excludeFromMatchesHandler,
      }}>
      {props.children}
    </MatchesContext.Provider>
  );
};

export const useMatches = () => {
  const {
    data,
    prev,
    next,
    requery,
    refetchMatchWithId,
    isFetching,
    isFetchingNext,
    isFetchingImages,
    hasPrev,
    hasNext,
    isLast,
    excludeFromMatches,
  } = useContext(MatchesContext);
  return {
    data,
    prev,
    next,
    requery,
    refetchMatchWithId,
    isFetching,
    isFetchingNext,
    isFetchingImages,
    hasPrev,
    hasNext,
    isLast,
    excludeFromMatches,
  };
};

