import {useGetRandomBannerAd} from '../../../server/react-query';
import {StyledDiv} from './styled';
import {useAnalytics} from '../../../hooks/useAnalytics';
import {FormEvent} from 'react';
import { useHistory } from 'react-router-dom';

const BannerAd = () => {
  const {data} = useGetRandomBannerAd();
  const {systemLogEvent} = useAnalytics();
  const history = useHistory();

  const onImageLoadedHandler = () => {
    systemLogEvent('ad_banner_viewed');
  };

  const onAdClickedHandler = (e: FormEvent<HTMLElement>) => {
    e.preventDefault();

    if (data && data.outboundLink) {
      systemLogEvent('ad_banner_clicked');
      if (data.linkType === 'inbound') {
        history.push(data.outboundLink);
      } else {
        window.open(data.outboundLink);
      }
    }
  };

  return (
    <StyledDiv>
      {data && (
        <a
          href={data.outboundLink}
          target="_blank"
          onClick={onAdClickedHandler}
          rel="noreferrer">
          <img src={data.imageUrl} alt="ad" onLoad={onImageLoadedHandler} />
        </a>
      )}
    </StyledDiv>
  );
};

export default BannerAd;
