import * as React from 'react';

function SvgWaveGradient(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}>
      <path
        d="M19.094 6.314a3 3 0 00-4.7-.642l-1.36-2.355A2.999 2.999 0 008.12 2.91 3 3 0 003.84 6.893l.048.083a3 3 0 00-1.796 4.39l3.938 6.82a8.625 8.625 0 1014.939-8.625l-1.875-3.247zm.563 9.21A6.376 6.376 0 017.98 17.06l-3.938-6.82a.75.75 0 011.299-.75l2.063 3.573c.023.04.049.078.077.115l.025.032c.021.026.044.05.067.074l.027.028c.032.031.065.06.1.087l.008.005c.033.024.067.046.102.067l.035.019c.029.015.058.03.087.042l.034.015c.04.016.082.03.123.04l.014.004c.038.009.076.016.115.022l.039.005c.032.003.065.005.098.006l.024.002.012-.001c.043 0 .086-.003.129-.009l.014-.002a1.134 1.134 0 00.246-.064l.038-.015a1.115 1.115 0 00.682-.956 1.12 1.12 0 00-.148-.641L5.79 5.769a.75.75 0 011.299-.75l3.187 5.52a1.133 1.133 0 00.767.545 1.125 1.125 0 001.182-1.67L9.786 5.192a.75.75 0 011.3-.75l2.811 4.871.44.763a4.88 4.88 0 00-.874 5.984 1.124 1.124 0 001.948-1.125 2.625 2.625 0 01.961-3.585c.032-.02.064-.04.095-.062.02-.014.039-.03.058-.046.01-.008.02-.015.028-.023.024-.02.046-.043.068-.065l.01-.01a1.11 1.11 0 00.063-.075l.009-.01c.018-.024.035-.05.052-.075l.01-.015.042-.075.012-.022c.012-.025.022-.05.033-.076l.01-.025c.026-.069.045-.14.057-.212l.003-.026c.004-.028.008-.056.01-.084v-.022c.002-.03.003-.059.002-.088l-.001-.015a1.17 1.17 0 00-.008-.095l-.002-.009a1.128 1.128 0 00-.018-.1l-.002-.007a1.095 1.095 0 00-.028-.098l-.008-.022a1.152 1.152 0 00-.083-.18l-.938-1.625a.75.75 0 011.3-.75l1.874 3.248a6.334 6.334 0 01.637 4.838zm2.277-9.581a1.125 1.125 0 01-1.537-.412 4.518 4.518 0 00-3.898-2.25 1.125 1.125 0 11.001-2.25h.001a6.775 6.775 0 015.845 3.375 1.125 1.125 0 01-.412 1.537zM7.138 23.223a1.125 1.125 0 01-1.585.14 12.338 12.338 0 01-2.768-3.302 1.125 1.125 0 111.948-1.125 10.091 10.091 0 002.265 2.701 1.124 1.124 0 01.14 1.585z"
        fill="url(#wave-gradient_svg__paint0_linear)"
      />
      <defs>
        <linearGradient
          id="wave-gradient_svg__paint0_linear"
          x1={12.094}
          y1={1.03}
          x2={12.094}
          y2={23.625}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#630099" stopOpacity={0.74} />
          <stop offset={1} stopColor="#0F2CC5" stopOpacity={0.73} />
        </linearGradient>
      </defs>
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgWaveGradient);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
