import {Icon, Typography} from '../../../../core/components';
import {StyledFinderSettings} from './styled';
import {useHistory} from 'react-router-dom';
import {RoutePath} from '../../../navigation/config/RouteConfig';
import {localization} from '../../../localization/Localization';
import { isMobile } from 'react-device-detect';

const FilterSettingsView = () => {
  const history = useHistory();

  const filterSettingsHandler = () => {
    history.push(RoutePath.FINDER_SETTINGS);
  };

  return (
    <StyledFinderSettings
      className={[
        'finder-settings flex justify-between cursor-pointer',
        isMobile ? 'floating-card' : '',
      ].join(' ')}
      onClick={filterSettingsHandler}>
      <div>
        <Typography
          label={localization.hdr_search_settings}
          variant="f2"
          weight="semibold"
          color="white"
        />
        <Typography
          label={localization.desc_search_setting}
          variant="f1"
          weight="normal"
          color="white"
        />
      </div>
      <div className="flex flex-col justify-center">
        <Icon
          className="cursor-pointer"
          name="sliders"
          color="white"
          type="button"
        />
      </div>
    </StyledFinderSettings>
  );
};

export default FilterSettingsView;
