import {Button, Typography} from '../../../../core/components';
import {StyledGetApp} from './styled';

interface GetAppCardProps {
  fixed?: boolean;
  appUrl: string;
  onClickNotNow: () => void;
}

const GetAppCard = (props: GetAppCardProps) => {
  const googlePlayUrl = process.env.REACT_APP_KAZAM_GOOGLE_PLAYSTORE_URL;
  const appStoreUrl = process.env.REACT_APP_KAZAM_APP_STORE_URL;

  const notNow = () => {
    localStorage.setItem('lastSeenGetAppBanner', new Date().toString());
    props.onClickNotNow();
  };

  const switchToTheApp = () => {
    window.open(props.appUrl);
  };

  return (
    <StyledGetApp
      fixed={props.fixed}
      className="flex flex-col">
      <div>
        <Typography
          label="kazam is better on the app"
          variant="f2"
          weight="semibold"
          color="white"
        />
        <Typography
          label="Get the kazam app for a better experience."
          variant="f1"
          weight="normal"
          color="white"
        />
      </div>
      <div className="flex justify-between pt-2">
        <Button
            className='not-now'
            label="Not now"
            variant='outlined'
            color="light"
            onClick={notNow}
          />
          <Button
            className='switch'
            label="Get the app"
            color="light"
            onClick={switchToTheApp}
          />
      </div>
    </StyledGetApp>
  );
};

export default GetAppCard;
