import React, {useEffect, useState} from 'react';
import {Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {StyledAdminReferralTabs} from './styled';

export enum ReferralType {
  USER = 'user',
  CREATED = 'created',
}

interface AdminReferralTypesProps {
  selected: ReferralType;
  onChange?: (tab: ReferralType) => void;
  className?: string;
}

const AdminReferralTypes = (props: AdminReferralTypesProps) => {
  const {selected, onChange, className} = props;

  const [activeTab, setActiveTab] = useState<ReferralType>(selected);

  useEffect(() => {
    setActiveTab(selected);
  }, [selected]);

  const onTabClickedHandler = (tab: ReferralType) => {
    setActiveTab(tab);
    onChange && onChange(tab);
  };

  return (
    <StyledAdminReferralTabs
      className={['tabs-control flex flex-row', className].join(' ')}>
      <div
        className={[
          'tab-control cursor-pointer',
          activeTab === ReferralType.USER ? 'active' : '',
        ].join(' ')}
        onClick={() => onTabClickedHandler(ReferralType.USER)}>
        <Typography
          label="User Generated"
          variant="f2"
          weight="semibold"
          color={
            activeTab === ReferralType.USER ? colorTheme.white : colorTheme.dark
          }
          align="center"
        />
      </div>

      <div
        className={[
          'tab-control cursor-pointer',
          activeTab === ReferralType.CREATED ? 'active' : '',
        ].join(' ')}
        onClick={() => onTabClickedHandler(ReferralType.CREATED)}>
        <Typography
          label="Admin Generated"
          variant="f2"
          weight="semibold"
          color={
            activeTab === ReferralType.CREATED ? colorTheme.white : colorTheme.dark
          }
          align="center"
        />
      </div>
    </StyledAdminReferralTabs>
  );
};

export default AdminReferralTypes;
