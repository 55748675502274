import {useEffect, useState} from 'react';
import {
  Button,
  TextArea,
  Toggle,
  Typography,
} from '../../../../core/components';
import {StyledWrapper} from './styled';
import {colorTheme} from '../../../../core/configs';
import {usePutAdminControl} from '../../../server/react-query';
import {useToast} from '../../../../core/components/toast';
import {
  AdminControl,
  AdminControlReqBody,
} from '../../../server/types/admin-control.types';

interface GenericChatMessageViewProps {
  data: AdminControl[];
}

const GenericChatMessageView = ({data}: GenericChatMessageViewProps) => {
  const {presentToast} = useToast();

  const [enableAutoMessage, setEnableAutoMessage] = useState(false);
  const [message, setMessage] = useState<string>('');

  const putAdminControl = usePutAdminControl();

  useEffect(() => {
    if (data) {
      var genericChat = data.find(d => d.type === 'genericChatMessage');

      if (genericChat) {
        setEnableAutoMessage(genericChat.enabled);
        setMessage(genericChat.text);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const insertMessage = (value: string) => {
    var currentMessage = message + value;
    setMessage(currentMessage);
  };

  const onEnableAutoMessage = async (value: boolean) => {
    try {
      const data: AdminControlReqBody = {
        type: 'genericChatMessage',
        enabled: value,
        text: message,
      };
      await putAdminControl.mutateAsync(data);
      setEnableAutoMessage(value);

      presentToast({
        message: `Successfully ${
          value ? 'enabled' : 'disabled'
        } generic chat feature.`,
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      console.log(e);
    }
  };

  const onPutAdminControl = async () => {
    try {
      const data: AdminControlReqBody = {
        type: 'genericChatMessage',
        text: message,
        enabled: true,
      };

      await putAdminControl.mutateAsync(data);

      presentToast({
        message: `Successfully saved.`,
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      console.log(e);
    }
  };

  return (
    <StyledWrapper>
      <Typography
        label="CHAT SETTINGS"
        variant="f1"
        weight="semibold"
        color={colorTheme.black}
      />

      <div className="pt-3 flex flex-row justify-between items-center">
        <div>
          <Typography
            label="Enable generic chat feature"
            variant="f3"
            color={enableAutoMessage ? colorTheme.dark : colorTheme.lightGray}
          />
          <Typography
            label="Set the generic chat message the kazam will receive when the homeowner initiates a chat."
            variant="f1"
            color={
              enableAutoMessage ? colorTheme.darkFaded : colorTheme.lightGray
            }
          />
        </div>
        <div>
          <Toggle value={enableAutoMessage} onToggle={onEnableAutoMessage} />
        </div>
      </div>
      {enableAutoMessage && (
        <div>
          <div className="pt-3 flex flex-row justify-between items-center">
            <TextArea
              placeholder="Enter generic chat message"
              rows={2}
              maxRows={2}
              autoresize
              bordered
              value={message}
              onChange={value => setMessage(value.currentTarget.value)}
            />
          </div>

          <div className="pt-3 flex flex-row justify-between items-center">
            <div className="flex space-x-4">
              <div onClick={() => insertMessage('<First Name>')}>
                <Typography
                  className="cursor-pointer"
                  label="Insert First Name"
                  variant="f2"
                  weight="semibold"
                  color={colorTheme.primary}
                />
              </div>

              <div onClick={() => insertMessage('<Full Name>')}>
                <Typography
                  className="cursor-pointer"
                  label="Insert Full Name"
                  variant="f2"
                  weight="semibold"
                  color={colorTheme.primary}
                />
              </div>
            </div>
          </div>

          <div className="pt-3 flex justify-end">
            <div style={{width: 'fit-content'}}>
              <Button
                label="Save"
                color="primary"
                onClick={onPutAdminControl}
                isLoading={putAdminControl.isLoading}
              />
            </div>
          </div>
        </div>
      )}
    </StyledWrapper>
  );
};

export default GenericChatMessageView;
