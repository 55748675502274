import {Typography} from '../../../../../core/components';
import AdminPage from '../../../../../core/components/admin-page/AdminPage';
import {colorTheme} from '../../../../../core/configs';
import {StyledAdminUsersPage} from "../styled";
import AdminUsersTabs from "../AdminUsersTabs";
import React, {useState} from "react";
import AdminUsersSearch from "../AdminUsersSearch";
import AdminForVerificationUsersTable from './AdminForVerificationUsersTable';

const AdminForVerificationUsersPage = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');

  const onSearchChangedHandler = (query: string) => {
    setSearchQuery(query);
  };

  return (
    <AdminPage>
      <StyledAdminUsersPage>
        <Typography
          label="Manage Users"
          variant="f3"
          weight="semibold"
          color={colorTheme.dark}
        />

        <AdminUsersTabs selected={4}/>

        <AdminUsersSearch
          onChange={onSearchChangedHandler}
          showExtractButton={false}
          placeholder="Search user first name, last name, or UUID" />

        <AdminForVerificationUsersTable searchQuery={searchQuery} />

      </StyledAdminUsersPage>
    </AdminPage>
  );
};

export default AdminForVerificationUsersPage;
