import React, {useEffect, useState} from 'react';
import {
  StyledDiv,
  StyledIconOverlay,
  StyledImage,
  StyledOverlay,
} from './styled';
import {AdvertisementResponse} from '../../../server/types/admin-control.types';
import {Icon, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {getAdsDwellTime} from '../../../utils/admin.util';
import {useAnalytics} from '../../../hooks/useAnalytics';
import { useHistory } from 'react-router-dom';

interface SplashAdProps {
  adData: AdvertisementResponse;
  onClose: () => void;
}

const SplashAd = ({adData, onClose}: SplashAdProps) => {
  const [secondsRemaining, setSecondsRemaining] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const {systemLogEvent} = useAnalytics();
  const history = useHistory();

  useEffect(() => {
    var dwellTime = getAdsDwellTime();
    if (dwellTime) {
      const minutesToSeconds = parseInt(dwellTime.minutes.toString()) * 60;
      const totalSeconds =
        minutesToSeconds + parseInt(dwellTime.seconds.toString());
      setSecondsRemaining(totalSeconds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (secondsRemaining > 0) {
      setTimeout(() => {
        setSecondsRemaining(secondsRemaining - 1);
      }, 1000);
    }

    const secondsToDisplay = secondsRemaining % 60;
    const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
    const minutesToDisplay = minutesRemaining % 60;

    setSeconds(secondsToDisplay);
    setMinutes(minutesToDisplay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondsRemaining]);

  const onClickClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClose();
  };

  const onAdClick = () => {
    if(adData.outboundLink){
      systemLogEvent('ad_clicked');
      if (adData.linkType === 'inbound') {
        history.push(adData.outboundLink);
        onClose();
      } else {
        window.open(adData.outboundLink);
      }
    } 
  };

  const onImageLoadedHandler = () => {
    systemLogEvent('ad_viewed');
  };

  return (
    <StyledDiv>
      <StyledImage
        src={adData?.imageUrl}
        alt="ad"
        onLoad={onImageLoadedHandler}
      />

      <StyledOverlay className="flex justify-end" onClick={onAdClick}>
        {secondsRemaining === 0 ? (
          <StyledIconOverlay>
            <Icon
              name="x"
              color={colorTheme.white}
              size={24}
              type="button"
              onClick={onClickClose}
            />
          </StyledIconOverlay>
        ) : (
          <div className="flex">
            <Typography
              label={minutes}
              variant="f2"
              weight="semibold"
              color={colorTheme.white}
            />
            <Typography
              label=":"
              variant="f2"
              weight="semibold"
              color={colorTheme.white}
            />
            <Typography
              label={seconds < 10 ? `0${seconds}` : seconds}
              variant="f2"
              weight="semibold"
              color={colorTheme.white}
            />
          </div>
        )}
      </StyledOverlay>
    </StyledDiv>
  );
};

export default SplashAd;
