import React, {useEffect, useRef, useState} from 'react';
import {Icon} from '../../../core/components';
import {colorTheme} from '../../../core/configs';
import Page from '../../../core/components/page/Page';
import GoogleMap from '../../../core/components/google-map/GoogleMap';

import {isMobile} from 'react-device-detect';
import {useHistory, useParams} from 'react-router-dom';
import {Action} from 'history';

import {CurrentLocationHeader} from './styled';
import { useGetConversationContentById } from '../../server/react-query';
import { CurrentLocationMeta } from '../../server/types/conversation.types';

interface Location {
  latitude: number;
  longitude: number;
}

interface ViewCurrentLocationParams {
  id: string;
}

const ViewCurrentLocation = () => {
  const {id: contentId} = useParams<ViewCurrentLocationParams>();

  const [currentLocation, setCurrentLocation] = useState<Location | null>(null);
  const history = useHistory();

  const {data, isFetching} = useGetConversationContentById(contentId)

  useEffect(() => {
    if (data
      && data.meta) {
      const meta = JSON.parse(data.meta) as CurrentLocationMeta
      setCurrentLocation({
        latitude: meta.lat,
        longitude: meta.long
      })
    }
  }, [data]);

  const backButtonClickHandler = () => {
    if (history.action === Action.Push) {
      history.go(-1); //.back() will throw an error
    } else {
      // No more previous page; replace to home
      history.replace('/');
    }
  };

  return (
    <Page
      showBackButton
      unPadded
      hideNavbar={isMobile ? true : false}
      showLoading={isFetching}
    >
      <CurrentLocationHeader className="flex justify-between">
        <div className="tab">
          <Icon
            name="arrowLeft"
            size={24}
            color={colorTheme.dark}
            type="button"
            onClick={backButtonClickHandler}></Icon>
        </div>

        <div className="tab flex justify-center">
          <label className="heading">Current Location</label>
        </div>
        <div className="tab flex justify-center"></div>
      </CurrentLocationHeader>

      {!isFetching && currentLocation && (
        <GoogleMap
          height={isMobile ? '95vh' : '80vh'}
          latitude={currentLocation.latitude}
          longitude={currentLocation.longitude}
          fullscreenControl={false}
          mapTypeId={'roadmap'}
        />
      )}
    </Page>
  );
};

export default ViewCurrentLocation;
