import gin from '../../../../assets/stories/gin.png'
import margie from '../../../../assets/stories/margie.png'
import ian from '../../../../assets/stories/ian.png'
import violet from '../../../../assets/stories/violet.png'


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../sliderStyles.css';

import {GradientBackground} from '../styled';
import {Button, Typography} from '../../../../core/components';
import {useHistory} from 'react-router-dom';
import {colorTheme} from '../../../../core/configs';
import {useAnalytics} from '../../../hooks/useAnalytics';
import {useEffect, useRef, useState} from 'react';
import { isMobile } from 'react-device-detect';
import React from 'react';
import '../style.css';
import './styled'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { StyledComment, StyledStoriesWrapper } from './styled';
import InAppCallNotificationView from '../../call-room/InAppCallNotificationView';

const SuccessStories = () => {
  const items = [
    { id: 0, 
      comment: "kazam app is convenient and easy to use. It's like a linkedin for job hunting and hiring but for household services. For us busy parents, it just gives us more options now." ,
      name: 'Gin',
      description: 'Homeowner, Mom of 4',
      role: 'Homeowner',
      color: colorTheme.magenta,
      logo: gin
    },
    { id: 1, 
      comment: 'Good day po thank you po sa kazam at nakahanap po agad ako ng employer. Maraming salamat po, God bless you and sana marami pa kayong matulungan.' ,
      name: 'Margie',
      description: 'Maid',
      role: 'Kasambahay',
      color: colorTheme.tint,
      logo: margie
    },
    { id: 2, 
      comment: 'I would definitely recommend kazam! Dito ko nahanap ang family driver namin na si Edwin. Madali siyang kausap at maayos ang kanyang trabaho.' ,
      name: 'Ian',
      description: 'Homeowner, looking for a driver ',
      role: 'Homeowner',
      color: colorTheme.magenta,
      logo: ian
    },
    { id: 3, 
      comment: 'Thank you po sa kazam, nakahanap ako ng maayos na trabaho.' ,
      name: 'Violet',
      description: 'Nanny',
      role: 'Kasambahay',
      color: colorTheme.tint,
      logo: violet
    },
  ];

  const SimpleSlider = () => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      cssEase: 'ease-out', 
    };

  
    return (
      <StyledStoriesWrapper className="slider-container dynamic-height gradient-blue-bg py-9">
        <Typography label=' - What Our Customers Say - ' variant='f3' color={colorTheme.light} weight='bold' />

        <Carousel showArrows={false} showStatus={false} axis='horizontal' className='w-full h-full flex flex-col items-center justify-center'>
          {items.map(item => (
              <div key={item.id} className='flex flex-col w-full h-full items-start justify-center  px-11' >
                <div className='h-full w-full flex flex-col justify-center items-center rounded-xl gap-4 relative mt-9 ' style={{backgroundColor: 'white', padding: '33px 24.5px 33px 24.5px'}}>
                  <div
                    style={{
                      position: 'absolute',
                      top: '0%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: '64px',
                      height: '64px',
                      display: 'flex',
                      borderRadius: '50%',
                    }}
                  >
                    <img src={item.logo} className='w-full h-full' alt={item.role} />
                  </div>

                  <div className='flex flex-col justify-between h-full gap-2.5'>
                    <StyledComment>
                      {item.comment}
                    </StyledComment>
                    
                    <div className='flex flex-col'>
                      <Typography label={item.name} variant='f2' color={item.color} align='center' weight='bold'/>
                      <Typography label={item.description} variant='f1' color={colorTheme.dark} align='center'/>
                    </div>
                  </div>
                  
                  <div
                    style={{
                      borderRadius: '0px 12px 0px 12px',
                      padding: '2px 8px 2px 8px',
                      backgroundColor: item.color,
                      position: 'absolute',
                      bottom: '0%',
                      left: '0%',
                      width: 'auto',
                      display: 'flex',
                    }}
                  >
                    <Typography label={item.role} variant='f2' weight='semibold' color={colorTheme.light}/>
                  </div>
                </div>
              </div>
            ))}
        </Carousel>
      </StyledStoriesWrapper>
      
    );
  };

  return (
    <>
      {!isMobile &&
        <StyledStoriesWrapper className='flex flex-col justify-center items-center w-full'>
          <Typography label=' - What Our Customers Say - ' variant='f5' color={colorTheme.light} weight='bold'/>

          <div className='flex flex-row gap-12'>
            {items.map(item => (
                <div key={item.id} className='flex flex-col flex-1 justify-center items-center rounded-xl pt-6 pb-9 px-4 gap-2.5 relative h-max' style={{backgroundColor: 'white', maxWidth: '350px'}}>
                  <div
                    style={{
                      position: 'absolute',
                      top: '0%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: 'auto',
                      display: 'flex',
                    }}
                  >
                    <img src={item.logo} className='w-16 h-16' alt={item.role} />
                  </div>

                  <div className='flex flex-col justify-between h-full gap-2.5'>
                    <StyledComment>
                      {item.comment}
                    </StyledComment>
                    
                    <div className='flex flex-col'>
                      <Typography label={item.name} variant='f2' color={item.color} align='center' weight='bold'/>
                      <Typography label={item.description} variant='f1' color={colorTheme.dark} align='center'/>
                    </div>
                  </div>
                  
                  <div
                    style={{
                      borderRadius: '0px 12px 0px 12px',
                      padding: '2px 8px 2px 8px',
                      backgroundColor: item.color,
                      position: 'absolute',
                      bottom: '0%',
                      left: '0%',
                      width: 'auto',
                      display: 'flex',
                    }}
                  >
                    <Typography label={item.role} variant='f2' weight='semibold' color={colorTheme.light}/>
                  </div>
                </div>
            ))}

          </div>
        </StyledStoriesWrapper>
      }

      {isMobile &&
        <SimpleSlider/>
      }
    </>
    
  );
};

export default SuccessStories;
