import {Button, Icon, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {ChatContentMedia} from '../../../server/types';
import {StyledChatWrapper, StyledChatContent, StyledImage, StyledChatMessage} from './styles';
import axios from 'axios';
import {useDialog} from '../../../../core/components/dialog';
import ViewImageAttachment from '../../dialog-content/view-image-attachment/ViewImageAttachment';
import {getCallDuration, isUsingBrowser} from '../../../utils/random.util';
import {Directory, Filesystem} from '@capacitor/filesystem';
import {useToast} from '../../../../core/components/toast';
import {Browser} from '@capacitor/browser';
import {format} from 'date-fns';
import {useEffect, useState} from 'react';
import { RoutePath } from '../../../navigation/config/RouteConfig';
import { useHistory } from 'react-router-dom';
import { CallMeta } from '../../../server/types/conversation.types';
interface MyChatMessageProps {
  message?: string;
  meta?: string
  className?: string;
  date: Date;
  image?: string;
  media?: ChatContentMedia;
  uploading?: boolean;
  fileName?: string;
  isHomeowner?: boolean
  conversationId?: string
  isSubscribed?: boolean
  onCallClick?: (video: boolean) => void
}

const MyChatMessage = (props: MyChatMessageProps) => {
  const {presentDialog, dismissDialog} = useDialog();
  const {presentToast} = useToast();
  const history = useHistory();
  
  const [isSystemMsg, setIsSystemMsg] = useState<boolean>(false);
  const [isVideoCall, setIsVideoCall] = useState<boolean>(false);

  const download = () => {
    if (props.media) {
      if (props.media.contentType.includes('image')) {
        prewiewImage(props.media);
      } else {
        downloadFile(props.media);
      }
    }
  };

  const prewiewImage = (media: ChatContentMedia) => {
    presentDialog({
      headerText: '',
      content: (
        <ViewImageAttachment
          media={media}
          onClose={dismissDialog}
          onImageClicked={() => downloadFile(media)}
        />
      ),
      hideHeader: true,
    });
  };

  const downloadFile = async (media: ChatContentMedia) => {
    if (isUsingBrowser()) {
      axios({
        url: media.url,
        method: 'GET',
        responseType: 'blob', // important
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', props.media?.title!);
          document.body.appendChild(link);
          link.click();
          showSuccessToast();
        })
        .catch(e => {
          showErrorToast();
        });
    } else {
      // const response = await fetch(media.url);
      // // convert to a Blob
      // const blob = await response.blob();
      // // convert to base64 data, which the Filesystem plugin requires
      // const base64Data = await convertBlobToBase64(blob) as string;

      // await Filesystem.writeFile({
      //   path: props.media?.title!,
      //   data: base64Data,
      //   directory: Directory.Library
      // }).then(res => {
      //   showSuccessToast();
      // }).catch(async e => {
      //     await Browser.open({ url: media.url });
      // });
      await Browser.open({url: media.url});
    }
  };

  const showSuccessToast = () => {
    presentToast({
      message: 'Downloaded successfully.',
      variant: 'success',
      position: 'bottom',
    });
  };

  const showErrorToast = () => {
    presentToast({
      message: 'Download failed.',
      variant: 'error',
      position: 'bottom',
    });
  };

  const convertBlobToBase64 = (blob: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });

  useEffect(() => {
    if (
      props.message === 'KAZAM_SYSTEM_VIDEO_CALL' ||
      props.message === 'KAZAM_SYSTEM_VOICE_CALL'
    ) {
      setIsSystemMsg(true);
      setIsVideoCall(
        props.message === 'KAZAM_SYSTEM_VIDEO_CALL' ? true : false,
      );
    }
  }, [props.message]);

  const systemMsgClickHandler = () => {
    if (!isSystemMsg) return;

    if (props.conversationId) {
      if (isVideoCall) {
        history.push(
          RoutePath.VIDEO_CALL_ROOM.replace(':id', props.conversationId),
        );
      } else {
        history.push(
          RoutePath.VOICE_CALL_ROOM.replace(':id', props.conversationId),
        );
      }
    }
  };

  const callAgainHandler = () => {
    props?.onCallClick
      && props.onCallClick(isVideoCall)

    systemMsgClickHandler()
  }

  return (
    <StyledChatWrapper>
      <StyledChatContent
        style={{
          backgroundColor: isSystemMsg
            ? colorTheme.generatedMessage
            : colorTheme.primary,
        }}>
        <div className="flex">
          {isSystemMsg && (
            <>
              <div
                className="h-8 w-8 flex self-center items-center justify-center rounded-full mr-4"
                style={{
                  backgroundColor: colorTheme.light,
                }}>
                <Icon
                  name={isVideoCall ? 'videoCall' : 'voiceCall'}
                  color={props.isHomeowner ? colorTheme.darkFaded : colorTheme.primary}
                  size={24}
                  type="button"
                />
              </div>
            </>
          )}
          <div>
            <div>
              {!isSystemMsg ? (
                <StyledChatMessage dangerouslySetInnerHTML={{
                  __html:
                    props.message?.replaceAll('\n', '<br />') || "",
                }} />
              ) : (
                <Typography
                  className="spaced-left"
                  label={isVideoCall ? 'Video call' : 'Voice call'}
                  variant="f1"
                  align="right"
                  color={colorTheme.dark}
                />
              )}

              {props.fileName ? (
                <div className="flex space-x-2 items-center cursor-pointer">
                  <Icon name="paperclip" size={16} color={colorTheme.white} />
                  <Typography
                    className="spaced-left"
                    label={props.fileName}
                    variant="f1"
                    weight="semibold"
                    align="right"
                    color={colorTheme.white}
                  />
                </div>
              ) : (
                props.image && (
                  <StyledImage
                    className="cursor-pointer"
                    src={props.image}></StyledImage>
                )
              )}

              {props.media &&
                (props.media.contentType.includes('image') ? (
                  <StyledImage
                    className="cursor-pointer"
                    src={props.media.url}
                    onClick={download}></StyledImage>
                ) : (
                  <div
                    className="flex space-x-2 items-center cursor-pointer"
                    onClick={download}>
                    <Icon name="paperclip" size={16} color={colorTheme.white} />
                    <Typography
                      className="spaced-left"
                      label={props.media.title}
                      variant="f1"
                      weight="semibold"
                      align="right"
                      color={colorTheme.white}
                    />
                  </div>
                ))}
            </div>

            <div className="mt-1 flex space-x-1">
              <Typography
                className="spaced-left"
                label={
                  props.uploading
                    ? `Uploading...`
                    : `${format(props.date, 'PP')} ${format(props.date, 'p')}`
                }
                variant="f1"
                weight="light"
                align="right"
                color={isSystemMsg ? colorTheme.darkFaded : colorTheme.white}
              />

              {isSystemMsg && props?.meta && 
                <>
                  <Typography
                    className="bullet"
                    label="•"
                    variant="f1"
                    weight="semibold"
                    color={colorTheme.lightGray}
                  />

                  <Typography
                    className="spaced-left"
                    label={getCallDuration(props.meta)}
                    variant="f1"
                    weight="light"
                    align="right"
                    color={colorTheme.darkFaded}
                  />
                </>
              }
            </div>
          </div>
        </div>

        {isSystemMsg
          && props.isHomeowner &&
          <div className="mt-1">
            <Button
              label="Call again"
              disabled={!props.isSubscribed}
              onClick={callAgainHandler}/>
          </div>
        }
      </StyledChatContent>
    </StyledChatWrapper>
  );
};

export default MyChatMessage;
