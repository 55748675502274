import React, {useEffect, useState} from 'react';
import {DurationInput, QuantityInput, Toggle, Typography} from '../../../../core/components';
import {StyledWrapper} from './styled';
import {colorTheme} from '../../../../core/configs';
import {usePutAdminControl} from '../../../server/react-query';
import {useToast} from '../../../../core/components/toast';
import {
  AdminControl,
  AdminControlReqBody,
  AdminControlType,
  DurationContent,
  QuantityContent,
} from '../../../server/types/admin-control.types';
import Divider from '../../../../core/components/divider/Divider';
import { KazamException } from '../../../server/types';

interface KazamSettingsViewProps {
  data: AdminControl[];
}

const KazamSettingsItem = ({
  item,
  label,
  description,
  inputLabel
}: {
  item?: AdminControl,
  label: string,
  description: string
  inputLabel: string
}) => {
  const {presentToast} = useToast();

  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [limit, setLimit] = useState<QuantityContent>();
  const [error, setError] = useState<string>();
  const [control, setControl] = useState<AdminControl | undefined>()

  const putAdminControl = usePutAdminControl();

  useEffect(() => {
    if (item) {
      setControl(item)
      setIsEnabled(item?.enabled)
      if (item.content) {
        var limit = JSON.parse(
          item?.content.toLowerCase(),
        ) as QuantityContent;
        setLimit(limit);
      }
    }
  }, [item])

  useEffect(() => {
    if (control) {
      if (control.content) {
        var limit = JSON.parse(
          control?.content.toLowerCase(),
        ) as QuantityContent;
        setLimit(limit);
      }
    }
  }, [control])

  const onSave = (value: QuantityContent) => {
    if (control) {
      const data: AdminControlReqBody = {
        type: control.type,
        enabled: isEnabled,
        content: JSON.stringify(value)
      };
      
      onPutAdminControl(data);
    }
  };

  const handleToggle = (toggled: boolean) => {
    setIsEnabled(toggled)    
    if (control) {
      const data: AdminControlReqBody = {
        type: control.type,
        enabled: toggled,
        content: control.content,
      };
      
      onPutAdminControl(data);
    }
  }

  const onPutAdminControl = async (data: AdminControlReqBody) => {
    try {
      const res = await putAdminControl.mutateAsync(data);
      setControl(res)
      setError("");
      presentToast({
        message: `Successfully saved.`,
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      console.log(e);
      const exception = e.data as KazamException;

      if (exception) {
        if (e.status
          && e.status === 403
          && exception.errorCode === 'SalaryLimitError') {
          setError(exception.error);
        }

        presentToast({
          message: exception.error,
          variant: 'error',
          position: 'bottom',
        });
      } else {
        presentToast({
          message: "Failed to save.",
          variant: 'error',
          position: 'bottom',
        });
      }
    }
  };

  return (
    <>
      <div className="pt-3 flex flex-row justify-between items-center">
        <div>
          <Typography
            label={label}
            variant="f3"
            color={isEnabled ? colorTheme.dark : colorTheme.lightGray}
          />
          <Typography
            label={description}
            variant="f1"
            color={
              isEnabled ? colorTheme.darkFaded : colorTheme.lightGray
            }
          />
        </div>
        <div>
          <Toggle value={isEnabled} onToggle={handleToggle} />
        </div>
      </div>

      {isEnabled && (
        <>
          <div className="pt-3">
            <div className="pt-3">
              <QuantityInput
                label={inputLabel}
                quantity={limit}
                onSubmit={onSave}
                isLoading={putAdminControl.isLoading}
                error={error}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
  
}

const KazamSettingsView = ({data}: KazamSettingsViewProps) => {
  const {presentToast} = useToast();

  const [isLoadingHiddenKazam, setIsLoadingHiddenKazam] = useState(false);
  const [isLoadingOnlineThreshold, setIsLoadingOnlineThreshold] = useState(
    false,
  );
  const [isLoadingOfflineThreshold, setIsLoadingOfflineThreshold] = useState(
    false,
  );
  const [
    isLoadingEmailNotifInterval,
    setIsLoadingEmailNotifInterval,
  ] = useState(false);

  const [
    kazamHiddenDuration,
    setKazamHiddenDuration,
  ] = useState<DurationContent>();
  const [
    onlineStatusThreshold,
    setOnlineStatusThreshold,
  ] = useState<DurationContent>();
  const [
    offlineStatusThreshold,
    setOfflineStatusThreshold,
  ] = useState<DurationContent>();
  const [
    emailNotifInterval,
    setEmailNotifInterval,
  ] = useState<DurationContent>();

  const putAdminControl = usePutAdminControl();

  useEffect(() => {
    if (data) {
      var hiddenKazamDuration = data.find(
        d => d.type === 'hiddenKazamDuration',
      );

      if (hiddenKazamDuration) {
        var duration = JSON.parse(
          hiddenKazamDuration?.content.toLowerCase(),
        ) as DurationContent;
        setKazamHiddenDuration(duration);
      }

      var onlineThreshold = data.find(d => d.type === 'onlineStatusThreshold');

      if (onlineThreshold) {
        var duration = JSON.parse(
          onlineThreshold?.content.toLowerCase(),
        ) as DurationContent;
        setOnlineStatusThreshold(duration);
      }

      var offlineThreshold = data.find(
        d => d.type === 'offlineStatusThreshold',
      );

      if (offlineThreshold) {
        var duration = JSON.parse(
          offlineThreshold?.content.toLowerCase(),
        ) as DurationContent;
        setOfflineStatusThreshold(duration);
      }

      var emailNotifInterval = data.find(
        d => d.type === 'emailNotificationInterval',
      );

      if (emailNotifInterval) {
        var duration = JSON.parse(
          emailNotifInterval?.content.toLowerCase(),
        ) as DurationContent;
        setEmailNotifInterval(duration);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSaveHiddenKazamDuration = (value: DurationContent) => {
    const data: AdminControlReqBody = {
      type: 'hiddenKazamDuration',
      enabled: true,
      content: JSON.stringify(value),
    };
    setIsLoadingHiddenKazam(true);
    onPutAdminControl(data);
  };

  const onSaveOnlineThreshold = (value: DurationContent) => {
    const data: AdminControlReqBody = {
      type: 'onlineStatusThreshold',
      enabled: true,
      content: JSON.stringify(value),
    };
    setIsLoadingOnlineThreshold(true);
    onPutAdminControl(data);
  };

  const onSaveOfflineThreshold = (value: DurationContent) => {
    const data: AdminControlReqBody = {
      type: 'offlineStatusThreshold',
      enabled: true,
      content: JSON.stringify(value),
    };
    setIsLoadingOfflineThreshold(true);
    onPutAdminControl(data);
  };

  const onSaveEmailNotifInterval = (value: DurationContent) => {
    const data: AdminControlReqBody = {
      type: 'emailNotificationInterval',
      enabled: true,
      content: JSON.stringify(value),
    };
    setIsLoadingEmailNotifInterval(true);
    onPutAdminControl(data);
  };

  const onPutAdminControl = async (data: AdminControlReqBody) => {
    try {
      await putAdminControl.mutateAsync(data);

      setIsLoadingHiddenKazam(false);
      setIsLoadingOnlineThreshold(false);
      setIsLoadingOfflineThreshold(false);
      setIsLoadingEmailNotifInterval(false);
      presentToast({
        message: `Successfully saved.`,
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      setIsLoadingHiddenKazam(false);
      setIsLoadingOnlineThreshold(false);
      setIsLoadingOfflineThreshold(false);
      setIsLoadingEmailNotifInterval(false);
      console.log(e);
    }
  };

  const getAdminControl = (type: AdminControlType) => {
    return data.find(d => d.type === type);
  }

  return (
    <StyledWrapper>
      <Typography
        label="KAZAM SETTINGS"
        variant="f1"
        weight="semibold"
        color={colorTheme.black}
      />
      <div>
        <div className="pt-3 flex flex-row justify-between items-center">
          <div>
            <Typography
              label="Hidden duration"
              variant="f3"
              color={colorTheme.dark}
            />
            <Typography
              label="Set the length of time for hidden profiles to remain hidden. The default value is 14 days. "
              variant="f1"
              color={colorTheme.darkFaded}
            />
          </div>
        </div>
        <div className="pt-3">
          <DurationInput
            duration={kazamHiddenDuration}
            onSubmit={onSaveHiddenKazamDuration}
            isLoading={isLoadingHiddenKazam}
          />
        </div>
      </div>

      <Divider />

      <div>
        <div className="flex flex-row justify-between items-center">
          <div>
            <Typography
              label="Online Recently Status Threshold"
              variant="f3"
              color={colorTheme.dark}
            />
            <Typography
              label="Set the length of time before user status becomes online recently from being online now. The default value is 2 minutes. "
              variant="f1"
              color={colorTheme.darkFaded}
            />
          </div>
        </div>
        <div className="pt-3">
          <DurationInput
            timeOnly
            duration={onlineStatusThreshold}
            onSubmit={onSaveOnlineThreshold}
            isLoading={isLoadingOnlineThreshold}
          />
        </div>
      </div>

      <Divider />

      <div>
        <div className="flex flex-row justify-between items-center">
          <div>
            <Typography
              label="Offline Status Threshold"
              variant="f3"
              color={colorTheme.dark}
            />
            <Typography
              label="Set the length of time before user status becomes offline from being online recently. The default value is 15 minutes. "
              variant="f1"
              color={colorTheme.darkFaded}
            />
          </div>
        </div>
        <div className="pt-3">
          <DurationInput
            timeOnly
            duration={offlineStatusThreshold}
            onSubmit={onSaveOfflineThreshold}
            isLoading={isLoadingOfflineThreshold}
          />
        </div>
      </div>

      <Divider />

      <div>
        <div className="flex flex-row justify-between items-center">
          <div>
            <Typography
              label="Email Notification Interval Control"
              variant="f3"
              color={colorTheme.dark}
            />
            <Typography
              label="Set the interval of sending email notifications to Kazam. The default value is 1 day."
              variant="f1"
              color={colorTheme.darkFaded}
            />
          </div>
        </div>
        <div className="pt-3">
          <DurationInput
            duration={emailNotifInterval}
            onSubmit={onSaveEmailNotifInterval}
            isLoading={isLoadingEmailNotifInterval}
          />
        </div>
      </div>

      <Divider />

      <KazamSettingsItem
        item={getAdminControl('hiredLockDuration')}
        label="Hired lock duration"
        description="Set the duration on how long the chat feature to kasambahay is locked from the acceptance of the contract."
        inputLabel='Days'
      />
      
    </StyledWrapper>
  );
};

export default KazamSettingsView;
