import React from 'react';
import {Icon, Typography} from '../../../core/components';
import {useHistory} from 'react-router-dom';
import {colorTheme} from '../../../core/configs';

const CallEndedActionComponent = () => {
  const history = useHistory();

  const goBack = () => {
    history.go(-1);
  };

  return (
    <div
      className="flex flex-col items-center w-screen gap-y-4"
      onClick={goBack}>
      <div
        className="rounded-full p-4 cursor-pointer"
        style={{
          backgroundColor: colorTheme.lightGray,
        }}>
        <Icon name="x" size={40} type="button" />
      </div>
      <Typography
        label="Close"
        variant="f3"
        weight="normal"
        color={colorTheme.white}
        singleLine
      />
    </div>
  );
};

export default CallEndedActionComponent;
