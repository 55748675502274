import React, {useEffect, useState} from 'react';
import Page from '../../../core/components/page/Page';
import {UserResponse} from '../../server/types';
import KazamProfileView from '../../views/cards/kazam-profile/KazamProfileView';
import {useParams} from 'react-router-dom';
import {useGetUserById} from '../../server/react-query';

interface JobSeekerProfileViewPageParams {
  id: string;
}

const JobSeekerProfileViewPage = () => {
  const [userData, setUserData] = useState<UserResponse | undefined>(undefined);
  const {id: userId} = useParams<JobSeekerProfileViewPageParams>();
  const {data, isFetching} = useGetUserById(userId);

  useEffect(() => {
    if (data) {
      setUserData({...data});
    }
  }, [data]);

  return (
    <Page showLoading={isFetching} showBackButton showDesktopBackButton>
      <KazamProfileView profile={userData} />
    </Page>
  );
};

export default JobSeekerProfileViewPage;
