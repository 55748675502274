import {Icon, Typography} from '../../../core/components';
import {colorTheme} from '../../../core/configs';

interface SocialMediaProps {
  hideHeader?: boolean;
  color?: string;
}

const SocialMedia = ({
  hideHeader = false,
  color = colorTheme.darkFaded
}: SocialMediaProps) => {
  const goToSNS = (url: string) => {
    window.open(url);
  };

  return (
    <div className="space-y-2">
      {!hideHeader && 
        <Typography
          label="Social Media Channels"
          variant="f1"
          weight="semibold"
          color={colorTheme.dark}
        />
      }
      
      <div className="flex flex-row space-x-4 items-center">
        <Icon
          type="button"
          name="facebook"
          size={24}
          color={color}
          onClick={() => goToSNS('https://www.facebook.com/kazamph/')}
        />
        <Icon
          type="button"
          name="instagram"
          size={24}
          color={color}
          onClick={() => goToSNS('https://www.instagram.com/kazamph/')}
        />
        <Icon
          type="button"
          name="twitter"
          size={24}
          color={color}
          onClick={() => goToSNS('https://twitter.com/kazamph')}
        />
      </div>
    </div>
  );
};

export default SocialMedia;
