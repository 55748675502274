import {isIOS, isMobile} from 'react-device-detect';
import styled, {css} from 'styled-components';
import {colorTheme} from '../../../core/configs';

export const StyledChatRoomPage = styled.div`
  background: ${colorTheme.darkFaded};

  & .sub-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    cursor: pointer;
    align-items: center;
  }

  & .col-1 {
    position: relative;
  }

  & .col-2 {
    padding: 0 8px;
    max-width: 230px;
  }

  & .col-3 {
    width: 24px;
    height: 24px;
  }

  & .avatar {
    width: 55px;
    height: 55px;
    border-radius: 30px;
    border-width: 1px;
    border-color: white;
    object-fit: cover !important;
  }

  & .online-status {
    width: 12px;
    height: 12px;
    position: absolute;
    float: bottom;
    bottom: 3px;
    right: 3px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
  }

  & .bullet {
    padding: 0 4px;
  }

  & .content {
    max-height: calc(100% - 145px);
    height: 100%;
  }
`;

export const StyledChatContentPage = styled.div`
  ${isMobile
    ? css`
        max-height: calc(100% - 200px);
      `
    : css`
        max-height: calc(100vh - 220px);
      `}
  height: 100%;
  overflow: auto;
  margin-top: 78px;
  margin-bottom: 65px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const StyledChatRoomMenu = styled.div`
  position: relative;

  & .overlay {
    position: fixed;
    float: left;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: 100;
  }

  & .menu-list {
    position: absolute;
    float: right;
    right: 0;
    top: 24px;
    width: 200px;
    z-index: 101;
  }

  & .menu-item:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  & .menu-item:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export const StyledChatRoomMenuItem = styled.div`
  padding: 10px 16px;
  background-color: white;

  :hover {
    background-color: ${colorTheme.lightGray};
  }

  & .menu-label {
    margin-left: 8px;
  }
`;

interface StyledChatTestProps {
  notSubscribed?: boolean
}
export const StyledChatTest = styled.div<StyledChatTestProps>`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  ${
    isMobile
      ? css`
          height: 100%;
        `
      : css`
          height: calc(100% - 66px);
        `
  }

  & .nav {
    height: 56px;
    background: red;
  }

  & .content {
    flex: 1 1 0%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  & .chats {
    flex: 1 1 0%;
    overflow-y: scroll;
    margin-top: 78px;
  }

  & .chat-input {
    ${p =>
      isMobile
        ? css`
            height:  ${p.notSubscribed ? '120px' : '48px'}
          `
        : css`
            height:  ${p.notSubscribed ? '24px' : '0'}
          `}
  }

  & .chat-input textarea {
    height: 100%;
  }
`;

interface StyledChatScrollableProps {
  isNotScrollable: boolean;
}

export const StyledChatScrollable = styled.div<StyledChatScrollableProps>`
  ${p =>
    p.isNotScrollable &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    `}
`;

export const StyledSendJobOffer = styled.div`

  position: ${isIOS ? 'fixed' : 'absolute'};;
  right: 20px;
  bottom: ${isIOS ? '120px' : '100px'};
  border: 1px solid ${colorTheme.tint};
  padding: 5px;
  border-radius: 40px;
  cursor:pointer;
  background-color:${colorTheme.white};

  & .round-layout {
      border: 1px solid ${colorTheme.tint};
      padding: 5px;
      border-radius: 35px;
      padding-left: 12px;
      padding-top: 10px;
      padding-bottom: 5px;
      padding-right: 10px;
  }

  & .btn-txt{
    padding-top: 5px;
    padding-bottom: 5px;
  }

`;

export const StyledOfferCard = styled.div`

    display: flex;
    width: 100%;
    justify-content:flex-end;


    & .offer-details{
        background-color:${colorTheme.lightGray};
        display: table-footer-group;
        padding: 10px;
        width: 320px;
        border-radius: 10px;
        font-size: 0.8rem;
        color:${colorTheme.black};
    }

    & .txt-heading{
      font-weight: 600;
    }

    & .offer-primary-btn{
      background-color: ${colorTheme.light};
      color: ${colorTheme.black};
      font-weight: 600;
    }

    & .offer-secondary-btn{
      background-color: ${colorTheme.darkFaded};
      color: ${colorTheme.white};
      font-weight: 600600;
    }

    & .offer-msg-time span {
        color: ${colorTheme.darkFaded};
        font-weight: 100;
      }

    & .offer-approval-status {
      color: ${colorTheme.primary};
      font-weight: 600;
    }

`;

export const StyledChatMessageMenu = styled.div`
  color: black;
  position: fixed;
  bottom: ${isIOS ? '105px' : '70px'};
  ${isMobile
    ? css`
        left: 10px;
      `
    : css`
        left: 310px;
    `}
  background: ${colorTheme.light};
  padding: 10px;
  border-radius: 10px;
  border: 1px solid ${colorTheme.seashell};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding-left: 5px;


  & .message-menu-options li , .message-menu-options li label{
      padding: 10px;
      color: ${colorTheme.darkFaded};
      cursor:pointer;
  }

  & .message-menu-options li:last-child{
      padding-bottom: 5px;
  }

  & .message-menu-options li:hover {
      background: ${colorTheme.seashell};
      border-radius: 10px;
  }


`;