import styled from 'styled-components';
import {colorTheme} from '../../../../core/configs';

export const StyledLabel = styled.div`
  font-size: 11px;
  font-style: italic;
  color: ${colorTheme.darkFaded};
  text-align: center;
`;

export const StyledLink = styled.div`
  cursor: pointer;
  font-size: 11px;
  color: ${colorTheme.primary};
`;

export const StyledLinkSelfie = styled.div`
  cursor: pointer;
  font-size: 11px;
  color: ${colorTheme.magenta};
`;

export const StyledLinkTwo = styled.div`
  cursor: pointer;
  font-size: 11px;
  color: ${colorTheme.magenta};
`;
