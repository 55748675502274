import {useState} from 'react';
import {colorTheme} from '../../../core/configs';
import {StarFilled, Star} from '../../../core/assets/icons/components';
import { Icon } from '../../../core/components';

interface RatingProps {
  rating: number;
  size?: number
  readonly?: boolean
  onRate?: (rating: number) => void;
}

const Rating = ({
  rating,
  size = 12,
  readonly =  false,
  onRate
}: RatingProps) => {
  const [ratingValue, setRatingValue] = useState(rating);
  const [totalStars, _] = useState(5);

  const onRateChange = (rating: number) => {
    if (!readonly) {
      onRate && onRate(rating);
      setRatingValue(rating);
    }
  };

  return (
    <div className="flex flex-row">
      {[...Array(totalStars)].map((star, index) => {
        const currentRating = index + 1;

        return (
          <div onClick={() => onRateChange(currentRating)}>
            {currentRating <= ratingValue ? (
              <Icon name='starFilled' size={size}/>
            ) : (
              <Icon name='star' size={size}/>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Rating;
