import React from "react";
import { StyledBackdrop } from "./styled";

interface BackdropProps {
    onClick: () => void
}

const Backdrop = (props: BackdropProps) => {
    return <StyledBackdrop onClick={props.onClick} />
};

export default Backdrop;
