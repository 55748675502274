import {Icon, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {UserResponse} from '../../../server/types';
import {StyleAdminIcon, StyledDiv} from './styled';
import {isMobile} from 'react-device-detect';
import {useHistory} from 'react-router-dom';
import KazamIcon from '../../../../assets/kazam-icon-transparent.png';

interface AdminChatProfileViewProps {
  profile: UserResponse;
}

const AdminChatProfileView = ({profile}: AdminChatProfileViewProps) => {
  const history = useHistory();

  const backButtonClickHandler = () => {
    console.log('back');
    history.go(-1);
  };

  return (
    <StyledDiv className={`${isMobile ? "floating-card" : ""}`}>
      <div className="flex flex-row w-full items-center space-x-2">
        {!isMobile && (
          <div>
            <Icon
              type="button"
              name="arrowLeft"
              size={24}
              color={colorTheme.dark}
              onClick={backButtonClickHandler}
            />
          </div>
        )}
        <div className="profile-picture">
          <div className="avatar flex justify-center items-center">
            <img src={KazamIcon} alt="profile" />
          </div>
        </div>

        <div className="flex justify-between items-center space-x-2 w-full flex1">
          <div className="flex flex-col w-full flex1">
            <div className="flex flex-row items-center space-x-1">
              <Typography
                label={profile?.firstName || 'Name'}
                variant="f2"
                weight="semibold"
                color={colorTheme.dark}
                singleLine
              />
              <StyleAdminIcon className="flex items-center justify-center">
                <Icon name="check" size={10} color={colorTheme.white} />
              </StyleAdminIcon>
            </div>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};

export default AdminChatProfileView;
