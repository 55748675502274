import {yupResolver} from '@hookform/resolvers/yup';
import {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {
  Button,
  DatePicker,
  Icon,
  Input,
  Typography,
} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {postVoucherSchema} from '../../../schema/admin.schema';
import {StyledLabel, StyledTargetComponent, SyledContent} from './styles';
import { useGetSubscription } from '../../../server/react-query/useSubscription';
import startOfDay from 'date-fns/startOfDay';
import { RadioButton, RadioButtonFilled } from '../../../../core/assets/icons/components';
import { PostVoucherBody, VoucherDiscountType } from '../../../server/types/voucher.types';
import { useGetVoucher, usePutVoucher } from '../../../server/react-query/useVoucher';
import { KazamException } from '../../../server/types';

interface UpdateVoucherModalViewProps {
  voucherId: string;
  onClose: () => void;
  onCancel: () => void;
  onUpdated: () => void;
  onError?: (message: string) => void;
}

const UpdateVoucherModalView = (props: UpdateVoucherModalViewProps) => {
  const {data, isFetching} = useGetVoucher(props.voucherId);
  const [selectedDiscountType, setSelectedDiscountType] = useState<VoucherDiscountType>(
    'amountOff'
  );

  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    setError,
    reset,
    formState: {errors},
  } = useForm<PostVoucherBody>({
    resolver: yupResolver(postVoucherSchema),
    defaultValues: {
      name: '',
      discountType: selectedDiscountType,
    },
  });

  useEffect(() => {
    if (data && !isFetching) {
      if (data.startDate)data.startDate = new Date(data.startDate)
      if (data.endDate) data.endDate = startOfDay(new Date(data.endDate))
      setSelectedDiscountType(data.discountType);
      
      reset({...data});
    }
  }, [data, isFetching]);

  const submitForm = async () => {
    handleSubmit(handleCreate)();
  };

  const putVoucher = usePutVoucher(getValues(), props.voucherId);
  const handleCreate = async () => {
    try {
      await putVoucher.mutateAsync();
      props.onUpdated();
    } catch (e: any) {
      console.log('error', e);
      const exception = e.data as KazamException;
      props.onError && props.onError(exception ? exception.error : "Failed to save.")
    }
  };

  const onSelectDiscountType = (value: VoucherDiscountType) => {
    setSelectedDiscountType(value);
    setValue('discountType', value)
  };

  return (
    <div className="flex flex-col items-center px-4 pb-4 space-y-2">
      <div className="flex w-full justify-between items-center">
        <Typography
          label="Edit Voucher"
          variant="f3"
          weight="semibold"
          color={colorTheme.dark}
        />
        <Icon
          name="x"
          color={colorTheme.dark}
          size={24}
          type="button"
          onClick={props.onClose}
          disabled={putVoucher.isLoading}
        />
      </div>

      <SyledContent>
        <div className="space-y-2">
          <div className="flex justify-between space-x-6">
            <div className='w-full'>
              <Controller
                control={control}
                name="name"
                render={({field: {value = '', onBlur, onChange}}) => (
                  <Input
                    label="Name"
                    inputSize="large"
                    placeholder="Enter name"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={errors.name?.message}
                  />
                )}
              />
            </div>
            <div className="w-full">
              <Controller
                control={control}
                name="code"
                render={({field: {value = '', onBlur, onChange}}) => (
                  <Input
                    label="Code"
                    inputSize="large"
                    placeholder="Enter code"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={errors.code?.message}
                  />
                )}
              />
            </div>
          </div>

          <StyledTargetComponent>
            <StyledLabel data-testid="label-element">Discount type</StyledLabel>
            <div className="flex space-x-4">
              <div
                className="cursor-pointer"
                style={{width: 'fit-content'}}
                onClick={() => onSelectDiscountType('amountOff')}>
                <div className="flex flex-row items-center">
                  {selectedDiscountType === 'amountOff' ? (
                    <RadioButtonFilled
                      className="radio-button"
                      color={colorTheme.dark}
                    />
                  ) : (
                    <RadioButton
                      className="radio-button"
                      color={colorTheme.dark}
                    />
                  )}
                  <Typography
                    label="Amount off"
                    variant="f2"
                    color={colorTheme.dark}
                  />
                </div>
              </div>

              <div
                className="cursor-pointer"
                style={{width: 'fit-content'}}
                onClick={() => onSelectDiscountType('percentageOff')}>
                <div className="flex flex-row items-center">
                  {selectedDiscountType === 'percentageOff' ? (
                    <RadioButtonFilled
                      className="radio-button"
                      color={colorTheme.dark}
                    />
                  ) : (
                    <RadioButton
                      className="radio-button"
                      color={colorTheme.dark}
                    />
                  )}
                  <Typography
                    label="Percentage off"
                    variant="f2"
                    color={colorTheme.dark}
                  />
                </div>
              </div>
            </div>
          </StyledTargetComponent>

          <div className="w-full">
            <Controller
              control={control}
              name="discountValue"
              render={({field: {value = '', onBlur, onChange}}) => (
                <Input
                  label="Discount Value"
                  inputSize="large"
                  placeholder="Enter value"
                  value={
                    typeof value === 'number'
                      ? value
                      : isNaN(parseFloat(value as string))
                      ? ''
                      : parseFloat(value as string)
                  }
                  onBlur={onBlur}
                  onChange={onChange}
                  error={errors.discountValue?.message}
                />
              )}
            />
          </div>

          <div className="flex justify-between space-x-6">
            <div className="w-full">
              <Controller
                control={control}
                name="usesLimit"
                render={({field: {value = '', onBlur, onChange}}) => (
                  <Input
                    label="Limit number of uses"
                    inputSize="large"
                    placeholder="Enter number"
                    value={
                      typeof value === 'number'
                        ? value
                        : isNaN(parseFloat(value as string))
                        ? ''
                        : parseFloat(value as string)
                    }
                    onBlur={onBlur}
                    onChange={onChange}
                    error={errors.usesLimit?.message}
                  />
                )}
              />
            </div>
            <div className="w-full">
              <Controller
                control={control}
                name="usesPerUserLimit"
                render={({field: {value = '', onBlur, onChange}}) => (
                  <Input
                    label="Limit number of uses per user"
                    inputSize="large"
                    placeholder="Enter number"
                    value={
                      typeof value === 'number'
                        ? value
                        : isNaN(parseFloat(value as string))
                        ? ''
                        : parseFloat(value as string)
                    }
                    onBlur={onBlur}
                    onChange={onChange}
                    error={errors.usesPerUserLimit?.message}
                  />
                )}
              />
            </div>
          </div>

          <div className="flex justify-between space-x-6">
            <div className="w-full">
              <Controller
                control={control}
                name="startDate"
                render={({field: {value, onBlur, onChange}}) => (
                  <DatePicker
                    label="Start date"
                    placeholder="mm/dd/yyyy"
                    value={value}
                    onChange={(v) => setValue('startDate', v ?? undefined)}
                    error={errors.startDate?.message}
                    enableFutureDates={true}
                    datePickerFontSize={7}
                    addClear
                  />
                )}
              />
            </div>
            <div className="w-full">
              <Controller
                control={control}
                name="endDate"
                render={({field: {value, onBlur, onChange}}) => (
                  <DatePicker
                    label="End date"
                    placeholder="mm/dd/yyyy"
                    value={value}
                    onChange={(v) => setValue('endDate', v ?? undefined)}
                    error={errors.endDate?.message}
                    enableFutureDates={true}
                    datePickerFontSize={7}
                    addClear
                  />
                )}
              />
            </div>
          </div>
        </div>
      </SyledContent>
      <div className="w-full flex flex-row space-x-2 pt-4">
        <Button
          label="Cancel"
          variant="outlined"
          className="flex-1"
          onClick={props.onCancel}
          disabled={putVoucher.isLoading}
        />
        <div className="spacer" />
        <Button
          label="Update"
          color="primary"
          className="flex-1"
          onClick={submitForm}
          isLoading={putVoucher.isLoading}
        />
      </div>
    </div>
  );
};

export default UpdateVoucherModalView;
