import Page from '../../../core/components/page/Page';
import {Button, Select, Typography} from '../../../core/components';
import {colorTheme} from '../../../core/configs';
import {languageList} from '../../server/types';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {localization} from '../../localization/Localization';
import BannerAd from '../../views/ads/banner-ad/BannerAd';
import {useAuth} from '../../hooks/useAuth';

const LanguageSettingsPage = () => {
  const history = useHistory();
  const {authData} = useAuth();
  const [language, setLanguage] = useState<string>('English');
  const [localizationKey, setLocalizationKey] = useState<string>('en');

  useEffect(() => {
    var key = localStorage.getItem('language');

    if (key) {
      setLanguage(getSelectedLanguage(key));
    } else {
      localStorage.setItem('language', localizationKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLanguageChange = (val?: string) => {
    if (val) {
      setLanguage(val);
    }
  };

  const onSave = () => {
    localization.setLanguage(getLocalizationKey());
    localStorage.setItem('language', getLocalizationKey());
    history.go(-1);
  };

  const getLocalizationKey = (): string => {
    if (language === 'English') {
      return 'en';
    } else {
      return 'ph';
    }
  };

  const getSelectedLanguage = (key: string): string => {
    if (key === 'en') {
      return 'English';
    } else {
      return 'Taglish';
    }
  };
  return (
    <Page showBackButton showDesktopBackButton>
      <div className="space-y-4">
        <div>
          <Typography
            label={localization.hdr_lang_settings}
            variant="f3"
            align="center"
            color={colorTheme.dark}
          />
          <div className="pt-1">
            <Typography
              label={localization.desc_select_lang}
              variant="f1"
              align="center"
              color={colorTheme.darkFaded}></Typography>
          </div>
        </div>

        <div className="pt-4 space-y-6">
          <Select
            label={localization.lbl_language}
            placeholder={localization.ph_select_lang}
            items={languageList}
            itemRenderer={(item, selected) => (
              <Typography
                label={item}
                variant="f1"
                color={colorTheme.dark}
                weight={selected ? 'semibold' : 'normal'}
                className="flex-1"
              />
            )}
            value={language}
            onChange={onLanguageChange}
            valueRenderer={value => value}
          />
          {authData && (
            <div>
              <BannerAd />
            </div>
          )}

          <div>
            <Button
              label={localization.btn_save}
              color="primary"
              onClick={onSave}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default LanguageSettingsPage;
