import styled, {css, keyframes} from 'styled-components';
import {colorTheme} from '../../configs';
import {isMobile} from 'react-device-detect';

export const StyledNavBarWrapper = styled.div`
  width: 100%;

  & .navBar {
    position: fixed;
    width: 100%;
    height: 56px;
    background: white;
    border-bottom: 1px solid #e2e2e2;
    top: 0;
    left: 0;
    z-index: 10;
    ${!isMobile &&
    css`
      height: 66px !important;
    `}
  }

  & .navBar nav {
    display: flex;
    align-content: center;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
  }

  & .navBar nav .navBarItem {
    min-width: 24px;
  }

  & .items {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }

  ${isMobile &&
  css`
    .absolute-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `}

  & .navBar .logo-wrapper img {
    height: 29px;
    cursor: pointer;

    ${!isMobile &&
    css`
      height: 48px !important;
    `}
  }
`;

export const StyledDrawerToggleButton = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const StyledBackButton = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const StyledOutline = styled.div`
  border: 1px solid ${colorTheme.lightGray};
  border-radius: 4px;
  padding: 4px;
`;

const animateSpin = keyframes`
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
`;

export const StyledLoader = styled.div`
  border-radius: 50%;
  border: 2px solid ${colorTheme.darkFaded};
  width: 24px;
  height: 24px;

  -webkit-animation: ${animateSpin} 0.7s linear infinite; /* Safari */
  animation: ${animateSpin} 0.7s linear infinite;
`;
