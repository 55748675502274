import {colorTheme} from '../../../core/configs';

export interface WizardStepProps {
  steps: number;
  currentStep: number;
}

const WizardStep = (props: WizardStepProps) => {
  const {steps, currentStep} = props;

  const items = [];

  for (let i = 0; i < steps; i++) {
    items.push({
      color: i < currentStep ? colorTheme.primary : colorTheme.lightGray,
    });
  }

  return (
    <div className="flex space-x-2 justify-between">
      {items.map((step, i) => {
        return (
          <div
            key={i}
            className="w-full"
            style={{
              height: '8px',
              borderRadius: '4px',
              backgroundColor: step.color,
            }}></div>
        );
      })}
    </div>
  );
};

export default WizardStep;
