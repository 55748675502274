import {Button, Icon, Typography} from '../../../../../core/components';
import {colorTheme} from '../../../../../core/configs';
import illustration from '../../../../../assets/subscription.svg';
import {StyledImg} from './styles';
import {localization} from '../../../../localization/Localization';
import { UserSubscriptionResponse } from '../../../../server/types';
import format from 'date-fns/format';

interface DialogProps {
  data?: UserSubscriptionResponse
  onContinueClick: () => void;
}

const SuccessfulPaymentDialogView = (props: DialogProps) => {

  const getDesc = (): string => {
    let desc = "Your subscription has no expiration.";

    if (props.data?.expiresOn) {
      desc = `Your subscription will expire on 
        ${format(new Date(props.data?.expiresOn ?? ""), 'PP')} at 
        ${format(new Date(props.data?.expiresOn ?? ""), 'p')}.`
    }

    return desc
  }
  return (
    <div className="flex flex-col items-center p-4 space-y-4">
      <StyledImg src={illustration} alt="Subscription"></StyledImg>
      <div className="space-y-2">
        <Typography
          label={`Successfully subscribed to <b>${props.data?.subscription?.name}</b>!`}
          align="center"
          weight="semibold"
          variant="f3"
          color={colorTheme.dark}
        />
        <div className="flex justify-center">
          <Typography
            align="center"
            label={getDesc()}
            weight="normal"
            variant="f1"
            color={colorTheme.darkFaded}
          />
        </div>
      </div>
      <div className="w-full">
        <Button
          label={localization.btn_continue}
          color="primary"
          onClick={props.onContinueClick}
        />
      </div>
    </div>
  );
};

export default SuccessfulPaymentDialogView;
