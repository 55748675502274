import {Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {StyledImg} from './styles';

interface NoResultProps {
  image: string;
  label: string;
  desc?: string;
}

const NoResultView = (props: NoResultProps) => {
  return (
    <div className="flex flex-col items-center p-4 space-y-4">
      <StyledImg src={props.image} alt="noMatches"></StyledImg>
      <Typography
        label={props.label}
        align="center"
        weight="normal"
        variant="f2"
        color={colorTheme.dark}
      />

      {props.desc && (
        <Typography
          className="pt-2"
          label={props.desc}
          align="center"
          weight="normal"
          variant="f2"
          color={colorTheme.dark}
        />
      )}
    </div>
  );
};

export default NoResultView;
