import {useEffect} from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {Button, Input} from '../index';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {daysSchema} from '../../../app/schema/admin.schema';
import {DurationContent} from '../../../app/server/types/admin-control.types';

export type DaysInputProps = {
  duration?: DurationContent;
  isLoading?: boolean;
  minValue?: number;
  onSubmit?: (value: DurationContent) => void;
};

const DaysInput = ({
  onSubmit,
  duration,
  isLoading,
  minValue,
}: DaysInputProps) => {
  const {
    control,
    getValues,
    reset,
    formState: {errors},
    handleSubmit,
  } = useForm<DurationContent>({
    resolver: yupResolver(daysSchema),
    defaultValues: {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
  });

  useEffect(() => {
    if (duration) {
      reset(duration);
    }
  }, [duration, reset]);

  const submitForm = () => {
    handleSubmit(submit)();
  };

  const submit = () => {
    if (onSubmit) {
      onSubmit(getValues());
    }
  };

  const handleInputChange = (
    onChange: (value: any) => void,
    minValue: number | undefined,
  ) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;

      if (inputValue === '') {
        onChange(1);
        return;
      }

      const sanitizedValue = inputValue.replace(/\D/g, ''); // Remove non-digits

      const numericValue = parseInt(sanitizedValue, 10);

      if (!isNaN(numericValue)) {
        onChange(Math.max(numericValue, minValue ?? 1));
      }
    };
  };

  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-row space-x-3">
        <div style={{width: '100px'}}>
          <Controller
            control={control}
            name="days"
            render={({field: {value, onBlur, onChange}}) => (
              <Input
                label="Days"
                type="number"
                inputSize="large"
                placeholder="1d"
                onChange={handleInputChange(onChange, minValue)}
                value={value === 0 ? '' : value}
                error={errors.minutes?.message}
                onBlur={onBlur}
                numericOnly={true}
              />
            )}
          />
        </div>
      </div>
      <div>
        <Button
          label="Save"
          color="primary"
          onClick={submitForm}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default DaysInput;
