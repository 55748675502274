import Page from '../../../../core/components/page/Page';
import {Button, Input, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {StyledDiv} from './styled';
import {useEffect, useState} from 'react';
import {localization} from '../../../localization/Localization';
import {
  RadioButton,
  RadioButtonFilled,
} from '../../../../core/assets/icons/components';
import {homeownerDisableReasons, kazamReasons} from './account.types';
import {useAuth} from '../../../hooks/useAuth';
import {
  useGetSystemStatistics,
  usePatchUserProfileStatus,
} from '../../../server/react-query';
import {PatchUserProfileStatusBody} from '../../../server/types';
import {useHistory} from 'react-router';
import {RoutePath} from '../../../navigation/config/RouteConfig';
import {useAnalytics} from '../../../hooks/useAnalytics';

const DisableAccountPage = () => {
  const history = useHistory();
  const {authData, updateAuthData} = useAuth();
  const {data: hookDataSystemStatistics} = useGetSystemStatistics();
  const {systemLogEvent} = useAnalytics();

  const [selectedReason, setSelectedReason] = useState<string>();
  const [reasons, setReasons] = useState<string[]>([]);
  const [reason, setReason] = useState<string>('');

  useEffect(() => {
    if (authData) {
      const reasons =
        authData.user.userRole === 'homeowner'
          ? homeownerDisableReasons
          : kazamReasons;
      setReasons(reasons);
      setSelectedReason(reasons[0]);
      setReason(localization.getString(reasons[0]));
    }
  }, [authData]);

  const selectHandler = (value: string) => {
    setSelectedReason(value);
    setReason(localization.getString(value));
  };

  const onReasonChange = (value: string) => {
    setReason(value);
  };

  const patchUserProfileStatus = usePatchUserProfileStatus();

  const onProceedDisable = async () => {
    try {
      var body: PatchUserProfileStatusBody = {
        status: 'disabled',
        reason: reason,
      };
      var user = await patchUserProfileStatus.mutateAsync(body);

      let data = authData;
      if (data) {
        data.user = user;
        updateAuthData(data);
      }

      systemLogEvent('active_users', {
        kazams:
          (hookDataSystemStatistics?.activeKazams || 0) -
          (authData?.user.userRole === 'kazam' ? 1 : 0),
        homeowners:
          (hookDataSystemStatistics?.activeHomeowners || 0) -
          (authData?.user.userRole === 'homeowner' ? 1 : 0),
      });

      systemLogEvent('system_statistics', {
        ...hookDataSystemStatistics,
      });

      history.replace(RoutePath.DISABLED_LANDING);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Page showBackButton showDesktopBackButton>
      <StyledDiv className="space-y-4">
        <div>
          <Typography
            label={localization.btn_disable_acc}
            variant="f3"
            align="center"
            color={colorTheme.dark}
          />
          <div className="pt-1">
            <Typography
              label={localization.desc_disable_profile_page}
              variant="f1"
              align="center"
              color={colorTheme.darkFaded}></Typography>
          </div>
        </div>

        <div className="pt-4 space-y-4">
          {reasons?.map((reason, i, arr) => {
            return (
              <div
                key={i}
                className="cursor-pointer"
                style={{width: 'fit-content'}}
                onClick={() => selectHandler(reason)}>
                <div className="flex flex-row items-center">
                  {selectedReason === reason ? (
                    <RadioButtonFilled
                      className="radio-button"
                      color={colorTheme.dark}
                    />
                  ) : (
                    <RadioButton
                      className="radio-button"
                      color={colorTheme.dark}
                    />
                  )}
                  <Typography
                    label={localization.getString(reason)}
                    variant="f2"
                    color={colorTheme.dark}
                  />
                </div>
              </div>
            );
          })}
        </div>

        <div>
          {selectedReason === 'rad_rsn_specify' && (
            <div className="w-full">
              <Input
                placeholder={localization.ph_enter_reason}
                inputSize="large"
                onChange={value => onReasonChange(value.currentTarget.value)}
              />
            </div>
          )}
        </div>

        <div className="pt-6">
          <Button
            label={localization.btn_proceed}
            color="primary"
            isLoading={patchUserProfileStatus.isLoading}
            onClick={onProceedDisable}
          />
        </div>
      </StyledDiv>
    </Page>
  );
};

export default DisableAccountPage;
