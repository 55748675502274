import React, {useEffect} from 'react';
import {Typography} from '../../../core/components';
import {useAuth} from '../../hooks/useAuth';
import {useHistory} from 'react-router-dom';
import firebase from 'firebase/app';
import {STORAGE} from '../../utils/storage.utils';
import {RoutePath} from '../../navigation/config/RouteConfig';
import { useDeleteFcmToken } from '../../server/react-query';

const LogoutPage = () => {
  const auth = useAuth();
  const history = useHistory();
  const deleteFcmToken = useDeleteFcmToken();  

  useEffect(() => {
    (async () => {
      const fcmTokenId = localStorage.getItem('fcmTokenId');
      if (fcmTokenId) {
        try {
          await deleteFcmToken.mutateAsync(fcmTokenId);
          localStorage.removeItem('fcmTokenId');
        } catch (error) {
          console.log(error);
        }
      }

      await firebase
        .auth()
        .signOut()
        .then(() => {
          const role = auth.authData?.user.userRole;
          if (firebase.messaging.isSupported() && role !== 'admin') {
            firebase.messaging().deleteToken();
          }

          auth.clearAuthData();
          STORAGE.clean();
          
          if (role === 'admin') {
            history.replace(RoutePath.ADMIN_SIGN_IN);
          } else {
            window.location.replace('/');
          }
        })
        .catch(err => {
          console.log(err);
        });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Typography variant="f1" label="Logging out .." />;
};

export default LogoutPage;
