import { PostCallSessionReqBody, PostCallSessionResponse } from "../types/call-session.types";
import { API, ApiEndpointsEnum } from '../api';
import { useMutation, useQueryClient } from "react-query";

const generateCallJWT = async (reqBody: PostCallSessionReqBody) => {
    return API.post<PostCallSessionResponse>(ApiEndpointsEnum.POST_CALL_SESSION, reqBody);
};

const useGenerateCallJWT = () => {
    const queryClient = useQueryClient();

    return useMutation(['post-generate-jwt'], (reqBody: PostCallSessionReqBody) => generateCallJWT(reqBody), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(['post-generate-jwt']);
        },
    });
};

export {
    useGenerateCallJWT
};
