import React, {useEffect, useState} from 'react';
import {DurationInput, Toggle, Typography} from '../../../../core/components';
import {StyledWrapper} from './styled';
import {colorTheme} from '../../../../core/configs';
import {usePutAdminControl} from '../../../server/react-query';
import {useToast} from '../../../../core/components/toast';
import {
  AdminControl,
  AdminControlReqBody,
  DurationContent,
} from '../../../server/types/admin-control.types';
import Divider from '../../../../core/components/divider/Divider';

interface KawayControlViewProps {
  data: AdminControl[];
}

const KawayControlView = ({data}: KawayControlViewProps) => {
  const {presentToast} = useToast();

  const [enableKaway, setEnableKaway] = useState(false);
  const [enableSchedule, setEnableSchedule] = useState(false);
  const [allowHiddenProfiles, setAllowHiddenProfiles] = useState(false);
  const [isSavingSched, setIsSavingSched] = useState(false);

  const [kawayDuration, setKawayDuration] = useState<DurationContent>();

  const putAdminControl = usePutAdminControl();

  useEffect(() => {
    if (data) {
      var enableKawayControl = data.find(d => d.type === 'enableKaway');
      if (enableKawayControl) {
        setEnableKaway(enableKawayControl.enabled);
      }

      var allowHiddenKazamForKaway = data.find(
        d => d.type === 'allowHiddenKazamForKaway',
      );
      if (allowHiddenKazamForKaway) {
        setAllowHiddenProfiles(allowHiddenKazamForKaway.enabled);
      }

      var enableSchedule = data.find(d => d.type === 'enableKawaySchedule');
      if (enableSchedule) {
        setEnableSchedule(enableSchedule.enabled);

        if (enableSchedule.content) {
          var duration = JSON.parse(
            enableSchedule?.content.toLowerCase(),
          ) as DurationContent;
          setKawayDuration(duration);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onEnableKaway = async (value: boolean) => {
    try {
      const data: AdminControlReqBody = {
        type: 'enableKaway',
        enabled: value,
      };
      await putAdminControl.mutateAsync(data);
      setEnableKaway(value);
      presentToast({
        message: `Successfully ${
          value ? 'enabled' : 'disabled'
        } kaway feature.`,
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      console.log(e);
    }
  };

  const onEnableSched = async (value: boolean) => {
    try {
      const data: AdminControlReqBody = {
        type: 'enableKawaySchedule',
        enabled: value,
      };
      await putAdminControl.mutateAsync(data);
      setEnableSchedule(value);

      if (!value) {
        setKawayDuration(undefined);
      }
      presentToast({
        message: `Successfully ${
          value ? 'enabled' : 'disabled'
        } kaway schedule feature.`,
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      console.log(e);
    }
  };

  const onSaveSchedule = async (value: DurationContent) => {
    try {
      const data: AdminControlReqBody = {
        type: 'enableKawaySchedule',
        enabled: true,
        content: JSON.stringify(value),
      };

      await putAdminControl.mutateAsync(data);

      setIsSavingSched(false);
      presentToast({
        message: `Successfully saved.`,
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      setIsSavingSched(false);
      console.log(e);
    }
  };

  const onAllowHiddenProfilesValueChanged = async (value: boolean) => {
    try {
      const data: AdminControlReqBody = {
        type: 'allowHiddenKazamForKaway',
        enabled: value,
      };

      await putAdminControl.mutateAsync(data);
      setAllowHiddenProfiles(value);

      presentToast({
        message: `Successfully ${
          value ? 'allowed' : 'disallowed'
        } hidden profiles.`,
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      console.log(e);
    }
  };

  return (
    <StyledWrapper>
      <Typography
        label="KAWAYS"
        variant="f1"
        weight="semibold"
        color={colorTheme.black}
      />

      <div className="pt-3 flex flex-row justify-between items-center">
        <div>
          <Typography
            label="Enable kaway feature"
            variant="f3"
            color={enableKaway ? colorTheme.dark : colorTheme.lightGray}
          />
          <Typography
            label="This will enable kazam to send kaway to homeowners."
            variant="f1"
            color={enableKaway ? colorTheme.darkFaded : colorTheme.lightGray}
          />
        </div>
        <div>
          <Toggle value={enableKaway} onToggle={onEnableKaway} />
        </div>
      </div>

      <Divider />

      <div>
        <div className="flex flex-row justify-between items-center">
          <div>
            <Typography
              label="Enable schedule"
              variant="f3"
              color={enableSchedule ? colorTheme.dark : colorTheme.lightGray}
            />
            <Typography
              label="This will enable kaway feature on a specific duration of time."
              variant="f1"
              color={
                enableSchedule ? colorTheme.darkFaded : colorTheme.lightGray
              }
            />
          </div>
          <div>
            <Toggle value={enableSchedule} onToggle={onEnableSched} />
          </div>
        </div>

        {enableSchedule && (
          <div className="pt-3">
            <DurationInput
              duration={kawayDuration}
              onSubmit={onSaveSchedule}
              isLoading={isSavingSched}
            />
          </div>
        )}
      </div>

      <Divider />

      <div className="flex flex-row justify-between items-center">
        <div>
          <Typography
            label="Allow hidden profiles"
            variant="f3"
            color={allowHiddenProfiles ? colorTheme.dark : colorTheme.lightGray}
          />
          <Typography
            label="This will enable hidden kazams to send kaways to homeowners."
            variant="f1"
            color={
              allowHiddenProfiles ? colorTheme.darkFaded : colorTheme.lightGray
            }
          />
        </div>
        <div>
          <Toggle
            value={allowHiddenProfiles}
            onToggle={onAllowHiddenProfilesValueChanged}
          />
        </div>
      </div>
    </StyledWrapper>
  );
};

export default KawayControlView;
