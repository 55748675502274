import React, {useCallback, useEffect, useState, useReducer} from 'react';
import {StyledChatsComponent} from './styled';
import {ChatResponse, UserRole} from '../../../server/types';
import ChatItem from './ChatItem';
import {RoutePath} from '../../../navigation/config/RouteConfig';
import {useHistory} from 'react-router-dom';
import {
  useGetConversation,
  useGetPaginatedConversations,
  useGetUnreadConversations,
} from '../../../server/react-query';
import noChats from '../../../../assets/no_chats.svg';
import NoResultView from '../../../views/cards/no-result/NoResultView';
import {getChatFilterSetting} from '../../../utils/chat-filter.util';
import {SavedFilter} from '../../../server/types/chat-filter.types';
import {BeatLoader} from 'react-spinners';
import {colorTheme} from '../../../../core/configs';
import {localization} from '../../../localization/Localization';
import AdminChatsPagination from './AdminChatsPagination';
import { PaginatedChatsQueryParams } from '../../../server/types/admin-control.types';

interface ChatsComponentProps {
  searchQuery?: string | undefined;
  userRole: UserRole
}

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'SET_SEARCH':
      return {
        ...state,
        searchQuery: action.payload.search,
        page: 1
      }
    case 'SET_PAGE':
      return {
        ...state,
        page: action.payload.page
      }
    case 'SET_USER_ROLE':
      return {
        ...state,
        userRole: action.payload.userRole,
        searchQuery: "",
        page: 1
      }
    default:
      return state
  }
}

const ChatsComponent = (props: ChatsComponentProps) => {
  const {searchQuery: propSearchQuery, userRole} = props;

  const [saveFilterSetting] = useState<SavedFilter>(getChatFilterSetting());

  const INITIAL_SEARCH_STATE: PaginatedChatsQueryParams = {
    searchQuery: '',
    sortBy: {
      0: 'distance',
      1: 'salary',
      2: 'active',
      3: 'activity',
    }[saveFilterSetting.activeTab],
    sortOrder: {
      0: {
        'Near To Far': 'nearest_first',
        'Far To Near': 'farthest_first',
      },
      1: {
        'Low To High': 'min_first',
        'High To Low': 'max_first',
      },
      2: {
        'Online First': 'most_active_first',
      },
      3: {
        'Latest First': 'latest_first',
      },
    }[saveFilterSetting.activeTab][
      saveFilterSetting.activeTab === 0
        ? saveFilterSetting.selectedDistanceSort
        : saveFilterSetting.activeTab === 1
        ? saveFilterSetting.selectedSalarySort
        : saveFilterSetting.activeTab === 2
        ? saveFilterSetting.selectedActiveSort
        : saveFilterSetting.selectedActivitySort
    ],
    roles: saveFilterSetting.selectedRoles.join(','),
    page: 1,
    userRole: userRole
  }

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [conversationData, setConversationData] = useState<ChatResponse[]>([]);
  const [unreadConversations, setUnreadConversations] = useState<
    ChatResponse[]
  >([]);

  const [searchBody, dispatchSearch] = useReducer(reducer, INITIAL_SEARCH_STATE)

  const pageSize = 10

  const history = useHistory();
  
  const {data, refetch, isFetching} = useGetPaginatedConversations(searchBody);

  const {data: hookDataUnreadConversations} = useGetUnreadConversations();

  useEffect(() => {
    if (hookDataUnreadConversations) {
      setUnreadConversations(hookDataUnreadConversations);
    }
  }, [hookDataUnreadConversations]);

  useEffect(() => {
    refetch()
  }, [searchBody]);

  useEffect(() => {
    dispatchSearch({
      type: 'SET_USER_ROLE',
      payload: {
        userRole: userRole
      },
    });
  }, [userRole]);

  const executeSearch = useCallback(searchQuery => {
    setPageNumber(1)
    dispatchSearch({
      type: 'SET_SEARCH',
      payload: {
        search: searchQuery?.trim(),
        page: 1
      },
    });
  }, []);

  // Throttle search
  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      executeSearch(propSearchQuery);
    }, 500);
    return () => timeoutRef && clearTimeout(timeoutRef);
  }, [propSearchQuery, executeSearch]);

  useEffect(() => {
    if (data) {
      setConversationData(data.data.map(i => ({...i})));
    }
  }, [data]);

  const itemClickHandler = (item: ChatResponse) => {
    history.push(
      RoutePath.ADMIN_CHAT_ROOM.replace(':id', item.conversationChannelId),
    );
  };

  const setPageNumberQuery = (pageNumber: number) => {
    setPageNumber(pageNumber)
    dispatchSearch({
      type: 'SET_PAGE',
      payload: {
        page: pageNumber
      },
    });
  }

  return (
    <StyledChatsComponent>
      {isFetching && 
        <div
          className="w-full flex justify-center items-center"
          style={{minHeight: '250px'}}>
          <BeatLoader
            color={colorTheme.primary}
            loading={isFetching}
            margin={2}
            size={15}
          />
        </div>
      }
      
      <div> 
        {conversationData && conversationData.length > 0 ? (
          <>
            {!isFetching && 
              <div style={{minHeight: '250px'}}>
                {conversationData.map((data, index) => (
                  <ChatItem
                    key={data.conversationChannelId}
                    data={data}
                    onClick={itemClickHandler}
                    isUnread={
                      !!unreadConversations.find(
                        u =>
                          u.conversationChannelId === data.conversationChannelId,
                      )
                    }
                  />
                ))}
              </div>
            }
            
            <AdminChatsPagination
              pageSize={pageSize}
              currentPage={pageNumber}
              totalPages={data?.total && data.total > 0 
                ? Math.ceil(data.total/pageSize)  
                : 0}
              showDebugView
              onPageChange={setPageNumberQuery}
            />
          </>
        ) : (
          !isFetching && 
            <NoResultView
              image={noChats}
              label={localization.desc_no_chats} />
        )}
      </div>
    </StyledChatsComponent>
  );
};

export default ChatsComponent;
