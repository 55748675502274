import * as React from 'react';

function SvgUserCross(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) {
  return (
    
    <svg width="12" height="10" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"  ref={svgRef} {...props}>
    <path d="M1 15V12C1 11 1.7 9 4.5 9C7.3 9 9.66667 9 10.5 9C11.3333 9 13 9.6 13 12V15" stroke="white" stroke-width="2"/>
    <circle cx="7" cy="3.5" r="2.5" stroke="white" stroke-width="2"/>
    <path d="M17.5844 7.08437C17.5323 7.13674 17.4704 7.17833 17.4022 7.20675C17.3336 7.2353 17.2601 7.25 17.1859 7.25C17.1117 7.25 17.0382 7.2353 16.9697 7.20675C16.9013 7.17826 16.8392 7.13654 16.787 7.08398C16.7869 7.08388 16.7868 7.08377 16.7867 7.08366L15 5.29394L13.2133 7.08366C13.2132 7.08375 13.2131 7.08385 13.213 7.08394C13.1608 7.13651 13.0987 7.17825 13.0303 7.20675C12.9618 7.2353 12.8883 7.25 12.8141 7.25C12.7399 7.25 12.6664 7.2353 12.5978 7.20675C12.5296 7.17833 12.4677 7.13675 12.4156 7.08439C12.3632 7.03226 12.3217 6.97033 12.2932 6.90212C12.2647 6.8336 12.25 6.76011 12.25 6.68588C12.25 6.61165 12.2647 6.53816 12.2932 6.46964C12.3217 6.40125 12.3635 6.33916 12.416 6.28695C12.4161 6.28685 12.4162 6.28675 12.4163 6.28665L14.2059 4.49973L12.4157 2.71223L12.4156 2.71209C12.3099 2.6064 12.2505 2.46305 12.2505 2.31358C12.2505 2.16412 12.3099 2.02077 12.4156 1.91508C12.5213 1.80938 12.6646 1.75 12.8141 1.75C12.9636 1.75 13.1069 1.80938 13.2126 1.91508L13.2127 1.91522L15 3.70553L16.7873 1.91522L16.7874 1.91508C16.8931 1.80938 17.0364 1.75 17.1859 1.75C17.3354 1.75 17.4787 1.80938 17.5844 1.91508C17.6901 2.02077 17.7495 2.16412 17.7495 2.31358C17.7495 2.46305 17.6901 2.6064 17.5844 2.71209L17.5843 2.71223L15.7941 4.49973L17.5837 6.28665C17.5838 6.28677 17.5839 6.28688 17.584 6.287C17.6366 6.3392 17.6783 6.40127 17.7068 6.46964C17.7353 6.53816 17.75 6.61166 17.75 6.68588C17.75 6.76011 17.7353 6.8336 17.7068 6.90212C17.6784 6.97032 17.6368 7.03225 17.5844 7.08437ZM17.5844 7.08437C17.5842 7.08462 17.5839 7.08486 17.5837 7.08511L17.4076 6.90762L17.5851 7.08366C17.5849 7.0839 17.5847 7.08414 17.5844 7.08437Z" fill="white" stroke="white" stroke-width="0.5"/>
    </svg>

    

  );
}

const ForwardRef = React.forwardRef(SvgUserCross);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
