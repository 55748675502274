import {useMutation, useQuery, useQueryClient} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';
import {
  VoucherResponse,
} from '../types/voucher.types';
import {
  PersonalityMatchResponse,
  PutPersonalityMatch,
} from '../types/personality-test.types';

const putPersonalityMatch = async (reqBody: PutPersonalityMatch) => {
  const url = ApiEndpointsEnum.PUT_PERSONALITY_MATCH;
  return API.put<PersonalityMatchResponse>(url, reqBody);
};

const usePutPersonalityMatch = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['put-personality-match'],
    (reqBody: PutPersonalityMatch) => putPersonalityMatch(reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['put-personality-match']);
      },
    },
  );
};

const getPersonalityMatch = () => {
  const url = ApiEndpointsEnum.GET_PERSONALITY_MATCH;
  return API.get<PersonalityMatchResponse>(url);
};

const useGetPersonalityMatch = () => {
  return useQuery(['get-personality-match'], () => getPersonalityMatch());
};

export {usePutPersonalityMatch, useGetPersonalityMatch};
