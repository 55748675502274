import * as React from 'react';

function SvgMessageCircleGradient(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}>
      <path
        d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.379 8.379 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"
        stroke="url(#message-circle-gradient_svg__paint0_linear)"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="message-circle-gradient_svg__paint0_linear"
          x1={12}
          y1={3}
          x2={12}
          y2={21}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#007E69" stopOpacity={0.91} />
          <stop offset={1} stopColor="#298309" stopOpacity={0.58} />
        </linearGradient>
      </defs>
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMessageCircleGradient);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
