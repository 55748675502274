import * as React from 'react';

function SvgDocumentChecked(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) {
  return (
    
    <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg" ref={svgRef} {...props}>
      <path d="M11 8L11 2L12 2L15.1717 5.16086L17.0071 6.99083L17 8L11 8Z" fill="white"/>
      <path d="M14.3521 12.3505C14.7267 11.257 16.2733 11.257 16.648 12.3505V12.3505C16.8847 13.0414 17.6736 13.3682 18.3295 13.047V13.047C19.3677 12.5387 20.4613 13.6323 19.953 14.6705V14.6705C19.6318 15.3264 19.9586 16.1153 20.6495 16.3521V16.3521C21.743 16.7267 21.743 18.2733 20.6495 18.648V18.648C19.9586 18.8847 19.6318 19.6736 19.953 20.3295V20.3295C20.4613 21.3677 19.3677 22.4613 18.3295 21.953V21.953C17.6736 21.6318 16.8847 21.9586 16.648 22.6495V22.6495C16.2733 23.743 14.7267 23.743 14.3521 22.6495V22.6495C14.1153 21.9586 13.3264 21.6318 12.6705 21.953V21.953C11.6323 22.4613 10.5387 21.3677 11.047 20.3295V20.3295C11.3682 19.6736 11.0414 18.8847 10.3505 18.648V18.648C9.25699 18.2733 9.25699 16.7267 10.3505 16.3521V16.3521C11.0414 16.1153 11.3682 15.3264 11.047 14.6705V14.6705C10.5387 13.6323 11.6323 12.5387 12.6705 13.047V13.047C13.3264 13.3682 14.1153 13.0414 14.3521 12.3505V12.3505Z" fill="white"/>
      <path d="M18 15L14.5 18.5L13 17L12 18L14.5 20.5L19 16L18 15Z" fill="#007E69"/>
      <path d="M8 21V19H2V2H12L17 7.5V11H19V6L13 0H0V13V21H8Z" fill="white"/>
    </svg>
    

  );
}

const ForwardRef = React.forwardRef(SvgDocumentChecked);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
