import { isMobile } from 'react-device-detect';
import { StyledAsSeenOnSectionWrapper, StyledLogosWrapper, StyledHeader } from './styled';

import dost from '../../../../assets/dost.png';
import dostMobile from '../../../../assets/dost-mobile.png';
import sgfp from '../../../../assets/sgfp.png';
import top100 from '../../../../assets/top-100-cropped.png';
import ideaSpace from '../../../../assets/idea-space.png';
import ideaSpaceLogo from '../../../../assets/ideaspace-logo.png';

const AsSeenOn = () => {


  return (
    <>
        <StyledAsSeenOnSectionWrapper className='flex flex-col justify-center items-center w-full'>
            <div>
                <StyledHeader>- As Seen On -</StyledHeader>
            </div>
          

            <StyledLogosWrapper >
                <div style={{height: isMobile ? '15vh' : '125px'}}>
                  <img src={dost} alt='DOST' />
                </div>
                <div>
                  <img src={sgfp} alt='Startup Grant Fund Program' />
                </div>
                <div>
                  <img className='idea-space' src={ideaSpaceLogo} alt='IdeaSpace Logo' />
                </div>
                <div>
                  <img src={top100} alt='QBO Top 100'/>
                </div>
                <div>
                  <img src={ideaSpace} alt='IdeaSpace' />
                </div>
            </StyledLogosWrapper>

        </StyledAsSeenOnSectionWrapper>
    </>
    
  );
};

export default AsSeenOn;
