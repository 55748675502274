import React, { useEffect } from 'react';
import { useStopwatch } from 'react-timer-hook';
import { Typography } from '../../../core/components';
import { colorTheme } from '../../../core/configs';


interface CallTimerProps {
  hide?: boolean
  onUpdateTotalSeconds?: (totalSeconds: number) => void
}

const CallTimer = ({
  hide,
  onUpdateTotalSeconds
}: CallTimerProps) => {
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true });

  useEffect(() => {
    if (totalSeconds > 0) {
      onUpdateTotalSeconds && onUpdateTotalSeconds(totalSeconds)
    }
  }, [totalSeconds])

  const getTime = () => {
    const secondsTimer = (seconds < 10) ? `0${seconds}` : `${seconds}`
    const minutesTimer = (minutes < 10) ? `0${minutes}` : `${minutes}`
    
    return `${minutesTimer}:${secondsTimer}`
  }

  if (hide) return <></>

  return (
    <>
      <Typography
        label={getTime()}
        variant='f3'
        color={colorTheme.light}
      />
    </>
  );
};

export default CallTimer;

