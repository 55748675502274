import React from 'react';

import {DialogProvider} from '../dialog';
import {ToastProvider} from '../toast';

const ComponentProvider: React.FC = ({children}) => {
  return (
    <DialogProvider>
      <ToastProvider>{children}</ToastProvider>
    </DialogProvider>
  );
};

export default ComponentProvider;
