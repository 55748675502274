import {isMobile} from 'react-device-detect';
import styled, { css } from 'styled-components';
import { colorTheme } from '../../../../core/configs';
import homeownerBg from '../../../../assets/homeowner-bg.webp';
import kasambahayBG from '../../../../assets/kasambahay-bg.webp';
import { isUsingMobileBrowser } from '../../../utils/random.util';

export const StyledHomeWrapper = styled.div`
  ${isUsingMobileBrowser()
    ? css`
        height: calc(100dvh - 55px - env(safe-area-inset-top));
      `
    : isMobile
    ? css`
        height: calc(100vh - 55px - env(safe-area-inset-top));
      `
    : css`
        height: calc(100vh - 65px);
      `}
`;

export const StyledHeader = styled.div`
  ${isMobile
    ? css`
        font-size: 0.875rem;
      `
    : css`
        font-size: 1.5rem;
      `}

  letter-spacing: 0rem;
  font-weight: 400;
  text-align: center;
  color: ${colorTheme.tint};

  b {
    color: ${colorTheme.primary};
  }
`;

export const StyledBodyWrapper = styled.div`
  height: 100%; 
  .kasambahay-section {
    height: 100%; 
    width: 100%;
    background:url(${kasambahayBG});
    background-size: cover;
    background-position: center;
    background-repeat: repeat;

    & .kasambahay-overlay-bg {
      background: rgba(21, 11, 107, 0.55);
      z-index: 1;
    }
  }

  .homeowner-section {
    height: 100%; 
    width: 100%;
    background:url(${homeownerBg});
    background-size: cover;
    background-position: center;
    background-repeat: repeat;

    & .homeowner-overlay-bg {
      background: rgba(128, 0, 153, 0.55);
    }
  }

  & .homeowner-overlay,
  .kasambahay-overlay {
      z-index: 2;

      & img {
        max-height: 85%;
      }
    }

  & .cta {
    width: fit-content;
    background-color: ${colorTheme.primary};
    border-radius: 4px;
    border: 2px solid ${colorTheme.white};
    cursor: pointer;
  }
`;