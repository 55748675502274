import styled from 'styled-components';
import {CheckboxColor, CheckboxSize, CheckboxSizeStyle} from './checkbox.types';
import {colorTheme} from './../../configs/color.config';

const checkboxSizeStyles: {[key in CheckboxSize]: CheckboxSizeStyle} = {
  default: {
    width: 20,
    height: 20,
    textSize: 0.875,
    lineHeight: 1.25,
    letterSpacing: -0.009375,
  },
  large: {
    width: 21,
    height: 21,
    textSize: 1.25,
    lineHeight: 1.875,
    letterSpacing: 0.01875,
  },
  small: {
    width: 15,
    height: 15,
    textSize: 0.75,
    lineHeight: 1.125,
    letterSpacing: 0,
  },
};

interface VariantColor {
  default: string;
}

const variantColors: {[key in CheckboxColor]: VariantColor} = {
  default: {
    default: colorTheme.dark,
  },
  primary: {
    default: colorTheme.primary,
  },
  secondary: {
    default: colorTheme.secondary,
  },
  success: {
    default: colorTheme.primary,
  },
  warning: {
    default: colorTheme.warning,
  },
  danger: {
    default: colorTheme.danger,
  },
};

export interface StyledCheckboxProps {
  size: CheckboxSize;
  color: CheckboxColor;
  disabled: boolean;
  indeterminate: boolean;
}

export const CheckboxContainer = styled.div<StyledCheckboxProps>`
  display: flex;
  align-items: center;
  user-select: none;
  opacity: ${pr => pr.disabled && '0.7'};
  cursor: ${pr => (pr.disabled || pr.indeterminate ? 'default' : 'pointer')};
  width: fit-content;
`;

export const CheckboxLabel = styled.span<StyledCheckboxProps>`
  margin-left: 8px;
  color: ${pr => variantColors[pr.color].default};

  font-size: ${pr => checkboxSizeStyles[pr.size].textSize}rem;
  line-height: ${pr => checkboxSizeStyles[pr.size].lineHeight}rem;
  letter-spacing: ${pr => checkboxSizeStyles[pr.size].letterSpacing}rem;
`;

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: inline-block;
  border: 2px solid ${pr => variantColors[pr.color].default};
  height: ${pr => checkboxSizeStyles[pr.size].height}px;
  width: ${pr => checkboxSizeStyles[pr.size].width}px;
  border-radius: 4px;
  margin-left: 2px;

  .checkmark {
    border-radius: 50%;
    display: block;
    stroke-width: 5px;
    stroke: ${pr => variantColors[pr.color].default};
    stroke-miterlimit: 10;
    stroke-dashoffset: 0;
  }

  .indeterminate {
    border-radius: 50%;
    display: block;
    stroke-width: 8px;
    stroke: ${pr => variantColors[pr.color].default};
    stroke-miterlimit: 10;
    stroke-dashoffset: 0;
  }
`;
