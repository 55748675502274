import React from 'react';
import {Typography} from '../../../../core/components';
import AdminPage from '../../../../core/components/admin-page/AdminPage';
import {colorTheme} from '../../../../core/configs';

const AdminLandingPage = () => {
  return (
    <AdminPage>
      <div>
        <Typography
          label="Welcome to kazam admin!"
          variant="f3"
          weight="semibold"
          color={colorTheme.dark}
        />
      </div>
    </AdminPage>
  );
};

export default AdminLandingPage;
