import * as React from 'react';

function SvgBgHome(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) {
  return (
    

    <svg width="80" height="77" viewBox="0 0 80 77" fill="none" xmlns="http://www.w3.org/2000/svg" ref={svgRef} {...props}>
        <rect width="80" height="77" rx="10" fill="#330099"/>
        <path d="M24.1043 60.8252V42.8252V41.8252C22.9043 42.6252 21.6043 42.1585 21.1043 41.8252C20.9376 41.8252 20.1043 41.3252 18.1043 39.3252C16.1043 37.3252 17.271 34.4918 18.1043 33.3252C23.6043 27.9918 35.2043 16.7252 37.6043 14.3252C40.0043 11.9252 42.9376 13.3252 44.1043 14.3252L62.6043 32.8252C64.2043 34.4252 63.6043 37.1585 63.1043 38.3252C62.4376 39.1585 60.9043 41.0252 60.1043 41.8252C59.3043 42.6252 57.771 42.1585 57.1043 41.8252V43.3252V60.8252C57.1043 63.2252 54.771 64.4918 53.6043 64.8252H27.6043C25.6043 64.8252 24.4376 62.1585 24.1043 60.8252Z" fill="white"/>
        <path d="M22.6043 40.8252L19.1043 37.3252C18.3043 36.1252 18.771 35.1585 19.1043 34.8252L38.6043 15.3252C40.2043 13.7252 41.9377 14.6585 42.6043 15.3252C47.771 20.6585 58.6043 31.8252 60.6043 33.8252C62.6043 35.8252 62.1043 37.3252 61.6043 37.8252L59.1043 40.3252C58.3043 41.1252 56.771 40.3252 56.1043 39.8252L41.6043 25.3252C40.5 24.2209 40 25 39.6043 25.3252C34.9377 29.8252 25.5664 39.2508 24.6043 40.3252C24 41 23 41 22.6043 40.8252Z" fill="#330099"/>
    </svg>

    

  );
}

const ForwardRef = React.forwardRef(SvgBgHome);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
