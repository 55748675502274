import {StyledSendOfferDialog} from './styled';
import {Button, Typography, TextArea} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {localization} from '../../../localization/Localization';
import { usePutJobOfferStatus } from '../../../server/react-query/useJobOffer';
import { useState } from 'react';

interface NoShowReportReasonProps {
  jobOfferId: string
  contentId: string
  onCancel: () => void;
  onNoShow: () => void;
}

const NoShowReportReasonDialog = (props: NoShowReportReasonProps) => {
  const [reason, setReason] = useState<string>()

  const putJobOfferStatus = usePutJobOfferStatus(props.jobOfferId)
  const noShow = async () => {
    try {
      await putJobOfferStatus.mutateAsync({
        contentId: props.contentId,
        status: 'noShow',
        reason
      })
      props.onNoShow()
    } catch (error) {
      
    }
  }
  return (
    <StyledSendOfferDialog>
      <div className="flex flex-col header mb-2 items-center">
        <Typography
          label="NO SHOW REPORT"
          variant="f3"
          weight="semibold"
          color={colorTheme.dark}
        />
      </div>

      <div>
        <Typography
          className="mb-3 text-center"
          label="State reason for now show below"
          variant="f1"
          weight="normal"
          align="center"
          color={colorTheme.darkFaded}
        />
      </div>

      <div className="mb-1">
        <div>
          <TextArea
            placeholder="Enter Reason"
            bordered
            forBroadcast
            rows={3}
            maxRows={3}
            onChange={value => setReason(value.currentTarget.value)}
          />
        </div>
      </div>

      <div className="w-full flex flex-row space-x-2 pt-2">
        <Button
          label="Cancel"
          variant="outlined"
          className="flex-1"
          onClick={props.onCancel}
        />

        <div className="spacer" />

        <Button
          label="Submit"
          color="primary"
          className="flex-1"
          isLoading={putJobOfferStatus.isLoading}
          onClick={noShow}
        />
      </div>
    </StyledSendOfferDialog>
  );
};

export default NoShowReportReasonDialog;
