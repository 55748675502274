import styled from 'styled-components';
import {colorTheme} from '../../../../core/configs';

export const StyledAdminAdsTabs = styled.div`
  margin-top: 24px;

  & .tab-control {
    border: solid 1px ${colorTheme.lightGray};
    background-color: ${colorTheme.light};
    padding: 8px;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .tab-control.active {
    color: white;
    background-color: ${colorTheme.primary};
  }
`;

export const StyledAdminAdsSearch = styled.div`
  margin-top: 36px;

  & .search-input {
    padding-right: 24px;
  }
`;
