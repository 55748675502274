import {useEffect, useState} from 'react';
import {DurationInput, Toggle, Typography} from '../../../../core/components';
import {StyledWrapper} from './styled';
import {colorTheme} from '../../../../core/configs';
import {usePutAdminControl} from '../../../server/react-query';
import {useToast} from '../../../../core/components/toast';
import {
  AdminControl,
  AdminControlReqBody,
  AdminControlType,
  DurationContent,
} from '../../../server/types/admin-control.types';
import SoloDurationInput from '../../../../core/components/days-input/days-input';
import HoursInput from '../../../../core/components/hours-input';

interface EkycInitialReminderViewProps {
  data: AdminControl[];
}

const EkycInitialReminderView = ({data}: EkycInitialReminderViewProps) => {
  const {presentToast} = useToast();
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [control, setControl] = useState<AdminControl | undefined>();

  const putAdminControl = usePutAdminControl();

  const [ekycInitialReminder, setEkycInitialReminder] =
    useState<DurationContent>();

  useEffect(() => {
    if (data) {
      var ekycInitialReminder = data.find(
        d => d.type === 'ekycInitialReminder',
      );

      if (ekycInitialReminder) {
        setIsEnabled(ekycInitialReminder.enabled);
        var duration = JSON.parse(
          ekycInitialReminder?.content.toLowerCase(),
        ) as DurationContent;
        setEkycInitialReminder(duration);
        setControl(ekycInitialReminder);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSaveEkycInitialReminder = (value: DurationContent) => {
    const data: AdminControlReqBody = {
      type: 'ekycInitialReminder',
      enabled: value.hours === 0 ? false : true,
      content: JSON.stringify(value),
    };
    onPutAdminControl(data);
    setIsEnabled(value.hours === 0 ? false : true)
  };

  const onPutAdminControl = async (data: AdminControlReqBody) => {
    try {
      const res = await putAdminControl.mutateAsync(data);
      setControl(res);
      presentToast({
        message: `Successfully saved.`,
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      console.log(e);
    }
  };

  const handleToggle = (toggled: boolean) => {
    setIsEnabled(toggled);

    const data: AdminControlReqBody = {
      type: control?.type || 'ekycInitialReminder',
      enabled: toggled,
      content: control?.content || JSON.stringify({duration: 0}),
    };

    onPutAdminControl(data);
  };

  return (
    <StyledWrapper>
      <Typography
        label="KAZAM SETTINGS"
        variant="f1"
        weight="semibold"
        color={colorTheme.black}
      />

      <div>
        <div className="pt-3 flex flex-row justify-between items-center">
          <div>
            <Typography
              label="Deferred Onboarding Initial In-App Reminder"
              variant="f3"
              color={colorTheme.dark}
            />
            <Typography
              label="Set the duration on how many hours before the EKYC Initial In-App reminder  shows after sign-up."
              variant="f1"
              color={colorTheme.darkFaded}
            />
          </div>
          <div>
            <Toggle value={isEnabled} onToggle={handleToggle} />
          </div>
        </div>

        {isEnabled && (
          <>
            <div className="pt-3">
              <HoursInput
                duration={ekycInitialReminder}
                onSubmit={onSaveEkycInitialReminder}
                isLoading={putAdminControl.isLoading}
                minValue={1}
              />
            </div>
          </>
        )}
      </div>
    </StyledWrapper>
  );
};

export default EkycInitialReminderView;
