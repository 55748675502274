import {Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {StyledImg} from './styles';
import searchNotif from '../../../../assets/search_notif.svg';
import {NotificationResponse} from '../../../server/types/notification.types';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import {localization} from '../../../localization/Localization';
import {useHistory} from 'react-router';
import {RoutePath} from '../../../navigation/config/RouteConfig';
import { isMobile } from 'react-device-detect';

interface NotificationProps {
  data: NotificationResponse;
}

const KazamNotificationView = (props: NotificationProps) => {
  const history = useHistory();

  const goToMatches = () => {
    const params = `?userId=${props.data.userId}`;
    history.push(RoutePath.MATCHES_LANDING + params);
  };

  return (
    <div
      className="flex flex-row space-x-2 items-center mb-6 cursor-pointer"
      onClick={goToMatches}>
      <StyledImg src={searchNotif} alt="searchNotif"></StyledImg>
      <div className="flex flex-col">
        <div className="flex space-x-1">
          <Typography
            label={localization.desc_match_notif.replace(
              '{firstName}',
              props.data.user.firstName,
            )}
            weight={props.data.readOn ? 'normal' : 'semibold'}
            variant="f1"
            color={colorTheme.dark}
          />
        </div>

        <Typography
          label={`${formatDistanceToNow(new Date(props.data.createdOn))} ago`}
          weight="normal"
          variant="f1"
          color={props.data.readOn ? colorTheme.darkFaded : colorTheme.dark}
        />
      </div>
    </div>
  );
};

export default KazamNotificationView;
