import {StyledOfferCard} from './styles';
import {Button} from '../../../../core/components';
import {useDialog} from '../../../../core/components/dialog';
import NoShowReportReasonDialog from '../../dialog-content/no-show-report-reason/NoShowReportReason';
import {UserResponse} from '../../../server/types';
import {ConversationContentMeta, JobOfferContentMeta} from '../../../server/types/job-offer.types';
import format from 'date-fns/format';
import { useGetJobOffer, usePutJobOfferStatus } from '../../../server/react-query/useJobOffer';
import { BeatLoader } from 'react-spinners';
import { colorTheme } from '../../../../core/configs';
import { useEffect } from 'react';

interface ChatJobOfferContractStartDateProps {
  sender?: UserResponse;
  recipient?: UserResponse;
  contentId: string;
  meta: ConversationContentMeta;
  date: Date;
  onStarted: (jobOfferId: string) => void
  onDataLoaded: () => void
}

const ChatJobOfferContractStartDate = ({
  sender,
  recipient,
  contentId,
  meta,
  date,
  onStarted,
  onDataLoaded
}: ChatJobOfferContractStartDateProps) => {
  const {presentDialog, dismissDialog} = useDialog();
  // const { data, isFetching } = useGetJobOffer(meta.JobOfferId)
  const senderIsHomeowner = sender?.userRole === 'homeowner'

  // useEffect(() => {
  //   if (data) onDataLoaded()
  // }, [data])

  const showSendOfferDialog = () => {
    presentDialog({
      headerText: '',
      enableBackdropDismiss: true,
      content: <NoShowReportReasonDialog
        contentId={contentId}
        jobOfferId={meta.JobOfferId}
        onCancel={dismissDialog}
        onNoShow={() => {
          dismissDialog()
          onStarted(meta.JobOfferId)
        }}
      />,
    });
  };

  const putJobOfferStatus = usePutJobOfferStatus(meta.JobOfferId)
  const started = async () => {
    try {
      await putJobOfferStatus.mutateAsync({
        contentId: contentId,
        status: 'started'
      })
      onStarted(meta.JobOfferId)
    } catch (error) {
      
    }
  }

  const getHeader = () => {
    switch (meta?.Status) {
      case 'started':
        return 'CONTRACT STARTED'
      case 'noShow':
        return 'NO SHOW REPORT'
    
      default:
        return 'CONTRACT START DATE'
    }
  }

  const getContent = () => {
    switch (meta?.Status) {
      case 'started':
        return senderIsHomeowner 
          ? `${recipient?.firstName} started the employment contract today ${format(new Date(meta.StartDate), 'PP')}. `
          : `You have started your employment with ${recipient?.firstName}.`
      case 'noShow':
        return meta.Reason ?? "The kasambahay is a no show."
      default:
        return `Today is ${recipient?.firstName}'s start date. Did they arrive to start with you as
        agreed?`
    }
  }

  if (meta?.Status === 'startedConfirmation'
    && !senderIsHomeowner) return <></>

  return (
    <StyledOfferCard className="msg-right">
      <div className="offer-details ">
        <>
          <div className="text-center mb-2">
            <label className="txt-heading">{getHeader()}</label>
          </div>

          <div className="flex gap-1 mb-3">
            <span>
              {getContent()}
            </span>
          </div>

          {meta?.Status === 'startedConfirmation' &&
            <div className="mt-2 mb-2">
              <Button
                label="Yes"
                className="offer-primary-btn mb-1"
                isLoading={putJobOfferStatus.isLoading}
                onClick={started}
              />

              <Button
                label="No"
                className="offer-secondary-btn"
                onClick={showSendOfferDialog}
              />
            </div>
          }

          <div className="text-right offer-msg-time">
            <span>{`${format(date, 'PP')} ${format(date, 'p')}`}</span>
          </div>
        </>
      </div>
    </StyledOfferCard>
  );
};

export default ChatJobOfferContractStartDate;
