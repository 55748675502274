import * as React from 'react';

function SvgHeartGradient(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}>
      <path
        d="M20.84 4.61a5.5 5.5 0 00-7.78 0L12 5.67l-1.06-1.06a5.501 5.501 0 10-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.499 5.499 0 000-7.78v0z"
        stroke="url(#heart-gradient_svg__paint0_linear)"
        strokeWidth={1.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="heart-gradient_svg__paint0_linear"
          x1={12}
          y1={2.998}
          x2={12}
          y2={21.23}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#630099" stopOpacity={0.74} />
          <stop offset={1} stopColor="#0F2CC5" stopOpacity={0.73} />
        </linearGradient>
      </defs>
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHeartGradient);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
