import styled from 'styled-components';
import {colorTheme} from '../../configs';

export interface StyleProps {
  error?: string;
  hasValue?: boolean;
}

export const StyledDatePicker = styled.div<StyleProps>`
  position: relative;
  input {
    cursor: pointer;
  }

  input:disabled {
    color: ${colorTheme.lightGray};
    border: 1px solid #e2e2e2;
  }

  input:placeholder {
    color: ${colorTheme.darkFaded};
    border: 1px solid #e2e2e2;
  }
`;
