import Page from '../../../core/components/page/Page';
import {Checkbox, Input, Typehead, Typography} from '../../../core/components';
import {colorTheme} from '../../../core/configs';
import {useHistory} from 'react-router-dom';
import {
  getLocations,
  getRoles,
  usePostFilterSettings,
} from '../../server/react-query';
import {useEffect, useState} from 'react';
import {
  FilterSettingResponse,
  KazamException,
  KazamInformation,
  LocationResponse,
  Role,
} from '../../server/types';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {PostFilterSettingsBody} from '../../server/types/filter-settings.types';
import {RoutePath} from '../../navigation/config/RouteConfig';
import {useAuth} from '../../hooks/useAuth';
import EmploymentComponent from './EmploymentComponent';
import {useQuery} from 'react-query';
import {localization} from '../../localization/Localization';
import {isMobile} from 'react-device-detect';
import {signUpJobSeekerInformationSchema} from '../../schema/sign-up.schema';
import { STORAGE } from '../../utils/storage.utils';
import { useToast } from '../../../core/components/toast';

interface RoleState {
  roleId: string;
  text: string;
  value: number;
  state: boolean;
}

const KazamFilterSettings = () => {
  const history = useHistory();
  const {presentToast} = useToast();
  
  const {updateAuthData, authData} = useAuth();
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [roles, setRoles] = useState<RoleState[]>([]);
  const [locations, setLocations] = useState<LocationResponse[]>([]);
  const [showRoleError, setShowRoleError] = useState(false);
  const [fetchRoles, setFetchRoles] = useState(false);
  const [fetchLocations, setFetchLocations] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<LocationResponse>();

  const postFilterSettings = usePostFilterSettings();
  const {data: rolesData, isFetching: isRolesFetching} = useQuery(
    ['get-roles'],
    getRoles,
    {
      enabled: fetchRoles,
    },
  );

  const {data: locationsData, isFetching: isLocationsFetching} = useQuery(
    ['get-locations'],
    getLocations,
    {
      enabled: fetchLocations,
    },
  );

  const {
    control,
    getValues,
    setValue,
    setError,
    formState: {errors},
    handleSubmit,
    reset,
  } = useForm<KazamInformation>({
    resolver: yupResolver(signUpJobSeekerInformationSchema),
    defaultValues: {
      minSalary: 1,
      location: '',
      livingArrangement: 'stayIn',
    },
  });

  useEffect(() => {
    var locations = localStorage.getItem('locations');
    if (locations) {
      var locationsData = JSON.parse(locations) as LocationResponse[];
      setLocations(locationsData);

      const signUpData = STORAGE.get<KazamInformation>('signUpData', true)
      if (signUpData) {
        reset(signUpData)
      }

      if (signUpData?.location) {
        var sel = locationsData?.find(l => l.locationId === signUpData?.location)
        if (!!sel) setSelectedLocation(sel)
      }
      STORAGE.clean('signUpData')

      const selected = STORAGE.get<LocationResponse>('currentLocation', true)
      if (!!selected) setSelectedLocation(selected)

      const filterRoles = STORAGE.get<string[]>('filterRoles', true)
      if (filterRoles) {
        setSelectedRoles(filterRoles)
        STORAGE.clean('filterRoles')
      }
    } else {
      setFetchLocations(true);
    }

    var roles = localStorage.getItem('roles');
    if (roles) {
      var rolesData = JSON.parse(roles) as Role[];
      setRolesData(rolesData);
    } else {
      setFetchRoles(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (locationsData) {
      setLocations(locationsData);
      localStorage.setItem('locations', JSON.stringify(locationsData));
    }
  }, [locationsData]);

  useEffect(() => {
    const roles = rolesData as Role[];
    setRolesData(roles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesData]);

  const setRolesData = (roles: Role[]) => {
    if (roles && roles.length > 0) {
      const roleState = roles?.map((role, i) => {
        return {
          roleId: role.roleId,
          text: role.text,
          state: false,
          value: i,
        };
      });
      setRoles(roleState);
      localStorage.setItem('roles', JSON.stringify(roles));
    }
  };
  const updateRole = (data: any) => {
    if (roles.length > 0) {
      let items = [...roles];
      let item = items[data.role.value];
      item.state = data.state;
      items[data.role.value] = item;
      setRoles(items);
    }
  };

  const submitForm = async () => {
    handleRoleEror();
    handleSubmit(handleSave)();
  };

  const handleSave = async () => {
    try {
      var data = getValues();

      var selectedRoles = roles.filter(
        (role: {state: boolean}) => role.state === true,
      );

      if (selectedRoles.length > 0 && selectedRoles.length <= 3) {
        setShowRoleError(false);

        const values: PostFilterSettingsBody = {
          salary: data.minSalary,
          livingArrangement: data.livingArrangement,
          location: data.location,
          roles: selectedRoles.map(role => {
            return role.roleId;
          }),
          searchAreaType: 'inYourCity',
        };
        var filterSettings = await postFilterSettings.mutateAsync(values);
        let authenticationData = authData;
        if (authenticationData && authenticationData.user) {
          authenticationData.user.filterSetting = filterSettings as FilterSettingResponse;
          updateAuthData(authenticationData);
        }
        STORAGE.clean('currentLocation')
        history.replace(RoutePath.MATCHES_LANDING);
      } else {
        setShowRoleError(true);
      }
    } catch (e: any) {
      console.log('error', e);
      const exception = e.data as KazamException;

      if (exception) {
        if (e.status
          && e.status === 403
          && exception.errorCode === 'SalaryLimitError') {
            setError("minSalary", {message: exception.error});
        }
        presentToast({
          message: exception.error,
          variant: 'error',
          position: 'bottom',
        });
      } else {
        presentToast({
          message: "Failed to save.",
          variant: 'error',
          position: 'bottom',
        });
      }
    }
  };

  const handleRoleEror = () => {
    var selectedRoles = roles.filter(
      (role: {state: boolean}) => role.state === true,
    );

    if (selectedRoles.length === 0 || selectedRoles.length > 3) {
      setShowRoleError(true);
    } else {
      setShowRoleError(false);
    }
  };

  const onSalaryChange = (value: any) => {
    var data = value.currentTarget.value.replace(/,/g, '');
    setValue('minSalary', parseFloat(data));
  };

  const goToCurrentLocation = () => {
    let data = getValues()
    if (selectedLocation?.locationId !== data.location) {
      STORAGE.clean('currentLocation')
    }
    
    var selectedRoles = roles.filter(r => r.state).map(r => r.roleId)
    STORAGE.save('signUpData', data, true)
    STORAGE.save('filterRoles', selectedRoles, true)
    history.push(RoutePath.SELECT_CURRENT_LOCATION);
  }

  return (
    <Page
      showLoading={isRolesFetching || isLocationsFetching}
      showRightText={isMobile}
      showDesktopSaveButton={!isMobile}
      rightText="Save"
      onRightActionClick={submitForm}
      rightActionIsLoading={postFilterSettings.isLoading}>
      <div className="space-y-4">
        <div>
          <Typography
            label={localization.hdr_kzm_info}
            variant="f3"
            align="center"
            color={colorTheme.dark}></Typography>
          <div className="pt-1">
            <Typography
              label={localization.desc_kzm_info}
              variant="f1"
              align="center"
              color={colorTheme.darkFaded}></Typography>
          </div>
        </div>
        <div className="space-y-4">
          <Typography
            label={localization.lbl_role_max}
            variant="f1"
            weight="semibold"
            align="left"
            color={showRoleError ? colorTheme.danger : colorTheme.darkFaded}
          />
          {roles?.map((role, i) => {
            return (
              <Checkbox
                key={i}
                label={role.text}
                checked={selectedRoles.includes(role.roleId)}
                onChange={state => updateRole({state, role})}
              />
            );
          })}
        </div>

        <div className="pt-2">
          <Controller
            control={control}
            name="minSalary"
            render={({field: {value = '', onBlur, onChange}}) => (
              <Input
                label={localization.lbl_min_salary}
                type="number"
                inputSize="large"
                placeholder={localization.ph_enter_salary}
                value={
                  typeof value === 'number'
                    ? value
                    : isNaN(parseFloat(value as string))
                    ? ''
                    : parseFloat(value as string)
                }
                onBlur={onBlur}
                onChange={onSalaryChange}
                error={errors.minSalary?.message}
                showThousandSeparator
              />
            )}
          />
        </div>

        <div className="pt-2">
          <>
            <Controller
              control={control}
              name="location"
              render={({field: {value, onChange}}) => (
                <Typehead
                  label={localization.lbl_your_loc}
                  items={locations}
                  placeholder={localization.ph_search_loc}
                  onChange={sel => {
                    var item = sel && sel.length > 0 ? sel[0].locationId : '';
                    onChange(item);
                  }}
                  selected={selectedLocation}
                  error={errors.location?.message}></Typehead>
              )}
            />

            <div onClick={goToCurrentLocation}>
              <Typography
                className="cursor-pointer"
                label="Use current location"
                variant="f1"
                weight="normal"
                color={colorTheme.primary}
              />
            </div>
          </>
        </div>

        <div className="pt-2">
          <Controller
            control={control}
            name="livingArrangement"
            render={({field: {value, onChange}}) => (
              <EmploymentComponent value={value} onChange={onChange} />
            )}
          />
        </div>
      </div>
    </Page>
  );
};

export default KazamFilterSettings;
