import {isMobile} from 'react-device-detect';
import styled, {css} from 'styled-components';
import {colorTheme} from '../../../../../core/configs';

export const StyledWrapper = styled.div`
  position: fixed;
  padding: 12px 16px;
  border-top: 1px solid ${colorTheme.lightGray};
  background: white;
  bottom: 0;
  left: 300px;
  right: 0;
`;

export const StyledDisclaimer = styled.div`
  background: #aabdd5;
  padding: 0 8px;
  position: absolute;
  top: -15px;
  left: 0;
  right: 0;

  & .overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledTextArea = styled.textarea`
  width: 100%;
  border-radius: 4px;
  height: 16px;
  max-height: 100%;

  resize: none;

  color: ${colorTheme.dark};

  font-size: 11.2px;
  line-height: 16.8px;

  padding: 0;

  &:focus {
    outline: none;
  }

  &:disabled {
    color: ${colorTheme.lightGray};
  }
`;
