import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';

type ConversationLogType = 'none' | 'verbose' | 'info' | 'warning' | 'error';

type ConversationClientConfig = {
  url: string;
  logLevel?: ConversationLogType;
};

type ConversationEventType =
  | 'connecting'
  | 'connected'
  | 'disconnected'
  | 'reconnecting'
  | 'reconnected'
  | 'connectFailed'
  | 'messageReceive'
  | 'conversationEnded'
  | 'conversationEnabled'
  | 'reFetchContents';

type ConversationEvent = (...args: any[]) => void;

interface IConversationClient {
  on(event: ConversationEventType, callback: ConversationEvent): void;

  create(conversationId: string): Promise<void>;

  removeCallback(callback: ConversationEvent): void;

  clearCallbacks(eventType: ConversationEventType): void;

  stop(): Promise<void>;

  join(userId: string): Promise<boolean>;

  send(userId: string, message: string): Promise<void>;

  sendMedia(userId: string, file: any): Promise<void>;
}

export class ConversationClient implements IConversationClient {
  private config: ConversationClientConfig;
  private hubConnection?: HubConnection;
  private conversationId?: string;
  private eventCallbacks: Record<ConversationEventType, ConversationEvent[]> = {
    connecting: [],
    connected: [],
    disconnected: [],
    reconnecting: [],
    reconnected: [],
    connectFailed: [],
    messageReceive: [],
    conversationEnabled: [],
    conversationEnded: [],
    reFetchContents: [],
  };

  constructor(config: ConversationClientConfig) {
    this.config = config;
  }

  async stop(): Promise<void> {
    if (this.hubConnection) {
      await this.hubConnection.stop();
    }
  }

  private shouldLogVerbose(): boolean {
    return this.config.logLevel === 'verbose';
  }

  private shouldLogInfo(): boolean {
    return this.shouldLogVerbose() || this.config.logLevel === 'info';
  }

  private shouldLogWarning(): boolean {
    return this.shouldLogVerbose() || this.config.logLevel === 'warning';
  }

  private shouldLogError(): boolean {
    return this.shouldLogVerbose() || this.config.logLevel === 'error';
  }

  on(event: ConversationEventType, callback: ConversationEvent): void {
    // Register event
    this.eventCallbacks[event].push(callback);
  }

  async create(conversationId: string): Promise<void> {
    this.conversationId = conversationId;

    if (this.shouldLogInfo()) {
      console.log('connecting');
    }
    this.invokeCallback('connecting');

    const connection = new HubConnectionBuilder()
      .withUrl(this.config.url)
      .configureLogging(
        {
          none: LogLevel.None,
          verbose: LogLevel.Debug,
          info: LogLevel.Information,
          warning: LogLevel.Warning,
          error: LogLevel.Error,
        }[this.config.logLevel || 'none'],
      )
      .build();

    try {
      await connection.start();
      this.hubConnection = connection;
      this.listenToHubLifecycleEvents();
      this.listenToHubMessages();
      this.listenifConversationEnded();
      this.listenifConversationIsEnabled();
      this.listenifRefetchContentsIsTriggered();

      if (this.shouldLogInfo()) {
        console.log('Connected');
      }
      this.invokeCallback('connected', connection.connectionId);
    } catch (e: any) {
      if (this.shouldLogError()) {
        console.log('Connect to hub failed', e);
      }
      this.invokeCallback('connectFailed', e);
    }
  }

  private listenToHubLifecycleEvents() {
    this.hubConnection!.onclose((error: Error | undefined) => {
      if (this.shouldLogVerbose()) {
        console.log('disconnected', {error});
      }
      this.invokeCallback('disconnected', error);
    });
    this.hubConnection!.onreconnected((connectionId: string | undefined) => {
      if (this.shouldLogVerbose()) {
        console.log('reconnected', {connectionId});
      }
      this.invokeCallback('reconnected', connectionId);
    });
    this.hubConnection!.onreconnecting((error: Error | undefined) => {
      if (this.shouldLogVerbose()) {
        console.log('reconnecting', {error});
      }
      this.invokeCallback('reconnecting', error);
    });
  }

  private listenToHubMessages() {
    this.hubConnection!.on('messageReceive', (data: string) => {
      if (this.shouldLogVerbose()) {
        console.log('message received', data);
      }
      this.invokeCallback('messageReceive', data);
    });
  }

  private listenifConversationEnded() {
    this.hubConnection!.on('conversationEnded', (data: string) => {
      if (this.shouldLogVerbose()) {
        console.log('conversation ended', data);
      }
      this.invokeCallback('conversationEnded', data);
    });
  }

  private listenifConversationIsEnabled() {
    this.hubConnection!.on('conversationEnabled', (data: string) => {
      if (this.shouldLogVerbose()) {
        console.log('conversation enabled', data);
      }
      this.invokeCallback('conversationEnabled', data);
    });
  }

  private listenifRefetchContentsIsTriggered() {
    this.hubConnection!.on('reFetchContents', (data: string) => {
      if (this.shouldLogVerbose()) {
        console.log('reFetchContents triggered', data);
      }
      this.invokeCallback('reFetchContents', data);
    });
  }

  removeCallback(callback: ConversationEvent): void {
    this.eventCallbacks['connecting'] = this.eventCallbacks[
      'connecting'
    ].filter((i: ConversationEvent) => i !== callback);
    this.eventCallbacks['connected'] = this.eventCallbacks['connected'].filter(
      (i: ConversationEvent) => i !== callback,
    );
    this.eventCallbacks['reconnecting'] = this.eventCallbacks[
      'reconnecting'
    ].filter((i: ConversationEvent) => i !== callback);
    this.eventCallbacks['reconnected'] = this.eventCallbacks[
      'reconnected'
    ].filter((i: ConversationEvent) => i !== callback);
    this.eventCallbacks['disconnected'] = this.eventCallbacks[
      'disconnected'
    ].filter((i: ConversationEvent) => i !== callback);
    this.eventCallbacks['connectFailed'] = this.eventCallbacks[
      'connectFailed'
    ].filter((i: ConversationEvent) => i !== callback);
    this.eventCallbacks['messageReceive'] = this.eventCallbacks[
      'messageReceive'
    ].filter((i: ConversationEvent) => i !== callback);
    this.eventCallbacks['conversationEnded'] = this.eventCallbacks[
      'conversationEnded'
    ].filter((i: ConversationEvent) => i !== callback);
    this.eventCallbacks['conversationEnabled'] = this.eventCallbacks[
      'conversationEnabled'
    ].filter((i: ConversationEvent) => i !== callback);
    this.eventCallbacks['reFetchContents'] = this.eventCallbacks[
      'reFetchContents'
    ].filter((i: ConversationEvent) => i !== callback);
  }

  clearCallbacks(eventType?: ConversationEventType): void {
    if (eventType) {
      this.eventCallbacks[eventType] = [];
    } else {
      this.eventCallbacks['connecting'] = [];
      this.eventCallbacks['connected'] = [];
      this.eventCallbacks['disconnected'] = [];
      this.eventCallbacks['reconnecting'] = [];
      this.eventCallbacks['reconnected'] = [];
      this.eventCallbacks['connectFailed'] = [];
      this.eventCallbacks['messageReceive'] = [];
      this.eventCallbacks['conversationEnded'] = [];
      this.eventCallbacks['conversationEnabled'] = [];
      this.eventCallbacks['reFetchContents'] = [];
    }
  }

  private invokeCallback(eventType: ConversationEventType, ...args: any[]) {
    for (let i = 0; i < this.eventCallbacks[eventType].length; i++) {
      this.eventCallbacks[eventType][i](...args);
    }
  }

  async send(userId: string, message: string): Promise<void> {
    if (this.hubConnection) {
      await this.hubConnection.send(
        'messageNew',
        userId,
        this.conversationId,
        message,
      );
    }
  }

  async sendMedia(userId: string, content: string): Promise<void> {
    if (this.hubConnection) {
      await this.hubConnection.send('mediaNew', this.conversationId, content);
    }
  }

  async join(userId: string): Promise<boolean> {
    if (this.hubConnection) {
      const joinResult = await this.hubConnection.invoke(
        'joinConversation',
        userId,
        this.conversationId,
      );
      return joinResult === true;
    }
    return false;
  }

  async endConversation(): Promise<void> {
    if (this.hubConnection) {
      await this.hubConnection.send(
        'conversationEnded',
        this.conversationId
      );
    }
  }

  async enableConversation(): Promise<void> {
    if (this.hubConnection) {
      await this.hubConnection.send(
        'conversationEnabled',
        this.conversationId
      );
    }
  }

  async triggerRefetchContents(): Promise<void> {
    if (this.hubConnection) {
      await this.hubConnection.send(
        'reFetchContents',
        this.conversationId
      );
    }
  }
}
