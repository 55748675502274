import {useMutation, useQueryClient} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';
import {QueryKeys} from './config/QueryConfig';
import {PostFilterSettingsBody} from '../types/filter-settings.types';

const usePostFilterSettings = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [QueryKeys.POST_FILTER_SETTINGS],
    (data: PostFilterSettingsBody) =>
      API.post(ApiEndpointsEnum.POST_FILTER_SETTINGS, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.POST_FILTER_SETTINGS]);
      },
    },
  );
};

export {usePostFilterSettings};
