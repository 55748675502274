import {useEffect, useReducer, useState} from 'react';
import AdminTable from '../AdminTable';
import {StyledAdminUsersTable} from '../styled';
import {useToast} from '../../../../../core/components/toast';
import {Column} from 'react-table';
import {Typography} from '../../../../../core/components';
import {colorTheme} from '../../../../../core/configs';
import {useAnalytics} from '../../../../hooks/useAnalytics';
import {useAuth} from '../../../../hooks/useAuth';
import {
  useGetForVerificationUsers,
  usePatchManualVerification,
} from '../../../../server/react-query/useAdminUsers';
import {useHistory} from 'react-router-dom';
import {usePostConversation} from '../../../../server/react-query';
import {ChatResponse, VerifiedStatus} from '../../../../server/types';
import {RoutePath} from '../../../../navigation/config/RouteConfig';
import {
  INITIAL_SEARCH_STATE,
  reducer,
} from '../verified/AdminVerifiedUsersTable';

interface TableData {
  userId: string;
  shuftiRef: string;
  name: string;
  location: string;
  userType: string;
  userStatus: string;
}

interface AdminUsersTableProps {
  searchQuery: string;
}

const AdminForVerificationUsersTable = (props: AdminUsersTableProps) => {
  const history = useHistory();

  const {searchQuery: propSearchQuery} = props;

  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [data, setData] = useState<TableData[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>(propSearchQuery);
  const [searchBody, dispatchSearch] = useReducer(
    reducer,
    INITIAL_SEARCH_STATE,
  );
  const {data: forVerificationUsers, refetch} =
    useGetForVerificationUsers(searchBody);

  const {systemLogEvent} = useAnalytics();
  const {authData} = useAuth();

  const {presentToast} = useToast();

  const [columns] = useState<Column<TableData>[]>([
    {
      Header: 'Name',
      accessor: 'name', // accessor is the "key" in the data
    },
    {
      Header: 'User ID',
      accessor: 'userId',
    },
    {
      Header: 'Shufti Reference ID',
      accessor: 'shuftiRef',
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'User Type',
      accessor: 'userType',
    },
    {
      Header: 'Action',
      accessor: 'action',
    },
  ] as Column<TableData>[]);

  useEffect(() => {
    refetch();
  }, [searchBody]);

  useEffect(() => {
    setPageNumber(1);
    setSearchQuery(propSearchQuery);
  }, [propSearchQuery]);

  useEffect(() => {
    dispatchSearch({
      type: 'SET_SEARCH',
      payload: {
        search: searchQuery?.trim(),
      },
    });
  }, [searchQuery]);

  const onPageChangedHandler = (pageNumber: number) => {
    setPageNumber(pageNumber);
    dispatchSearch({
      type: 'SET_PAGE',
      payload: {
        page: pageNumber,
      },
    });
  };

  useEffect(() => {
    if (forVerificationUsers) {
      setPageCount(
        Math.ceil(forVerificationUsers.total / forVerificationUsers.pageSize),
      );
      setData(
        forVerificationUsers.data.map(i => {
          const userId = i.userId;
          const shuftiRef = i.lastRevokedVerificationId;
          const name = `${i.firstName} ${i.lastName}`;

          let location = '';
          if (i.filterSetting) {
            const userLocation = i.filterSetting.location;
            if (userLocation) {
              location = userLocation?.cityOrMunicipality;
              if (userLocation.hasProvince) {
                location += `, ${userLocation.province.name}`;
              }
            }
          }

          const userType =
            i.userRole === 'homeowner' ? 'Homeowner' : 'Kasambahay';
          return {
            userId,
            shuftiRef,
            name,
            location,
            userType,
          } as TableData;
        }),
      );
    }
  }, [forVerificationUsers]);

  const patchManualVerification = usePatchManualVerification();
  const onChangeVerifiedStatus = async (
    rowData: TableData,
    verifiedStatus: VerifiedStatus,
  ) => {
    try {
      await patchManualVerification.mutateAsync({
        userId: rowData.userId,
        verifiedStatus,
      });

      presentToast({
        message: 'Successfully updated.',
        variant: 'success',
        position: 'bottom',
      });

      await refetch();
    } catch (e) {
      console.log(e);
      presentToast({
        message: `Unexpected error was occurred.`,
        variant: 'error',
        position: 'bottom',
      });
    }
  };

  const postConversation = usePostConversation();
  const onChatUserClickedHandler = async (rowData: TableData) => {
    try {
      var conversation = (await postConversation.mutateAsync({
        conversationChannelName: `Conversation with ${rowData.userId}`,
        memberIds: [rowData.userId || '', authData?.user?.userId || ''],
      })) as ChatResponse;

      if (conversation) {
        history.push(
          RoutePath.ADMIN_CHAT_ROOM.replace(
            ':id',
            conversation.conversationChannelId,
          ),
        );
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const manualRenderFn = (columnId: string, rowData: TableData) => {
    return (
      <div className="flex space-x-2">
        <div
          className="cursor-pointer"
          onClick={() => onChatUserClickedHandler(rowData)}>
          <Typography
            label="Chat"
            variant="f2"
            color={colorTheme.primary}
            align="left"
          />
        </div>

        <div>|</div>

        <div
          className="cursor-pointer"
          onClick={() => onChangeVerifiedStatus(rowData, 'verified')}>
          <Typography
            label="Verify"
            variant="f2"
            color={colorTheme.primary}
            align="left"
          />
        </div>

        <div>|</div>

        <div
          className="cursor-pointer"
          onClick={() => onChangeVerifiedStatus(rowData, 'rejected')}>
          <Typography
            label="Reject"
            variant="f2"
            color={colorTheme.primary}
            align="left"
          />
        </div>
      </div>
    );
  };

  return (
    <StyledAdminUsersTable>
      <AdminTable
        columns={columns}
        data={data}
        pageSize={10}
        pageCount={pageCount}
        pageNumber={pageNumber}
        showDebugView={false}
        onPageChange={onPageChangedHandler}
        manualRenderFn={manualRenderFn}
      />
    </StyledAdminUsersTable>
  );
};

export default AdminForVerificationUsersTable;
