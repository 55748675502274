import Page from '../../../../core/components/page/Page';
import {Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {localization} from '../../../localization/Localization';
import SubscriptionHistoryItem from './SubscriptionHistoryItem';
import { useGetSubscriptionHistory } from '../../../server/react-query/useUserSubscription';
import { BeatLoader } from 'react-spinners';
import format from 'date-fns/format';

const SubscriptionHistoryPage = () => {
  const { data, isFetching } = useGetSubscriptionHistory()

  return (
    <Page showBackButton showDesktopBackButton>
      <div className="space-y-4">
        <div>
          <Typography
            label={localization.hdr_subscription_history}
            variant="f3"
            align="center"
            color={colorTheme.dark}
          />
          <div className="pt-1">
            <Typography
              label={localization.desc_susbcription_history}
              variant="f1"
              align="center"
              color={colorTheme.darkFaded}></Typography>
          </div>
        </div>

        <div>
          {isFetching ? (
             <div
              className="w-full flex justify-center items-center">
              <BeatLoader
                color={colorTheme.primary}
                loading={true}
                margin={2}
                size={15}
              />
            </div>
          ) : (
            data
            && data.length > 0 &&
            <div className="space-y-4">
              {data.map((s, i) => {
                return (
                  <SubscriptionHistoryItem
                    key={i}
                    label={s.name}
                    dateSubscribed={`${format(new Date(s.startDate), 'PP')}, ${format(new Date(s.startDate), 'p')}`}
                    amount={s.amount}
                    data={s}
                  />
                )
              })}
            </div>
          )}
        </div>
      </div>
    </Page>
  );
};

export default SubscriptionHistoryPage;
