import styled from "styled-components";

export const StyledHideConfirmationDialog = styled.div`
  padding: 0 16px 22px 16px;

  & .spacer {
    width: 16px;
  }

  & .actions {
    margin-top: 16px;
  }

  & .message {
    margin-top: 8px;
  }
`;