import { useHistory, useParams } from "react-router-dom";
import Page from "../../../../core/components/page/Page";
import { useEffect } from "react";
import { useDialog } from "../../../../core/components/dialog";
import SuccessfulPaymentDialogView from "../../../views/dialog-content/paywall/successful-payment-dialog/SuccessfulPaymentDialogView";
import { RoutePath } from "../../../navigation/config/RouteConfig";
import { useQuery } from "react-query";
import { useGetConversation, usePostConversation } from "../../../server/react-query";
import { ChatResponse } from "../../../server/types";
import { useAuth } from "../../../hooks/useAuth";
import ProcessingPaymentDialogView from "../../../views/dialog-content/paywall/successful-payment-dialog/ProcessingPaymentDialogView";
import { getCheckoutSession } from "../../../server/react-query/usePayment";
interface PaymentSuccessRedirectionParams {
  id: string;
}

const PaymentSuccessRedirection = () => {
  const { presentDialog, dismissDialog } = useDialog()
  let history = useHistory()
  const {authData} = useAuth();
  
  const {id: userId} = useParams<PaymentSuccessRedirectionParams>()

  const {data: conversations} = useGetConversation();

  const {data} = useQuery(
    ['get-checkout-session'],
    () => getCheckoutSession(),
    {
      retry: 10
    },
  );

  useEffect(() => {
    showProcessingPayment()
  }, [userId])
  
  useEffect(() => {
    if (data) {
      dismissDialog()
      let conversation = existingConversation();

      if (conversation) {
        showSuccessModal(conversation.conversationChannelId)
      } else {
        createConversation()
      }
    }
  }, [data])

  const showProcessingPayment = () => {
    presentDialog({
      headerText: '',
      enableBackdropDismiss: false,
      hideClose: true,
      content: (
        <ProcessingPaymentDialogView />
      )
    });
  }

  const showSuccessModal = (conversationId: string) => {
    presentDialog({
      headerText: '',
      enableBackdropDismiss: false,
      hideClose: true,
      content: (
        <SuccessfulPaymentDialogView
          data={data}
          onContinueClick={() => {
            dismissDialog()
            const params = `?fromCarousel=true`
            history.replace(
              RoutePath.CHAT_ROOM.replace(
                ':id',
                conversationId,
              ) + params
            );
          }}
        />
      )
    });
  }

  const existingConversation = () => {
    return conversations?.find(
      c => !!c.members.find(m => m.userId === userId),
    );
  }

  const postConversation = usePostConversation();
  const createConversation = async () => {
    try {
      var conversation = (await postConversation.mutateAsync({
        conversationChannelName: `Conversation with ${userId}`, // Use user's name as conversation name
        memberIds: [userId || '', authData?.user?.userId || '']
      })) as ChatResponse;
      showSuccessModal(conversation.conversationChannelId)
    } catch (e: any) {
      console.log(e);
    }
  }
  
  return (
    <Page showLoading>
        <></>
    </Page>
  );
};

export default PaymentSuccessRedirection;
