import styled from 'styled-components';
import {colorTheme} from '../../../../core/configs';

export const StyledDisclaimerContent = styled.div`
  padding: 16px;
  border: 1px solid ${colorTheme.lightGray};
  border-radius: 4px;
  max-height: 175px;
  overflow: auto;
`;
