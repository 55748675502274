import React, {Fragment, useEffect, useState} from 'react';
import {Listbox, Transition} from '@headlessui/react';

import {Typography, Icon} from '../../../core/components';
import {colorTheme} from '../../../core/configs';
import VerifiedTag from '../verified-tag';

type OptionsType = 'float' | 'inline';

type BaseSelectProps<T = any> = {
  items: T[];
  itemRenderer: (item: T, selected: boolean) => React.ReactNode;

  valueRenderer: (value: T) => string;

  onChange?: (value?: T) => void;

  label?: string;
  placeholder?: string;

  maxOverflow?: number;

  error?: string;

  type?: OptionsType;

  canSelectNone?: boolean;

  color?: string;

  borderless?: boolean;

  iconClassName?: string;

  unPadded?: boolean;
  disabled?: boolean
  verified?: boolean
};

type SelectProps<T = any, S = keyof T> = (
  | {
      idKey: S;
      idValue?: any;
      value?: never;
    }
  | {
      value?: T;
      idKey?: never;
      idValue?: never;
    }
) &
  BaseSelectProps<T>;

const maxHeight = (items: number) => 32 * items + 12 + 'px';

const Select = <T,>(props: SelectProps<T>) => {
  const {
    idValue,
    idKey,
    value,
    valueRenderer,
    items,
    itemRenderer,
    onChange,
    label,
    placeholder,
    maxOverflow = 5,
    error,
    type = 'float',
    canSelectNone = false,
    color,
    borderless,
    iconClassName,
    unPadded,
    disabled = false,
    verified = false,
  } = props;

  const getValue = () => {
    if (value) {
      return value;
    }
    if (idKey) {
      return items.find(i => i[idKey] === idValue);
    }
    return undefined;
  };

  const [currentValue, setCurrentValue] = useState<T | undefined>(getValue());

  useEffect(() => {
    setCurrentValue(value || undefined);
  }, [value, items]);

  useEffect(() => {
    if (idKey) {
      const item = items.find(i => i[idKey] === idValue);
      setCurrentValue(item || undefined);
    }
  }, [idKey, idValue, items]);

  useEffect(() => {
    if (onChange) {
      onChange(currentValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  return (
    <Listbox value={currentValue} onChange={setCurrentValue} disabled={disabled}>
      {({open}) => (
        <div className="w-full">
          {label && (
            <div className='flex space-x-1 items-center mb-1'>
              <div
                style={{
                  fontSize: '0.8rem',
                  fontWeight: 'bold',
                  lineHeight: '16.8px',
                  color: colorTheme.darkFaded,
                }}>
                {label}
              </div>

              {verified && <VerifiedTag />}
            </div>
          )}

          <div className="relative">
            <Listbox.Button className="w-full">
              <div
                className={`w-full h-8 flex items-center rounded ${
                  unPadded ? '' : 'p-4'
                }`}
                style={{
                  height: unPadded ? undefined : '40px',
                  backgroundColor: 'white',
                  border: borderless ? undefined : `1px solid`,
                  borderColor: error
                    ? colorTheme.danger
                    : borderless
                    ? undefined
                    : colorTheme.lightGray,
                }}>
                {currentValue ? (
                  <Typography
                    label={valueRenderer(currentValue)}
                    variant="f2"
                    color={color ? color : disabled ? colorTheme.lightGray : colorTheme.dark}
                    className="flex-1 truncate"
                  />
                ) : (
                  <Typography
                    label={placeholder || '--'}
                    variant="f2"
                    color={color ? color : colorTheme.darkFaded}
                    className="flex-1"
                  />
                )}

                <Icon
                  name={open ? 'chevronUp' : 'chevronDown'}
                  size={16}
                  color={color ? color : disabled ? colorTheme.lightGray : colorTheme.dark}
                  type="button"
                  className={iconClassName}
                />
              </div>
            </Listbox.Button>

            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Listbox.Options
                className={
                  'mt-1 w-full border border-borderLightGray rounded py-1' +
                  (type === 'float' ? ' absolute' : '')
                }
                style={{
                  backgroundColor: 'white',
                  maxHeight: maxHeight(maxOverflow),
                  overflowY: 'auto',
                  zIndex: 101
                }}>
                {items.length === 0 ? (
                  <>
                    <Typography
                      label="--"
                      variant="f1"
                      color={colorTheme.darkFaded}
                      className="flex-1 p-2"
                    />
                  </>
                ) : (
                  <>
                    {canSelectNone && (
                      <Listbox.Option
                        value={undefined}
                        className="cursor-pointer">
                        <div className="h-8 hover:bg-bgWhiteHover p-2">
                          <Typography
                            label="--"
                            variant="f1"
                            color={colorTheme.darkFaded}
                            className="flex-1"
                          />
                        </div>
                      </Listbox.Option>
                    )}
                    {items.map((item, index) => (
                      <Listbox.Option
                        key={index}
                        value={item}
                        className="cursor-pointer">
                        {({selected, active}) => (
                          <div
                            className={
                              'hover:bg-bgWhiteHover p-2' +
                              (active ? ' bg-bgWhiteHover' : '')
                            }>
                            {itemRenderer(item, selected)}
                          </div>
                        )}
                      </Listbox.Option>
                    ))}
                  </>
                )}
              </Listbox.Options>
            </Transition>
          </div>

          {error && (
            <div
              data-testid="error-msg-element"
              style={{
                marginTop: '0.25rem',
                fontSize: '0.75rem',
                lineHeight: '1.125rem',
                color: colorTheme.danger,
              }}>
              {error}
            </div>
          )}
        </div>
      )}
    </Listbox>
  );
};

export default Select;
