import {useEffect} from 'react';
import {
  PushNotificationSchema,
  PushNotifications,
  Token,
  ActionPerformed,
} from '@capacitor/push-notifications';
import {useHistory} from 'react-router-dom';
import {RoutePath} from '../../../app/navigation/config/RouteConfig';
import {useToast} from '../../../core/components/toast';
import {
  usePatchUserProfileFCMToken,
  usePostFcmToken,
} from '../../server/react-query';
import {Device} from '@capacitor/device';
import {FcmTokenBody} from '../../server/types';
import {isUsingBrowser} from '../../utils/random.util';
import {useDialog} from '../../../core/components/dialog';
import InAppCallNotificationView from '../../pages/call-room/InAppCallNotificationView';
import {LocalNotifications} from '@capacitor/local-notifications';
import {isMobile} from 'react-device-detect';

const PushNotificationsListener = () => {
  const history = useHistory();
  const {presentToast} = useToast();
  const patchUserProfileFCMToken = usePatchUserProfileFCMToken();
  const postFcmToken = usePostFcmToken();
  const {presentDialog} = useDialog();

  // To load once
  useEffect(() => {
    initFirebasPusNotifs();
    if (isMobile) {
      createLocalNotifChannel();
      registerActionsForCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initFirebasPusNotifs = async () => {
    if (!isUsingBrowser()) {
      PushNotifications.checkPermissions().then(res => {
        if (res.receive !== 'granted') {
          PushNotifications.requestPermissions().then(res => {
            if (res.receive === 'denied') {
              console.log('Push Notification permission denied');
            } else {
              register();
            }
          });
        } else {
          register();
        }
      });
    }
  };

  const getDeviceId = async (): Promise<string> => {
    const id = await Device.getId();
    return id.identifier;
  };

  const register = () => {
    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', async (token: Token) => {
      const id = await getDeviceId();
      console.log('sucess token registration: ' + JSON.stringify(token));
      console.log('device Id: ' + id);
      // await patchUserProfileFCMToken.mutateAsync({token: token.value});

      const data: FcmTokenBody = {
        type: 'mobile',
        deviceId: id,
        token: token.value,
      };

      const res = await postFcmToken.mutateAsync(data);
      localStorage.setItem('fcmTokenId', res.fcmTokenId);
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error on registration: ' + JSON.stringify(error));
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log('pushNotificationReceived');
        console.log(notification);
        if (
          notification.data.type !== 'Chat' &&
          !notification.data.type.includes('Call')
        ) {
          presentToast({
            title: notification.data.title,
            message: notification.data.body,
            notificationType: notification.data.type,
            notifFromUserId: notification.data.fromUserId,
            variant: 'notification',
            position: 'top',
          });
        }

        if (notification.data.type.includes('Call')) {
          // separate checking for system notifications
          const callType =
            notification.data.type === 'VideoCall' ? 'video' : 'voice';

          showCallingDialog(notification.data.fromUserId, callType);
        }
      },
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('pushNotificationActionPerformed');
        console.log(notification);
        handleNotificationRedirection(notification);
      },
    );
  };

  const showCallingDialog = (id: string, callType: 'video' | 'voice') => {
    presentDialog({
      headerText: '',
      enableBackdropDismiss: false,
      hideClose: true,
      fullWidth: true,
      hideHeader: true,
      useFullScreen: true,
      content: <InAppCallNotificationView id={id} callType={callType} />,
    });
  };

  const handleNotificationRedirection = (notif: ActionPerformed) => {
    if (notif && notif.notification.data) {
      var data = notif.notification.data;
      if (
        ['JobOffer', 'Chat', 'ChatAttachment', 'VideoCall', 'VoiceCall'].includes(data.type)
      ) {
        const isACall = data.type === 'VideoCall' || data.type === 'VoiceCall';
        if (data.notifTime) {
          if (isACall) {
            const notifTime = new Date(`${data.notifTime} UTC`);
            const expirationTime = new Date(notifTime.getTime() + 30 * 1000);
            const localCurrentTime = new Date().toLocaleString();

            if (
              new Date(localCurrentTime).getTime() > expirationTime.getTime()
            ) {
              redirectToChat(data.fromUserId);
            } else {
              const callType = data.type === 'VoiceCall' ? 'voice' : 'video';
              attemptToAnswerCall(data.fromUserId, callType);
            }
          } else {
            redirectToChat(data.fromUserId);
          }
        } else {
          redirectToChat(data.fromUserId);
        }
      } else if (
        data.type === 'KawayFromFavorites' ||
        data.type === 'KawayFromMatches'
      ) {
        history.push(RoutePath.KAWAYS);
        history.push(RoutePath.FAVORITE_VIEW.replace(':id', data.fromUserId));
      } else {
        const params = `?userId=${data.fromUserId}`;
        history.push(RoutePath.MATCHES_LANDING + params);
      }
    }
  };

  const createLocalNotifChannel = async () => {
    LocalNotifications.createChannel({
      id: 'local_notif_android',
      name: 'Notification Channel',
      importance: 5,
      description: 'Local Notification Channel for Android',
      sound: 'ringing_tone.wav',
      visibility: 1,
      vibration: true,
    });
  };

  const registerActionsForCall = async () => {
    const acceptAction = {
      id: 'CALL_ACCEPT',
      title: 'Accept',
      foreground: true,
    };

    const declineAction = {
      id: 'CALL_DECLINE',
      title: 'Decline',
    };

    const callActions = {
      id: 'CALL_ACTIONS',
      actions: [acceptAction, declineAction],
    };

    LocalNotifications.registerActionTypes({
      types: [callActions],
    });

    addLocalNotifListeners();
  };

  const attemptToAnswerCall = (id: string, callType: 'video' | 'voice') => {
    LocalNotifications.cancel({notifications: [{id: 1}, {id: 2}]});
    LocalNotifications.removeAllDeliveredNotifications();
    history.push(RoutePath.CHAT);
    setTimeout(() => {
      showCallingDialog(id, callType);
    }, 100);
  };

  const redirectToChat = (id: string) => {
    history.push(RoutePath.CHAT);
    setTimeout(async () => {
      // history.push(RoutePath.CHAT_ROOM.replace(':id', data.fromUserId));
      history.push(RoutePath.CHAT_ROOM.replace(':id', id));
    }, 100);
  };

  const addLocalNotifListeners = () => {
    LocalNotifications.addListener(
      'localNotificationActionPerformed',
      async notification => {
        const id = notification.notification.extra.id;
        const callType = notification.notification.extra.callType;

        switch (notification.actionId) {
          case 'CALL_ACCEPT':
            console.log('ACCEPTING');
            attemptToAnswerCall(id, callType);
            break;

          case 'CALL_DECLINE':
            LocalNotifications.cancel({notifications: [{id: 1}, {id: 2}]});
            LocalNotifications.removeAllDeliveredNotifications();
            console.log('DECLINING');
            break;

          default:
            attemptToAnswerCall(id, callType);
            break;
        }
      },
    );
  };

  return null;
};

export default PushNotificationsListener;
