import * as yup from 'yup';

export const jobOfferSchema = yup.object({
  salary: yup
    .number()
    .typeError('Enter a valid number.')
    .test(
      'min',
      'Minimum value is 0',
      value => typeof value === 'number' && value >= 0,
    ),
  locationId: yup.string().required('This field is required.'),
  livingArrangement: yup.string().nullable(),
  startDate: yup.date().required().typeError('Enter a valid date'),
  endDate: yup
    .date()
    .nullable(true)
    .typeError('Enter a valid date')
    .min(yup.ref('startDate'), "End date can't be before start date.")
    .transform((_, val) => val ?? null),
});

export const putJobHistorySchema = yup.object({
  terminationReason: yup.string().nullable(),
  rating: yup.number().required().min(1, 'Rating must be at least 1'),
  endDate: yup.date().required().typeError('Enter a valid date')
});
