import StepWizard from 'react-step-wizard';
import SignUpLoginInformationPage from './SignUpLoginInformationPage';
import SignUpOTPPage from './SignUpOTPPage';
import SignUpPersonalInformationPage from './SignUpPersonalInformationPage';
import SignUpJobSeekerRolesPage from './SignUpJobSeekerRolesPage';
import SignUpJobSeekerInformationPage from './SignUpJobSeekerInformationPage';
import SignUpEmailNotifsPage from './SignUpEmailNotifsPage';
import SignUpProfilePhotoUpdatePage from './SignUpProfilePhotoUpdatePage';
import SignUpConfirmationPage from './SignUpConfirmationPage';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

const SignUpWizard = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [step] = useState<string>(params.get('step') || '1');
  
  return (
    <StepWizard isLazyMount={true} initialStep={parseInt(step) ?? 1}>
      <SignUpConfirmationPage />
      <SignUpLoginInformationPage />
      <SignUpOTPPage />
      <SignUpPersonalInformationPage />
      <SignUpJobSeekerRolesPage />
      <SignUpJobSeekerInformationPage />
      <SignUpEmailNotifsPage />
      <SignUpProfilePhotoUpdatePage />
    </StepWizard>
  );
};

export default SignUpWizard;
