import simpleLogo from '../../../../assets/aboutus-simple-new-logo.svg';
import saferLogo from '../../../../assets/aboutus-safer-logo.svg';
import smarterLogo from '../../../../assets/aboutus-smarter-new-logo.svg';

import {Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {isMobile} from 'react-device-detect';
import '../style.css';
import {StyledHeader} from './styled';

const AboutUs = () => {
  var items = [{
    logo: simpleLogo,
    label: "Simpler",
    desc: "Simplified hiring - find, connect, hire"
  },
  {
    logo: saferLogo,
    label: "Safer",
    desc: "Safe and secure platform, for worry-free hiring."
  },
  {
    logo: smarterLogo,
    label: "Smarter",
    desc: "Smarter way of hiring using <br>In Chat Video Call and Personality Matching"
  }]
  return (
    <div className={`flex flex-col items-center p-6 md:p-14 gap-4 md:gap-12`}>
      <div className="px-6 lg:px-9 flex-none">
        <StyledHeader
          dangerouslySetInnerHTML={{
            __html:
              '<b>kazam</b> is an online platform connecting kasambahays such as maid, child care, senior care, cook, family driver or houseboy and homeowners directly in a simpler, safer and smarter way.',
          }}
        />
      </div>

      <div
        className="grid grid-cols-1 md:grid-cols-3 gap-12"
        style={{maxWidth: `${isMobile?'237px':'855px'}`}}>

        {items.map((item, index) => {
          return (
            <div>
              <div
                key={index}
                className="h-full flex flex-col flex-1 justify-center items-center rounded-xl p-2 md:p-4 gap-4 md:gap-6"
                style={{backgroundColor: '#F6F6F6'}}>
                <img
                  src={item.logo}
                  className="w-16 md:w-20 h-16 md:h-20 flex-1"
                  alt="simpler"
                />
                <div className="flex flex-col gap-1 h-full flex-grow">
                  <Typography
                    label={item.label}
                    color={colorTheme.primary}
                    variant={`${isMobile ? 'f1' : 'f2'}`}
                    weight="semibold"
                    align="center"
                  />
      
                  <Typography
                    label={item.desc}
                    color={colorTheme.black}
                    variant="f1"
                    align="center"
                  />
                </div>
              </div>
            </div>
            
          )
        })}
      </div>
    </div>
  );
};

export default AboutUs;
