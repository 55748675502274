import styled from 'styled-components';
import {colorTheme} from '../../../../core/configs';

export const StyledInputLabel = styled.div`
  margin-bottom: 0.25rem;

  font-size: 0.8rem;
  font-weight: 600;
  line-height: 16.8px;

  color: ${colorTheme.darkFaded};
`;

export const StyledLink = styled.div`
  cursor: pointer;
  font-size: 11px;
  color: ${colorTheme.primary};
`;

export const StyledLinkTwo = styled.div`
  cursor: pointer;
  font-size: 11px;
  color: ${colorTheme.magenta};
`;

export const StyledPending = styled.div`
  font-size: 11px;
  text-align: center;
  color: ${colorTheme.darkFaded};
`;
