import {Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {StyledImg} from './styles';
import kaway from '../../../../assets/kaway_illustration.svg';
import {NotificationResponse} from '../../../server/types/notification.types';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {RoutePath} from '../../../navigation/config/RouteConfig';

interface NotificationProps {
  data: NotificationResponse;
}

const HomeownerNotificationView = ({data}: NotificationProps) => {
  const [notifMessage, setNotifMessage] = useState<string>('');
  const history = useHistory();

  const goToKaways = () => {
    history.push(RoutePath.KAWAYS);
    history.push(RoutePath.FAVORITE_VIEW.replace(':id', data.userId));
  };

  useEffect(() => {
    if (data.user) {
      var message = '';
      if (data.notificationType === 'kawayFromFavorites') {
        message = `${data.user.firstName} from your Favorites list is online now and available to chat with you!`;
      } else {
        message = `${data.user.firstName} who matches your recent search  is online now and available to chat with you!`;
      }

      setNotifMessage(message);
    }
  }, [data]);

  return (
    <div
      className="flex flex-row space-x-2 items-center mb-6 cursor-pointer"
      onClick={goToKaways}>
      <StyledImg src={kaway} alt="searchNotif"></StyledImg>
      <div className="flex flex-col">
        <div className="flex space-x-1">
          <Typography
            label={notifMessage}
            weight={data.readOn ? 'normal' : 'semibold'}
            variant="f1"
            color={colorTheme.dark}
          />
        </div>

        <Typography
          label={`${formatDistanceToNow(new Date(data.createdOn))} ago`}
          weight="normal"
          variant="f1"
          color={data.readOn ? colorTheme.darkFaded : colorTheme.dark}
        />
      </div>
    </div>
  );
};

export default HomeownerNotificationView;
