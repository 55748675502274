import styled from 'styled-components';
import {colorTheme} from '../../../../core/configs';

export const StyledDefaultProfileView = styled.div`
  position: relative;
  width: 100%;
  height: 340px;
  background: ${colorTheme.primary};
  border-radius: 16px;

  & .flex1 {
    flex: 1 1 0%;
    min-width: 0;
  }

  & .overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledProfileOverlay = styled.div`
  padding: 16px;
  top: 0;
  position: absolute;
  width: 100%;
  height: 340px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 16px;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  border-radius: 16px;
`;
