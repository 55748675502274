import {yupResolver} from '@hookform/resolvers/yup';
import {useState} from 'react';
import {isMobile} from 'react-device-detect';
import {Controller, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {Icon, Input} from '../../../../core/components';
import Page from '../../../../core/components/page/Page';
import {useToast} from '../../../../core/components/toast';
import {colorTheme} from '../../../../core/configs';
import {localization} from '../../../localization/Localization';
import {changePasswordSchema} from '../../../schema/profile.schema';
import {putNewPassword} from '../../../server/react-query';
import {ChangePassword, ChangePasswordBody} from '../../../server/types';

const ChangePasswordPage = () => {
  const history = useHistory();
  const {presentToast} = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showRepeatNewPassword, setShowRepeatNewPassword] = useState<boolean>(false);

  const {
    control,
    getValues,
    formState: {errors},
    handleSubmit,
  } = useForm<ChangePassword>({
    resolver: yupResolver(changePasswordSchema),
    defaultValues: {
      currentPassword: '',
      password: '',
      repeatPassword: '',
    },
  });

  const submitForm = async () => {
    handleSubmit(handleSave)();
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      var data = getValues();
      const values: ChangePasswordBody = {
        newPassword: data.password,
        currentPassword: data.currentPassword,
      };
      await putNewPassword(values);

      localStorage.removeItem('lastLoginPassword');
      history.go(-1);
      setIsLoading(false);
      presentToast({
        message: 'Password changed sucessfully.',
        notificationType: '',
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      setIsLoading(false);
      presentToast({
        message: e.data.error,
        notificationType: '',
        variant: 'error',
        position: 'bottom',
      });
    }
  };

  return (
    <Page
      showBackButton
      showRightText={isMobile}
      showDesktopSaveButton={!isMobile}
      rightText="Save"
      onRightActionClick={submitForm}
      rightActionIsLoading={isLoading}>
      <div className="mb-4">
        <Controller
          control={control}
          name="currentPassword"
          render={({field: {value = '', onBlur, onChange}}) => (
            <Input
              label={localization.lbl_current_pw}
              inputSize="large"
              type={showCurrentPassword ? 'text' : 'password'}
              placeholder={localization.ph_current_pw}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              error={errors.currentPassword?.message}
              iconRight={
                <Icon
                  name={showCurrentPassword ? 'eye' : 'eyeOff'}
                  size={16}
                  color={value ? colorTheme.dark : colorTheme.darkFaded}
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                />
              }
            />
          )}
        />
      </div>

      <div className="mb-4">
        <Controller
          control={control}
          name="password"
          render={({field: {value = '', onBlur, onChange}}) => (
            <Input
              label={localization.lbl_new_pw}
              inputSize="large"
              type={showNewPassword ? 'text' : 'password'}
              placeholder={localization.ph_enter_new_pw}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              error={errors.password?.message}
              iconRight={
                <Icon
                  name={showNewPassword ? 'eye' : 'eyeOff'}
                  size={16}
                  color={value ? colorTheme.dark : colorTheme.darkFaded}
                  onClick={() => setShowNewPassword(!showNewPassword)}
                />
              }
            />
          )}
        />
      </div>

      <div>
        <Controller
          control={control}
          name="repeatPassword"
          render={({field: {value = '', onBlur, onChange}}) => (
            <Input
              label={localization.lbl_confirm_pw}
              inputSize="large"
              type={showRepeatNewPassword ? 'text' : 'password'}
              placeholder={localization.ph_repeat_pw}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              error={errors.repeatPassword?.message}
              iconRight={
                <Icon
                  name={showRepeatNewPassword ? 'eye' : 'eyeOff'}
                  size={16}
                  color={value ? colorTheme.dark : colorTheme.darkFaded}
                  onClick={() => setShowRepeatNewPassword(!showRepeatNewPassword)}
                />
              }
            />
          )}
        />
      </div>
    </Page>
  );
};

export default ChangePasswordPage;
