import React from 'react';
import {Icon, Typography} from '../../../core/components';
import {isBrowser, isMobile} from 'react-device-detect';
import {colorTheme} from '../../../core/configs';

interface CallActionsComponentProps {
  isConnected: boolean
  hasRenderedLocalVideo?: boolean
  canUseAudio?: boolean
  hasConnectedLocalAudio?: boolean
  canUseVideo?: boolean
  disabledAudio: boolean;
  isVideoCall: boolean;
  disabledVideo?: boolean;
  isKazam?: boolean;
  onMicClick: () => void;
  onCameraClick?: () => void;
  onEndCallClick: () => void;
}

const CallActionsComponent = ({
  isConnected,
  hasRenderedLocalVideo,
  canUseAudio,
  hasConnectedLocalAudio,
  canUseVideo,
  disabledAudio,
  isVideoCall,
  disabledVideo,
  isKazam,
  onMicClick,
  onCameraClick,
  onEndCallClick,
}: CallActionsComponentProps) => {
  const endCall = () => {
    onEndCallClick();
  };

  return (
    <>
      {isVideoCall ? (
        <div className="flex flex-row items-center justify-around w-full"
          style={{maxWidth: isBrowser ? '400px' : '', justifyContent: 'space-around'}}>
          {!isKazam && (
            <div
              className={`h-10 w-10 flex justify-center items-center ${
                disabledVideo ? 'rounded-full p-2 disabled-video' : ''
              }`}>
              <Icon
                className={`${isConnected ? 'opacity-100 pointer-events-auto' : 'opacity-50 pointer-events-none'}`}
                name={disabledVideo ? 'endVideo' : 'videoCall'}
                size={disabledVideo ? 24 : 32}
                type="button"
                color={colorTheme.light}
                disabled={!isConnected}
                onClick={onCameraClick}
              />
            </div>
          )}
          <div
            className={`h-10 w-10 flex justify-center items-center ${
              disabledAudio ? 'rounded-full p-2 disabled-audio' : ''
            }`}>
            <Icon
              className={`${isConnected && canUseAudio ? 'opacity-100 pointer-events-auto' : 'opacity-50 pointer-events-none'}`}
              name={disabledAudio ? 'micDisabled' : 'micEnabledWhite'}
              size={disabledAudio ? 24 : 32}
              type="button"
              disabled={!isConnected || !canUseAudio}
              onClick={onMicClick}
            />
          </div>
          <div className="rounded-full p-2 end-call h-10 w-10 flex justify-center items-center">
            <Icon
              className={`${(!isConnected || (isVideoCall && !hasRenderedLocalVideo))
                ? 'opacity-50 pointer-events-none'
                : 'opacity-100 pointer-events-auto'}`}
              name="endVoice"
              size={24}
              type="button"
              color={colorTheme.light}
              disabled={!isConnected || (isVideoCall && !hasRenderedLocalVideo)}
              onClick={endCall} />
          </div>
        </div>
      ) : (
        <div className="flex justify-between">
          <div className="flex flex-col items-center gap-y-4">
            <div className="rounded-full p-4 red-button">
              <Icon
                className={`${(!isConnected || (!isVideoCall && !hasConnectedLocalAudio))
                  ? 'opacity-50 pointer-events-none'
                  : 'opacity-100 pointer-events-auto'}`}
                name="endVoice"
                size={40}
                type="button"
                color={colorTheme.light}
                disabled={!isConnected || (!isVideoCall && !hasConnectedLocalAudio)}
                onClick={endCall} />
            </div>
            <Typography
              label="End"
              variant="f3"
              weight="normal"
              color={colorTheme.white}
              singleLine
              className="cursor-pointer"
            />
          </div>
          <div className="flex flex-col items-center gap-y-4">
            <div
              className="rounded-full p-4 enabled-button">
              <Icon
                className={`${isConnected && canUseAudio ? 'opacity-100 pointer-events-auto' : 'opacity-50 pointer-events-none'}`}
                name={disabledAudio ? 'micDisabled' : 'micEnabledWhite'}
                size={40}
                type="button"
                disabled={!isConnected || !canUseAudio}
                onClick={onMicClick}
              />
            </div>
            <Typography
              label="Mute"
              variant="f3"
              weight="normal"
              color={colorTheme.white}
              singleLine
              className="cursor-pointer"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CallActionsComponent;
