import React, {useEffect, useState} from 'react';
import {Icon, Typography} from '../../../../core/components';
import AdminPage from '../../../../core/components/admin-page/AdminPage';
import {useDialog} from '../../../../core/components/dialog';
import {colorTheme} from '../../../../core/configs';
import {Column} from 'react-table';
import AdminTable from '../users/AdminTable';
import {capitalize} from '../../../utils/random.util';
import GenericConfirmationDialog from '../../../views/dialog-content/generic-dialog/GenericConfirmationDialog';
import {useToast} from "../../../../core/components/toast";
import {useAuth} from "../../../hooks/useAuth";
import AdminSubscriptionSearch from './AdminSubscriptionSearch';
import CreateSubscriptionModalView from '../../../views/dialog-content/subscription-modal/CreateSubscriptionModalView';
import UpdateSubscriptionModalView from '../../../views/dialog-content/subscription-modal/UpdateSubscriptionModalView';
import { SubscriptionResponse } from '../../../server/types/subscription.types';
import { useDeleteSubscription, useGetSubscriptions } from '../../../server/react-query/useSubscription';
import { KazamException } from '../../../server/types';
import ExtractReportDialog from '../../../views/dialog-content/ExtractReportDialog';
import { usePostJob } from '../../../server/react-query';

interface TableData {
  subscriptionId: string
  name: string
  period: string
  amount: number
  availLimit?: number
  priority: number
  availers: number
  status: string
  startDate: Date
  endDate: Date
  createdOn: Date
}

const AdminSubscriptionPage = () => {
  const {presentDialog, dismissDialog} = useDialog();
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [searchKey, setSearchKey] = useState<string>('');
  const [subscriptionsData, setSubscriptionsData] = useState<TableData[]>([]);

  const {data, isLoading, isFetching, refetch} = useGetSubscriptions({
    searchKey: searchKey,
    pageNumber: pageNumber
  });

  const {presentToast} = useToast();
  const {authData} = useAuth();

  const [columns] = useState<Column<TableData>[]>([
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Status',
      accessor: 'status', 
    },
    {
      Header: 'Validity',
      accessor: 'period', 
    },
    {
      Header: 'Amount',
      accessor: 'amount', 
    },
    {
      Header: 'Availers',
      accessor: 'availers', 
    },
    {
      Header: 'Action',
      accessor: 'action',
    },
    {
      Header: '',
      accessor: 'open',
    },
  ] as Column<TableData>[]);

  useEffect(() => {
    if (data) {
      setPageCount(data.totalPages);
      setPageNumber(data.pageNumber);

      setSubscriptionsData(
        data.data.map(i => {
          let period = 'No Expiration'

          if (i.period) {
            period = i.period > 1
            ? `${i.period} days` 
            : `${i.period} day`
          }
          const status = capitalize(i.status)

          return {...i, period, status} as TableData;
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (searchKey === '') {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const createAd = () => {
    presentDialog({
      headerText: '',
      content: (
        <CreateSubscriptionModalView
          type='splash'
          onClose={dismissDialog}
          onCancel={dismissDialog}
          onCreated={() => {
            dismissDialog();
            refetch();
          }}
        />
      ),
      enableBackdropDismiss: false,
      hideClose: true,
      fullWidth: true,
    });
  };

  const viewSubscription = (subscriptionId: string) => {
    presentDialog({
      headerText: '',
      content: (
        <UpdateSubscriptionModalView
          subscriptionId={subscriptionId}
          onClose={dismissDialog}
          onCancel={dismissDialog}
          onUpdated={() => {
            dismissDialog();
            refetch();
          }}
          onError={showError}
        />
      ),
      enableBackdropDismiss: false,
      hideClose: true,
      fullWidth: true,
    });
  };

  const onPageChangedHandler = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const onSearch = () => {
    refetch();
  };

  const onSubscriptionClick = (value: any) => {
    const data = value as SubscriptionResponse;
    if (data) {
      viewSubscription(data.subscriptionId);
    }
  };

  const onDeleteSubscriptionClick = (value: any) => {
    const data = value as SubscriptionResponse;
    deleteSubscriptionDialog(data);
  };

  const deleteSubscription = useDeleteSubscription();
  const deleteAsync = async (data: SubscriptionResponse) => {
    if (data) {
      try {
        await deleteSubscription.mutateAsync(data.subscriptionId);
        dismissDialog();
      } catch (e: any) {
        console.log(e);
        const exception = e.data as KazamException;
        showError(exception ? exception.error : 'Failed to delete.')
      }
    }
  };
  const deleteSubscriptionDialog = (data: SubscriptionResponse) => {
    presentDialog({
      headerText: '',
      content: (
        <GenericConfirmationDialog
          headerText="Delete Subscription"
          desc="Are you sure you want to delete this subscription?"
          rightButtonText="Delete"
          leftButtonText="Cancel"
          onLeftButtonAction={dismissDialog}
          onRightButtonAction={() => {
            deleteAsync(data);
          }}
        />
      ),
      enableBackdropDismiss: true,
    });
  };

  const manualRenderFn = (columnId: string, rowData: TableData) => {
    if (columnId === 'open') {
      return (
        <div className="flex flex-col justify-center items-end">
          <Icon name="chevronRight" color={colorTheme.dark} />
        </div>
      );
    }
    
    return (
      <div
        className="cursor-pointer"
        onClick={(event: React.MouseEvent) => {
          event.stopPropagation();
          onDeleteSubscriptionClick(rowData);
        }}>
        <Typography
          label="Delete"
          variant="f2"
          color={colorTheme.primary}
          align="left"
        />
      </div>
    );
  };

  const showError = (message: string) => {
    presentToast({
      message: message,
      variant: 'error',
      position: 'bottom',
    })
  }

  const postJob = usePostJob();
  const onExtractClickedHandler = async () => {
    if (authData?.user.email) {
      try {
        await postJob.mutateAsync({
          type: 'subscribedUsersReport',
          params: JSON.stringify({
            email: authData?.user.email
          }),
        });

        presentDialog({
          headerText: '',
          content: (
            <ExtractReportDialog requestName="Subscribed Users" onOkayClick={() => dismissDialog()} />
          ),
          enableBackdropDismiss: true,
          hideClose: true,
        });
      } catch (error) {
        console.log({error});
        presentToast({
          message: 'Request failed',
          variant: 'error',
          position: 'bottom',
        });
      }
    }
  };

  return (
    <AdminPage>
      <div>
        <Typography
          label="Manage Subscriptions"
          variant="f3"
          weight="semibold"
          color={colorTheme.dark}
        />
      </div>

      <AdminSubscriptionSearch
        value={searchKey}
        onClickCreate={createAd}
        onChange={setSearchKey}
        onClickSearch={onSearch}
        onExtractAllClickedHandler={onExtractClickedHandler}
      />

      <div className="pt-6">
        <AdminTable
          columns={columns}
          data={subscriptionsData}
          pageSize={10}
          pageCount={pageCount}
          showDebugView={false}
          onPageChange={onPageChangedHandler}
          onRowClick={onSubscriptionClick}
          manualRenderFn={manualRenderFn}
        />
      </div>
    </AdminPage>
  );
};

export default AdminSubscriptionPage;
