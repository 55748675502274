import * as yup from 'yup';

export const editFilterSettingSchema = yup.object({
  salary: yup
    .number()
    .required('This field is required.')
    .typeError('This field is required.'),
  livingArrangement: yup.string().nullable(),
  searchAreaType: yup.string().required('This field is required.'),
  roles: yup.array().required('This field is required.'),
  location: yup.string().required('This field is required.'),
  province: yup.string(),
  nearbyRadius: yup
    .number()
    .test(
      'min',
      'Minimum value is 0',
      value => typeof value === 'number' && value >= 0,
    ),
});

export const postFilterSettingSchema = yup.object({
  maxSalary: yup
    .number()
    .required('This field is required.')
    .typeError('This field is required.'),
  searchAreaType: yup.string().required('This field is required.'),
  livingArrangement: yup.string().nullable(),
  location: yup.string().required('This field is required.'),
  province: yup.string(),
  nearbyRadius: yup
    .number()
    .test(
      'min',
      'Minimum value is 0',
      value => typeof value === 'number' && value >= 0,
    ),
});
