import {
  Typography,
  Checkbox,
  Button,
  WizardStep,
} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import logo from '../../../../assets/KAZAM_logo_Transparent.svg';
import {useGetRoles} from '../../../server/react-query';
import {useEffect, useState} from 'react';
import {RoutePath} from '../../../navigation/config/RouteConfig';
import {useHistory} from 'react-router-dom';
import Page from '../../../../core/components/page/Page';
import {localization} from '../../../localization/Localization';
import {useAnalyticsTrackComponent} from '../../../hooks/useAnalyticsTrackComponent';
import {useAnalytics} from '../../../hooks/useAnalytics';

interface RoleState {
  roleId: string;
  value: number;
  text: string;
  state: boolean;
}

const SignUpJobSeekerRolesPage = (props: any) => {
  useAnalyticsTrackComponent(SignUpJobSeekerRolesPage.name);

  const history = useHistory();
  const {systemLogEvent} = useAnalytics();

  const {data} = useGetRoles();

  const [roles, setRoles] = useState<RoleState[]>([]);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (data) {
      const roleState = data?.map((role, i) => {
        return {
          roleId: role.roleId,
          state: false,
          value: i,
          text: role.text,
        };
      });
      setRoles(roleState);
      localStorage.setItem('roles', JSON.stringify(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleNext = () => {
    var selectedRoles = roles.filter(
      (role: {state: boolean}) => role.state === true,
    );
    if (selectedRoles.length > 0 && selectedRoles.length <= 3) {
      var signUp = localStorage.getItem('signup');
      if (signUp) {
        var signUpData = JSON.parse(signUp);
        signUpData.roles = selectedRoles.map(role => {
          return role.roleId;
        });
        localStorage.setItem('signup', JSON.stringify(signUpData));
      }

      systemLogEvent('kazam_reg_step_4');

      props.nextStep();
    } else {
      setShowError(true);
    }
  };

  const cancelSignUp = () => {
    localStorage.removeItem('signup');
    history.replace(RoutePath.LANDING);
  };

  const updateRole = (data: any) => {
    if (roles.length > 0) {
      let items = [...roles];
      let item = items[data.role.value];
      item.state = data.state;
      items[data.role.value] = item;
      setRoles(items);
    }
  };

  return (
    <Page hideNavbar>
      <div className="space-y-6">
        <div>
          <WizardStep
            steps={props.totalSteps}
            currentStep={props.currentStep}></WizardStep>
        </div>
        <div className="flex justify-center">
          <img style={{height: '29px'}} src={logo} alt="Logo" />
        </div>
        <div>
          <div>
            <Typography
              label={localization.hdr_roles}
              variant="f3"
              align="center"
              color={colorTheme.dark}></Typography>
          </div>
          <div className="pt-1">
            <Typography
              label={localization.desc_roles}
              variant="f1"
              align="center"
              color={
                showError ? colorTheme.danger : colorTheme.darkFaded
              }></Typography>
          </div>
        </div>
        <div className="pt-2 space-y-4">
          {roles?.map((role, i) => {
            return (
              <div key={i}>
                <Checkbox
                  label={role.text}
                  checked={false}
                  onChange={state => updateRole({state, role})}
                />
              </div>
            );
          })}
        </div>
        <div className="pt-6">
          <div>
            <Button
              label={localization.btn_next}
              color="primary"
              onClick={handleNext}
            />
          </div>
          <div className="pt-4">
            <Button
              label={localization.btn_cancel}
              variant="outlined"
              onClick={cancelSignUp}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default SignUpJobSeekerRolesPage;
