import React from "react";
import {Icon, Typography} from "../../../../core/components";
import {colorTheme} from "../../../../core/configs";
import {StyledChatRoomMenuItem} from "./styled";
import {IconName} from "../../../../core/components/icon";

interface ChatRoomMenuItemProps {
  iconName: IconName;
  label: string;
  onClick?: () => void;
  className?: string;
}

const ChatRoomMenuItem = (props: ChatRoomMenuItemProps) => {
  const {iconName, label, onClick, className} = props;

  const clickHandler = () => {
    onClick && onClick();
  };

  return (
    <StyledChatRoomMenuItem
      className={['flex flex-row items-center cursor-pointer', className].join(' ')}
      onClick={clickHandler}>
      <Icon
        name={iconName}
        color={colorTheme.darkFaded}
        size={16} />
      <Typography
        label={label}
        variant="f1"
        color={colorTheme.darkFaded}
        className="menu-label" />
    </StyledChatRoomMenuItem>
  );
};

export default ChatRoomMenuItem;
