import {useState} from 'react';
import {StyledChatRoomMenu} from './styled';
import {colorTheme} from '../../../../core/configs';
import {Icon} from '../../../../core/components';
import ChatRoomMenuItem from './ChatRoomMenuItem';
import {useToast} from '../../../../core/components/toast';
import { useDialog } from '../../../../core/components/dialog';
import GenericConfirmationDialog from '../../../views/dialog-content/generic-dialog/GenericConfirmationDialog';
import { useHistory } from 'react-router-dom';
import { ConversationStatusType } from '../../../server/types/conversation.types';

interface ChatRoomMenuProps {
  channelId: string;
  channelStatus?: ConversationStatusType;
  onEndConversation?: () => void;
  onEnableConversation?: () => void;
}

const ChatRoomMenu = (props: ChatRoomMenuProps) => {
  const {channelId, channelStatus, onEndConversation, onEnableConversation} = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const {presentDialog, dismissDialog} = useDialog();
  const history = useHistory();

  const iconClickedHandler = () => {
    setIsOpen(prevState => !prevState);
  };

  const overlayClicked = () => {
    setIsOpen(false);
  };

  const showConversationDialog = () => {
    presentDialog({
      headerText: '',
      content: (
        <GenericConfirmationDialog
          headerText={channelStatus === 'active' ? "End Conversation" : "Enable Conversation"}
          desc={channelStatus === 'active' 
            ? "Are you sure you want to end this conversation?"
            : "Are you sure you want to enable this conversation?"}
          rightButtonText={channelStatus === 'active' ? "End" : "Enable"}
          leftButtonText="Cancel"
          onLeftButtonAction={dismissDialog}
          onRightButtonAction={updateConversationStatusClickedHandler}
        />
      ),
      enableBackdropDismiss: true,
    });
  }

  const updateConversationStatusClickedHandler = async () => {
    setIsOpen(false);
    dismissDialog();

    if (channelStatus === "active") {
      onEndConversation && onEndConversation();
    } else {
      onEnableConversation && onEnableConversation();
    }
  };

  return (
    <StyledChatRoomMenu>
      <Icon
        name="moreHorizontal"
        size={24}
        color={colorTheme.white}
        type="button"
        onClick={iconClickedHandler}
      />
      {isOpen && (
        <div className="overlay w-full h-full" onClick={overlayClicked} />
      )}
      {isOpen && (
        <div className="menu-list shadow-lg">
          <ChatRoomMenuItem
            className="menu-item"
            iconName={channelStatus === 'active' ? "xCircle" : "check"}
            label={channelStatus === 'active' ? "End Conversation" : "Enable Conversation"}
            onClick={showConversationDialog}
          />
        </div>
      )}
    </StyledChatRoomMenu>
  );
};

export default ChatRoomMenu;
