import React, {useCallback, useEffect, useState} from 'react';
import {StyledChatsComponent} from './styled';
import {ChatMemberResponse, ChatResponse} from '../../server/types';
import ChatItem from './ChatItem';
import {RoutePath} from '../../navigation/config/RouteConfig';
import {useHistory} from 'react-router-dom';
import {
  useGetConversation,
  useGetUnreadConversations,
} from '../../server/react-query';
import noChats from '../../../assets/no_chats.svg';
import NoResultView from '../../views/cards/no-result/NoResultView';
import {getChatFilterSetting} from '../../utils/chat-filter.util';
import {SavedFilter} from '../../server/types/chat-filter.types';
import {BeatLoader} from 'react-spinners';
import {colorTheme} from '../../../core/configs';
import {localization} from '../../localization/Localization';
import { useAuth } from '../../hooks/useAuth';
import AdminChatItem from './AdminChatItem';

interface ChatsComponentProps {
  searchQuery?: string | undefined;
}

const ChatsComponent = (props: ChatsComponentProps) => {
  const {authData} = useAuth();
  const {searchQuery: propSearchQuery} = props;

  const [saveFilterSetting] = useState<SavedFilter>(getChatFilterSetting());

  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [conversationData, setConversationData] = useState<ChatResponse[]>([]);
  const [unreadConversations, setUnreadConversations] = useState<
    ChatResponse[]
  >([]);

  const history = useHistory();
  const {data, refetch, isFetching} = useGetConversation(
    searchQuery?.trim(),
    {
      0: 'distance',
      1: 'salary',
      2: 'active',
      3: 'activity',
    }[saveFilterSetting.activeTab],
    {
      0: {
        'Near To Far': 'nearest_first',
        'Far To Near': 'farthest_first',
      },
      1: {
        'Low To High': 'min_first',
        'High To Low': 'max_first',
      },
      2: {
        'Online First': 'most_active_first',
      },
      3: {
        'Latest First': 'latest_first',
      },
    }[saveFilterSetting.activeTab][
      saveFilterSetting.activeTab === 0
        ? saveFilterSetting.selectedDistanceSort
        : saveFilterSetting.activeTab === 1
        ? saveFilterSetting.selectedSalarySort
        : saveFilterSetting.activeTab === 2
        ? saveFilterSetting.selectedActiveSort
        : saveFilterSetting.selectedActivitySort
    ],
    saveFilterSetting.selectedRoles.join(','),
  );

  const {data: hookDataUnreadConversations} = useGetUnreadConversations();

  useEffect(() => {
    if (hookDataUnreadConversations) {
      setUnreadConversations(hookDataUnreadConversations);
    }
  }, [hookDataUnreadConversations]);

  useEffect(() => {
    (async () => {
      await refetch();
    })();
  }, [searchQuery, refetch]);

  const executeSearch = useCallback(searchQuery => {
    setSearchQuery(searchQuery);
  }, []);

  // Throttle search
  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      executeSearch(propSearchQuery);
    }, 500);
    return () => timeoutRef && clearTimeout(timeoutRef);
  }, [propSearchQuery, executeSearch]);

  useEffect(() => {
    if (data) {
      setConversationData(data.map(i => ({...i})));
    }
  }, [data]);

  const itemClickHandler = (item: ChatResponse) => {
    history.push(
      RoutePath.CHAT_ROOM.replace(':id', item.conversationChannelId),
    );
  };

  const getChatItem = (data: ChatResponse) => {
    const {user} = data.members.find(
      m => m.userId !== authData?.user.userId,
    ) as ChatMemberResponse;

    if (user.userRole === 'admin') {
      return (
        <AdminChatItem
          key={data.conversationChannelId}
          data={data}
          onClick={itemClickHandler}
          isUnread={
            !!unreadConversations.find(
              u =>
                u.conversationChannelId === data.conversationChannelId,
            )
          }
        />
      )
    } else {
      return (
        <ChatItem
          key={data.conversationChannelId}
          data={data}
          onClick={itemClickHandler}
          isUnread={
            !!unreadConversations.find(
              u =>
                u.conversationChannelId === data.conversationChannelId,
            )
          }
        />
      )
    }
  }

  return (
    <StyledChatsComponent>
      {isFetching ? (
        <div
          className="w-full flex justify-center items-center"
          style={{minHeight: '250px'}}>
          <BeatLoader
            color={colorTheme.primary}
            loading={isFetching}
            margin={2}
            size={15}
          />
        </div>
      ) : (
        <div>
          {conversationData && conversationData.length > 0 ? (
            <div>
              {conversationData.map((data, index) => (
                getChatItem(data)
              ))}
            </div>
          ) : (
            <NoResultView
              image={noChats}
              label={localization.desc_no_chats}></NoResultView>
          )}
        </div>
      )}
    </StyledChatsComponent>
  );
};

export default ChatsComponent;
