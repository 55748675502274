export enum QueryKeys {
  POST_USER = 'postUser',
  POST_OTP_REQUEST = 'postOtpRequest',
  POST_PASSWORD_RESET_REQUEST = 'postPasswordResetRequest',
  POST_PASSWORD_RESET_VERIFY = 'postPasswordResetVerify',
  PUT_PASSWORD_RESET = 'putPasswordReset',
  PUT_RECOVER_ACCOUNT = 'putRecoverAccount',
  POST_OTP_VERIFY = 'postOtpVerify',
  GET_ROLES = 'getRoles',
  GET_PROFILE = 'getKazamProfile',
  PUT_PROFILE = 'putKazamProfile',
  PUT_PROFILE_PHOTO = 'putProfilePhoto',
  POST_FILTER_SETTINGS = 'postFilterSettings',
  IS_MOBILE_EXISTING = 'isMobileExisting',
  PUT_NEW_PASSWORD = 'putNewPassword',
  PATCH_VERIFCATION_REQUEST = 'patchVerificationRequest',
}
