import * as React from 'react';
import {SVGProps, Ref, forwardRef, memo} from 'react';
const SvgEndVideo = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 25"
    ref={ref}
    {...props}>
    <path
      fill="currentColor"
      d="M18 14v1.675l-2-2V6.5H8.825l-2-2H16c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412V11l3.15-3.15c.167-.167.35-.208.55-.125.2.083.3.242.3.475v8.6c0 .233-.1.392-.3.475-.2.083-.383.042-.55-.125L18 14Zm1.85 9.15L1.35 4.65a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l18.5 18.5a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275ZM4 4.5l2 2H4v12h12v-2l2 2c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 16 20.5H4c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 2 18.5v-12c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 4 4.5Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgEndVideo);
const Memo = memo(ForwardRef);
export default Memo;
