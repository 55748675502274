import * as React from 'react';
import {SVGProps, Ref, forwardRef, memo} from 'react';
const SvgCheckCircle = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 25"
    ref={ref}
    {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M22 11.58v.92a10 10 0 1 1-5.93-9.14"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M22 4.5 12 14.51l-3-3"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCheckCircle);
const Memo = memo(ForwardRef);
export default Memo;
