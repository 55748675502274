import styled from "styled-components";

export const StyledChatProfileViewPage = styled.div`
  & .back-btn-container {
    margin-right: 8px;
  }
  
  & .search-container {
    margin-top: 36px;
  }

  & .search-container .btn-search {
    margin-left: 24px;
  }
  
  & .table-container {
    margin-top: 24px;
  }

  .profile-view {
    max-width: 352px;
  } 
`;