import {Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {StyledImg, StyledLine, StyledOr} from './styles';
import googlePlayImg from '../../../../assets/google-play.svg';
import appStoreImg from '../../../../assets/app-store.svg';
import { getTheAppUrl } from '../../../utils/random.util';

interface GetTheAppViewProps {
  googlePlayUrl?: string;
  appStoreUrl?: string;
  textOnly?: boolean;
}

const GetTheAppView = (props: GetTheAppViewProps) => {
  
  const goToUrl = (url: string) => {
    window.open(url);
  };

  return (
    <>
      {props.textOnly ?
        <div style={{width: 'fit-content'}}
          onClick={() => goToUrl(getTheAppUrl())}>
          <Typography
            className='cursor-pointer'
            label="Get the app!"
            variant="f3"
            weight="semibold"
            color={colorTheme.primary}
          />
        </div>
      :
        <div className="flex flex-col items-center pt-4 space-y-2">
          <StyledOr className='space-x-2'>
            <StyledLine />
            <Typography
              label="or"
              align="center"
              weight="normal"
              variant="f1"
              color={colorTheme.darkFaded}
            />
            <StyledLine />
          </StyledOr>
          
          <Typography
            label="Get the app"
            align="center"
            weight="normal"
            variant="f1"
            color={colorTheme.dark}
          />
          <div className='flex space-x-1'>
            {props.googlePlayUrl && 
              <StyledImg
                src={googlePlayImg}
                alt="googlePlay"
                onClick={() => goToUrl(props.googlePlayUrl!)}>
              </StyledImg>}
            {props.appStoreUrl && 
              <StyledImg 
                src={appStoreImg} 
                alt="appStore"
                onClick={() => goToUrl(props.appStoreUrl!)}
              ></StyledImg>}
          </div>
        </div> 
      }
    </>
   
  );
};

export default GetTheAppView;
