import {Button, Icon, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import kazamIllustration from '../../../../assets/kaway_illustration.svg';
import {StyledImg} from './styles';
import {localization} from '../../../localization/Localization';

interface DialogProps {
  forHomeowners?: boolean;
  onContinueClick: () => void;
}

const KazamKawayDialogContentView = (props: DialogProps) => {
  return (
    <div className="flex flex-col items-center p-4 space-y-4">
      <StyledImg src={kazamIllustration} alt="Kaway"></StyledImg>
      <div className="space-y-2">
        <Typography
          label={
            props.forHomeowners
              ? localization.dlg_hdr_new_ho
              : localization.dlg_hdr_new_kzm
          }
          align="center"
          weight="semibold"
          variant="f3"
          color={colorTheme.dark}
        />
        <div className="flex justify-center space-x-1">
          <Icon name="waveGradient" size={24} />
          <div style={{width: '175px'}}>
            <Typography
              align="center"
              label={
                props.forHomeowners
                  ? localization.dlg_desc_new_ho
                  : localization.dlg_desc_new_kzm
              }
              weight="normal"
              variant="f1"
              color={colorTheme.darkFaded}
            />
          </div>
        </div>
      </div>
      <div className="w-full">
        <Button
          label={localization.btn_continue}
          color="primary"
          onClick={props.onContinueClick}
        />
      </div>
    </div>
  );
};

export default KazamKawayDialogContentView;
