import styled, { css } from 'styled-components';
import {colorTheme} from '../../configs';

interface PageWrapperProps {
  chatRoom?: boolean;
}

export const StyledMainWrapper = styled.div<PageWrapperProps>`
  width: 100vw;
  height: 100%;
`;

export const StyledMainPageWrapper = styled.div`
  height: 100%;
`;

export const StyledPageWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  overflow-y: auto;

  &.padding {
    padding: 24px;
  }
`;

export const StyledPageContent = styled.div<PageWrapperProps>`
  width: 100%;
  height: fit-content;

  ${p =>
    p.chatRoom
      ? css`
          height: 100%;
        `
      : css`
          height: fit-content;
        `}
`;

export const StyledSideBarWrapper = styled.div`
  min-width: 300px;
  background: ${colorTheme.light};
`;
