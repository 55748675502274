import {Button, Typography} from '../../../../../core/components';
import {colorTheme} from '../../../../../core/configs';
import illustration from '../../../../../assets/no_chats.svg';
import {StyledImg} from './styles';
import {localization} from '../../../../localization/Localization';
import {
  useGetActiveTrialSubscription,
  usePostTrialSubscription,
  usePutActiveTrialSubscription
} from '../../../../server/react-query/useTrialSubscription';
import { getTrialFreeChats } from '../../../../utils/admin.util';
import { useAuth } from '../../../../hooks/useAuth';
import { BeatLoader } from 'react-spinners';
import { ChatResponse, KazamException } from '../../../../server/types';
import { usePostConversation, usePostOnlineStatus } from '../../../../server/react-query';

interface DialogProps {
  userId?: string
  onSuccess: (conversationId: string) => void
  onError: (error: string) => void
}

const TrialSubscriptionDialogContentView = (props: DialogProps) => {
  const {data: activeTrialSubscription, isFetching} = useGetActiveTrialSubscription()
  const {authData, updateAuthData} = useAuth()
  const freeChats = getTrialFreeChats()
  
  const getHeader = (): string => {
    let header = `Hello ${authData?.user?.firstName}!`

    if (activeTrialSubscription) {
      header = `${header} You have <b>${activeTrialSubscription.remainingFreeChats} free
        ${activeTrialSubscription.remainingFreeChats === 1 ? 'chat' : 'chats'} remaining</b>!`
    } else {
      header = `${header} You have <b>${freeChats} free 
        ${parseInt(freeChats as unknown as string) === 1 ? 'chat' : 'chats'}</b>!`
    }

    return header
  }

  const getDesc = (): string => {
    let chats = activeTrialSubscription 
      ? activeTrialSubscription.remainingFreeChats
      : parseInt(freeChats as unknown as string)

    let desc = `After the ${chats} free ${chats > 1 ? 'chats' : 'chat'}
      , you will need to subscribe to our kazam packages​`

    return desc
  }

  const postConversation = usePostConversation();
  const createConversation = async () => {
    try {
      var conversation = (await postConversation.mutateAsync({
        conversationChannelName: `Conversation with ${props?.userId}`, // Use user's name as conversation name
        memberIds: [props?.userId || '', authData?.user?.userId || '']
      })) as ChatResponse;

      return conversation.conversationChannelId
    
    } catch (e: any) {
      console.log(e);
      const exception = e.data as KazamException;
      props.onError(exception ? exception.error : "Failed to create.")
    }
  }

  const postTrialSubscription = usePostTrialSubscription()
  const putActiveTrialSubscription = usePutActiveTrialSubscription()
  const postOnlineStatus = usePostOnlineStatus()
  const updateTrialSubscription = async (conversationId: string) => {
    try {
      if (activeTrialSubscription) {
        await putActiveTrialSubscription.mutateAsync()
      } else {
        await postTrialSubscription.mutateAsync()
      }
      const res: any = await postOnlineStatus.mutateAsync();
      if (res && res.user) {
        let data = authData;
        if (data) {
          data.user = res.user;
          updateAuthData(data);
        }
      }
      props.onSuccess(conversationId)
    } catch (e: any) {
      const exception = e.data as KazamException;
      props.onError && props.onError(exception ? exception.error : "Failed to save.")
    }
  }

  const onClickContinue = async () => {
    var conversationId = await createConversation()
    if (conversationId) await updateTrialSubscription(conversationId)
  }

  return (
    <div className="flex flex-col items-center p-4 space-y-4">
      <StyledImg src={illustration} alt="Kaway"></StyledImg>

      {isFetching ? (
        <div className='flex items-center justify-center'>
          <BeatLoader
            color={colorTheme.primary}
            loading
            size={15}
          />
        </div>
      ) : (
        <>
          <div className="space-y-2">
            <Typography
              label={getHeader()}
              align="center"
              weight="semibold"
              variant="f3"
              color={colorTheme.dark}
            />
            <Typography
              align="center"
              label={getDesc()}
              weight="normal"
              variant="f1"
              color={colorTheme.darkFaded}
            />
          </div>
          <div className="w-full">
            <Button
              label={localization.btn_continue}
              color="primary"
              onClick={onClickContinue}
              isLoading={postTrialSubscription.isLoading
                || putActiveTrialSubscription.isLoading
                || postConversation.isLoading}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default TrialSubscriptionDialogContentView;
