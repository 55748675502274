import {StyledChatLocation} from './styles';
import StaticGoogleMap from '../../../../core/components/google-map/StaticGoogleMap';
import format from 'date-fns/format';
import { useHistory } from 'react-router-dom';
import { RoutePath } from '../../../navigation/config/RouteConfig';
import { UserResponse } from '../../../server/types';
import MessageAvatar from '../recipient-chat-message/MessageAvatar';
import { Typography } from '../../../../core/components';
import { colorTheme } from '../../../../core/configs';

interface ChatLocationProps {
  currentUser?: UserResponse
  fromUser: UserResponse
  contentId: string
  lat: number
  long: number
  address?: string
  date: Date
  onDataLoaded?: () => void
}

const ChatLocation = ({
  currentUser,
  fromUser,
  contentId,
  lat,
  long,
  address,
  date,
  onDataLoaded
}: ChatLocationProps) => {
  const history = useHistory()
  const currentUserIsSender = currentUser?.userId === fromUser?.userId

  const viewLocation = () => {
    const url = RoutePath.VIEW_CURRENT_LOCATION.replace(':id', contentId);
    history.push(url);
  }
  return (
    <StyledChatLocation isSender={currentUserIsSender}>
      {!currentUserIsSender &&
        <MessageAvatar profile={fromUser}/>
      }
      
      <div className="offer-details cursor-pointer space-y-1"
        onClick={viewLocation}>
        {/* <GoogleMap
          latitude={30.7136398}
          longitude={76.7189289}
          height={150}
          fullscreenControl={false}
          gestureHandling={'none'}
          mapTypeId={'roadmap'}
        /> */}
        
        <StaticGoogleMap
          latitude={lat}
          longitude={long}
          // onLoadImage={onDataLoaded}
        />

        <div>
          <Typography
            label="Current Location"
            variant='f1'
            color={colorTheme.dark}
          />
        </div>

        {address && 
          <div className="flex gap-1 mb-1 truncate">
            <span className="location-txt">
              {address}
            </span>
          </div>
        }

        <div className={`${currentUserIsSender ? 'text-right': 'text-left'} offer-msg-time`}>
          <span>{`${format(date, 'PP')} ${format(date, 'p')}`}</span>
        </div>
      </div>
    </StyledChatLocation>
  );
};

export default ChatLocation;
