import Page from '../../../core/components/page/Page';
import {VersionInfo} from '../../../VersionInfo';
import signInImage from '../../../assets/img_I_Am_Homeowner.svg';
import {StyledDiv, StyledImg} from './styled';
import {Icon, Typography} from '../../../core/components';
import {colorTheme} from '../../../core/configs';
import Divider from '../../../core/components/divider/Divider';
import {localization} from '../../localization/Localization';
import SocialMedia from './SocialMedia';

const AboutUs = () => {
  var versionInfo = VersionInfo;

  return (
    <Page showBackButton>
      <div className="section w-full flex flex-col space-y-4">
        <StyledImg src={signInImage} alt="Sign in images" />
        <div className="space-x-1">
          <div className="flex flex-row justify-center space-x-1">
            <Typography label={localization.hdr_about} variant="f3" color={colorTheme.dark}/>
            <Typography
              className="spaced-left"
              label="kazam"
              variant="f3"
              weight="semibold"
              color={colorTheme.primary}
            />
          </div>
          <Typography
            label={localization.desc_landing}
            align="center"
            variant="f1"
            weight="normal"
            color={colorTheme.darkFaded}
          />
        </div>
        <Divider />
        <div className="space-y-2">
          <StyledDiv
            dangerouslySetInnerHTML={{
              __html:
                'The <b>kazam platform</b> is an online service to connect Homeowners with Kasambahays such as child care, senior care, cook, maid, family driver, houseboy, and store assistant.',
            }}
          />
          <StyledDiv
            dangerouslySetInnerHTML={{
              __html:
                'We built the <b>kazam platform</b> because we believe that Homeowners need a simple, safe, and secure way to communicate with Kasambahays such as child care, senior care, cook, maid, family driver, and store assistant. The kazam platform was designed to give both Homeowners and Kasambahays a reliable alternative compared to the traditional ways that they find each other.',
            }}
          />
          <Typography
            label=""
            variant="f1"
            weight="normal"
            color={colorTheme.darkFaded}
          />
          <Typography
            label="Founded in 2021. Headquartered in Pasig City."
            variant="f1"
            weight="normal"
            color={colorTheme.darkFaded}
          />
          <Typography
            label="Kazam is 100% Filipino-owned. "
            variant="f1"
            weight="normal"
            color={colorTheme.darkFaded}
          />
        </div>
        <Divider />
        <div className="space-y-2">
          <Typography
            label="Contact Us"
            variant="f1"
            weight="semibold"
            color={colorTheme.dark}
          />
          <div className="space-y-1">
            {/* <div className="flex flex-row space-x-1">
              <Typography
                label="Contact number:"
                variant="f1"
                weight="semibold"
                color={colorTheme.darkFaded}
              />
              <Typography
                label="(02) 8864 0339"
                variant="f1"
                weight="normal"
                color={colorTheme.darkFaded}
              />
            </div> */}
            <div className="flex flex-row space-x-1">
              <Typography
                label="Email address:"
                variant="f1"
                weight="semibold"
                color={colorTheme.darkFaded}
              />
              <div
                onClick={() =>
                  window.open('mailto:support@kazam.ph', '_blank')
                }>
                <Typography
                  className="cursor-pointer"
                  label="support@kazam.ph"
                  variant="f1"
                  weight="normal"
                  color={colorTheme.primary}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-2">
          <Typography
            label="Version Info"
            variant="f1"
            weight="semibold"
            color={colorTheme.dark}
          />
          <div className="space-y-1">
            <div className="flex flex-row space-x-1">
              <Typography
                label="Version no.:"
                variant="f1"
                weight="semibold"
                color={colorTheme.darkFaded}
              />
              <Typography
                label={versionInfo.version}
                variant="f1"
                weight="normal"
                color={colorTheme.darkFaded}
              />
            </div>
            <div className="flex flex-row space-x-1">
              <Typography
                label="Build no.:"
                variant="f1"
                weight="semibold"
                color={colorTheme.darkFaded}
              />
              <Typography
                label={versionInfo.build}
                variant="f1"
                weight="normal"
                color={colorTheme.darkFaded}
              />
            </div>
          </div>
        </div>
        <div className="space-y-2">
          <Typography
            label="Third-party"
            variant="f1"
            weight="semibold"
            color={colorTheme.dark}
          />
          <div className="space-y-1">
            <StyledDiv
              dangerouslySetInnerHTML={{
                __html:
                  'All illustrations used in this website are designed by <a href="https://www.freepik.com" target="_blank">Freepik</a>',
              }}
            />
          </div>
        </div>
        
        <SocialMedia />
      </div>
    </Page>
  );
};

export default AboutUs;
