import {isMobile} from 'react-device-detect';
import styled from 'styled-components';

export const StyledFavoriteView = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  & .bottom-nav {
    border-top: 1px solid #e2e2e2;
    background-color: white;
    position: ${isMobile ? 'fixed' : 'absolute'};
    width: 100%;
    height: 71px;
    bottom: 0;
    left: 0;
  }

  & .action-middle {
    margin: 0px 27px;
  }
`;
