import * as React from 'react';

function SvgRadioButtonFilled(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}>
      <path
        d="M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16.001A8 8 0 0112 20z"
        fill="currentColor"
      />
      <path d="M12 7a5 5 0 100 10 5 5 0 000-10z" fill="currentColor" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgRadioButtonFilled);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
