import {Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {StyledImg} from './styles';
import jobOffer from '../../../../assets/job_offer.svg';
import jobDetails from '../../../../assets/job_details.svg';
import {NotificationResponse} from '../../../server/types/notification.types';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import {localization} from '../../../localization/Localization';
import {useHistory} from 'react-router';
import {RoutePath} from '../../../navigation/config/RouteConfig';
import { isMobile } from 'react-device-detect';
import { useGetConversationById } from '../../../server/react-query';
import { useEffect, useState } from 'react';
import { JobHistoryResponse } from '../../../server/types/job-offer.types';
import { getTerminationReasonValue } from '../../../utils/job-history.util';
import { getJobDetailsContentMeta } from '../../../utils/job-offer.util';

interface JobEndedNotificationViewProps {
  data: NotificationResponse;
}

const JobEndedNotificationView = (props: JobEndedNotificationViewProps) => {
  const history = useHistory();
  const [jobHistoryMeta, setJobHistoryMeta] = useState<JobHistoryResponse>()

  const { data } = useGetConversationById(props.data.content);
  
  useEffect(() => {
    if(data && data.contents[0].meta){
      var parsedData = getJobDetailsContentMeta(data.contents[0].meta)
      setJobHistoryMeta(parsedData)
    }
  }, [data]);

  const goToChat = () => {
    history.push(RoutePath.CHAT_ROOM.replace(':id', props.data.content));
  };

  return (
    <div
      className="flex flex-row space-x-2 items-center mb-6 cursor-pointer"
      onClick={goToChat}
    >
      <StyledImg src={jobDetails} alt="jobDetails" />
      <div className="flex flex-col">
        <div className="flex space-x-1">
          <Typography
            label={localization.desc_job_ended_notif.replace(
              '{firstName}',
              props.data.user.firstName,
            ).replace(
              '{reason}',
              getTerminationReasonValue(jobHistoryMeta?.terminationReason ?? "voluntaryResignation").toLowerCase()
            ).replace(
              '{user}',
              props.data.user.userRole === 'kazam' ? "kasambahay" : "homeowner"
            )}
            weight={props.data.readOn ? 'normal' : 'semibold'}
            variant="f1"
            color={colorTheme.dark}
          />
        </div>

        <Typography
          label={`${formatDistanceToNow(new Date(props.data.createdOn))} ago`}
          weight="normal"
          variant="f1"
          color={props.data.readOn ? colorTheme.darkFaded : colorTheme.dark}
        />
      </div>
    </div>
  );
};

export default JobEndedNotificationView;
