import * as React from 'react';
import {SVGProps, Ref, forwardRef, memo} from 'react';
const SvgPlusBig = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="m11.978 3.515.044 16.97M3.515 12.022l16.97-.044"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPlusBig);
const Memo = memo(ForwardRef);
export default Memo;
