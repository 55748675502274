import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {usePostOnlineStatus} from '../../server/react-query';
import {useAuth} from '../../hooks/useAuth';
import {ONLINE_STATUS_PUSH_INTERVAL} from '../../utils/online-status.util';

const StatusUpdater = () => {
  const [shouldTransmit, setShouldTransmit] = useState<boolean>(false);
  const [intervalRef, setIntervalRef] = useState<NodeJS.Timeout | undefined>(
    undefined,
  );
  const {authData, updateAuthData} = useAuth();
  const postOnlineStatus = usePostOnlineStatus();

  const updateOnlineStatus = useCallback(() => {
    (async () => {
      const res: any = await postOnlineStatus.mutateAsync();

      if (res && res.user) {
        let data = authData;
        if (data) {
          data.user = res.user;
          updateAuthData(data);
        }
      }
    })();
  }, [postOnlineStatus]);

  useEffect(() => {
    if (shouldTransmit) {
      if (!intervalRef) {
        updateOnlineStatus();
        const intervalRef = setInterval(() => {
          if (shouldTransmit) {
            updateOnlineStatus();
          }
        }, ONLINE_STATUS_PUSH_INTERVAL);
        setIntervalRef(intervalRef);
      }
    } else {
      if (intervalRef) {
        clearInterval(intervalRef);
        setIntervalRef(undefined);
      }
    }
  }, [shouldTransmit, intervalRef, updateOnlineStatus]);

  useEffect(() => {
    if (!!authData && authData.user && authData.user.userRole !== 'admin') {
      setShouldTransmit(!!authData);
    }
  }, [authData]);

  return <Fragment />;
};

export default StatusUpdater;
