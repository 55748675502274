import {useState} from 'react';
import {ImageUploadSize} from '.';
import {Icon, Typography} from '..';
import {localization} from '../../../app/localization/Localization';
import {colorTheme} from '../../configs';
import {useToast} from '../toast';
import {
  StyledDefaultImageView,
  StyledError,
  StyledImage,
  StyledImageOverlay,
  StyledInputLabel,
} from './styles';

export interface ImageSelectorProps {
  label?: string;
  error?: string;
  onSelectPhoto: (file: any | null) => void;
  onError?: (message: string) => void;
  providedPhoto?: string;
  imageUploadSize?: ImageUploadSize;
  readOnly?: boolean
}

const ImageUpload = (props: ImageSelectorProps) => {
  var {imageUploadSize = 'profile', error} = props;
  const [fileUri, setFileUri] = useState<string | undefined>('');
  const {presentToast} = useToast();
  let inputElement: HTMLElement | null;

  const handleClick = () => {
    inputElement?.click();
  };

  const fileSizeChecker = (fileSize: number, uri: string, image: Blob) => {
    if (fileSize / 1000000 > 5) {
      const error = 'File size is over 5MB';
      onError(error);
      presentToast({
        message: error,
        variant: 'error',
        position: 'bottom',
      });
    } else if (fileSize && fileSize / 1000000 <= 5) {
      setFileUri(uri);
      props.onSelectPhoto(image);
    }
  };

  const onError = (message: string) => {
    if (props.onError) {
      props.onError(message);
    }
    setFileUri(undefined);
    props.onSelectPhoto(undefined);
  };

  const handleFileChange = (event: any) => {
    const {target} = event;
    const {files} = target;

    if (files && files[0]) {
      const image = new Image();
      const reader = new FileReader();

      reader.onload = () => {
        const fileSize = files[0].size;
        fileSizeChecker(fileSize, URL.createObjectURL(files[0]), files[0]);
        if (reader !== null && typeof reader.result == 'string') {
          image.src = reader.result;
        }
      };

      reader.readAsDataURL(files[0]);

      image.onload = async function () {
        if (imageUploadSize === 'splash' && image.width > image.height) {
          onError('Please upload a portrait image.');
        }

        if (imageUploadSize === 'banner' && image.height > image.width) {
          onError('Please upload a landscape image.');
        }
      };
    }
  };

  return (
    <div className="w-full">
      {props.label && (
        <StyledInputLabel data-testid="label-element">
          {props.label}
        </StyledInputLabel>
      )}
      <div className="flex justify-center">
        <StyledDefaultImageView
          imageUploadSize={imageUploadSize}
          onClick={() => !props?.readOnly && handleClick()}
          className="flex items-center justify-center"
          style={{
            borderWidth: fileUri || props.providedPhoto ? '0' : '2px',
            borderColor:
              fileUri || props.providedPhoto
                ? 'transparent'
                : error
                ? colorTheme.danger
                : colorTheme.lightGray,
          }}>
          {(fileUri || props.providedPhoto) && (
            <StyledImage
              src={fileUri ? fileUri : props.providedPhoto}></StyledImage>
          )}

          {!props?.readOnly && 
            <StyledImageOverlay
              imageUploadSize={imageUploadSize}
              className={`w-full h-full flex flex-col justify-center items-center ${
                (fileUri || props.providedPhoto) && 'image'
              }`}
              style={{
                background:
                  fileUri || props.providedPhoto
                    ? 'rgba(0,0,0,0.25)'
                    : 'transparent',
              }}>
              <div>
                <Icon
                  name="imageAdd"
                  size={48}
                  color={
                    fileUri || props.providedPhoto
                      ? colorTheme.light
                      : error
                      ? colorTheme.danger
                      : colorTheme.darkFaded
                  }></Icon>
              </div>
              <div className="pt-1">
                <Typography
                  label={localization.desc_upload_pic}
                  variant="f1"
                  align="center"
                  color={
                    fileUri || props.providedPhoto
                      ? colorTheme.light
                      : error
                      ? colorTheme.danger
                      : colorTheme.darkFaded
                  }></Typography>
              </div>
            </StyledImageOverlay>
          }

          
          <input
            type="file"
            ref={input => (inputElement = input)}
            accept="image/jpeg, image/png, .svg, image/heic, image/heic-sequence"
            onChange={handleFileChange}
            style={{display: 'none'}}
          />
        </StyledDefaultImageView>
      </div>
      {error && <StyledError>{error}</StyledError>}
    </div>
  );
};

export default ImageUpload;
