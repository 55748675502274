import React, {useEffect, useState} from 'react';
import Page from '../../../core/components/page/Page';
import {StyledChatFilterPage} from './styled';
import {Button, Icon, Select, Typography} from '../../../core/components';
import {colorTheme} from '../../../core/configs';
import RolesSelectionComponent from './RolesSelectionComponent';
import {useHistory, useParams} from 'react-router-dom';
import {
  SavedFilter,
  SortActive,
  SortActivity,
  SortDistance,
  SortSalary,
  TabIndex,
} from '../../server/types/chat-filter.types';
import {
  getChatFilterSetting,
  saveChatFilterSetting,
} from '../../utils/chat-filter.util';
import {useGetRoles} from '../../server/react-query';
import {Role} from '../../server/types';
import {isMobile} from 'react-device-detect';
import {localization} from '../../localization/Localization';
import {useAuth} from '../../hooks/useAuth';

interface ChatFilterPageParams {
  id: string;
}

const ChatFilterPage = () => {
  const {authData} = useAuth();

  const {id: tabIndex} = useParams<ChatFilterPageParams>();
  const [savedFilter] = useState<SavedFilter>(getChatFilterSetting());
  const [selectedRoles, setSelectedRoles] = useState<string[]>(
    savedFilter.selectedRoles,
  );
  const [activeTab, setActiveTab] = useState<TabIndex>(savedFilter.activeTab);
  const [distanceSortValues] = useState<SortDistance[]>([
    'Near To Far',
    'Far To Near',
  ]);
  const [salarySortValues] = useState<SortSalary[]>([
    'Low To High',
    'High To Low',
  ]);
  const [
    selectedDistanceSort,
    setSelectedDistanceSort,
  ] = useState<SortDistance>(savedFilter.selectedDistanceSort);
  const [selectedSalarySort, setSelectedSalarySort] = useState<SortSalary>(
    savedFilter.selectedSalarySort,
  );
  const [selectedActiveSort] = useState<SortActive>(
    savedFilter.selectedActiveSort,
  );
  const [selectedActivitySort] = useState<SortActivity>(
    savedFilter.selectedActivitySort,
  );
  const [roles, setRoles] = useState<Role[]>([]);
  const [isSelectedRolesValid, setIsSelectedRolesValid] = useState<boolean>(
    true,
  );

  const history = useHistory();
  const {data: hookDataRoles} = useGetRoles();

  useEffect(() => {
    setIsSelectedRolesValid(selectedRoles.length > 0);
  }, [selectedRoles]);

  useEffect(() => {
    if (roles) {
      if (!savedFilter.selectedRoles || savedFilter.selectedRoles.length < 1) {
        setSelectedRoles(roles.map(i => i.roleId));
      }
    }
  }, [roles, savedFilter]);

  useEffect(() => {
    if (hookDataRoles) {
      setRoles(hookDataRoles);
    }
  }, [hookDataRoles]);

  const saveClickHandler = () => {
    if (isSelectedRolesValid) {
      const filterState: SavedFilter = {
        activeTab,
        selectedDistanceSort,
        selectedSalarySort,
        selectedActiveSort,
        selectedActivitySort,
        selectedRoles,
      };
      saveChatFilterSetting(filterState);

      history.go(-1);
    }
  };

  const tabClickedHandler = (tab: TabIndex) => {
    setActiveTab(tab);
  };

  const selectedRolesChangedHandler = (roleIds: string[]) => {
    setSelectedRoles(roleIds);
  };

  const sortOrderChangedHandler = (value?: string) => {
    if (value) {
      if (activeTab === 0) {
        setSelectedDistanceSort(value as SortDistance);
      } else if (activeTab === 1) {
        setSelectedSalarySort(value as SortSalary);
      }
    }
  };

  const resetClickedHandler = () => {
    // Save default
    const filterState: SavedFilter = {
      activeTab: 3,
      selectedDistanceSort: 'Near To Far',
      selectedSalarySort: 'Low To High',
      selectedActiveSort: 'Online First',
      selectedActivitySort: 'Latest First',
      selectedRoles: roles.map(i => i.roleId),
    };
    saveChatFilterSetting(filterState);

    history.go(-1);
  };

  const backButtonClickHandler = () => {
    history.go(-1);
  };

  return (
    <Page
      showBackButton
      showRightText={isMobile}
      rightText="Save"
      onRightActionClick={saveClickHandler}
      unPadded>
      {!isMobile && (
        <div
          className="cursor-pointer flex justify-between items-center"
          style={{
            paddingLeft: '24px',
            paddingTop: '24px',
            paddingRight: '24px',
          }}>
          <Icon
            name="arrowLeft"
            size={24}
            color={colorTheme.dark}
            onClick={backButtonClickHandler}
          />

          <div style={{width: 'fit-content'}}>
            <Button
              label={localization.btn_save}
              color="default"
              variant="outlined"
              onClick={saveClickHandler}
            />
          </div>
        </div>
      )}

      <StyledChatFilterPage>
        {tabIndex === '0' && (
          <Typography
            label={localization.hdr_chat_list}
            variant="f3"
            weight="semibold"
            align="center"
          />
        )}
        {tabIndex === '1' && (
          <div className="flex justify-center items-center space-x-1">
            <Icon name="heartFilled" color={colorTheme.dark} size={20}></Icon>
            <Typography
              label={localization.hdr_fave_list}
              variant="f3"
              weight="semibold"
              align="center"
            />
          </div>
        )}
        {tabIndex === '2' && (
          <div className="flex justify-center items-center space-x-1">
            <Icon name="wave" color={colorTheme.dark} size={20}></Icon>
            <Typography
              label={localization.hdr_kaway_list}
              variant="f3"
              weight="semibold"
              align="center"
            />
          </div>
        )}

        <div className="sort-container flex flex-row w-full justify-between items-center">
          <Typography
            label={localization.hdr_sort_by}
            variant="f2"
            weight="semibold"
          />
          <div className="flex flex-row items-center">
            {activeTab in
              [
                0, // Distance
                1, // Salary
              ] && (
              <Select
                color={colorTheme.darkFaded}
                borderless
                iconClassName="dd-icon"
                unPadded
                canSelectNone={false}
                items={
                  activeTab === 0
                    ? distanceSortValues
                    : activeTab === 1
                    ? salarySortValues
                    : []
                }
                itemRenderer={i => {
                  return (
                    <Typography
                      label={i}
                      variant="f2"
                      color={colorTheme.darkFaded}
                    />
                  );
                }}
                value={
                  activeTab === 0
                    ? selectedDistanceSort
                    : activeTab === 1
                    ? selectedSalarySort
                    : selectedActiveSort
                }
                onChange={sortOrderChangedHandler}
                valueRenderer={value => value}
              />
            )}
          </div>
        </div>

        <div className="sort-type-container flex flex-row">
          <div
            className={`tab flex-1 cursor-pointer ${
              activeTab === 0 ? 'tab-active' : ''
            }`}
            onClick={() => tabClickedHandler(0)}>
            <Typography
              label={localization.lbl_distance}
              align="center"
              variant="f2"
              color={activeTab === 0 ? colorTheme.white : colorTheme.black}
            />
          </div>

          {authData?.user.userRole === 'homeowner' && (
            <div
              className={`tab flex-1 cursor-pointer ${
                activeTab === 1 ? 'tab-active' : ''
              }`}
              onClick={() => tabClickedHandler(1)}>
              <Typography
                label={localization.lbl_salary}
                align="center"
                variant="f2"
                color={activeTab === 1 ? colorTheme.white : colorTheme.black}
              />
            </div>
          )}

          <div
            className={`tab flex-1 cursor-pointer ${
              activeTab === 2 ? 'tab-active' : ''
            }`}
            onClick={() => tabClickedHandler(2)}>
            <Typography
              label={localization.lbl_online_status}
              align="center"
              variant="f2"
              color={activeTab === 2 ? colorTheme.white : colorTheme.black}
            />
          </div>
        </div>

        <div className="role-container">
          <Typography
            label={isSelectedRolesValid ? 'Role' : 'Role (required)'}
            variant="f1"
            weight="semibold"
            color={
              !isSelectedRolesValid ? colorTheme.danger : colorTheme.darkFaded
            }
          />
          <RolesSelectionComponent
            className="role-selection"
            values={selectedRoles}
            onChange={selectedRolesChangedHandler}
          />
        </div>

        <div className="reset-container w-full flex flex-col items-end">
          <div>
            <Button
              label={localization.btn_reset}
              color="default"
              variant="outlined"
              onClick={resetClickedHandler}
            />
          </div>
          <Typography
            label={localization.desc_most_recent}
            variant="f1"
            color={colorTheme.darkFaded}
          />
        </div>
      </StyledChatFilterPage>
    </Page>
  );
};

export default ChatFilterPage;
