import * as React from 'react';
import {SVGProps, Ref, forwardRef, memo} from 'react';
const SvgBrain = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 25 25"
    ref={ref}
    {...props}>
    <path
      fill="currentColor"
      d="M13.75 3.5c3.88 0 7 3.14 7 7 0 2.8-1.63 5.19-4 6.31v4.69h-7v-3h-1c-1.11 0-2-.89-2-2v-3h-1.5c-.42 0-.66-.5-.42-.81l1.92-2.53a7.003 7.003 0 0 1 7-6.66Zm0-2c-4.59 0-8.39 3.42-8.94 7.9l-1.56 2.1h-.03l-.02.03c-.55.76-.62 1.76-.19 2.59.36.69 1 1.17 1.74 1.32v1.06c0 1.85 1.28 3.42 3 3.87v3.13h11V18c2.5-1.67 4-4.44 4-7.5 0-4.97-4.04-9-9-9Zm4.33 8.3-1.96.51 1.44 1.46a.9.9 0 0 1 0 1.27.9.9 0 0 1-1.27 0l-1.45-1.44-.52 1.96c-.12.49-.61.76-1.07.64a.91.91 0 0 1-.66-1.11l.53-1.96-1.96.53a.91.91 0 0 1-1.11-.66c-.12-.45.16-.95.64-1.07l1.96-.52-1.44-1.45a.9.9 0 0 1 1.27-1.27l1.46 1.44.51-1.96c.12-.49.62-.77 1.09-.64.49.13.77.62.64 1.1l-.53 1.97 1.97-.53c.48-.13.97.15 1.1.64.13.47-.15.97-.64 1.09Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgBrain);
const Memo = memo(ForwardRef);
export default Memo;
