import {useMutation, useQuery, useQueryClient} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';
import {NotificationResponse} from '../types/notification.types';

const getNotifications = () => {
  return API.get<NotificationResponse[]>(ApiEndpointsEnum.GET_NOTIFICATIONS);
};

const useGetNotifications = () => {
  return useQuery(['get-notifications'], () => getNotifications());
};

const getUnreadNotifications = () => {
  return API.get<number>(ApiEndpointsEnum.GET_UNREAD_NOTIFICATIONS);
};

const useGetUnreadNotifications = () => {
  return useQuery(['get-unread-notifications'], () => getUnreadNotifications());
};

const postMarkAllNotificationsAsRead = () => {
  return API.post<null>(ApiEndpointsEnum.POST_READ_NOTIFICATIONS);
};

const usePostMarkAllNotificationsAsRead = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['post-mark-all-notifications-as-read'],
    () => postMarkAllNotificationsAsRead(),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'post-mark-all-notifications-as-read',
        ]);
        queryClient.removeQueries([
          'get-unread-notifications',
        ]);
      },
    },
  );
};

export {
  useGetNotifications,
  getNotifications,
  useGetUnreadNotifications,
  usePostMarkAllNotificationsAsRead,
};
