import React, {useEffect, useState} from 'react';
import {UserResponse} from '../../server/types';
import Page from '../../../core/components/page/Page';
import HomeownerProfileView from '../../views/cards/homeowner-profile/HomeownerProfileView';
import {useParams} from 'react-router-dom';
import {useGetUserById} from '../../server/react-query';

interface EmployerProfileViewPageParams {
  id: string;
}

const EmployerProfileViewPage = () => {
  const {id: userId} = useParams<EmployerProfileViewPageParams>();
  const {data, isFetching} = useGetUserById(userId);

  const [userData, setUserData] = useState<UserResponse | undefined>(undefined);

  useEffect(() => {
    if (data) {
      setUserData({...data});
    }
  }, [data]);

  return (
    <Page showLoading={isFetching} showBackButton showDesktopBackButton>
      <HomeownerProfileView profile={userData} />
    </Page>
  );
};

export default EmployerProfileViewPage;
