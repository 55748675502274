import React, {useCallback, useEffect, useState} from 'react';
import {colorTheme} from '../../../core/configs';
import {Icon, Typography} from '../../../core/components';
import {StyledKazamExperience} from './styled';
import {useGetUnreadConversations} from '../../server/react-query';
import {useQueryClient} from 'react-query';
import {localization} from '../../localization/Localization';
import KazamExperienceCard from './KazamExperienceCard';
import { JobHistoryResponse } from '../../server/types/job-offer.types';

interface KazamExperienceProps {
  jobHistory?: JobHistoryResponse[]
}

const KazamExperience = (props: KazamExperienceProps) => {
  if (!props?.jobHistory
    ||props?.jobHistory?.length === 0) return <></>

  return (
    <StyledKazamExperience>
      <Typography
        className="heading"
        label={localization.hdr_experience}
        variant="f2"
        weight="semibold"
        color={colorTheme.lightGray}
      />

      <div className="container">
        {props.jobHistory?.map((j, i) => {
          return (
            <KazamExperienceCard
              key={i}
              data={j}
            />
          )
        })}
      </div>
    </StyledKazamExperience>
  );
};

export default KazamExperience;
