import styled from "styled-components";
import {colorTheme} from "../../../../core/configs";

export const StyledAdminUsersPage = styled.div`
`;

export const StyledAdminUsersTabs = styled.div`
  margin-top: 24px;

  & .tab-control {
    border: solid 1px ${colorTheme.lightGray};
    background-color: ${colorTheme.light};
    padding: 8px;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .tab-control.active {
    color: white;
    background-color: ${colorTheme.primary};
  }
`;

export const StyledAdminReportUsersTable = styled.div`
  margin-top: 24px;
  
  & .btn-ban {
    padding: 14px;
    background-color: transparent;
  }

  & .table-row {
    cursor: pointer;
  }
`;

export const StyledAdminAllUsersTable = styled.div`
  margin-top: 24px;
`;

export const StyledAdminUsersSearch = styled.div`
  margin-top: 36px;

  & .btn-wrapper {
    margin-left: 24px;
  }
`;

export const StyledAdminTable = styled.div`
  .table-container {
    min-height: 600px;
  }
  
  & table {
    border-top: 1px solid ${colorTheme.lightGray};
    border-bottom: 1px solid ${colorTheme.lightGray};
  }

  & table tbody tr {
    border-top: 2px solid ${colorTheme.lightGray};
  }

  & table tbody tr:not(:last-child) {
    border-bottom: 2px solid ${colorTheme.lightGray};
  }

  & table tr th {
    padding: 17px 14px;
  }

  & table tr td {
    padding: 14px;
  }

  & th {
    text-align: start;
  }

  & .pagination {
    margin-top: 24px;
  }

  & .pagination-control {
    min-width: 50px;
    min-height: 40px;
    border: 1px solid ${colorTheme.lightGray};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  & .selected {
    background-color: ${colorTheme.primary};
  }

  & .pagination-control.clickable,
  .pagination-control.nav-button {
    cursor: pointer;
  }

  & .pagination-control.clickable:hover,
  .pagination-control.nav-button:hover {
    background-color: ${colorTheme.lightGray};
  }

  & .row-dark {
    background-color: #F3F3F3;
  }
`;

export const StyledAdminUsersTable = styled.div`
  margin-top: 24px;
`;

export const StyledAdminReportersViewPage = styled.div`
  & .table-container {
    margin-top: 36px;
  }
  
  & .back-btn-container {
    margin-right: 8px;
  }
`;
