import React, {ElementType} from 'react';
import {StyledTypography} from './styled';
import {
  TypographyVariant,
  TypographyWeight,
  TypographyAlignment,
} from './typography.types';

const variantComponentMapping: {[key in TypographyVariant]: string} = {
  f1: 'h6',
  f2: 'h5',
  f3: 'h4',
  f4: 'h3',
  f5: 'h2',
  f6: 'h1',
  caption: 'p',
};

const weightMapping: {[key in TypographyWeight]: number} = {
  normal: 400,
  light: 300,
  semibold: 600,
  bold: 700
};

export interface TypographyProps {
  component?: ElementType;
  variant?: TypographyVariant;
  weight?: TypographyWeight;
  align?: TypographyAlignment;
  className?: string;
  label: React.ReactText;
  color?: string;
  singleLine?: boolean;
}

const Typography: React.ForwardRefRenderFunction<unknown, TypographyProps> = (
  props,
  ref,
) => {
  const {
    component,
    variant = 'f1',
    weight = 'normal',
    align = 'left',
    label,
    className,
    color,
  } = props;

  const styles = {
    variant,
    align,
    weight: weightMapping[weight],
    color,
  };

  const tag = component || variantComponentMapping[variant];

  return (
    <StyledTypography
      as={tag}
      className={className}
      {...styles}
      singleLine={props.singleLine}
      dangerouslySetInnerHTML={{ __html: label }}>
    </StyledTypography>
  );
};

export default Typography;
