import React, {MouseEventHandler} from 'react';
import {getIconComponentByName} from './icon.util';
import {IconName, IconType} from './icon.types';
import {StyledIcon} from './styled';
import {colorTheme} from '../../configs';

export type IconProps = {
  /**
   * Icon name. This will be used for mapping the icon svg asset.
   * See list of the supported icon name.
   */
  name?: IconName;

  /**
   */
  iconSrc?: string;

  color?: string;

  /**
   * Icon size in px.
   * Size will be used as the height
   * while width will be set to auto.
   * @default - 24
   */
  size?: number;

  strokeWidth?: string | number;

  onClick?: MouseEventHandler<HTMLImageElement | SVGElement>;

  className?: string;
  disabled?: boolean;

  type?: IconType;
};

// TODO: should improve the Icon component to handle change of color
const Icon: React.ForwardRefRenderFunction<unknown, IconProps> = (
  props,
  ref,
) => {
  const {
    name,
    iconSrc,
    size = 24,
    color = colorTheme.primary,
    onClick,
    className,
    disabled = false,
    type = 'default',
    strokeWidth,
  } = props;

  const styles = {
    size,
    clickable: type === 'button' ? true : false,
    disabled,
  };

  if (!!name) {
    const IconComp = getIconComponentByName(name);

    return (
      <IconComp
        data-testid="icon-name-element"
        className={className}
        height={size + 'px'}
        width={size + 'px'}
        color={color}
        strokeWidth={strokeWidth}
        onClick={onClick}
        style={{
          cursor: disabled
            ? 'not-allowed'
            : styles.clickable
            ? 'pointer'
            : 'default',
        }}
      />
    );
  }

  if (!!iconSrc) {
    return (
      <StyledIcon
        data-testid="icon-src-element"
        className={className}
        src={iconSrc}
        alt="icon-src"
        onClick={onClick}
        {...styles}
      />
    );
  }

  // return empty element if name and iconSrc is not supplied
  return <></>;
};

export default Icon;
