import React, {useEffect} from 'react';
import {Icon, Typography} from '..';
import {colorTheme} from '../../configs';
import {StyledImg, StyledToast} from './styled';
import {ToastVariant, ToastPosition} from './toast.types';
import searchNotif from '../../../assets/search_notif.svg';
import kaway from '../../../assets/kaway_illustration.svg';
import { useHistory } from 'react-router-dom';
import { RoutePath } from '../../../app/navigation/config/RouteConfig';

export interface ToastProps {
  /**
   * Displayed message of the toast
   */
  title?: string;
  message: string;
  notificationType?: string;
  notifFromUserId?: string;
  dismiss?: () => void;
  duration?: number;

  position?: ToastPosition;
  variant?: ToastVariant;
  showIcon?: boolean;
  showDismiss?: boolean;

  className?: string;
}

const Toast: React.ForwardRefRenderFunction<unknown, ToastProps> = (
  props,
  ref,
) => {
  const {
    title,
    message,
    notificationType = 'SearchMatch',
    notifFromUserId,
    dismiss,
    duration = 2000,
    variant = 'success',
    position = 'bottom',
    className,
  } = props;

  useEffect(() => {
    if (dismiss) {
      const timeout = setTimeout(() => dismiss(), duration);
      return () => clearTimeout(timeout);
    }
  }, [dismiss, duration]);

  const history = useHistory();
  const handleNotificationRedirection = (type: string) => {
    if (notifFromUserId) {
      if (type === 'SearchMatch') {
        const params = `?userId=${notifFromUserId}`;
        history.push(RoutePath.MATCHES_LANDING + params);
      } else {
        history.push(RoutePath.KAWAYS);
        history.push(RoutePath.FAVORITE_VIEW.replace(':id', notifFromUserId));
      }
    }
  };

  return (
    <StyledToast
      data-testid="toast-element"
      variant={variant}
      position={position}
      className={className}>
      {variant === 'notification' ? (
        <div className="flex space-x-2 cursor-pointer" onClick={() => handleNotificationRedirection(notificationType)}>
          <StyledImg
            src={notificationType === 'SearchMatch' ? searchNotif : kaway}
            alt="searchNotif"></StyledImg>
          <div className="w-full flex items-center justify-between space-x-8">
            <div className="flex flex-col">
              <Typography
                label={title ? title : ''}
                variant="f1"
                weight="semibold"
                color={colorTheme.dark}
              />
              <Typography
                label={message}
                variant="f1"
                weight="normal"
                color={colorTheme.dark}
              />
            </div>
            <div onClick={dismiss}>
              <Icon name="x" color={colorTheme.dark} size={15}></Icon>
            </div>
          </div>
        </div>
      ) : (
        <Typography
          label={message}
          variant="f2"
          weight="semibold"
          color={colorTheme.white}
          align="center"
        />
      )}
    </StyledToast>
  );
};

export default Toast;
