import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  Button,
  Checkbox,
  Icon,
  Input,
  Typography,
  WizardStep,
} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {signUpLoginInformationSchema} from '../../../schema/sign-up.schema';
import {LoginInformation} from '../../../server/types';
import logo from '../../../../assets/KAZAM_logo_Transparent.svg';
import {useHistory, useLocation} from 'react-router-dom';
import {RoutePath} from '../../../navigation/config/RouteConfig';
import {useEffect, useState} from 'react';
import {useToast} from '../../../../core/components/toast';
import {
  useGetPrivacyPolicy,
  useGetTaC,
  usePostOtpRequest,
} from '../../../server/react-query';
import {StyledDiv} from './styled';
import Page from '../../../../core/components/page/Page';
import {localization} from '../../../localization/Localization';
import {useAnalyticsTrackComponent} from '../../../hooks/useAnalyticsTrackComponent';
import {useDialog} from '../../../../core/components/dialog';
import ReadRequiredDocumentDialog from '../../../views/dialog-content/read-required-document-dialog/ReadRequiredDocumentDialog';
import {useAnalytics} from '../../../hooks/useAnalytics';

const SignUpLoginInformationPage = (props: any) => {
  useAnalyticsTrackComponent(SignUpLoginInformationPage.name);

  const history = useHistory();
  const location = useLocation();
  const {presentToast} = useToast();
  const {systemLogEvent} = useAnalytics();
  const {presentDialog, dismissDialog} = useDialog();

  const {data: privacyPolicy, isLoading: gettingPrivacyPolicy} = useGetPrivacyPolicy();
  const {data: termsAndCondition, isLoading: gettingTerms} = useGetTaC();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);

  const {
    control,
    getValues,
    reset,
    formState: {errors},
    handleSubmit,
    setValue,
  } = useForm<LoginInformation>({
    resolver: yupResolver(signUpLoginInformationSchema),
    defaultValues: {
      mobileNumber: '',
      password: '',
      repeatPassword: '',
      acceptTerms: false,
      acceptDataPrivacy: false,
      consentMarketing: true,
    },
  });

  useEffect(() => {
    var signUp = localStorage.getItem('signup');
    if (signUp) {
      var signUpData = JSON.parse(signUp);
      reset(signUpData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitForm = async () => {
    handleSubmit(handleNext)();
  };

  const handleNext = () => {
    try {
      requestOTP();
    } catch (error) {
      console.log('error', error);
    }
  };

  const postOtpRequest = usePostOtpRequest();
  const requestOTP = async () => {
    try {
      setLoading(true);
      var data = getValues();
      await postOtpRequest.mutateAsync({
        mobileNumber: data.mobileNumber,
      });

      setLoading(false);
      localStorage.setItem('signup', JSON.stringify(data));

      if (location.pathname === RoutePath.EMPLOYER_SIGN_UP) {
        systemLogEvent('homeo_reg_step_1');
      } else {
        systemLogEvent('kazam_reg_step_1');
      }

      props.nextStep();
    } catch (error: any) {
      setLoading(false);
      presentToast({
        message: error.data.error,
        variant: 'error',
        position: 'bottom',
      });
    }
  };

  const cancelSignUp = () => {
    localStorage.removeItem('signup');
    history.replace(RoutePath.LANDING);
  };

  const showTerms = (val: boolean) => {
    if (!val) {
      presentDialog({
        headerText: '',
        content: (
          <ReadRequiredDocumentDialog
            headerText={'Terms and Conditions'}
            content={termsAndCondition ? termsAndCondition.content : ''}
            disagreeButtonText={localization.btn_disagree}
            agreeButtonText={localization.btn_agree}
            onDisagreeButtonAction={() => {
              setValue('acceptTerms', false);
              dismissDialog();
            }}
            onAgreeButtonAction={() => {
              setValue('acceptTerms', true);
              dismissDialog();
            }}
            accepted={getValues().acceptTerms}
          />
        ),
        enableBackdropDismiss: false,
        hideClose: true,
        fullWidth: true,
      });
    }
  };

  const showPrivacyPolicy = (val: boolean) => {
    if (!val) {
      presentDialog({
        headerText: '',
        content: (
          <ReadRequiredDocumentDialog
            headerText={'Data Privacy Policy'}
            content={privacyPolicy ? privacyPolicy.content : ''}
            disagreeButtonText={'Disagree'}
            agreeButtonText={'Agree'}
            onDisagreeButtonAction={() => {
              setValue('acceptDataPrivacy', false);
              dismissDialog();
            }}
            onAgreeButtonAction={() => {
              setValue('acceptDataPrivacy', true);
              dismissDialog();
            }}
            accepted={getValues().acceptDataPrivacy}
          />
        ),
        enableBackdropDismiss: false,
        hideClose: true,
        fullWidth: true,
      });
    }
  };

  return (
    <Page hideNavbar showLoading={gettingPrivacyPolicy || gettingTerms}>
      <div className="space-y-6">
        <div>
          <WizardStep
            steps={props.totalSteps}
            currentStep={props.currentStep}></WizardStep>
        </div>
        <div className="flex justify-center">
          <img style={{height: '29px'}} src={logo} alt="Logo" />
        </div>
        <div>
          <div className="flex flex-1">
            <Typography
              label={localization.hdr_sign_up}
              variant="f3"
              align="center"
              color={colorTheme.dark}
            />
          </div>
          <div className="pt-1">
            <Typography
              label={
                location.pathname === RoutePath.EMPLOYER_SIGN_UP
                  ? localization.desc_ho_sign_up
                  : localization.desc_kzm_sign_up
              }
              variant="f1"
              align="center"
              color={colorTheme.darkFaded}></Typography>
          </div>
        </div>
        <div>
          <div>
            <div>
              <Controller
                control={control}
                name="mobileNumber"
                render={({field: {value = '', onBlur, onChange}}) => (
                  <Input
                    type="tel"
                    inputSize="large"
                    placeholder={localization.ph_enter_mobile}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={errors.mobileNumber?.message}
                  />
                )}
              />
            </div>
            <div className="pt-4">
              <Controller
                control={control}
                name="password"
                render={({field: {value = '', onBlur, onChange}}) => (
                  <Input
                    inputSize="large"
                    type={showPassword ? 'text' : 'password'}
                    placeholder={localization.ph_enter_pw}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={errors.password?.message}
                    iconRight={
                      <Icon
                        name={showPassword ? 'eye' : 'eyeOff'}
                        size={16}
                        color={value ? colorTheme.dark : colorTheme.darkFaded}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    }
                  />
                )}
              />
            </div>

            <div className="pt-4">
              <Controller
                control={control}
                name="repeatPassword"
                render={({field: {value = '', onBlur, onChange}}) => (
                  <Input
                    inputSize="large"
                    type={showRepeatPassword ? 'text' : 'password'}
                    placeholder={localization.ph_repeat_pw}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    onSubmit={submitForm}
                    error={errors.repeatPassword?.message}
                    iconRight={
                      <Icon
                        name={showRepeatPassword ? 'eye' : 'eyeOff'}
                        size={16}
                        color={value ? colorTheme.dark : colorTheme.darkFaded}
                        onClick={() =>
                          setShowRepeatPassword(!showRepeatPassword)
                        }
                      />
                    }
                  />
                )}
              />
            </div>
          </div>
          <div className="space-y-4 pt-4">
            <div className="flex">
              <Controller
                control={control}
                name="acceptTerms"
                render={({field: {value = false, onBlur, onChange}}) => (
                  <div className="flex" onClick={() => showTerms(value)}>
                    <Checkbox
                      checked={value}
                      onChange={onChange}
                      disabled={!value}
                      color={errors.acceptTerms?.message ? 'danger' : 'default'}
                    />
                    <div className="flex space-x-1">
                      <Typography
                        label="I consent to "
                        variant="f1"
                        align="left"
                        color={
                          errors.acceptTerms?.message
                            ? colorTheme.danger
                            : colorTheme.dark
                        }></Typography>
                      <div onClick={() => showTerms(false)}>
                        <Typography
                          label="Terms and Conditions."
                          variant="f1"
                          weight="semibold"
                          align="left"
                          color={
                            errors.acceptTerms?.message
                              ? colorTheme.danger
                              : colorTheme.primary
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="acceptDataPrivacy"
                render={({field: {value = false, onBlur, onChange}}) => (
                  <div
                    className="flex"
                    onClick={() => showPrivacyPolicy(value)}>
                    <Checkbox
                      checked={value}
                      onChange={onChange}
                      disabled={!value}
                      color={
                        errors.acceptDataPrivacy?.message ? 'danger' : 'default'
                      }
                    />
                    <div className="flex space-x-1">
                      <Typography
                        label="I consent to "
                        variant="f1"
                        align="left"
                        color={
                          errors.acceptDataPrivacy?.message
                            ? colorTheme.danger
                            : colorTheme.dark
                        }></Typography>
                      <div onClick={() => showPrivacyPolicy(false)}>
                        <Typography
                          label="Data Privacy Policy."
                          variant="f1"
                          weight="semibold"
                          align="left"
                          color={
                            errors.acceptDataPrivacy?.message
                              ? colorTheme.danger
                              : colorTheme.primary
                          }></Typography>
                      </div>
                    </div>
                  </div>
                )}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="consentMarketing"
                render={({field: {value = true, onBlur, onChange}}) => (
                  <div className="flex">
                    <Checkbox
                      checked={true}
                      onChange={onChange}
                      color={
                        errors.consentMarketing?.message ? 'danger' : 'default'
                      }
                    />
                    <div>
                      <StyledDiv
                        dangerouslySetInnerHTML={{
                          __html:
                            'I consent to receive marketing messages from <b>kazam</b> and its affiliates.',
                        }}
                      />
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
        <div id="recaptcha-container"></div>
        <div>
          <div>
            <Button
              label={localization.btn_next}
              color="primary"
              isLoading={loading}
              onClick={submitForm}
            />
          </div>
          <div className="pt-4">
            <Button
              label={localization.btn_cancel}
              variant="outlined"
              onClick={cancelSignUp}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};
export default SignUpLoginInformationPage;
