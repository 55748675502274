import styled, {css} from "styled-components";
import {colorTheme} from "../../../core/configs";


interface StyledKazamExperienceProps {
}

export const StyledKazamExperience = styled.div<StyledKazamExperienceProps>`


.heading{
    color: ${colorTheme.tint};
    font-weight:600;
}

.card:not(:last-child) {
    border-bottom: 1px solid ${colorTheme.lightGray};
}


`;