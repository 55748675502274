import React, {useCallback, useEffect, useRef, useState} from 'react';
import {LoadMore, Typography, Icon, Button} from '../../../core/components';
import {colorTheme} from '../../../core/configs';

import {StyledChatMessageMenu} from './styled';
import {RoutePath} from '../../navigation/config/RouteConfig';
import {useHistory} from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

interface ChatMenuOptionsProps {
  channelId: string
  hideSendOffer?: boolean
  onClickAddAttachment?: () => void;
  onClickShowSendOfferDialog?: () => void;
  onClickOpenOptions?: () => void;
}

const ChatMenuOptions = ({
  channelId,
  hideSendOffer,
  onClickAddAttachment,
  onClickShowSendOfferDialog,
  onClickOpenOptions,
}: ChatMenuOptionsProps) => {
  const history = useHistory();
  const {authData} = useAuth()

  const handleAddAttachmentClick = () => {
    onClickAddAttachment?.();
  };

  const handleShowSendOfferDialog = () => {
    onClickShowSendOfferDialog?.();
    onClickOpenOptions?.();
  };

  const redirectToCurrentLocation = () => {
    const url = RoutePath.CURRENT_LOCATION.replace(':id', channelId);
    history.push(url);
  };

  return (
    <StyledChatMessageMenu>
      <ul className="message-menu-options">
        <li onClick={handleAddAttachmentClick}>
          <div className="flex gap-2">
            <span>
              <Icon
                className="cursor-pointer"
                name="paperclip"
                color={colorTheme.darkFaded}
                type="button"
                size={24}
              />
            </span>
            <span>
              <label>Add Attachment</label>
            </span>
          </div>
        </li>

        <li onClick={redirectToCurrentLocation}>
          <div className="flex gap-2">
            <span>
              <Icon
                className="cursor-pointer"
                name="locationLight"
                color={colorTheme.darkFaded}
                type="button"
                size={24}
              />
            </span>
            <span>
              <label>Send My Location</label>
            </span>
          </div>
        </li>


        {authData?.user?.userRole === 'homeowner'
          && !hideSendOffer &&
          <li onClick={handleShowSendOfferDialog}>
            <div className="flex gap-2">
              <span>
                <Icon
                  className="cursor-pointer"
                  name="documentCheckedLight"
                  color={colorTheme.darkFaded}
                  type="button"
                  size={24}
                />
              </span>
              <span>
                <label>Send Job Offer</label>
              </span>
            </div>
          </li>
        }
      </ul>
    </StyledChatMessageMenu>
  );
};

export default ChatMenuOptions;
