import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export const StyledImageWrapper = styled.div`
  width: 100%;
  height: 340px;
  background: linear-gradient(-90deg, #330099, #4F53C3, #4F53C3, #330099);
  background-size: 400% 400%;
  border-radius: 16px;
  animation: ${shimmer} 3s ease infinite;
`;

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  border-radius: 16px;
`;
