import Page from '../../../../core/components/page/Page';
import {Button, Icon, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {StyledDiv, StyledImg} from './styled';
import {localization} from '../../../localization/Localization';
import {useHistory} from 'react-router';
import {RoutePath} from '../../../navigation/config/RouteConfig';
import disabled_acct from '../../../../assets/disabled_acct.svg';

const DisableAccountPage = () => {
  const history = useHistory();

  return (
    <Page
      showMenu
      showRightComponent
      rightComponent={
        <Icon name="messageMulti" color={colorTheme.darkFaded} />
      }>
      <StyledDiv className="space-y-4">
        <div className="flex justify-center">
          <StyledImg src={disabled_acct} />{' '}
        </div>

        <div>
          <Typography
            label={localization.hdr_disabled_profile}
            variant="f3"
            align="center"
            weight="semibold"
            color={colorTheme.dark}
          />
          <div className="pt-1">
            <Typography
              label={localization.desc_disabled_profile}
              variant="f1"
              align="center"
              color={colorTheme.darkFaded}></Typography>
          </div>
        </div>
        <div className="pt-6">
          <Button
            label={localization.btn_view_profile}
            color="primary"
            onClick={() => history.push(RoutePath.MY_PROFILE)}
          />
        </div>
      </StyledDiv>
    </Page>
  );
};

export default DisableAccountPage;
