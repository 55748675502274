import {useMutation, useQuery} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';
import { CheckoutSession, CreateCheckoutSession, UserSubscriptionResponse } from '../types';

const postCheckoutSession = (body: CreateCheckoutSession) => {
  return API.post<CheckoutSession>(ApiEndpointsEnum.POST_CHECKOUT, body);
};

const usePostCheckoutSession = () => {

  return useMutation(
    ['post-checkout-session'], (body: CreateCheckoutSession) => postCheckoutSession(body)
  );
};

const getCheckoutSession = () => {
  return API.get<UserSubscriptionResponse>(ApiEndpointsEnum.GET_CHECKOUT);
};

const useGetCheckoutSession = () => {
  return useQuery(['get-checkout-session'], () => getCheckoutSession());
};

export {
  usePostCheckoutSession,
  useGetCheckoutSession,
  getCheckoutSession
};
