import {FormEvent, useEffect, useState} from 'react';
import {StyledChatPage} from './styled';
import {Icon, Input, Typography} from '../../../../core/components';
import ChatsComponent from './ChatsComponent';
import {localization} from '../../../localization/Localization';
import AdminPage from '../../../../core/components/admin-page/AdminPage';
import { colorTheme } from '../../../../core/configs';
import { useHistory } from 'react-router-dom';
import { RoutePath } from '../../../navigation/config/RouteConfig';
import AdminChatsTabs from './AdminChatsTabs';
import { UserRole } from '../../../server/types';

const AdminChatPage = () => {
  const history = useHistory();
  
  const [activeTab, setActiveTab] = useState<UserRole>('homeowner');
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const searchChangedHandler = (event: FormEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  };

  const filterClickedHandler = () => {
    history.push(RoutePath.CHAT_FILTER.replace(':id', '0'));
  };

  const onChangeTab = (role: UserRole) => {
    setSearchValue("")
    setActiveTab(role)
  }

  return (
    <AdminPage>
      <div>
        <Typography
          label="Chats"
          variant="f3"
          weight="semibold"
          color={colorTheme.dark}
        />
      </div>

      <AdminChatsTabs selected={activeTab} onChange={onChangeTab} />

      <StyledChatPage>
        <div className="search-bar">
          <Input
            placeholder={localization.ph_search_name_uuid}
            inputSize="large"
            value={searchValue}
            onChange={searchChangedHandler}
            iconRight={
              <Icon
                name="filter"
                size={14}
                color={colorTheme.darkFaded}
                type="button"
                onClick={filterClickedHandler}
              />
            }
          />
        </div>

        <div className="page">
          <ChatsComponent
            searchQuery={searchValue}
            userRole={activeTab}/>
        </div>
      </StyledChatPage>
    </AdminPage>
  );
};

export default AdminChatPage;
