import React, {FormEvent, useState} from "react";
import {Button, Icon, Input} from "../../../../core/components";
import {colorTheme} from "../../../../core/configs";
import {StyledAdminUsersSearch} from "./styled";

interface AdminUsersSearchProps {
  onChange?: (query: string) => void;
  showExtractButton?: boolean;
  extractClickHandler?: () => void;
  placeholder?: string;
}

const AdminUsersSearch = (props: AdminUsersSearchProps) => {
  const [searchQuery, setSearchQuery] = useState<string>('');

  const onSearchInputChangedHandler = (event: FormEvent<HTMLInputElement>) => {
    setSearchQuery(event.currentTarget.value);

    if (!event.currentTarget.value) {
      setTimeout(() => {
        props.onChange && props.onChange('');
      }, 300);
    }
  };

  const onClearSearchClickedHandler = () => {
    setSearchQuery('');
    setTimeout( () => props.onChange && props.onChange(''), 300);
  };

  const onSearchClickedHandler = async () => {
    props.onChange && props.onChange(searchQuery);
  };

  const onExtractAllClickedHandler = () => {
    if (props.showExtractButton) {
      props.extractClickHandler && props.extractClickHandler();
    }
  };

  return (
    <StyledAdminUsersSearch className="w-full flex flex-row items-center">
      <div className="search-input flex-1 flex flex-col">
        <Input
          iconLeft={(
            <Icon
              name="search"
              size={12}
              color={colorTheme.darkFaded} />
          )}
          onChange={onSearchInputChangedHandler}
          value={searchQuery}
          iconRight={searchQuery ? (
            <Icon
              type="button"
              onClick={onClearSearchClickedHandler}
              color={colorTheme.dark}
              name="x"
              size={12} />
          ) : undefined}
          inputSize="large"
          placeholder={props.placeholder ?? "Search user name"} />
      </div>
      <div className="btn-wrapper">
        <Button label="Search" color="primary" onClick={onSearchClickedHandler} />
      </div>
      {props.showExtractButton && (
        <div className="btn-wrapper">
          <Button
            label="Extract All"
            variant="outlined"
            className="btn-search"
            onClick={onExtractAllClickedHandler} />
        </div>
      )}
    </StyledAdminUsersSearch>
  );
};

export default AdminUsersSearch;
