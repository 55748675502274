import styled from 'styled-components';
import {ImageUploadSize, ImageUploadSizeStyle} from '.';
import {colorTheme} from '../../../core/configs';

const imageUploadSizeStyles: {
  [key in ImageUploadSize]: ImageUploadSizeStyle;
} = {
  profile: {
    height: '200px',
    width: '200px',
    textSize: 0.75,
    lineheight: 1.125,
  },
  splash: {
    height: '245px',
    width: '210px',
    textSize: 0.75,
    lineheight: 1.125,
  },
  banner: {
    height: '245px',
    width: '100%',
    textSize: 0.75,
    lineheight: 1.125,
  },
};

export interface StyleImageUploadProps {
  imageUploadSize: ImageUploadSize;
}

export const StyledDefaultImageView = styled.div<StyleImageUploadProps>`
  position: relative;
  width: ${pr => imageUploadSizeStyles[pr.imageUploadSize].width};
  height: ${pr => imageUploadSizeStyles[pr.imageUploadSize].height};
  border-radius: 4px;
  border: 2px solid ${colorTheme.lightGray};
`;

export const StyledImageOverlay = styled.div<StyleImageUploadProps>`
  padding: 16px;
  top: 0;
  position: absolute;
  width: ${pr => imageUploadSizeStyles[pr.imageUploadSize].width};
  height: ${pr => imageUploadSizeStyles[pr.imageUploadSize].height};
  border-radius: 4px;

  & .image {
    background: rgba(0, 0, 0, 0.25);
  }
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  border-radius: 4px;
`;

export const StyledInputLabel = styled.div`
  margin-bottom: 0.25rem;

  font-size: 0.8rem;
  font-weight: 600;
  line-height: 16.8px;

  color: ${colorTheme.darkFaded};
`;

export const StyledError = styled.div`
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;

  font-size: 0.8rem;
  line-height: 16.8px;

  color: ${colorTheme.danger};
`;
