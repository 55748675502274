import GenericConfirmationDialog from '../../../app/views/dialog-content/generic-dialog/GenericConfirmationDialog';
import {colorTheme} from '../../configs';
import { useDialog } from '../dialog';
import Icon from '../icon';
import Typography from '../typography';
import {StyledDiv, StyledCircleDiv} from './styled';

export interface NoShowTagProps {
  type?: 'iconOnly' | 'NoShowText';
  onClickNoShowText?: () => void;
}

const NoShowTag = (props: NoShowTagProps) => {
  const {presentDialog} = useDialog()
  const {type = 'iconOnly', onClickNoShowText} = props;

  const showNoShowDialog = () => {
    presentDialog({
      headerText: '',
      content: (
        <GenericConfirmationDialog
          headerText="NO SHOW"
          desc="This user has been tagged as no show by another homeowner."
        />
      ),
      enableBackdropDismiss: true,
    });
  };

  if (type === 'NoShowText') {
    return (
      <StyledDiv
        className="space-x-1 cursor-pointer"
        onClick={showNoShowDialog}>
        <Icon name="userCross" size={16} color={colorTheme.white} />

        <Typography
          label="No Show"
          variant="f2"
          weight="semibold"
          color={colorTheme.white}
        />
      </StyledDiv>
    );
  }

  return (
    <StyledCircleDiv>
      <Icon name="fileText" size={12} color={colorTheme.white} />
    </StyledCircleDiv>
  );
};

export default NoShowTag;
