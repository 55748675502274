import {Button, Icon, Typography, WizardStep} from '../../../core/components';
import {colorTheme} from '../../../core/configs';
import {useHistory, useLocation} from 'react-router-dom';

interface PersonalityTestOptionProps {
  text: string;
  value: number;
  selected: boolean;
  onSelect?: () => void
}

const PersonalityTestOption = ({
  text,
  value,
  selected,
  onSelect
}: PersonalityTestOptionProps) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <div className='flex items-center space-x-2 cursor-pointer'
      onClick={onSelect}
    >
      <div
        className="flex items-center justify-center w-10 h-10 rounded-3xl"
        style={{
          background: selected ? colorTheme.tint : colorTheme.lightGray,
        }}>
        <Typography
          label={value}
          color={selected ? colorTheme.light : colorTheme.tint}
          variant="f2"
          weight="semibold"
        />
      </div>
      <Typography
        label={text}
        color={selected ? colorTheme.dark : colorTheme.darkFaded}
        variant="f2"
        weight="semibold"
      />
    </div>
  );
};
export default PersonalityTestOption;
