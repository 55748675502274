import Page from '../../../core/components/page/Page';
import {Button, Typography} from '../../../core/components';
import {colorTheme} from '../../../core/configs';
import verification from '../../../assets/verification.svg';
import {StyledImg} from './styled';

import {useGetMyProfile} from '../../server/react-query';

import VerifiedTag from '../../../core/components/verified-tag/VerifiedTag';
import {useEffect, useState} from 'react';
import {VerifiedStatus} from '../../server/types';
import {useHistory} from 'react-router-dom';
import {RoutePath} from '../../navigation/config/RouteConfig';
import {addMonths, differenceInMonths, format} from 'date-fns';
import {useDialog} from '../../../core/components/dialog';
import GenericConfirmationDialog from '../../views/dialog-content/generic-dialog/GenericConfirmationDialog';

const VerificationPage = () => {
  const {data, isFetching, refetch} = useGetMyProfile();
  const {presentDialog, dismissDialog} = useDialog();

  const history = useHistory();

  const [status, setStatus] = useState<VerifiedStatus>('notVerified');

  useEffect(() => {
    if (data && data?.verifiedStatus) {
      setStatus(data.verifiedStatus);
    }
  }, [data]);

  const onStartIdVerification = async () => {
    if (data) {
      const lastVerifiedDate = data?.lastVerifiedDate
        ? new Date(data.lastVerifiedDate)
        : null;
      const currentDate = new Date();

      // If the user has never verified, allow them to proceed
      if (!lastVerifiedDate) {
        history.push(RoutePath.IDENTITY_VERIFICATION);
        return;
      }

      const monthsSinceLastVerification = differenceInMonths(
        currentDate,
        lastVerifiedDate,
      );

      // Check if 6 months or more have passed since last verification
      if (monthsSinceLastVerification >= 6) {
        history.push(RoutePath.IDENTITY_VERIFICATION);
      } else {
        const monthsLeft = 6 - monthsSinceLastVerification;
        const nextEligibleDate = addMonths(lastVerifiedDate, 6);
        const formattedNextEligibleDate = format(
          nextEligibleDate,
          'MMMM dd, yyyy',
        );

        presentDialog({
          headerText: '',
          content: (
            <GenericConfirmationDialog
              headerText="Get ID Verified"
              desc={`You can only verify again in ${monthsLeft} months, on or after ${formattedNextEligibleDate}.`}
            />
          ),
          enableBackdropDismiss: false,
        });
      }
    }
  };

  return (
    <Page showBackButton showLoading={isFetching}>
      <div className="space-y-4">
        <div>
          <StyledImg src={verification} alt="verification"></StyledImg>
        </div>

        <div className="flex space-x-1 justify-center items-center">
          <VerifiedTag type="verifiedText" />
          <Typography
            label="at kazam"
            variant="f3"
            weight="semibold"
            align="center"
            color={colorTheme.dark}
          />
        </div>

        <Typography
          label="Users with the ID Verified tag were able to present valid government IDs that match their profile details (name, photo, birthdate)​."
          variant="f1"
          color={colorTheme.darkFaded}
          align="center"
        />

        <div className="flex flex-col items-center space-y-1">
          <Typography
            label="E-KYC verification does not guarantee trustworthiness or level of job experience, so remember to do your due diligence when dealing with other users on the platform.​"
            variant="f1"
            color={colorTheme.darkFaded}
            align="center"
          />
        </div>

        {(!!data?.faceDocumentVerified || status === 'verified') && (
          <>
            <div className="flex flex-col items-center space-y-1">
              <Typography
                label="Your profile is"
                variant="f1"
                color={colorTheme.darkFaded}
                align="center"
              />
              <VerifiedTag type="verifiedText" />
              <Typography
                label="To maintain your status, remember to keep your full name, birthdate, and photo unchanged. Changing those details will reset your ID Verified status.​​"
                variant="f1"
                color={colorTheme.darkFaded}
                align="center"
              />
            </div>
          </>
        )}

        {data !== null && data !== undefined && (
          <>
            {data?.faceDocumentVerified === false &&
              status !== 'verified' &&
              status !== 'pending' && (
                <Button
                  label={'START ID VERIFICATION'}
                  color={'primary'}
                  onClick={onStartIdVerification}
                />
              )}

            {data?.verifiedStatus === 'pending' && (
              <Button
                label={'Pending Request'}
                color={'darkFaded'}
                disabled={true}
              />
            )}
          </>
        )}
      </div>
    </Page>
  );
};

export default VerificationPage;
