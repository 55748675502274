import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  Typography,
  Input,
  Button,
  WizardStep,
  Select,
  SeparatedDatePickerComponent,
  Typehead,
} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {
  signUpEmployerPrimaryInformationSchema,
  signUpPrimaryInformationSchema,
} from '../../../schema/sign-up.schema';
import {Gender, genderList, PrimaryInformation} from '../../../server/types/user.types';
import logo from '../../../../assets/KAZAM_logo_Transparent.svg';
import {useHistory} from 'react-router-dom';
import {RoutePath} from '../../../navigation/config/RouteConfig';
import {
  useGetAdminControl,
  useGetLocations,
  usePostFilterSettings,
  usePostUser,
} from '../../../server/react-query';
import {
  AuthMe,
  LocationResponse,
  PostUserBody,
} from '../../../server/types';
import {useLocation} from 'react-router-dom';
import {useAuth} from '../../../hooks/useAuth';
import Page from '../../../../core/components/page/Page';
import firebase from 'firebase/app';
import {capitalize, getGenderEnum, getGenderString} from '../../../utils/random.util';
import {useEffect, useState} from 'react';
import {localization} from '../../../localization/Localization';
import {useAnalyticsTrackComponent} from '../../../hooks/useAnalyticsTrackComponent';
import {useAnalytics} from '../../../hooks/useAnalytics';
import {calculateAge} from '../../../utils/date.util';
import {PostFilterSettingsBody} from '../../../server/types/filter-settings.types';
import { STORAGE } from '../../../utils/storage.utils';
import format from 'date-fns/format';
import { QuantityContent } from '../../../server/types/admin-control.types';

const SignUpPersonalInformationPage = (props: any) => {
  useAnalyticsTrackComponent(SignUpPersonalInformationPage.name);

  const {systemLogEvent, initializeUser} = useAnalytics();
  const {updateAuthData} = useAuth();
  const history = useHistory();
  const postUser = usePostUser();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState<LocationResponse[]>([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedLocation, setSelectedLocation] = useState<LocationResponse>();

  const params = new URLSearchParams(location.search);

  const {data: locationsData} = useGetLocations();
  const {data: minSalary} = useGetAdminControl('salaryLowerLimit');
  const postFilterSettings = usePostFilterSettings();

  const {
    control,
    getValues,
    setValue,
    reset,
    formState: {errors},
    handleSubmit,
  } = useForm<PrimaryInformation>({
    resolver: yupResolver(
      location.pathname === RoutePath.EMPLOYER_SIGN_UP
        ? signUpEmployerPrimaryInformationSchema
        : signUpPrimaryInformationSchema,
    ),
    defaultValues: {
      firstName: '',
      lastName: '',
      birthDate: '',
      gender: '',
      location: '',
    },
  });

  useEffect(() => {
    if (locationsData) {
      setLocations(locationsData);
      localStorage.setItem('locations', JSON.stringify(locationsData));

      const signUpData = STORAGE.get<PrimaryInformation>('signUpData', true)
      if (signUpData) {
        const birthDate = signUpData?.birthDate ? new Date(format(
          new Date(signUpData.birthDate.toString()),
          'M/dd/yyyy',
        )) : "";
        reset({
          ...signUpData,
          birthDate
        })
      }

      if (signUpData?.location) {
        var sel = locationsData?.find(l => l.locationId === signUpData?.location)
        if (!!sel) setSelectedLocation(sel)
      }

      const selected = STORAGE.get<LocationResponse>('currentLocation', true)
      if (!!selected) setSelectedLocation(selected)
      STORAGE.clean('signUpCurrentStep')
      STORAGE.clean('signUpData')
    }
  }, [locationsData]);

  const submitForm = async () => {
    handleSubmit(handleNext)();
  };

  const handleNext = async () => {
    try {
      setLoading(true);
      var formData = getValues();
      var signUp = localStorage.getItem('signup');
      if (signUp) {
        var signUpData = JSON.parse(signUp);
        signUpData.personalData = formData;
        localStorage.setItem('signup', JSON.stringify(signUpData));
        localStorage.setItem('homeownerLocation', formData.location);
        localStorage.setItem('homeownerLocationName', selectedCity);
        var referralCode = localStorage.getItem('referralCode');
        
        const values: PostUserBody = {
          mobileNumber: signUpData.mobileNumber,
          userRole:
            location.pathname === RoutePath.EMPLOYER_SIGN_UP
              ? 'homeowner'
              : 'kazam',
          firebaseUserId: signUpData.firebaseUserId,
          firebaseRefreshToken: signUpData.firebaseRefreshToken,
          firstName: formData.firstName,
          lastName: formData.lastName,
          gender: getGenderEnum(formData.gender),
          birthDate: formData.birthDate,
          password: signUpData.password,
          referralCode: referralCode || undefined,
        };
        var response = (await postUser.mutateAsync(values)) as AuthMe;

        if (response && response.customToken) {
          await firebase
            .auth()
            .signInWithCustomToken(response.customToken)
            .then((res: any) => {
              setLoading(false);
              response.accessToken = res.user.Aa;
              updateAuthData(response);

              try {
                // post filter settings
                addFilterSettings();

                // Ensure initialized
                // initialize user properties as well
                initializeUser(response.user);

                // Registration completed
                if (location.pathname === RoutePath.EMPLOYER_SIGN_UP) {
                  systemLogEvent('homeo_reg_step_3');
                } else {
                  systemLogEvent('kazam_reg_step_3');
                }

                systemLogEvent('complete_registration', {
                  age: calculateAge(
                    new Date(response.user.birthDate),
                  ).toString(),
                  gender: capitalize(response.user.gender),
                  city:
                    response.user.filterSetting.location?.cityOrMunicipality +
                    (response.user.filterSetting.location?.hasProvince
                      ? `, ${response.user.filterSetting.location?.province.name}`
                      : ''),
                  role:
                    response.user.userRole === 'homeowner'
                      ? 'homeowner'
                      : 'kazam',
                  salary: response.user.filterSetting?.salary
                    ? response.user.filterSetting?.salary
                    : 0,
                });
              } catch (e) {
                console.log(e);
              }
              STORAGE.clean('currentLocation')
              props.nextStep();
            })
            .catch(error => {
              setLoading(false);
              console.log(error);
            });
        }
      }
    } catch (error) {
      setLoading(false);
      console.log('error', error);
    }
  };

  const addFilterSettings = async () => {
    var formData = getValues();

    if (formData.location) {
      var salary = 0
      if (minSalary
        && minSalary.enabled
        && minSalary.content) {
        let data = JSON.parse(minSalary.content) as QuantityContent
        salary = data.value
      }
      
      const filterSettingsValues: PostFilterSettingsBody = {
        salary: salary,
        livingArrangement: 'stayIn',
        location: formData.location,
        roles: [],
        searchAreaType: 'allMatches',
      };
      await postFilterSettings.mutateAsync(filterSettingsValues);
    }
  };

  const cancelSignUp = () => {
    localStorage.removeItem('signup');
    STORAGE.clean('signUpCurrentStep')
    STORAGE.clean('signUpData')
    STORAGE.clean('currentLocation')
    history.replace(RoutePath.LANDING);
  };

  const goToCurrentLocation = () => {
    let data = getValues()
    if (selectedLocation?.locationId !== data.location) {
      STORAGE.clean('currentLocation')
    }
    
    STORAGE.save('signUpData', data, true)
    STORAGE.save('signUpCurrentStep', props.currentStep)
    history.push(RoutePath.SELECT_CURRENT_LOCATION);
  }

  return (
    <Page hideNavbar>
      <div className="space-y-6">
        <div>
          <WizardStep
            steps={props.totalSteps}
            currentStep={props.currentStep}></WizardStep>
        </div>
        <div className="flex justify-center">
          <img style={{height: '29px'}} src={logo} alt="Logo" />
        </div>
        <div>
          <div>
            <Typography
              label={localization.hdr_su_user_info}
              variant="f3"
              align="center"
              color={colorTheme.dark}></Typography>
          </div>
          <div className="pt-1">
            <Typography
              label={localization.desc_su_user_info}
              variant="f1"
              align="center"
              color={colorTheme.darkFaded}></Typography>
          </div>
        </div>
        <div>
          <div>
            <Controller
              control={control}
              name="firstName"
              render={({field: {value = '', onBlur, onChange}}) => (
                <Input
                  inputSize="large"
                  placeholder={localization.ph_enter_first_name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={errors.firstName?.message}
                  maxLength={20}
                />
              )}
            />
          </div>
          <div className="pt-4">
            <Controller
              control={control}
              name="lastName"
              render={({field: {value = '', onBlur, onChange}}) => (
                <Input
                  inputSize="large"
                  placeholder={localization.ph_enter_last_name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={errors.lastName?.message}
                  maxLength={20}
                />
              )}
            />
          </div>
          <div className="pt-4">
            <Controller
              control={control}
              name="birthDate"
              render={({field: {value, onBlur, onChange}}) => (
                // !isMobile ? (
                //   <DatePicker
                //     value={value}
                //     onChange={onChange}
                //     placeholder={localization.ph_enter_birthdate}
                //     error={errors.birthDate?.message}
                //   />
                // ) : (
                //   <Input
                //     className={value ? 'has-value' : 'empty date-class'}
                //     inputSize="large"
                //     type={!isMobile ? 'text' : 'date'}
                //     placeholder={localization.ph_enter_birthdate}
                //     value={value ? value.toString() : ''}
                //     onFocus={e => {
                //       e.currentTarget.type = 'date';
                //       e.currentTarget.click();
                //     }}
                //     onBlur={e =>
                //       value && !isMobile
                //         ? (e.currentTarget.type = 'date')
                //         : e.currentTarget.blur()
                //     }
                //     onChange={onChange}
                //     error={errors.birthDate?.message}
                //   />
                // )
                <SeparatedDatePickerComponent
                  value={value}
                  onChange={onChange}
                  placeholder={localization.ph_enter_birthdate}
                  error={errors.birthDate?.message}
                />
              )}
            />
          </div>
          <div className="pt-4">
            <Controller
              control={control}
              name="gender"
              render={({field: {value, onChange}}) => (
                <Select
                  placeholder={localization.ph_select_gender}
                  items={genderList}
                  itemRenderer={(item, selected) => (
                    <Typography
                      label={item}
                      variant="f1"
                      color={colorTheme.dark}
                      weight={selected ? 'semibold' : 'normal'}
                      className="flex-1"
                    />
                  )}
                  onChange={onChange}
                  value={value}
                  valueRenderer={value => value}
                  error={errors.gender?.message}
                />
              )}
            />
          </div>
          {location.pathname === RoutePath.EMPLOYER_SIGN_UP && (
            <div className="pt-4">
              <Controller
                control={control}
                name="location"
                render={({field: {value, onChange}}) => (
                  <Typehead
                    items={locations}
                    placeholder={localization.ph_search_loc}
                    onChange={sel => {
                      const item = sel && sel.length > 0 ? sel[0] : null;
                      setSelectedCity(item ? item.cityOrMunicipality : '');
                      onChange(item?.locationId);
                    }}
                    selected={selectedLocation}
                    error={errors.location?.message}
                  />
                )}
              />

              <div onClick={goToCurrentLocation}>
                <Typography
                  className="cursor-pointer"
                  label="Use current location"
                  variant="f1"
                  weight="normal"
                  color={colorTheme.primary}
                />
              </div>
            </div>
          )}
        </div>
        <div>
          <div>
            <Button
              label={localization.btn_next}
              isLoading={postUser.isLoading || loading}
              color="primary"
              onClick={submitForm}
            />
          </div>
          <div className="pt-4">
            <Button
              label={localization.btn_cancel}
              variant="outlined"
              onClick={cancelSignUp}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default SignUpPersonalInformationPage;
