import React, {useEffect, useState} from 'react';
import {Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {StyledAdminAdsTabs} from './styled';

export enum AdTab {
  SPLASH = 'splash',
  BANNER = 'banner',
}

interface AdminAdsTabsProps {
  selected: AdTab;
  onChange?: (tab: AdTab) => void;
  className?: string;
}

const AdminAdsTabs = (props: AdminAdsTabsProps) => {
  const {selected, onChange, className} = props;

  const [activeTab, setActiveTab] = useState<AdTab>(selected);

  useEffect(() => {
    setActiveTab(selected);
  }, [selected]);

  const onTabClickedHandler = (tab: AdTab) => {
    setActiveTab(tab);
    onChange && onChange(tab);
  };

  return (
    <StyledAdminAdsTabs
      className={['tabs-control flex flex-row', className].join(' ')}>
      <div
        className={[
          'tab-control cursor-pointer',
          activeTab === AdTab.SPLASH ? 'active' : '',
        ].join(' ')}
        onClick={() => onTabClickedHandler(AdTab.SPLASH)}>
        <Typography
          label="Splash"
          variant="f2"
          weight="semibold"
          color={
            activeTab === AdTab.SPLASH ? colorTheme.white : colorTheme.dark
          }
          align="center"
        />
      </div>

      <div
        className={[
          'tab-control cursor-pointer',
          activeTab === AdTab.BANNER ? 'active' : '',
        ].join(' ')}
        onClick={() => onTabClickedHandler(AdTab.BANNER)}>
        <Typography
          label="Banner"
          variant="f2"
          weight="semibold"
          color={
            activeTab === AdTab.BANNER ? colorTheme.white : colorTheme.dark
          }
          align="center"
        />
      </div>
    </StyledAdminAdsTabs>
  );
};

export default AdminAdsTabs;
