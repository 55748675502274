interface IColorTheme {
  primary: string;
  secondary: string;
  accent: string;
  tint: string;
  warning: string;
  danger: string;
  black: string;
  white: string;
  dark: string;
  darkFaded: string;
  light: string;
  lightGray: string;
  green: string;
  red: string;
  generatedMessage: string;
  seashell:string;
  magenta:string;
}

export type TColorTheme = keyof IColorTheme;

export const colorTheme: IColorTheme = {
  primary: '#34049C',
  secondary: '#CCC6FF',
  accent: '#998CFF',
  tint: '#150B6B',
  warning: '#FFB032',
  danger: '#FF4343',
  black: '#000000',
  white: '#FFFFFF',
  dark: '#2E394C',
  darkFaded: '#8798AD',
  light: '#FBFBFB',
  lightGray: '#E2E2E2',
  green: '#007E69',
  red: '#B6051B',
  generatedMessage: '#EFEFEF',
  seashell: '#f1eaea',
  magenta: '#AD0082'
};
