import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';
import {Icon, TextArea, Typography} from '../../../../../core/components';
import {colorTheme} from '../../../../../core/configs';
import {StyledDisclaimer, StyledWrapper} from './styled';
import {chatSchema} from '../../../../schema/chat.schema';
import {useToast} from '../../../../../core/components/toast';
import {localization} from '../../../../localization/Localization';

interface Chat {
  message: string;
}

interface ChatTextProps {
  canChat?: boolean;
  isBanned?: boolean;
  fromReported?: boolean;
  conversationEnded?: boolean;
  onSendMessage: (message: string) => void;
  onSelectMedia: (file: any | null) => void;
  setUploadedUri: (uri: string | undefined) => void;
}

const ChatText = ({
  onSendMessage,
  onSelectMedia,
  setUploadedUri,
  canChat = true,
  isBanned = false,
  conversationEnded = false,
  fromReported = false,
}: ChatTextProps) => {
  let inputElement: HTMLElement | null;
  const {presentToast} = useToast();

  const handleClick = () => {
    inputElement?.click();
  };

  const fileSizeChecker = (fileSize: number, uri: string, file: Blob) => {
    if (fileSize / 1000000 > 5) {
      presentToast({
        message: 'File size is over 5MB',
        variant: 'error',
        position: 'bottom',
      });
    } else if (fileSize && fileSize / 1000000 <= 5) {
      onSelectMedia(file);
      setUploadedUri(uri);
    }
  };

  const {control, getValues, reset, handleSubmit} = useForm<Chat>({
    resolver: yupResolver(chatSchema),
    defaultValues: {
      message: '',
    },
  });

  const submitForm = async () => {
    handleSubmit(onSubmit)();
  };

  const onSubmit = async () => {
    var data = getValues();
    onSendMessage(data.message);
    reset();
  };

  const handleFileChange = (event: any) => {
    const {target} = event;
    const {files} = target;

    if (files && files[0]) {
      const reader = new FileReader();

      reader.onload = () => {
        const fileSize = files[0].size;
        fileSizeChecker(fileSize, URL.createObjectURL(files[0]), files[0]);
      };

      reader.readAsDataURL(files[0]);
    }
  };

  const getMessage = () => {
    if (conversationEnded || fromReported) {
      return "You can't reply to this conversation." 
    } else {
      if (isBanned) return "This person is banned on kazam." 
      return "This person is unavailable on kazam."
    }
  }

  return (
    <StyledWrapper className='chat-input-wrapper'>
      {canChat && (
        <StyledDisclaimer>
          <Typography
            className="overflow"
            label={localization.desc_disclaimer}
            variant="caption"
            color={colorTheme.white}
            align="center"
          />
        </StyledDisclaimer>
      )}

      {canChat && !conversationEnded ? (
        <div className="flex flex-row items-center space-x-4">
          <Icon
            className="cursor-pointer"
            name="paperclip"
            color={colorTheme.darkFaded}
            type="button"
            size={24}
            onClick={handleClick}
          />

          <Controller
            control={control}
            name="message"
            render={({field: {value = '', onBlur, onChange}}) => (
              <TextArea
                placeholder={localization.ph_write_msg}
                rows={1}
                maxRows={3}
                autoresize
                value={value}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />

          <Icon
            className="cursor-pointer"
            name="send"
            color={colorTheme.darkFaded}
            type="button"
            size={24}
            onClick={submitForm}
          />
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <Typography
            label={getMessage()}
            variant="f1"
            color={colorTheme.darkFaded}
            align="center"
          />
        </div>
      )}

      <input
        type="file"
        ref={input => (inputElement = input)}
        accept="image/jpeg, image/png, .svg, image/heic, image/heic-sequence, .pdf"
        onChange={handleFileChange}
        style={{display: 'none'}}
      />
    </StyledWrapper>
  );
};

export default ChatText;
