import React from 'react';
import {StyledHideConfirmationDialog} from './styled';
import {Button, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {localization} from '../../../localization/Localization';
import { getHiredLockDuration } from '../../../utils/admin.util';

interface HiredTagDialogProps {
  userName?: any;
}

const HiredTagDialog = (props: HiredTagDialogProps) => {
  const hiredLockDuration = getHiredLockDuration()
  return (
    <StyledHideConfirmationDialog className="flex flex-col items-center">
      <div className="header">
        <Typography
          label={localization.dlg_hdr_hired_tagged}
          variant="f3"
          weight="semibold"
          color={colorTheme.dark}
        />
      </div>

      <div className="message">
        <Typography
          label={`This user has been hired by another homeowner. <br /> Hired kasambahays are unavailable for chat for ${hiredLockDuration} ${hiredLockDuration > 1 ? 'days' : 'day'} after hiring to protect the hiring homeowner.`}
          align="center"
          variant="f1"
          color={colorTheme.darkFaded}
        />
      </div>

      
    </StyledHideConfirmationDialog>
  );
};
  
export default HiredTagDialog;