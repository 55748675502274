import React, {useState} from 'react';
import logo from '../../../../assets/KAZAM_logo_Transparent.svg';
import {Button, Icon, Input, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {useHistory} from 'react-router-dom';
import {useToast} from '../../../../core/components/toast';
import {useAuth} from '../../../hooks/useAuth';
import {
  AuthMe,
  FilterSettingResponse,
  SignInBody,
  UserResponse,
} from '../../../server/types';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {signInSchema} from '../../../schema/sign-in.schema';
import {usePostSignInAdmin} from '../../../server/react-query';
import {Controller} from 'react-hook-form';
import {RoutePath} from '../../../navigation/config/RouteConfig';
import {StyledDiv} from './styled';
import firebase from 'firebase/app';

const AdminSignInPage = () => {
  const {updateAuthData} = useAuth();
  const history = useHistory();

  const {presentToast} = useToast();

  const [isSigningIn, setIsSigningIn] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const logoClickHandler = () => {
    history.replace('/');
  };

  const {
    control,
    getValues,
    handleSubmit,
    formState: {errors},
  } = useForm<SignInBody>({
    resolver: yupResolver(signInSchema),
    defaultValues: {
      mobileNumber: '',
      password: '',
    },
  });

  const postSignInAdmin = usePostSignInAdmin(getValues());

  const submitForm = () => {
    handleSubmit(handleSignIn)();
  };

  const handleSignIn = async () => {
    setIsSigningIn(true);
    try {
      const response = (await postSignInAdmin.mutateAsync()) as AuthMe;

      if (response && response.customToken) {
        await firebase
          .auth()
          .signInWithCustomToken(response.customToken)
          .then((res: any) => {
            response.accessToken = res.user.Aa;
            updateAuthData(response);
            history.replace(RoutePath.ADMIN_LANDING);
          })
          .catch(error => {
            console.log(error);
          });
      }
    } catch (e: any) {
      setIsSigningIn(false);

      if (e && e.status >= 400 && e.status < 500) {
        presentToast({
          message: 'Invalid mobile number or password',
          variant: 'error',
          position: 'bottom',
        });
      } else {
        presentToast({
          message: 'Failed signing in',
          variant: 'error',
          position: 'bottom',
        });
      }
    }
  };

  const handleMicrosoftSignIn = async () => {
    try {
      const microsoftProvider = new firebase.auth.OAuthProvider(
        'microsoft.com',
      ).setCustomParameters({
        login_hint: '',
        tenant: 'af65ebfb-cc15-44e1-a833-f5cd1728dee9',
        prompt: 'select_account',
      });
      await firebase
        .auth()
        .signInWithPopup(microsoftProvider)
        .then((res: any) => {
          let email = res.user.email;
          try {
            email = (email as string).split('#')[0].replace('_', '@');
          } catch (e) {
            console.log(e);
          }

          var fs: FilterSettingResponse = {
            filterSettingId: '',
            userId: '',
            searchAreaType: 'inYourCity',
            livingArrangement: 'stayIn',
            kazamRoles: [],
          };
          var user: UserResponse = {
            userRole: 'admin',
            email: email,
            userId: 'be187714-8ecb-4da6-9708-fccb8a35ef20',
            firebaseUserId: '',
            firebaseRefreshToken: '',
            userStatus: 'active',
            mobileNumber: '',
            firstName: '',
            lastName: '',
            birthDate: new Date(),
            gender: '',
            photo: '',
            createdOn: new Date(),
            receiveMarketingEmails: false,
            modifiedOn: new Date(),
            isBanned: false,
            referralCode: '',
            filterSetting: fs,
            lastAcceptedTerms: new Date(),
            lastAcceptedPrivacyPolicy: new Date(),
            lastAcceptedChatDisclaimer: new Date(),
            isEligibleForTrial: false,
            verifiedStatus: 'verified'
          };
          const data: AuthMe = {
            accessToken: res.user.Aa,
            user: user,
            refreshToken: '',
          };
          updateAuthData(data);
          history.replace(RoutePath.ADMIN_LANDING);
        })
        .catch(error => {
          console.log(error);
        });
    } catch (e: any) {
      console.log(e);
    }
  };

  return (
    <div className="w-full h-full flex justify-center items-center">
      <div>
        <div className="flex flex-row justify-center">
          <img
            className="logo cursor-pointer"
            style={{height: '48px'}}
            src={logo}
            alt="Logo"
            onClick={logoClickHandler}
          />
        </div>

        <div className="flex flex-col pt-4">
          <Typography
            label="Welcome to Kazam Admin!"
            variant="f3"
            color={colorTheme.dark}
            align="center"
          />
          <Typography
            className="pt-1"
            label="Enter your admin login credential below."
            variant="f1"
            color={colorTheme.darkFaded}
            align="center"
          />
        </div>

        <StyledDiv className="flex flex-col p-8 space-y-4 mt-4">
          <div className="control">
            <Controller
              control={control}
              name="mobileNumber"
              render={({field: {value = '', onBlur, onChange}}) => (
                <Input
                  type="tel"
                  inputSize="large"
                  placeholder="Enter mobile number (09xxxxxxxxx)"
                  onChange={onChange}
                  value={value}
                  error={errors.mobileNumber?.message}
                  onBlur={onBlur}
                />
              )}
            />
          </div>
          <div className="control">
            <Controller
              control={control}
              name="password"
              render={({field: {value = '', onBlur, onChange}}) => (
                <Input
                  inputSize="large"
                  placeholder="Enter password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={onChange}
                  value={value}
                  error={errors.password?.message}
                  onBlur={onBlur}
                  onSubmit={submitForm}
                  iconRight={
                    <Icon
                      name={showPassword ? 'eye' : 'eyeOff'}
                      size={16}
                      color={value ? colorTheme.dark : colorTheme.darkFaded}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  }
                />
              )}
            />
          </div>

          <div className="section">
            <Button
              label="Sign In"
              color="primary"
              onClick={submitForm}
              isLoading={isSigningIn}
            />
          </div>
          <div className="forgot-password-container flex flex-row justify-center space-x-1">
            <Typography label="or" variant="f1" color={colorTheme.darkFaded} />
          </div>
          <div className="section">
            <Button
              label="Sign in with Microsoft"
              color="default"
              variant="outlined"
              onClick={handleMicrosoftSignIn}
            />
          </div>
        </StyledDiv>
      </div>
    </div>
  );
};

export default AdminSignInPage;
