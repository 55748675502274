import Page from '../../../core/components/page/Page';
import {Button, Typography} from '../../../core/components';
import logo from '../../../assets/KAZAM_logo_Transparent.svg';
import sampleFaceId from '../../../assets/face_and_id.png';
import {colorTheme} from '../../../core/configs';
import {useHistory} from 'react-router-dom';
import { usePostVerifyFacialDocument } from '../../server/react-query';
import { isUsingApp } from '../../utils/random.util';
import { InAppBrowser } from '@capgo/inappbrowser';
import { Browser } from '@capacitor/browser';
import { isIOS } from 'react-device-detect';
import { useToast } from '../../../core/components/toast';
import { RoutePath } from '../../navigation/config/RouteConfig';
import { useState } from 'react';

const IdentityVerificationPage = () => {
  const history = useHistory();
  const {presentToast} = useToast();
  const postVerifyFacialDocument = usePostVerifyFacialDocument();
  
  const [showIds, setShowIds] = useState<boolean>(false);

  const onCancel = () => {
    history.go(-1);
  };

  const onProceedToFacialDocument = async () => {
    try {
      const response = await postVerifyFacialDocument.mutateAsync();

      if (response && response.verification_url) {
        if (isUsingApp()) {
          await openInAppBrowser(
            response.verification_url,
            response.reference,
          );
        } else {
          window.location.replace(response.verification_url);
        }
      }
    } catch (error) {
      console.log("🚀 ~ onProceedToFacialDocument ~ error:", error)
      presentToast({
        message: `Unexpected error was occurred.`,
        variant: 'error',
        position: 'bottom',
      });
    }
  }


  const openInAppBrowser = async (
    verification_url: string,
    verification_id: string,
  ) => {
    const redirect_url = `/verification/${verification_id}`;

    if (isIOS) {
      await Browser.open({url: verification_url});
    } else {
      await InAppBrowser.openWebView({
        url: verification_url,
        title: 'Kazam verification',
        toolbarColor: '#ffffff',
        closeModal: true,
      });

      InAppBrowser.addListener('urlChangeEvent', data => {
        if (data.url.includes(redirect_url)) {
          InAppBrowser.close();
          InAppBrowser.removeAllListeners();
          history.replace(redirect_url);
        }
      });

      InAppBrowser.addListener('closeEvent', data => {
        InAppBrowser.removeAllListeners();
      });
    }
  };

  return (
    <>
      <Page showBackButton>
        <div className="w-full max-w-md space-y-8">
          <div className="text-center space-y-2">
            <Typography
              label={'We need to check your identity.'}
              variant="f3"
              weight="normal"
              align="center"
              color={colorTheme.dark}
            />
            <Typography
              label={'Get your account verified by doing the following steps.'}
              variant="f1"
              weight="normal"
              align="center"
              color={colorTheme.darkFaded}
            />
          </div>

          <div className="p-4 rounded-lg">
            <div className="flex justify-center">
              <img src={sampleFaceId} alt="id" style={{height: '128px'}} />
            </div>
          </div>

          <div className="text-center space-y-2">
            <Typography
              label="Get your ID and be camera-ready!"
              variant="f1"
              weight="normal"
              align="center"
              color={colorTheme.darkFaded}
            />
          </div>

          <div className="text-center space-y-2">
            <Typography
              label={
                'Prepare a valid government-issued ID, such as a National ID, Driver\'s License or Passport. We will use the details on your ID and use the selfie generated image for your profile.'
              }
              variant="f1"
              weight="normal"
              align="center"
              color={colorTheme.darkFaded}
            />
          </div>

          <div className="text-center space-y-2">
            <Typography
              label={
                'To ensure a smooth verification process, please check/update your kazam profile and make sure your First Name, Last Name, and Birthdate exactly match the details in your valid ID.'
              }
              variant="f1"
              weight="normal"
              align="center"
              color={colorTheme.darkFaded}
            />
          </div>
          
          <div className="text-center space-y-1">
            <div onClick={() => {
              history.push(RoutePath.MY_PROFILE)
            }}>
              <Typography
                label="Click here to review your personal details."
                variant="f1"
                weight="bold"
                align="center"
                color={colorTheme.accent}
              />
            </div>

            <div onClick={() => setShowIds(!showIds)}>
              <Typography
                label="Click here to see more accepted national IDs."
                variant="f1"
                weight="bold"
                align="center"  
                color={colorTheme.accent}
              />
            </div>  
          </div>

          {showIds &&
            <div className="text-center space-y-2">
              <Typography
                label={'National IDs accepted:<br>Residence Permit<br>' +
                  'Student Permit<br>' +
                  'Alien Employment Permit<br>'  +
                  'Philippine Retirement Authority ID<br>' +
                  'Community Identification Card<br>' +
                  'Resident\'s ID<br>' +
                  'Citizen Card<br>'  +
                  'Veterans Affairs Office ID<br>'  +
                  'DSWD Card<br>'  +
                  'Persons With Disability ID<br>' +
                  'Barangay ID<br>' +
                  'Senior Citizen ID<br>' +
                  //'National Bureau of Investigation ID<br>' +
                  'Tax ID<br>' +
                  'Social Security ID<br>' +
                  'Postal ID<br>' +
                  'PhilHealth ID<br>' +
                  'Unified Multi-Purpose ID Card (UMID)'}
                variant="f1"
                weight="normal"
                align="center"
                color={colorTheme.darkFaded}
              />
            </div>
          }

          <div className="space-y-4">
            <Button
              className="w-full bg-indigo-600 hover:bg-indigo-700 text-white py-3 rounded-lg text-lg font-semibold"
              label="Proceed"
              color="primary"
              onClick={onProceedToFacialDocument}
              isLoading={postVerifyFacialDocument.isLoading}
            />
            <Button
              className="w-full bg-white text-indigo-600 border-indigo-600 py-3 rounded-lg text-lg font-semibold"
              label="Cancel"
              variant="outlined"
              onClick={onCancel}
            />
          </div>
        </div>
      </Page>
    </>
  );
};

export default IdentityVerificationPage;
