import * as React from 'react';

function SvgBxUpArrow(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}>
      <path
        d="M2.99996 19.0001H21C21.1822 18.9995 21.3608 18.9493 21.5166 18.8547C21.6724 18.7602 21.7995 18.6249 21.8842 18.4636C21.9688 18.3022 22.0078 18.1207 21.9971 17.9388C21.9863 17.7569 21.9261 17.5814 21.823 17.4311L12.823 4.43111C12.45 3.89211 11.552 3.89211 11.178 4.43111L2.17796 17.4311C2.07379 17.581 2.0127 17.7567 2.00133 17.9389C1.98996 18.1211 2.02875 18.303 2.11348 18.4647C2.19821 18.6264 2.32564 18.7619 2.48193 18.8562C2.63822 18.9506 2.81738 19.0004 2.99996 19.0001Z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBxUpArrow);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
