import {isMobile} from 'react-device-detect';
import styled, {css} from 'styled-components';
import {colorTheme} from '../../../../core/configs';

export const StyledChatRoomPage = styled.div`
  background: ${colorTheme.darkFaded};

  & .sub-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    cursor: pointer;
    align-items: center;
  }

  & .col-1 {
    position: relative;
  }

  & .col-2 {
    padding: 0 8px;
    max-width: 230px;
  }

  & .col-3 {
    width: 24px;
    height: 24px;
  }

  & .avatar {
    width: 55px;
    height: 55px;
    border-radius: 30px;
    border-width: 1px;
    border-color: white;
    object-fit: cover !important;
  }

  & .online-status {
    width: 12px;
    height: 12px;
    position: absolute;
    float: bottom;
    bottom: 3px;
    right: 3px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
  }

  & .bullet {
    padding: 0 4px;
  }

  & .content {
    max-height: calc(100% - 145px);
    height: 100%;
  }
`;

export const StyledChatContentPage = styled.div`
  ${isMobile
    ? css`
        max-height: calc(100% - 200px);
      `
    : css`
        max-height: calc(100vh - 220px);
      `}
  height: 100%;
  overflow: auto;
  margin-top: 78px;
  margin-bottom: 65px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const StyledChatRoomMenu = styled.div`
  position: relative;

  & .overlay {
    position: fixed;
    float: left;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: 100;
  }

  & .menu-list {
    position: absolute;
    float: right;
    right: 0;
    top: 24px;
    width: 200px;
    z-index: 101;
  }

  & .menu-item:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  & .menu-item:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export const StyledChatRoomMenuItem = styled.div`
  padding: 10px 16px;
  background-color: white;

  :hover {
    background-color: ${colorTheme.lightGray};
  }

  & .menu-label {
    margin-left: 8px;
  }
`;

export const StyledChatTest = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  ${
    isMobile
      ? css`
          height: 100%;
        `
      : css`
          height: calc(100% - 66px);
        `
  }

  & .nav {
    height: 56px;
    background: red;
  }

  & .content {
    flex: 1 1 0%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  & .chats {
    flex: 1 1 0%;
    overflow-y: auto;
    margin-top: 78px;
  }

  & .chat-input textarea {
    height: 100%;
  }
`;

interface StyledChatScrollableProps {
  isNotScrollable: boolean;
}

export const StyledChatScrollable = styled.div<StyledChatScrollableProps>`
  ${p =>
    p.isNotScrollable &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    `}
`;
