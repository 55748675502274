import {useEffect} from "react";
import {Typography} from "../../../core/components";
import {StyledReferralLandingPage} from "./styled";
import {useHistory, useParams} from "react-router-dom";
import {RoutePath} from "../../navigation/config/RouteConfig";
import {useGetReferralInfo} from "../../server/react-query";

interface ReferralParams {
  id: string;
}

const ReferralLandingPage = () => {
  const history = useHistory();
  const {id: referralCode} = useParams<ReferralParams>();
  const {data, refetch} = useGetReferralInfo(referralCode);

  useEffect(() => {
    if (data) {
      const params = `?referralCode=${referralCode}`;
      localStorage.setItem('referralCode', referralCode);
      history.replace(RoutePath.LANDING + params);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    (async () => {
      await refetch();
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralCode]);

  return (
    <StyledReferralLandingPage>
      <Typography label="Loading..." />
    </StyledReferralLandingPage>
  );
};

export default ReferralLandingPage;
