import React, {useEffect, useState} from 'react';
import {StyledChatRoomMenu} from './styled';
import {colorTheme} from '../../../core/configs';
import {Icon} from '../../../core/components';
import ChatRoomMenuItem from './ChatRoomMenuItem';
import {
  useDeleteFavorite, useDeleteHide,
  useGetConversationById,
  useGetFavorites, useGetHides,
  usePostAbuseReport, usePostFavorite, usePostHide,
} from '../../server/react-query';
import {useToast} from '../../../core/components/toast';
import {useAuth} from '../../hooks/useAuth';

interface ChatRoomMenuProps {
  channelId: string;
  myUserId: string;
}

const ChatRoomMenu = (props: ChatRoomMenuProps) => {
  const {channelId, myUserId} = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [userId, setUserId] = useState<string | undefined>(undefined);
  const [isUserActive, setIsUserActive] = useState<boolean>(true);

  const {data: hookDataConversation} = useGetConversationById(channelId);
  const {data: hookDataFavorites} = useGetFavorites();
  const {data: hookHidesData} = useGetHides();
  const {authData} = useAuth();

  const {presentToast} = useToast();

  useEffect(() => {
    if (hookHidesData) {
      setIsHidden(
        !!hookHidesData.find(hide => hide.tagUserId === userId),
      );
    }
  }, [hookHidesData, userId]);

  useEffect(() => {
    if (hookDataConversation && hookDataFavorites) {
      const user = hookDataConversation.members.find(
        m => myUserId && m.userId !== myUserId,
      );
      if (user) {
        setUserId(user.userId);
        setIsFavorite(
          !!hookDataFavorites.find(f => f.tagUserId === user.userId),
        );
        setIsUserActive(user.user.userStatus === "active" && !user.user.isBanned);
      }
    }
  }, [hookDataConversation, hookDataFavorites, myUserId]);

  const iconClickedHandler = () => {
    setIsOpen(prevState => !prevState);
  };

  const overlayClicked = () => {
    setIsOpen(false);
  };

  const postFavorite = usePostFavorite({favoriteUserId: userId || ''});
  const addAsFavoriteClickedHandler = async () => {
    if (userId) {
      try {
        await postFavorite.mutateAsync();
        presentToast({
          message: 'Successfully added to favorites.',
          variant: 'success',
          position: 'bottom',
        });
      } catch (e: any) {
        console.log(e);
      } finally {
        setIsOpen(false);
      }
    }
  };

  const deleteFavorite = useDeleteFavorite(userId || '');
  const removeAsFavoriteClickedHandler = async () => {
    if (userId) {
      try {
        await deleteFavorite.mutateAsync();
        presentToast({
          message: 'Successfully removed from favorites.',
          variant: 'success',
          position: 'bottom',
        });
      } catch (e: any) {
        console.log(e);
      } finally {
        setIsOpen(false);
      }
    }
  };

  const postHide = usePostHide({hideUserId: userId || ''});
  const hideClickedHandler = async () => {
    if (userId) {
      try {
        await postHide.mutateAsync();
        presentToast({
          message: 'Kazam successfully hidden.',
          variant: 'success',
          position: 'bottom',
        });
      } catch (e: any) {
        console.log(e);
      } finally {
        setIsOpen(false);
      }
    }
  };

  const deleteHide = useDeleteHide(userId || '');
  const unhideClickedHandler = async () => {
    if (userId) {
      try {
        await deleteHide.mutateAsync();
        presentToast({
          message: 'Kazam successfully unhidden.',
          variant: 'success',
          position: 'bottom',
        });
      } catch (e: any) {
        console.log(e);
      } finally {
        setIsOpen(false);
      }
    }
  };

  const postAbuseReport = usePostAbuseReport({
    userId: userId || '',
    conversationChannelId: channelId
  });
  const reportAbuseClickedHandler = async () => {
    setIsOpen(false);

    try {
      await postAbuseReport.mutateAsync();

      presentToast({
        message: `Reported successfully.`,
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      if (e.status === 409) {
        presentToast({
          message: `Reported successfully.`,
          variant: 'success',
          position: 'bottom',
        });
      } else {
        console.log(e);
        presentToast({
          message: `Unexpected error was occurred.`,
          variant: 'error',
          position: 'bottom',
        });
      }
    }
  };

  return (
    <StyledChatRoomMenu>
      <Icon
        name="moreHorizontal"
        size={24}
        color={isUserActive ? colorTheme.dark : colorTheme.darkFaded}
        type={isUserActive ? "button" : "default"}
        onClick={isUserActive ? iconClickedHandler : () => {}}
      />
      {isOpen && (
        <div className="overlay w-full h-full" onClick={overlayClicked} />
      )}
      {isOpen && (
        <div className="menu-list shadow-lg">
          {authData &&
            authData.user &&
            authData.user.userRole === 'homeowner' && (
              <ChatRoomMenuItem
                className="menu-item"
                iconName="heart"
                label={(isFavorite ? 'Remove' : 'Add') + ' as favorite'}
                onClick={
                  isFavorite
                    ? removeAsFavoriteClickedHandler
                    : addAsFavoriteClickedHandler
                }
              />
            )}

          {authData &&
            authData.user &&
            authData.user.userRole === 'homeowner' && (
              <ChatRoomMenuItem
                className="menu-item"
                iconName="xCircle"
                label={isHidden ? "Unhide" : "Hide"}
                onClick={isHidden ? unhideClickedHandler : hideClickedHandler}
              />
            )}

          <ChatRoomMenuItem
            className="menu-item"
            iconName="alertTriangle"
            label="Report Abuse"
            onClick={reportAbuseClickedHandler}
          />
        </div>
      )}
    </StyledChatRoomMenu>
  );
};

export default ChatRoomMenu;
