import Page from '../../../core/components/page/Page';
import {Button, Typography} from '../../../core/components';
import logo from '../../../assets/KAZAM_logo_Transparent.svg';
import sampleFaceId from '../../../assets/sample_face_id.png';
import {colorTheme} from '../../../core/configs';
import {useHistory} from 'react-router-dom';
import {usePostVerifyFacial} from '../../server/react-query';
import {isUsingApp} from '../../utils/random.util';
import {InAppBrowser} from '@capgo/inappbrowser';
import {Browser} from '@capacitor/browser';
import {isIOS} from 'react-device-detect';
import { useToast } from '../../../core/components/toast';

const CaptureVerificationPage = () => {
  const history = useHistory();
  const postVerifyFacial = usePostVerifyFacial();
  const {presentToast} = useToast();

  const onCancel = () => {
    history.go(-1);
  };

  const onProceedToFacial = async () => {
    try {
      const response = await postVerifyFacial.mutateAsync();

      if (response && response.verification_url) {
        if (isUsingApp()) {
          await openInAppBrowser(response.verification_url, response.reference);
        } else {
          window.location.replace(response.verification_url);
        }
      }
    } catch (error) {
      console.log('🚀 ~ onProceedToFacialDocument ~ error:', error);
      presentToast({
        message: `Unexpected error was occurred.`,
        variant: 'error',
        position: 'bottom',
      });
    }
  };

  const openInAppBrowser = async (
    verification_url: string,
    verification_id: string,
  ) => {
    const redirect_url = `/verification/${verification_id}`;

    if (isIOS) {
      await Browser.open({url: verification_url});
    } else {
      await InAppBrowser.openWebView({
        url: verification_url,
        title: 'Kazam verification',
        toolbarColor: '#ffffff',
        closeModal: true,
      });

      InAppBrowser.addListener('urlChangeEvent', data => {
        if (data.url.includes(redirect_url)) {
          InAppBrowser.close();
          InAppBrowser.removeAllListeners();
          history.replace(redirect_url);
        }
      });

      InAppBrowser.addListener('closeEvent', data => {
        InAppBrowser.removeAllListeners();
      });
    }
  };

  return (
    <>
      <Page showBackButton>
        <div className="w-full max-w-md space-y-8">
          <div className="text-center space-y-2">
            <Typography
              label={'We will take a selfie portrait for liveness check'}
              variant="f3"
              weight="normal"
              align="center"
              color={colorTheme.dark}
            />
            <Typography
              label={
                'Get a True Selfie badge on your profile by doing the following steps.'
              }
              variant="f1"
              weight="normal"
              align="center"
              color={colorTheme.darkFaded}
            />
          </div>

          <div className="p-4 rounded-lg">
            <div className="flex justify-center">
              <img src={sampleFaceId} alt="id" style={{height: '128px'}} />
            </div>
          </div>

          <div className="text-center space-y-2">
            <Typography
              label={'Get your face ready!'}
              variant="f1"
              weight="normal"
              align="center"
              color={colorTheme.darkFaded}
            />
          </div>

          <div className="text-center space-y-2">
            <Typography
              label={
                'We will be taking a selfie of you. This will help us verify if you are a real person. Also, make sure that you are presentable as we will use this as your profile photo.'
              }
              variant="f1"
              weight="normal"
              align="center"
              color={colorTheme.darkFaded}
            />
          </div>

          <div className="space-y-4">
            <Button
              className="w-full bg-indigo-600 hover:bg-indigo-700 text-white py-3 rounded-lg text-lg font-semibold"
              label="Proceed"
              color="primary"
              onClick={onProceedToFacial}
              isLoading={postVerifyFacial.isLoading}
            />
            <Button
              className="w-full bg-white text-indigo-600 border-indigo-600 py-3 rounded-lg text-lg font-semibold"
              label="Cancel"
              variant="outlined"
              onClick={onCancel}
            />
          </div>
        </div>
      </Page>
    </>
  );
};

export default CaptureVerificationPage;
