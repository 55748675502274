import {useEffect, useState} from 'react';
import {ChatResponse, ChatMemberResponse} from '../../server/types';
import {StyleAdminIcon, StyledGenericChatItem} from './styled';
import {Icon, Typography} from '../../../core/components';
import {colorTheme} from '../../../core/configs';
import {useAuth} from '../../hooks/useAuth';
import KazamIcon from '../../../assets/kazam-icon-transparent.png';

interface AdminChatItemProps {
  data: ChatResponse;
  onClick?: (_: ChatResponse) => void;
  isUnread?: boolean;
}

const AdminChatItem = (props: AdminChatItemProps) => {
  const [lastMessage, setLastMessage] = useState<string>('');

  const {authData} = useAuth();
  const {members, contents} = props.data;
  const {user} = members.find(
    m => m.userId !== authData?.user.userId,
  ) as ChatMemberResponse; // Won't be null

  useEffect(() => {
    if (contents && contents.length > 0) {
      var content = contents[0];

      if (content.media.length > 0) {
        setLastMessage('Sent an attachment.');
      } else {
        if (contents[0].content === 'KAZAM_SYSTEM_VIDEO_CALL') {
          setLastMessage('Video call notification');
        } else if (contents[0].content === 'KAZAM_SYSTEM_VOICE_CALL') {
          setLastMessage('Voice call notification');
        } else {
          setLastMessage(contents[0].content);
        }
      }
    } else {
      // no message yet
      setLastMessage('[No message yet]');
    }
  }, [contents]);

  const itemClickedHandler = () => {
    props.onClick && props.onClick(props.data);
  };

  return (
    <StyledGenericChatItem
      onClick={itemClickedHandler}>

      <div className="flex flex-row w-full items-center space-x-2 admin-profile">
        <div className="profile-picture">
          <div className="avatar avatar-admin flex justify-center items-center">
            <img src={KazamIcon} alt="profile" />
          </div>
        </div>

        <div className="flex justify-between items-center space-x-2 w-full flex1">
          <div className="flex flex-col w-full flex1">

            <div className='flex space-x-1   items-center'>
              <Typography
                label={user.firstName}
                variant="f2"
                weight="semibold"
                color={colorTheme.dark}
              />
              <StyleAdminIcon className="flex items-center justify-center">
                <Icon name="check" size={10} color={colorTheme.white} />
              </StyleAdminIcon>
            </div>
            

            <div className="flex flex-row">
              <div className="overflow">
                <Typography
                  label={lastMessage}
                  variant="f1"
                  weight={props.isUnread ? "semibold" : "normal"}
                  color={
                    props.isUnread ? colorTheme.dark : colorTheme.darkFaded
                  }
                  singleLine
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledGenericChatItem>
  );
};

export default AdminChatItem;
