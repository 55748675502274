import {isMobile} from 'react-device-detect';
import styled from 'styled-components';
import {colorTheme} from '../../configs';

export interface StyleProps {
  error?: string;
  datePickerFontSize?: number
}

export const StyledDatePicker = styled.div<StyleProps>`
  .DatePicker {
    width: 100%;
  }

  .DatePicker input, .date-picker-input {
    -moz-appearance: none;
    -webikt-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 4px;
    height: 40px;
    width: 100%;
    color: #2e394c;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
    border: 1px solid
      ${pr => (pr.error ? colorTheme.danger : colorTheme.lightGray)};
    background: white;
    text-align: left;

    ::placeholder {
      color: ${colorTheme.darkFaded};
    }

    &:focus {
      outline: none;
      border: 1px solid
        ${pr => (pr.error ? colorTheme.danger : colorTheme.accent)};
    }
  }

  .responsive-calendar {
    font-size: ${pr => (pr.datePickerFontSize 
      ? `${pr.datePickerFontSize}px !important` 
      : isMobile ? '8px !important' : '9px !important')};
  }

  .DatePicker_calendarContainer {
    z-index: 300 !important;
  }
`;

export const StyledClear = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 40px;
  right: 0.625rem;
  z-index: 10;
`;

export const StyledInputLabel = styled.div`
  margin-bottom: 0.25rem;

  font-size: 0.8rem;
  font-weight: 600;
  line-height: 16.8px;

  color: ${colorTheme.darkFaded};
`;
