import {useMutation, useQuery, useQueryClient} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';
import { UserTrialSubscriptionResponse } from '../types';

const postTrialSubscription = () => {
  return API.post<UserTrialSubscriptionResponse>(ApiEndpointsEnum.POST_TRIAL_SUBSCRIPTION);
};

const usePostTrialSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation(['post-trial-subscription'], () => postTrialSubscription(), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['post-trial-subscription']);
    },
  });
};

const putActiveTrialSubscription = async () => {
  return API.put<UserTrialSubscriptionResponse>(ApiEndpointsEnum.PUT_ACTIVE_TRIAL_SUBSCRIPTION);
};

const usePutActiveTrialSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['put-active-trial-subscription'],
    () => putActiveTrialSubscription(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['put-active-trial-subscription']);
      },
    },
  );
};

const getActiveTrialSubscription = () => {
  return API.get<UserTrialSubscriptionResponse>(ApiEndpointsEnum.GET_ACTIVE_TRIAL_SUBSCRIPTION);
};

const useGetActiveTrialSubscription = () => {
  const queryClient = useQueryClient();
  
  return useQuery(
    ['get-active-trial-subscription'],
    () => getActiveTrialSubscription(),
    {
      retry: 1
    });
};

export {
  usePostTrialSubscription,
  usePutActiveTrialSubscription,
  useGetActiveTrialSubscription
};
