import styled from 'styled-components';
import {colorTheme} from '../../../core/configs';

export const StyledPageWrapper = styled.div`
  & .section {
    margin-top: 16px;
  }
`;

export const StyledEmploymentComponent = styled.div`
  & .radio-button {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
`;

export const StyledRoleComponent = styled.div`
  .checkbox {
    margin-right: 8px;
  }
`;

export const StyledLocationComponent = styled.div`
  position: relative;

  & .suggestions {
    border: 1px solid ${colorTheme.darkFaded};
    background: white;
    border-radius: 3px;
    float: left;
    width: 100%;
    top: 100%;
    left: 0;
    position: absolute;
    z-index: 100;
    max-height: 150px;
    overflow-y: auto;
  }

  & .suggestions-item {
    padding: 5px 10px;
  }

  & .suggestions-item:hover,
  & .suggestions-item:active {
    background-color: #f3f4f6;
  }
`;

export const StyledInputLabel = styled.div`
  margin-bottom: 0.25rem;

  font-size: 0.8rem;
  font-weight: 600;
  line-height: 16.8px;

  color: ${colorTheme.darkFaded};
`;
