import {Icon} from '../../../../core/components';
import StyleStatusIcon from '../../../../core/components/profile-status/styled';
import {colorTheme} from '../../../../core/configs';
import UserOnlineStatus from '../../../components/online-status/UserOnlineStatus';
import {ChatContentMedia, UserResponse} from '../../../server/types';
import {StyledAvatar} from './styles';
import KazamIcon from '../../../../assets/kazam-icon.png';
interface MessageAvatarProps {
  profile?: UserResponse;
}

const MessageAvatar = (props: MessageAvatarProps) => {
  return (
    <StyledAvatar>
      {props.profile?.userStatus === 'deleted' ? (
        <div className="col-1 flex-shrink mr-2 relative">
          <div className="disabled-avatar flex justify-center items-center">
            <Icon name="user" color={colorTheme.darkFaded} size={10}></Icon>
          </div>
        </div>
      ) : (
        <div className="col-1 flex-shrink mr-2 relative">
          {props.profile?.photo ? (
            <div className="avatar flex justify-center items-center">
              <img
                className={
                  props.profile.userRole === 'admin' ? 'admin-profile' : ''
                }
                src={
                  props.profile.userRole === 'admin'
                    ? KazamIcon
                    : props.profile?.photo
                }
                alt="profile"
              />
            </div>
          ) : (
            <div className="default-avatar flex justify-center items-center">
              <Icon name="user" color={colorTheme.white} size={10}></Icon>
            </div>
          )}

          {props.profile?.userRole !== 'admin' && (
            <div className="online-status flex justify-center items-center">
              {props.profile?.userId ? (
                <UserOnlineStatus
                  disabledUser={props.profile?.userStatus === 'disabled'}
                  bannedUser={props.profile.isBanned}
                  userId={props.profile.userId}
                  width={8}
                  height={8}
                />
              ) : (
                <StyleStatusIcon
                  disabledUser={props.profile?.userStatus === 'disabled'}
                  width={8}
                  height={8}
                  status={'online'}
                />
              )}
            </div>
          )}
        </div>
      )}
    </StyledAvatar>
  );
};

export default MessageAvatar;
