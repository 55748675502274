import {Icon, Typography} from '../../../../../core/components';
import {colorTheme} from '../../../../../core/configs';
import {UserResponse} from '../../../../server/types';
import {useEffect, useState} from 'react';
import {LivingArrangement} from '../../../../server/types/filter-settings.types';
import UserOnlineStatus from '../../../../components/online-status/UserOnlineStatus';
import StyleStatusIcon from '../../../../../core/components/profile-status/styled';
import {StyledDiv} from './styled';
import {toNumberWithCommas} from '../../../../utils/decimal.util';
import {isMobile} from 'react-device-detect';
import {useHistory} from 'react-router-dom';
import {localization} from '../../../../localization/Localization';
import ChatRoomMenu from '../ChatRoomMenu';
import { ConversationStatusType } from '../../../../server/types/conversation.types';
import { RoutePath } from '../../../../navigation/config/RouteConfig';
import VerifiedTag from '../../../../../core/components/verified-tag';

interface ChatProfileViewProps {
  channelId: string;
  channelStatus?: ConversationStatusType;
  profile: UserResponse;
  hideMenu?: boolean
  onEndConversation?: () => void;
  onEnableConversation?: () => void;
  onCallClick?: (callType: string) => void;
}

const ChatProfileView = (props: ChatProfileViewProps) => {
  const history = useHistory();
  const {profile} = props;
  const [isKazam, setIsKazam] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [lookingForLabel, setLookingForLabel] = useState<string>('');
  const [livingArrangementLabel, setLivingArrangementLabel] = useState<string>(
    '',
  );
  const [salary, setSalary] = useState<string>('');

  useEffect(() => {
    if (profile) {
      setIsKazam(profile.userRole === 'kazam');

      var lookingFor = profile.filterSetting.kazamRoles
        .map(kr => kr.role.text)
        .join(', ');
      setLookingForLabel(
        profile.userRole === 'kazam'
          ? lookingFor
          : `Looking for: ${lookingFor}`,
      );
      setSalary(
        `₱ ${toNumberWithCommas(profile?.filterSetting?.salary || 0)} ${
          localization.ho_pr_per_mo
        }`,
      );

      const newLivingArrangementLabel = getLivingArrangementLabel(
        profile.filterSetting.livingArrangement,
      );
      setLivingArrangementLabel(newLivingArrangementLabel);
      setIsDisabled(profile.userStatus === 'disabled');
      setIsDeleted(profile.userStatus === 'deleted');
    }
  }, [profile]);

  const getLivingArrangementLabel = (la: LivingArrangement) => {
    switch (la) {
      case 'stayIn':
        return 'Stay-In';
      case 'stayOut':
        return 'Stay-Out';
      default:
        return 'Stay-In or Stay-Out';
    }
  };

  const backButtonClickHandler = () => {
    console.log('back');
    history.go(-1);
  };

  const profileClickedHandler = () => {
    if (profile.userId) {
      history.push(RoutePath.ADMIN_CHAT_PROFILE.replace(':id', profile.userId));
    }
  };

  const navigateToVideoCallPage = async () => {
    if (props.onCallClick) props.onCallClick('video');
    history.push(RoutePath.VIDEO_CALL_ROOM.replace(':id', props.channelId));
  };

  const navigateToVoiceCallPage = async () => {
    if (props.onCallClick) props.onCallClick('voice');
    history.push(RoutePath.VOICE_CALL_ROOM.replace(':id', props.channelId));
  };

  return (
    <StyledDiv>
      <div className="flex flex-row w-full items-center space-x-2">
        {!isMobile && (
          <div>
            <Icon
              type="button"
              name="arrowLeft"
              size={24}
              color={colorTheme.white}
              onClick={backButtonClickHandler}
            />
          </div>
        )}
        {isDeleted ? (
          <div className="profile-picture">
            <div className="disabled-avatar flex justify-center items-center">
              <Icon name="user" color={colorTheme.white}></Icon>
            </div>
          </div>
        ) : (
          <div className="profile-picture">
            {profile?.photo ? (
              <div className="avatar flex justify-center items-center">
                <img src={profile?.photo} alt="profile" />
              </div>
            ) : (
              <div className="default-avatar flex justify-center items-center">
                <Icon name="user" color={colorTheme.white}></Icon>
              </div>
            )}

            <div className="online-status flex justify-center items-center">
              {profile?.userId ? (
                <UserOnlineStatus
                  disabledUser={isDisabled}
                  bannedUser={profile.isBanned}
                  userId={profile.userId}
                  width={10}
                  height={10}
                />
              ) : (
                <StyleStatusIcon width={10} height={10} status={'online'} />
              )}
            </div>
          </div>
        )}

        <div className="flex justify-between items-center space-x-6 w-full flex1">
          {isDeleted || profile.isBanned ? (
            <div className="flex flex-col w-full flex1">
              <Typography
                label={isDeleted ? "Kazam User" : profile.firstName}
                variant="f2"
                weight="semibold"
                color={colorTheme.white}
              />
              {profile.isBanned &&
                <Typography
                  label="This user has been banned"
                  variant="f1"
                  color={colorTheme.white}
                />
              }
            </div>
          ) : (
            <div className="flex flex-col w-full flex1 cursor-pointer"
              onClick={profileClickedHandler}>
              <div className="flex flex-row items-center">
                <Typography
                  label={profile?.firstName || 'Name'}
                  variant="f2"
                  weight="semibold"
                  color={colorTheme.white}
                  singleLine
                />
                <Typography
                  className="bullet"
                  label="•"
                  variant="f1"
                  weight="semibold"
                  color={colorTheme.white}
                />
                <div className="overflow">
                  <Typography
                    label={profile?.filterSetting?.location?.text || 'Location'}
                    variant="f1"
                    weight="semibold"
                    color={colorTheme.white}
                    singleLine
                  />
                </div>

                {(profile?.verifiedStatus === 'verified' || profile?.faceDocumentVerified) &&
                  <div className="pl-1"><VerifiedTag /></div>
                }
              </div>

              <div className="overflow">
                <Typography
                  label={lookingForLabel}
                  variant="f1"
                  weight="semibold"
                  color={colorTheme.white}
                  singleLine
                />
              </div>

              <div className="flex flex-row items-center">
                {isKazam && (
                  <div className="overflow">
                    <Typography
                      label={salary}
                      variant="f1"
                      weight="normal"
                      color={colorTheme.white}
                      singleLine
                    />
                  </div>
                )}

                {isKazam && (
                  <Typography
                    className="bullet"
                    label="•"
                    variant="f1"
                    weight="normal"
                    color={colorTheme.white}
                  />
                )}
                <div className="overflow">
                  <Typography
                    label={livingArrangementLabel}
                    variant="f1"
                    weight="normal"
                    color={colorTheme.white}
                    singleLine
                  />
                </div>
              </div>
            </div>
          )}

          <>
            <div
              onClick={navigateToVoiceCallPage}
              className='opacity-100 pointer-events-auto'>
              <Icon
                type='button'
                name="voiceCall"
                color={colorTheme.light}
              />
            </div>
            <div
              onClick={navigateToVideoCallPage}
              className='opacity-100 pointer-events-auto'>
              <Icon
                type='button'
                name="videoCall"
                color={colorTheme.light}
              />
            </div>
          </>

          {!props.hideMenu &&
            <ChatRoomMenu
              channelId={props.channelId}
              channelStatus={props.channelStatus}
              onEnableConversation={props.onEnableConversation}
              onEndConversation={props.onEndConversation}
            />
          }

        </div>
      </div>
    </StyledDiv>
  );
};

export default ChatProfileView;
