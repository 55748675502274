import {Icon, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import { StyledTable } from './styled';

interface AdminChatsPagination {
  currentPage: number;
  pageSize: number;
  totalPages: number;
  showDebugView?: boolean;
  onPageChange?: (pageNumber: number) => void;
}

const AdminChatsPagination = (props: AdminChatsPagination) => {
  const {
    currentPage,
    pageSize,
    totalPages,
    onPageChange
  } = props;

  const maxNumberOfPagesShown = 20;
  const pagesArray = Array.from({ length: maxNumberOfPagesShown }, (_, i) => i);

  const getPageStart = () : number => {
    const pageNumber = currentPage;
    const modulo = pageNumber % maxNumberOfPagesShown;
  
    if (modulo === 0) {
      return pageNumber - (maxNumberOfPagesShown - 1);
    } else {
      return pageNumber - (modulo - 1);
    }
  };

  const onPreviousPageBlockClickedHandler = () => {
    const prevPageBlock = getPageStart() - maxNumberOfPagesShown;
    const newPage = prevPageBlock > 1 ? prevPageBlock : 1;
    onPageChange && onPageChange(newPage);
  };

  const onNextPageBlockClickedHandler = () => {
    const nextPageBlock = getPageStart() + maxNumberOfPagesShown;
    const newPage = nextPageBlock < totalPages ? nextPageBlock : totalPages;
    onPageChange && onPageChange(newPage);
  };

  return (
    <StyledTable>
      <div className="pagination flex flex-row items-center">
        {currentPage - maxNumberOfPagesShown > 0 && (
          <div
            className="pagination-control nav-button cursor-pointer"
            onClick={onPreviousPageBlockClickedHandler}>
            <div onClick={() => {}}>
              <Icon name="chevronLeft" type="button" color={colorTheme.dark} />
            </div>
          </div>
        )}

        {currentPage - maxNumberOfPagesShown > 0 && (
          <div className="pagination-control ellipses">
            <Typography
              label={'...'}
              variant="f2"
              weight="semibold"
              color={colorTheme.dark}
            />
          </div>
        )}

        {pagesArray.map((_, i)=> {
          const pageNumber = getPageStart() + i;
          if (pageNumber <= totalPages) {
            const isActive = pageNumber === currentPage;
            return (
              <div
                key={pageNumber}
                className={[
                  'pagination-control page-button',
                  isActive ? 'selected' : 'clickable',
                ].join(' ')}
                onClick={() => onPageChange && onPageChange(pageNumber)}>
                <Typography
                  label={pageNumber.toString()}
                  variant="f2"
                  weight="semibold"
                  color={isActive ? colorTheme.white : colorTheme.dark}
                />
              </div>
            );
          } else {
            return undefined;
          }
        })}

        {getPageStart() + (maxNumberOfPagesShown - 1) < totalPages && (
          <div className="pagination-control ellipses">
            <Typography
              label={'...'}
              variant="f2"
              weight="semibold"
              color={colorTheme.dark}
            />
          </div>
        )}

        {getPageStart() + (maxNumberOfPagesShown - 1) < totalPages && (
          <div
            className="pagination-control nav-button cursor-pointer"
            onClick={onNextPageBlockClickedHandler}>
            <Icon name="chevronRight" type="button" color={colorTheme.dark} />
          </div>
        )}
      </div>
    </StyledTable >
  );
};

export default AdminChatsPagination;
