import styled, {css} from "styled-components";
import {colorTheme} from "../../../core/configs";

interface StyledMessageBadgeProps {
  hasRightPadding?: boolean;
}

export const StyledMessageBadge = styled.div<StyledMessageBadgeProps>`
  position: relative;
  ${props => props.hasRightPadding ? css`
    min-width: 28px;
  ` : ''}
  
  & .badge {
    position: absolute;
    float: right;
    bottom: 0;
    right: 0;
    background-color: ${colorTheme.red};
    min-width: 12px;
    height: 12px;
    font-size: 9px;
    color: white;
    padding: 0 3px;
    border-radius: 6px;
    child-align: middle;
  }
`;
