import format from 'date-fns/format';
import { UserResponse } from '../../../server/types';
import { ConversationContentMeta, JobOfferContentMeta } from '../../../server/types/job-offer.types';
import {StyledOfferCard} from './styles';
import JobOfferDetails from '../chat-job-offer-details/JobOfferDetails';

interface ChatJobOfferAcceptedProps {
  sender?: UserResponse
  recipient?: UserResponse
  meta: JobOfferContentMeta
  offer: ConversationContentMeta
  date: Date
}

const ChatJobOfferAccepted = (props: ChatJobOfferAcceptedProps) => {
  const senderIsHomeowner = props?.sender?.userRole === 'homeowner'
  return (
    <StyledOfferCard className="msg-right">
      <div className="offer-details ">
        <div className="text-center mb-2">
          <label className="txt-heading">OFFER ACCEPTED</label>
        </div>

        <div className="flex gap-1 mb-3">
          <span>{senderIsHomeowner 
            ? `${props?.recipient?.firstName} has accepted your offer of employment.`
            : `You have accepted ${props?.recipient?.firstName}’s offer of employment.`}</span>
        </div>

        {senderIsHomeowner && 
          <div className="flex gap-1 mb-2">
            <span>
              {`Please come back on ${format(new Date(props.meta.StartDate), 'PP')} 
              to let us know whether ${props?.recipient?.firstName} started as agreed.`}
            </span>
          </div>
        }

        <JobOfferDetails offer={props.offer}/>

        <div className="text-right offer-msg-time">
          <span>{`${format(props.date, 'PP')} ${format(props.date, 'p')}`}</span>
        </div>
      </div>
    </StyledOfferCard>
  );
};

export default ChatJobOfferAccepted;
