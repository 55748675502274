import {Icon, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {StyledFinderSettings} from './styled';
import {isMobile} from 'react-device-detect';

interface CustomStickyViewProps {
  color: string;
  header: string;
  subtitle: string;
  onClickNavigate: () => void;
  onHideBanner: () => void;
}

const CustomStickyView = (props: CustomStickyViewProps) => {
  const {color, header, subtitle, onClickNavigate, onHideBanner} = props;

  return (
    <StyledFinderSettings
      color={color}
      className={[
        'finder-settings flex justify-between cursor-pointer',
        isMobile ? 'floating-card-2' : '',
      ].join(' ')}
      onClick={onClickNavigate}>
      <div>
        <Typography
          label={header}
          variant="f2"
          weight="semibold"
          color={colorTheme.white}
        />
        <Typography
          className='underline'
          label={subtitle}
          variant="f1"
          weight="normal"
          color={colorTheme.white}
        />
      </div>
      <div className="flex flex-col justify-center">
        <Icon
          className="cursor-pointer"
          name="x"
          color={colorTheme.white}
          type="button"
          onClick={(event) => {
            event.stopPropagation();
            onHideBanner();
          }}
        />
      </div>
    </StyledFinderSettings>
  );
};

export default CustomStickyView;
