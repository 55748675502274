import {colorTheme} from '../../configs';
import Icon from '../icon';
import Typography from '../typography';
import { StyledDiv, StyledCircleDiv } from './styled';
import {useToast} from '../../../core/components/toast';
import {useDialog} from '../../../core/components/dialog';
import HiredDialogContentView from '../../../app/views/dialog-content/hired-dialog/HiredTagDialog';

export interface HiredTagProps {
  type?: 'iconOnly' | 'HiredText'
//   onClickHiredText?: () => void
  userName: string
}



const HiredTag = (props: HiredTagProps) => {
    const {
        type = 'iconOnly',
        // onClickHiredText
        userName
      } = props;

    const {presentToast} = useToast();
    const {presentDialog, dismissDialog} = useDialog();

    const showError = (message: string) => {
        presentToast({
          message: message,
          variant: 'error',
          position: 'bottom',
        })
    }

    const showHiredDialog = () => {
        presentDialog({
        headerText: '',
        enableBackdropDismiss: true,
        content: (
            <HiredDialogContentView 
                userName={userName}           
            />
        )
        });
    };

    
    
      if (type === 'HiredText') {
        return (
          <StyledDiv className='space-x-1  cursor-pointer'
            onClick={showHiredDialog}>
            <Icon
              name="documentChecked"
              size={16}
              color={colorTheme.white}
            />
    
            <Typography
              label="Hired"
              variant="f2"
              weight='semibold'
              color={colorTheme.white}
            />
          </StyledDiv>
        )
      }
    
      return (
        <StyledCircleDiv>
          <Icon
            name="fileText"
            size={12}
            color={colorTheme.white}
          />
        </StyledCircleDiv>
      );

}

export default HiredTag;
