import styled from 'styled-components';
import { colorTheme } from '../../../../../core/configs';
import { isMobile } from 'react-device-detect';

interface StyledSubscriptionItemProps {
  isSelected?: boolean
}

export const StyledSubscriptions = styled.div`
  width: 100%;
  display: flex;
  max-height: 345px;
  overflow-y: auto;
`;

export const StyledSubscriptionItem = styled.div<StyledSubscriptionItemProps>`
  border-radius: 8px;
  border: 1px solid ${p => p.isSelected ? colorTheme.primary : colorTheme.lightGray};
  padding: 16px;
  cursor: pointer;

  :hover {
    border: 1px solid ${colorTheme.primary};
  }
`;
