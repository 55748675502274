import React, {FormEvent, useEffect, useState} from 'react';
import {StyledChatPage} from './styled';
import {Icon, Input, Typography} from '../../../core/components';
import ChatsComponent from './ChatsComponent';
import FavoritesComponent from './FavoritesComponent';
import KawaysComponent from './KawaysComponent';
import {useAuth} from '../../hooks/useAuth';
import {colorTheme} from '../../../core/configs';
import Page from '../../../core/components/page/Page';
import {useHistory} from 'react-router-dom';
import {RoutePath} from '../../navigation/config/RouteConfig';
import {useGetAdminControls} from '../../server/react-query';
import {localization} from '../../localization/Localization';

interface ChatPageProps {
  index?: number;
}

const ChatPage = (props: ChatPageProps) => {
  const {index = 0} = props;
  const {authData} = useAuth();
  const [isHomeowner, setIsHomeowner] = useState<boolean>(
    authData?.user.userRole === 'homeowner',
  );
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [activeTab, setActiveTab] = useState<number>(
    parseFloat(localStorage.getItem('chat-page-active-index') || '0'),
  );
  const [enableKaway, setEnableKaway] = useState(true);

  const history = useHistory();
  const {data: adminData} = useGetAdminControls();

  useEffect(() => {
    setActiveTab(index);
  }, [index]);

  useEffect(() => {
    setIsHomeowner(authData?.user.userRole === 'homeowner');
  }, [authData]);

  useEffect(() => {
    localStorage.setItem('chat-page-active-index', activeTab.toString());
  }, [activeTab]);

  useEffect(() => {
    if (adminData) {
      var enableKawayControl = adminData.find(d => d.type === 'enableKaway');
      if (enableKawayControl) {
        setEnableKaway(enableKawayControl.enabled);
      }
    }
  }, [adminData]);

  const searchChangedHandler = (event: FormEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  };

  const tabClickedHandler = (tabIndex: number) => {
    // setActiveTab(tabIndex);
    switch (tabIndex) {
      case 0:
        history.replace(RoutePath.CHAT);
        break;
      case 1:
        history.replace(RoutePath.FAVORITES);
        break;
      case 2:
        history.replace(RoutePath.KAWAYS);
        break;

      default:
        history.replace(RoutePath.CHAT);
    }
  };

  const filterClickedHandler = () => {
    history.push(RoutePath.CHAT_FILTER.replace(':id', activeTab.toString()));
  };

  return (
    <Page showBackButton={enableKaway} showMenu={!enableKaway} unPadded>
      <StyledChatPage>
        {authData?.user?.userRole === 'homeowner' && (
          <div className="flex flex-row w-full tabs items-center">
            <div
              className={activeTab === 0 ? 'tab active-tab' : 'tab'}
              onClick={() => tabClickedHandler(0)}>
              <Typography
                label={localization.sm_chat}
                variant="f1"
                color={activeTab === 0 ? colorTheme.dark : colorTheme.darkFaded}
              />
            </div>
            <div
              className={activeTab === 1 ? 'tab active-tab' : 'tab'}
              onClick={() => tabClickedHandler(1)}>
              <Typography
                label={localization.sm_favorites}
                variant="f1"
                color={activeTab === 1 ? colorTheme.dark : colorTheme.darkFaded}
              />
            </div>
            <div
              className={activeTab === 2 ? 'tab active-tab' : 'tab'}
              onClick={() => tabClickedHandler(2)}>
              <Typography
                label={localization.sm_kaways}
                variant="f1"
                color={activeTab === 2 ? colorTheme.dark : colorTheme.darkFaded}
              />
            </div>
          </div>
        )}

        <div className="search-bar">
          <Input
            placeholder={localization.ph_search_name}
            inputSize="large"
            value={searchValue}
            onChange={searchChangedHandler}
            iconRight={
              <Icon
                name="filter"
                size={14}
                color={colorTheme.darkFaded}
                type="button"
                onClick={filterClickedHandler}
              />
            }
          />
        </div>

        <div className="page">
          {isHomeowner && activeTab === 0 && (
            <ChatsComponent searchQuery={searchValue} />
          )}
          {isHomeowner && activeTab === 1 && (
            <FavoritesComponent searchQuery={searchValue} />
          )}
          {isHomeowner && activeTab === 2 && (
            <KawaysComponent searchQuery={searchValue} />
          )}

          {!isHomeowner && <ChatsComponent searchQuery={searchValue} />}
        </div>
      </StyledChatPage>
    </Page>
  );
};

export default ChatPage;
