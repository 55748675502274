import * as React from 'react';
import {SVGProps, Ref, forwardRef, memo} from 'react';
const SvgVoucher = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 25 25"
    ref={ref}
    {...props}>
    <g clipPath="url(#voucher_svg__a)">
      <path
        fill="currentColor"
        d="m16.36 7 1.44 1.5L9.64 17 8.2 15.5 16.36 7ZM3.4 2h19.2C23.932 2 25 3.112 25 4.5v5c-.637 0-1.247.263-1.697.732A2.554 2.554 0 0 0 22.6 12c0 .663.253 1.299.703 1.768.45.469 1.06.732 1.697.732v5c0 1.387-1.068 2.5-2.4 2.5H3.4a2.352 2.352 0 0 1-1.697-.732A2.554 2.554 0 0 1 1 19.5v-5c1.332 0 2.4-1.113 2.4-2.5 0-.663-.253-1.299-.703-1.768A2.352 2.352 0 0 0 1 9.5v-5c0-.663.253-1.299.703-1.768A2.352 2.352 0 0 1 3.4 2Zm0 2.5v3.175a4.897 4.897 0 0 1 1.756 1.828c.421.759.643 1.62.643 2.497 0 .877-.222 1.738-.643 2.497A4.897 4.897 0 0 1 3.4 16.325V19.5h19.2v-3.175a4.897 4.897 0 0 1-1.756-1.828A5.153 5.153 0 0 1 20.201 12c0-.877.222-1.738.643-2.497A4.898 4.898 0 0 1 22.6 7.675V4.5H3.4ZM10 7c.996 0 1.8.838 1.8 1.875s-.804 1.875-1.8 1.875-1.8-.838-1.8-1.875S9.004 7 10 7Zm6 6.25c.996 0 1.8.838 1.8 1.875C17.8 16.163 16.996 17 16 17s-1.8-.837-1.8-1.875c0-1.037.804-1.875 1.8-1.875Z"
      />
    </g>
    <defs>
      <clipPath id="voucher_svg__a">
        <path fill="#fff" d="M.75.5h24v24h-24z" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgVoucher);
const Memo = memo(ForwardRef);
export default Memo;
