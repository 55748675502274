import Page from '../../../core/components/page/Page';
import {Typography} from '../../../core/components';
import {colorTheme} from '../../../core/configs';
import settings from '../../../assets/settings.svg';
import {StyledImg} from './styles';
import SettingsItemViewProps from '../../views/cards/settings-item/SettingsItemView';
import {RoutePath} from '../../navigation/config/RouteConfig';
import {localization} from '../../localization/Localization';
import {useAuth} from '../../hooks/useAuth';
import BannerAd from '../../views/ads/banner-ad/BannerAd';

const SettingsPage = () => {
  const {authData} = useAuth();

  return (
    <Page showBackButton>
      <div className="space-y-4">
        <Typography
          label={localization.hdr_settings}
          variant="f3"
          weight="semibold"
          align="center"
          color={colorTheme.dark}
        />

        {authData && (
          <div>
            <BannerAd />
          </div>
        )}

        <div>
          <StyledImg src={settings} alt="settings"></StyledImg>
        </div>

        <div className="pt-6 space-y-4">
          {/* <SettingsItemViewProps
            label={localization.hdr_subs_details}
            description="Lorem ipsum dolor"
            path={RoutePath.SUBSCRIPTION_SETTINGS}
          /> */}

          <SettingsItemViewProps
            label={localization.lbl_language}
            description={localization.desc_lang_settings}
            path={RoutePath.LANGUAGE_SETTINGS}
          />

          {authData
            && authData?.user?.userRole === 'homeowner' && 
             <SettingsItemViewProps
              label={localization.lbl_subscription_details}
              description={localization.desc_subscription_details}
              path={RoutePath.SUBSCRIPTION_DETAILS}
            />
          }
        </div>
      </div>
    </Page>
  );
};

export default SettingsPage;
