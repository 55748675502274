import React, { useEffect } from 'react';
import { useStopwatch } from 'react-timer-hook';
import { Typography } from '../../../core/components';
import { colorTheme } from '../../../core/configs';
import { StyledBetaSection } from './styled';

const BetaSection = () => {

  return (
    <StyledBetaSection>
      <div className="beta-section">
        <Typography
          label="Calling Feature is in Beta"
          variant='f1'
          weight='semibold'
          color={colorTheme.tint}
        />
      </div>
    </StyledBetaSection>
  );
};

export default BetaSection;

