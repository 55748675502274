import React, {useEffect, useState} from 'react';
import {
  CheckboxContainer,
  CheckboxLabel,
  StyledCheckbox,
  StyledCheckboxProps,
} from './styled';
import {CheckboxSize, CheckboxStatus, CheckboxColor} from './checkbox.types';

export interface CheckboxProps {
  label?: string;
  size?: CheckboxSize;
  status?: CheckboxStatus;
  color?: CheckboxColor;
  disabled?: boolean;
  checked?: boolean;
  indeterminate?: boolean;
  className?: string;

  onChange?: (checked: boolean) => void;

  /**
   * To be fixed
   */
  ref?: any;
}

const Checkbox: React.ForwardRefRenderFunction<unknown, CheckboxProps> = (
  props,
  ref,
) => {
  const {
    label,
    size = 'default',
    color = 'default',
    className = '',
    disabled = false,
    indeterminate = false,
    checked = false,
    onChange,
  } = props;

  const styles: StyledCheckboxProps = {
    size,
    color,
    disabled,
    indeterminate,
  };

  const [checkState, setCheckState] = useState(false);

  useEffect(() => {
    setCheckState(checked);
  }, [checked]);

  useEffect(() => {
    if (onChange !== undefined) {
      onChange(checkState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkState]);

  const onClick = () => {
    if (!indeterminate && !disabled) {
      setCheckState(c => !c);
    }
  };

  return (
    <CheckboxContainer
      data-testid="checkbox-element"
      role="checkbox"
      aria-checked={checked}
      className={className}
      onClick={onClick}
      {...styles}>
      <StyledCheckbox {...styles}>
        {indeterminate && (
          <svg
            data-testid="indeterminate-element"
            className="indeterminate"
            viewBox="0 0 40 40">
            <path className="checkmark__check" fill="none" d="M7.1 20.2l26 0" />
          </svg>
        )}
        {checkState && (
          <svg
            data-testid="checkmark-element"
            className="checkmark"
            viewBox="0 0 40 40">
            <path
              className="checkmark__check"
              fill="none"
              d="M7.8 20.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        )}
      </StyledCheckbox>
      <CheckboxLabel data-testid="check-label-element" {...styles}>
        {label}
      </CheckboxLabel>
    </CheckboxContainer>
  );
};

export default Checkbox;
