import styled, {css} from 'styled-components';
import {isMobile} from 'react-device-detect';
import {colorTheme} from '../../configs';

interface PageWrapperProps {
  hideNavbar?: boolean;
  showLoading?: boolean;
  unPadded?: boolean;
  forMatches?: boolean;
  forChats?: boolean;
  forSignUp?: boolean;
  forEditProfile?: boolean;
  defaultLanding?: boolean;
}

export const StyledMainWrapper = styled.div<PageWrapperProps>`
  width: 100vw;

  ${p =>
    !isMobile
      ? css`
          height:  ${p.defaultLanding ? 'fit-content' : 'calc(100% - 66px);'}
        `
      : css`
          height: ${p.hideNavbar ? '100vh' : 'calc(100% - 56px)'};
        `}
`;

export const StyledMainPageWrapper = styled.div<PageWrapperProps>`
  ${p =>
    !isMobile && p.forSignUp
      ? css`
          height: calc(100vh - 66px);
        `
      : css`
          height: 100%;
        `}

  ${p =>
    !isMobile
      ? css`
          margin-top: 66px;
        `
      : css`
          margin-top: ${p.hideNavbar ? '0px' : '56px'};
        `}
`;

export const StyledPageWrapper = styled.div<PageWrapperProps>`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: auto;

  ${p => !p.forChats && !p.defaultLanding && 'padding: 24px;'}

  ${p => p.forMatches && !isMobile && 'align-items: center;'}

  ${p => p.unPadded && isMobile && 'padding: 0px !important;'}

  ${p => p.forEditProfile && !isMobile && 'padding-top: 60px !important;'}

  ${p =>
    p.showLoading
      ? css`
          display: flex;
          justify-content: center;
          align-items: center;
          height: ${p.hideNavbar
            ? '100vh'
            : isMobile
            ? 'calc(100vh - 56px)'
            : 'calc(100vh - 66px)'};
        `
      : 'height: 100%;'}

  ${p => p.defaultLanding && !isMobile && 'max-width: 1920px'}
`;

interface PageContentProps {
  hideNavbar?: boolean;
  showLoading?: boolean;
  unPadded?: boolean;
  showFilterSettings?: boolean;
  forMatches?: boolean;
  forChats?: boolean;
  forChatRoom?: boolean;
  forLanding?: boolean;
}

export const StyledPageContent = styled.div<PageContentProps>`
  ${p => !p.forChats && 'max-width: 450px;'}
  ${p => p.forLanding && 'max-width: 100%;'}

  width: 100%;

  ${p =>
    p.forChatRoom
      ? css`
          height: 100%;
        `
      : css`
          height: fit-content;
        `}

  ${p =>
    p.showFilterSettings && !isMobile && !p.forMatches && 'margin-top: 100px;'}

  ${p =>
    !isMobile &&
    !p.showLoading &&
    !p.forChats &&
    !p.forLanding &&
    css`
      border: 1px solid ${colorTheme.lightGray};
      border-radius: 4px;
      padding: ${p.unPadded ? '24px !important' : '48px !important'};
    `};
`;

export const StyledSideBarWrapper = styled.div`
  max-width: 300px;
  width: 100%;
  background: ${colorTheme.light};
`;

export const StyledNotificationWrapper = styled.div`
  min-width: 300px;
  max-width: 300px;
  background: ${colorTheme.light};
  padding: 24px;
`;

export const StyledNotificationContent = styled.div`
  background: ${colorTheme.white};
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid ${colorTheme.lightGray};
  max-height: 300px;
  min-height: 250px;
  overflow-y: auto;
`;

export const StyledNotificationEmpty = styled.div`
  img {
    height: 150px !important;
    min-width: auto !important;
  }
`;

export const StyledBack = styled.div`
  position: absolute;
  left: 24px;
`;

export const StyledSave = styled.div`
  position: absolute;
  right: 24px;
`;
