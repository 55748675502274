import Page from '../../../core/components/page/Page';
import {Button, Typography} from '../../../core/components';
import {colorTheme} from '../../../core/configs';

import {useAuth} from '../../hooks/useAuth';
import {useHistory, useLocation} from 'react-router-dom';
import {useDialog} from '../../../core/components/dialog';
import UserVerificationDiscard from './UserVerificationDiscard';
import {RoutePath} from '../../navigation/config/RouteConfig';
import {usePatchMismatchUserProfile} from '../../server/react-query';
import {
  ProfileMismatchResult,
  UserRequestStatusResponse,
  UserVerificationMismatchProfileBody,
} from '../../server/types/user.verification.types';
import GenericConfirmationDialog from '../../views/dialog-content/generic-dialog/GenericConfirmationDialog';
import {useEffect, useState} from 'react';
import { usePatchRevokeVerifications } from '../../server/react-query/useAdminUsers';

const UserVerificationMismatchPage = () => {
  const {presentDialog, dismissDialog} = useDialog();
  const {updateAuthData, authData} = useAuth();

  const location = useLocation();
  const history = useHistory();
  const metaData = location.state.metaData;
  const mismatchedResult = location.state
    .mismatchResult as ProfileMismatchResult;
  const patchMismatchUserProfile = usePatchMismatchUserProfile();
  const patchRevokeVerifications = usePatchRevokeVerifications();
  const [parsedData, setParsedData] = useState<UserRequestStatusResponse>();

  useEffect(() => {
    const tempParsedData: UserRequestStatusResponse = JSON.parse(metaData);

    if (tempParsedData) {
      setParsedData(tempParsedData);
    }
  }, [metaData]);

  const onAccept = async () => {
    try {
      var body: UserVerificationMismatchProfileBody = {
        firstName: mismatchedResult.firstNameMismatch
          ? mismatchedResult.firstNameMismatch
          : authData!.user.firstName,
        lastName: mismatchedResult.lastNameMismatch
          ? mismatchedResult.lastNameMismatch
          : authData!.user.lastName,
        birthdate: mismatchedResult.birthDateMismatch
          ? new Date(mismatchedResult.birthDateMismatch)
          : authData!.user.birthDate,
        gender: mismatchedResult.genderMismatch
          ? mismatchedResult.genderMismatch
          : authData!.user.gender,
        verificationData: parsedData ?? JSON.parse(metaData),
      };

      const response = await patchMismatchUserProfile.mutateAsync(body);

      if (response) {
        let data = authData;

        if (data) {
          data.user = response;
          updateAuthData(data);
        }
        history.push(RoutePath.MY_PROFILE);
      }
    } catch (error) {
      console.log('🚀 ~ onAccept ~ error:', error);
    }
  };

  const onDecline = () => {
    presentDialog({
      headerText: '',
      enableBackdropDismiss: false,
      hideClose: true,
      content: (
        <>
          <UserVerificationDiscard
            isLoading={patchRevokeVerifications.isLoading}
            onCancelClick={() => {
              dismissDialog();
            }}
            onContinueClick={async () => {
              await patchRevokeVerifications.mutateAsync({
                userId: authData!.user?.userId,
                verifiedStatus: 'pending',
              });
              dismissDialog();
              presentDialog({
                hideClose: true,
                headerText: '',
                content: (
                  <GenericConfirmationDialog
                    headerText="Queued for manual verification"
                    desc={`You will be contacted by administrators for manual verification and update.`}
                    rightButtonText="Ok"
                    onRightButtonAction={() => {
                      dismissDialog();
                      history.push(RoutePath.MY_PROFILE);
                    }}
                  />
                ),
                enableBackdropDismiss: false,
              });
            }}
          />
        </>
      ),
    });
  };

  return (
    <>
      <Page showBackButton={false}>
        <div className="space-y-8">
          {authData && (
            <div className="w-full max-w-md space-y-8">
              <div className="text-center space-y-2">
                <Typography
                  label={'ID Verification Results'}
                  variant="f3"
                  weight="normal"
                  align="center"
                  color={colorTheme.dark}
                />
                <Typography
                  label={
                    'Personal details entered and scanned ID details not matching.'
                  }
                  variant="f1"
                  weight="normal"
                  align="center"
                  color={colorTheme.danger}
                />
              </div>

              <div className="space-y-0">
                <Typography
                  label={'PERSONAL DETAILS:'}
                  variant="f1"
                  weight="normal"
                  align="left"
                  color={colorTheme.darkFaded}
                />
                <Typography
                  label={`First Name: ${authData.user.firstName}`}
                  variant="f1"
                  weight="normal"
                  align="left"
                  color={colorTheme.darkFaded}
                />
                <Typography
                  label={`Last Name: ${authData.user.lastName}`}
                  variant="f1"
                  weight="normal"
                  align="left"
                  color={colorTheme.darkFaded}
                />
                <Typography
                  label={`Birthdate: ${new Date(
                    authData.user.birthDate,
                  ).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })}`}
                  variant="f1"
                  weight="normal"
                  align="left"
                  color={colorTheme.darkFaded}
                />
                <Typography
                  label={`Gender: ${
                    authData.user.gender.charAt(0).toUpperCase() +
                    authData.user.gender.slice(1)
                  }`}
                  variant="f1"
                  weight="normal"
                  align="left"
                  color={colorTheme.darkFaded}
                />
              </div>
            </div>
          )}

          {mismatchedResult && parsedData && (
            <div className="w-full max-w-md space-y-8">
              <div className="space-y-0">
                <Typography
                  label={'SCANNED DETAILS:'}
                  variant="f1"
                  weight="normal"
                  align="left"
                  color={colorTheme.darkFaded}
                />
                <Typography
                  label={`First Name: ${
                    mismatchedResult.firstNameMismatch ??
                    parsedData?.verification_data?.document?.name?.first_name ??
                    'N/A'
                  }`}
                  variant="f1"
                  weight="normal"
                  align="left"
                  color={
                    mismatchedResult.firstNameMismatch
                      ? colorTheme.danger
                      : colorTheme.dark
                  }
                />
                <Typography
                  label={`Last Name: ${
                    mismatchedResult.lastNameMismatch ??
                    parsedData?.verification_data?.document?.name?.last_name ??
                    'N/A'
                  }`}
                  variant="f1"
                  weight="normal"
                  align="left"
                  color={
                    mismatchedResult.lastNameMismatch
                      ? colorTheme.danger
                      : colorTheme.dark
                  }
                />
                <Typography
                  label={`Date of Birth: ${
                    mismatchedResult.birthDateMismatch
                      ? new Date(
                          mismatchedResult.birthDateMismatch,
                        ).toLocaleDateString('en-US', {
                          month: '2-digit',
                          day: '2-digit',
                          year: 'numeric',
                        })
                      : parsedData?.verification_data?.document?.dob
                      ? new Date(
                          parsedData.verification_data.document.dob,
                        ).toLocaleDateString('en-US', {
                          month: '2-digit',
                          day: '2-digit',
                          year: 'numeric',
                        })
                      : 'N/A'
                  }`}
                  variant="f1"
                  weight="normal"
                  align="left"
                  color={
                    mismatchedResult.birthDateMismatch
                      ? colorTheme.danger
                      : colorTheme.dark
                  }
                />
                <Typography
                  label={`Gender: ${
                    mismatchedResult.genderMismatch
                      ? mismatchedResult.genderMismatch
                          .charAt(0)
                          .toUpperCase() +
                        mismatchedResult.genderMismatch.slice(1)
                      : parsedData?.verification_data?.document?.gender === 'M'
                      ? 'Male'
                      : parsedData?.verification_data?.document?.gender === 'F'
                      ? 'Female'
                      : 'N/A'
                  }`}
                  variant="f1"
                  weight="normal"
                  align="left"
                  color={
                    mismatchedResult.genderMismatch
                      ? colorTheme.danger
                      : colorTheme.dark
                  }
                />
              </div>
            </div>
          )}

          <div className="w-full max-w-md space-y-8">
            <div className="text-left space-y-2">
              <Typography
                label={
                  'We found details that are not matching. Do you accept the details from the scanned ID and update your personal details accordingly?'
                }
                variant="f1"
                weight="normal"
                align="left"
                color={colorTheme.danger}
              />
            </div>

            <div
              style={{
                borderTop: '2px dashed grey',
                width: '100%',
              }}
            />

            <div className="text-left space-y-2">
              <Typography
                label={
                  'By accepting, we will use the details from the scanned ID and tag yourself as ID Verified. Else, you will be contacted by administrators for manual verification and update.'
                }
                variant="f1"
                weight="normal"
                align="left"
                color={colorTheme.accent}
              />
            </div>
          </div>

          <div className="space-y-4">
            <Button
              label="Accept"
              color="primary"
              onClick={onAccept}
              isLoading={patchMismatchUserProfile.isLoading}
            />
            <Button
              label="No"
              variant="outlined"
              onClick={onDecline}
              isLoading={patchRevokeVerifications.isLoading}
            />
          </div>
        </div>
      </Page>
    </>
  );
};

export default UserVerificationMismatchPage;
