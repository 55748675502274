import styled from 'styled-components';

export const StyledDialog = styled.div`
  padding: 0 16px 22px 16px;

  & #dropdown-date {
    display: flex;
  }

  & #dropdown-day {
    flex: 2;
    margin-left: 12px;
  }

  & #dropdown-month {
    flex: 4;
  }

  & #dropdown-year {
    flex: 3;
    margin-left: 12px;
  }

  & select {
    border-radius: 4px;
    height: 40px;
    width: 100%;
    color: #2e394c;
    padding: 0 8px;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: left;
    border: 1px solid #e2e2e2;
    background: white;
  }

  & select:focus {
    outline-width: 0;
    outline: none;
  }

  & .spacer {
    width: 16px;
  }

  & .actions {
    margin-top: 16px;
  }

  & .message {
    margin-top: 8px;
  }
`;
