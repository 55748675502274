import React from 'react';

const PreviousArrowIcon = ({ width = "24", height = "25" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 16.5L2 12.5M2 12.5L6 8.5M2 12.5L22 12.5"
      stroke="#998CFF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PreviousArrowIcon;
