import {Typography} from '../../../../../core/components';
import AdminPage from '../../../../../core/components/admin-page/AdminPage';
import {colorTheme} from '../../../../../core/configs';
import {StyledAdminUsersPage} from '../styled';
import AdminUsersTabs from '../AdminUsersTabs';
import {useState} from 'react';
import AdminUsersSearch from '../AdminUsersSearch';
import AdminAllUsersTable from './AdminAllUsersTable';
import {useAuth} from '../../../../hooks/useAuth';
import {usePostJob} from '../../../../server/react-query';
import {useToast} from '../../../../../core/components/toast';
import {useDialog} from '../../../../../core/components/dialog';
import ExtractReportDialog from '../../../../views/dialog-content/ExtractReportDialog';

const AdminUsersAllPage = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const postJob = usePostJob();
  const {presentToast} = useToast();
  const {presentDialog, dismissDialog} = useDialog();
  const {authData} = useAuth();

  const onSearchChangedHandler = (query: string) => {
    setSearchQuery(query);
  };

  const onExtractClickedHandler = async () => {
    if (authData?.user.email) {
      try {
        await postJob.mutateAsync({
          type: 'usersReport',
          params: JSON.stringify({
            email: authData.user.email,
          }),
        });

        presentDialog({
          headerText: '',
          content: (
            <ExtractReportDialog
              requestName="Users Data"
              onOkayClick={() => dismissDialog()}
            />
          ),
          enableBackdropDismiss: true,
          hideClose: true,
        });
      } catch (error) {
        console.log({error});
        presentToast({
          message: 'Request failed',
          variant: 'error',
          position: 'bottom',
        });
      }
    }
  };

  return (
    <AdminPage>
      <StyledAdminUsersPage>
        <Typography
          label="Manage Users"
          variant="f3"
          weight="semibold"
          color={colorTheme.dark}
        />

        <AdminUsersTabs selected={0} />

        <AdminUsersSearch
          onChange={onSearchChangedHandler}
          showExtractButton
          extractClickHandler={onExtractClickedHandler}
          placeholder="Search user first name, last name, or UUID"
        />

        <AdminAllUsersTable searchQuery={searchQuery} />
      </StyledAdminUsersPage>
    </AdminPage>
  );
};

export default AdminUsersAllPage;
