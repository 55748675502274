import styled from 'styled-components';
import {colorTheme} from '../../../../core/configs';

export const StyledChatWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const StyledChatContent = styled.div`
  padding: 8px;
  width: fit-content;
  border-radius: 8px 8px 0 8px;
  background: ${colorTheme.primary};

  & h6 {
    word-break: break-word;
  }
`;

export const StyledImage = styled.img`
  height: 120px;
`;

export const StyledChatMessage = styled.div`
  font-size: 0.8rem;
  line-height: 1.2rem;
  letter-spacing: 0;
  font-weight: 400;
  color: ${colorTheme.white};

  a, b {
    color: ${colorTheme.primary};
  }
`;
