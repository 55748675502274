import {Fragment, useEffect} from 'react';
import { useDialog } from '../../../core/components/dialog';
import { VersionInfo } from '../../../VersionInfo';
import {useGetLatestVersionInfo} from '../../server/react-query';
import { getAppUrl } from '../../utils/random.util';
import { STORAGE } from '../../utils/storage.utils';
import GenericConfirmationDialog from '../../views/dialog-content/generic-dialog/GenericConfirmationDialog';

const VersionInfoChecker = () => {
  const {data: latestVersionInfo} = useGetLatestVersionInfo();
  const {presentDialog, dismissDialog} = useDialog();

  const goToApp = (appUrl: string) => {
    window.open(appUrl);
  };

  const dismiss = () => {
    localStorage.setItem('skippedVersion', latestVersionInfo?.targetVersion ?? '');
    dismissDialog();
  };

  useEffect(() => {
    if (latestVersionInfo) {
      var appUrl = getAppUrl();
      var versionInfo = VersionInfo;

      if (appUrl && (parseInt(latestVersionInfo.targetVersion) > parseInt(versionInfo.build))) {
        if (parseInt(versionInfo.build) >= parseInt(latestVersionInfo.minSupportedVersion)) {
          var skippedVersion = localStorage.getItem('skippedVersion');
          if (skippedVersion != null && parseInt(skippedVersion) !== parseInt(latestVersionInfo.targetVersion)) {  
            presentDialog({
                headerText: '',
                content: (
                <GenericConfirmationDialog
                    headerText={latestVersionInfo.title}
                    desc={latestVersionInfo.description}
                    rightButtonText="Update"
                    leftButtonText="Skip"
                    onLeftButtonAction={dismiss}
                    onRightButtonAction={() => goToApp(appUrl)}
                />
                ),
                enableBackdropDismiss: false,
                hideClose: true
            }); 
          }
        } else {
          presentDialog({
              headerText: '',
              content: (
              <GenericConfirmationDialog
                  headerText={latestVersionInfo.title}
                  desc={latestVersionInfo.description}
                  rightButtonText="Update"
                  leftButtonText=''
                  onLeftButtonAction={dismiss}
                  onRightButtonAction={() => goToApp(appUrl)}
              />
              ),
              enableBackdropDismiss: false,
              hideClose: true
          });
        }
      }
    }
  }, [latestVersionInfo]);

  return <Fragment />;
};

export default VersionInfoChecker;
