import {isMobile} from 'react-device-detect';
import styled, {css} from 'styled-components';
import {colorTheme} from '../../../core/configs';

export const StyledLocationError = styled.div`
  background: ${colorTheme.lightGray};
  height:${
    isMobile ? '100vh' : '80vh'
  };

  & .error-text {
    padding: 10px;
    background: ${colorTheme.red};
  }

`;

export const CurrentLocationHeader  = styled.div`
    
  display: flex;
  color: black;
  width:100%;
  border-bottom: 1px solid ${colorTheme.lightGray};
  background-color: ${colorTheme.white};

  & .back-arrow{
    margin-top: 5px;
  }

  & .tab{
    color: black;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom:10px;
  }

  & .heading{
    font-weight:600;
    margin-left: 20px;
    margin-top: 5px;
  }
`;