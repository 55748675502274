import styled from 'styled-components';

interface StyledIconProps {
  size: number;
  clickable: boolean;
  disabled: boolean;
}

export const StyledIcon = styled.img<StyledIconProps>`
  height: ${pr => pr.size}px;
  width: 'auto';

  object-fit: fill;

  cursor: ${pr =>
    pr.disabled ? 'not-allowed' : pr.clickable ? 'pointer' : 'default'};
`;
