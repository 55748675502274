import * as React from 'react';

function SvgDocumentCheckedLight(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) {
  return (
    
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={svgRef} {...props}>
        <path d="M19.875 7.375V11.375H17.875V8.375H12.875V3.375H3.875V19.375H9.875V21.375H2.868C2.60481 21.375 2.35239 21.2705 2.1662 21.0845C1.98 20.8985 1.87527 20.6462 1.875 20.383V2.367C1.875 1.83 2.324 1.375 2.877 1.375H13.872L19.875 7.375ZM12.661 14.702C12.7 13.975 13.261 13.383 13.985 13.306L14.855 13.214C14.9587 13.2031 15.0564 13.1597 15.134 13.09L15.785 12.505C16.0473 12.2695 16.3849 12.1351 16.7373 12.1259C17.0897 12.1168 17.4338 12.2334 17.708 12.455L18.39 13.005C18.47 13.07 18.57 13.108 18.674 13.114L19.548 13.161C20.275 13.2 20.867 13.761 20.944 14.485L21.036 15.355C21.0468 15.4588 21.0902 15.5564 21.16 15.634L21.745 16.285C22.232 16.827 22.253 17.642 21.795 18.208L21.245 18.89C21.1798 18.9709 21.1416 19.0702 21.136 19.174L21.089 20.048C21.0703 20.4001 20.9267 20.734 20.6841 20.9898C20.4415 21.2457 20.1156 21.4067 19.765 21.444L18.895 21.536C18.7913 21.5469 18.6936 21.5903 18.616 21.66L17.965 22.245C17.7027 22.4805 17.3651 22.6149 17.0127 22.6241C16.6603 22.6332 16.3162 22.5166 16.042 22.295L15.36 21.745C15.2791 21.6798 15.1798 21.6416 15.076 21.636L14.202 21.589C13.8499 21.5703 13.516 21.4267 13.2602 21.1841C13.0043 20.9415 12.8433 20.6156 12.806 20.265L12.714 19.395C12.7031 19.2913 12.6597 19.1936 12.59 19.116L12.005 18.465C11.7695 18.2027 11.6351 17.8651 11.6259 17.5127C11.6168 17.1603 11.7334 16.8162 11.955 16.542L12.505 15.86C12.5702 15.7791 12.6084 15.6798 12.614 15.576L12.661 14.702ZM19.905 16.405L18.845 15.345L16.375 17.815L14.905 16.345L13.845 17.405L16.375 19.935L19.905 16.405Z" fill="#8798AD"/>
    </svg>

  );
}

const ForwardRef = React.forwardRef(SvgDocumentCheckedLight);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
