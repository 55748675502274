import _ from "lodash"
import { LivingArrangement } from "../server/types/filter-settings.types"
import { ConversationContentMeta, JobHistoryDetails, JobHistoryResponse, JobOfferContentMeta, JobOfferResponse } from "../server/types/job-offer.types"
import { Nullable } from "../types/types"
import { getHiredLockDuration } from "./admin.util"
import addDays from "date-fns/addDays"
import { UserResponse } from "../server/types"

export const getLivingArrangement = (v: string): Nullable<LivingArrangement> => {
  switch (v) {
    case "Stay-In":
      return 'stayIn'
    case "Stay-Out":
      return 'stayOut'
    default:
      return null
  }
}

export const getLivingArrangementValue = (v: Nullable<LivingArrangement>) => {
  switch (v) {
    case "stayIn":
      return 'Stay-In'
    case "stayOut":
      return 'Stay-Out'
    default:
      return 'Stay-In or Stay-Out'
  }
}

export const getContentJoboffer = (meta: string): ConversationContentMeta => {
  const data = JSON.parse(meta) as ConversationContentMeta
  return data
}

export const getContentMeta = (meta: string): JobOfferContentMeta => {
  const data = JSON.parse(meta) as JobOfferContentMeta
  return data
}

export const getJobDetailsContentMeta = (meta: string): JobHistoryResponse => {
  var parsedData: any = JSON.parse(meta)
  if (parsedData) {
    const lowerCaseMeta: any = {};
    Object.keys(parsedData).forEach(key => {
      lowerCaseMeta[key.charAt(0).toLocaleLowerCase() + key.slice(1)] = parsedData[key];
    });
    parsedData = lowerCaseMeta;
  }

  const data: JobHistoryResponse = parsedData;
  return data
}

export const getJobHistoryDetails = (
  items?: JobHistoryResponse[],
  currentUser?: UserResponse
): JobHistoryDetails => {
  let isHired = false
  let isNoShow = false
  let availableForChat = true
  let latestJobStartDate = new Date()
  let lockedProfileEndDate= new Date()
  let sortedJH: JobHistoryResponse[] = []
  
  const lockDuration = getHiredLockDuration();

  if (items && items.length > 0) {
    // const filteredJh = items?.filter(j => j.status != 'pending')  
    const sorted = _.sortBy(items, (item) => {
      return new Date(item.createdOn);
    }).reverse();
    const latestJH = sorted[0]

    const hasPendingOrActive = items
      .some(j => j.status === 'active'
      || j.status === 'pending')
    isHired = hasPendingOrActive
    sortedJH = sorted.filter(j => j.status != 'pending')

    availableForChat = true
    if (isHired) {
      latestJobStartDate = new Date(latestJH.startDate)
      lockedProfileEndDate = addDays(new Date(latestJH.startDate), lockDuration)
      
      const isHiredLock = new Date() < lockedProfileEndDate
      const hiredByMe = (latestJH.employerId === currentUser?.userId)
        && currentUser?.userRole === 'homeowner'
      availableForChat = !isHiredLock || hiredByMe
    }

    if (sortedJH.length > 0) {
      const latestShownJH = sortedJH[0]
      isNoShow = latestShownJH.status === 'noShow'
    }
  } else {
    isHired = false
    isNoShow = false
    availableForChat = true
    sortedJH = []
  }
  
  return {
    isHired,
    isNoShow,
    latestJobStartDate,
    lockedProfileEndDate,
    sortedJH,
    availableForChat
  }
}