import {useHistory, useLocation} from 'react-router-dom';
import {StyledSideDrawer} from './styled';
import {RoutePath} from '../../../app/navigation/config/RouteConfig';
import {useAuth} from '../../../app/hooks/useAuth';
import {isMobile} from 'react-device-detect';
import logo from '../../../assets/KAZAM_logo_Transparent.svg';
import {
  homeownerPrimaryNavItems,
  kazamPrimaryNavItems,
  navItems,
  secondaryNavItems,
  unAuthenticatedNavItems,
  unAuthenticatedPrimaryNavItems,
  adminPrimaryNavItems,
  adminSecondaryNavItems,
  NavItemList,
} from './side-bar.types';
import Divider from '../divider/Divider';
import SideDrawerMenus from './SideDrawerMenus';
import SideDrawerMenu from './SideDrawerMenu';
import SideDrawerProfile from './SideDrawerProfile';
import {localization} from '../../../app/localization/Localization';
import { colorTheme } from '../../configs';

interface SideBarProps {
  sideDrawerOpen?: boolean;
}

const SideBar = (props: SideBarProps) => {
  const history = useHistory();
  const location = useLocation();
  const {authData} = useAuth();

  const goToProfile = () => {
    history.push(RoutePath.MY_PROFILE);
  };

  const getIconColor = (nav: NavItemList) => {
    switch (nav) {
      case 'getVerified':
        return colorTheme.accent
      case 'takePersonalityTest':
        return colorTheme.warning
      case 'captureTrueSelfie':
        return colorTheme.magenta
      default:
        return undefined
    }
  }

  return (
    <div>
      {authData ? (
        <StyledSideDrawer
          open={props.sideDrawerOpen}
          isMobile={isMobile}
          isAdmin={authData.user.userRole === 'admin'}
          className="flex flex-col justify-between">
          <div>
            {(isMobile || authData.user.userRole === 'admin') && (
              <div className="logo-wrapper side-menu-logo">
                <img src={logo} alt="Logo" />
              </div>
            )}

            {(isMobile || authData.user.userRole === 'admin') && <Divider />}
            <div
              style={{
                maxHeight: 'calc(100vh - 242px)',
                overflowY: 'auto',
              }}>
              {authData.user.userRole === 'homeowner' && (
                <SideDrawerMenus>
                  {homeownerPrimaryNavItems?.map((nav, i) => {
                    return (
                      <SideDrawerMenu
                        key={i}
                        text={localization.getString(navItems[nav].name)}
                        path={navItems[nav].path}
                        iconName={navItems[nav].icon}
                        alt={navItems[nav].name}
                        disabled={
                          navItems[nav].disabled ||
                          authData.user.userStatus === 'disabled'
                        }
                        active={location.pathname === navItems[nav].path}
                        defaultColor={getIconColor(nav)}
                      />
                    );
                  })}
                </SideDrawerMenus>
              )}
              {authData.user.userRole === 'kazam' && (
                <SideDrawerMenus>
                  {kazamPrimaryNavItems?.map((nav, i) => {
                    return (
                      <SideDrawerMenu
                        key={i}
                        text={localization.getString(navItems[nav].name)}
                        path={navItems[nav].path}
                        iconName={navItems[nav].icon}
                        alt={navItems[nav].name}
                        disabled={
                          navItems[nav].disabled ||
                          authData.user.userStatus === 'disabled'
                        }
                        active={location.pathname === navItems[nav].path}
                        defaultColor={getIconColor(nav)}
                      />
                    );
                  })}
                </SideDrawerMenus>
              )}
              {authData.user.userRole === 'admin' && (
                <SideDrawerMenus>
                  {adminPrimaryNavItems?.map((nav, i) => {
                    return (
                      <SideDrawerMenu
                        key={i}
                        text={navItems[nav].name}
                        path={navItems[nav].path}
                        iconName={navItems[nav].icon}
                        alt={navItems[nav].name}
                        disabled={navItems[nav].disabled}
                        active={
                          location.pathname.includes('admin/users/')
                            ? navItems[nav].path.includes('admin/users/')
                            : location.pathname === navItems[nav].path
                        }
                      />
                    );
                  })}
                </SideDrawerMenus>
              )}
              <Divider />

              {authData.user.userRole === 'admin' ? (
                <SideDrawerMenus>
                  {adminSecondaryNavItems?.map((nav, i) => {
                    return (
                      <SideDrawerMenu
                        key={i}
                        text={localization.getString(navItems[nav].name)}
                        path={navItems[nav].path}
                        iconName={navItems[nav].icon}
                        alt={navItems[nav].name}
                        disabled={navItems[nav].disabled}
                        active={location.pathname === navItems[nav].path}
                      />
                    );
                  })}
                </SideDrawerMenus>
              ) : (
                <SideDrawerMenus>
                  {secondaryNavItems?.map((nav, i) => {
                    return (
                      <SideDrawerMenu
                        key={i}
                        text={localization.getString(navItems[nav].name)}
                        path={navItems[nav].path}
                        iconName={navItems[nav].icon}
                        alt={navItems[nav].name}
                        disabled={navItems[nav].disabled}
                        active={location.pathname === navItems[nav].path}
                        url={navItems[nav].url}
                      />
                    );
                  })}
                </SideDrawerMenus>
              )}
            </div>
          </div>
          {authData.user.userRole !== 'admin' && (
            <div>
              <Divider />
              <SideDrawerProfile
                firstName={authData.user.firstName}
                lastName={authData.user.lastName}
                image={authData.user.photo}
                onProfileClick={goToProfile}></SideDrawerProfile>
            </div>
          )}
        </StyledSideDrawer>
      ) : (
        <StyledSideDrawer
          open={props.sideDrawerOpen || !isMobile}
          isMobile={isMobile}
          isDefaultLanding={location.pathname === RoutePath.LANDING}>
          {isMobile && (
            <div className="logo-wrapper side-menu-logo">
              <img src={logo} alt="Logo" />
            </div>
          )}

          {isMobile && <Divider />}

          <SideDrawerMenus>
            {unAuthenticatedPrimaryNavItems?.map((nav, i) => {
              return (
                <SideDrawerMenu
                  key={i}
                  text={localization.getString(navItems[nav].name)}
                  path={navItems[nav].path}
                  iconName={navItems[nav].icon}
                  alt={navItems[nav].name}
                  disabled={navItems[nav].disabled}
                  active={location.pathname === navItems[nav].path}
                />
              );
            })}
          </SideDrawerMenus>

          <Divider />

          <SideDrawerMenus>
            {unAuthenticatedNavItems?.map((nav, i) => {
              return (
                <SideDrawerMenu
                  key={i}
                  text={localization.getString(navItems[nav].name)}
                  path={navItems[nav].path}
                  iconName={navItems[nav].icon}
                  alt={navItems[nav].name}
                  disabled={navItems[nav].disabled}
                  active={location.pathname === navItems[nav].path}
                  analyticsEvent={navItems[nav].analyticsClickEventName}
                  url={navItems[nav].url}
                />
              );
            })}
          </SideDrawerMenus>
        </StyledSideDrawer>
      )}
    </div>
  );
};

export default SideBar;
