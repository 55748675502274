import * as React from 'react';
import {SVGProps, Ref, forwardRef, memo} from 'react';
const SvgEndVoice = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 25"
    ref={ref}
    {...props}>
    <path
      fill="currentColor"
      d="m3.4 16.4-2.3-2.25a.96.96 0 0 1-.3-.7c0-.267.1-.5.3-.7 1.467-1.583 3.158-2.77 5.075-3.563C8.092 8.396 10.033 8 12 8s3.904.396 5.813 1.188c1.908.791 3.604 1.979 5.087 3.562.2.2.3.433.3.7 0 .267-.1.5-.3.7l-2.3 2.25a.975.975 0 0 1-1.3.1l-2.9-2.2a.993.993 0 0 1-.3-.35.992.992 0 0 1-.1-.45v-2.85a15.13 15.13 0 0 0-1.95-.475A11.868 11.868 0 0 0 12 10c-.7 0-1.383.058-2.05.175-.667.117-1.317.275-1.95.475v2.85a.992.992 0 0 1-.1.45.993.993 0 0 1-.3.35l-2.9 2.2a.975.975 0 0 1-1.3-.1ZM6 11.45c-.483.25-.95.538-1.4.863-.45.324-.917.687-1.4 1.087l1 1L6 13v-1.55Zm12 .05V13l1.8 1.4 1-.95a15.34 15.34 0 0 0-1.4-1.125A9.933 9.933 0 0 0 18 11.5Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgEndVoice);
const Memo = memo(ForwardRef);
export default Memo;
