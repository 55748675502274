import {Browser} from '@capacitor/browser';
import axios from 'axios';
import {format} from 'date-fns';
import {Icon, Typography} from '../../../../core/components';
import {useDialog} from '../../../../core/components/dialog';
import StyleStatusIcon from '../../../../core/components/profile-status/styled';
import {useToast} from '../../../../core/components/toast';
import {colorTheme} from '../../../../core/configs';
import UserOnlineStatus from '../../../components/online-status/UserOnlineStatus';
import {ChatContentMedia, UserResponse} from '../../../server/types';
import {getCallDuration, isUsingBrowser, linkify} from '../../../utils/random.util';
import ViewImageAttachment from '../../dialog-content/view-image-attachment/ViewImageAttachment';
import {StyledChatWrapper, StyledChatContent, StyledImage, StyledChatMessage} from './styles';
import KazamIcon from '../../../../assets/kazam-icon.png';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {RoutePath} from '../../../navigation/config/RouteConfig';
interface RecipientChatMessageProps {
  profile?: UserResponse;
  message?: string;
  meta?: string
  className?: string;
  date: Date;
  image?: string;
  media?: ChatContentMedia;
  conversationId?: string;
}

const RecipientChatMessage = (props: RecipientChatMessageProps) => {
  const {presentDialog, dismissDialog} = useDialog();
  const {presentToast} = useToast();
  const history = useHistory();
  const [isSystemMsg, setIsSystemMsg] = useState<boolean>(false);
  const [isVideoCall, setIsVideoCall] = useState<boolean>(false);

  const isAdmin = props?.profile?.userRole === 'admin'

  const download = () => {
    if (props.media) {
      if (props.media.contentType.includes('image')) {
        prewiewImage(props.media);
      } else {
        downloadFile(props.media);
      }
    }
  };

  const prewiewImage = (media: ChatContentMedia) => {
    presentDialog({
      headerText: '',
      content: (
        <ViewImageAttachment
          media={media}
          onClose={dismissDialog}
          onImageClicked={() => downloadFile(media)}
        />
      ),
      hideHeader: true,
    });
  };

  const downloadFile = async (media: ChatContentMedia) => {
    if (isUsingBrowser()) {
      axios({
        url: media.url,
        method: 'GET',
        responseType: 'blob', // important
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', props.media?.title!);
          document.body.appendChild(link);
          link.click();
          showSuccessToast();
        })
        .catch(e => {
          showErrorToast();
        });
    } else {
      // const response = await fetch(media.url);
      // // convert to a Blob
      // const blob = await response.blob();
      // // convert to base64 data, which the Filesystem plugin requires
      // const base64Data = await convertBlobToBase64(blob) as string;

      // await Filesystem.writeFile({
      //   path: props.media?.title!,
      //   data: base64Data,
      //   directory: Directory.Documents
      // }).then(res => {
      //   showSuccessToast();
      // }).catch(async e => {
      //   await Browser.open({ url: media.url });
      // });
      await Browser.open({url: media.url});
    }
  };

  const showSuccessToast = () => {
    presentToast({
      message: 'Downloaded successfully.',
      variant: 'success',
      position: 'bottom',
    });
  };

  const showErrorToast = () => {
    presentToast({
      message: 'Download failed.',
      variant: 'error',
      position: 'bottom',
    });
  };

  const convertBlobToBase64 = (blob: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });

  const systemMsgClickHandler = () => {
    if (!isSystemMsg) return;

    if (props.conversationId) {
      if (isVideoCall) {
        history.push(
          RoutePath.VIDEO_CALL_ROOM.replace(':id', props.conversationId),
        );
      } else {
        history.push(
          RoutePath.VOICE_CALL_ROOM.replace(':id', props.conversationId),
        );
      }
    }
  };

  useEffect(() => {
    if (
      props.message === 'KAZAM_SYSTEM_VIDEO_CALL' ||
      props.message === 'KAZAM_SYSTEM_VOICE_CALL'
    ) {
      setIsSystemMsg(true);
      setIsVideoCall(
        props.message === 'KAZAM_SYSTEM_VIDEO_CALL' ? true : false,
      );
    }
  }, [props.message]);

  const getChatMessage = () => {
    var message = props?.message?.replaceAll('\n' , '<br />') ?? ""
    if (isAdmin) message = linkify(message)
    return message
  }

  return (
    <StyledChatWrapper>
      {props.profile?.userStatus === 'deleted' ? (
        <div className="col-1 flex-shrink mr-2">
          <div className="disabled-avatar flex justify-center items-center">
            <Icon name="user" color={colorTheme.darkFaded} size={10}></Icon>
          </div>
        </div>
      ) : (
        <div className="col-1 flex-shrink mr-2">
          {props.profile?.photo ? (
            <div className="avatar flex justify-center items-center">
              <img
                className={
                  props.profile.userRole === 'admin' ? 'admin-profile' : ''
                }
                src={
                  props.profile.userRole === 'admin'
                    ? KazamIcon
                    : props.profile?.photo
                }
                alt="profile"
              />
            </div>
          ) : (
            <div className="default-avatar flex justify-center items-center">
              <Icon name="user" color={colorTheme.white} size={10}></Icon>
            </div>
          )}

          {props.profile?.userRole !== 'admin' && (
            <div className="online-status flex justify-center items-center">
              {props.profile?.userId ? (
                <UserOnlineStatus
                  disabledUser={props.profile?.userStatus === 'disabled'}
                  bannedUser={props.profile.isBanned}
                  userId={props.profile.userId}
                  width={8}
                  height={8}
                />
              ) : (
                <StyleStatusIcon
                  disabledUser={props.profile?.userStatus === 'disabled'}
                  width={8}
                  height={8}
                  status={'online'}
                />
              )}
            </div>
          )}
        </div>
      )}

      <StyledChatContent
        style={{
          backgroundColor: isSystemMsg
            ? colorTheme.generatedMessage
            : colorTheme.light,
        }}>
        <div className="flex">
          <div>
            {!isSystemMsg ? (
              <StyledChatMessage dangerouslySetInnerHTML={{
                __html: getChatMessage(),
              }} />
            ) : (
              <Typography
                className="spaced-left"
                label={isVideoCall ? 'Video call' : 'Voice call'}
                variant="f1"
                align="left"
                color={colorTheme.dark}
              />
            )}

            {props.media &&
              (props.media.contentType.includes('image') ? (
                <StyledImage
                  className="cursor-pointer"
                  src={props.media.url}
                  onClick={download}></StyledImage>
              ) : (
                <div
                  className="flex space-x-2 items-center cursor-pointer"
                  onClick={download}>
                  <Icon name="paperclip" size={16} color={colorTheme.dark} />
                  <Typography
                    className="spaced-left"
                    label={props.media.title}
                    variant="f1"
                    weight="semibold"
                    align="right"
                    color={colorTheme.dark}
                  />
                </div>
              ))}
            <div className="mt-1 flex space-x-1">
              <Typography
                className="spaced-left"
                label={`${format(props.date, 'PP')} ${format(props.date, 'p')}`}
                variant="f1"
                weight="light"
                align="left"
                color={colorTheme.darkFaded}
              />

              {isSystemMsg && props?.meta && 
                <>
                  <Typography
                    className="bullet"
                    label="•"
                    variant="f1"
                    weight="semibold"
                    color={colorTheme.lightGray}
                  />

                  <Typography
                    className="spaced-left"
                    label={getCallDuration(props.meta)}
                    variant="f1"
                    weight="light"
                    align="right"
                    color={colorTheme.darkFaded}
                  />
                </>
              }
            </div>
          </div>
          {isSystemMsg && (
            <div
              className="h-8 w-8 flex self-center items-center justify-center rounded-full ml-4"
              style={{
                backgroundColor: colorTheme.light,
              }}>
              <Icon
                name={isVideoCall ? 'videoCall' : 'voiceCall'}
                size={24}
              />
            </div>
          )}
        </div>
      </StyledChatContent>
    </StyledChatWrapper>
  );
};

export default RecipientChatMessage;
