import format from 'date-fns/format';
import { UserResponse } from '../../../server/types';
import { JobHistoryResponse, JobOfferMeta } from '../../../server/types/job-offer.types';
import {StyledOfferCard} from './styles';
import JobEndedDetails from './JobEndedDetails';
import { Button, Typography } from '../../../../core/components';
import { useEffect, useState } from 'react';
import Rating from '../../../components/rating/Rating';
import { colorTheme } from '../../../../core/configs';

interface ChatJobEndedProps {
  user?: UserResponse
  meta: JobHistoryResponse
  date: Date
  onUpdate: (jobHistory: JobHistoryResponse, offerMeta: JobOfferMeta, role: string) => void
}

const ChatJobEnded = (props: ChatJobEndedProps) => {

  const [offerMeta, setOfferMeta] = useState<JobOfferMeta>()
  const [roles, setRoles] = useState<string>();
  const isHomeowner = props?.user?.userRole === 'homeowner'

  useEffect(() => {
    if (props
      && props.meta.meta) {
      var meta = JSON.parse(props.meta.meta) as JobOfferMeta
      setOfferMeta(meta)

      var roles = meta.Roles?.map(r => r.Role?.Text)
      setRoles(roles.join(", "))
    }
  }, [props.meta.meta])

  return (
    <StyledOfferCard className="msg-right">
      <div className="offer-details ">
        <div className="text-center mb-2">
          <label className="txt-heading">JOB DETAILS</label>
        </div>

        <JobEndedDetails offer={props.meta}/>

        {(!!props?.meta?.employeeRating
          && !!props?.meta?.employerRating) && 
          <div className='flex flex-col items-center justify-center space-y-2 pt-1'>
            <div className='flex w-full'>
              <Typography
                label={isHomeowner ? 'You Rated Kasambahay' : 'You Rated Homeowner'}
                variant="f1"
                weight="semibold"
                align="left"
                color={colorTheme.dark}
              />
            </div>
            
            <Rating
              size={24} 
              rating={isHomeowner
                ? props?.meta?.employerRating
                : props?.meta?.employeeRating}
              readonly
            />

            <div className='flex w-full'>
              <Typography
                label={isHomeowner ? 'Kasambahay Rated You' : 'Homeowner Rated You'}
                variant="f1"
                weight="semibold"
                align="left"
                color={colorTheme.dark}
              />
            </div>

            <Rating
              size={24} 
              rating={isHomeowner
                ? props?.meta?.employeeRating
                : props?.meta?.employerRating}
              readonly 
            />
          </div> 
        }

        {(isHomeowner && !props?.meta?.employerRating
          || !isHomeowner && !props?.meta?.employeeRating &&
          <Button
            label={isHomeowner ? 'Rate Kasambahay' : 'Rate Homeowner'}
            className="flex-1 mt-2"
            color='danger'
            onClick={() => props.onUpdate(props.meta, offerMeta!, roles!)} 
          />
        )}

        <div className="text-right offer-msg-time">
          <span>{`${format(props.date, 'PP')} ${format(props.date, 'p')}`}</span>
        </div>
      </div>
    </StyledOfferCard>
  );
};

export default ChatJobEnded;
