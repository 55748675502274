import * as React from 'react';

function SvgWaveGradientFilled(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}>
      <path
        d="M17.25 20.37a7.5 7.5 0 01-10.246-2.746l-3.937-6.82a1.875 1.875 0 013.247-1.875l-1.5-2.598a1.875 1.875 0 113.248-1.875l.75 1.299a1.875 1.875 0 013.247-1.875l2.813 4.871a1.875 1.875 0 013.247-1.875l1.875 3.248a7.5 7.5 0 01-2.745 10.245z"
        fill="url(#wave-gradient-filled_svg__paint0_linear)"
      />
      <path
        d="M20.644 9.749L18.77 6.5a2.628 2.628 0 00-4.434-.18l-1.626-2.816a2.624 2.624 0 00-4.51-.06 2.625 2.625 0 00-4.034 3.26l.314.545a2.624 2.624 0 00-2.062 3.929l3.938 6.82a8.25 8.25 0 1014.29-8.25zm-.625 5.872a6.75 6.75 0 01-12.365 1.628l-3.938-6.82a1.125 1.125 0 011.949-1.125l2.062 3.572a.758.758 0 00.052.078l.016.02.046.05a.739.739 0 00.085.076l.003.003a.742.742 0 00.07.046l.022.012.06.029.021.009c.027.01.055.02.083.027l.006.002a.738.738 0 00.172.023h.022c.029 0 .058-.002.087-.006h.007a.734.734 0 00.079-.016l.025-.007a.731.731 0 00.063-.021l.024-.01a.747.747 0 00.356-1.065L6.964 8.554l-1.5-2.598A1.125 1.125 0 017.412 4.83l.749 1.296.001.003 2.438 4.222a.75.75 0 001.299-.75L9.46 5.38a1.125 1.125 0 111.95-1.125l2.811 4.87v.001l.594 1.028a4.502 4.502 0 00-1.029 5.719.75.75 0 101.3-.75 3 3 0 011.098-4.098.74.74 0 00.11-.08l.01-.007a.793.793 0 00.05-.05h.002a.704.704 0 00.046-.054l.002-.002a.777.777 0 00.038-.055l.003-.006a.696.696 0 00.03-.053l.006-.011a.727.727 0 00.023-.052l.007-.016a.73.73 0 00.02-.061l.002-.009.002-.008a.796.796 0 00.013-.063l.003-.02a.736.736 0 00.006-.053v-.017a.779.779 0 00.001-.057v-.01a.722.722 0 00-.006-.063l-.001-.007a.752.752 0 00-.012-.065l-.002-.008a.72.72 0 00-.018-.063l-.007-.018A.74.74 0 0016.46 10l-.937-1.624a1.125 1.125 0 111.948-1.125l1.875 3.248a6.707 6.707 0 01.674 5.122zm-11.8 7.307a.75.75 0 01-1.044.189 11.153 11.153 0 01-3.324-3.618.75.75 0 111.299-.75 9.665 9.665 0 002.88 3.135.75.75 0 01.189 1.044zM15.75 2.905a.75.75 0 01.75-.749 5.645 5.645 0 014.87 2.813.75.75 0 01-1.298.75 4.14 4.14 0 00-3.573-2.063.75.75 0 01-.749-.75z"
        fill="url(#wave-gradient-filled_svg__paint1_linear)"
      />
      <defs>
        <linearGradient
          id="wave-gradient-filled_svg__paint0_linear"
          x1={11.907}
          y1={2.944}
          x2={11.907}
          y2={21.374}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#630099" stopOpacity={0.74} />
          <stop offset={1} stopColor="#0F2CC5" stopOpacity={0.73} />
        </linearGradient>
        <linearGradient
          id="wave-gradient-filled_svg__paint1_linear"
          x1={11.912}
          y1={2.156}
          x2={11.912}
          y2={23.25}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#630099" stopOpacity={0.74} />
          <stop offset={1} stopColor="#0F2CC5" stopOpacity={0.73} />
        </linearGradient>
      </defs>
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgWaveGradientFilled);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
