import {useEffect, useState} from 'react';
import {StyledDialog} from './styled';
import {
  Button,
} from '../../../../core/components';
import {DropdownComponent, DropdownDate} from '@iskaiiii/react-dropdown-date';
import {localization} from '../../../localization/Localization';

interface DatePickerDialogProps {
  value?: Date;
  onSelectDate: (date: Date | undefined) => void;
}

const DatePickerDialog = (props: DatePickerDialogProps) => {
  const [maxDate, setMaxDate] = useState<string | undefined>(undefined);
  const [dateSelected, setDateSelected] = useState<Date>();
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [loadPicker, setLoadPicker] = useState<boolean>(false);

  useEffect(() => {
    var d = new Date(),
      year = d.getFullYear(),
      date18yrsAgo = new Date(year - 18, d.getMonth(), d.getDate()),
      latestDate18yrsAgo = new Date(year - 18, 11, 31);

    var minDate = formatDate(date18yrsAgo);
    var maxDate = formatDate(latestDate18yrsAgo);

    setDateSelected(date18yrsAgo);
    setSelectedDate(props.value ? formatDate(props.value) : minDate);
    setMaxDate(maxDate);
    setLoadPicker(true);
  }, []);

  const formatDate = (date: any) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const onDateChange = (date: Date) => {
    setDateSelected(date);
    setSelectedDate(formatDate(date));
  };

  return (
    <StyledDialog>
      {loadPicker && (
        <DropdownDate
          startDate={'1920-01-01'}
          endDate={maxDate}
          selectedDate={selectedDate}
          onDateChange={onDateChange}
          options={{
            yearReverse: true,
          }}
          order={[
            DropdownComponent.month,
            DropdownComponent.day,
            DropdownComponent.year,
          ]}
        />
      )}

      <div className="actions">
        <Button
          label={localization.btn_continue}
          color="primary"
          className="flex-1"
          onClick={() => props.onSelectDate(dateSelected)}
        />
      </div>
    </StyledDialog>
  );
};

export default DatePickerDialog;
