import {isMobile} from 'react-device-detect';
import styled, { css } from 'styled-components';
import { colorTheme } from '../../../../core/configs';
import { isUsingMobileBrowser } from '../../../utils/random.util';
export const StyledStoriesWrapper = styled.div`
  ${isUsingMobileBrowser()
    ? css`
        height: calc(100dvh - 55px - env(safe-area-inset-top));
        padding: 36px 0 36px 0;
      `
    : isMobile 
    ? css`
        height: calc(100vh - 55px - env(safe-area-inset-top));
        padding: 36px 0 36px 0;
      `
    : css`
        height: min-content;
        padding: 52px;
        gap: 48px;
      `}

    background: linear-gradient(180deg, rgba(99, 0, 153, 0.74) 0%, rgba(15, 44, 197, 0.73) 100%);
    position: relative;

    .carousel.carousel-slider {
      overflow: unset;
      display: flex;
      flex: 1;
      position: unset;
      display: flex;
    }

    .carousel .control-dots {
      display: flex;
      justify-content: center;
      bottom: 36px;
    }

    .carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
      background-color: #998CFF;
    }

    .carousel .control-dots .dot {
      opacity: 1;
      width: 12px;
      height: 12px;
      background-color: #e2e2e2;
      opacity: 1;
      border: 1px solid #e2e2e2;
      border-radius: 50%;
      content: '';
      box-shadow: none;
    }
`;

export const StyledComment = styled.div`
  ${isMobile
    ? css`
        font-size: 0.75rem;
      `
    : css`
        font-size: 0.875rem;
      `}

  letter-spacing: 0rem;
  font-family: Poppins;
  font-style: italic;
  font-weight: 400;
  text-align: center;
  padding-top: 8px;
  color: ${colorTheme.dark};
`;
