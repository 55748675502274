import {StyledToggle, StyledToggleIndicator} from './styled';

export interface ToggleProps {
  value: boolean;
  onToggle?: (value: boolean) => void;
}

const Toggle = (props: ToggleProps) => {
  const {value, onToggle} = props;

  return (
    <StyledToggle
      value={value}
      className={value ? 'justify-end' : 'justify-start'}
      onClick={() => onToggle && onToggle(!value)}>
      <StyledToggleIndicator />
    </StyledToggle>
  );
};

export default Toggle;
