import React, {FormEvent, useEffect, useState} from "react";
import AdminPage from "../../../../core/components/admin-page/AdminPage";
import {StyledChatProfileViewPage} from "./styled";
import {Button, Icon, Input, Typography} from "../../../../core/components";
import {colorTheme} from "../../../../core/configs";
import {UserResponse} from "../../../server/types";
import {useGetReferralsByUser, useGetUserById, usePostJob} from "../../../server/react-query";
import {useHistory, useParams} from "react-router-dom";
import AdminTable from "../users/AdminTable";
import {Column} from "react-table";
import {format} from "date-fns";
import ExtractReportDialog from "../../../views/dialog-content/ExtractReportDialog";
import {useToast} from "../../../../core/components/toast";
import {useDialog} from "../../../../core/components/dialog";
import {useAuth} from "../../../hooks/useAuth";
import HomeownerProfileView from "../../../views/cards/homeowner-profile/HomeownerProfileView";
import KazamProfileView from "../../../views/cards/kazam-profile/KazamProfileView";

interface ChatProfileViewParams {
  id: string;
}

const ChatProfileView = () => {
  const history = useHistory();
  const {id: userId} = useParams<ChatProfileViewParams>();
  const {data, isFetching} = useGetUserById(userId);

  const [userData, setUserData] = useState<UserResponse | undefined>(undefined);

  useEffect(() => {
    if (data) {
      setUserData({...data});
    }
  }, [data]);

  const onBackButtonClickedHandler = () => {
    history.go(-1);
  };

  return (
    <AdminPage showLoading={isFetching}>
      <StyledChatProfileViewPage>
        <div
          className="w-full flex flex-row items-center cursor-pointer"
          onClick={onBackButtonClickedHandler}>
          <div className="back-btn-container">
            <Icon
              name="chevronLeft"
              size={24}
              color={colorTheme.dark}
              type="button" />
          </div>
          <Typography
            label={userData?.firstName ?? ""}
            variant="f3"
            weight="semibold"
            color={colorTheme.dark}
          />
        </div>

        <div className="flex justify-center">
          <div className="profile-view m-16">
            {userData?.userRole === 'homeowner' 
              ? <HomeownerProfileView profile={userData} adminView/>
              : <KazamProfileView profile={userData} adminView />
            }
          </div>
        </div>
        

      </StyledChatProfileViewPage>
    </AdminPage>
  );
};

export default ChatProfileView;
