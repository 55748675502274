import {colorTheme} from '../../configs';
import Icon from '../icon';
import Typography from '../typography';
import { StyledDiv, StyledCircleDiv } from './styled';

export interface PersonalityMatchTagProps {
  type?: 'iconOnly' | 'full'
  score?: string
  onClick?: () => void
}

const PersonalityMatchTag = (props: PersonalityMatchTagProps) => {
  const {
    type = 'iconOnly',
    onClick
  } = props;

  if (type === 'full') {
    return (
      <StyledDiv className='space-x-1 cursor-pointer'
        onClick={onClick}>
        <Icon
          name="brain"
          size={16}
          color={colorTheme.white}
        />

        <Typography
          label={`${props?.score}% Personality Match`}
          variant="f2"
          weight='semibold'
          color={colorTheme.white}
        />
      </StyledDiv>
    )
  }

  return (
    <StyledCircleDiv>
      <Icon
        name="brain"
        size={12}
        color={colorTheme.white}
      />
    </StyledCircleDiv>
  );
};

export default PersonalityMatchTag;
