import {
  AdminControl,
  DurationContent,
  PaywallContent,
  QuantityContent,
} from '../server/types/admin-control.types';

export const getOnlineStatusThreshold = (): DurationContent | null => {
  let threshold: DurationContent | null = null;
  var adminData = localStorage.getItem('adminData');
  if (adminData) {
    var parsedAdminData = JSON.parse(adminData) as AdminControl[];

    if (parsedAdminData) {
      var onlineThresholdControl = parsedAdminData.find(
        d => d.type === 'onlineStatusThreshold',
      );
      if (onlineThresholdControl) {
        threshold = JSON.parse(
          onlineThresholdControl.content.toLowerCase(),
        ) as DurationContent;
      }
    }
  }
  return threshold;
};

export const getOfflineStatusThreshold = (): DurationContent | null => {
  let threshold: DurationContent | null = null;
  var adminData = localStorage.getItem('adminData');
  if (adminData) {
    var parsedAdminData = JSON.parse(adminData) as AdminControl[];

    if (parsedAdminData) {
      var offlineThresholdControl = parsedAdminData.find(
        d => d.type === 'offlineStatusThreshold',
      );
      if (offlineThresholdControl) {
        threshold = JSON.parse(
          offlineThresholdControl.content.toLowerCase(),
        ) as DurationContent;
      }
    }
  }
  return threshold;
};

export const getAdsDwellTime = (): DurationContent | null => {
  let dwellTime: DurationContent | null = null;
  var adminData = localStorage.getItem('adminData');
  if (adminData) {
    var parsedAdminData = JSON.parse(adminData) as AdminControl[];

    if (parsedAdminData) {
      var adsDwellTimeSettings = parsedAdminData.find(
        d => d.type === 'adsDwellTime',
      );
      if (adsDwellTimeSettings) {
        dwellTime = JSON.parse(
          adsDwellTimeSettings.content.toLowerCase(),
        ) as DurationContent;
      }
    }
  }
  return dwellTime;
};

export const getAdsFrequency = (): QuantityContent | null => {
  let frequency: QuantityContent | null = null;
  var adminData = localStorage.getItem('adminData');
  if (adminData) {
    var parsedAdminData = JSON.parse(adminData) as AdminControl[];

    if (parsedAdminData) {
      var adsFrequencySettings = parsedAdminData.find(
        d => d.type === 'adsFrequency',
      );
      if (adsFrequencySettings) {
        frequency = JSON.parse(
          adsFrequencySettings.content.toLowerCase(),
        ) as QuantityContent;
      }
    }
  }
  return frequency;
};

export const getReferralText = (): AdminControl | undefined => {
  let referralText: AdminControl | undefined = undefined;
  var adminData = localStorage.getItem('adminData');
  if (adminData) {
    var parsedAdminData = JSON.parse(adminData) as AdminControl[];

    if (parsedAdminData) {
      referralText = parsedAdminData.find(d => d.type === 'referralText');
    }
  }
  return referralText;
};

export const getTrialFreeChats = (): number | undefined => {
  let freeChats = 0;

  var adminData = localStorage.getItem('adminData');
  if (adminData) {
    var parsedAdminData = JSON.parse(adminData) as AdminControl[];
    if (parsedAdminData) {
      var paywallControl = parsedAdminData.find(d => d.type === 'paywallControl');
      
      if (paywallControl) {
        var content = JSON.parse(
          paywallControl.content,
        ) as PaywallContent;

        return content.freeChats
      } 
    }
  }
  return freeChats
};

export const getPaywall = (): AdminControl | undefined => {
  let paywall: AdminControl | undefined = undefined;
  var adminData = localStorage.getItem('adminData');
  if (adminData) {
    var parsedAdminData = JSON.parse(adminData) as AdminControl[];

    if (parsedAdminData) {
      paywall = parsedAdminData.find(d => d.type === 'paywallControl');
    }
  }
  return paywall;
};

export const getHiredLockDuration = (): number => {
  let hiredLockDuration = 0
  var adminData = localStorage.getItem('adminData');
  if (adminData) {
    var parsedAdminData = JSON.parse(adminData) as AdminControl[];

    if (parsedAdminData) {
      const hiredLock = parsedAdminData.find(d => d.type === 'hiredLockDuration');
      if (hiredLock
        && hiredLock.enabled
        && hiredLock.content) {
        let data = JSON.parse(hiredLock.content) as QuantityContent
        hiredLockDuration = data.value
      }
    }
  }
  return hiredLockDuration;
};
