import StepWizard from 'react-step-wizard';
import SignUpLoginInformationPage from './SignUpLoginInformationPage';
import SignUpOTPPage from './SignUpOTPPage';
import SignUpPersonalInformationPage from './SignUpPersonalInformationPage';
import SignUpEmailNotifsPage from './SignUpEmailNotifsPage';
import SignUpProfilePhotoUpdatePage from './SignUpProfilePhotoUpdatePage';
import EmployerSignUpConfirmationPage from './EmployerSignUpConfirmationPage';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { STORAGE } from '../../../utils/storage.utils';

const EmployerSignUpWizard = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [step, setStep] = useState<string>(params.get('step') || '1');

  return (
    <StepWizard isLazyMount={true} initialStep={parseInt(step) ?? 1}>
      <EmployerSignUpConfirmationPage />
      <SignUpLoginInformationPage />
      <SignUpOTPPage />
      <SignUpPersonalInformationPage />
      <SignUpEmailNotifsPage />
      <SignUpProfilePhotoUpdatePage />
    </StepWizard>
  );
};

export default EmployerSignUpWizard;
