import styled from 'styled-components';
import {colorTheme} from '../../configs';

interface StyledToggleProps {
  value: boolean;
}
export const StyledToggle = styled.div<StyledToggleProps>`
  display: flex;
  align-items: center;
  width: 42px;
  height: 20px;
  border-radius: 20px;
  padding: 2px;
  cursor: pointer;
  background: ${p => (p.value ? colorTheme.primary : colorTheme.lightGray)};
`;

export const StyledToggleIndicator = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background: ${colorTheme.white};
`;
