import {isMobile, isSafari} from 'react-device-detect';
import styled, { css } from 'styled-components';
import { colorTheme } from '../../../../core/configs';
import homeownerBg from '../../../../assets/homeowner-bg.webp';
import kasambahayBG from '../../../../assets/kasambahay-bg.webp';
import { isUsingMobileBrowser } from '../../../utils/random.util';

export const StyledAsSeenOnSectionWrapper = styled.div`
  ${isUsingMobileBrowser()
    ? css`
        height: calc(100svh - 55px - env(safe-area-inset-top));
      `
    : isMobile
    ? css`
        height: calc(100vh - 55px - env(safe-area-inset-top));
      `
    : css`
        height: auto;
      `}  

  ${isMobile
    ? css`
        padding: 36px 24px 36px 24px;
        justify-content: space-between;
        gap: 16px;
      `
    : css`
        padding: 52px;
        gap: 48px;
      `}
    background: #FFFFFF;
`;

export const StyledHeader = styled.div`
  ${isMobile
    ? css`
        font-size: 1.25rem;
      `
    : css`
        font-size: 1.953rem;
      `}

  letter-spacing: 0rem;
  font-weight: 700;
  text-align: center;
  color: ${colorTheme.primary};
`;

export const StyledLogosWrapper = styled.div`
  ${isMobile
    ? css`
        gap: 12px;
        flex-direction: column;
        height: 90%;
      `
    : css`
        gap: 48px;
        height: auto;
      `}
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    ${isMobile
      ? css`
          height: fit-content;
          max-height: 15vh;
          flex-grow: 1; 
          width: fit-content;
          overflow: hidden;
        `
      : css`
          height: 125px;
          width: auto;
        `}
    display: flex;
    justify-content: center;
    max-width: 250px;
  }

  img {
    width: auto;
    height: auto;
    object-fit: scale-down;
  }

  .idea-space {
      width: ${isMobile 
        ? 'auto'
        : '200px'};
      min-width: ${isSafari ? '200px' : 'unset'}
    }
`;
