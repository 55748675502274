import * as React from 'react';

function SvgImageAdd(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}>
      <path
        d="M4 5h13v7h2V5c0-1.103-.897-2-2-2H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h8v-2H4V5z"
        fill="currentColor"
      />
      <path d="M8 11l-3 4h11l-4-6-3 4-1-2z" fill="currentColor" />
      <path d="M19 14h-2v3h-3v2h3v3h2v-3h3v-2h-3v-3z" fill="currentColor" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgImageAdd);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
