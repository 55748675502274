import {
  differenceInYears,
  hoursToMilliseconds,
  isBefore,
  minutesToMilliseconds,
} from 'date-fns';
import {DurationContent} from '../server/types/admin-control.types';

export const calculateAge = (birthDate: Date) => {
  const age = differenceInYears(new Date(), birthDate);
  return age;
};

export const getUtcNowInMillis = (): number => {
  const now = new Date();
  return now.getTime();
};

export const getTotalMillis = (value: DurationContent): number => {
  var hoursInMillis = hoursToMilliseconds(value.hours);
  var minutesInMillis = minutesToMilliseconds(value.minutes);

  return hoursInMillis + minutesInMillis;
};

export const getWeekNumber = (date: any): number => {
  /*
  * Ref. https://stackoverflow.com/a/39502645/8724367
  * */
  if (!(date instanceof Date)) date = new Date();

  // ISO week date weeks start on Monday, so correct the day number
  const nDay = (date.getDay() + 6) % 7;

  // ISO 8601 states that week 1 is the week with the first Thursday of that year
  // Set the target date to the Thursday in the target week
  date.setDate(date.getDate() - nDay + 3);

  // Store the millisecond value of the target date
  const n1stThursday = date.valueOf();

  // Set the target to the first Thursday of the year
  // First, set the target to January 1st
  date.setMonth(0, 1);

  // Not a Thursday? Correct the date to the next Thursday
  if (date.getDay() !== 4) {
    date.setMonth(0, 1 + ((4 - date.getDay()) + 7) % 7);
  }

  // The week number is the number of weeks between the first Thursday of the year
  // and the Thursday in the target week (604800000 = 7 * 24 * 3600 * 1000)
  return 1 + Math.ceil((n1stThursday - date) / 604800000);
};

export const getAppBannerIsSeenPastOneWeek = (): boolean => {
  var lastSeenGetAppBanner = localStorage.getItem('lastSeenGetAppBanner');
  
  if (lastSeenGetAppBanner) {
    var lastSeenGetAppBannerDate = new Date(lastSeenGetAppBanner as string);
    var today = new Date();
    today.setHours(today.getHours() - 24);
    var dateIsPastOneWeek = isBefore(lastSeenGetAppBannerDate, today);
  
    return dateIsPastOneWeek ? true : false;
  } else {
    return true;
  }
};

export const featuredAdIsSeenMoreThanADay = (): boolean => {
  var lastSeenFeaturedAd = localStorage.getItem('lastSeenFeaturedAd');
  
  if (lastSeenFeaturedAd) {
    var lastSeenFeaturedAdDate = new Date(lastSeenFeaturedAd as string);
    var today = new Date();
    today.setHours(today.getHours() - 24);
    var dateIsPastOneDay = isBefore(lastSeenFeaturedAdDate, today);
  
    return dateIsPastOneDay ? true : false;
  } else {
    return true;
  }
};
