import {AxiosRequestConfig} from 'axios';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';
import {
  AdminControl,
  AdminControlReqBody,
  AdminControlType,
  AdvertisementResponse,
  BroadcastResponse,
  GetAdsParams,
  MatchingAttributeResponse,
  PostAdBody,
  PostBroadcastBody,
  PostReferralLinkBody,
  ReferralLinkResponse,
  SearchWeightParams,
} from '../types/admin-control.types';
import {convertJsonToParams} from '../../utils/query.util';
import {PaginatedData} from '../types/pagination.type';

const getAdminControls = () => {
  return API.get<AdminControl[]>(ApiEndpointsEnum.GET_ADMIN_CONTROLS);
};

const useGetAdminControls = () => {
  return useQuery(['get-admin-controls'], () => getAdminControls());
};

const getAdminControl = (type: AdminControlType) => {
  const url = `${ApiEndpointsEnum.GET_ADMIN_CONTROl}?type=${type}`
  return API.get<AdminControl>(url);
};

const useGetAdminControl = (type: AdminControlType) => {
  return useQuery(['get-admin-control'], () => getAdminControl(type));
};

const getMultipleAdminControl = (types: AdminControlType[]) => {
  const typeQueryString = types.map(type => `type=${encodeURIComponent(type)}`).join('&');
  const url = `${ApiEndpointsEnum.GET_MULTIPLE_ADMIN_CONTROl}?${typeQueryString}`;
  return API.get<AdminControl[]>(url);
};

const useGetMultipleAdminControl = (types: AdminControlType[]) => {
  return useQuery(['get-mulitple-admin-control'], () => getMultipleAdminControl(types));
}

const putAdminControl = async (reqBody: AdminControlReqBody) => {
  const url = ApiEndpointsEnum.PUT_ADMIN_CONTROL;
  return API.put<AdminControl>(url, reqBody);
};

const usePutAdminControl = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['put-admin-control'],
    (reqBody: AdminControlReqBody) => putAdminControl(reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['put-admin-control']);
      },
    },
  );
};

const getMatchingAttributes = () => {
  return API.get<MatchingAttributeResponse[]>(
    ApiEndpointsEnum.GET_ADMIN_MATCH_ATTRIBUTES,
  );
};

const useGetMatchingAttributes = () => {
  return useQuery(['get-matching-attributes'], () => getMatchingAttributes());
};

const putMatchingAttribute = async (reqBody: SearchWeightParams) => {
  const url = ApiEndpointsEnum.PUT_ADMIN_MATCH_ATTRIBUTES;
  return API.put<MatchingAttributeResponse[]>(url, reqBody);
};

const usePutMatchingAttribute = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['put-matching-attributes'],
    (reqBody: SearchWeightParams) => putMatchingAttribute(reqBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['put-admin-control']);
      },
    },
  );
};

const postAd = async (reqBody: PostAdBody) => {
  const headerAuthorization: any = {
    'Content-Type': 'multipart/form-data',
  };

  const config: AxiosRequestConfig = {
    headers: {
      ...headerAuthorization,
    },
  };

  const formData = new FormData();
  formData.append('name', reqBody.name);
  formData.append('outboundLink', reqBody.outboundLink ? reqBody.outboundLink : '');
  formData.append('type', reqBody.type);
  formData.append('linkType', reqBody.linkType);
  formData.append('targetType', reqBody.targetType);
  formData.append('startDate', reqBody.startDate.toUTCString());
  formData.append('endDate', reqBody.endDate.toUTCString());
  formData.append('image', reqBody.image!);
  formData.append('featured', `${reqBody.featured}`);
  formData.append('includeInLoop', `${reqBody.includeInLoop}`);

  return API.post<AdvertisementResponse>(
    ApiEndpointsEnum.POST_AD,
    formData,
    config,
  );
};

const usePostAd = () => {
  const queryClient = useQueryClient();

  return useMutation(['post-ad'], (reqBody: PostAdBody) => postAd(reqBody), {
    onSuccess: () => {
      queryClient.invalidateQueries(['post-ad']);
    },
  });
};

const putAd = async (reqBody: PostAdBody, adId: string) => {
  const headerAuthorization: any = {
    'Content-Type': 'multipart/form-data',
  };

  const config: AxiosRequestConfig = {
    headers: {
      ...headerAuthorization,
    },
  };

  const formData = new FormData();
  formData.append('name', reqBody.name);
  formData.append('outboundLink', reqBody.outboundLink ? reqBody.outboundLink : '');
  formData.append('type', reqBody.type);
  formData.append('linkType', reqBody.linkType);
  formData.append('targetType', reqBody.targetType);
  formData.append('startDate', reqBody.startDate.toUTCString());
  formData.append('endDate', reqBody.endDate.toUTCString());
  formData.append('featured', `${reqBody.featured}`);
  formData.append('includeInLoop', `${reqBody.includeInLoop}`);

  if (reqBody.image) {
    formData.append('image', reqBody.image!);
  }

  const url = ApiEndpointsEnum.PUT_AD.replace('{id}', adId);
  return API.put<AdvertisementResponse>(url, formData, config);
};

const usePutAd = (reqBody: PostAdBody, adId: string) => {
  const queryClient = useQueryClient();

  return useMutation(['put-ad'], () => putAd(reqBody, adId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['put-ad']);
    },
  });
};

const getAd = (adId: string) => {
  const url = ApiEndpointsEnum.GET_AD.replace('{id}', adId);
  return API.get<AdvertisementResponse>(url);
};

const useGetAd = (adId: string) => {
  return useQuery(['get-ad', adId], () => getAd(adId));
};

const getRandomBannerAd = () => {
  return API.get<AdvertisementResponse>(ApiEndpointsEnum.GET_RANDOM_BANNER_AD);
};

const useGetRandomBannerAd = () => {
  return useQuery(['get-random-banner-ad'], () => getRandomBannerAd());
};

const getRandomSplashAd = () => {
  return API.get<AdvertisementResponse>(ApiEndpointsEnum.GET_RANDOM_SLPASH_AD);
};

const useGetRandomSplashAd = () => {
  return useQuery(['get-random-splash-ad'], () => getRandomSplashAd());
};

const getFeaturedSplashAd = () => {
  return API.get<AdvertisementResponse>(ApiEndpointsEnum.GET_FEATURED_SPLASH_AD);
};

const useGetFeaturedSplashAd = () => {
  return useQuery(['get-featured-splash-ad'], () => getFeaturedSplashAd());
};

const getAds = (params: GetAdsParams) => {
  const queryParams = convertJsonToParams(params as any);

  const url = ApiEndpointsEnum.GET_ADS + '?' + queryParams;
  return API.get<PaginatedData<AdvertisementResponse>>(url);
};

const useGetAds = (params: GetAdsParams) => {
  return useQuery(['get-ads'], () => getAds(params));
};

const deleteAd = (adId: string) => {
  const url = ApiEndpointsEnum.DELETE_AD.replace('{id}', adId);
  return API.del(url);
};

const useDeleteAd = () => {
  const queryClient = useQueryClient();

  return useMutation(['delete-ad'], (adId: string) => deleteAd(adId), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['delete-ad']);
      await queryClient.fetchQuery(['get-ads']);
    },
  });
};

const postBroadcast = (reqBody: PostBroadcastBody) => {
  return API.post<BroadcastResponse>(ApiEndpointsEnum.POST_BROADCAST, reqBody);
};

const usePostBroadcast = () => {
  const queryClient = useQueryClient();

  return useMutation(['post-conversation'], (reqBody: PostBroadcastBody) => postBroadcast(reqBody), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['post-broadcast']);
    },
  });
};

const postReferralLink = (reqBody: PostReferralLinkBody) => {
  return API.post<ReferralLinkResponse>(ApiEndpointsEnum.POST_REFERRAL_LINK, reqBody);
};

const usePostReferralLink = () => {
  const queryClient = useQueryClient();

  return useMutation(['post-referral-link'], (reqBody: PostReferralLinkBody) => postReferralLink(reqBody), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['post-referral-link']);
    },
  });
};

const getReferralLink = (referralLinkId: string) => {
  const url = ApiEndpointsEnum.GET_REFERRAL_LINK.replace('{id}', referralLinkId);
  return API.get<ReferralLinkResponse>(url);
};

const useGetReferralLink = (referralLinkId: string) => {
  return useQuery(['get-referral-link', referralLinkId], () => getReferralLink(referralLinkId));
};

const deleteReferralLink = (referralLinkId: string) => {
  const url = ApiEndpointsEnum.DELETE_REFERRAL_LINK.replace('{id}', referralLinkId);
  return API.del(url);
};

const useDeleteReferralLink = () => {
  const queryClient = useQueryClient();

  return useMutation(['delete-referral-link'], (referralLinkId: string) => deleteReferralLink(referralLinkId), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['delete-referral-link']);
      await queryClient.fetchQuery(['get-referral-transactions']);
    },
  });
};

const putReferalLink = async (reqBody: PostReferralLinkBody, referralLinkId: string) => {
  const url = ApiEndpointsEnum.PUT_REFERRAL_LINK.replace('{id}', referralLinkId);
  return API.put<ReferralLinkResponse>(url, reqBody);
};

const usePutReferalLink = (reqBody: PostReferralLinkBody, referralLinkId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['put-referral-link'],
    () => putReferalLink(reqBody, referralLinkId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['put-referral-link']);
      },
    },
  );
};

export {
  getAdminControls,
  useGetAdminControls,
  useGetAdminControl,
  usePutAdminControl,
  useGetMatchingAttributes,
  usePutMatchingAttribute,
  usePostAd,
  usePutAd,
  useGetAd,
  useGetRandomBannerAd,
  getRandomSplashAd,
  useGetRandomSplashAd,
  useGetAds,
  useDeleteAd,
  usePostBroadcast,
  usePostReferralLink,
  useGetReferralLink,
  usePutReferalLink,
  useDeleteReferralLink,
  getFeaturedSplashAd,
  useGetMultipleAdminControl
};
