import React from 'react';
import {
  Button,
  Icon,
  Input,
  TextArea,
  Toggle,
  Typography,
} from '../../../../core/components';
import AdminPage from '../../../../core/components/admin-page/AdminPage';
import {useToast} from '../../../../core/components/toast';
import {colorTheme} from '../../../../core/configs';
import Papa from 'papaparse';
import {useDialog} from '../../../../core/components/dialog';
import BroadcastDialog from '../../../views/dialog-content/BroadcastDialog';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { postBroadcast } from '../../../schema/admin.schema';
import { BroadcastResponse, PostBroadcastBody } from '../../../server/types/admin-control.types';
import _ from 'lodash';
import { usePostBroadcast } from '../../../server/react-query';
import GenericConfirmationDialog from '../../../views/dialog-content/generic-dialog/GenericConfirmationDialog';

const AdminBroadcastPage = () => {
  const {presentDialog, dismissDialog} = useDialog();
  const {presentToast} = useToast();

  const postBroadcastApi = usePostBroadcast();

  const hiddenFileInput = React.useRef(null as null | HTMLInputElement);

  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    trigger,
    reset,
    formState: {errors},
  } = useForm<PostBroadcastBody>({
    resolver: yupResolver(postBroadcast),
    defaultValues: {
      readOnly: true,
      userIds: [],
      fileName: '',
      title: '',
      message: ''
    },
  });

  const addFileHandler = () => {
    hiddenFileInput.current?.click();
  };

  const parseFile = async (file: any) => {
    Papa.parse(file, {
      header: true,
      complete: results => {
        let data: any[] = [];
        Object.values(results.data).map((userId: any, index) => {
          if (!_.isNil(userId.UserId) && userId.UserId.length > 0) {
            data.push(userId.UserId);
          }
        });
        
        setValue('userIds', Object.values(data));
      },
    });
  };

  const onUploadFiles = (acceptedFiles: any) => {
    if (acceptedFiles.target.files[0]) {
      setValue('fileName', acceptedFiles.target.files[0].name);
      trigger();
      parseFile(acceptedFiles.target.files[0]);
    }
    acceptedFiles.target.value = null;
  };

  const showEmptyUserIdsErrorToast = () => {
    presentToast({
      message: 'Uploaded CSV does not have UUIDs.',
      variant: 'error',
      position: 'bottom',
    });
  };

  const showResponseDialog = (
    response: BroadcastResponse
  ) => {
    reset();
    var failedToSend = response.failedToSend?.map((u) => {return u.userId});

    presentDialog({
      fullWidth: (failedToSend && failedToSend?.length > 0) ?? false,
      headerText: '',
      content: (
        <BroadcastDialog
          sentUuidsCount={response.successfullySent?.length}
          failedToSendIds={failedToSend}
        />
      ),
      enableBackdropDismiss: false,
      hideClose: false,
    });
  }

  const showBroadcastingDialog = () => {
    presentDialog({
      headerText: '',
      content: (
        <GenericConfirmationDialog
          headerText='Sending Broadcast...'
          desc='Please do not refresh the page.'
          hideActions
        />
      ),
      enableBackdropDismiss: false,
      hideClose: true,
    });
  }

  const submitForm = async () => {
    handleSubmit(handleOnSubmit)();
  };

  const handleOnSubmit = async () => {
    try {
      var data = getValues();
      if (data.userIds.length === 0) {
        showEmptyUserIdsErrorToast();
      } else {
        showBroadcastingDialog();
        var response = await postBroadcastApi.mutateAsync(data);
        dismissDialog();
        showResponseDialog(response);
      }
    } catch (e: any) {
      presentToast({
        message: e.data.error,
        variant: 'error',
        position: 'bottom',
      });
    }
  };

  return (
    <AdminPage>
      <div className="space-y-2 mb-9">
        <Typography
          label="Broadcast"
          variant="f4"
          weight="semibold"
          color={colorTheme.dark}
        />
      </div>

      <div className="pt-3 flex flex-row justify-between items-center mb-9">
        <div className="flex-col">
          <Typography
            label="Read only"
            variant="f2"
            weight="normal"
            color={colorTheme.dark}
          />

          <Typography
            label="This will disable user to reply."
            variant="f1"
            weight="normal"
            color={colorTheme.darkFaded}
          />
        </div>
        <div>
          <Controller
            control={control}
            name="readOnly"
            render={({field: {value, onChange}}) => (
              <Toggle value={value} onToggle={onChange} />
            )}
          />
        </div>
      </div>

      <div className="mb-6"
        onClick={addFileHandler}>
        <Controller
          control={control}
          name="fileName"
          render={({field: {value, onBlur, onChange}}) => (
            <Input
              readOnly
              className='cursor-pointer'
              placeholder="Upload list of users to broadcast in csv"
              inputSize="large"
              value={value}
              onChange={onChange}
              error={errors.fileName?.message}
              iconRight={
                <>
                  <Icon
                    name="filePlus"
                    size={18}
                    color={colorTheme.darkFaded}
                    type="button"
                  />
                  <input
                    type="file"
                    accept=".csv"
                    ref={hiddenFileInput}
                    onChange={e => onUploadFiles(e)}
                    style={{display: 'none'}}
                  />
                </>
              }
            />
          )}
        />
      </div>

      <div className="flex-col mb-3">
        <Controller
          control={control}
          name="title"
          render={({field: {value, onBlur, onChange}}) => (
            <Input
              label="Broadcast Name"
              placeholder="Enter title"
              inputSize="large"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={errors.title?.message}
            />
          )}
        />
      </div>

      <div className="flex-col mb-8">
        <Controller
          control={control}
          name="message"
          render={({field: {value, onBlur, onChange}}) => (
            <TextArea
              label="Message"
              placeholder="Enter message"
              bordered
              forBroadcast
              rows={3}
              maxRows={3}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              error={errors.message?.message}
            />
          )}
        />
      </div>

      <div className="flex flex-row">
        <Button
          label="Send"
          color="primary"
          className="btn-search"
          onClick={submitForm}
          isLoading={postBroadcastApi.isLoading}
        />
      </div>
    </AdminPage>
  );
};

export default AdminBroadcastPage;
