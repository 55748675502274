import styled from "styled-components";
import {colorTheme} from "../../../core/configs";

export const StyledChatFilterPage = styled.div`
  padding: 16px;
  
  & .sort-container {
    margin-top: 24px;
    min-height: 36px;
  }
  
  & .dd-icon {
    margin-left: 10px;
  }
  
  & .sort-type-container {
    margin-top: 16px;
  }
  
  & .tab {
    border: 1px solid ${colorTheme.primary};
    padding: 9px 8px;
  }

  & .tab:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  & .tab:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  
  & .tab-active {
    background-color: ${colorTheme.primary};
  }
  
  & .role-container {
    margin-top: 24px;
  }
  
  & .role-selection {
    margin-top: 8px;
  }
  
  & .reset-container {
    margin-top: 26px;
  }
`;