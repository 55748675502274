export const homeownerDisableReasons: string[] = [
  'rad_ho_rsn_1',
  'rad_ho_rsn_2',
  'rad_ho_rsn_3',
  'rad_ho_rsn_5',
  'rad_ho_rsn_6',
  'rad_rsn_specify',
];

export const homeownerDeleteReasons: string[] = [
  'rad_ho_rsn_1',
  'rad_ho_rsn_2',
  'rad_ho_rsn_3',
  'rad_ho_rsn_4',
  'rad_ho_rsn_5',
  'rad_ho_rsn_6',
  'rad_rsn_specify',
];

export const kazamReasons: string[] = [
  'rad_kzm_rsn_1',
  'rad_kzm_rsn_2',
  'rad_kzm_rsn_3',
  'rad_kzm_rsn_4',
  'rad_kzm_rsn_5',
  'rad_rsn_specify',
];
