import styled from 'styled-components';
import {colorTheme} from '../../../../core/configs';

export const StyledChatWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: end;

  & .col-1 {
    position: relative;
  }

  & .col-3 {
    width: 24px;
    height: 24px;
  }

  & .default-avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: relative;
    background: ${colorTheme.primary};
    border-width: 1px;
    border-color: white;
  }

  & .disabled-avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: relative;
    background: ${colorTheme.white};
    border-width: 1px;
    border-color: ${colorTheme.lightGray};
  }

  & .avatar {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    position: relative;
    background: ${colorTheme.white};
    border-width: 1px;
    border-color: ${colorTheme.darkFaded};
  }

  & .avatar img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover !important;
  }

  & img.admin-profile {
    width: 22px;
    height: 22px;
  }

  & .online-status {
    width: 8px;
    height: 8px;
    position: absolute;
    float: bottom;
    bottom: 1px;
    right: 0;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
  }
`;

export const StyledChatContent = styled.div`
  padding: 8px;
  width: fit-content;
  border-radius: 8px 8px 8px 0;
  border: 1px solid ${colorTheme.lightGray};

  & h6 {
    word-break: break-word;
  }
`;

export const StyledImage = styled.img`
  height: 120px;
`;

export const StyledAvatar = styled.div`

  & .default-avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: relative;
    background: ${colorTheme.primary};
    border-width: 1px;
    border-color: white;
  }

  & .disabled-avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: relative;
    background: ${colorTheme.white};
    border-width: 1px;
    border-color: ${colorTheme.lightGray};
  }

  & .avatar {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    position: relative;
    background: ${colorTheme.white};
    border-width: 1px;
    border-color: ${colorTheme.darkFaded};
  }

  & .avatar img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover !important;
  }

  & img.admin-profile {
    width: 22px;
    height: 22px;
  }

  & .online-status {
    width: 8px;
    height: 8px;
    position: absolute;
    float: bottom;
    bottom: 1px;
    right: 0;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
  }
`;

export const StyledChatMessage = styled.div`
  font-size: 0.8rem;
  line-height: 1.2rem;
  letter-spacing: 0;
  font-weight: 400;
  color: ${colorTheme.dark};

  a, b {
    color: ${colorTheme.primary}
  }
`;
