import styled from "styled-components";
import {colorTheme} from "../../../../core/configs";

export const StyledAdminReferralsPage = styled.div`
  & .col-1 {
    flex: 3;
  }

  & .col-2 {
    flex: 6;
    margin-left: 24px;
  }

  & .col-3 {
    flex: 2;
  }
  
  & .dashboard {
    margin-top: 36px;
  }
  
  & .dashboard-item {
    padding: 15px 21px;
    border: 1px solid ${colorTheme.lightGray};
    border-radius: 4px;
  }
  
  & .dashboard .active-referrers {
    margin-top: 24px;
  }
  
  & .total-referrals, .active-referrers {
    min-width: 280px;
  }
  
  & .chart-doughnut {
    max-width: 250px;
  }
  
  & .graph-tabs > .graph-tab {
    cursor: pointer;
    min-width: 73px;
    min-height: 28px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid ${colorTheme.accent};
  }

  & .graph-tabs > .graph-tab:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  & .graph-tabs > .graph-tab:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  
  & .graph-tab.selected {
    background-color: ${colorTheme.accent};
  }
  
  & .graph-container {
    margin-top: 40px;
    width: 100%;
    min-height: 180px;
  }
  
  & .search-container {
    margin-top: 36px;
  }
  
  & .search-container .btn-search {
    margin-left: 24px;
  }
  
  & .table-container {
    margin-top: 24px;
  }
  
  & .table-row {
    cursor: pointer;
  }
`;

export const StyledReferralViewPage = styled.div`
  & .back-btn-container {
    margin-right: 8px;
  }
  
  & .search-container {
    margin-top: 36px;
  }

  & .search-container .btn-search {
    margin-left: 24px;
  }
  
  & .table-container {
    margin-top: 24px;
  }
`;

export const StyledAdminReferralTabs = styled.div`
  margin-top: 24px;

  & .tab-control {
    border: solid 1px ${colorTheme.lightGray};
    background-color: ${colorTheme.light};
    padding: 8px;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .tab-control.active {
    color: white;
    background-color: ${colorTheme.primary};
  }
`;

