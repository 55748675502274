import {GoogleMap as CapacitorGoogleMap, MapType } from '@capacitor/google-maps';
import {useRef, useEffect, useState } from 'react';
import { colorTheme } from '../../configs';
import { BeatLoader } from 'react-spinners';
import { isIOS, isMobile } from 'react-device-detect';
import { getGoogleMapApiKey } from '../../../app/utils/random.util';

export type GoogleMapProps = {
  latitude?: number;
  longitude?: number;
  zoom?: number;
  mapTypeId?: string;
  gestureHandling?: string;
  disableDefaultUI?: boolean;
  fullscreenControl?: boolean;
  streetViewControl?: boolean;
  width?: any;
  height?: any;
};

type MapConfig = {
  center: {
    lat: number;
    lng: number;
  };
  zoom: number;
  mapTypeId?: string; // Make mapTypeId optional
  disableDefaultUI?: boolean;
  fullscreenControl?: boolean;
  streetViewControl?: boolean;
  gestureHandling?: string;
  // Add other properties as needed
};

const GoogleMap = ({
  latitude = 0,
  longitude = 0,
  zoom = 20,
  mapTypeId = '',
  gestureHandling = '',
  disableDefaultUI = false,
  fullscreenControl = true,
  streetViewControl = false,
  width = '100%',
  height = '100%',
}: GoogleMapProps) => {
  const mapRef = useRef<HTMLElement>(null);
  const mapInstance = useRef<CapacitorGoogleMap | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const apiKey = getGoogleMapApiKey();

  useEffect(() => {
    let isMounted = true;

    async function createMap() {
      if (!mapRef.current || !isMounted) return;

      const config: MapConfig = {
        center: {
          lat: latitude,
          lng: longitude,
        },
        zoom: zoom,
      };

      if (mapTypeId) {
        config.mapTypeId = mapTypeId;
      }

      config.disableDefaultUI = disableDefaultUI;

      config.streetViewControl = streetViewControl;

      if (gestureHandling) {
        config.gestureHandling = gestureHandling;
      }

      config.fullscreenControl = fullscreenControl;

      const map = await CapacitorGoogleMap.create({
        id: 'my-cool-map',
        element: mapRef.current,
        apiKey: apiKey,
        config,
      });

      // Add a red marker
      map.addMarker({
        coordinate: {
          lat: latitude,
          lng: longitude,
        },
      });

      mapInstance.current = map;

      if (isMobile) {
        // Inject CSS to handle light and dark mode styles
        const style = document.createElement('style');
        style.innerHTML = `
          @media (prefers-color-scheme: dark) {
            body, textarea {
              background-color: transparent  !important;
              color: white !important;
            }
            thead, tbody, .dashboard-item {
              color: white !important;
            }
            .ios .body, .md .body {
              background-color: transparent !important;
            }
          }

          @media (prefers-color-scheme: light) {
            body, textarea {
              background-color: transparent !important;
              color: black !important;
            }
            thead, tbody, .dashboard-item {
              color: black !important;
            }
            .ios .body, .md .body {
              background-color: transparent !important;
            }
          }
        `;

        mapRef.current?.appendChild(style);
      }
      setIsLoading(false);
    }

    createMap();

    return () => {
      isMounted = false;
      if (mapInstance.current) {
        mapInstance.current.destroy();
        mapInstance.current = null;
      }
    };


  }, [apiKey, disableDefaultUI, fullscreenControl, gestureHandling, latitude, longitude, mapTypeId, streetViewControl, zoom]);

  return (
    <div className="component-wrapper" style={{backgroundColor:'transparent'}}>
      {isLoading && (
        <div
          className="loader"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999, // Ensure loader is on top of the map
          }}
        >
          <BeatLoader
            color={colorTheme.primary}
            loading={isLoading}
            margin={2}
            size={15}
          />
        </div>
      )} 

      <capacitor-google-map
        ref={mapRef}
        style={{
          display: 'inline-block',  
          width: width,
          height: isIOS 
            ? `${window.innerHeight}px` 
            : height,
        }}></capacitor-google-map>
    </div>
  
  );
};

export default GoogleMap;
