import { isAndroid } from "react-device-detect";
import { getGoogleMapApiKey } from "../../../app/utils/random.util";

export type StaticGoogleMapProps = {
    latitude: number
    longitude: number
    onLoadImage: () => void
};

const StaticGoogleMap = ({
    latitude = 0,
    longitude = 0,
    onLoadImage
  }: StaticGoogleMapProps) => {

    const getStaticMapUrl = () => {
      let apiKey = getGoogleMapApiKey();
      if (isAndroid) apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY_WEB_SERVICE ?? ""
      const base_url = process.env.REACT_APP_STATIC_GOOGLE_MAP_BASE_URL || ""
      
      return `${base_url}?center=${latitude},${longitude}&zoom=14&size=300x150&markers=color:red%7C${latitude},${longitude}&key=${apiKey}`
    }

    return (
      <div className="component-wrapper">
        <img
          onLoad={onLoadImage}
          className="rounded-t-2xl"
          src={getStaticMapUrl()}
          alt="static-map"
        />
      </div>
    );
}

export default StaticGoogleMap;
