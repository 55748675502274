import Page from '../../../core/components/page/Page';
import {Typography} from '../../../core/components';
import {colorTheme} from '../../../core/configs';
import KazamNotificationView from '../../views/cards/kazam-notification/KazamNotificationView';
import {
  useGetNotifications,
  usePostMarkAllNotificationsAsRead,
} from '../../server/react-query';
import noNotification from '../../../assets/no_notifications.svg';
import NoResultView from '../../views/cards/no-result/NoResultView';
import {useAuth} from '../../hooks/useAuth';
import HomeownerNotificationView from '../../views/cards/homeowner-notification/HomeownerNotificationView';
import {localization} from '../../localization/Localization';
import {useEffect} from 'react';
import BannerAd from '../../views/ads/banner-ad/BannerAd';
import JobOfferNotificationView from '../../views/cards/kazam-notification/JobOfferNotificationView';
import JobDetailsNotificationView from '../../views/cards/kazam-notification/JobDetailsNotificationView';
import JobEndedNotificationView from '../../views/cards/kazam-notification/JobEndedNotificationView';

const KazamNotificationsPage = () => {
  const {authData} = useAuth();
  const {data, isFetching} = useGetNotifications();
  const postMarkAllNotificationsAsRead = usePostMarkAllNotificationsAsRead();

  useEffect(() => {
    (async () => {
      localStorage.removeItem('fromEmail');
      console.log(data);

      setTimeout(async () => {
        await postMarkAllNotificationsAsRead.mutateAsync();
      }, 1000);
      
    })();
  }, [data]);

  return (
    <Page showLoading={isFetching} showBackButton>
      <div className="space-y-4">
        <Typography
          label={localization.hdr_notifs}
          variant="f3"
          weight="semibold"
          align="center"
          color={colorTheme.dark}
        />
        <div>
          <BannerAd />
        </div>
        <div className="space-y-6">
          {data && data.length > 0 ? (
            <div>
              {data &&
                data.length > 0 &&
                data.map((notif, i) => {
                  return (
                    <div key={i}>
                      {authData?.user.userRole === 'kazam' ? (
                        notif?.notificationType === 'jobOffer' ? (
                          <JobOfferNotificationView key={i} data={notif} />
                        ) : notif?.notificationType === 'jobHistoryEdited' ? (
                          <JobDetailsNotificationView key={i} data={notif} />
                        ) : notif?.notificationType === 'jobEnded' ? (
                          <JobEndedNotificationView key={i} data={notif} />
                        ) : (
                          <KazamNotificationView key={i} data={notif} />
                        )
                      ) : (
                        notif?.notificationType === 'jobHistoryEdited' ? (
                          <JobDetailsNotificationView key={i} data={notif} />
                        ) : notif?.notificationType === 'jobEnded' ? (
                          <JobEndedNotificationView key={i} data={notif} />
                        ) : (
                          <HomeownerNotificationView key={i} data={notif} />
                        )
                      )}
                    </div>
                  );
                })}
            </div>
          ) : (
            <NoResultView
              image={noNotification}
              label={localization.desc_no_notifs}></NoResultView>
          )}
        </div>
      </div>
    </Page>
  );
};

export default KazamNotificationsPage;
