import React, {createContext, ReactNode, useContext, useState} from 'react';
import {createPortal} from 'react-dom';
import Toast, {ToastProps} from './Toast';
import {generateUEID} from '../../utils';
import {StyledToastContainer, StyledToastWrapper} from './styled';

type ToastContextProps = {
  presentToast: (props: ToastProps) => void;
};

const ToastContext = createContext<ToastContextProps>({
  presentToast: () => {},
});

interface IToast extends ToastProps {
  id: string;
}

interface ToastProviderProps {
  children: ReactNode;
}

// TODO: handle multiple instances of toast. for now just show one toast at a time
export const ToastProvider = (props: ToastProviderProps) => {
  const [toast, setToast] = useState<IToast | null>(null);

  const presentToast = (pr: ToastProps) => {
    const id = generateUEID();

    if (!toast) {
      setToast({id, ...pr});
    }
  };

  const dismissToast = () => {
    setToast(null);
  };

  const providerValue = {
    presentToast,
  };

  return (
    <ToastContext.Provider value={providerValue}>
      {props.children}

      {createPortal(
        <div>
          {toast && (
            <StyledToastContainer
              position={toast.position ? toast.position : 'bottom'}>
              <StyledToastWrapper
                position={toast.position ? toast.position : 'bottom'}>
                <Toast {...toast} dismiss={dismissToast} />
              </StyledToastWrapper>
            </StyledToastContainer>
          )}
        </div>,
        document.body,
      )}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const {presentToast} = useContext(ToastContext);

  return {
    presentToast,
  };
};
