import { TerminationReason } from "../server/types/job-history.type"

export const getTerminationReason = (v: string): TerminationReason => {
    switch (v) {
      case "Voluntary Resignation":
        return 'voluntaryResignation'
      case "Termination":
        return 'termination'
      default:
        return "voluntaryResignation"
    }
  }
  
  export const getTerminationReasonValue = (v: TerminationReason) => {
    switch (v) {
      case "termination":
        return 'Termination'
      case "voluntaryResignation":
        return 'Voluntary Resignation'
      default:
        return "Voluntary Resignation"
    }
  }