import styled from 'styled-components';
import {colorTheme} from '../../../core/configs';

export const StyledImg = styled.img`
  height: 120px;
`;

export const StyledDiv = styled.div`
  font-size: 0.8rem;
  line-height: 1.2rem;
  -webkit-letter-spacing: 0rem;
  -moz-letter-spacing: 0rem;
  -ms-letter-spacing: 0rem;
  letter-spacing: 0rem;
  font-weight: 400;
  text-align: left;
  color: ${colorTheme.darkFaded};

  a {
    font-size: 0.8rem;
    line-height: 1.2rem;
    -webkit-letter-spacing: 0rem;
    -moz-letter-spacing: 0rem;
    -ms-letter-spacing: 0rem;
    letter-spacing: 0rem;
    font-weight: 400;
    text-align: left;
    color: ${colorTheme.primary};
  }
`;
