import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  Typography,
  Input,
  Button,
  WizardStep,
} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {passwordResetRequestSchema} from '../../../schema/sign-up.schema';
import {PostPasswordResetRequest} from '../../../server/types';
import logo from '../../../../assets/KAZAM_logo_Transparent.svg';
import {useHistory} from 'react-router-dom';
import {RoutePath} from '../../../navigation/config/RouteConfig';
import {useState} from 'react';
import {useToast} from '../../../../core/components/toast';
import {usePostPasswordResetRequest} from '../../../server/react-query';
import Page from '../../../../core/components/page/Page';
import {localization} from '../../../localization/Localization';

const PasswordResetRequestPage = (props: any) => {
  const history = useHistory();
  const {presentToast} = useToast();
  const [loading, setLoading] = useState(false);

  const {
    control,
    getValues,
    formState: {errors},
    handleSubmit,
  } = useForm<PostPasswordResetRequest>({
    resolver: yupResolver(passwordResetRequestSchema),
    defaultValues: {
      mobileNumber: '',
    },
  });

  const submitForm = async () => {
    handleSubmit(handleNext)();
  };

  const handleNext = () => {
    try {
      requestOTP();
    } catch (error) {
      console.log('error', error);
    }
  };

  const postPasswordResetRequest = usePostPasswordResetRequest();
  const requestOTP = async () => {
    try {
      setLoading(true);
      var data = getValues();
      await postPasswordResetRequest.mutateAsync({
        mobileNumber: data.mobileNumber,
      });

      setLoading(false);
      localStorage.setItem('passwordReset', JSON.stringify(data));
      props.nextStep();
    } catch (error: any) {
      setLoading(false);
      presentToast({
        message: error.data.error,
        variant: 'error',
        position: 'bottom',
      });
    }
  };

  const cancelPasswordReset = () => {
    localStorage.removeItem('passwordReset');
    history.replace(RoutePath.SIGN_IN);
  };

  return (
    <Page hideNavbar>
      <div className="space-y-6">
        <div>
          <WizardStep
            steps={props.totalSteps}
            currentStep={props.currentStep}></WizardStep>
        </div>
        <div className="flex justify-center">
          <img style={{height: '29px'}} src={logo} alt="Logo" />
        </div>
        <div>
          <div>
            <Typography
              label={localization.lnk_reset_pw}
              variant="f3"
              align="center"
              color={colorTheme.dark}></Typography>
          </div>
          <div className="pt-1">
            <Typography
              label={localization.desc_reset_pw}
              variant="f1"
              align="center"
              color={colorTheme.darkFaded}></Typography>
          </div>
        </div>
        <div>
          <div>
            <div>
              <Controller
                control={control}
                name="mobileNumber"
                render={({field: {value = '', onBlur, onChange}}) => (
                  <Input
                    type="tel"
                    inputSize="large"
                    placeholder={localization.ph_enter_mobile}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    onSubmit={submitForm}
                    error={errors.mobileNumber?.message}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div>
          <div>
            <Button
              label={localization.btn_next}
              color="primary"
              isLoading={loading}
              onClick={submitForm}
            />
          </div>
          <div className="pt-4">
            <Button
              label={localization.btn_cancel}
              variant="outlined"
              onClick={cancelPasswordReset}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default PasswordResetRequestPage;
