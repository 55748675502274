import { IVersionInfo } from './core/models/IVersionInfo';

export class KazamVersionInfo implements IVersionInfo {
  name = process.env.REACT_APP_NAME || "Kazam";
  version = process.env.REACT_APP_VERSION || "1.5.1";
  build = process.env.REACT_APP_BUILD || "1920";
  baseUrl = process.env.REACT_APP_BASE_URL || "@BASE_URL";
  target = process.env.REACT_APP_TARGET || "prod";
  hash = process.env.REACT_APP_HASH || "387bc97346f363a2c81626313597c9629b8a5895";
  branch = process.env.REACT_APP_BRANCH || "master";

  public toString(): string {
    return `${this.name} ${this.version} b${this.build}`;
  }
}

export const VersionInfo = new KazamVersionInfo();
