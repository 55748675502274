import {AxiosRequestConfig} from 'axios';

export const BASE_API = process.env.REACT_APP_BASE_URL;

export const apiConfig: AxiosRequestConfig = {
  withCredentials: true,
  baseURL: BASE_API,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': 'true',
  },
};
