import styled from "styled-components";
import { colorTheme } from "../../../core/configs";

export const StyledExtractReportDialog = styled.div`
  margin: 15px;
  
  & .btn-okay {
    margin-top: 20px;
  }
`;

export const StyledBroadcastDialog = styled.div`
  margin: 0 16px 16px 16px;
  justify-content: center;
  width: auto;
`;

export const StyledFailedToSendBox = styled.div`
  border: 1px solid lightgray;
  border-radius: 4px;
  width: auto;
  height: 100%;
  max-height 72px;
  gap: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 8px;
  margin: 12px 0;
`;

export const StyledFailedToSendIDBox = styled.div`
  background-color: ${colorTheme.darkFaded};
  border-radius: 12px;
  width: fit-content;
  height: 24px;
`;