import {SavedFilter} from "../server/types/chat-filter.types";

export const globalFilterStorageKey = 'chat-page-filter';

export const getChatFilterSetting = (): SavedFilter => {
  const saved = JSON.parse(localStorage.getItem(globalFilterStorageKey) || '{}');
  if (Object.keys(saved).length > 0) {
    // Use saved values
    saved['activeTab'] = saved['activeTab'] || 0;
    saved['selectedDistanceSort'] = saved['selectedDistanceSort'] || 'Near To Far';
    saved['selectedSalarySort'] = saved['selectedSalarySort'] || 'Low To High';
    saved['selectedActiveSort'] = saved['selectedActiveSort'] || 'Online First';
    saved['selectedActivitySort'] = saved['selectedActivitySort'] || 'Latest First';
    saved['selectedRoles'] = saved['selectedRoles'] || [];
  } else {
    // Use default
    saved['activeTab'] = 3;
    saved['selectedDistanceSort'] = 'Near To Far';
    saved['selectedSalarySort'] = 'Low To High';
    saved['selectedActiveSort'] = 'Online First';
    saved['selectedActivitySort'] = 'Latest First';
    saved['selectedRoles'] = [];
  }

  return saved as SavedFilter;
}

export const saveChatFilterSetting = (data: SavedFilter) => {
  localStorage.setItem(globalFilterStorageKey, JSON.stringify(data));
};
