import styled, {css} from 'styled-components';
import {InputSize, InputSizeStyle} from './input.types';
import {colorTheme} from './../../configs/color.config';
import {isMobile} from 'react-device-detect';

const inputSizeStyles: {[key in InputSize]: InputSizeStyle} = {
  default: {
    height: 32,
    padding: 0.5,
    textSize: 0.75,
    lineheight: 1.125,
  },
  large: {
    height: 40,
    padding: 1,
    textSize: 1,
    lineheight: 1.5,
  },
  extraLarge: {
    height: 94,
    padding: 1,
    textSize: 1,
    lineheight: 1.5,
  },
  small: {
    height: 28,
    padding: 0.5,
    textSize: 0.75,
    lineheight: 1.125,
  },
};

export interface StyleInputProps {
  inputSize: InputSize;
  inputSizeStyle?: InputSizeStyle; 
  error?: string;
  iconLeft?: boolean;
  iconRight?: boolean;
}

export const StyleInput = styled.input<StyleInputProps>`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none !important;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  border-radius: 4px;

  height: ${pr => inputSizeStyles[pr.inputSize].height}px;
  width: 100%;

  color: ${colorTheme.dark};

  /* add 1.25 rem for padding(left/right) if the icon exist */
  padding-left: ${pr =>
    inputSizeStyles[pr.inputSize].padding + (pr.iconLeft ? 1.25 : 0)}rem;
  padding-right: ${pr =>
    inputSizeStyles[pr.inputSize].padding + (pr.iconRight ? 1.25 : 0)}rem;

  font-size: ${pr => inputSizeStyles[pr.inputSize].textSize}rem;
  line-height: ${pr => inputSizeStyles[pr.inputSize].lineheight}rem;
  text-align: left;

  border: 1px solid
    ${pr => (pr.error ? colorTheme.danger : colorTheme.lightGray)};

  &:focus {
    outline: none;
    border: 1px solid
      ${pr => (pr.error ? colorTheme.danger : colorTheme.accent)};
  }

  &:disabled {
    color: ${colorTheme.lightGray};
  }

  ::placeholder {
    color: ${colorTheme.darkFaded};
  }

  background: white;

  &.empty::-webkit-datetime-edit {
    color: ${colorTheme.darkFaded} !important;
  }

  &.has-value::-webkit-datetime-edit {
    color: ${colorTheme.dark} !important;
  }

  ${isMobile &&
  css`
    &.date-class:before {
      width: 100%;
      content: 'Enter birthdate ' !important;
      color: ${colorTheme.darkFaded} !important;
    }
  `}
`;

export const StyledNumberFormat = styled.div<StyleInputProps>`
  width: 100%;
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none !important;

    display: flex;
    align-items: center;
    position: relative;

    border-radius: 4px;

    height: ${pr => inputSizeStyles[pr.inputSize].height}px;
    width: 100%;

    color: ${colorTheme.dark};

    /* add 1.25 rem for padding(left/right) if the icon exist */
    padding-left: ${pr =>
      inputSizeStyles[pr.inputSize].padding + (pr.iconLeft ? 1.25 : 0)}rem;
    padding-right: ${pr =>
      inputSizeStyles[pr.inputSize].padding + (pr.iconRight ? 1.25 : 0)}rem;

    font-size: ${pr => inputSizeStyles[pr.inputSize].textSize}rem;
    line-height: ${pr => inputSizeStyles[pr.inputSize].lineheight}rem;
    text-align: left;

    border: 1px solid
      ${pr => (pr.error ? colorTheme.danger : colorTheme.lightGray)};

    &:focus {
      outline: none;
      border: 1px solid
        ${pr => (pr.error ? colorTheme.danger : colorTheme.accent)};
    }

    &:disabled {
      color: ${colorTheme.lightGray};
    }

    ::placeholder {
      color: ${colorTheme.darkFaded};
    }

    background: white;

    &.empty::-webkit-datetime-edit {
      color: ${colorTheme.darkFaded} !important;
    }

    &.has-value::-webkit-datetime-edit {
      color: ${colorTheme.dark} !important;
    }

    ${isMobile &&
    css`
      &.date-class:before {
        width: 100%;
        content: 'Enter birthdate ' !important;
        color: ${colorTheme.darkFaded} !important;
      }
    `}
  }
`;

export const StyledInputLabel = styled.div<StyleInputProps>`
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 16.8px;

  color: ${colorTheme.darkFaded};
`;

export const StyledInputIcon = styled.div<StyleInputProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;

  height: ${pr => inputSizeStyles[pr.inputSize].height}px;

  left: ${pr => pr.iconLeft && '0.625rem'};
  right: ${pr => pr.iconRight && '0.625rem'};

  z-index: 10;
`;
