import React, {useCallback, useEffect, useState} from 'react';
import StyleStatusIcon from '../../../core/components/profile-status/styled';
import {StatusVariant} from '../../../core/components/profile-status';
import {useGetOnlineStatus} from '../../server/react-query';
import {useQueryClient} from 'react-query';
import {ONLINE_STATUS_PULL_INTERVAL} from '../../utils/online-status.util';
import {Icon} from '../../../core/components';
import {colorTheme} from '../../../core/configs';

interface UserOnlineStatusProps {
  userId: string;
  disabledUser?: boolean;
  bannedUser?: boolean;
  width?: number;
  height?: number;
}

const UserOnlineStatus = (props: UserOnlineStatusProps) => {
  const [status, setStatus] = useState<StatusVariant>('offline');
  const [intervalRef, setIntervalRef] = useState<NodeJS.Timeout | undefined>(
    undefined,
  );

  const {width, height, userId, disabledUser, bannedUser} = props;

  const queryClient = useQueryClient();
  const {data} = useGetOnlineStatus(userId);

  useEffect(() => {
    return () => intervalRef && clearInterval(intervalRef);
  }, [intervalRef]);

  useEffect(() => {
    if (data) {
      setStatus(data.status);
    }
  }, [data]);

  const fetchData = useCallback(() => {
    const intervalRef = setInterval(async () => {
      await queryClient.fetchQuery(`get-online-status-${userId}`);
    }, ONLINE_STATUS_PULL_INTERVAL);
    setIntervalRef(intervalRef);
  }, [queryClient, userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <StyleStatusIcon
      width={width}
      height={height}
      status={status}
      disabledUser={disabledUser}
      bannedUser={bannedUser}
      className="flex items-center justify-center">
      {disabledUser || bannedUser && <Icon name="x" size={8} color={colorTheme.white} />}
    </StyleStatusIcon>
  );
};

export default UserOnlineStatus;
