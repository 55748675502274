import styled, {keyframes} from 'styled-components';
import {ToastVariant, ViriantColor} from './toast.types';
import {colorTheme} from '../../configs/color.config';
import {ToastPosition} from '.';

const variantColors: {[key in ToastVariant]: ViriantColor} = {
  success: {
    bgColor: colorTheme.primary,
  },
  warning: {
    bgColor: colorTheme.warning,
  },
  error: {
    bgColor: colorTheme.danger,
  },
  notification: {
    bgColor: colorTheme.lightGray,
  },
};

interface StyledToastProps {
  variant: ToastVariant;
  position: ToastPosition;
}

interface StyledToastWrapperProps {
  position: ToastPosition;
}

export const StyledToast = styled.div<StyledToastProps>`
  display: flex;
  align-items: center;

  font-size: 1rem;

  text-align: center;

  width: fit-content;

  margin: 0 24px;

  border-radius: 4px;

  padding: 0.5rem 1rem;

  color: ${colorTheme.white};
  background-color: ${pr => variantColors[pr.variant].bgColor};
`;

export const StyledToastContainer = styled.div<StyledToastWrapperProps>`
  width: 100%;
  height: 0px;
  z-index: 1000;

  position: absolute;
  bottom: ${pr => (pr.position === 'bottom' ? '8rem' : 'unset')};
  top: ${pr => (pr.position === 'top' ? '4rem' : 'unset')};
  left: 0;

  display: flex;
  justify-content: center;
`;

const animateToastFromBottom = keyframes`
  0% {
    transform: translateY(4rem);
  }
  -100% {
    transform: translateY(-4rem);
  }
`;

const animateToastFromTop = keyframes`
0% {
  transform: translateY(-4rem);
}
100% {
  transform: translateY(0);
}
`;

export const StyledToastWrapper = styled.div<StyledToastWrapperProps>`
  animation: ${pr =>
      pr.position === 'bottom' ? animateToastFromBottom : animateToastFromTop}
    0.3s;
  position: absolute;
`;

export const StyledImg = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid ${colorTheme.lightGray};
`;
