import Page from '../../../core/components/page/Page';
import {Typography} from '../../../core/components';
import {colorTheme} from '../../../core/configs';
import {useAuth} from '../../hooks/useAuth';
import { useGetJobHistory } from '../../server/react-query/useHistory';
import KazamExperienceCard from '../../components/kazam-experience/KazamExperienceCard';

const HistoryPage = () => {
  const {authData} = useAuth();

  const {data, isFetching, refetch} = useGetJobHistory();

  return (
    <Page showBackButton showLoading={isFetching}>

      {data && data.length > 0 ? 
        <div className="space-y-4">
          <Typography
            label={authData?.user.userRole === 'kazam' ? 'Job History' : 'Hiring History'}
            variant="f2"
            weight="semibold"
            align="center"
            color={colorTheme.tint}
          />

          <div className="container">
            {data.map((j, i) => {
                return (
                <KazamExperienceCard
                    key={i}
                    data={j}
                    isJobHistory={true}
                    onUpdated={() => refetch()}
                />
                )
            })}
          </div>
          
        </div>
      :
        <Typography
          className="pt-2"
          label='No job history yet.'
          align="center"
          weight="normal"
          variant="f2"
          color={colorTheme.dark}
        />
      }
      
    </Page>
  );
};

export default HistoryPage;
