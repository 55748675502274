import React, {useEffect, useState} from 'react';
import Page from '../../../core/components/page/Page';
import './TaCPage.css';
import {useGetTaC} from '../../server/react-query';

const TaCPage = () => {
  const [tocData, setTocData] = useState<string>('');

  const {data, isFetching} = useGetTaC();

  useEffect(() => {
    if (data) {
      setTocData(data.content);
    }
  }, [data]);

  return (
    <Page showBackButton showLoading={isFetching}>
      <div className="tac">
        <div dangerouslySetInnerHTML={{__html: tocData}} />
      </div>
    </Page>
  );
};

export default TaCPage;
