import {isMobile} from 'react-device-detect';
import styled, { css } from 'styled-components';
import { colorTheme } from '../../../../core/configs';

export const StyledHeader = styled.div`
  ${isMobile
    ? css`
        font-size: 1.125rem;
      `
    : css`
        font-size: 1.5rem;
      `}

  letter-spacing: 0rem;
  font-weight: 400;
  text-align: center;
  color: ${colorTheme.tint};

  b {
    color: ${colorTheme.primary};
  }
`;
