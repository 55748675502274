import { useState } from "react";
import { useLocation } from "react-router";
import StepWizard from "react-step-wizard";
import PersonalityTestQuestion from "./PersonalityTestQuestion";
import { useAuth } from "../../hooks/useAuth";
import { homeownerPersonalityTest, kasambahayPersonalityTest } from "../../server/data/personality-test-questions";


const PersonalityTestWizard = () => {
  const {authData} = useAuth()
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [step] = useState<string>(params.get('step') || '1');

  const isHomeowner = authData?.user?.userRole === 'homeowner'
  const questions = isHomeowner ? homeownerPersonalityTest : kasambahayPersonalityTest
  
  return (
    <StepWizard isLazyMount={true} initialStep={parseInt(step) ?? 1}>
      {questions.map((q, i) => {
        return (
          <PersonalityTestQuestion
            key={i}
            questionNumber={i+1}
            data={q}
          />
        )
      })}
    </StepWizard>
  );
};

export default PersonalityTestWizard;
