import React, {createContext, ReactNode, useContext, useState} from 'react';
import {createPortal} from 'react-dom';
import Dialog, {BaseDialogProps} from './Dialog';
import {StyledDialogContainer} from './styled';

type DialogContextProps = {
  presentDialog: (props: BaseDialogProps) => void;
  dismissDialog: () => void;
};

const DialogContext = createContext<DialogContextProps>({
  presentDialog: () => {},
  dismissDialog: () => {},
});

interface DialogProviderProps {
  children: ReactNode;
}

export const DialogProvider = (props: DialogProviderProps) => {
  const [dialog, setDialog] = useState<BaseDialogProps | null>(null);

  const presentDialog = (pr: BaseDialogProps) => {
    const defaultProps: BaseDialogProps = {
      enableBackdropDismiss: true,
      ...pr,
    };
    setDialog(defaultProps);
  };

  const dismissDialog = () => {
    setDialog(null);
  };

  const clickOutside = (ev: React.MouseEvent<HTMLDivElement>) => {
    if (dialog?.enableBackdropDismiss) {
      setDialog(null);
    }
  };

  const onClickDialogWrapper = (ev: React.MouseEvent<HTMLDivElement>) => {
    ev.stopPropagation();
    ev.nativeEvent.stopImmediatePropagation();
  };

  const providerValue = {
    presentDialog,
    dismissDialog,
  };

  return (
    <DialogContext.Provider value={providerValue}>
      {props.children}

      {createPortal(
        <div
          style={
            dialog
              ? {
                  position: 'fixed',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 200,
                }
              : {}
          }>
          {dialog && (
            <StyledDialogContainer
              onClick={clickOutside}
              style={{
                padding: dialog.useFullScreen ? '0' : '0 24px',
              }}>
              <div
                style={{
                  width: '100%',
                  maxWidth: dialog.fullWidth ? '670px' : '400px',
                }}
                onClick={onClickDialogWrapper}>
                <Dialog {...dialog} headerAction={dismissDialog} />
              </div>
            </StyledDialogContainer>
          )}
        </div>,
        document.body,
      )}
    </DialogContext.Provider>
  );
};

export const useDialog = () => {
  const {presentDialog, dismissDialog} = useContext(DialogContext);

  return {
    presentDialog,
    dismissDialog,
  };
};
