import {useHistory} from 'react-router';
import {Icon, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {RoutePath} from '../../../navigation/config/RouteConfig';

interface SettingsItemViewProps {
  label: string;
  description: string;
  path: RoutePath;
}

const SettingsItemView = (props: SettingsItemViewProps) => {
  const history = useHistory();

  const onClick = () => {
    history.push(props.path);
  };
  return (
    <div onClick={onClick} className="cursor-pointer">
      <div className="flex">
        <div className="flex justify-between items-center space-x-2 w-full flex1">
          <div>
            <Typography
              label={props.label}
              variant="f2"
              color={colorTheme.dark}
            />
            <Typography
              label={props.description}
              variant="f1"
              color={colorTheme.darkFaded}
            />
          </div>
          <div>
            <Icon name="chevronRight" color={colorTheme.darkFaded} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsItemView;
