import {DurationContent} from '../server/types/admin-control.types';
import {OnlineStatus} from '../types/types';
import {getTotalMillis, getUtcNowInMillis} from './date.util';

const _1sec = 1000;
const _1min = 60 * _1sec;
const _1hour = 60 * _1min;
const _1day = 24 * _1hour;
const _1week = 7 * _1day;
const _2mins = 2 * _1min;
const _15mins = 15 * _1min;

export const extractOnlineStatus = (
  timestamp: number,
  onlineDuration: DurationContent | null,
  offlineDuration: DurationContent | null,
) => {
  const result: OnlineStatus = {status: 'offline', lastOnline: ''};
  const nowUtcMillis = getUtcNowInMillis();
  const diff = nowUtcMillis - timestamp;
  const onlineStatusThreshold = onlineDuration
    ? getTotalMillis(onlineDuration)
    : _2mins;
  const offlineThreshold = offlineDuration
    ? getTotalMillis(offlineDuration) + onlineStatusThreshold
    : _15mins + onlineStatusThreshold;

  if (diff >= offlineThreshold) {
    result.status = 'offline';
  } else if (diff >= onlineStatusThreshold) {
    result.status = 'away';
  } else {
    result.status = 'online';
  }

  if (diff >= _1week) {
    // Weeks
    result.lastOnline = `${(diff / _1week).toFixed(0)}w`;
  } else if (diff >= _1day) {
    // Days
    result.lastOnline = `${(diff / _1day).toFixed(0)}d`;
  } else if (diff >= _1hour) {
    // Hours
    result.lastOnline = `${(diff / _1hour).toFixed(0)}h`;
  } else if (diff >= _1min) {
    // Minutes
    result.lastOnline = `${(diff / _1min).toFixed(0)}m`;
  } else {
    // Seconds
    result.lastOnline = 'Now';
  }
  return result;
};

export const ONLINE_STATUS_PUSH_INTERVAL = 30_000; // Update online status every 30 seconds
export const ONLINE_STATUS_PULL_INTERVAL = 30_000; // Get online status every 30 seconds
