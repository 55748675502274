import {AxiosRequestConfig} from 'axios';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';

import {convertJsonToParams} from '../../utils/query.util';
import {PaginatedData} from '../types/pagination.type';
import { GetSubscriptionsParams, PostSubscriptionBody, SubscriptionResponse } from '../types/subscription.types';

const postSubscription = (reqBody: PostSubscriptionBody) => {
  return API.post<SubscriptionResponse>(ApiEndpointsEnum.POST_SUBSCRIPTION, reqBody);
};

const usePostSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation(['post-subscription'], (reqBody: PostSubscriptionBody) => postSubscription(reqBody), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['post-subscription']);
    },
  });
};

const putSubscription = async (reqBody: PostSubscriptionBody, subscriptionId: string) => {
  const url = ApiEndpointsEnum.PUT_SUBSCRIPTION.replace('{id}', subscriptionId);
  return API.put<SubscriptionResponse>(url, reqBody);
};

const usePutSubscription = (reqBody: PostSubscriptionBody, subscriptionId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ['put-subscription'],
    () => putSubscription(reqBody, subscriptionId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['put-subscription']);
      },
    },
  );
};

const getSubscription = (subscriptionId: string) => {
  const url = ApiEndpointsEnum.GET_SUBSCRIPTION.replace('{id}', subscriptionId);
  return API.get<SubscriptionResponse>(url);
};

const useGetSubscription = (subscriptionId: string) => {
  return useQuery(['get-subscription', subscriptionId], () => getSubscription(subscriptionId));
};

const deleteSubscription = (subscriptionId: string) => {
  const url = ApiEndpointsEnum.DELETE_SUBSCRIPTION.replace('{id}', subscriptionId);
  return API.del(url);
};

const useDeleteSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation(['delete-subscription'], (subscriptionId: string) => deleteSubscription(subscriptionId), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['delete-subscription']);
      await queryClient.fetchQuery(['get-subscriptions']);
    },
  });
};

const getSubscriptions = (params: GetSubscriptionsParams) => {
  const queryParams = convertJsonToParams(params as any);

  const url = ApiEndpointsEnum.GET_SUBSCRIPTIONS + '?' + queryParams;
  return API.get<PaginatedData<SubscriptionResponse>>(url);
};

const useGetSubscriptions = (params: GetSubscriptionsParams) => {
  return useQuery(['get-subscriptions'], () => getSubscriptions(params));
};

const getAvailableSubscriptions = () => {
  return API.get<SubscriptionResponse[]>(ApiEndpointsEnum.GET_AVAILABLE_SUBSCRIPTIONS);
};

const useGetAvailableSubscriptions = () => {
  return useQuery(['get-available-subscriptions'], () => getAvailableSubscriptions());
};

export {
  usePostSubscription,
  useGetSubscription,
  usePutSubscription,
  useDeleteSubscription,
  useGetSubscriptions,
  useGetAvailableSubscriptions
};
