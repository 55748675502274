import React from 'react';
import {StyledDialog} from './styled';
import {Button, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import { ButtonColor } from '../../../../core/components/button';
import { TypographyAlignment } from '../../../../core/components/typography/typography.types';

interface GenericConfirmationDialogProps {
  headerText: string;
  alignHeader?: TypographyAlignment;
  desc: string;
  leftButtonText?: string;
  rightButtonText?: string;
  rightButtonColor?: ButtonColor
  onLeftButtonAction?: () => void;
  onRightButtonAction?: () => void;
  hideActions?: boolean;
  isLoading?: boolean
}

const GenericConfirmationDialog = (props: GenericConfirmationDialogProps) => {
  return (
    <StyledDialog className="flex flex-col items-center">
      <div className="header">
        <Typography
          label={props.headerText}
          variant="f3"
          align={props.alignHeader}
          weight="semibold"
          color={colorTheme.dark}
        />
      </div>

      <div className="message">
        <Typography
          label={props.desc}
          align="center"
          variant="f1"
          color={colorTheme.darkFaded}
        />
      </div>

      {!props.hideActions &&
        <div className="actions flex flex-row w-full">
          {props.leftButtonText &&
            <Button
              label={props.leftButtonText}
              variant="outlined"
              className="flex-1"
              onClick={props.onLeftButtonAction}
            />  
          }

          {props.leftButtonText && 
            <div className="spacer" />
          }

          {props.rightButtonText &&
            <Button
              label={props.rightButtonText}
              color={props?.rightButtonColor ?? 'primary'}
              className="flex-1"
              isLoading={props.isLoading}
              onClick={props.onRightButtonAction}
            />
          }
        </div>}
    </StyledDialog>
  );
};

export default GenericConfirmationDialog;
