import React, {useEffect, useState} from 'react';
import {ChatContentResponse, TagResponse} from '../../server/types';
import {toNumberWithCommas} from '../../utils/decimal.util';
import {StyledGenericChatItem} from './styled';
import UserOnlineStatus from '../../components/online-status/UserOnlineStatus';
import {Icon, Typography} from '../../../core/components';
import {colorTheme} from '../../../core/configs';
import HideConfirmationDialog from '../../views/dialog-content/hide-confirmation-dialog/HideConfirmationDialog';
import {useDialog} from '../../../core/components/dialog';
import {usePostHide} from '../../server/react-query';
import {useAuth} from '../../hooks/useAuth';
import {localization} from '../../localization/Localization';
import VerifiedTag from '../../../core/components/verified-tag';

interface KawayItemProps {
  data: TagResponse;
  onClick?: (_: TagResponse) => void;
  recentContent?: ChatContentResponse;
}

const KawayItem = (props: KawayItemProps) => {
  const [lookingFor, setLookingFor] = useState<string>('');
  const [salary, setSalary] = useState<string>('');
  const [lastMessage, setLastMessage] = useState<string>('');
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const {authData} = useAuth();
  const {recentContent} = props;
  const {user} = props.data;

  const {presentDialog, dismissDialog} = useDialog();
  const postHide = usePostHide({hideUserId: user.userId});

  useEffect(() => {
    if (user) {
      const dataLookingFor = user.filterSetting.kazamRoles
        .map(kr => kr.role.text)
        .join(', ');
      setLookingFor(dataLookingFor);

      const dataSalary = `₱ ${toNumberWithCommas(
        user.filterSetting.salary || 0,
      )} ${localization.ho_pr_per_mo}`;
      setSalary(dataSalary);

      if (recentContent) {
        if (recentContent.media.length > 0) {
          setLastMessage('Sent an attachment.');
        } else {
          setLastMessage(recentContent.content);
        }
      } else {
        setLastMessage('[No message yet]');
      }

      setIsDisabled(user.userStatus === 'disabled');
      setIsDeleted(user.userStatus === 'deleted');
    }
  }, [user, recentContent]);

  const clickHandler = () => {
    props.onClick
      && !isDisabled
      && !isDeleted
      && !user.isBanned
      && props.onClick(props.data);
  };

  const hideKazamClickHandler = (event: React.MouseEvent) => {
    if (
      !isDisabled
      && !isDeleted
      && !user.isBanned
    ) {
      event.stopPropagation();
      presentDialog({
        headerText: '',
        content: (
          <HideConfirmationDialog onCancel={dismissDialog} onHide={hideHandler} />
        ),
        enableBackdropDismiss: true,
      });
    }
  };

  const hideHandler = async () => {
    try {
      await postHide.mutateAsync();
    } catch (e: any) {
      console.log(e);
    } finally {
      dismissDialog();
    }
  };

  return (
    <StyledGenericChatItem
      isHomeowner={authData?.user.userRole === 'homeowner'}
      onClick={clickHandler}>
      <div className="flex flex-row w-full items-center space-x-2">
        <div className="profile-picture">
          {isDeleted ? (
            <div className="disabled-avatar flex justify-center items-center">
              <Icon name="user" color={colorTheme.white}></Icon>
            </div>
          ) : (
            <div className="avatar flex justify-center items-center">
              {user.photo ? (
                <img src={user.photo} alt="profile" />
              ) : (
                <Icon name="user" color={colorTheme.white}></Icon>
              )}

              <div className="online-status flex justify-center items-center">
                <UserOnlineStatus
                  disabledUser={isDisabled}
                  bannedUser={user.isBanned}
                  userId={user.userId}
                  width={10}
                  height={10}
                />
              </div>
            </div>
          )}
        </div>

        <div className="flex justify-between items-center space-x-2 w-full flex1">
          {isDeleted || user.isBanned ? (
            <div className="flex flex-col w-full flex1">
              <Typography
                label={isDeleted ? "Kazam User" : user.firstName}
                variant="f2"
                weight="semibold"
                color={colorTheme.dark}
              />
              <Typography
                label={isDeleted ? "Deleted account" : "This user has been banned"}
                variant="f1"
                color={colorTheme.darkFaded}
              />
            </div>
          ) : (
            <div className="flex flex-col w-full flex1">
              <div className='flex items-center space-x-1'>
                <Typography
                  label={user.firstName}
                  variant="f2"
                  weight="semibold"
                  color={colorTheme.dark}
                />
                {(user?.verifiedStatus === 'verified' || user?.faceDocumentVerified) && <VerifiedTag />}
              </div>
              <div className="flex flex-row">
                <div className="overflow">
                  <Typography
                    label={lookingFor}
                    variant="f1"
                    weight="semibold"
                    color={colorTheme.darkFaded}
                    singleLine
                  />
                </div>
                <Typography
                  className="bullet"
                  label="•"
                  variant="f1"
                  weight="semibold"
                  color={colorTheme.lightGray}
                />
                <div className="overflow">
                  <Typography
                    label={salary}
                    variant="f1"
                    weight="semibold"
                    color={colorTheme.darkFaded}
                    singleLine
                  />
                </div>
              </div>
              <div className="flex flex-row">
                <div className="overflow">
                  <Typography
                    label={lastMessage}
                    variant="f1"
                    color={colorTheme.darkFaded}
                    singleLine
                  />
                </div>
              </div>
            </div>
          )}

          {!isDeleted && (
            <div>
              <Icon
                disabled={isDisabled || user.isBanned}
                name="xCircle"
                color={isDisabled || user.isBanned ? colorTheme.lightGray : colorTheme.darkFaded}
                size={24}
                type="button"
                onClick={hideKazamClickHandler}
              />
            </div>
          )}
        </div>
      </div>
    </StyledGenericChatItem>
  );
};

export default KawayItem;
