import React from 'react';
import KazamPurpleIcon from '../../../assets/KAZAM_logo_Transparent.png';
import KazamWhiteIcon from '../../../assets/KAZAM_white_logo_Transparent.png';

interface CallNavbarComponentProps {
  isVideoCall: boolean;
  isAnswered: boolean;
}

const CallNavbarComponent = ({
  isVideoCall,
  isAnswered,
}: CallNavbarComponentProps) => {
  return (
    <div
      className="w-full h-14 grid absolute justify-center py-3 z-50"
      style={{
        background: isVideoCall ? isAnswered 
          ? '#000'
          : 'transparent' 
          : 'transparent',
      }}>
      <img
        src={KazamWhiteIcon}
        alt="Kazam Icon"
        style={{
          height: 29,
        }}
      />
    </div>
  );
};

export default CallNavbarComponent;
