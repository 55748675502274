import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  Typography,
  Input,
  Button,
  WizardStep,
} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {signUpOTPSchema} from '../../../schema/sign-up.schema';
import {OTP} from '../../../server/types/user.types';
import logo from '../../../../assets/KAZAM_logo_Transparent.svg';
import {useEffect, useState} from 'react';
import {RoutePath} from '../../../navigation/config/RouteConfig';
import {useHistory} from 'react-router-dom';
import {useToast} from '../../../../core/components/toast';
import Page from '../../../../core/components/page/Page';
import {
  usePostPasswordResetRequest,
  usePostPasswordResetVerify,
} from '../../../server/react-query';
import {localization} from '../../../localization/Localization';

const PasswordResetOTPPage = (props: any) => {
  const history = useHistory();
  const {presentToast} = useToast();
  const [loading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState(20);
  const [resends, setResends] = useState(1);

  const {
    control,
    getValues,
    formState: {errors},
    handleSubmit,
  } = useForm<OTP>({
    resolver: yupResolver(signUpOTPSchema),
    defaultValues: {
      code: '',
    },
  });

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);

  const submitForm = async () => {
    handleSubmit(handleNext)();
  };

  const handleNext = () => {
    try {
      verifyOTP();
    } catch (error) {
      console.log('error', error);
    }
  };

  const resendOTP = () => {
    if (resends <= 2) {
      requestOTP();
    } else {
      presentToast({
        message: 'You have reached the maximum retries.',
        variant: 'error',
        position: 'bottom',
      });
    }
  };

  const cancelPasswordReset = () => {
    localStorage.removeItem('passwordReset');
    history.replace(RoutePath.SIGN_IN);
  };

  const postPasswordResetRequest = usePostPasswordResetRequest();
  const requestOTP = async () => {
    try {
      setLoading(true);
      var passwordReset = localStorage.getItem('passwordReset');
      if (passwordReset) {
        var passwordResetData = JSON.parse(passwordReset);
        await postPasswordResetRequest.mutateAsync({
          mobileNumber: passwordResetData.mobileNumber,
        });

        setLoading(false);
        setSeconds(20);
        setResends(resends + 1);
        presentToast({
          message: 'Password reset code sent.',
          variant: 'success',
          position: 'bottom',
        });
      }
    } catch (error: any) {
      setLoading(false);
      presentToast({
        message: error.data.error,
        variant: 'error',
        position: 'bottom',
      });
    }
  };

  const postPasswordResetVerify = usePostPasswordResetVerify();
  const verifyOTP = async () => {
    try {
      setLoading(true);
      var passwordReset = localStorage.getItem('passwordReset');
      if (passwordReset) {
        var passwordResetData = JSON.parse(passwordReset);

        await postPasswordResetVerify.mutateAsync({
          mobileNumber: passwordResetData.mobileNumber,
          otp: getValues().code,
        });

        setLoading(false);
        props.nextStep();
      }
    } catch (error: any) {
      setLoading(false);
      presentToast({
        message: error.data.error,
        variant: 'error',
        position: 'bottom',
      });
    }
  };

  return (
    <Page hideNavbar>
      <div className="space-y-6">
        <div>
          <WizardStep
            steps={props.totalSteps}
            currentStep={props.currentStep}></WizardStep>
        </div>
        <div className="flex justify-center">
          <img style={{height: '29px'}} src={logo} alt="Logo" />
        </div>
        <div>
          <div>
            <Typography
              label={localization.hdr_otp}
              variant="f3"
              align="center"
              color={colorTheme.dark}></Typography>
          </div>
          <div className="pt-1">
            <Typography
              label={localization.desc_reset_pw_otp}
              variant="f1"
              align="center"
              color={colorTheme.darkFaded}></Typography>
          </div>
        </div>
        <div>
          <div>
            <Controller
              control={control}
              name="code"
              render={({field: {value = '', onBlur, onChange}}) => (
                <Input
                  type="number"
                  inputSize="large"
                  placeholder={localization.ph_enter_otp}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  onSubmit={submitForm}
                  error={errors.code?.message}
                />
              )}
            />
          </div>
        </div>

        <div>
          <div>
            <Button
              label={localization.btn_submit}
              color="primary"
              isLoading={loading}
              onClick={submitForm}
            />
            <div className="flex justify-center pt-2">
              <div>
                <Typography
                  label={
                    seconds > 0
                      ? `Resend code after ${seconds} seconds`
                      : 'Didn’t receive any code? '
                  }
                  variant="f1"
                  align="center"
                  color={colorTheme.darkFaded}></Typography>
              </div>

              {seconds === 0 && (
                <div className="pl-1" onClick={resendOTP}>
                  <Typography
                    label={localization.lnk_resend_otp}
                    variant="f1"
                    align="center"
                    color={colorTheme.primary}></Typography>
                </div>
              )}
            </div>
          </div>
          <div className="pt-4">
            <Button
              label={localization.btn_cancel}
              variant="outlined"
              onClick={cancelPasswordReset}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default PasswordResetOTPPage;
