
import {
  isAndroid,
  isChrome,
  isChromium,
  isEdge,
  isEdgeChromium,
  isFirefox,
  isIOS,
  isMacOs,
  isMobileSafari,
  isOpera,
  isSafari,
  isSamsungBrowser,
  isWindows
} from 'react-device-detect';

import {Gender} from '../server/types';
import { getAppBannerIsSeenPastOneWeek } from './date.util';
import { CallMeta } from '../server/types/conversation.types';

export const generateUEID = () => {
  let first = (Math.random() * 46656) | 0;
  let second = (Math.random() * 46656) | 0;

  return (
    ('000' + first.toString(36)).slice(-3) +
    ('000' + second.toString(36)).slice(-3)
  );
};

export const capitalize = (text: string) => {
  const capitalizedString = text.charAt(0).toUpperCase() + text.slice(1);
  return capitalizedString;
};

export const getGenderEnum = (gender: string) => {
  switch (gender) {
    case 'Male':
      return gender.toLowerCase();
    case 'Female':
      return gender.toLowerCase();
    default:
      return 'male';
  }
};

export const getGenderString = (gender: Gender) => {
  switch (gender) {
    case 'male':
      return capitalize(gender);
    case 'female':
      return capitalize(gender);
    default:
      return 'male';
  }
};

export const isUsingApp = (): boolean => {
  var platform = process.env.REACT_APP_PLATFORM ?? 'web';
  return platform === 'mobileApp';
};

export const isUsingBrowser = (): boolean => {
  if (isUsingApp()) {
    return false;
  }

  if (isChrome 
    || isChromium
    || isSafari
    || isMobileSafari 
    || isSamsungBrowser
    || isEdge
    || isEdgeChromium
    || isFirefox
    || isOpera) {
      return true;
    } else {
      return false;
    }
};

export const isUsingMobileBrowser = (): boolean => {
  if (isUsingApp()) {
    return false;
  }
  
  if ((isChrome 
    || isChromium
    || isSafari
    || isMobileSafari
    || isSamsungBrowser
    || isEdge
    || isEdgeChromium
    || isFirefox
    || isOpera) && (isAndroid || isIOS)) {
      return true;
    } else {
      return false;
    }
};

export const getAppBannerUrl = (): string => {
  const googlePlayUrl = process.env.REACT_APP_KAZAM_GOOGLE_PLAYSTORE_URL;
  const appStoreUrl = process.env.REACT_APP_KAZAM_APP_STORE_URL;
  const dateIsPastWeek = getAppBannerIsSeenPastOneWeek();
  
  if (dateIsPastWeek && isUsingMobileBrowser()) {
    if ((googlePlayUrl && isAndroid)) {
      return googlePlayUrl;
    } else if (appStoreUrl && isIOS){
      return appStoreUrl;
    } else {
      return '';
    }
  } else {
    return '';
  }
};

export const getTheAppUrl = (): string => {
  const googlePlayUrl = process.env.REACT_APP_KAZAM_GOOGLE_PLAYSTORE_URL;
  const appStoreUrl = process.env.REACT_APP_KAZAM_APP_STORE_URL;
  
  if (isUsingBrowser()) {
    if ((googlePlayUrl && (isAndroid || isWindows))) {
      return googlePlayUrl;
    } else if (appStoreUrl && (isIOS || isMacOs)){
      return appStoreUrl;
    } else {
      return '';
    }
  } else {
    return '';
  }
};

export const getAppUrl = (): string => {
  const googlePlayUrl = process.env.REACT_APP_KAZAM_GOOGLE_PLAYSTORE_URL;
  const appStoreUrl = process.env.REACT_APP_KAZAM_APP_STORE_URL;
  
  if (isUsingApp()) {
    if ((googlePlayUrl && isAndroid)) {
      return googlePlayUrl;
    } else if (appStoreUrl && isIOS){
      return appStoreUrl;
    } else {
      return '';
    }
  } else {
    return '';
  }
};

export const getTimestampInSeconds = () => {
  return Math.floor(Date.now() / 1000)
};

export function linkify(text: string) {
  var urlRegex = /(\b(https):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  return text.replace(urlRegex, function(url) {
    return '<a target="_blank" href="' + url + '">' + url + "</a>";
  });
}

export const getCallDuration = (metaData: string) => {
  let duration = ""
  if (metaData) {
    var meta = JSON.parse(metaData) as CallMeta
    if (meta.duration
      && meta.duration > 0) {
      const totalSeconds = meta.duration
      var h = Math.floor(totalSeconds / 3600);
      var m = Math.floor(totalSeconds % 3600 / 60);
      var s = Math.floor(totalSeconds % 3600 % 60);

      var hDisplay = h > 0 ? h + (h == 1 ? "hr " : "hrs ") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? "min " : "mins ") : "";
      var sDisplay = s > 0 ? s + (s == 1 ? "sec" : "secs") : "";
      duration = hDisplay + mDisplay + sDisplay
    }
  }
  
  return duration.trim()
}

export const getGoogleMapApiKey = (): string => {
  const webApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY_WEB ?? "";
  const androidApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY_ANDROID ?? "";
  const iOSApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY_IOS ?? "";
  
  if (isUsingApp()) {
    if ((isAndroid)) {
      return androidApiKey;
    } else if (isIOS){
      return iOSApiKey;
    } else {
      return webApiKey;
    }
  } else {
    return webApiKey;
  }
};
