import {colorTheme} from '../../../../../core/configs';
import {localization} from '../../../../localization/Localization';
import { Typography, Button } from '../../../../../core/components';
import SubscriptionItem from './SubscriptionItem';
import { StyledSubscriptions } from './styles';
import { useGetAvailableSubscriptions } from '../../../../server/react-query/useSubscription';
import { BeatLoader } from 'react-spinners';
import { useState } from 'react';
import { usePostCheckoutSession } from '../../../../server/react-query/usePayment';
import { KazamException } from '../../../../server/types';
import { useHistory, useLocation } from 'react-router-dom';
import { Browser } from '@capacitor/browser';
import { isUsingApp } from '../../../../utils/random.util';
import { InAppBrowser } from '@capgo/inappbrowser'
import { useDialog } from '../../../../../core/components/dialog';

interface DialogProps {
  userId?: string | undefined
  onSuccess: () => void
  onError: (error: string) => void
}

const SubscriptionsDialogContentView = (props: DialogProps) => {
  const location = useLocation();
  let history = useHistory()
  const {dismissDialog} = useDialog();
  
  const {data, isFetching} = useGetAvailableSubscriptions()

  const [selected, setSelected]= useState<string>();

  

  const proceedToPurchaseSummary = (subscriptionId: string) => {
    history.push(`/purchase-summary/${subscriptionId}/${props.userId}`);
    dismissDialog()
  }

  
  return (
    <>
      <Typography
        className='absolute top-4 left-4'
        label="Subscribe to chat"
        variant="f2"
        color={colorTheme.dark}
      />
      {data && data.length > 3 && 
        <Typography
          className='absolute top-10 left-4'
          label="Scroll down to see all subscription packages"
          variant="f1"
          color={colorTheme.primary}
        />
      }
      <div className={`flex flex-col items-center p-4 space-y-4
          ${data && data.length <= 3 && 'pt-0'}`}>
        <StyledSubscriptions className='flex-col space-y-4'>
          {isFetching && 
            <div
              className="w-full flex justify-center items-center">
              <BeatLoader
                color={colorTheme.primary}
                loading={true}
                margin={2}
                size={15}
              />
            </div>
          }

          {data
            && data?.length > 0
            && !isFetching && 
            <>
              {data.map((s, i) => {
                return (
                  <SubscriptionItem
                    key={i}
                    data={s}
                    isSelected={selected === s.subscriptionId}
                    onClick={() => setSelected(s.subscriptionId)}
                  />
                )
              })}
            </>
          }
        </StyledSubscriptions>

        <div className="w-full flex flex-col space-y-1">
          <Button
            label={localization.btn_proceed_payment}
            color="primary"
            disabled={!!!selected}
            onClick={() => selected && proceedToPurchaseSummary(selected)}
          />

          <Typography
            label="You will be redirected to the PayMongo payment gateway page."
            variant="caption"
            color={colorTheme.darkFaded}
            align="center"
          />
        </div>
      </div>
    </>
  );
};

export default SubscriptionsDialogContentView;
