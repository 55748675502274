import {Typography} from '../../../../../core/components';
import AdminPage from '../../../../../core/components/admin-page/AdminPage';
import {colorTheme} from '../../../../../core/configs';
import {StyledAdminUsersPage} from "../styled";
import AdminReportedUsersTable from "./AdminReportedUsersTable";
import AdminUsersTabs from "../AdminUsersTabs";
import React, {useState} from "react";
import AdminUsersSearch from "../AdminUsersSearch";
import {usePostJob} from "../../../../server/react-query";
import {useToast} from "../../../../../core/components/toast";
import {useDialog} from "../../../../../core/components/dialog";
import ExtractReportDialog from "../../../../views/dialog-content/ExtractReportDialog";
import {useAuth} from "../../../../hooks/useAuth";

const AdminUsersReportedPage = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const postJob = usePostJob();
  const {presentToast} = useToast();
  const {presentDialog, dismissDialog} = useDialog();
  const {authData} = useAuth();

  const onSearchChangedHandler = (query: string) => {
    setSearchQuery(query);
  };

  const onExtractClickedHandler = async () => {
    if (authData?.user.email) {
      try {
        await postJob.mutateAsync({
          type: 'reportedUsersReport',
          params: JSON.stringify({
            email: authData.user.email,
          }),
        });

        presentDialog({
          headerText: '',
          content: (
            <ExtractReportDialog requestName="Reported Users" onOkayClick={() => dismissDialog()} />
          ),
          enableBackdropDismiss: true,
          hideClose: true,
        });
      } catch (error) {
        console.log({error});
        presentToast({
          message: 'Request failed',
          variant: 'error',
          position: 'bottom',
        });
      }
    }
  };

  return (
    <AdminPage>
      <StyledAdminUsersPage>
        <Typography
          label="Manage Users"
          variant="f3"
          weight="semibold"
          color={colorTheme.dark}
        />

        <AdminUsersTabs selected={1} />

        <AdminUsersSearch
          onChange={onSearchChangedHandler}
          showExtractButton={true}
        extractClickHandler={onExtractClickedHandler}/>

        <AdminReportedUsersTable searchQuery={searchQuery} />

      </StyledAdminUsersPage>
    </AdminPage>
  );
};

export default AdminUsersReportedPage;
