import styled from 'styled-components';
import {colorTheme} from '../../configs';

export interface StyleTypeheadProps {
  error?: string;
}

export const StyledWrapper = styled.div<StyleTypeheadProps>`
  & input {
    height: 40px;

    &:focus {
      outline: none;
      border: 1px solid ${colorTheme.accent};
    }
  }

  & .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid
      ${pr => (pr.error ? colorTheme.danger : colorTheme.lightGray)};
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  & .dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    max-height: 150px !important;
  }

  & .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
`;

export const StyledInputLabel = styled.div`
  margin-bottom: 0.25rem;

  font-size: 0.8rem;
  font-weight: 600;
  line-height: 16.8px;

  color: ${colorTheme.darkFaded};
`;
