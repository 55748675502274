import React, {useEffect, useState} from 'react';
import Page from '../../../core/components/page/Page';
import './PrivacyPolicyPage.css';
import {useGetPrivacyPolicy} from '../../server/react-query';

const PrivacyPolicyPage = () => {
  const [privacyPolicyData, setPrivacyPolicyData] = useState<string>('');

  const {data, isFetching} = useGetPrivacyPolicy();

  useEffect(() => {
    if (data) {
      setPrivacyPolicyData(data.content);
    }
  }, [data]);

  return (
    <Page showBackButton showLoading={isFetching}>
      <div className="privacy-policy">
        <div dangerouslySetInnerHTML={{__html: privacyPolicyData}} />
      </div>
    </Page>
  );
};

export default PrivacyPolicyPage;
