import styled, {css} from 'styled-components';
import {colorTheme} from '../../../core/configs';
import {isBrowser, isIOS} from 'react-device-detect';

export const StyledVideoCallRoomPage = styled.div`
  ${isIOS &&
    css`
      position: fixed;
      inset: 0;
      overflow-y: hidden;
    `
  }

  & .call-nav {
    ${isIOS &&
      css`
        padding-top: env(safe-area-inset-top);
      `
    }
  }

  & .viewport {
    position: relative;
    box-sizing: border-box;
    width: 100vw;
    display: flex;
    overflow: hidden;
    flex-direction: column;

    ${isIOS
      ? css`
          height: calc(100vh - env(safe-area-inset-top));
        `
      : css`
          height: 100vh;
        `}
    ${isIOS
      ? css`
          max-height: calc(100vh - env(safe-area-inset-top));
        `
      : css`
          max-height: 100vh;
        `}

    .video-container {
      position: relative;
      width: 100vw;
      height: ${isIOS ? 'calc(100vh - env(safe-area-inset-top))' : '100vh'};

      & .single-video-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      & .single-video-wrap {
        width: 100%;
        position: relative;
      }

      & .video-canvas {
        height: 100%;
        scale: ${isIOS ? '1.4' : '1.1'};  
      }
      & .self-video {
        position: absolute;
        width: 254px;
        height: 143px;
        top: 50px;
        right: 30px;
        z-index: 2;
        display: none;
        & .single-self-video {
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
        & .self-video-show {
          display: block;
        }
      }
    }

    & .profile-picture {
      width: 102px;
      position: relative;
    }

    & .default-avatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      position: relative;
      background: ${colorTheme.primary};
      border-width: 1px;
      border-color: white;
    }

    & .disabled-avatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      position: relative;
      background: ${colorTheme.lightGray};
    }

    & .avatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      position: relative;
      border-width: 1px;
      border-color: white;
      background: ${colorTheme.white};
    }

    & .avatar img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover !important;
    }

    & .online-status {
      width: 12px;
      height: 12px;
      position: absolute;
      float: bottom;
      bottom: 8px;
      right: 8px;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
    }

    & .preview-video {
      overflow: hidden;
      height: 100%;

      & > video,
      & > canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }
      & > .preview-video-show {
        display: block;
      }

      & > .self-video-min {
        height: 20% !important;
        width: auto !important;
        border-radius: 14px !important;
        top: 92px !important;
        right: 20px !important;
        left: auto !important;
        z-index: 20 !important;
      }

      & > .phone-rear-camera-view {
        -webkit-transform: scaleX(1) !important;
        transform: scaleX(1) !important;
      }
    }

    & .video-operations-preview > div {
      display: flex;
      padding: 10px;
      background: #000;

      & .disabled-video {
        background-color: ${colorTheme.danger};
      }

      & .disabled-audio {
        background-color: grey;
      }

      & .end-call {
        background-color: ${colorTheme.danger};
      }

      & svg {
        cursor: pointer !important;
      }
    }

    & .avatar-wrap {
      position: absolute;
      pointer-events: auto;
      list-style: none;
      top: 0;
      left: 0;
    }

    & .avatar-wrap-dragging {
      z-index: 10;
    }

    & .single-view-avatar {
      top: 0;
      left: 0;
    }

    & .self-video-non-sab {
      display: none;
      position: absolute;
    }
  }
`;

export const StyledVoiceCallRoomPage = styled.div`
  background: linear-gradient(
    180deg,
    rgba(99, 0, 153, 0.74) 0%,
    rgba(15, 44, 197, 0.73) 100%
  );

  ${isIOS &&
    css`
      position: fixed;
      inset: 0;
      overflow-y: hidden;
    `
  } 

  & .call-nav {
    ${isIOS &&
      css`
        padding-top: env(safe-area-inset-top);
      `
    }
  }
  
  & .viewport {
    position: relative;
    box-sizing: border-box;
    width: 100vw;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    ${isIOS
      ? css`
          height: calc(100vh - env(safe-area-inset-top));
        `
      : css`
          height: 100vh;
        `}
    ${isIOS
      ? css`
          max-height: calc(100vh - env(safe-area-inset-top));
        `
      : css`
          max-height: 100vh;
        `}

    & .profile-picture {
      width: 102px;
      position: relative;
    }

    & .default-avatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      position: relative;
      background: ${colorTheme.primary};
      border-width: 1px;
      border-color: white;
    }

    & .disabled-avatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      position: relative;
      background: ${colorTheme.lightGray};
    }

    & .avatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      position: relative;
      border-width: 1px;
      border-color: white;
      background: ${colorTheme.white};
    }

    & .avatar img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover !important;
    }

    & .online-status {
      width: 12px;
      height: 12px;
      position: absolute;
      float: bottom;
      bottom: 8px;
      right: 8px;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
    }

    & .voice-operations-preview > div {
      display: flex;
      padding: 15px 20px;
      border-radius: 14px;

      & .enabled-button {
        background-color: #e2e2e27a;
      }

      & .red-button {
        background-color: #b6051b;
      }

      & svg {
        cursor: pointer !important;
      }
    }

    & .avatar-wrap {
      position: absolute;
      pointer-events: auto;
      list-style: none;
      top: 0;
      left: 0;
    }

    & .avatar-wrap-dragging {
      z-index: 10;
    }

    & .single-view-avatar {
      top: 0;
      left: 0;
    }
  }
`;

export const StyledInAppCallNotifPage = styled.div`
  background: linear-gradient(
    180deg,
    rgba(99, 0, 153, 0.74) 0%,
    rgba(15, 44, 197, 0.73) 100%
  );

  ${isBrowser
    && css`
      border-radius: 16px;
    `
  }

  & .call-nav {
    ${isIOS &&
      css`
        padding-top: env(safe-area-inset-top);
      `
    }
  }

  & .viewport {
    position: relative;
    box-sizing: border-box;
    display: flex;
    overflow: hidden;
    flex-direction: column;

    ${isBrowser
      && css`
          width: 100%;
          height: 600px !important;
        `}

    ${isIOS
      ? css`
          height: 100vh;
        `
      : css`
          height: 100vh;
        `}
    ${isIOS
      ? css`
          max-height: 100vh;
        `
      : css`
          max-height: 100vh;
        `}

    & .profile-picture {
      width: 102px;
      position: relative;
    }

    & .default-avatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      position: relative;
      background: ${colorTheme.primary};
      border-width: 1px;
      border-color: white;
    }

    & .disabled-avatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      position: relative;
      background: ${colorTheme.lightGray};
    }

    & .avatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      position: relative;
      border-width: 1px;
      border-color: white;
      background: ${colorTheme.white};
    }

    & .avatar img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover !important;
    }

    & .online-status {
      width: 12px;
      height: 12px;
      position: absolute;
      float: bottom;
      bottom: 8px;
      right: 8px;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
    }

    & .voice-operations-preview > div {
      display: flex;
      padding: 15px 20px;
      border-radius: 14px;

      & .enabled-button {
        background-color: #e2e2e27a;
      }

      & .red-button {
        background-color: #b6051b;
      }

      & svg {
        cursor: pointer !important;
      }
    }

    & .avatar-wrap {
      position: absolute;
      pointer-events: auto;
      list-style: none;
      top: 0;
      left: 0;
    }

    & .avatar-wrap-dragging {
      z-index: 10;
    }

    & .single-view-avatar {
      top: 0;
      left: 0;
    }
  }
`;

export const StyledBetaSection = styled.div`

  position: absolute;
  ${isIOS ?
    css`
      top: calc(env(safe-area-inset-top) + 60px);
    ` : 
    css`
      top: 56px;top: 60px;
    `
  }
  
  left: 0;
  right: 0;
  z-index: 50;
  display: flex;
  justify-content: center;
  width: 100%;
  

  & .beta-section {
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    padding: 4px 8px;
    border-radius: 25px;
    background: ${colorTheme.secondary};
  }
`;
