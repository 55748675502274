import React from 'react';

const NextArrowIcon = ({ width = "24", height = "25" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 8.5L22 12.5M22 12.5L18 16.5M22 12.5H2"
      stroke="#998CFF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default NextArrowIcon;
