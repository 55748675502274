import { isMobile } from 'react-device-detect';
import {Typography} from '../../../../core/components';
import {StyledFinderSettings} from './styled';

const EditProfileStickyView = () => {
  return (
    <StyledFinderSettings 
    className={[
      'finder-settings flex justify-between cursor-pointer',
      isMobile ? 'floating-card' : '',
    ].join(' ')}>
      <div>
        <Typography
          label="Before making any changes, please take a screen capture or copy the content of this screen if you wish to save existing values."
          variant="f1"
          weight="normal"
          color="white"
        />
      </div>
    </StyledFinderSettings>
  );
};

export default EditProfileStickyView;
