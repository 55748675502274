import { useMutation, useQuery, useQueryClient } from "react-query";
import { API, ApiEndpointsEnum } from "../api";
import { GetVouchersParams, PostVoucherBody, PostVoucherRedeemBody, VoucherRedeemResponse, VoucherResponse } from "../types/voucher.types";
import { convertJsonToParams } from "../../utils/query.util";
import { PaginatedData } from "../types/pagination.type";


const postVoucher = (reqBody: PostVoucherBody) => {
    return API.post<VoucherResponse>(ApiEndpointsEnum.POST_VOUCHER, reqBody);
};
  
const usePostVoucher = () => {
    const queryClient = useQueryClient();

    return useMutation(['post-voucher'], (reqBody: PostVoucherBody) => postVoucher(reqBody), {
        onSuccess: async () => {
        await queryClient.invalidateQueries(['post-voucher']);
        },
    });
};

const postVoucherRedeem = (reqBody: PostVoucherRedeemBody) => {
    return API.post<VoucherRedeemResponse>(ApiEndpointsEnum.POST_VOUCHER_REDEEM, reqBody);
};
  
const usePostVoucherRedeem = () => {
    const queryClient = useQueryClient();

    return useMutation(['post-voucher-redeem'], (reqBody: PostVoucherRedeemBody) => postVoucherRedeem(reqBody), {
        onSuccess: async () => {
        await queryClient.invalidateQueries(['post-voucher-redeem']);
        },
    });
};

const putVoucher = async (reqBody: PostVoucherBody, voucherId: string) => {
    const url = ApiEndpointsEnum.PUT_VOUCHER.replace('{id}', voucherId);
    return API.put<VoucherResponse>(url, reqBody);
  };
  
const usePutVoucher = (reqBody: PostVoucherBody, voucherId: string) => {
    const queryClient = useQueryClient();

    return useMutation(
        ['put-voucher'],
        () => putVoucher(reqBody, voucherId),
        {
        onSuccess: () => {
            queryClient.invalidateQueries(['put-voucher']);
        },
        },
    );
};

const getVoucher = (voucherId: string) => {
    const url = ApiEndpointsEnum.GET_VOUCHER.replace('{id}', voucherId);
    return API.get<VoucherResponse>(url);
  };
  
  const useGetVoucher = (voucherId: string) => {
    return useQuery(['get-voucher', voucherId], () => getVoucher(voucherId));
  };

const deleteVoucher = (voucherId: string) => {
    const url = ApiEndpointsEnum.DELETE_VOUCHER.replace('{id}', voucherId);
    return API.del(url);
};
  
const useDeleteVoucher = () => {
    const queryClient = useQueryClient();

    return useMutation(['delete-voucher'], (voucherId: string) => deleteVoucher(voucherId), {
        onSuccess: async () => {
        await queryClient.invalidateQueries(['delete-voucher']);
        await queryClient.fetchQuery(['get-vouchers']);
        },
    });
};

const getVouchers = (params: GetVouchersParams) => {
    const queryParams = convertJsonToParams(params as any);

    const url = ApiEndpointsEnum.GET_VOUCHERS + '?' + queryParams;
    return API.get<PaginatedData<VoucherResponse>>(url);
};
  
const useGetVouchers = (params: GetVouchersParams) => {
    return useQuery(['get-vouchers'], () => getVouchers(params));
};

export {
    usePostVoucher,
    useGetVouchers,
    useGetVoucher,
    usePutVoucher,
    useDeleteVoucher,
    usePostVoucherRedeem
  };
  