import React, {useEffect, useState} from 'react';
import logo from '../../../../assets/KAZAM_logo_Transparent.svg';
import signInImage from '../../../../assets/img_I_Am_Homeowner.svg';
import {StyledSignInPage} from './styled';
import Page from '../../../../core/components/page/Page';
import {Button, Icon, Input, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {useHistory} from 'react-router-dom';
import {useToast} from '../../../../core/components/toast';
import {useAuth} from '../../../hooks/useAuth';
import {AuthMe, SignInBody} from '../../../server/types';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {signInSchema} from '../../../schema/sign-in.schema';
import {usePostSignIn, usePutRecoverAccount} from '../../../server/react-query';
import {Controller} from 'react-hook-form';
import {RoutePath} from '../../../navigation/config/RouteConfig';
import firebase from 'firebase/app';
import {localization} from '../../../localization/Localization';
import {useDialog} from '../../../../core/components/dialog';
import BannedAccountNoticeDialog from '../../../views/dialog-content/banned-account/BannedAccountNoticeDialog';
import DeletedAccountNoticeDialog from '../../../views/dialog-content/deleted-account/DeletedAccountNoticeDialog';
import {useAnalytics} from '../../../hooks/useAnalytics';
import {calculateAge} from '../../../utils/date.util';
import {capitalize, isUsingBrowser} from '../../../utils/random.util';
import GetTheAppView from '../../../views/cards/get-the-app/GetTheAppView';

const SignInPage = () => {
  const {updateAuthData} = useAuth();
  const history = useHistory();
  const {systemLogEvent, initializeUser} = useAnalytics();

  const {presentToast} = useToast();
  const {presentDialog, dismissDialog} = useDialog();

  const [isSigningIn, setIsSigningIn] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const googlePlayUrl = process.env.REACT_APP_KAZAM_GOOGLE_PLAYSTORE_URL;
  const appStoreUrl = process.env.REACT_APP_KAZAM_APP_STORE_URL;

  const logoClickHandler = () => {
    history.replace('/');
  };

  const goToResetPassword = () => {
    history.push(RoutePath.PASSWORD_RESET);
  };

  const {
    control,
    getValues,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<SignInBody>({
    resolver: yupResolver(signInSchema),
    defaultValues: {
      mobileNumber: '',
      password: '',
    },
  });

  useEffect(() => {
    var mobileNumber = localStorage.getItem('lastLoginNumber') ?? undefined;
    var password = localStorage.getItem('lastLoginPassword') ?? undefined;

    reset({mobileNumber, password});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const postSignIn = usePostSignIn(getValues());

  const submitForm = () => {
    handleSubmit(handleSignIn)();
  };

  const onCloseBannedNoticeDialogClicked = () => {
    dismissDialog();
  };

  const putRecoverAccount = usePutRecoverAccount();
  const onContinueRecoverAccount = async () => {
    dismissDialog();
    try {
      setIsSigningIn(true);
      const values = getValues();
      const response = (await putRecoverAccount.mutateAsync({
        mobileNumber: values.mobileNumber,
      })) as AuthMe;

      if (response && response.customToken) {
        await firebase
          .auth()
          .signInWithCustomToken(response.customToken)
          .then((res: any) => {
            response.accessToken = res.user.Aa;
            updateAuthData(response);

            var today = new Date();
            localStorage.setItem('lastLoginDate', today.toISOString());
            history.replace(RoutePath.MATCHES_LANDING);
          })
          .catch(error => {
            console.log(error);
          });
      }
    } catch (e: any) {
      setIsSigningIn(false);
      presentToast({
        message: 'Failed to recover account.',
        variant: 'error',
        position: 'bottom',
      });
    }
  };

  const handleSignIn = async () => {
    setIsSigningIn(true);
    try {
      const response = (await postSignIn.mutateAsync()) as AuthMe;

      if (response && response.customToken) {
        await firebase
          .auth()
          .signInWithCustomToken(response.customToken)
          .then((res: any) => {
            response.accessToken = res.user.Aa;
            updateAuthData(response);

            var today = new Date();
            localStorage.setItem('lastLoginDate', today.toISOString());

            const values = getValues();
            localStorage.setItem('lastLoginNumber', values.mobileNumber);
            localStorage.setItem('lastLoginPassword', values.password);

            initializeUser(response.user);
            systemLogEvent('sign_in', {
              age: calculateAge(new Date(response.user.birthDate)).toString(),
              gender: capitalize(response.user.gender),
              city:
                response.user.filterSetting.location?.cityOrMunicipality +
                (response.user.filterSetting.location?.hasProvince
                  ? `, ${response.user.filterSetting.location?.province.name}`
                  : ''),
              role:
                response.user.userRole === 'homeowner' ? 'homeowner' : 'kazam',
              salary: response.user.filterSetting?.salary
                ? response.user.filterSetting?.salary
                : 0,
            });

            history.replace(RoutePath.LANDING);
          })
          .catch(error => {
            console.log(error);
          });
      }
    } catch (e: any) {
      setIsSigningIn(false);

      if (e && e.status >= 400 && e.status < 500) {
        if (e.status === 403 && e.data && e.data === 'Account was banned') {
          // Account is banned
          presentDialog({
            headerText: '',
            content: (
              <BannedAccountNoticeDialog
                onClose={onCloseBannedNoticeDialogClicked}
              />
            ),
            enableBackdropDismiss: false,
            hideClose: false,
          });
        } else if (e.data.errorCode === 'A_0006') {
          // Account was recently deleted
          presentDialog({
            headerText: '',
            content: (
              <DeletedAccountNoticeDialog
                onCancel={dismissDialog}
                onContinue={onContinueRecoverAccount}
              />
            ),
            enableBackdropDismiss: false,
            hideClose: false,
          });
        } else {
          presentToast({
            message: 'Invalid mobile number or password',
            variant: 'error',
            position: 'bottom',
          });
        }
      } else {
        presentToast({
          message: 'Failed signing in',
          variant: 'error',
          position: 'bottom',
        });
      }
    }
  };

  return (
    <Page hideNavbar>
      <StyledSignInPage>
        <div className="w-full flex flex-row justify-center">
          <img
            className="logo cursor-pointer"
            src={logo}
            alt="Logo"
            onClick={logoClickHandler}
          />
        </div>

        <div className="section w-full flex flex-row justify-center">
          <img
            className="sign-in-image"
            src={signInImage}
            alt="Sign in images"
          />
        </div>

        <div className="section flex flex-row justify-center">
          <Typography
            label={localization.hdr_landing}
            variant="f3"
            weight="semibold"
            color={colorTheme.dark}
          />
          <Typography
            className="spaced-left"
            label="kazam"
            variant="f3"
            weight="semibold"
            color={colorTheme.primary}
          />
          <Typography label="!" variant="f3" weight="semibold" />
        </div>

        <div className="flex flex-row justify-center">
          <Typography
            label={localization.desc_landing}
            variant="f1"
            color={colorTheme.darkFaded}
          />
        </div>

        <div className="section controls flex flex-col">
          <div className="control">
            <Controller
              control={control}
              name="mobileNumber"
              render={({field: {value = '', onBlur, onChange}}) => (
                <Input
                  type="tel"
                  inputSize="large"
                  placeholder={localization.ph_enter_mobile}
                  onChange={onChange}
                  value={value}
                  error={errors.mobileNumber?.message}
                  onBlur={onBlur}
                />
              )}
            />
          </div>
          <div className="control">
            <Controller
              control={control}
              name="password"
              render={({field: {value = '', onBlur, onChange}}) => (
                <Input
                  inputSize="large"
                  placeholder={localization.ph_enter_pw}
                  type={showPassword ? 'text' : 'password'}
                  onChange={onChange}
                  value={value}
                  error={errors.password?.message}
                  onBlur={onBlur}
                  onSubmit={submitForm}
                  iconRight={
                    <Icon
                      name={showPassword ? 'eye' : 'eyeOff'}
                      size={16}
                      color={value ? colorTheme.dark : colorTheme.darkFaded}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  }
                />
              )}
            />
          </div>
        </div>

        <div className="section">
          <Button
            label={localization.btn_sign_in}
            color="primary"
            onClick={submitForm}
            isLoading={isSigningIn}
          />
        </div>

        <div className="forgot-password-container flex flex-row justify-center">
          <Typography
            label={localization.lnk_forgot_pw_desc}
            variant="f1"
            color={colorTheme.darkFaded}
          />
          <span
            className="spaced-left cursor-pointer"
            onClick={goToResetPassword}>
            <Typography
              label={localization.lnk_reset_pw}
              variant="f1"
              color={colorTheme.primary}
            />
          </span>
        </div>

        {(googlePlayUrl || appStoreUrl) && isUsingBrowser() &&
          <GetTheAppView googlePlayUrl={googlePlayUrl} appStoreUrl={appStoreUrl} />
        }
      </StyledSignInPage>
    </Page>
  );
};

export default SignInPage;
